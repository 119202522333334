import React from 'react';
import { FormGroup, Label } from 'reactstrap';
import Select from 'react-select/creatable';
import { gql, useMutation, useQuery } from '@apollo/client';
import { ActionMeta, ValueType } from 'react-select';
import EditActions, { EditActionsProps } from './EditActions';
import { MineralGroup, MineralGroupType } from '@wgt/types';

interface UseMineralGroupArgs {
  type?: MineralGroupType;
  skip?: boolean;
  parentId?: string;
  filter?: {
    mineralGroup?: {
      id: number[];
    };
  };
}

type UseMineralGroupParams = UseMineralGroupArgs | undefined;

export const LIST_MINERAL_GROUPS = gql`
  query mineralGroups($filter: MineralGroupFilterInput) {
    mineralGroups(filter: $filter, first: 1000) {
      data {
        id
        label: name
        parent {
          id
        }
      }
    }
  }
`;

function useMineralGroup({ type, skip, parentId }: UseMineralGroupParams = {}) {
  return useQuery<{ mineralGroups: { data: MineralGroup[] } }>(
    LIST_MINERAL_GROUPS,
    {
      skip,
      nextFetchPolicy: 'cache-first',
      variables: {
        filter: {
          type,
          ...(parentId ? { parent_id: [parentId] } : {}),
        },
      },
    },
  );
}

interface MineralGroupSelectorProps extends EditActionsProps {
  type: MineralGroupType;
  onChange: (
    value: ValueType<MineralGroup>,
    action: ActionMeta<MineralGroup>,
  ) => void;
  label: string;
  name: string;
  skip?: boolean;
  parentId?: string;
}

export default function MineralGroupSelector({
  value,
  onChange,
  type,
  name,
  label,
  skip,
  handleDelete,
  handleUpdate,
  isLoadingDelete,
  parentId,
}: MineralGroupSelectorProps): JSX.Element {
  const { data, loading } = useMineralGroup({ type, skip, parentId });

  const [mutate] = useMutation(
    gql`
      mutation createMineralGroup(
        $name: String!
        $type: MineralGroupType
        $parent_id: ID
      ) {
        createMineralGroup(name: $name, type: $type, parent_id: $parent_id) {
          id
          name
        }
      }
    `,
    {
      refetchQueries: [
        {
          query: LIST_MINERAL_GROUPS,
          variables: {
            filter: {
              type,
            },
          },
        },
      ],
    },
  );
  const create = (input: string) =>
    mutate({
      variables: { type, parent_id: parentId, name: input },
    });

  return (
    <FormGroup>
      <Label className="d-flex aling-items-center justify-content-between">
        {label}
        <EditActions
          handleDelete={handleDelete}
          handleUpdate={handleUpdate}
          value={value}
          isLoadingDelete={isLoadingDelete}
          skip={skip}
        />
      </Label>
      <Select
        isClearable
        options={data?.mineralGroups.data}
        getOptionLabel={(e) => e.label}
        getOptionValue={(e) => `${e.id}`}
        isLoading={loading}
        isDisabled={skip}
        onCreateOption={create}
        closeMenuOnSelect={false}
        value={value ?? null}
        name={name}
        onChange={onChange}
      />
    </FormGroup>
  );
}
