import React, { useState } from 'react';
import {
  UncontrolledPopover,
  PopoverBody,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { NOT_COMPOUND_LIST } from '@wgt/shared/constants';
import { Button, Text } from '../..';

export default function NotCompound(): JSX.Element {
  const [popoverNotCompound, setPopoverNotCompound] = useState<boolean>(false);
  const { t } = useTranslation(['pointOfSalesCompounds', 'pointOfSales']);
  const togglePopoverNotCompound = () =>
    setPopoverNotCompound(!popoverNotCompound);

  const { register, watch } = useFormContext();
  const notCompound = watch('notCompound');

  return (
    <>
      <Button type="combobox" id="listNotCompoundPopover">
        <div className="d-flex justify-content-between w-100">
          <div>
            {t(notCompound ? notCompound : 'pointOfSales:None Compound')}
          </div>
          <div>{popoverNotCompound ? <FiChevronUp /> : <FiChevronDown />}</div>
        </div>
      </Button>
      <UncontrolledPopover
        trigger="legacy"
        isOpen={popoverNotCompound}
        target="listNotCompoundPopover"
        toggle={togglePopoverNotCompound}
      >
        <PopoverBody className="popover-list-payment">
          <Text type="muted">{t('pointOfSales:None Compound Period')}</Text>
          <Row className="mt-2 payment-type-checkbox">
            <Col xs="12">
              {NOT_COMPOUND_LIST.map((period) => (
                <FormGroup check key={period} className="mt-1">
                  <Label check>
                    <Input
                      type="radio"
                      name="notCompound"
                      defaultValue={period}
                      defaultChecked={notCompound === period}
                      innerRef={register()}
                    />
                    {t(period)}
                  </Label>
                </FormGroup>
              ))}
            </Col>
          </Row>
        </PopoverBody>
      </UncontrolledPopover>
    </>
  );
}
