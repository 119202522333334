import {
  Observable,
  ApolloClient,
  createHttpLink,
  gql,
  ApolloLink,
} from '@apollo/client';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import { currentAuthQuery, AuthorizationProps } from '../../graphql/auth.gql';
import cache from '../cache';
import { AuthenticationActions, ActionType } from '@wgt/authentication';
import config from '../../config';
interface Authorization {
  authorization: AuthorizationProps;
}

const refreshTokenGql = gql`
  mutation refreshToken {
    refreshToken {
      access_token
      token_refresh
    }
  }
`;

const clear = async () =>
  Promise.all([
    cache.reset(),
    AuthenticationActions.dispatch?.({
      type: ActionType.SET,
      payload: undefined,
    }),
  ]);

const error = (): ApolloLink =>
  onError(({ graphQLErrors, operation, forward }: ErrorResponse) => {
    if (!graphQLErrors) {
      return undefined;
    }

    if (
      !graphQLErrors.some((x) =>
        x.message.toUpperCase().includes('UNAUTHENTICATED'),
      )
    ) {
      return undefined;
    }

    let token = '';
    try {
      const data = cache.readQuery<Authorization>({
        query: currentAuthQuery,
      });
      token = data?.authorization?.token_refresh ?? '';
    } catch (e) {
      console.error(e);
    }

    if (!token) {
      clear();
      return undefined;
    }
    const client = new ApolloClient({
      cache,
      link: createHttpLink({
        uri: config.apiUrl,
      }),
    });

    return new Observable((observer) => {
      client
        .mutate({
          mutation: refreshTokenGql,
          context: {
            headers: { authorization: `Bearer ${token}` },
          },
          update: async (_, { data: { refreshToken } }) => {
            cache.writeQuery({
              query: currentAuthQuery,
              data: {
                authorization: {
                  access_token: refreshToken.access_token,
                  token_refresh: refreshToken.token_refresh,
                  __typename: 'AuthTokenType',
                },
              },
            });

            return AuthenticationActions.dispatch?.(refreshToken);
          },
        })
        .then(async (res) => {
          const refreshed = res.data?.refreshToken ?? {};
          AuthenticationActions.dispatch?.(refreshed);
          operation.setContext(({ headers = {} }) => ({
            ...headers,
            authorization: `Bearer ${refreshed.access_token}`,
          }));
          const subscriber = {
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          };

          forward(operation).subscribe(subscriber);
        })
        .catch(async (e) => {
          await clear();
          observer.error(e);
        });
    });
  });

export default error;
