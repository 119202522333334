/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import useCrmRoutes from '../../../../../../hooks/useCrmRoutes';
import Put from '../Put';
import { GET_PROPERTY_VALUE_GQL } from '../../../graphql';

const PutModal: React.FC = () => {
  const history = useHistory();
  const params = useParams<{ id: string; propertyValueId: string }>();
  const { t } = useTranslation('property');
  const { actualRoute } = useCrmRoutes();
  const [opened, setOpen] = useState<boolean>(true);

  const toggle = () => setOpen((isOpen) => !isOpen);

  useEffect(() => {
    let time: any;
    if (!opened) {
      time = setTimeout(
        () => history.push(`/crm/${actualRoute}/${params.id}/edit`),
        1000,
      );
    }
    return () => {
      clearTimeout(time);
    };
  }, [opened]);

  const { data, loading } = useQuery(GET_PROPERTY_VALUE_GQL, {
    skip: !params.propertyValueId,
    variables: {
      id: params.propertyValueId,
    },
  });

  return (
    <Modal
      isOpen={opened}
      toggle={toggle}
      size="xl"
      className="propery-value-modal"
    >
      <ModalHeader toggle={toggle} className="m-0">
        {t('Edit Property Value')}
      </ModalHeader>
      <ModalBody>
        <Put
          toggle={toggle}
          propertyValue={data?.propertyValue}
          isLoading={loading}
        />
      </ModalBody>
    </Modal>
  );
};

export default PutModal;
