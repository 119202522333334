import React from 'react';
import { useTranslation } from 'react-i18next';
import { FiFileText } from 'react-icons/fi';
import { Policy } from '@wgt/types';
import { Text } from '../../../../../../../components';
import Options from './Options';
import useLoggedContext from '../../../../../../../hooks/useLoggedContext';

interface PolicyProps {
  policy: Policy;
}

export default function PolicyCard({ policy }: PolicyProps): JSX.Element {
  const { description, net_day_count, expiry_date, type } = policy;
  const { t } = useTranslation('pointOfSales');
  const { active } = useLoggedContext();
  return (
    <div className="border rounded p-2 bg-white policy-card">
      <div className="d-flex header">
        <FiFileText className="iconFileText" />
        <div>
          <Text className="box-title ml-2 my-1">
            {type?.name}
            {policy?.title && ` - ${policy?.title}`}
          </Text>
          <Text className="box-text ml-2">
            {description.substr(0, 29)}
            {description.length > 32 && '...'}
          </Text>
        </div>
      </div>

      <Text className="box-text ml-2 mt-1">
        {policy?.firm?.id === active?.id && 'stock owner inputs.'}
      </Text>

      <div className="ml-2 mt-2">
        <Text>
          {t('Expiry date')}:{' '}
          <strong>
            {t('Net - day', {
              net_day_count,
              plural: Number(net_day_count) > 1 ? 's' : '',
            })}
          </strong>
        </Text>
        <Text>
          {t('Day')}: <strong>{expiry_date}</strong>
        </Text>
      </div>

      <div>
        <Options policy={policy} />
      </div>
    </div>
  );
}
