import React, { memo } from 'react';
import useWorkOrderSchedule from '../useWorkOrderSchedule';
import { WorkOrderProductProvider } from './useWorkOrderProduct';
import WorkOrderProductItem from './WorkOrderProductItem';

export default memo(function WorkOrderProducts(): JSX.Element {
  const [{ workOrder, products }] = useWorkOrderSchedule();
  return (
    <div className="d-flex flex-column mt-2 overflow-auto content-scroll">
      <div className="container  ">
        {(workOrder?.products ?? products)?.map((product, x) => (
          <WorkOrderProductProvider product={product} key={x}>
            <WorkOrderProductItem />
          </WorkOrderProductProvider>
        ))}
        {workOrder?.posProducts?.map((posProduct, x) => (
          <WorkOrderProductProvider product={posProduct.product} key={x}>
            <WorkOrderProductItem />
          </WorkOrderProductProvider>
        ))}
      </div>
    </div>
  );
});
