import React, { useState } from 'react';
import {
  FieldDependenciesFormProps,
  SubmitDependenciesFormProps,
} from '../../../../../types';
import { Button } from '../../../../../../../../components';
import { InputGroup, Form } from 'reactstrap';
import { FormProvider, useForm } from 'react-hook-form';
import BtnLoading from '../../../../../../../../components/_molecules/BtnLoading';
import { LayoutComponentDataTypePropertyValue } from '@wgt/types';
import { gql, useQuery } from '@apollo/client';

const FieldDependenciesForm: React.FC<FieldDependenciesFormProps> = ({
  filteredFields,
  onSubmit,
  loading,
}) => {
  const [fieldIdSelected, setFieldIdSelected] = useState<string>();

  const methods = useForm();

  const { data } = useQuery(
    gql`
      query templateField($templateFieldId: ID!) {
        templateField(id: $templateFieldId) {
          values {
            id
            name
            key
          }
        }
      }
    `,
    {
      variables: {
        templateFieldId: fieldIdSelected,
      },
    },
  );

  const handleSubmit = async (dataValue: SubmitDependenciesFormProps) => {
    await onSubmit(dataValue);
    setFieldIdSelected(undefined);
    methods.reset();
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(handleSubmit)}>
        <InputGroup>
          <select
            required
            className="form-control"
            name="fieldId"
            ref={methods.register}
            onChange={(e) => setFieldIdSelected(e.target.value)}
          >
            <option>Select field</option>
            {filteredFields?.map((filteredField) => (
              <option key={filteredField.id} value={filteredField.id}>
                {filteredField.formValues?.label}
              </option>
            ))}
          </select>

          <select
            disabled={!data?.templateField.values.length}
            name="valueId"
            ref={methods.register}
            className="form-control"
          >
            <option value="">Select Value</option>
            {data?.templateField.values.map(
              (filteredFieldValue: LayoutComponentDataTypePropertyValue) => (
                <option
                  key={filteredFieldValue.id}
                  value={filteredFieldValue.id}
                >
                  {filteredFieldValue.name}
                </option>
              ),
            )}
          </select>

          <Button
            htmlType="submit"
            disabled={!data?.templateField.values.length || loading}
          >
            {loading ? <BtnLoading /> : 'save'}
          </Button>
        </InputGroup>
      </Form>
      <hr />
    </FormProvider>
  );
};

export default FieldDependenciesForm;
