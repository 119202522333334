import React, { useMemo } from 'react';
import { useProductUpdate } from '../Provider';
import { useFormContext } from 'react-hook-form';
import { PARCEL_TYPE_PER_DIAMOND_ID } from '@wgt/shared/constants';
import FirstSection from './FirstSection';
import SecondSection from './SecondSection';
import { convertJsonLayoutToTemplateFields } from '@wgt/converters';

interface TemplateFieldsProps {
  section: 'first' | 'second';
}

const TemplateFields: React.FC<TemplateFieldsProps> = ({
  section = 'first',
}) => {
  const { pages, isFormEnabled } = useProductUpdate();
  const { watch } = useFormContext();
  const watchParcelTypeId = watch(
    `pages[${pages.activeIndex}].fields.parcel_type_id`,
  );

  const [first, second] = useMemo(() => {
    const addStockLayout = pages?.active?.category?.template?.layout;

    if (
      !addStockLayout ||
      (typeof addStockLayout !== 'string' &&
        Object.prototype.toString.call(addStockLayout) === '[object Array]')
    ) {
      return [[], []];
    }
    const fields = pages.active?.category?.template?.fields ?? [];

    return Object.values(addStockLayout).map((layout) =>
      convertJsonLayoutToTemplateFields({
        layout,
        fields,
      }),
    );
  }, [pages.active?.category?.template]);

  const SECTIONS = {
    first: { component: FirstSection, rows: first },
    second: { component: SecondSection, rows: second },
  };

  const SectionComponent = SECTIONS[section].component;

  if (typeof pages.activeIndex === 'undefined') {
    return <></>;
  }

  return (
    <SectionComponent
      rows={SECTIONS[section].rows}
      prefix={`pages[${pages.activeIndex}].fields.`}
      component="component"
      disabled={!isFormEnabled}
      rangeToSingle={Number(watchParcelTypeId) === PARCEL_TYPE_PER_DIAMOND_ID}
    />
  );
};

export default TemplateFields;
