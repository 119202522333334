/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory, Route, Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { ProductBase } from '@wgt/types';
import { Button, Line, Title } from '../../../../components';
import { useQuery } from '@apollo/client';
import Loader from '../../../../components/_organisms/Loader';
import { PRODUCT_DETAIL_GQL } from './graphql';
import TemplateFields from './TemplateFields';
import ProductPageIdNavigator from './Components/PageIdNavigator';
import CardLayoutText from '../../../../components/CardLayoutText';
import ProductTotals from './Components/ProductTotals';
import OfficeCard from './Components/OfficeCard';
import './styles.scss';
import MovePageIDToCurrentSku from '../ProductPut/MovePageIDToCurrentSku';
import WorkOrder from '../../../../components/WorkOrder';
import { WorkOrderProvider } from '../../../../components/WorkOrder/useWorkOrderContext';
import WorkOrderButton from './WorkOrderButton';
import MediaCenter from '../Update/MediaCenter';
import TraceDocuments from '../../../../components/TraceDocuments';

export default function Views(): JSX.Element {
  const params = useParams<{ productId: string }>();

  const { t } = useTranslation(['product', 'common']);
  const history = useHistory();

  const { data, loading } = useQuery<{ product: ProductBase }>(
    PRODUCT_DETAIL_GQL,
    {
      variables: {
        id: params.productId,
      },
    },
  );

  useEffect(() => {
    if (!data?.product?.pages?.length || location.pathname.match(/pages/gi)) {
      return;
    }

    const firstPage = data?.product.pages[0];
    history.push(`/crm/products/${params.productId}/pages/${firstPage.id}`);
  }, [location.pathname, data?.product]);

  const pageId = location.pathname.substring(
    location.pathname.lastIndexOf('/') + 1,
  );

  return (
    <div className="update-product-screen">
      {loading && <Loader />}
      {!loading && (
        <>
          <Row className="py-4">
            <Col
              xs="12"
              sm={{ size: 8, order: 0 }}
              xl={{ size: 4, order: 0 }}
              className="d-flex align-items-center pr-0"
            >
              <Title heading="h1" className="mr-5">
                {data?.product.category.name}
              </Title>
            </Col>

            <Col
              xs="12"
              sm={{ size: 12, order: 2 }}
              md={{ size: 6, order: 2 }}
              xl={{ size: 3, order: 1 }}
              className="d-flex align-items-center justify-content-center px-0 mt-4 m-xl-0"
            >
              <Route
                path="/crm/products/:productId/pages/:pageId"
                component={ProductPageIdNavigator}
              />
            </Col>
            <Col
              xs="12"
              sm={{ size: 12, order: 3 }}
              md={{ size: 6, order: 2 }}
              xl={{ size: 3, order: 2 }}
              className="d-flex align-items-center justify-content-center d-xl-block mt-4 m-xl-0"
            >
              <MovePageIDToCurrentSku />
            </Col>
            <Col
              xs="12"
              sm={{ size: 4, order: 1 }}
              xl={{ size: 2, order: 3 }}
              className="d-flex align-items-center justify-content-end mt-4 m-sm-0"
            >
              <Link
                className="btn btn-primary ml-3"
                to={`/crm/categories/${data?.product.category.id}/products/create`}
              >
                {t('Add')}
              </Link>
              <Link
                className="btn btn-primary ml-3"
                to={`/crm/categories/${data?.product.category.id}/products/${params.productId}/pages/${pageId}`}
              >
                {t('Edit')}
              </Link>
              <Link
                className="btn btn-primary ml-3"
                to={`/crm/search/categories/${data?.product.category.id}/products`}
              >
                {t('Return')}
              </Link>
            </Col>
          </Row>
          <MediaCenter />
          <WorkOrderProvider productId={data?.product.id}>
            <>
              <Row className="mt-4">
                <Col
                  xs="9"
                  md="3"
                  lg="5"
                  xl="6"
                  className="d-flex flex-row align-items-end justify-content-end justify-content-md-start"
                >
                  <WorkOrderButton pageIdActive={data?.product?.pages?.[0]} />
                </Col>

                <Col
                  xs="3"
                  md="2"
                  lg="2"
                  xl="2"
                  className="d-flex flex-row align-items-end justify-content-end pr-md-0"
                >
                  <Button type="secondary">{t('Calendar')}</Button>
                </Col>

                <Col
                  xs="12"
                  md="7"
                  lg="5"
                  xl="4"
                  className="d-flex flex-row align-items-end justify-content-end pl-0 mt-4 mt-md-0"
                >
                  <div className="mx-3">
                    <p className="text-trace">
                      {!!data?.product.id && (
                        <TraceDocuments productId={data?.product.id} />
                      )}
                    </p>
                  </div>
                  <div className="pos-actions">
                    <p className="font-regular mb-2">{t('Point of Sales')}</p>
                    <div className="pos-buttons">
                      <Button>{t('Sold')}</Button>
                      <Button>{t('Consignment')}</Button>
                    </div>
                  </div>
                </Col>
              </Row>

              <WorkOrder />
            </>
          </WorkOrderProvider>

          <div className="common-attributes mt-4">
            <Row>
              <Col lg={4} className="d-flex flex-column">
                <CardLayoutText
                  content={[
                    { header: t('Search name'), content: data?.product.name },
                  ]}
                />

                <Row className="mt-auto">
                  <Col
                    className="d-flex flex-row pt-4 justify-content-between"
                    xs={12}
                  >
                    <ProductTotals productId={params.productId} />
                  </Col>
                </Row>
              </Col>

              <Col lg={8} className="mt-4 mt-lg-0">
                <CardLayoutText
                  content={[
                    {
                      header: t('Description'),
                      content: data?.product.description,
                    },
                  ]}
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg={10}>
                <CardLayoutText
                  content={[
                    { header: t('SKU'), content: data?.product.sku },
                    { header: t('Lot ID'), content: data?.product.lot_id },
                    { header: t('Tray ID'), content: data?.product.tray_id },
                    {
                      header: t('Crate IDs'),
                      content: data?.product?.crates
                        ?.map((item: any) => item.name)
                        .join(', '),
                    },
                    {
                      header: t('Box IDs'),
                      content: data?.product?.boxes
                        ?.map((item: any) => item.name)
                        .join(', '),
                    },
                  ]}
                />
              </Col>
              <Col lg={2} className="mt-4 mt-lg-0">
                <CardLayoutText
                  content={[
                    {
                      header: t('Status'),
                      content: data?.product.productStatus?.name,
                    },
                  ]}
                />
              </Col>
            </Row>

            <Route
              path="/crm/products/:productId/pages/:pageId?"
              component={TemplateFields}
            />

            <div className="mt-4">
              <Title heading="h2">Office</Title>
              <Line />
              <Row>
                <Col xs={4}>
                  <div className="d-flex justify-content-between">
                    <OfficeCard value={data?.product?.office} />
                  </div>
                </Col>
              </Row>
            </div>

            <div className="mt-5 d-flex justify-content-end">
              <Link
                className="btn btn-outline-primary"
                to={`/crm/categories/${data?.product.category.id}/products/${params.productId}/pages/${pageId}`}
              >
                {t('Edit')}
              </Link>
              <Link
                className="btn btn-primary ml-3"
                to={`/crm/search/categories/${data?.product.category.id}/products`}
              >
                {t('Return')}
              </Link>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
