import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useDrop } from 'react-dnd';
import { MediaCenterController } from '../../../../../../../hooks/useMediaCenterController';
import { useBagContext } from '../../../../../../../services/bag';
import Point from './Point';
import BackgroundSelector from '../../BackgroundSelector';
import { LIST_TAGS } from './graphql';
import InclusionTagsComponent from './Tags';
import { UPDATE_FILE } from '../../graphql';
import { MediaCenterFileType } from '@wgt/types';

export interface MediaContentProps {
  type: MediaCenterFileType;
}
export default function Content({ type }: MediaContentProps): JSX.Element {
  const {
    media: { active, selectBackground },
    productId,
  } = useBagContext<MediaCenterController>();
  const [update] = useMutation(UPDATE_FILE);

  const container = React.useRef<HTMLDivElement | null>(null);

  const { data } = useQuery(LIST_TAGS, {
    skip: !productId,
    variables: {
      filter: {
        product_id: productId,
      },
    },
  });

  const onUpdate = (background: string) => {
    update({
      variables: {
        id: Number(active?.id),
        file: { background, type },
      },
    });
    selectBackground(background);
  };

  const [, drop] = useDrop({
    accept: 'tag',
    drop: (_, monitor) => {
      const clientOffset = {
        initial: monitor.getInitialSourceClientOffset(),
        final: monitor.getSourceClientOffset(),
      };
      const dropTargetPosition = container.current?.getBoundingClientRect();
      if (!dropTargetPosition) {
        return;
      }

      const { x: initialX, y: initialY } = clientOffset.initial ?? {};
      const { x: finalX, y: finalY } = clientOffset.final ?? {};

      if (!finalY || !finalX || !initialX || !initialY) {
        return;
      }

      const y =
        finalY > initialY
          ? initialY + (finalY - initialY) - dropTargetPosition.top
          : initialY - (initialY - finalY) - dropTargetPosition.top;

      const x =
        finalX > initialX
          ? initialX + (finalX - initialX) - dropTargetPosition.left
          : initialX - (initialX - finalX) - dropTargetPosition.left;

      return { clientOffset, x, y };
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <>
      <BackgroundSelector active={active} update={onUpdate} />
      <div className="media-center-content-holder">
        {active && (
          <>
            <div className="content-side" />
            <div className="media-center-content-image">
              <div
                ref={(e) => {
                  drop(e);
                  container.current = e;
                }}
                style={{
                  backgroundImage: `url('${active.file_url}')`,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center center',
                  height: '400px',
                  width: '400px',
                  backgroundColor: 'green',
                }}
              >
                {active?.productInclusionTags?.map((productInclusion) => (
                  <Point
                    type={type}
                    inclusion={productInclusion}
                    key={`point-${productInclusion.position_x}-${productInclusion.position_y}`}
                  />
                ))}
              </div>
            </div>
            <div className="content-side">
              <InclusionTagsComponent
                inclusionTags={data?.inclusionTags?.data ?? []}
                type={type}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}
