import { useMutation } from '@apollo/client';
import React, { useState, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import classnames from 'classnames';
import { FaTrash } from 'react-icons/fa';
import { Button } from '../../../../../../components';
import ConfirmDialog from '../../../../../../components/_molecules/ConfirmDialog';
import { useBagContext } from '../../../../../../services/bag';
import { DELETE_PRODUCT_CRATE_ID } from '../../../graphql';
import { CrateId } from '@wgt/types';
import useToast from '../../../../../../hooks/useToast';
import { useTranslation } from 'react-i18next';

interface CrateItemProps {
  crate: CrateId;
  index: number;
}

export default function CrateItem({
  crate,
  index,
}: CrateItemProps): JSX.Element {
  const { register, errors } = useFormContext();
  const { toast } = useToast();

  const {
    crate: { fields, remove },
  } = useBagContext<{
    crate: { fields: CrateId[]; remove(i: number): void };
  }>();
  const { t } = useTranslation('product');
  const [removeFn, { loading: removing }] = useMutation(
    DELETE_PRODUCT_CRATE_ID,
    {
      variables: { id: crate.id },
      onCompleted: () => {
        toast('success', 'Crate ID successfully deleted.');
        remove(index);
      },
    },
  );
  const [showRemoveConfirm, setShowRemoveConfirm] = useState<boolean>(false);

  const onConfirm = useCallback(async () => {
    if (crate.id?.toString().match(/^\d+$/)) {
      await removeFn();
    } else {
      remove(index);
    }
    setShowRemoveConfirm(false);
  }, [crate, index]);

  const onClickRemove = (e: React.MouseEvent) => {
    e.preventDefault();
    setShowRemoveConfirm(true);
  };

  return (
    <>
      <div
        className={classnames('d-flex align-items-center justify-content-end', {
          'mt-2': index > 0,
        })}
      >
        <input
          type="hidden"
          name={`crates[${index}].id`}
          ref={register()}
          defaultValue={crate.id}
        />
        <input
          name={`crates[${index}].name`}
          ref={register({ required: fields.length > 1 })}
          defaultValue={crate.name}
          className={classnames('form-control w-100', {
            'is-invalid': errors.crates?.[index]?.name,
          })}
        />

        <Button
          htmlType="button"
          disabled={removing}
          onClick={onClickRemove}
          small
          type="danger"
          className="ml-2"
        >
          <FaTrash />
        </Button>
      </div>
      <ConfirmDialog
        isShown={showRemoveConfirm}
        onConfirm={onConfirm}
        title={t('Are you sure you want to delete Carte Id?')}
        onClose={() => setShowRemoveConfirm(false)}
        isLoading={removing}
      />
    </>
  );
}
