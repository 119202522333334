import React, { useMemo, useState } from 'react';
import { Pane, Popover, Small, Spinner } from 'evergreen-ui';
import { FormGroup, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import usePointOfSaleUpdate, { PAYMENT_OPTIONS } from '../usePointOfSaleUpdate';

export default function IncludeInPayment(): JSX.Element {
  const [popoverField, setPopoverField] = useState<boolean>(false);
  const { t } = useTranslation(['product', 'common']);
  const { pointOfSale, update, isUpdating } = usePointOfSaleUpdate();

  const { register, handleSubmit, getValues } = useForm({
    defaultValues: pointOfSale,
  });

  const selectedTypes = useMemo<string>(() => {
    const selecteds = PAYMENT_OPTIONS.filter(
      (x) => pointOfSale[x.field],
    ).map((x) => t(x.label));

    if (selecteds.length > 2) {
      return selecteds.slice(0, 2).join(', ') + '...';
    }

    return selecteds.join(', ');
  }, [pointOfSale]);

  const onSubmit = handleSubmit((data) => {
    const hasChange = Object.keys(data).reduce((acc, key) => {
      if (data[key] !== pointOfSale[key]) {
        acc = true;
      }
      return acc;
    }, false);

    if (!hasChange) {
      return;
    }
    update({
      variables: {
        id: pointOfSale.id,
        point_of_sale: data,
      },
    });
    setPopoverField(false);
  });

  return (
    <FormGroup className="simple-input mb-0">
      <Label for="fields">{t('common:Field')}</Label>
      <Popover
        onCloseComplete={onSubmit}
        onOpen={() => setPopoverField(true)}
        content={
          <Pane display="flex" flexDirection="column" margin={5} padding={5}>
            <Pane marginY={5}>
              <Small fontSize={10}>Include in payment</Small>
            </Pane>
            {PAYMENT_OPTIONS.map(({ field, label }) => (
              <Pane key={field} justifyContent="space-between" display="flex">
                <label htmlFor={field}>{t(label)}</label>
                <input
                  type="checkbox"
                  ref={register}
                  name={field}
                  id={field}
                  defaultChecked={!!getValues(field)}
                />
              </Pane>
            ))}
          </Pane>
        }
      >
        <button
          type="button"
          className="btn button combobox button-input-popover-field"
          disabled={isUpdating}
        >
          <div className="d-flex justify-content-between w-100">
            <div style={{ whiteSpace: 'nowrap', overflowX: 'hidden' }}>
              {isUpdating ? (
                <Spinner size={12} />
              ) : selectedTypes ? (
                selectedTypes
              ) : (
                'Select'
              )}
            </div>
            <div>{popoverField ? <FiChevronUp /> : <FiChevronDown />}</div>
          </div>
        </button>
      </Popover>
    </FormGroup>
  );
}
