import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Row, Col, Form } from 'reactstrap';
import { Button, SimpleInput } from '../../../../components';
import { CreateFormProps } from '../types';
import BtnLoading from '../../../../components/_molecules/BtnLoading';

const FormTemplate: React.FC<CreateFormProps> = ({
  onSubmit,
  isSubmitting,
}) => {
  const methods = useForm();
  const { errors } = methods;
  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(onSubmit)}>
        <Row>
          <Col md={4} sm={12} xs={4}>
            <SimpleInput
              required
              field="name"
              label="Template Name"
              hint={errors?.name?.message ?? ''}
            />
          </Col>
          <Col md={4} sm={12} xs={4}>
            <SimpleInput
              field="description"
              label="Description"
              hint={errors?.description?.message ?? ''}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col>
            <Button htmlType="submit">
              {isSubmitting ? <BtnLoading /> : 'Save'}
            </Button>
          </Col>
        </Row>
      </Form>
    </FormProvider>
  );
};

export default FormTemplate;
