/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef } from 'react';
import { PropertyValue } from '@wgt/types';
import { Avatar, IconButton } from 'evergreen-ui';
import { useDrag, useDrop } from 'react-dnd';
import { FaTrash } from 'react-icons/fa';
import { FiEdit } from 'react-icons/fi';
import { useParams, useHistory } from 'react-router-dom';
import useCrmRoutes from '../../../../../../hooks/useCrmRoutes';

interface ValuesTableItemProps {
  propertyValue: PropertyValue;
  setDeleteItem: React.Dispatch<React.SetStateAction<PropertyValue | null>>;
  handleSort: any;
}

export default function ValuesTableItem({
  propertyValue,
  setDeleteItem,
  handleSort,
}: ValuesTableItemProps): JSX.Element {
  const element = useRef<HTMLTableRowElement>(null);

  const { actualRoute } = useCrmRoutes();
  const history = useHistory();
  const params = useParams<ParamTypes>();

  const [, drag] = useDrag({
    item: {
      type: 'propertyValue',
      value: propertyValue,
    },
    end: (item, monitor) => {
      const result = monitor.getDropResult();

      if (!result) {
        return;
      }
      const { dragged, droppedAt } = result;

      handleSort({ id: dragged.id, position: droppedAt.position ?? 1 });
    },
  });
  const [, drop] = useDrop({
    accept: 'propertyValue',
    drop: (dragged: any) => ({
      dragged: dragged.value,
      droppedAt: propertyValue,
    }),
  });

  drag(drop(element));

  return (
    <>
      <tr ref={element}>
        <td>
          <div className="property-value-image">
            {propertyValue.name}
            {propertyValue.image && (
              <Avatar src={propertyValue.image} size={50} />
            )}
            {propertyValue.options?.color && (
              <div
                style={{
                  backgroundColor: propertyValue.options?.color,
                  height: '20px',
                  width: '20px',
                }}
              />
            )}
          </div>
        </td>
        <td>{propertyValue.abbreviations?.join(', ')}</td>
        <td>{propertyValue.key}</td>
        <td className="edit-col">
          <div className="d-flex justify-content-end align-items-center">
            <IconButton
              appearance="minimal"
              className="mx-1"
              onClick={() =>
                history.push(
                  `/crm/${actualRoute}/${params.id}/edit/property-values/${propertyValue.id}`,
                )
              }
              icon={FiEdit}
            />
            <IconButton
              appearance="minimal"
              intent="danger"
              className="mx-1 my-0 p-0"
              icon={FaTrash}
              onClick={() => setDeleteItem(() => propertyValue)}
            />
          </div>
        </td>
      </tr>
    </>
  );
}
