import { gql } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { FaTag } from 'react-icons/fa';
import classnames from 'classnames';
import useFakeQuery from '../../hooks/useFakeQuery';
import './styles.scss';
interface StoragePrice {
  id: number;
  amount: number;
  icon: 'up' | 'down';
  description: string;
}

interface FakeQuery {
  storagePrices: StoragePrice[];
  userStorage: { id: number };
}

const defaultValue: FakeQuery = {
  storagePrices: [
    {
      id: 1,
      amount: 0,
      icon: 'up',
      description: '0 to 30 GigaByte Free',
    },
    {
      id: 2,
      amount: 20,
      icon: 'down',
      description: '100 GigaByte',
    },
    {
      id: 3,
      amount: 30,
      icon: 'down',
      description: '1 TeraByte',
    },
  ],
  userStorage: { id: 1 },
};

export function StoragePrice({
  storageItem,
  selected,
}: {
  storageItem: StoragePrice;
  selected?: boolean;
}): JSX.Element {
  const { t } = useTranslation('storage');

  return (
    <div className="storage-price-item">
      <div className="d-flex align-items-center">
        <FaTag className={classnames({ selected })} />
        <div className="price-container ml-3 flex-1">{storageItem.amount}</div>
      </div>
      <div className="price-icon-holder mt-5 mb-5 d-flex align-items-center justify-content-center">
        <div
          className={classnames(
            'price-icon-content d-flex  justify-content-center p-2',
            {
              'align-items-start': storageItem.icon === 'up',
              'align-items-end': storageItem.icon === 'down',
            },
          )}
        >
          <div
            className={classnames(
              'price-icon-content-holder d-flex justify-content-center',
              {
                [storageItem.icon]: true,
              },
            )}
          >
            <div className="price-icon-content-line" />
          </div>
        </div>
      </div>
      {t(storageItem.description)}
    </div>
  );
}

const StoragePrices: React.FC = () => {
  const { data } = useFakeQuery<FakeQuery>(
    gql`
      query storagePrices {
        storagePrices {
          amount
          icon
          desciption
        }
      }
    `,
    { defaultValue },
  );
  return (
    <div className="m-5 mt-0">
      <Row>
        {data?.storagePrices?.map((storagePrice, key) => (
          <Col key={`storage-price-${key}`}>
            <StoragePrice
              storageItem={storagePrice}
              selected={data.userStorage?.id === storagePrice.id}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default StoragePrices;
