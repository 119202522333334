import React, { useState } from 'react';
import { Button } from 'evergreen-ui';
import { useTranslation } from 'react-i18next';
import { NavbarPageBase } from '@wgt/types';
import { FaChevronDown } from 'react-icons/fa';
import SidebarPane, { SidebarPaneItem } from '../../SidebarPane';
import FirmLogo from '../../../assets/wgt-firm-logo.png';
import { FIRM_CONTROL_PAGES } from '../../../utils/navbarPages';
import ComingSoonModals from '../../ComingSoonModals';
import ManageAlert from '../../../pages/Crm/LearnToUse/ManageAlert';

const NavbarFirmControls: React.FC = () => {
  const { t } = useTranslation('menus');
  const [isSidebarShown, setSidebarShown] = useState(false);
  const [isShownComingSoonModule, setShownComingSoonModal] = useState<boolean>(
    false,
  );
  const [openSidebarLearnToUse, setOpenSidebarLearnToUse] = useState<boolean>(
    false,
  );

  const handleClick = (menuItem: NavbarPageBase) => {
    if (menuItem.comingSoon) {
      setShownComingSoonModal(!!menuItem.name);
    } else if (menuItem.name === 'learn-to-use') {
      setOpenSidebarLearnToUse(true);
    }
    return undefined;
  };

  return (
    <>
      <Button
        className="firm-dropdown-menu"
        onClick={() => setSidebarShown(true)}
      >
        <div className="navbar-avatar">
          <div>
            <img src={FirmLogo} alt={t('Firm controls')} />
          </div>
          <span>{t('Firm controls')}</span>
          <FaChevronDown className="ml-2" />
        </div>
      </Button>
      <SidebarPane
        isShown={isSidebarShown}
        setShown={setSidebarShown}
        size={350}
        closeText={t('Close Menu')}
        className="sidebar-mega-menu"
      >
        {FIRM_CONTROL_PAGES.map((menuItem, key) => (
          <SidebarPaneItem
            key={`menu-firm-${key}`}
            {...menuItem}
            {...(menuItem.isOnClick && {
              onClick: () => handleClick(menuItem),
            })}
          />
        ))}
      </SidebarPane>

      <SidebarPane
        isShown={openSidebarLearnToUse}
        setShown={setOpenSidebarLearnToUse}
        size={850}
        header={t('Learn to Use Manual')}
      >
        <ManageAlert />
      </SidebarPane>

      <ComingSoonModals
        isOpen={isShownComingSoonModule}
        setOpen={setShownComingSoonModal}
      />
    </>
  );
};

export default NavbarFirmControls;
