import React, { useState } from 'react';
import classnames from 'classnames';
import { IconButton } from 'evergreen-ui';
import { FiEdit } from 'react-icons/fi';
import { useFormContext } from 'react-hook-form';
import { Form } from 'reactstrap';
import { PointOfSaleBase } from '@wgt/types';
import { SimpleInput, Text } from '../../../../../../components';
import useCurrency from '../../../../../../hooks/useCurrency';

interface Props {
  operator?: string;
  description: string;
  className?: string;
  isEditable?: boolean;
  field: string;
}

const Value: React.FC<Props> = ({
  operator,
  description,
  className,
  isEditable,
  field,
}) => {
  const { convertAmount } = useCurrency();
  const [isEditing, setEditing] = useState<boolean>(false);
  const { getValues, watch } = useFormContext<PointOfSaleBase>();
  const value = watch(field, 0);

  return (
    <div
      className={classnames('d-flex flex-row justify-content-end', className)}
    >
      <div className="text-right">
        <div className="d-flex align-items-center justify-content-end mb-0">
          {isEditable && !isEditing && (
            <IconButton
              appearance="minimal"
              icon={FiEdit}
              iconSize={15}
              className="iconUpdate"
              onClick={() => setEditing(true)}
            />
          )}
          {isEditing ? (
            <Form
              onSubmit={(e) => [
                e.stopPropagation(),
                e.preventDefault(),
                setEditing(false),
              ]}
            >
              <SimpleInput
                type="number"
                field={field}
                defaultValue={getValues(field) ?? '0'}
              />
            </Form>
          ) : (
            <Text className="amount ml-0">{convertAmount(value)} USD</Text>
          )}
        </div>
        <Text type="muted">{description}</Text>
      </div>

      <div className="d-flex align-items-center justify-content-end operator">
        <Text>{!!operator && operator}</Text>
      </div>
    </div>
  );
};

export default Value;
