import React from 'react';
import { Row, Col, FormGroup, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import { SimpleInput, Button } from '../../';
import Compound from './Compound';
import NotCompound from './NotCompound';
import { useFormContext } from 'react-hook-form';

export default function CalculateInterest(): JSX.Element {
  const { t } = useTranslation('pointOfSales');
  const { setValue, watch } = useFormContext();

  const compoundInterestDate = watch('compound_interest_date');

  return (
    <div className="mb-4">
      <Row>
        <Col xs="6">
          <FormGroup className="simple-input">
            <Label
              htmlFor="compound_interest_date"
              className="label-calculate-interest"
            >
              {t('Interest after')}
            </Label>
            <DatePicker
              dateFormat="MMMM d, yyyy"
              selected={compoundInterestDate}
              onChange={(date: Date) =>
                setValue('compound_interest_date', date)
              }
              className="form-control"
              id="compound_interest_date"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col xs="4">
          <Compound />
        </Col>
        <Col xs="4" className="px-0">
          <NotCompound />
        </Col>
        <Col xs="4" className="d-flex align-items-center">
          <div className="pr-2">%</div>
          <SimpleInput
            type="number"
            field="compound_interest_value"
            defaultValue="0"
            required
            className="text-right"
          />
        </Col>
      </Row>
      <div className="text-right mt-3">
        <Button small>{t('SAVE interest')}</Button>
      </div>
    </div>
  );
}
