import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { ADD_STOCK_GQL } from '../graphql';
import { useQuery } from '@apollo/client';
import Loader from '../../../../components/_organisms/Loader';
import { ProductQueryType } from '../types';

import './style.scss';
import ComponentsContent from './ComponentsContent';
import useCrmRoutes from '../../../../hooks/useCrmRoutes';
import useLoggedContext from '../../../../hooks/useLoggedContext';
import { MovePageIDToSKUProvider } from '../../../../components/MovePageIDToSKU';

const Update: React.FC = () => {
  const params = useParams<ParamTypes>();
  const { setProductRoutes } = useCrmRoutes();
  const { active } = useLoggedContext();
  const ADD_STOCK_FILTERS = useMemo(
    () => ({
      id: params.id,
      officeFilter: active ? { firm_id: [active.id] } : null,
    }),
    [active, params],
  );
  const { data, loading } = useQuery<ProductQueryType>(ADD_STOCK_GQL, {
    skip: !params.id,
    variables: ADD_STOCK_FILTERS,
    onCompleted: ({ product }) => setProductRoutes(product),
  });

  return (
    <MovePageIDToSKUProvider
      refetchQueries={[{ query: ADD_STOCK_GQL, variables: ADD_STOCK_FILTERS }]}
      source={data?.product.pages ?? []}
    >
      {!data?.product || loading ? (
        <Loader />
      ) : (
        <ComponentsContent
          product={data.product}
          filters={ADD_STOCK_FILTERS}
          parcelTypes={data?.parcelTypes?.data ?? []}
        />
      )}
    </MovePageIDToSKUProvider>
  );
};

export default Update;
