import { InvoiceFull, TransactionType } from '@wgt/types';
import {
  OperationVariables,
  QueryLazyOptions,
  useLazyQuery,
} from '@apollo/client';
import moment from 'moment';
import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { INVOICES, TRANSACTION_TYPES } from './graphql';
import useFakeQuery from '../../../hooks/useFakeQuery';

export interface StatementsFilters {
  term?: string;
  created_at: { from?: string; to?: string };
  transactionTypes: TransactionType[];
}

export interface InvoiceResult extends InvoiceFull {
  date: string;
  transaction: string;
}

interface StatementsContextData {
  onSubmit: SubmitHandler<StatementsFilters>;
  results?: InvoiceResult[];
  searching?: boolean;
  activeInvoice?: InvoiceResult;
  setActiveInvoice: Dispatch<SetStateAction<InvoiceResult | undefined>>;
  activeInvoicePayments?: InvoiceResult;
  setActiveInvoicePayments: Dispatch<SetStateAction<InvoiceResult | undefined>>;
  transactionTypes: TransactionType[];
  fetch: (options?: QueryLazyOptions<OperationVariables> | undefined) => void;
}

export const StatementsContext = React.createContext<StatementsContextData>(
  {} as StatementsContextData,
);

export const StatementsProvider: React.FC = ({ children }) => {
  const [activeInvoice, setActiveInvoice] = useState<
    InvoiceResult | undefined
  >();
  const [activeInvoicePayments, setActiveInvoicePayments] = useState<
    InvoiceResult | undefined
  >();

  const { data: transactionTypesData } = useFakeQuery<{
    transactionTypes: { data: TransactionType[] };
  }>(TRANSACTION_TYPES, {
    defaultValue: {
      transactionTypes: {
        data: [
          { id: 1, name: 'Broker', description: 'middle action' },
          { id: 2, name: 'Consignment Sent', description: 'in custody' },
          {
            id: 3,
            name: 'Consignment Received',
            description: 'return custody',
          },
          { id: 4, name: 'Consignment Return', description: 'return custody' },
          { id: 5, name: 'Data Storage', description: 'wgt service' },
          { id: 6, name: 'Purchase', description: 'received from seller' },
          { id: 7, name: 'Purchase Return', description: 'retun to seller' },
          { id: 8, name: 'Sold', description: 'sent to buyer' },
          { id: 9, name: 'Sold Return', description: 'return from buyer' },
          { id: 10, name: 'Storage', description: 'wgt service' },
          { id: 11, name: 'Website', description: 'wgt service' },
          { id: 12, name: 'Website Application', description: 'wgt service' },
          { id: 13, name: 'Work Order', description: 'service' },
          { id: 14, name: 'Work Order Return ', description: 'service' },
        ],
      },
    },
  });

  const [fetch, { data, loading }] = useLazyQuery<{
    invoices: { data: InvoiceResult[] };
  }>(INVOICES);

  const onSubmit = (variables: StatementsFilters) => {
    const filter = {
      _term: variables.term?.length ? variables.term : undefined,
      // Backend is not ready for this filter yet. comments must stay here until backend finishes this feature
      // transactionTypes: variables.transactionTypes?.length
      //   ? variables.transactionTypes.map(
      //       (transactionType) => transactionType.id,
      //     )
      //   : undefined,
      created_at:
        variables.created_at?.from || variables.created_at?.to
          ? {
              from: variables.created_at?.from
                ? moment(variables.created_at.from).format('YYYY-M-D HH:mm:ss')
                : undefined,
              to: variables.created_at?.to
                ? moment(variables.created_at.to).format('YYYY-M-D HH:mm:ss')
                : undefined,
            }
          : undefined,
    };
    fetch({ variables: { filter } });
  };

  const contextValues: StatementsContextData = {
    onSubmit,
    results: data?.invoices.data ?? [],
    searching: loading,
    activeInvoice,
    setActiveInvoice,
    transactionTypes: transactionTypesData?.transactionTypes.data ?? [],
    activeInvoicePayments,
    setActiveInvoicePayments,
    fetch,
  };

  return (
    <StatementsContext.Provider value={contextValues}>
      {children}
    </StatementsContext.Provider>
  );
};

export default function useStatementsContext(): StatementsContextData {
  return useContext(StatementsContext);
}
