import React, { useMemo, useEffect } from 'react';
import CustomHeader from '../../../../components/_molecules/CustomHeader';
import { useParams, useHistory } from 'react-router-dom';
import useCrmRoutes from '../../../../hooks/useCrmRoutes';
import Form from './Form';
import { SubmitHandler } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import {
  CREATE_FIRM_GQL,
  GET_FIRM_GQL,
  UPDATE_FIRM_GQL,
  GET_PUT_SCREEN_DATA_GQL,
  LIST_FIRMS_GQL,
} from '../graphql';
import Loader from '../../../../components/_organisms/Loader';
import useToast from '../../../../hooks/useToast';
import { Firm } from '@wgt/types';

const Put: React.FC = () => {
  const history = useHistory();
  const params = useParams<ParamTypes>();
  const { setCustomTitle, actualRoute } = useCrmRoutes();
  const { toast } = useToast();
  const [createFirm, { loading, data }] = useMutation(CREATE_FIRM_GQL, {
    refetchQueries: [{ query: LIST_FIRMS_GQL }],
  });
  const [updateFirm, { loading: updating, data: updated }] = useMutation(
    UPDATE_FIRM_GQL,
    {
      refetchQueries: [{ query: LIST_FIRMS_GQL }],
    },
  );
  const { loading: fetchingScreenData, data: screenData } = useQuery(
    GET_PUT_SCREEN_DATA_GQL,
  );

  const { loading: fetchingFirm, data: editData } = useQuery(GET_FIRM_GQL, {
    skip: !params?.id,
    variables: {
      id: params.id,
    },
  });

  const isEdit = useMemo(() => {
    return params?.id;
  }, [params]);

  const isLoading = useMemo(() => {
    return loading || fetchingFirm || updating || fetchingScreenData;
  }, [loading, fetchingFirm, updating, fetchingScreenData]);

  useEffect(() => {
    setCustomTitle(`${isEdit ? 'Edit' : 'Create'} Firm`);
  }, []);

  useEffect(() => {
    if (data?.createFirm?.id) {
      toast('success');
      history.push(`/crm/${actualRoute}/${data.createFirm.id}`);
    }
  }, [data]);

  useEffect(() => {
    if (updated?.updateFirm?.id) {
      toast('success');
      history.push(`/crm/${actualRoute}/${updated.updateFirm.id}`);
    }
  }, [updated]);

  const onSubmit: SubmitHandler<Firm> = async ({
    extra: { locale, timezone, currency, ...extra },
    address: { country, ...address },
    ...variables
  }) => {
    const dataFirm = {
      ...variables,
      types: variables.types.map((x) => Number(x.id)),
      discount: variables.discount
        ? parseInt(variables.discount.toString(), 10)
        : null,
      address: {
        ...address,
        country_id: country?.id ? Number(country?.id.toString()) : undefined,
      },
      extra: {
        ...extra,
        timezone_id: timezone?.id ? Number(timezone.id) : undefined,
        locale_id: locale?.id ? Number(locale?.id) : undefined,
        currency_id: currency?.id ? Number(currency?.id) : undefined,
        logo:
          typeof extra.logo === 'string' || extra.logo === null
            ? undefined
            : extra.logo,

        discount_fee: extra.discount_fee
          ? parseInt(extra.discount_fee.toString(), 10)
          : null,
        as_discount_fee: extra.as_discount_fee
          ? parseInt(extra.as_discount_fee.toString(), 10)
          : null,
        default_association: extra.default_association
          ? parseInt(extra.default_association.toString(), 10)
          : null,
      },
    };

    if (isEdit) {
      await updateFirm({
        variables: {
          firm: dataFirm,
          id: params.id,
        },
      });
    } else {
      await createFirm({
        variables: dataFirm,
      });
    }
  };
  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <CustomHeader title={`${isEdit ? 'Edit' : 'Create'}`} showList />
      <Form
        onSubmit={onSubmit}
        isSubmitting={loading}
        data={editData?.firm}
        types={screenData?.firmTypes?.data || []}
      />
    </div>
  );
};

export default Put;
