import React, { useContext, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { TemplateLayoutContext } from '../../../../../../hooks/useTemplateLayout';
import { Button, Title } from '../../../../../../components';
import Column1 from '../../../../../../assets/1-column.png';
import Column2 from '../../../../../../assets/2-columns.png';
import Column3 from '../../../../../../assets/3-columns.png';
import Column4 from '../../../../../../assets/4-columns.png';
import { SectionStringType } from '@wgt/types';

const layoutProps = {
  md: '3',
  lg: ' 3',
};
interface AddNewLayoutStructureProps {
  section: SectionStringType;
}

const AddNewLayoutStructure: React.FC<AddNewLayoutStructureProps> = ({
  section,
}) => {
  const [isOpen, setOpen] = useState(false);
  const { onAddNewRow } = useContext(TemplateLayoutContext);
  const handleAddNewRow = (numberOfColumns: number) => {
    const newRowStructure = Array.from({ length: numberOfColumns }, () => ({
      size: (12 / numberOfColumns).toString(),
      group: {
        title: ``,
        content: [],
      },
    }));
    onAddNewRow({ newRowStructure, section });
    setOpen(false);
  };

  return (
    <div>
      {!isOpen ? (
        <div className="d-flex align-items-center justify-content-end border rounded m-3 p-2">
          <Button onClick={() => setOpen(true)}> Add a new Section</Button>
        </div>
      ) : (
        <div className="d-flex flex-column border rounded m-3 p-2">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <Title heading="h4">Select the column number</Title>
            <Button onClick={() => setOpen(false)} small type="secondary">
              Cancel
            </Button>
          </div>

          <Row>
            <Col
              {...layoutProps}
              onClick={() => handleAddNewRow(1)}
              className="cursor-pointer"
            >
              <img src={Column1} />
            </Col>
            <Col
              {...layoutProps}
              onClick={() => handleAddNewRow(2)}
              className="cursor-pointer"
            >
              <img src={Column2} />
            </Col>
            <Col
              {...layoutProps}
              onClick={() => handleAddNewRow(3)}
              className="cursor-pointer"
            >
              <img src={Column3} />
            </Col>
            <Col
              {...layoutProps}
              onClick={() => handleAddNewRow(4)}
              className="cursor-pointer"
            >
              <img src={Column4} />
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default AddNewLayoutStructure;
