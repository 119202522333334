import React from 'react';
import CustomHeader from '../../../../components/_molecules/CustomHeader';

const Result: React.FC = () => {
  return (
    <div>
      <CustomHeader title="Search results" />
    </div>
  );
};

export default Result;
