import React, { useMemo, useEffect } from 'react';
import CustomHeader from '../../../../components/_molecules/CustomHeader';
import { useParams, useHistory } from 'react-router-dom';
import useCrmRoutes from '../../../../hooks/useCrmRoutes';
import Form from './Form';
import { SubmitHandler } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import {
  CREATE_CATEGORY_GROUPS_GQL,
  GET_CATEGORY_GROUPS_GQL,
  LIST_CATEGORY_GROUPS_GQL,
  UPDATE_CATEGORY_GROUPS_GQL,
} from '../graphql';
import Loader from '../../../../components/_organisms/Loader';
import { InputCategoryGroup } from '../types';
import useToast from '../../../../hooks/useToast';

const Put: React.FC = () => {
  const history = useHistory();
  const params = useParams<ParamTypes>();
  const { setCustomTitle, actualRoute } = useCrmRoutes();
  const { toast } = useToast();
  const [create, { loading }] = useMutation(CREATE_CATEGORY_GROUPS_GQL, {
    refetchQueries: [{ query: LIST_CATEGORY_GROUPS_GQL }],
    onCompleted: ({ createCategoryGroup }) => {
      toast('success');
      history.push(`/crm/${actualRoute}/${createCategoryGroup.id}/edit`);
    },
    onError: () => {
      toast('error');
    },
  });

  const [update, { loading: updating }] = useMutation(
    UPDATE_CATEGORY_GROUPS_GQL,
    {
      refetchQueries: [{ query: LIST_CATEGORY_GROUPS_GQL }],
      onCompleted: () => {
        toast('success');
        history.push(`/crm/${actualRoute}`);
      },
      onError: () => {
        toast('error');
      },
    },
  );

  const { loading: fetchingCategoryGroup, data: editData } = useQuery(
    GET_CATEGORY_GROUPS_GQL,
    {
      skip: !params?.id,
      variables: {
        id: params.id,
      },
    },
  );

  const isEdit = useMemo(() => {
    return params?.id;
  }, [params]);

  const isLoading = useMemo(() => {
    return loading || fetchingCategoryGroup || updating;
  }, [loading, fetchingCategoryGroup, updating]);

  useEffect(() => {
    setCustomTitle(`${isEdit ? 'Edit' : 'Create'} Category Group`);
  }, []);

  const onSubmit: SubmitHandler<InputCategoryGroup> = (variables) => {
    if (isEdit) {
      update({
        variables: {
          category_group: variables,
          id: params.id,
        },
      });
    } else {
      create({
        variables,
      });
    }
  };
  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <CustomHeader title={`${isEdit ? 'Edit' : 'Create'}`} showList />
      <Form
        onSubmit={onSubmit}
        isSubmitting={loading}
        data={editData?.categoryGroup}
      />
    </div>
  );
};

export default Put;
