import React, { useMemo } from 'react';
import { CategoryBase, ProductBase } from '@wgt/types';
import SearchResultsTable from '../../../../components/SearchResultsTable';
import Loader from '../../../../components/_organisms/Loader';
import { Paginator } from '../../../../services/graphql/paginator.gql';
import PaginatorInfo from './PaginatorInfo';

interface ResultsProps {
  category?: CategoryBase;
  results: {
    products: ProductBase[];
    paginator: Paginator;
  };
  loading?: boolean;
}

const Results: React.FC<ResultsProps> = ({
  results: { products, paginator },
  loading,
  category,
}) => {
  const headers = useMemo(() => {
    if (!category?.template?.result_header) {
      return [];
    }

    return category.template.result_header;
  }, [category]);
  return (
    <div className="mb-5">
      {loading && (
        <div className="flex-1 m-5 d-flex align-items-center justify-content-center">
          <Loader round />
        </div>
      )}

      {!!products?.length && <PaginatorInfo paginator={paginator} />}
      <SearchResultsTable
        products={products?.length ? products : []}
        headers={headers?.length ? headers : []}
      />
    </div>
  );
};

export default React.memo(Results);
