import React from 'react';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { Form, Row, Col, Container, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Country } from '@wgt/types';
import { SimpleInput, Button } from '../../../../../components';
import SelectSearch from '../../../../../components/SelectSearch';
import { COUNTRIES } from '../../../Firms/graphql';

interface InputEmployee {
  first_name?: string;
  firm_address_1?: string;
  last_name?: string;
  firm_address_2?: string;
  firm_name?: string;
  email?: string;
  state?: string;
  office_phone?: string;
  number?: number;
  firm_city?: string;
  direct_phone?: string;
  postal_code?: string;
  mobile?: string;
  firm_country: { id: number };
}

export default function Content(): JSX.Element {
  const { t } = useTranslation(['hr', 'common']);

  const methods = useForm();

  const { handleSubmit } = methods;

  const onSubmit = (variables: InputEmployee) => {
    const data = {
      ...variables,
      firm_country: variables.firm_country.id,
    };
    console.log({ data });
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Container fluid className="add-employee-form">
          <Row className="border shadow p-4 mt-4">
            <Col lg="6">
              <SimpleInput
                field="first_name"
                label={t('First Name')}
                placeholder={t('eg name')}
              />
            </Col>
            <Col lg="6">
              <SimpleInput
                field="firm_address_1"
                label={`${t('Firm Address')} 1`}
                placeholder={t('eg address Av')}
              />
            </Col>
            <Col lg="6">
              <SimpleInput
                field="last_name"
                label={t('Last Name')}
                placeholder={t('eg name')}
              />
            </Col>
            <Col lg="6">
              <SimpleInput
                field="firm_address_2"
                label={`${t('Firm Address')} 2`}
                placeholder={t('eg address Av')}
              />
            </Col>
            <Col lg="6">
              <SimpleInput
                field="firm_name"
                label={t('Firm Name')}
                placeholder={t('eg name')}
              />
            </Col>
            <Col lg="6">
              <div className="simple-dropdown">
                <Label>{t('Firm Country')}</Label>
                <Controller
                  name="firm_country"
                  defaultValue={methods.getValues('firm_country') ?? ''}
                  render={({ onChange }) => (
                    <SelectSearch
                      gql={COUNTRIES}
                      getOptionLabel={(e: Country) => `${e.name ?? ''} `}
                      getOptionValue={(e: Country) => `${e.id}`}
                      name="name"
                      placeholder={t('eg country')}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
            </Col>
            <Col lg="6">
              <SimpleInput
                field="email"
                label={t('E-mail')}
                placeholder={`${t('eg')} contact@company.com`}
                type="email"
              />
            </Col>
            <Col lg="6">
              <SimpleInput
                field="state"
                label={t('Firm State/Province')}
                placeholder={t('eg state')}
              />
            </Col>
            <Col lg="6" className="input-office-phone">
              <Label>{t('Office Phone')}</Label>
              <div className="d-flex">
                <SimpleInput
                  field="office_phone"
                  placeholder={`${t('eg')} (123) 456-7890`}
                  className="w-75"
                />
                <div className="d-flex">
                  <div className="ml-3 mr-1 align-self-center">
                    <p>{t('Ext')}</p>
                  </div>
                  <SimpleInput
                    field="ext"
                    placeholder={`${t('eg')} 123`}
                    className="w-100"
                    type="number"
                  />
                </div>
              </div>
            </Col>
            <Col lg="6">
              <SimpleInput
                field="firm_city"
                label={t('Firm City')}
                placeholder={t('eg city')}
              />
            </Col>
            <Col lg="6">
              <SimpleInput
                field="direct_phone"
                label={t('Direct Phone')}
                placeholder={`${t('eg')} (123) 456-7890`}
              />
            </Col>
            <Col lg="6">
              <SimpleInput
                field="postal_code"
                label={t('Firm Zip Code / Postal Code')}
                placeholder={`${t('eg')} 1234-5678`}
              />
            </Col>
            <Col lg="6">
              <SimpleInput
                field="mobile"
                label={t('Mobile')}
                placeholder={`${t('eg')} (123) 456-7890`}
              />
            </Col>
            <Col lg="12" className="text-right">
              <Button htmlType="submit">{t('Save')}</Button>
            </Col>
          </Row>
        </Container>
      </Form>
    </FormProvider>
  );
}
