/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useMemo, useState, useEffect } from 'react';
import {
  useForm,
  FormProvider,
  SubmitHandler,
  Controller,
} from 'react-hook-form';
import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Form, Row, Col, Container, Input, Label } from 'reactstrap';
import {
  LayoutComponent,
  LayoutComponentDataType,
  LayoutComponentFormValues,
  TemplateFieldBase,
} from '@wgt/types';
import {
  convertLayoutComponentFormValuesToGraphqlCreateInput,
  convertLayoutComponentFormValuesToGraphqlUpdateInput,
} from '@wgt/converters';
import {
  SimpleInput,
  SimpleDropdown,
  Button,
  Line,
} from '../../../../../../../../components';
import { TemplateFieldsContext } from '../../../../../../../../hooks/useTemplateFieldsContext';
import BtnLoading from '../../../../../../../../components/_molecules/BtnLoading';
import { LIST_COMPONENT_CONTENT_GQL } from '../../../../../graphql';
import { FetchedDataTypes } from '../../../../../types';
import useFieldActions from '../useFieldActions';
import { useTranslation } from 'react-i18next';
import RenderLayoutComponentTemplate from '../../../../../../../../components/_organisms/RenderLayoutComponentTemplate';
import FieldOptions from './FieldOptions';
import { Property } from '../../../../../../Properties/types';

const ActiveItemForm: React.FC = () => {
  const [keyActived, setKeyActived] = useState<string>('');

  const params = useParams<ParamTypes>();
  const { t } = useTranslation('template');
  const { active } = useContext(TemplateFieldsContext);
  const activeField = active as TemplateFieldBase;
  const initialFormValues: LayoutComponentFormValues = {
    data_type_id: undefined,
    component_id: activeField?.component_id,
    label: '',
    property_id: undefined,
    search_component_id: activeField?.component_id,
    values: [],
    is_searchable: false,
    is_required: true,
    is_highlight: false,
    is_single_shape: false,
    template_id: parseInt(params.id, 10),
    config: {},
  };

  const methods = useForm<LayoutComponentFormValues>({
    defaultValues: activeField?.formValues ?? initialFormValues,
    shouldUnregister: false,
  });

  const { handleSubmit, errors, watch, getValues, setValue } = methods;

  const { data, loading } = useQuery(LIST_COMPONENT_CONTENT_GQL, {
    fetchPolicy: 'cache-and-network',
    skip: !activeField?.formValues?.component_id,
    variables: {
      id: activeField?.formValues?.component_id,
    },
  });

  const { create, submitting, update } = useFieldActions();

  const dataTypeId = watch('data_type_id');

  const { data: dataProperties } = useQuery(
    gql`
      query propertiesByDataType($filter: PropertyFilterInput) {
        properties(filter: $filter, first: 1000) {
          data {
            id
            name
          }
        }
      }
    `,
    {
      skip: !dataTypeId,
      variables: {
        filter: {
          dataType: {
            id: dataTypeId,
          },
        },
      },
    },
  );

  const { dataTypes }: FetchedDataTypes = useMemo(() => {
    if (!data?.component) {
      return { dataTypes: [] };
    }
    const { dataTypes: types } = data?.component;

    return { dataTypes: types };
  }, [data?.component]);

  const avaiableComponents = useMemo(
    () =>
      data?.components?.data?.filter((component: LayoutComponent) =>
        component.dataTypes.some(
          (dataType: LayoutComponentDataType) =>
            dataTypes.findIndex((x) => x.id === dataType.id) >= 0,
        ),
      ),
    [data?.components?.data, dataTypes],
  );

  const onSubmit: SubmitHandler<LayoutComponentFormValues> = (input) => {
    if (activeField?.id) {
      const convertedUpdateInput = convertLayoutComponentFormValuesToGraphqlUpdateInput(
        { ...activeField?.formValues, ...input },
      );

      update({
        variables: {
          fieldId: activeField.id,
          field: convertedUpdateInput,
        },
      });
    } else {
      const convertedCreateInput = convertLayoutComponentFormValuesToGraphqlCreateInput(
        { ...activeField?.formValues, ...input },
      );

      if (convertedCreateInput) {
        create({
          variables: {
            input: convertedCreateInput,
          },
        });
      }
    }
  };

  useEffect(() => {
    const dataTypeKeyActived = dataTypes?.find(
      (type: any) => Number(type.id) === Number(dataTypeId),
    );
    setKeyActived(dataTypeKeyActived?.key ?? '');
  }, [dataTypeId, dataTypes]);

  useEffect(() => {
    methods.reset(activeField.formValues ?? initialFormValues);
  }, [active]);

  if (loading) {
    return (
      <div>
        <BtnLoading />
      </div>
    );
  }

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Container>
          <Row>
            <Col xs="12">
              <SimpleInput
                required
                field="label"
                label={t('Label')}
                hint={errors.label?.message || ''}
              />
            </Col>
            <Col xs="12">
              <Controller
                name="data_type_id"
                defaultValue={getValues('data_type_id') ?? ''}
                rules={{ required: true }}
                render={({ name, onChange, value }) => (
                  <div className="simple-dropdown">
                    <Label>{t('Type')}</Label>
                    <Input
                      name={name}
                      onChange={(e) => [
                        onChange(e),
                        setValue('property_id', ''),
                      ]}
                      type="select"
                      value={value}
                      invalid={!!errors?.data_type_id}
                    >
                      <option value="">{t('Select')}</option>
                      {dataTypes.map((type) => (
                        <option key={type.id} value={type.id}>
                          {type.name}
                        </option>
                      ))}
                    </Input>
                  </div>
                )}
              />
            </Col>
            <Col xs="12">
              <Controller
                name="property_id"
                render={({ name, onChange, value }) => (
                  <div className="simple-dropdown">
                    <Label>{t('Property')}</Label>
                    <Input
                      name={name}
                      onChange={onChange}
                      type="select"
                      value={value}
                      invalid={!!errors?.property_id}
                    >
                      <option value="">{t('Select')}</option>
                      {dataProperties?.properties.data.map((prop: Property) => (
                        <option key={prop.id} value={prop.id}>
                          {prop.name}
                        </option>
                      ))}
                    </Input>
                  </div>
                )}
              />
            </Col>
            <Col xs="12">
              <SimpleDropdown
                required
                placeholder={t('Select')}
                field="component_id"
                options={avaiableComponents}
                label={t('Add Stock Component')}
                text="name"
                controller="id"
              />
            </Col>
            <Col xs="12">
              <SimpleDropdown
                required
                placeholder={t('Select')}
                field="search_component_id"
                options={avaiableComponents}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                  methods.setValue('search_component_id', e.target.value)
                }
                label={t('Search Component')}
                text="name"
                controller="id"
              />
            </Col>
            <Col xs="12">
              {keyActived && (
                <RenderLayoutComponentTemplate component={keyActived} />
              )}
            </Col>

            <Col xs="12">
              <Line className="mt-4" />
              <strong>Settings</strong>
            </Col>
            <Col xs="12" className="mb-5">
              <FieldOptions />
            </Col>

            <Col className="text-right">
              <Button
                htmlType="submit"
                disabled={submitting}
                isLoading={submitting}
              >
                {t(activeField?.id ? 'Save' : 'Update')}
              </Button>
            </Col>
          </Row>
        </Container>
      </Form>
    </FormProvider>
  );
};

export default ActiveItemForm;
