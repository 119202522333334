import React from 'react';
import { Mineral } from '@wgt/types';
import { Row, Col, Collapse } from 'reactstrap';
import { IconButton } from 'evergreen-ui';
import { FiPlusCircle, FiMinusCircle } from 'react-icons/fi';
import classnames from 'classnames';
import { MineralItemContent } from './MineralItemContent';

interface MineralsListItemProps {
  mineral: Mineral;
  isOpened?: boolean;
  onToggle(): void;
}
const MineralsListItem: React.FC<MineralsListItemProps> = ({
  mineral,
  isOpened,
  onToggle,
}) => {
  return (
    <>
      <Row
        className={classnames('border rounded mb-3 mx-0 py-3 mineral-body', {
          'bg-light': isOpened,
        })}
      >
        <Col xs="12" lg="2" className="d-flex">
          <IconButton
            appearance="minimal"
            iconSize={26}
            icon={isOpened ? FiMinusCircle : FiPlusCircle}
            onClick={onToggle}
            className="icon-toggle mr-2"
          />
          {mineral.classification?.label}
        </Col>

        <Col xs="12" lg="2">
          {mineral.group?.label}
        </Col>
        <Col xs="12" lg="2">
          {mineral.subgroup?.label}
        </Col>
        <Col xs="12" lg="2">
          {mineral.species?.label}
        </Col>
        <Col xs="12" lg="2">
          {mineral.variety?.label}
        </Col>
        <Col xs="12" lg="4">
          {mineral.series?.label}
        </Col>
        <Col xs="12" lg="12">
          <Collapse isOpen={isOpened} className="w-100 mt-3">
            {isOpened && <MineralItemContent mineral={mineral} />}
          </Collapse>
        </Col>
      </Row>
    </>
  );
};

export default MineralsListItem;
