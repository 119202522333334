/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import useCrmRoutes from '../../../../../../hooks/useCrmRoutes';
import Table from '../../../../../../components/_organisms/Table';
import {
  LIST_PROPERTY_VALUE_VARIATION_VALUES_GQL,
  DELETE_PROPERTY_VALUE_VARIATION_VALUE_GQL,
} from '../../../graphql';
import { PropertyValueVariationValue } from '@wgt/types';

const PropertyValueVariationValueList: React.FC = () => {
  const history = useHistory();
  const params = useParams<ParamTypes>();
  const { actualRoute } = useCrmRoutes();

  return (
    <div>
      <Table
        hover
        striped
        columns={[
          { label: 'Name', field: 'name' },
          { label: 'Key', field: 'key' },
          { label: 'Image', field: 'image_url', type: 'image', size: 50 },
        ]}
        query={LIST_PROPERTY_VALUE_VARIATION_VALUES_GQL}
        node="propertyValueVariationValues"
        deleteMutation={DELETE_PROPERTY_VALUE_VARIATION_VALUE_GQL}
        getMutationArgs={(item: PropertyValueVariationValue) => ({
          id: item.id,
        })}
        getConfirmMessage={(item: PropertyValueVariationValue) =>
          `Are you sure you want to delete ${item?.name}?`
        }
        showDetail={false}
        variables={{
          filter: { property_value_variation_id: Number(params.id) },
        }}
        editAction={(item: PropertyValueVariationValue) =>
          history.push(
            `/crm/${actualRoute}/${params.id}/edit/variation-values/${Number(
              item.id,
            )}`,
          )
        }
      />
    </div>
  );
};

export default PropertyValueVariationValueList;
