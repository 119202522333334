import React from 'react';
import { FaTrash } from 'react-icons/fa';
import { FileListItemProps } from '../../../types';
import { Button } from '../../../../../../components';

const FileListItem: React.FC<FileListItemProps> = ({
  file,
  handleRemoveFile,
}) => {
  return (
    <div className="file-list-item">
      <span>{file.fileToUpload[0]?.name}</span>
      <Button small type="danger" onClick={handleRemoveFile}>
        <FaTrash />
      </Button>
    </div>
  );
};

export default FileListItem;
