import React, { useState, useMemo } from 'react';
import { UncontrolledPopover, PopoverBody, Row } from 'reactstrap';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import { PaymentTypeGroup } from '@wgt/types';
import { useQuery } from '@apollo/client';
import { LIST_PAYMENT_TYPE_GROUPS_GQL } from './graphql';
import { Button, Text } from '../';
import Loader from '../_organisms/Loader';
import PaymentTypeItem from './PaymentTypeItem';

export default function PaymentType(): JSX.Element {
  const [popoverTypePayment, setPopoverTypePayment] = useState<boolean>(false);
  const { t } = useTranslation('pointOfSales');

  const [paymentTypeIdsChecked, setPaymentTypeIdsChecked] = useState<number[]>(
    [],
  );

  const handlePaymentTypeIds = (paymentTypeId: number) => {
    const dataIds = paymentTypeIdsChecked?.includes(paymentTypeId)
      ? paymentTypeIdsChecked?.filter((name) => name !== paymentTypeId)
      : [...(paymentTypeIdsChecked ?? []), paymentTypeId];
    setPaymentTypeIdsChecked(dataIds);
    return dataIds;
  };

  const togglePopoverTypePayment = () =>
    setPopoverTypePayment(!popoverTypePayment);

  const { data, loading } = useQuery<{
    paymentTypeGroups: { data: PaymentTypeGroup[] };
  }>(LIST_PAYMENT_TYPE_GROUPS_GQL);

  const paymentTypeGroups = useMemo(() => data?.paymentTypeGroups?.data ?? [], [
    data?.paymentTypeGroups?.data,
  ]);

  const paymentTypeDescription = useMemo(() => {
    return paymentTypeGroups
      .reduce<string[]>((acumulator, paymentGroup) => {
        const dataDescription =
          paymentGroup.paymentTypes
            ?.filter((paymentType) =>
              paymentTypeIdsChecked?.includes(Number(paymentType.id)),
            )
            .map((paymentTypeFiltered) => paymentTypeFiltered.name) ?? [];

        return [...acumulator, ...dataDescription];
      }, [])
      .join(', ');
  }, [paymentTypeIdsChecked, paymentTypeGroups]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center mt-3">
        <Loader round />
      </div>
    );
  }

  return (
    <>
      <Button type="combobox" id="listTypePaymentPopover">
        <div className="d-flex justify-content-between w-100">
          <div style={{ whiteSpace: 'nowrap', overflowX: 'hidden' }}>
            {paymentTypeDescription === ''
              ? t('Payment Type')
              : paymentTypeDescription}
          </div>
          <div>{popoverTypePayment ? <FiChevronUp /> : <FiChevronDown />}</div>
        </div>
      </Button>
      <UncontrolledPopover
        trigger="legacy"
        isOpen={popoverTypePayment}
        target="listTypePaymentPopover"
        toggle={togglePopoverTypePayment}
      >
        <PopoverBody className="popover-list-payment">
          <Text type="muted">{t('Select Payment Type')}</Text>
          <Row className="mt-2 payment-type-checkbox">
            {paymentTypeGroups.map((paymentTypeGroup: PaymentTypeGroup) => (
              <PaymentTypeItem
                key={paymentTypeGroup.id}
                paymentTypeGroup={paymentTypeGroup}
                handlePaymentTypeIds={handlePaymentTypeIds}
                paymentTypeIdsChecked={paymentTypeIdsChecked}
              />
            ))}
          </Row>
        </PopoverBody>
      </UncontrolledPopover>
    </>
  );
}
