import React, { ChangeEvent, useState } from 'react';
import {
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  Label,
  Container,
  ModalHeader,
} from 'reactstrap';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { JobTitle, JobTitleInput } from '@wgt/types';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { useMutation } from '@apollo/client';
import useToast from '../../../../../../../hooks/useToast';
import { SimpleInput, Button } from '../../../../../../../components';
import SelectSearch from '../../../../../../../components/SelectSearch';
import { GET_JOB_TITLES_GQL, CREATE_JOB_TITLE_GQL } from '../graphql';

interface Props {
  toggle: () => void;
}

export default function AddJobTitleModalForm({ toggle }: Props): JSX.Element {
  const { t } = useTranslation(['hr', 'common']);
  const [selectEnabled, setSelectEnabled] = useState<string>('');
  const [selectError, setSelectError] = useState<boolean>(false);

  const { toast } = useToast();
  const methods = useForm();
  const { handleSubmit, errors } = methods;

  const [create, { loading }] = useMutation<JobTitleInput>(
    CREATE_JOB_TITLE_GQL,
    {
      refetchQueries: [{ query: GET_JOB_TITLES_GQL }],
      onCompleted: () => [toast('success'), toggle()],
      onError: () => {
        toast('error');
      },
    },
  );

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectEnabled(event.target.value);
    setSelectError(false);
  };

  const onSubmit = handleSubmit((values) => {
    if (selectEnabled === '') {
      setSelectError(true);
      return;
    }

    const variables = {
      ...values,
      main_id: selectEnabled === 'main_id' ? values.main?.id : undefined,
      parent_id:
        selectEnabled === 'parent_id' ? values.parent_id?.id : undefined,
      level: Number(values.level),
    };

    create({ variables });
  });

  return (
    <Modal isOpen toggle={toggle} className="add-job-title-modal">
      <FormProvider {...methods}>
        <Form onSubmit={onSubmit} noValidate>
          <ModalHeader toggle={toggle}>{t('Add New Job Title')}</ModalHeader>

          <ModalBody>
            <SimpleInput field="name" label={t('common:Name')} required />

            <Container
              className={classnames('border rounded mt-3 pb-3 pt-2', {
                'border-danger': selectError,
              })}
            >
              {selectError && (
                <p className="text-danger mb-1 mt-2 ml-2">
                  {t('You must at least select an option')}
                </p>
              )}
              <div className="simple-dropdown">
                <Label>
                  <input
                    type="radio"
                    className="mr-2 mt-2"
                    name="chooseTitle"
                    value="main_id"
                    onChange={(e) => handleRadioChange(e)}
                  />
                  {t('Nest Subordinate under')}
                </Label>
                <Controller
                  name="main_id"
                  defaultValue=""
                  rules={{
                    required: selectEnabled === 'main_id',
                  }}
                  render={({ onChange }) => (
                    <SelectSearch
                      gql={GET_JOB_TITLES_GQL}
                      getOptionLabel={(e: JobTitle) => `${e.name ?? ''} `}
                      getOptionValue={(e: JobTitle) => `${e.id}`}
                      name="name"
                      placeholder={t('Please select a parent')}
                      onChange={onChange}
                      isDisabled={selectEnabled !== 'main_id'}
                      invalid={Boolean(errors?.main_id)}
                    />
                  )}
                />
              </div>

              <div className="simple-dropdown mt-2">
                <Label>
                  <input
                    type="radio"
                    className="mr-2 mt-2"
                    name="chooseTitle"
                    value="parent_id"
                    onChange={(e) => handleRadioChange(e)}
                  />
                  {t('Nest Job Title under')}
                </Label>
                <Controller
                  name="parent_id"
                  defaultValue=""
                  rules={{
                    required: selectEnabled === 'parent_id',
                  }}
                  render={({ onChange }) => (
                    <SelectSearch
                      gql={GET_JOB_TITLES_GQL}
                      getOptionLabel={(e: JobTitle) => `${e.name ?? ''} `}
                      getOptionValue={(e: JobTitle) => `${e.id}`}
                      name="name"
                      placeholder={t('Please select a parent')}
                      onChange={onChange}
                      isDisabled={selectEnabled !== 'parent_id'}
                      invalid={Boolean(errors?.parent_id)}
                    />
                  )}
                />
              </div>
            </Container>
            <SimpleInput
              field="level"
              label="Level"
              type="number"
              className="mt-2"
              required
            />
          </ModalBody>
          <ModalFooter>
            <Button htmlType="submit" isLoading={loading}>
              {t('common:Save')}
            </Button>
          </ModalFooter>
        </Form>
      </FormProvider>
    </Modal>
  );
}
