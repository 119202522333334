import React, { useRef } from 'react';
import { Pane } from 'evergreen-ui';
import { ProductBase, CategoryBase, PageId } from '@wgt/types';
import { Button } from '../../../../../components';
import { useMutation } from '@apollo/client';
import StateManager from 'react-select';
import { useProductUpdate } from '../Provider';
import { ADD_STOCK_GQL } from '../../graphql';
import SelectSearch from '../../../../../components/SelectSearch';
import { PRODUCT } from '../../ProductUpdateRow/graphql';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { SEARCH, ASSOCIATE } from './graphql';
import { SearcherProps } from './types';

const SKU: React.FC<SearcherProps> = ({ inline }) => {
  const { filters } = useProductUpdate();
  const { getValues } = useFormContext();
  const { t } = useTranslation(['product', 'common']);
  const category = getValues('category') as CategoryBase;

  const [associateSKU, { loading: submitting }] = useMutation(ASSOCIATE, {
    refetchQueries: [
      { query: inline ? PRODUCT : ADD_STOCK_GQL, variables: filters },
    ],
  });
  const skuRef = useRef<StateManager<ProductBase> | null>(null);

  const associate = () => {
    if (!skuRef.current?.state.value) {
      return;
    }

    const selected = skuRef.current?.state.value as ProductBase;
    associateSKU({
      variables: {
        id: selected.id,
        sku: getValues('sku'),
      },
    });
  };

  return (
    <Pane className="sku-form" display={inline ? 'flex' : 'initial'}>
      <div style={{ width: '280px', fontSize: inline ? 10 : 14, zIndex: 4 }}>
        <SelectSearch
          gql={SEARCH}
          name="sku"
          getOptionLabel={(e) => `${e.name ?? ''}`}
          getOptionValue={(e) => `${e.id}`}
          ref={skuRef}
          placeholder={t('Enter SKU and Locate Page ID')}
          parseResults={(items: PageId[]) =>
            items.filter((item) => item.product?.sku !== getValues('sku'))
          }
          parseVariables={(variables) => ({
            filter: {
              category_id: category?.id,
              product: {
                sku: variables.filter.sku,
              },
            },
          })}
        />
      </div>
      <Button
        small
        htmlType="button"
        onClick={associate}
        disabled={submitting}
        isLoading={submitting}
        className="mx-3"
      >
        {t('common:Move')}
      </Button>
      {!inline && (
        <button type="button" className="btn btn-outline-primary mr-2 btn-sm">
          {t('common:Menu')}
        </button>
      )}
    </Pane>
  );
};

export default SKU;
