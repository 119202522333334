import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Image from './Pages/Image';
import Video from './Pages/Video';
import Reports from './Pages/Reports';
import Layout from './Pages/Layout';
import Inclusions from './Pages/Inclusions';
import { Pane, Tablist, Tab } from 'evergreen-ui';
import BagProvider from '../../../../../services/bag';
import useMediaCenterController from '../../../../../hooks/useMediaCenterController';
import { BACKGROUND_OPTIONS } from '../../../../../utils/constants';
import './styles.scss';

export const DEFAULT_BG = BACKGROUND_OPTIONS[0];

export interface MediaCenterProps {
  productId?: string | null;
  categoryId?: string;
  getProductId?: () => Promise<string | undefined>;
}

interface Menu {
  text: string;
  Component: React.FC;
}

const menus: Menu[] = [
  {
    text: 'Image',
    Component: Image,
  },
  {
    text: 'Video',
    Component: Video,
  },
  {
    text: 'Reports',
    Component: Reports,
  },
  {
    text: 'Inclusions',
    Component: Inclusions,
  },
  {
    text: 'Layout',
    Component: Layout,
  },
];

export default function MediaCenter({
  productId,
  categoryId,
  getProductId,
}: MediaCenterProps): JSX.Element {
  const [active, setActive] = useState<Menu>(menus[0]);
  const params = useParams<ParamTypes>();
  const controller = useMediaCenterController({
    getProductId,
    categoryId,
    productId: productId ?? params.id,
  });

  return (
    <Pane className="media-center-holder">
      <Pane display="flex" justifyContent="center">
        <Tablist className="media-center-tabs">
          {menus.map((menu) => (
            <Tab
              key={menu.text}
              id={menu.text}
              onSelect={() => setActive(menu)}
              isSelected={active.text === menu.text}
              aria-controls={`tab-${menu.text}`}
            >
              {menu.text}
            </Tab>
          ))}
        </Tablist>
      </Pane>
      <Pane>
        <BagProvider value={controller}>
          <active.Component />
        </BagProvider>
      </Pane>
    </Pane>
  );
}
