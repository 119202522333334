import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../../../components';
import usePointOfSaleUpdate from '../../usePointOfSaleUpdate';
import ConfirmModal from './ConfirmModal';
import useInvoice from './useInvoice';

interface PaymentProps {
  setPaymentModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setInvoiceId: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export default function Payment({
  setPaymentModalOpen,
  setInvoiceId,
}: PaymentProps): JSX.Element {
  const [isConfirmOpen, setConfirmOpen] = useState(false);
  const { products } = usePointOfSaleUpdate();
  const { t } = useTranslation(['pointOfSales', 'common']);
  const [createInvoice, { data, loading }] = useInvoice();

  useEffect(() => {
    if (!!data?.createInvoice?.id) {
      setInvoiceId(data.createInvoice.id);
    }
  }, [data]);

  const checkInvoiceAndOpenPayments = async () => {
    setConfirmOpen(false);
    await createInvoice();
    setPaymentModalOpen(true);
  };

  const onClickPayments = () =>
    products.every((product) => product.confirmed)
      ? checkInvoiceAndOpenPayments()
      : setConfirmOpen(true);

  return (
    <>
      <ConfirmModal
        isShown={isConfirmOpen}
        onCancel={() => setConfirmOpen(false)}
        onConfirm={checkInvoiceAndOpenPayments}
      />

      <Button onClick={onClickPayments} disabled={loading} isLoading={loading}>
        {t('Payments')}
      </Button>
    </>
  );
}
