import { PageId, WorkOrderItem, WorkOrder } from '@wgt/types';
import React, { createContext, useContext, useState } from 'react';
interface WorkOrderContext {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  activePageId: PageId | null;
  setActivePageId: React.Dispatch<React.SetStateAction<PageId | undefined>>;
  selectedWorkOrderItem: WorkOrderItem;
  productId?: number;
  setWorkOrderItem: React.Dispatch<
    React.SetStateAction<WorkOrderItem | undefined>
  >;
  workOrder: WorkOrder;
  setWorkOrder: React.Dispatch<React.SetStateAction<WorkOrder | undefined>>;
}
const Context = createContext({});

export function WorkOrderProvider({
  children,
  productId,
}: {
  children: JSX.Element;
  productId?: number;
}): JSX.Element {
  const [isOpen, setOpen] = useState(false);
  const [selectedWorkOrderItem, setWorkOrderItem] = useState<WorkOrderItem>();
  const [activePageId, setActivePageId] = useState<PageId>();
  const [workOrder, setWorkOrder] = useState<WorkOrder>();

  return (
    <Context.Provider
      value={{
        isOpen,
        setOpen,
        activePageId,
        setActivePageId,
        selectedWorkOrderItem,
        setWorkOrderItem,
        productId,
        workOrder,
        setWorkOrder,
      }}
    >
      {children}
    </Context.Provider>
  );
}
export default function useWorkOrderContext(): WorkOrderContext {
  const context = useContext(Context) as WorkOrderContext;
  return context;
}
