/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useMemo } from 'react';
import { UncontrolledPopover, PopoverBody, Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { useFormContext } from 'react-hook-form';
import { OfficeCategory } from '@wgt/types';
import classnames from 'classnames';
import { Button } from '../../../../../../../components';
import Loader from '../../../../../../../components/_organisms/Loader';
import { GET_OFFICE_TYPES_GQL, GET_OFFICE_CATEGORIES_GQL } from '../graphql';
import ItemsSelect from './ItemsSelect';

export default function OfficeCategories(): JSX.Element {
  const { t } = useTranslation('common');
  const [popoverOpen, setPopoverOpen] = useState(false);

  const toggle = () => setPopoverOpen(!popoverOpen);

  const { watch, errors } = useFormContext();

  const watchValues = watch(['types', 'category_ids']);

  const { data, loading } = useQuery(GET_OFFICE_TYPES_GQL);

  const { data: dataOfficeCategory, loading: loadingOfficeCategory } = useQuery(
    GET_OFFICE_CATEGORIES_GQL,
  );

  const types = useMemo(() => {
    return data?.__type?.enumValues
      ? data?.__type?.enumValues?.map((value: any) => ({
          id: value.name,
          name: value.name.split('_').join(' '),
        }))
      : [];
  }, [data?.__type?.enumValues]);

  const categories = useMemo(() => dataOfficeCategory?.categories?.data ?? [], [
    dataOfficeCategory,
  ]);

  const itemsSelected = useMemo(() => {
    const typesSelected = watchValues?.types?.length
      ? types
          .filter((type: any) => watchValues?.types?.includes(type.id))
          .map((type: any) => type.name)
      : [];
    const categoriesSelected = watchValues?.category_ids?.length
      ? categories
          .filter((category: OfficeCategory) =>
            watchValues?.category_ids?.includes(category.id),
          )
          .map((category: OfficeCategory) => category.name)
      : [];
    return typesSelected.concat(categoriesSelected).join(', ');
  }, [watchValues?.types?.length, watchValues?.category_ids.length]);

  return (
    <>
      <Button
        id="officeCategoryPopover"
        type="combobox"
        className={classnames({ 'is-invalid': errors?.types })}
      >
        <div className="d-flex justify-content-between w-100">
          <div className="text-truncate">
            {itemsSelected ? (
              <span className="text-capitalize">{itemsSelected}</span>
            ) : (
              t('Description')
            )}
          </div>
          <div>{popoverOpen ? <FiChevronUp /> : <FiChevronDown />}</div>
        </div>
      </Button>

      <UncontrolledPopover
        trigger="legacy"
        target="officeCategoryPopover"
        toggle={toggle}
        isOpen={popoverOpen}
        className="popover-office-category"
        placement="top"
      >
        <PopoverBody className="px-4 pt-3 pb-2">
          <Row>
            <Col xs="12" className="list-checkbox">
              {loading ? (
                <Loader small />
              ) : (
                <ItemsSelect items={types} field="types" />
              )}
              <div className="mb-3" />
              {loadingOfficeCategory ? (
                <Loader small />
              ) : (
                <ItemsSelect items={categories} field="category_ids" />
              )}
            </Col>
          </Row>
        </PopoverBody>
      </UncontrolledPopover>
    </>
  );
}
