import React from 'react';
import InternalRoutes from '../../../components/_organisms/InternalRoutes';
import useCrmRoutes from '../../../hooks/useCrmRoutes';
import Category from './Category';
import Result from './Result';

const Templates: React.FC = () => {
  const { isCrmInternalRoute, actualRoute } = useCrmRoutes();

  const routes = [
    {
      component: Category,
      name: 'Category',
      path: `/crm/${actualRoute}/categories/:category/products`,
    },
    {
      component: Result,
      exact: true,
      name: 'Result',
      path: `/crm/${actualRoute}/:term`,
    },
  ];

  if (isCrmInternalRoute) {
    return <InternalRoutes routes={routes} />;
  }

  return <Result />;
};

export default Templates;
