/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from '@apollo/client';
import { User } from '@wgt/types';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import SelectOfficeCard from '../../../../../../components/SelectOfficeCard';
import usePointOfSaleUpdate from '../../usePointOfSaleUpdate';
import { USERS_GQL } from '../../../graphql';
import { FormProvider, useForm } from 'react-hook-form';
import useLoggedContext from '../../../../../../hooks/useLoggedContext';

const SalesRepresentative: React.FC = () => {
  const { t } = useTranslation();
  const { pointOfSale, setFakeData } = usePointOfSaleUpdate();
  const { active } = useLoggedContext();

  const { data } = useQuery(USERS_GQL, {
    skip: !active?.id,
    variables: {
      filter: {
        employments: { sales_representative: true, id: active?.id },
      },
    },
  });
  const users = useMemo(
    () =>
      data?.users?.data?.map(
        ({ id, first_name, middle_name, last_name, email }: User) => ({
          id,
          name: `${first_name} ${middle_name} ${last_name}`,
          email,
        }),
      ) ?? [],
    [data?.users],
  );
  const methods = useForm({
    defaultValues: {
      sales_representative: pointOfSale.salesRepresentative?.id ?? '',
    },
  });

  const w = methods.watch('sales_representative');

  useEffect(() => {
    setFakeData((x: any) => ({ ...x, salesRepresentative: w }));
  }, [w]);

  return (
    <FormProvider {...methods}>
      <SelectOfficeCard
        title={t('Sales Representative')}
        options={users}
        field="sales_representative"
        placeholder={t('Choose a sales representative')}
      />
    </FormProvider>
  );
};

export default SalesRepresentative;
