import React from 'react';
import { UncontrolledPopover, PopoverBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { FiMoreHorizontal } from 'react-icons/fi';
import { IconButton } from 'evergreen-ui';
import usePointOfSaleUpdate from '../../../usePointOfSaleUpdate';

import { Policy } from '@wgt/types';
import { Button } from '../../../../../../../components/';

export default function Options({ policy }: { policy: Policy }): JSX.Element {
  const { t } = useTranslation('common');
  const { update, isUpdating, pointOfSale } = usePointOfSaleUpdate();

  return (
    <>
      <IconButton
        appearance="minimal"
        icon={FiMoreHorizontal}
        iconSize={24}
        className="button-close"
        id="popoverMenuCard"
      />
      <UncontrolledPopover
        trigger="legacy"
        placement="bottom"
        target="popoverMenuCard"
        className="popover-card"
      >
        <PopoverBody>
          <ul className="list-group">
            <li className="list-group-item list-group-item-action">
              {t('Remove')}
            </li>
            <li className="list-group-item list-group-item-action">
              {t('View')}
            </li>
            <li className="list-group-item list-group-item-action">
              {t('Edit')}
            </li>
            <li className="list-group-item list-group-item-action">
              {t('Default')}
            </li>
            <li className="list-group-item list-group-item-action ">
              <Button
                htmlType="button"
                type="text"
                isLoading={isUpdating}
                onClick={() =>
                  !isUpdating &&
                  update({
                    variables: {
                      id: pointOfSale.id,
                      point_of_sale: { policy_id: policy.id },
                    },
                  })
                }
              >
                {!isUpdating && t('Choose')}
              </Button>
            </li>
          </ul>
        </PopoverBody>
      </UncontrolledPopover>
    </>
  );
}
