/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useMutation } from '@apollo/client';

import LoginForm, { FormValues } from '../../components/_molecules/LoginForm';
import Logo from '../../components/_organisms/Logo';
import Footer from '../../components/_organisms/CustomFooter';

import { useHistory } from 'react-router-dom';
import useCrmRoutes from '../../hooks/useCrmRoutes';

import './styles.scss';
import InternalRoutes from '../../components/_organisms/InternalRoutes';
import {
  currentAuthQuery,
  loginMutation,
} from '../../services/graphql/auth.gql';
import { useTranslation } from 'react-i18next';

import useToast from '../../hooks/useToast';
import useAuthenticationCookies from '@wgt/authentication/useAuthenticationCookies';

interface LoginFormValues {
  email: string;
  password: string;
}

interface AuthProps {
  routes: any;
}

const Auth: React.FC<AuthProps> = ({ routes }) => {
  const [loginError, setLoginError] = useState<string | null>(null);
  const [currentToken] = useAuthenticationCookies();
  const { t } = useTranslation('authentication');
  const { toast } = useToast();
  const [, setCookie] = useAuthenticationCookies();
  const [makeLogin, { loading }] = useMutation(loginMutation, {
    update: async (cache, { data: { login } }) => {
      cache.writeQuery({
        query: currentAuthQuery,
        data: { authorization: login },
        broadcast: true,
      });
      setCookie(login);
    },
    onError: () => setLoginError(t('invalid-credentials')),
  });
  const history = useHistory<{ pathname?: string }>();

  const { isInternalRoute } = useCrmRoutes();

  const handleSignIn: SubmitHandler<FormValues> = async (
    values: LoginFormValues,
  ) => {
    makeLogin({
      variables: {
        ...values,
      },
    });
  };

  useEffect(() => {
    if (!currentToken?.access_token) {
      return;
    }

    if (history.location.pathname !== '/auth/logout') {
      toast('success', t('welcome'));
      history.push(history?.location?.state?.pathname ?? '/crm/profile');
    }
  }, [currentToken, history]);

  return (
    <div className="login-screen">
      <div className="overlay" />
      <div className="form-holder">
        <Logo />
        {!isInternalRoute && (
          <LoginForm
            handleSignIn={handleSignIn}
            isSubmitting={loading}
            hint={loginError}
          />
        )}
        <InternalRoutes routes={routes} />
      </div>

      <Footer />
    </div>
  );
};

export default Auth;
