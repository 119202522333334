import React, { useState } from 'react';
import WgtReportLayout from '../../../components/_organisms/ReportLayout';
import InvoiceDetails from '../../../components/InvoiceDetails';
import { Button } from '../../../components';
import { useTranslation } from 'react-i18next';
import { InvoiceFull } from '@wgt/types';
export default function InvoiceDetailsContent({
  invoice,
  type,
}: {
  invoice: InvoiceFull;
  type?: 'invoice' | 'aml';
}): JSX.Element {
  const [invoiceType, setInvoiceType] = useState<'invoice' | 'aml'>(
    type ?? 'invoice',
  );
  const { t } = useTranslation('statements');
  return (
    <div className="w-100">
      <Button
        onClick={() => setInvoiceType('invoice')}
        type={invoiceType === 'invoice' ? 'gray' : 'white'}
      >
        {t('Invoice')}
      </Button>
      <Button
        onClick={() => setInvoiceType('aml')}
        type={invoiceType === 'aml' ? 'gray' : 'white'}
        className="ml-3"
      >
        {t('AML Statement')}
      </Button>
      <div className="w-100 mt-3">
        <WgtReportLayout>
          <InvoiceDetails invoice={invoice} type={invoiceType} />
        </WgtReportLayout>
      </div>
    </div>
  );
}
