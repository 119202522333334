import React, { useState } from 'react';
import { Button } from 'evergreen-ui';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { NavbarPageBase } from '@wgt/types';
import NavbarAvatar from '../NavbarAvatar';
import ConfirmDialog from '../ConfirmDialog';
import useMe from '../../../hooks/useMe';
import Loader from '../../_organisms/Loader';
import SidebarPane, { SidebarPaneItem } from '../../SidebarPane';
import NoProfilePhoto from '../../../assets/no-profile-photo.jpg';
import { USER_MENU_PAGES } from '../../../utils/navbarPages';
import ComingSoonModals from '../../ComingSoonModals';
import ManageAlert from '../../../pages/Crm/LearnToUse/ManageAlert';
import './styles.scss';

const UserDropdownMenu: React.FC = () => {
  const { t } = useTranslation('menus');
  const [isShownComingSoonModule, setShownComingSoonModal] = useState<boolean>(
    false,
  );
  const [isSidebarShown, setSidebarShown] = useState(false);
  const [isConfirmSingOut, setConfirmSingOut] = useState(false);
  const [openSidebarLearnToUse, setOpenSidebarLearnToUse] = useState<boolean>(
    false,
  );
  const history = useHistory();

  const [me] = useMe();

  const confirmSingOut = () => history.push('/auth/logout');

  const handleClick = (menuItem: NavbarPageBase) => {
    if (menuItem.comingSoon) {
      setShownComingSoonModal(!!menuItem.name);
    } else if (menuItem.name === 'learn-to-use') {
      setOpenSidebarLearnToUse(true);
    }
    return undefined;
  };

  if (!me?.id) {
    return <Loader small round />;
  }

  return (
    <>
      <ConfirmDialog
        isShown={isConfirmSingOut}
        onConfirm={confirmSingOut}
        title={t('Are you sure you want to logout')}
        onClose={() => setConfirmSingOut(false)}
      />
      <Button
        className="user-dropdown-menu"
        onClick={() => setSidebarShown(true)}
      >
        <NavbarAvatar
          avatar={NoProfilePhoto}
          name={`${me?.first_name} ${me?.last_name}` ?? ''}
        />
      </Button>
      <SidebarPane
        isShown={isSidebarShown}
        setShown={setSidebarShown}
        size={350}
        closeText={t('Close Menu')}
        className="sidebar-mega-menu"
      >
        {USER_MENU_PAGES.map((menuItem, key) => (
          <SidebarPaneItem
            key={`menu-user-${key}`}
            {...menuItem}
            {...(menuItem.isOnClick && {
              onClick: () => handleClick(menuItem),
            })}
          />
        ))}
        <div className="mt-auto btn-sign-out">
          <button type="button" onClick={() => setConfirmSingOut(true)}>
            {t('Sign out of WGT')}
          </button>
        </div>
      </SidebarPane>

      <SidebarPane
        isShown={openSidebarLearnToUse}
        setShown={setOpenSidebarLearnToUse}
        size={850}
        header={t('Learn to Use Manual')}
      >
        <ManageAlert />
      </SidebarPane>

      <ComingSoonModals
        isOpen={isShownComingSoonModule}
        setOpen={setShownComingSoonModal}
      />
    </>
  );
};

export default UserDropdownMenu;
