/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { TemplateLayoutGroupContent } from '@wgt/types';
import CardLayoutText, {
  Content,
} from '../../../../../components/CardLayoutText';
import convertValueField from '@wgt/converters/product';

interface Props {
  content: TemplateLayoutGroupContent[];
  values: any;
}

export default function CharacteristicSection({
  content,
  values,
}: Props): JSX.Element {
  const card = content
    .filter(
      (item: TemplateLayoutGroupContent) =>
        item.content?.property?.key &&
        convertValueField(values?.[item.content?.property?.key]) !== '',
    )
    .map((item: TemplateLayoutGroupContent) => ({
      header: item.content?.label,
      content: item.content?.property?.key
        ? convertValueField(values?.[item.content?.property?.key])
        : '',
    })) as Content[];

  return (
    <div>
      <CardLayoutText content={card} containerClass="mb-3" />
    </div>
  );
}
