import { setContext } from '@apollo/client/link/context';
import { ApolloLink, gql } from '@apollo/client';
import { currentTokenFragment } from '../../graphql/auth.gql';

const currentAuthQuery = gql`
  query authorization {
    authorization {
      ...tokens_Part
    }
  }
  ${currentTokenFragment}
`;

const contextQuery = gql`
  {
    activeContext {
      id
    }
  }
`;

export default (): ApolloLink =>
  setContext(async (_, { headers, cache }) => {
    let token;
    let firmId;
    try {
      const data = cache.readQuery({ query: currentAuthQuery });
      token = data?.authorization?.access_token;
      const dataContext = cache.readQuery({ query: contextQuery });
      firmId = dataContext?.activeContext?.id;
    } catch (e) {
      console.log(e);
    }

    if (!token) {
      return { headers };
    }

    const authorizationHeaders = {
      ['Firm-Id']: firmId ?? '',
      authorization: token ? `Bearer ${token}` : '',
    };

    return {
      headers: {
        ...headers,
        ...authorizationHeaders,
      },
    };
  });
