/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  LayoutComponentDataTypePropertyValue,
  LayoutTemplateFieldComponent,
} from '@wgt/types';
import React, { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import ComponentElement from './ComponentElement';

function AddStockComponent(props: any): JSX.Element {
  const { field, prefix, showAllFields, templateField } = props;
  const currentTemplateField = templateField as LayoutTemplateFieldComponent;
  const fieldToManage = `${field.property?.key}`;
  const { getValues, setValue } = useFormContext();

  const watchValues = !showAllFields && undefined;
  const isVisible = useMemo(() => {
    if (showAllFields) {
      return true;
    }
    const hasDependencies =
      !!field.valueDependencies?.length ||
      !!currentTemplateField.fieldDependencies?.length;

    if (!hasDependencies) {
      return true;
    }

    const fieldDependencies = currentTemplateField.fieldDependencies?.reduce(
      (visible, { property }) => {
        if (
          typeof watchValues?.[property?.key ?? ''] === 'string' &&
          !!watchValues?.[property?.key ?? '']
        ) {
          return true;
        }
        const values = Object.values(watchValues?.[property?.key ?? ''] ?? {});
        if (
          !!values.filter((x: any) =>
            typeof x.selected === 'boolean' ? x.selected : !!x,
          ).length
        ) {
          return true;
        }

        return visible;
      },
      false,
    );

    const valueDependencies = currentTemplateField.valueDependencies?.reduce(
      (visible, { field: dependencyField, value: dependencyValue }) => {
        if (
          !watchValues?.[dependencyField?.property?.key ?? ''] ||
          !dependencyValue?.key
        ) {
          return visible;
        }
        if (
          typeof watchValues?.[dependencyField?.property?.key ?? ''] ===
          'string'
        ) {
          return (
            watchValues?.[dependencyField?.property?.key ?? ''] ===
              dependencyValue?.key ?? visible
          );
        } else {
          return (
            !!watchValues?.[dependencyField?.property?.key ?? '']?.[
              dependencyValue.key
            ]?.selected ?? visible
          );
        }
      },
      false,
    );

    return fieldDependencies || valueDependencies;
  }, [field, watchValues, showAllFields]);

  const filteredFields: LayoutComponentDataTypePropertyValue[] = useMemo(() => {
    if (showAllFields) {
      return currentTemplateField.values;
    }
    const valuesList: LayoutComponentDataTypePropertyValue[] = currentTemplateField.values.reduce(
      (list: LayoutComponentDataTypePropertyValue[], fieldValue) => {
        if (!fieldValue.dependencies?.length) {
          return [...list, fieldValue];
        }

        const visible = fieldValue.dependencies.reduce(
          (isFieldVisible, { fieldDependencies, valueDependencies }) => {
            if (!fieldDependencies.length && !valueDependencies.length) {
              return true;
            }

            const foundFieldDependency = fieldDependencies.some((dep) => {
              const { property } = dep;
              if (
                typeof watchValues?.[property?.key ?? ''] === 'string' &&
                !!watchValues?.[property?.key ?? '']
              ) {
                return true;
              }
              if (
                !!Object.values(
                  watchValues?.[property?.key ?? ''] ?? {},
                ).filter((x: any) => x.selected)?.length
              ) {
                return true;
              }

              return !!watchValues?.[property?.key ?? '']?.length;
            });

            const foundValueDependency = valueDependencies
              .filter((valueDependency) => !!valueDependency.field?.property)
              .some(({ field: valueDependencyField, value }) => {
                if (
                  typeof watchValues?.[
                    valueDependencyField?.property?.key ?? ''
                  ] === 'string'
                ) {
                  return (
                    watchValues?.[valueDependencyField?.property?.key ?? ''] ===
                    value?.key
                  );
                }

                if (
                  value &&
                  watchValues?.[valueDependencyField?.property?.key ?? '']
                    ?.length
                ) {
                  return (
                    !!watchValues?.[
                      valueDependencyField?.property?.key ?? ''
                    ] ?? ''.includes(value.key)
                  );
                }

                return value
                  ? !!watchValues?.[
                      valueDependencyField?.property?.key ?? ''
                    ]?.[value.key ?? '']?.selected
                  : false;
              });

            return (
              foundFieldDependency || foundValueDependency || isFieldVisible
            );
          },
          false,
        );

        return visible ? [...list, fieldValue] : list;
      },
      [],
    );

    return valuesList;
  }, [field.values, watchValues, showAllFields]);

  useEffect(() => {
    if (!isVisible) {
      if (typeof getValues(`${prefix}${fieldToManage}`) === 'string') {
        setValue(`${prefix}${fieldToManage}`, '');
      }
      const values = Object.values(
        getValues(`${prefix}${fieldToManage}`) ?? {},
      );
      if (
        !!values.filter((x: any) =>
          typeof x.selected === 'boolean' ? x.selected : !!x,
        ).length
      ) {
        setValue(`${prefix}${fieldToManage}`, '');
      }
    }
  }, [isVisible, fieldToManage, prefix]);
  if (!isVisible) {
    return <React.Fragment />;
  }
  return <ComponentElement {...props} values={filteredFields} />;
}

export default React.memo(AddStockComponent);
