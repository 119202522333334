/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Pane } from 'evergreen-ui';
import { SearchLayoutResultColumn } from '@wgt/types';
import { useBagContext } from '../../../../../../services/bag';

interface FieldProps {
  field: SearchLayoutResultColumn;
  type: 'inserted' | 'available';
  accept?: string | string[];
}

export default function Field({
  field,
  type,
  accept = '',
}: FieldProps): JSX.Element {
  const { move } = useBagContext();
  const elem = useRef<HTMLDivElement | null>(null);
  const [, drop] = useDrop({
    accept,
    drop: (dragged: any) => ({
      dragged: dragged.field,
      dropped: field,
      from: dragged.type,
      to: type,
    }),
  });

  const [{ opacity }, drag] = useDrag({
    item: { type, field },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? '0' : '1',
    }),
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (dropResult) {
        const { from, to, dragged, dropped } = dropResult;
        move({
          from: { item: dragged, kind: from },
          to: { item: dropped, kind: to },
        });
      } else {
        move({
          from: { item, kind: item?.type },
          to: { kind: item?.type === 'available' ? 'inserted' : 'available' },
        });
      }
    },
  });

  const onRef = (e: HTMLDivElement | null) => {
    drag(e);
    elem.current = e;
  };

  drag(drop(elem));
  return (
    <Pane
      ref={onRef}
      padding={5}
      margin={5}
      border
      borderRadius={5}
      backgroundColor="#fff"
      style={{ opacity }}
    >
      {field.title}
    </Pane>
  );
}
