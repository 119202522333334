import React from 'react';
import { PropertyValueVariation } from '@wgt/types';
import CustomHeader from '../../../../components/_molecules/CustomHeader';
import Table from '../../../../components/_organisms/Table';
import {
  LIST_PROPERTY_VALUE_VARIATIONS_GQL,
  DELETE_PROPERTY_VALUE_VARIATION_GQL,
} from '../graphql';

const PropertyValueVariationList: React.FC = () => {
  return (
    <div>
      <CustomHeader title={'Property Value Variations'} showCreate />
      <Table
        hover
        striped
        node="propertyValueVariations"
        columns={[{ label: 'Name', field: 'name' }]}
        query={LIST_PROPERTY_VALUE_VARIATIONS_GQL}
        deleteMutation={DELETE_PROPERTY_VALUE_VARIATION_GQL}
        getMutationArgs={(item: PropertyValueVariation) => ({ id: item.id })}
        getConfirmMessage={(item: PropertyValueVariation) =>
          `Are you sure you want to delete ${item?.name}?`
        }
        showDetail={false}
      />
    </div>
  );
};

export default PropertyValueVariationList;
