import {
  useMutation,
  gql,
  MutationFunctionOptions,
  FetchResult,
  MutationResult,
} from '@apollo/client';
import usePointOfSaleUpdate from '../../usePointOfSaleUpdate';

interface Invoice {
  id: string;
}

interface InvoiceMutationResponse {
  createInvoice: Invoice;
}
interface InvoiceMutationVariables {
  point_of_sale_id: string;
  status: 'active' | 'disabled' | 'development';
}

type CreateAction = (
  options?: MutationFunctionOptions<
    InvoiceMutationResponse,
    InvoiceMutationVariables
  >,
) => Promise<FetchResult<InvoiceMutationResponse>>;

type UseInvoice = [CreateAction, MutationResult<InvoiceMutationResponse>];

export default function useInvoice(): UseInvoice {
  const { pointOfSale } = usePointOfSaleUpdate();
  return useMutation<InvoiceMutationResponse, InvoiceMutationVariables>(
    gql`
      mutation createInvoice($point_of_sale_id: ID!, $status: StatusInvoice!) {
        createInvoice(point_of_sale_id: $point_of_sale_id, status: $status) {
          id
        }
      }
    `,
    {
      variables: {
        point_of_sale_id: pointOfSale.id,
        status: 'active',
      },
    },
  );
}
