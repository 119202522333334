import { Permission } from '@wgt/types';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'evergreen-ui';
import { FaCog } from 'react-icons/fa';

import useMe from '../../../hooks/useMe';
import SidebarPane, { SidebarSimpleExpandContent } from '../../SidebarPane';
import { ADMIN_PAGES } from '../../../utils/navbarPages';

export interface Page {
  name: string;
  title: string;
  url: string;
}

const NavbarAdminControls: React.FC = () => {
  const { t } = useTranslation('menus');
  const [me] = useMe();
  const [isSidebarShown, setSidebarShown] = useState(false);
  const permissions = useMemo(() => {
    if (!me?.roles?.length) {
      return [];
    }

    const rolePermissions =
      me.roles?.reduce(
        (userPermissions: Permission[], role) => [
          ...userPermissions,
          ...role.permissions,
        ],
        [],
      ) ?? [];

    return [...me.permissions, ...rolePermissions].map(
      (permission) => permission.name,
    );
  }, [me]);

  const isSuperAdmin = useMemo(() => {
    if (!me?.roles?.length) {
      return false;
    }
    return me?.roles[0].name === 'super-admin';
  }, [me]);

  const pages = useMemo(
    () =>
      ADMIN_PAGES.filter(
        (pageItem) => permissions.includes(pageItem.name) || isSuperAdmin,
      ),
    [ADMIN_PAGES, permissions, isSuperAdmin],
  );

  return isSuperAdmin ? (
    <>
      <Button
        className="admin-dropdown-menu"
        onClick={() => setSidebarShown(true)}
      >
        <div className="navbar-avatar">
          <span>
            <FaCog size={20} />
          </span>
        </div>
      </Button>
      <SidebarPane
        isShown={isSidebarShown}
        setShown={setSidebarShown}
        size={400}
        closeText={t('Close Admin Menu')}
      >
        <SidebarSimpleExpandContent pages={pages} title={t('Admin Controls')} />
      </SidebarPane>
    </>
  ) : (
    <React.Fragment />
  );
};

export default NavbarAdminControls;
