import React, { useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import ConfirmDialog from '../../../../../components/_molecules/ConfirmDialog';
import { Title, Line, AlertMessage } from '../../../../../components';
import { ReportsList } from '../../types';
import ReportsTable from './ReportsTable';
import { LIST_FILES, DELETE_FILE } from '../MediaCenter/graphql';
import { MediaCenterItemBase, Report } from '@wgt/types';
import { useProductUpdate } from '../Provider';
import useToast from '../../../../../hooks/useToast';
import { Pane } from 'evergreen-ui';
import NewReportForm from './NewReportForm';
import { useTranslation } from 'react-i18next';

const Reports: React.FC = () => {
  const { isFormEnabled, pages } = useProductUpdate();

  const [deleteItem, setDeleteItem] = useState<MediaCenterItemBase | null>(
    null,
  );
  const params = useParams<ParamTypes>();
  const { t } = useTranslation(['product', 'common']);
  const pageId = useMemo(() => pages.active?.id, [pages.active?.id]);
  const { toast } = useToast();
  const filter = useMemo(
    () => ({
      product_id: params.id,
      type: 'guide',
      pages: {
        id: [pageId],
      },
    }),
    [params, pageId],
  );

  const { data, loading } = useQuery<{
    files: { data: Report[] };
  }>(LIST_FILES, {
    variables: {
      filter,
    },
    skip: !pageId,
  });

  const [deleteProductReport, { loading: removing }] = useMutation(
    DELETE_FILE,
    {
      refetchQueries: [
        {
          query: LIST_FILES,
          variables: {
            filter,
          },
        },
      ],
      onCompleted: () => {
        toast('success', t('Report deleted'));
        setDeleteItem(null);
      },
    },
  );

  const handleReportCreatedSuccess = () => {
    toast('success', t('Report Created'));
  };

  const reportListObj: ReportsList = useMemo(() => {
    if (!data?.files?.data) {
      return {
        right: [] as MediaCenterItemBase[],
        left: [] as MediaCenterItemBase[],
      };
    }
    const separatedList = data.files.data.reduce(
      (acc, currentItem, currentIndex) => {
        const transformAcc = { ...acc };

        if (currentIndex >= 4 && currentIndex % 4 === 0) {
          transformAcc.left.push(currentItem);
        } else {
          transformAcc.right.push(currentItem);
        }
        return transformAcc;
      },
      { right: [] as MediaCenterItemBase[], left: [] as MediaCenterItemBase[] },
    );
    return separatedList;
  }, [data?.files?.data]);

  return (
    <div className="reports-content mt-4">
      <div className="d-flex justify-content-between d-flex align-items-center">
        <Title heading="h2">{t('Reports')}</Title>
      </div>

      <Line />

      <Row>
        <Col lg={6} xs={12}>
          {!!reportListObj.left.length && (
            <Pane className="reports-content-list">
              <ReportsTable
                reports={reportListObj.left}
                setDeleteItem={setDeleteItem}
              />
            </Pane>
          )}
          {isFormEnabled && (
            <NewReportForm
              handleReportCreatedSuccess={handleReportCreatedSuccess}
            />
          )}
        </Col>
        <Col lg={6} xs={12} className="reports-content-list">
          <Pane display="flex" flexDirection="column" width="100%">
            {!!data?.files.data.length && !loading && (
              <ReportsTable
                reports={reportListObj.right}
                setDeleteItem={setDeleteItem}
              />
            )}
            {!data?.files.data.length && !loading && (
              <Pane className="w-100">
                <AlertMessage>
                  {t('There is no Report registered at the moment.')}
                </AlertMessage>
              </Pane>
            )}
          </Pane>
        </Col>
      </Row>

      <ConfirmDialog
        isShown={!!deleteItem}
        onConfirm={() => {
          deleteProductReport({
            variables: {
              id: deleteItem?.id,
            },
          });
        }}
        title={t('Confirm delete report?')}
        onClose={() => setDeleteItem(null)}
        isLoading={removing}
      />
    </div>
  );
};

export default Reports;
