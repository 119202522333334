import React from 'react';
import { useBagContext } from '../../../../../../../services/bag';
import TollFreePhoneItem from './TollFreePhoneItem';

interface TollFreePhone {
  id?: number;
  name?: string;
}

export default function TollFreePhones(): JSX.Element {
  const {
    tollFreePhone: { fields },
  } = useBagContext<{
    tollFreePhone: { fields: TollFreePhone[] };
  }>();

  return (
    <div className="simple-input mt-3">
      {fields.map((tollFreePhone, index) => (
        <TollFreePhoneItem
          tollFreePhone={tollFreePhone as TollFreePhone}
          index={index}
          key={`toll-free-phone-${index}`}
        />
      ))}
    </div>
  );
}
