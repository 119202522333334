import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  GIVE_PERMISSION_TO_ROLE_GQL,
  REVOKE_PERMISSION_FROM_ROLE_GQL,
} from '../graphql';
import BtnLoading from '../../../../components/_molecules/BtnLoading';
import { Checkbox } from 'evergreen-ui';
import { PermissionProps } from '../types';

const PermissionComponent: React.FC<PermissionProps> = ({
  role,
  permission,
  hasPermission,
}) => {
  const [addPermission, { data, loading }] = useMutation(
    GIVE_PERMISSION_TO_ROLE_GQL,
  );
  const [
    revokePermission,
    { data: revokedData, loading: revokingPermission },
  ] = useMutation(REVOKE_PERMISSION_FROM_ROLE_GQL);
  const [isChecked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(!!hasPermission);
  }, []);

  useEffect(() => {
    if (data?.givePermissionToRole?.message) {
      setChecked(true);
    }
  }, [data]);

  useEffect(() => {
    if (revokedData?.revokePermissionToRole?.message) {
      setChecked(false);
    }
  }, [revokedData]);

  const onClick = () => {
    if (!isChecked) {
      addPermission({
        variables: {
          id: role?.id,
          permission_id: permission?.id,
        },
      });
    } else {
      revokePermission({
        variables: {
          id: role?.id,
          permission_id: permission?.id,
        },
      });
    }
  };

  if (loading || revokingPermission) {
    return <BtnLoading />;
  }

  return permission ? (
    <Checkbox
      label={permission.name}
      checked={isChecked}
      disabled={loading}
      onChange={onClick}
    />
  ) : (
    <></>
  );
};

export default PermissionComponent;
