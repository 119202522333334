import { ProductBase } from '@wgt/types';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

const SearchProductDetailsProductIds: React.FC = () => {
  const { getValues } = useFormContext<ProductBase>();
  const { t } = useTranslation(['product', 'common']);
  const product = getValues();
  return (
    <Row className="p-3">
      <Col>
        {t('Lot ID')}
        <br />
        <i>{product.lot_id ?? t('common:Not Available')}</i>
      </Col>
      <Col>
        {t('Tray ID')}
        <br />
        <i>{product.tray_id ?? t('common:Not Available')}</i>
      </Col>
      {product.boxes?.map((box, key) => (
        <Col key={`box-id-${key}`}>
          {`${t('Box ID')} ${key + 1}`}
          <br />
          <i>{box.name ?? t('common:Not Available')}</i>
        </Col>
      ))}
      {product.crates?.map((create, key) => (
        <Col key={`crate-id-${key}`}>
          {`${t('Crate ID')} ${key + 1}`}
          <br />
          <i>{create.name ?? t('common:Not Available')}</i>
        </Col>
      ))}
    </Row>
  );
};

export default SearchProductDetailsProductIds;
