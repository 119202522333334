import React, { useState, useEffect } from 'react';
import useToggler from '@wgt/shared/hooks/useToggler';
import { ProductBase } from '@wgt/types';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { Button } from '..';
import PageIDs from '../../pages/Crm/Products/Update/Header/PageIDs';
import TraceDocumentsButton from '../TraceDocuments';
import WorkOrderScheduleModal from '../WorkOrderSchedule';
import useWorkOrderContext from '../WorkOrder/useWorkOrderContext';
import WorkOrder from '../WorkOrder';
import { useProductUpdate } from '../../pages/Crm/Products/Update/Provider';

const SearchProductDetailsActions: React.FC = () => {
  const { getValues } = useFormContext();
  const { t } = useTranslation(['product', 'pointOfSales']);
  const [isScheduleFormOpen, toggleScheduleForm] = useToggler();
  const [createdScheduleId, setCreatedScheduleId] = useState<string>();
  const { isOpen, setOpen, setActivePageId } = useWorkOrderContext();
  const { pages } = useProductUpdate();

  useEffect(() => setActivePageId(pages?.active), [pages?.active]);

  return (
    <>
      <div className="d-flex justify-content-between p-3">
        <div>
          <Button
            type="orange"
            className={classnames('mr-4', { outline: !isOpen })}
            onClick={() => setOpen(!isOpen)}
          >
            {t('pointOfSales:Open Work')}
          </Button>
          <Button type="orange">{t('pointOfSales:History')}</Button>
          <Link
            to={`/crm/products/${getValues('id')}`}
            className="btn btn-outline-secondary ml-3"
          >
            {t('View')}
          </Link>
        </div>
        <div className="d-flex ">
          <Button type="secondary" onClick={toggleScheduleForm}>
            {t('Calendar')}
          </Button>
          <div className="mx-4">
            <TraceDocumentsButton productId={getValues('id')} />
          </div>
          <PageIDs productId={getValues('id')} />
        </div>
        {isScheduleFormOpen && (
          <WorkOrderScheduleModal
            scheduleId={createdScheduleId}
            products={[getValues() as ProductBase]}
            toggle={toggleScheduleForm}
            onCompleted={({ id }) => setCreatedScheduleId(id)}
          />
        )}
      </div>
      <WorkOrder />
    </>
  );
};

export default SearchProductDetailsActions;
