import { NavbarPageBase } from '@wgt/types';

export default [
  {
    name: 'list-category-groups',
    title: 'Category Groups',
    url: '/crm/category-groups',
  },
  {
    name: 'list-categories',
    title: 'Categories',
    url: '/crm/categories',
  },
  {
    name: 'list-shape-group',
    title: 'Shapes',
    url: '/crm/shape-groups',
  },
  {
    name: 'list-firms',
    title: 'Firms',
    url: '/crm/firms',
  },
  {
    name: 'list-roles',
    title: 'Roles',
    url: '/crm/roles',
  },
  {
    name: 'list-properties',
    title: 'Properties',
    url: '/crm/properties',
  },
  {
    name: 'list-property-value-variations',
    title: 'Property Value Variation',
    url: '/crm/property-value-variation',
  },
  {
    name: 'list-templates',
    title: 'Templates',
    url: '/crm/templates',
  },
  {
    name: 'list-users',
    title: 'Users',
    url: '/crm/users',
  },
  {
    name: 'point-sales',
    title: 'Point of Sales',
    url: '/crm/point-sales',
  },
  {
    name: 'work-order-type',
    title: 'Work Order Type',
    url: '/crm/work-order-type',
  },
  {
    name: 'newsletter',
    title: 'Newsletter',
    url: '/crm/newsletter',
  },
] as NavbarPageBase[];
