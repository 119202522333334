import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'reactstrap';
import { Button } from '../../../../../../components';
import Loader from '../../../../../../components/_organisms/Loader';
import { GET_MINERAL_GROUP } from '../../../graphql';

export default function MineralGroupUpdateModal({
  mineralId,
  onCancel,
  onSubmit,
  isLoading,
}: {
  mineralId?: string | number;
  onCancel(): void;
  onSubmit(mineralName: string): void;
  isLoading?: boolean;
}): JSX.Element {
  const [mineralName, setMineralName] = useState<string>('');
  const { t } = useTranslation();

  const { loading } = useQuery(GET_MINERAL_GROUP, {
    variables: { id: mineralId },
    skip: !mineralId,
    onCompleted: ({ mineralGroup: { name } }) => setMineralName(name),
  });

  return (
    <Modal isOpen={!!mineralId}>
      {loading ? (
        <Loader />
      ) : (
        <div className="p-5">
          <input
            onChange={(e) => setMineralName(e.target.value)}
            className="form-control"
            value={mineralName}
          />
          <div className="mt-4 d-flex justify-content-end">
            <Button
              type="secondary"
              htmlType="button"
              onClick={onCancel}
              disabled={isLoading}
            >
              {t('Cancel')}
            </Button>
            <Button
              type="primary"
              htmlType="button"
              className="ml-2"
              disabled={isLoading}
              isLoading={isLoading}
              onClick={() => onSubmit(mineralName)}
            >
              {t('Save')}
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
}
