import React from 'react';
import InternalRoutes from '../../../components/_organisms/InternalRoutes';
import useCrmRoutes from '../../../hooks/useCrmRoutes';
import Category from './Category';

export default function ImportPage(): JSX.Element {
  const { isCrmInternalRoute, actualRoute } = useCrmRoutes();
  const routes = [
    {
      component: Category,
      name: 'Firm',
      path: `/crm/${actualRoute}/category/:id`,
      exact: true,
    },
  ];

  if (isCrmInternalRoute) {
    return <InternalRoutes routes={routes} />;
  }

  return <React.Fragment />;
}
