import React from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
  field: string;
  isMulti?: boolean;
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  option?: any;
  required?: boolean;
}

export default function PropertyValueCheckBox({
  field,
  disabled,
  option,
}: Props): JSX.Element {
  const { register, getValues } = useFormContext();

  return (
    <div>
      <input
        className="field-value-variation-input"
        type="checkbox"
        ref={register()}
        name={`${field}.${option.key}.selected`}
        defaultChecked={getValues(`${field}.${option.key}.selected`)}
        id={`property-value-${option.id}`}
        disabled={disabled}
      />
      <label htmlFor={`property-value-${option.id}`}>{option.name}</label>
    </div>
  );
}
