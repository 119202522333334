/* eslint-disable @typescript-eslint/no-explicit-any */
import { ShapeDescription } from '@wgt/types';
import { Pane } from 'evergreen-ui';
import React, { useMemo } from 'react';
import { ArrayField } from 'react-hook-form';
import './styles.scss';

export default function ShapeDescriptionCheckbox({
  description,
  editable,
  onChange,
  fields,
}: {
  description: ShapeDescription;
  editable?: boolean;
  onChange(e: React.ChangeEvent<HTMLInputElement>): void;
  fields: Partial<ArrayField<Record<string, any>, '__id'>>[];
}): JSX.Element {
  const isChecked = useMemo(
    () => fields.some((found) => found.id === description.id),
    [fields],
  );

  return (
    <Pane className="form-shape-checkbox">
      <Pane>
        <input
          id={description.id}
          type="checkbox"
          disabled={!editable}
          value={description.id}
          onChange={onChange}
          checked={isChecked}
        />
        {description.icon_url && <img src={description.icon_url} />}
      </Pane>

      <label htmlFor={description.id}>{description.name}</label>
    </Pane>
  );
}
