import { MediaCenterItemBase } from '@wgt/types';
import React from 'react';
import { FaTimes } from 'react-icons/fa';

const FilesList: React.FC<{
  files: MediaCenterItemBase[];
  setDeleteItemIndex(item: string): void;
  isLoading?: boolean;
}> = ({ setDeleteItemIndex, files, isLoading }) => {
  return (
    <div className="scroll">
      <div className="container-fluid">
        <div className="scrolling-wrapper row flex-row flex-nowrap no-gutters">
          {files?.map((fileItem, key) => (
            <div
              key={`media-${key}`}
              className="col-auto d-flex align-items-center p-1 bg-white mx-2 "
            >
              <div className="card card-block">
                <img src={fileItem.file_url} className="card-img-top" />
                {!isLoading && (
                  <FaTimes
                    className="remove-media-button"
                    onClick={() =>
                      setDeleteItemIndex(fileItem?.id?.toString() ?? '')
                    }
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FilesList;
