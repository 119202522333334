import { gql } from '@apollo/client';

export const SEARCH = gql`
  query productPageIds($filter: ProductPageIdFilterInput) {
    productPageIds(filter: $filter) {
      data {
        id
        name
        product {
          id
          sku
        }
      }
    }
  }
`;

export const ASSOCIATE = gql`
  mutation associateSKU($id: ID!, $sku: String!) {
    associateSKU(id: $id, sku: $sku) {
      message
    }
  }
`;
