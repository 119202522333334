import React, { useState } from 'react';
import Context from './CollapseContext';

interface Props {
  options: string[];
}

const CollapseProvider: React.FC<Props> = ({ options, children }) => {
  const [active, setActive] = useState<string | null>(null);

  const set = (tab: string) => {
    if (active === tab) {
      return setActive(null);
    }
    const result = options.find((x) => x === tab);
    if (result) {
      setActive(result);
    }
  };

  return (
    <Context.Provider value={{ active, set, options }}>
      {children}
    </Context.Provider>
  );
};

export default CollapseProvider;
