import React, { useMemo } from 'react';
import WorkOrderItem from '../WorkOrderItemForm/WorkOrderItem';
import useWorkOrderItemFormContext from './useWorkOrderItemContext';
import WorkOrderItemsFooter from './WorkOrderItemsFooter';

export default function WorkOrderItems(): JSX.Element {
  const [{ workOrderItems }] = useWorkOrderItemFormContext();

  const totalPrice = useMemo(
    () =>
      workOrderItems.reduce((ac, value) => {
        return ac + value.hour_price;
      }, 0),
    [workOrderItems],
  );

  return (
    <>
      {workOrderItems.map((order, index) => (
        <WorkOrderItem workOrderItem={order} key={order?.id ?? index} />
      ))}
      <WorkOrderItem />
      <WorkOrderItemsFooter totalPrice={totalPrice} />
    </>
  );
}
