import { ProductBase } from '@wgt/types';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import Title from '../Title';

const SearchProductDetailsOffice: React.FC = () => {
  const { getValues } = useFormContext<ProductBase>();
  const { t } = useTranslation('product');
  const { office } = getValues();
  return (
    <Row>
      <Col>
        <Title heading="h4">{t('Office')}</Title>
        <p>
          {office?.name}
          <br />
          {office?.address}
        </p>
      </Col>
      <Col>
        <Title heading="h4">{t('Sales Representatives')}</Title>
        <p>
          {/* there is NO sales_representative in product GQL yet */}
          Name Last Name <br />
          Name Last Name <br />
          Name Last Name <br />
        </p>
      </Col>
      <Col>
        <Title heading="h4">{t('Executive Buyer')}</Title>
        <p>
          {/* there is NO executive_buyer in product GQL yet */}
          Name Last Name <br />
          Name Last Name <br />
          Name Last Name <br />
        </p>
      </Col>
    </Row>
  );
};

export default SearchProductDetailsOffice;
