import React, { useMemo } from 'react';
import { convertJsonLayoutToTemplateFields } from '@wgt/converters';
import { LayoutStructure } from '@wgt/types';
import { useProductUpdate } from '../../pages/Crm/Products/Update/Provider';
import TemplateFieldsDetails from './TemplateFieldsDetails';

const TemplateFields: React.FC = () => {
  const { pages } = useProductUpdate();

  const [first, second] = useMemo(() => {
    const resultsLayout = pages.active?.category?.template
      ?.result_layout as LayoutStructure;

    const fields = pages.active?.category?.template?.fields;

    if (!resultsLayout) {
      return [[], []];
    }

    return Object.values(resultsLayout).map((layout) =>
      convertJsonLayoutToTemplateFields({
        layout,
        fields: fields ?? [],
      }),
    );
  }, [pages?.active]);

  if (typeof pages.activeIndex === 'undefined') {
    return <React.Fragment />;
  }
  return (
    <>
      <TemplateFieldsDetails
        rows={first}
        prefix={`pages[${pages.activeIndex}].fields.`}
      />
      <TemplateFieldsDetails
        rows={second}
        prefix={`pages[${pages.activeIndex}].fields.`}
      />
    </>
  );
};

export default TemplateFields;
