import React from 'react';
import { Avatar, IconButton, Popover } from 'evergreen-ui';
import { FaChevronDown, FaChevronUp, FaEllipsisH } from 'react-icons/fa';
import { Card, CardBody, Col, Row } from 'reactstrap';
import useToggler from '@wgt/shared/hooks/useToggler';
import useWorkOrderSchedule from '../useWorkOrderSchedule';
import { useTranslation } from 'react-i18next';
import LogoDummy from '../../../assets/logo-dummy.png';
import NoProfilePhoto from '../../../assets/no-profile-photo.jpg';
interface FakeContact {
  id: number;
  name: string;
  email: string;
  position: string;
  address: string;
  phone: string;
  firm?: FakeFirm;
}

export interface FakeFirm {
  id: number;
  name: string;
  address: string;
  phone: string;
  email: string;
  contacts: FakeContact[];
}

export const firms = [
  {
    id: 1,
    name: 'World Gem Trade',
    address: 'United States, California, 8605 santa Monica Blvd',
    phone: '213 986 3526',
    email: 'hello@worldgemtrade.com',
    contacts: [
      {
        id: 1,
        name: 'João Hencke',
        email: 'joao.hencke@worldgemtrade.com',
        address: 'United States, California, 8605 santa Monica Blvd',
        phone: '213 986 3526',
        position: 'General manager',
      },
      {
        id: 2,
        name: 'Edilson Junior',
        email: 'edilson.junior@worldgemtrade.com',
        address: 'United States, California, 8605 santa Monica Blvd',
        phone: '213 986 3526',
        position: 'General manager',
      },
    ],
  },
  {
    id: 2,
    name: 'King Gems',
    address: 'United States, California, 8605 santa Monica Blvd',
    phone: '213 986 3526',
    email: 'hello@worldgemtrade.com',
    contacts: [
      {
        id: 4,
        name: 'Mateus Gamba',
        email: 'mateus.gamba@kinggems.com',
        address: 'United States, California, 9663 santa Monica Blvd',
        phone: '213 986 3526',
        position: 'General manager',
      },
    ],
  },
] as FakeFirm[];

interface ContactListProps {
  items: FakeFirm[];
  editable?: boolean;
  onSelect?: (contact: FakeContact) => void;
}

export default function ContactList({
  items,
  editable,
  onSelect,
}: ContactListProps): JSX.Element {
  const [isUsersShown, toggleUsersShown] = useToggler(true);
  const [{ activeTab }] = useWorkOrderSchedule();
  const { t } = useTranslation('workOrder');
  return (
    <>
      {items.map((item, index) => (
        <React.Fragment key={`firm-${index}`}>
          <Card className="mt-3">
            <CardBody>
              <Row>
                <Col xs="auto">
                  <img src={LogoDummy} />
                </Col>
                <Col>
                  <h5 className="m-0">{item.name}</h5>
                  <p className="my-2">{item.address}</p>
                  <p className="mb-0  ">
                    <span className="mr-4 font-weight-bold">{item.phone}</span>
                    {item.email}
                  </p>
                </Col>
                <Col
                  xs="2"
                  lg="1"
                  className="d-flex flex-column justify-content-end"
                >
                  <Popover
                    content={
                      <div className="d-flex flex-column p-2">
                        {!!activeTab.context?.type ? (
                          <p>{t(`Add to ${activeTab.context?.type}`)}</p>
                        ) : (
                          <>
                            <p>{t(`Add to Broker`)}</p>
                            <p>{t(`Add to Collector`)}</p>
                            <p>{t(`Add to Supplier`)}</p>
                          </>
                        )}
                      </div>
                    }
                  >
                    <IconButton
                      type="button"
                      appearance="minimal"
                      icon={FaEllipsisH}
                      className="mr-0 pr-0"
                    />
                  </Popover>
                  <IconButton
                    type="button"
                    appearance="minimal"
                    icon={isUsersShown ? FaChevronDown : FaChevronUp}
                    onClick={toggleUsersShown}
                    className="mr-0 pr-0"
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
          {isUsersShown &&
            item.contacts.map((contact, indexContact) => (
              <Card
                className="h-25 my-1"
                key={`firm-contact-${indexContact}`}
                style={{ cursor: editable ? 'pointer' : 'initial' }}
                onClick={() => editable && onSelect?.(contact)}
              >
                <CardBody className="py-2">
                  <Row>
                    <Col xs="auto">
                      <Avatar src={NoProfilePhoto} size={40} />
                    </Col>
                    <Col className="pt-1">
                      <Row>
                        <Col
                          xs="12"
                          lg={4}
                          className="d-flex align-items-center"
                        >
                          <div>
                            <h5 className="m-0">{contact.name}</h5>
                            <p className="mb-0">{contact.position}</p>
                          </div>
                        </Col>
                        <Col
                          xs="12"
                          lg={8}
                          className="d-flex align-items-center"
                        >
                          <div>
                            <p className="m-0">{contact.address}</p>
                            <p className="mb-0">
                              <span className="mr-4 font-weight-bold">
                                {contact.phone}
                              </span>
                              {contact.email}
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            ))}
        </React.Fragment>
      ))}
    </>
  );
}
