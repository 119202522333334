import { gql } from '@apollo/client';

export const GIVE_PERMISSION_TO_USER_GQL = gql`
  mutation givePermissionToUserMutation($id: ID!, $permission_id: ID!) {
    givePermissionToUser(id: $id, permission_id: $permission_id) {
      message
    }
  }
`;
export const REVOKE_PERMISSION_FROM_USER_GQL = gql`
  mutation revokePermissionToUserMutation($id: ID!, $permission_id: ID!) {
    revokePermissionToUser(id: $id, permission_id: $permission_id) {
      message
    }
  }
`;

export const permissionIdentityFragment = gql`
  fragment permissionIdentity_Part on Permission {
    id
    name
    scope
  }
`;

export const LIST_PERMISSIONS = gql`
  query permissions {
    permissions(first: 10000) {
      data {
        ...permissionIdentity_Part
      }
    }
  }
  ${permissionIdentityFragment}
`;

export const LIST_USER_PERMISSIONS = gql`
  query userPermissions($userId: ID!, $scope: PermissionScopes) {
    userPermissions: permissions(
      first: 1000
      filter: { users: { id: [$userId] }, scope: $scope }
    ) {
      data {
        ...permissionIdentity_Part
      }
    }
  }
  ${permissionIdentityFragment}
`;
