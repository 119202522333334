import React from 'react';
import PriceInput from '../../../../../components/PriceInput';

interface CaratWeightPops {
  displayText: string;
}

const CaratWeight: React.FC<CaratWeightPops> = ({ displayText }) => {
  return (
    <PriceInput
      displayText={displayText}
      title="Total Carat Weight"
      muted="ct"
    />
  );
};

export default CaratWeight;
