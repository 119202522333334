import React from 'react';
import { Abbreviation } from '@wgt/types';
import { useBagContext } from '../../../../../../../services/bag';
import AbbreviationsInput from './AbbreviationsInput';

export default function Abbreviations(): JSX.Element {
  const {
    abbreviation: { fields },
  } = useBagContext<{
    abbreviation: { fields: Abbreviation[] };
  }>();

  return (
    <div className="simple-input">
      {fields.map((abbreviation, index, abbreviations) => (
        <AbbreviationsInput
          abbreviation={abbreviation as Abbreviation}
          index={index}
          key={abbreviation.id}
          total={abbreviations.length}
        />
      ))}
    </div>
  );
}
