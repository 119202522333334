import { PageId } from '@wgt/types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Popover } from 'evergreen-ui';

interface PageIDListProps {
  pages: PageId[];
  value?: PageId;
  remove?: (page: PageId) => void;
}
export default function PageIDList({
  pages,
  value,
  remove,
}: PageIDListProps): JSX.Element {
  const { t } = useTranslation('product');

  return (
    <div className="page-ids align-items-center">
      <div className="input-group input-group-sm">
        <div className="input-group-prepend">
          <span className="input-group-text">
            <p className="m-0">{t('Page ID')}</p>
          </span>
        </div>
        {(pages.length > 1 && (
          <Popover
            content={pages.map((page) => (
              <div
                className="d-flex justify-content-between align-items-center m-1"
                key={`page-move-${page.id}`}
              >
                <span>&nbsp;{page.name}</span>
                {!!remove && (
                  <button
                    type="button"
                    className="btn btn-danger btn-sm btn-clear"
                    onClick={() => remove(page)}
                  >
                    {t('remove')}
                  </button>
                )}
              </div>
            ))}
          >
            <button
              id="pageId"
              className="btn btn-clear  btn-sm text-center d-flex align-items-center justify-content-between m-0 p-0 flex-1"
              type="button"
            >
              <p>
                <span>{pages.findIndex((x) => x.id === value?.id) + 1}</span>
                {value?.name}
              </p>
            </button>
          </Popover>
        )) || (
          <button
            id="pageId"
            className="btn btn-clear  btn-sm text-center d-flex align-items-center justify-content-between m-0 p-0 flex-1"
            type="button"
          >
            {value?.name}

            {!!value && !!remove && (
              <button
                type="button"
                className="btn btn-sm btn-danger btn-clear"
                onClick={() => remove(value)}
              >
                {t('remove')}
              </button>
            )}
          </button>
        )}
      </div>
    </div>
  );
}
