import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Label } from 'reactstrap';
import { SimpleInput } from '../../';
import Line from '../../Line';

const InputRangeTemplate: React.FC = () => {
  const { t } = useTranslation('template');
  const { errors, getValues } = useFormContext();

  return (
    <>
      <Line className="mt-4" />
      <Label>
        <strong>{t('Set Minimum & Maximum values for Range component')}</strong>
      </Label>
      <SimpleInput
        required
        type="number"
        field="config.min"
        label={t('Minimum value')}
        hint={
          errors.config?.min?.type === 'validate'
            ? t('Minimum value must be less than Maximum value')
            : errors?.config?.min?.message || ''
        }
        className="mb-3"
        registerProps={{
          validate: (value: string) => {
            return Number(value) < Number(getValues('config.max'));
          },
        }}
      />
      <SimpleInput
        required
        type="number"
        field="config.max"
        label={t('Maximum value')}
        hint={errors.config?.max?.message || ''}
      />
    </>
  );
};
export default InputRangeTemplate;
