import { useCallback } from 'react';

type UseAnchor = (elementId: string) => void;

const useAnchor = (): UseAnchor =>
  useCallback((elementId: string) => {
    document.getElementById(elementId)?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }, []);

export default useAnchor;
