import { UserProfile } from '@wgt/types';
import React, { useContext } from 'react';
import { USER_DETAILS } from '../pages/Crm/Profile/graphql';
import useFakeQuery from './useFakeQuery';
import useMe from './useMe';
const PROFILE_DETAILS: UserProfile = {
  id: 1,
  first_name: 'Admin',
  middle_name: 'WGT',
  last_name: 'User',
  email: 'admin@worldgemtrade.com',
  jobTitles: [{ id: 1, name: 'Admin Job Title', level: 0 }],
  deparments: [{ id: 1, name: 'Admin Department' }],
  address1: 'Address 1',
  address2: 'Address 2',
  country: { id: 1, name: 'Country' },
  city: 'City',
  zipcode: 'Zip Code / Postal Code',
  phone: '855 285 9775',
  mobile: '213 986 3526',
  fax: '855 285 9775',
  firm: {
    id: 1,
    name: 'Firm Name',
    website: 'worldgemtrade.com',
    slogan: 'Trace for better world',
    description:
      'Online network that provides trace busienss services to manage everything. ',
    toll_free_phone: '855 285 9775',
    fax: '855 285 9775',
    email: 'info@company.com',
    local_phone: '213 986 3526',
    address: {
      address1: 'Address 1',
      address2: 'Address 2',
      country: { id: 1, name: 'Country' },
      state: 'State / Province',
      city: 'City',
      postal_code: 'Zip Code / Postal Code',
    },
  },
  homeLocations: [
    {
      address1: '8605 santa Monica Blvd #9999',
      address2: 'Address 2',
      email: 'info@company.com',
      city: 'Los Angeles',
      state: 'California',
      country: { id: 1, name: 'United States' },
      zipcode: '900069-4109',
      toll_free_phone: '855 285 9775',
      local_phone: '855 285 9775',
      mobile: '855 285 9775',
      fax: '855 285 9775',
    },
    {
      address1: '8605 santa Monica Blvd #9999',
      address2: 'Address 2',
      email: 'info@company.com',
      city: 'Los Angeles',
      state: 'California',
      country: { id: 1, name: 'United States' },
      zipcode: '900069-4109',
      toll_free_phone: '855 285 9775',
      local_phone: '855 285 9775',
      mobile: '855 285 9775',
      fax: '855 285 9775',
    },
  ],
};
interface ProfileContextData {
  user?: UserProfile;
  loading?: boolean;
}

export const ProfileContext = React.createContext<ProfileContextData>(
  {} as ProfileContextData,
);

export const ProfileProvider: React.FC = ({ children }) => {
  const [me] = useMe();

  const { data, loading } = useFakeQuery<UserProfile | undefined>(
    USER_DETAILS,
    {
      defaultValue: PROFILE_DETAILS,
      skip: !me?.id,
      variables: {
        id: me?.id,
      },
    },
  );

  const contextValues: ProfileContextData = {
    user: data,
    loading,
  };

  return (
    <ProfileContext.Provider value={contextValues}>
      {children}
    </ProfileContext.Provider>
  );
};

export default function useProfileContext(): ProfileContextData {
  return useContext(ProfileContext);
}
