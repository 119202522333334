import React, { useContext } from 'react';

import { Col, Row } from 'reactstrap';
import { TemplateLayoutContext } from '../../../../../../../hooks/useTemplateLayout';
import { Button } from '../../../../../../../components';

import TemplateLayoutGroupForm from '../TemplateLayoutGroupForm';
import { SectionStringType, TemplateLayoutRow } from '@wgt/types';

interface TemplateLayoutStructureItemProps {
  rowItems: TemplateLayoutRow;
  rowNumber: number;
  section: SectionStringType;
}

const TemplateLayoutStructureItem: React.FC<TemplateLayoutStructureItemProps> = ({
  rowItems,
  rowNumber,
  section,
}) => {
  const { onRemoveRow } = useContext(TemplateLayoutContext);
  return (
    <div className="m-3">
      <Row noGutters>
        <Col xs="12" className="text-right">
          <Button
            small
            type="danger"
            onClick={() => onRemoveRow(rowNumber, section)}
          >
            Remove
          </Button>
        </Col>
      </Row>
      <Row className="template-layout-container-structure-row" noGutters>
        {rowItems.map((rowItem, key) => (
          <Col
            key={key}
            md={12 / rowItems.length}
            lg={12 / rowItems.length}
            className="template-layout-container-structure-column"
          >
            <TemplateLayoutGroupForm
              colNumber={key}
              rowNumber={rowNumber}
              rowItem={rowItem}
              section={section}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default TemplateLayoutStructureItem;
