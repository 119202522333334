import { Report } from '@wgt/types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FiFileText } from 'react-icons/fi';
import { Button } from '../../../../../../../components';

interface PosProductReportsProps {
  reports: Report[];
}
const PosProductReports: React.FC<PosProductReportsProps> = ({ reports }) => {
  const { t } = useTranslation();

  return (
    <>
      <dt className="col-sm-3 text-right">{t('common:Report')}</dt>
      <dd className="col-sm-9">
        {!reports.length
          ? t('No report created')
          : reports.map((report, key) => (
              <Button
                key={`por-product-report-${key}`}
                type="gray"
                iconBefore={FiFileText}
                className="outline"
                small
              >
                {t('View')}
              </Button>
            ))}
      </dd>
    </>
  );
};

export default PosProductReports;
