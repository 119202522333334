import React from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button, FormGroup } from 'reactstrap';
import BtnLoading from '../BtnLoading';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface FormProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleForgot: any;
  isSubmitting: boolean;
}
export interface FormValues {
  email: string;
  password: string;
}

const ForgotPasswordForm: React.FC<FormProps> = ({
  handleForgot,
  isSubmitting,
}) => {
  const { register, handleSubmit, errors } = useForm();
  const { t } = useTranslation(['authentication', 'common']);

  return (
    <Form onSubmit={handleSubmit(handleForgot)}>
      <FormGroup>
        <Link to="/auth" className="forgot-link">
          {t('common:cancel')}
        </Link>
        <label htmlFor="email">{t('email.label')}</label>
        <input
          className={`form-control ${errors?.email && 'is-invalid'}`}
          id="email"
          name="email"
          placeholder={t('email.placeholder')}
          ref={register({
            required: true,
          })}
          defaultValue=""
          aria-invalid={errors?.email ? 'true' : 'false'}
        />
      </FormGroup>

      <Button
        type="submit"
        disabled={isSubmitting}
        size="sm"
        color="success"
        block
      >
        {isSubmitting ? <BtnLoading /> : t('Submit')}
      </Button>
    </Form>
  );
};

export default ForgotPasswordForm;
