/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { IconButton, Pane } from 'evergreen-ui';
import Select, { components, GroupProps } from 'react-select';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { Label } from 'reactstrap';

const ACTIVITIES = [
  {
    label: 'Sales Lead',
    value: 'sales_lead',
    options: [
      {
        label: 'Seller',
        value: 'seller',
      },
      {
        label: 'Buyer',
        value: 'buyer',
      },
      {
        label: 'Broker',
        value: 'broker',
      },
    ],
  },
  {
    label: 'Consignment',
    value: 'consignment',
  },
  {
    label: 'Invoice Development',
    value: 'invoice_development',
  },
  {
    label: 'Consignment Expiry Date',
    value: 'consignment_expiry_date',
  },
  {
    label: 'Sold Expiry Date',
    value: 'sold_expiry_date',
  },
];

type Context = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const GroupContext = React.createContext<Context>({
  isOpen: true,
  setIsOpen: (val) => val,
});

function Group({
  children,
  ...props
}: GroupProps<{ label: string; value: string }>): JSX.Element {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <GroupContext.Provider value={{ isOpen, setIsOpen }}>
      <components.Group {...props}>{isOpen && children}</components.Group>
    </GroupContext.Provider>
  );
}

function GroupHeading({
  children,
  ...props
}: GroupProps<{ label: string; value: string }>): JSX.Element {
  const { isOpen, setIsOpen } = React.useContext(GroupContext);

  return (
    <components.GroupHeading {...props}>
      <Pane
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        onClick={() => setIsOpen((x) => !x)}
      >
        {children}
        <IconButton
          icon={isOpen ? FaChevronUp : FaChevronDown}
          appearance="minimal"
        />
      </Pane>
    </components.GroupHeading>
  );
}

export default function SelectActivity({ onChange, value }: any): JSX.Element {
  return (
    <>
      <Label>Activity</Label>
      <Select
        options={ACTIVITIES}
        components={{
          Group,
          GroupHeading,
        }}
        onChange={onChange}
        value={value}
        maxMenuHeight={400}
      />
    </>
  );
}
