import AmlStatement from './Invoices/AmlStatement';
import Simple from './Invoices/Simple';

export default [
  {
    component: AmlStatement,
    name: 'AML Statement',
    path: '/reports/invoices/aml-statement/:id',
  },
  {
    component: Simple,
    name: 'Point Of Sales',
    path: '/reports/invoices/simple/:id',
  },
];
