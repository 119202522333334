import { PageId } from '@wgt/types';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Popover, PopoverBody } from 'reactstrap';

interface PageIDSelectorProps {
  pages: PageId[];
  value?: PageId;
  onChange?: (page: PageId) => void;
}
export default function PageIDSelector({
  pages,
  value,
  onChange,
}: PageIDSelectorProps): JSX.Element {
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const { t } = useTranslation('product');
  const toggle = useCallback(() => setPopoverOpen((x) => !x), []);

  return (
    <div className="page-ids align-items-center" id="field-page-id">
      <div className="input-group input-group-sm">
        <div className="input-group-prepend">
          <span className="input-group-text">
            <p className="m-0">{t('Page ID')}</p>
          </span>
        </div>
        <button
          id="pageId"
          className="btn btn-clear  btn-sm text-center d-flex align-items-center justify-content-between m-0 p-0 flex-1"
          type="button"
        >
          <p>
            <span>{pages.findIndex((x) => x.id === value?.id) + 1}</span>
            {value?.name}
          </p>
        </button>
        <Popover
          placement="bottom"
          isOpen={isPopoverOpen}
          target="pageId"
          toggle={toggle}
        >
          <PopoverBody>
            {pages.map((page) => (
              <React.Fragment key={`page-id-header-${page.__id}`}>
                <button
                  type="button"
                  className="btn btn-sm btn-clear btn-block text-left"
                  onClick={() => [setPopoverOpen(false), onChange?.(page)]}
                >
                  &nbsp;{page.name}
                </button>
              </React.Fragment>
            ))}
          </PopoverBody>
        </Popover>
      </div>
    </div>
  );
}
