import { gql } from '@apollo/client';

export const LIST_WORK_ORDER_TYPE_GQL = gql`
  query workOrderTypes($filter: WorkOrderTypeFilterInput) {
    workOrderTypes(first: 1000, filter: $filter) {
      data {
        id
        name
        is_selectable
        children {
          id
          name
          is_selectable
        }
        parent {
          id
        }
      }
    }
  }
`;

export const CREATE_WORK_ORDER_TYPE_GQL = gql`
  mutation createWorkOrderType(
    $name: String!
    $parent_id: ID
    $is_selectable: Boolean
  ) {
    createWorkOrderType(
      name: $name
      parent_id: $parent_id
      is_selectable: $is_selectable
    ) {
      id
    }
  }
`;

export const UPDATE_WORK_ORDER_TYPE_GQL = gql`
  mutation updateWorkOrderType(
    $id: ID!
    $workOrderType: UpdateWorkOrderTypeInput!
  ) {
    updateWorkOrderType(id: $id, workOrderType: $workOrderType) {
      id
    }
  }
`;
export const GET_WORK_ORDER_TYPE_GQL = gql`
  query workOrderType($id: ID!) {
    workOrderType(id: $id) {
      id
      name
      is_selectable
      children {
        id
        name
        is_selectable
        children {
          id
          name
          is_selectable
        }
      }
      parent {
        id
      }
    }
  }
`;

export const DELETE_WORK_ORDER_TYPE_GQL = gql`
  mutation deleteWorkOrderType($id: ID!) {
    deleteWorkOrderType(id: $id) {
      message
    }
  }
`;
