import React from 'react';
import ColorOption from './ColorOption';
import classnames from 'classnames';
import { ExpandedDropdownOptions } from '@wgt/types';
import { Text } from '../../..';
import { useFormContext } from 'react-hook-form';
import { getProp } from '../../../../utils/methods';
import './index.scss';

interface Props {
  label: string;
  field: string;
  values: ExpandedDropdownOptions[];
  required?: boolean;
  isMulti?: boolean;
  disabled?: boolean;
}

const ExpandedDropdownColor: React.FC<Props> = ({
  label,
  values,
  field,
  required,
  isMulti,
  disabled,
}) => {
  const { errors } = useFormContext();
  return (
    <div
      className={classnames('expanded-dropdown-color', {
        'is-invalid': getProp(errors, field),
      })}
    >
      <Text>{label}</Text>
      <div className="d-flex">
        {values.map((optionValue, i) => (
          <ColorOption
            key={`color-option-dropdown-${i}`}
            value={optionValue}
            field={field}
            required={required}
            disabled={disabled}
            isMulti={isMulti}
          />
        ))}
      </div>
    </div>
  );
};

export default ExpandedDropdownColor;
