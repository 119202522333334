import { gql } from '@apollo/client';

export const LIST_WORK_ORDER_DOCUMENTS = gql`
  query workOrderDocuments($workOrderId: ID!) {
    workOrderDocuments(filter: { workorder_id: [$workOrderId] }) {
      data {
        id
        file_url
        file_name
      }
    }
  }
`;

export const REMOVE_WORK_ORDER_DOCUMENT = gql`
  mutation deleteWorkOrderDocument($id: ID!) {
    deleteWorkOrderDocument(id: $id) {
      message
    }
  }
`;

export const CREATE_WORK_ORDER_DOCUMENT = gql`
  mutation createWorkOrderDocument(
    $workOrderId: ID!
    $file: Upload
    $file_name: String
  ) {
    createWorkOrderDocument(
      workorder_id: $workOrderId
      file: $file
      file_name: $file_name
    ) {
      id
    }
  }
`;
