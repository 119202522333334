import React from 'react';
import classnames from 'classnames';
import { MediaCenterController } from '../../../../../../hooks/useMediaCenterController';
import { useBagContext } from '../../../../../../services/bag';
import ButtonControllers from '../ButtonControllers';
import { DocumentNode, OperationDefinitionNode } from 'graphql';
import { useMutation, useQuery } from '@apollo/client';
import MediaCenterBottom from '../MediaCenterBottom';
import { DEFAULT_BG } from '..';
import ActiveItemImage from '../ActiveItemImage';
import { MediaCenterFileType, MediaCenterItemBase } from '@wgt/types';
import NewMediaForm from '../NewMediaForm';
import { FileTypeStr } from '../../../types';
import { LIST_FILES } from '../graphql';

interface MediaCenterContentProps {
  content?: React.FC;
  deleteGql: DocumentNode;
  updateGql: DocumentNode;
  refetchQuery: DocumentNode;
  remove(indexToRemove: number): void;
  active?: MediaCenterItemBase;
  setActiveItemIndex(index: number): void;
  selectBackground: (backgroundColor: string) => void;
  createGql: DocumentNode;
  acceptFiles: FileTypeStr;
  filesDescription: string;
  modalTitle?: string;
  hasThumbnail?: boolean;
  type: MediaCenterFileType;
}

const MediaCenterContent: React.FC<MediaCenterContentProps> = ({
  content: Content,
  deleteGql,
  refetchQuery,
  remove,
  active,
  setActiveItemIndex,
  selectBackground,
  updateGql,
  createGql,
  acceptFiles,
  filesDescription,
  hasThumbnail,
  type,
}) => {
  const {
    isExpanded,
    isCreating,
    productId,
    media: { setItems, items },
  } = useBagContext<MediaCenterController>();

  useQuery(LIST_FILES, {
    skip: !productId,
    variables: {
      filter: {
        product_id: productId,
        type,
      },
    },
    onCompleted: ({ files }) => setItems(files?.data ?? []),
  });

  const [removeFn, { loading }] = useMutation(deleteGql, {
    refetchQueries: [{ query: refetchQuery, variables: { id: productId } }],
  });
  const [update] = useMutation(updateGql);

  const onUpdateBackground = (background: string) => {
    const definition = updateGql.definitions.find(
      (x) => x.kind === 'OperationDefinition',
    ) as OperationDefinitionNode;

    const COMMON_TYPES = ['id'];

    const variables = definition?.variableDefinitions?.reduce(
      (acc, { variable }) => {
        const draft = { ...acc };
        const variableName = variable.name.value;
        draft[variableName] = COMMON_TYPES.includes(variableName)
          ? active?.[variableName]
          : { background };
        return draft;
      },
      { type },
    );
    update({ variables });
    selectBackground(background);
  };

  const onRemove = async (item: MediaCenterItemBase) => {
    await removeFn({
      variables: {
        id: Number(item.id),
      },
    });
    remove(items.findIndex((x) => x.id === active?.id));
  };

  return (
    <div
      className={'media-center-holder'}
      style={{
        backgroundColor: isExpanded
          ? active?.background ?? DEFAULT_BG
          : DEFAULT_BG,
      }}
    >
      <div
        className={classnames('media-center-content', {
          open: isExpanded && !active?.id,
        })}
      >
        {isExpanded && isCreating && (
          <NewMediaForm
            hasThumbnail={hasThumbnail}
            filesDescription={filesDescription}
            accept={acceptFiles}
            createGql={createGql}
            type={type}
          />
        )}
        {isExpanded && !isCreating && (
          <>
            {Content ? (
              <Content />
            ) : (
              <ActiveItemImage
                image={hasThumbnail ? active?.thumbnail_url : active?.file_url}
                active={active}
                onUpdate={onUpdateBackground}
              />
            )}
          </>
        )}
      </div>
      <ButtonControllers />
      <MediaCenterBottom
        onRemove={onRemove}
        loading={loading}
        active={active}
        setActiveIndex={setActiveItemIndex}
        items={items}
        hasThumbnail={hasThumbnail}
      />
    </div>
  );
};

export default MediaCenterContent;
