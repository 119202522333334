import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import ContactSelector from './ContactSelector';

export default memo(function WorkOrderBuyerBroker(): JSX.Element {
  const { t } = useTranslation('workOrder');

  return (
    <div className="container overflow-auto content-scroll">
      <ContactSelector label={t('Broker')} selecteds={[]} />
      <ContactSelector label={t('Supplier')} selecteds={[]} />
      <ContactSelector label={t('Collector')} selecteds={[]} />
    </div>
  );
});
