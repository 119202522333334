import { useQuery } from '@apollo/client';
import React from 'react';
import SidebarPane from '../../../components/SidebarPane';
import Loader from '../../../components/_organisms/Loader';
import useStatementsContext, { InvoiceResult } from './useStatements';
import { INVOICE_DETAILS } from './graphql';
import InvoiceDetailsContent from './InvoiceDetailsContent';
import { useTranslation } from 'react-i18next';

export default function InvoiceDetails(): JSX.Element {
  const { activeInvoice, setActiveInvoice } = useStatementsContext();
  const { t } = useTranslation('statements');
  const setShow = () =>
    setActiveInvoice((invoice) => (invoice?.id ? undefined : invoice));

  const { data, loading } = useQuery<{ invoice: InvoiceResult }>(
    INVOICE_DETAILS,
    {
      variables: {
        id: activeInvoice?.id,
      },
      skip: !activeInvoice?.id,
    },
  );

  return (
    <SidebarPane
      isShown={!!activeInvoice?.id}
      setShown={setShow}
      size={800}
      header={t('Crime Prevention Network')}
    >
      <>
        {loading && <Loader round />}
        {!loading && data?.invoice.id && (
          <InvoiceDetailsContent invoice={data.invoice} />
        )}
      </>
    </SidebarPane>
  );
}
