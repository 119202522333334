import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'reactstrap';
import Loader from '../../../../components/_organisms/Loader';
import { useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import InvoiceDetails from '../../../../components/InvoiceDetails';

const GET_INVOICE_GQL = gql`
  query invoice($id: ID!) {
    invoice(id: $id) {
      id
      snapshot
      pointOfSale {
        firm {
          name
          website
          vatcode
          address {
            address
            number
            phone
            postal_code
            city
            state
            country {
              name
            }
          }
          extra {
            logo_url
          }
        }
        executiveBuyer {
          employments {
            name
            website
            vatcode
            address {
              address
              number
              phone
              postal_code
              city
              state
              country {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export default function PointOfSales(): JSX.Element {
  const { ready } = useTranslation('invoicesReport');

  const params = useParams<ParamTypes>();

  const { data, loading } = useQuery(GET_INVOICE_GQL, {
    variables: {
      id: params.id,
    },
  });

  const invoiceData = useMemo(() => {
    return data?.invoice ?? {};
  }, [data?.invoice]);

  if (!ready || loading) {
    return <Loader />;
  }

  return (
    <Container className="mt-3">
      <InvoiceDetails invoice={invoiceData} />
    </Container>
  );
}
