import React, { useMemo } from 'react';
import { LayoutStructure, SearchLayoutResultColumn } from '@wgt/types';
import TemplateLayout from '../../../../../../components/TemplateLayout';
import { useProductUpdate } from '../../../Update/Provider';
import { convertJsonLayoutToTemplateFields } from '@wgt/converters';

interface TemplateFieldsProps {
  section: 'first' | 'second';
  headers?: SearchLayoutResultColumn[];
  borderedError?: boolean;
}

const TemplateFields: React.FC<TemplateFieldsProps> = ({
  section,
  headers,
  borderedError,
}) => {
  const { pages, isFormEnabled } = useProductUpdate();

  const [first, second] = useMemo(() => {
    const resultsLayout = pages.active?.category?.template
      ?.result_layout as LayoutStructure;

    const fields = pages.active?.category?.template?.fields;

    if (!resultsLayout) {
      return [[], []];
    }
    return Object.values(resultsLayout).map((layout) =>
      convertJsonLayoutToTemplateFields({
        layout,
        fields:
          fields?.filter(
            (field) =>
              field.property?.key &&
              !headers
                ?.map((header) => header.key)
                .includes(field.property.key),
          ) ?? [],
      }),
    );
  }, [pages?.active]);

  const rowsToRender = {
    first,
    second,
  };

  return (
    <TemplateLayout
      rows={rowsToRender[section]}
      prefix={`pages[${pages.activeIndex}].fields.`}
      component="component"
      disabled={!isFormEnabled}
      borderedError={borderedError}
      resultsForm
    />
  );
};

export default TemplateFields;
