import React, { useEffect } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useCrmRoutes from '../../../hooks/useCrmRoutes';
import Filter from './StatementsFilter';
import DateRange from './StatementsDateRange';
import Results from './StatementsResults';
import Types from './StatementsTypes';
import useStatementsContext, { StatementsProvider } from './useStatements';
import { Form } from 'reactstrap';
import InvoiceDetails from './InvoiceDetails';
import InvoicePaymentsDetails from './InvoicePaymentsDetails';

import './styles.scss';

function Content() {
  const { onSubmit } = useStatementsContext();
  const { handleSubmit } = useFormContext();
  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Filter />
        <DateRange />
        <Results />
        <Types />
      </Form>
      <InvoiceDetails />
      <InvoicePaymentsDetails />
    </>
  );
}

export default function Statements(): JSX.Element {
  const { setCustomTitle } = useCrmRoutes();
  const { t } = useTranslation('statements');
  const methods = useForm({
    shouldUnregister: false,
  });
  useEffect(() => {
    setCustomTitle(t('anti money laundring'));
  }, []);

  return (
    <div className="mt-5">
      <FormProvider {...methods}>
        <StatementsProvider>
          <Content />
        </StatementsProvider>
      </FormProvider>
    </div>
  );
}
