import React, { memo, useEffect } from 'react';
import { Pane } from 'evergreen-ui';
import PriceInput from '../../../../components/PriceInput';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import setCurrencyMaskInput, {
  removeCurrencyMask,
} from '../../../../helpers/CurrencyMaskInput';

export default memo(function ProductPageIdPrice(): JSX.Element {
  const { t } = useTranslation('product');
  const { getValues, setValue } = useFormContext();
  const calculateTotalsPerPage = (section: string) => {
    const pricePerCarat = Number(
      removeCurrencyMask(getValues('page.price_per_carat') ?? 0),
    );
    const caratWeight = Number(
      removeCurrencyMask(getValues('page.carat_weight') ?? 0),
    );
    const totalPrice = Number(
      removeCurrencyMask(getValues('page.total_price') ?? 0),
    );

    if ('caractType' === section) {
      // Please, let's keep the following rules here in first moment
      // a) Price per Carat (x times) Carat Weight (= equals) Total Price$10.00 x 10.00 cts = $100.00
      // c) $300 Total Price / 3 Carat Weight. The Price per carat is $100.
      const resultTotalPrice = pricePerCarat * caratWeight;

      setValue(
        'page.total_price',
        setCurrencyMaskInput(resultTotalPrice.toFixed(2).toString()),
      );
    } else {
      // b) $300.00 Total Price (/ divide) $100.00 Price per Carat (= equals) Carat Weight is 3 cts.
      const resultCaratWeight = totalPrice > 0 ? totalPrice / pricePerCarat : 0;

      setValue(
        'page.carat_weight',
        setCurrencyMaskInput(resultCaratWeight.toFixed(2).toString()),
      );
    }
  };

  useEffect(() => {
    setTimeout(() => {
      calculateTotalsPerPage('caractType');
    }, 500);
  }, []);

  return (
    <Pane>
      <PriceInput
        field="page.total_price"
        title={t('Total Price')}
        muted="USD"
        required
        onKeyUp={() => calculateTotalsPerPage('totalPrice')}
      />
      <PriceInput
        field="page.price_per_carat"
        title={t('Price per carat')}
        muted="USD"
        required
        onKeyUp={() => calculateTotalsPerPage('caractType')}
      />
      <PriceInput
        field="page.carat_weight"
        title={t('Carat Weight')}
        muted="ct"
        required
        onKeyUp={() => calculateTotalsPerPage('caractType')}
      />
    </Pane>
  );
});
