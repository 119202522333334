const getDigitsFromValue = (value = ''): string =>
  value.replace(/(-(?!\d))|[^0-9|-]/g, '') || '';

const padDigits = (digits: string) => {
  const desiredLength = 3;
  const actualLength = digits.length;

  if (actualLength >= desiredLength) {
    return digits;
  }

  const amountToAdd = desiredLength - actualLength;
  const padding = '0'.repeat(amountToAdd);

  return padding + digits;
};

const removeLeadingZeros = (numberValue: string) =>
  numberValue.replace(/^0+([0-9]+)/, '$1');

const addMaskToNumber = (
  numberValue: string,
  decimalSymbol: string,
  thousandsSeparatorSymbol: string,
) => {
  const centsStartingPosition = numberValue.length - 2;
  const dollars = removeLeadingZeros(
    numberValue.substring(0, centsStartingPosition),
  );
  const cents = numberValue.substring(centsStartingPosition);

  const character = dollars.split('').reverse().join('');

  const mask = [];
  for (let i = 0; i < character.length; i++) {
    if (i % 3 === 0 && i > 1) {
      mask.push(thousandsSeparatorSymbol);
    }
    mask.push(character[i]);
  }

  return mask.reverse().join('') + decimalSymbol + cents;
};

const setCurrencyMaskInput = (
  value = '',
  decimalSymbol = '.',
  thousandsSeparatorSymbol = ',',
): string => {
  if (value) {
    const digits = getDigitsFromValue(value);
    const digitsWithPadding = padDigits(digits);
    const price = addMaskToNumber(
      digitsWithPadding,
      decimalSymbol,
      thousandsSeparatorSymbol,
    );
    return price.toString();
  }
  return '0.00';
};

export const removeCurrencyMask = (value: string): string =>
  value.toString().replace(/[^0-9.-]+/g, '');

export default setCurrencyMaskInput;
