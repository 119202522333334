import { gql } from '@apollo/client';

export const variationValuesFragment = gql`
  fragment templateFieldVariationValues_Part on PropertyValueVariationValue {
    id
    name
    key
    options
    image
    image_url
  }
`;

export const shapeFragment = gql`
  fragment templateShape_Part on Shape {
    id
    title
    image_url
    shapeGroup {
      id
      title
    }
  }
`;

export const propertyValueFragment = gql`
  fragment templateFieldPropertyValue_Part on PropertyValue {
    id
    name
    key
    options
    image
  }
`;

export const templateFieldFragment = gql`
  fragment templateField_Part on TemplateField {
    id
    label
    is_required
    is_searchable
    is_highlight
    is_single_shape
  }
`;

export const componentFragment = gql`
  fragment templateFieldComponent_Part on Component {
    id
    name
    key
  }
`;

export const propertyFragment = gql`
  fragment templateFieldProperty_Part on Property {
    id
    name
    key
  }
`;

export const dataTypeFragment = gql`
  fragment templateFieldDataType_Part on DataType {
    id
    name
    key
  }
`;

export const commonAttributesFragment = gql`
  fragment productCommonAttributes_Part on Product {
    id
    name
    draft
    sku
    description
    lot_id
    tray_id
    total_price
    crates {
      id
      name
    }
    boxes {
      id
      name
    }
    office {
      id
      name
    }
  }
`;

export const productCategoryFragment = gql`
  fragment categoryEntity_Part on Category {
    id
    name
    mixed
    categoryType {
      id
    }
    template {
      fields {
        id
        label
        is_required
        is_searchable
        is_highlight
        is_single_shape
        property {
          name
          key
          dataType {
            name
            key
          }
        }
        component {
          id
          name
          key
        }
        values {
          id
          name
          key
          options
          image
          variations {
            id
            name
            variationValues {
              id
              name
              key
              options
              image
              image_url
            }
          }
        }
      }
      shapes {
        id
        title
        image_url
        shapeGroup {
          id
          title
        }
      }
      layout
      result_layout
    }
  }
`;

export const parcelTypesFragment = gql`
  fragment parcelTypeIdentifier_Part on ParcelType {
    id
    title
    description
  }
`;

export const firmFragment = gql`
  fragment firmProduct_Part on Firm {
    id
    name
    extra {
      email
    }
    address {
      address
      state
      phone
      country {
        name
      }
    }
  }
`;

export const productStatusFragment = gql`
  fragment productStatusIdentifier_Part on ProductStatus {
    id
    name
  }
`;

export const officeFragment = gql`
  fragment office_Part on Office {
    id
    name
    address
  }
`;
