import React from 'react';
import { Pane, Paragraph } from 'evergreen-ui';
import { Title } from '../../../../../components';

export interface OfficeOption {
  name?: string;
  address?: string;
  state?: string;
  phone?: string;
  country?: {
    name?: string;
  };
}

interface Props {
  value?: OfficeOption;
}

export default function OfficeCard({ value }: Props): JSX.Element {
  return (
    <div className="w-100">
      <Title heading="h4" className="ml-2 mb-1">
        {value?.name}
      </Title>

      <div className="card border rounded">
        <div className="card-body p-2">
          <Pane padding="10px">
            <Paragraph marginY="10px">Email: {'N/A'}</Paragraph>
            <Paragraph marginY="10px">
              Phone: <strong>{value?.phone ?? 'N/A'}</strong>
            </Paragraph>
            <Paragraph>{value?.address}</Paragraph>
            <Paragraph>
              <strong>{value?.state}</strong>
            </Paragraph>
            <Paragraph>{value?.country?.name}</Paragraph>
          </Pane>
        </div>
      </div>
    </div>
  );
}
