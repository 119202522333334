import React from 'react';
import Table from '../../../../components/_organisms/Table';
import CustomHeader from '../../../../components/_molecules/CustomHeader';
import { LIST_SHAPE_GROUPS_GQL, DELETE_SHAPE_GROUP_GQL } from '../graphql';
import { ShapeGroup } from '../types';

const List: React.FC = () => (
  <div>
    <CustomHeader title="Shape Groups" showCreate />
    <Table
      hover
      striped
      columns={[{ label: 'Title', field: 'title' }]}
      query={LIST_SHAPE_GROUPS_GQL}
      node="shapeGroups"
      deleteMutation={DELETE_SHAPE_GROUP_GQL}
      getMutationArgs={(item: ShapeGroup) => ({ id: item.id })}
      getConfirmMessage={(item: ShapeGroup) =>
        `Confirm delete of ${item?.title}?`
      }
      showDetail={false}
    />
  </div>
);

export default List;
