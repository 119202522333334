import React, { useEffect } from 'react';
import useCrmRoutes from '../../../../hooks/useCrmRoutes';

const Details: React.FC = () => {
  const { routes, setCustomRoutes } = useCrmRoutes();

  useEffect(() => {
    setCustomRoutes([{ name: 'test', path: '/test' }]);
  }, [routes]);

  return <h1>Details</h1>;
};

export default Details;
