import { gql } from '@apollo/client';
import { paginatorInfoFragment } from '../../../services/graphql/paginator.gql';

export const categoryFragment = gql`
  fragment category_Part on Category {
    id
    name
    description
    mixed
    template {
      id
      name
    }
    categoryType {
      id
      name
    }
    categoryGroup {
      id
      name
    }
    productType {
      id
      name
    }
  }
`;

export const LIST_CATEGORIES_GQL = gql`
  query categoriesQuery($page: Int, $first: Int) {
    categories(page: $page, first: $first) {
      data {
        ...category_Part
      }
      paginatorInfo {
        ...paginatorInfo_Part
      }
    }
  }
  ${paginatorInfoFragment}
  ${categoryFragment}
`;

export const CREATE_CATEGORY_GQL = gql`
  mutation createCategoryMutation($input: CategoryInput!) {
    createCategory(input: $input) {
      id
    }
  }
`;
export const UPDATE_CATEGORY_GQL = gql`
  mutation updateCategoryMutation($id: ID!, $category: UpdateCategoryInput!) {
    updateCategory(id: $id, category: $category) {
      id
    }
  }
`;

export const GET_CATEGORY_GQL = gql`
  query categoryQuery($id: ID!, $isEdit: Boolean!) {
    category(id: $id) @include(if: $isEdit) {
      ...category_Part
    }
    templates(first: 1000) {
      data {
        id
        name
      }
    }
    categoryTypes(first: 1000) {
      data {
        id
        name
      }
    }
    categoryGroups(first: 1000) {
      data {
        id
        name
      }
    }
    productTypes(first: 1000) {
      data {
        id
        name
      }
    }
  }
  ${categoryFragment}
`;

export const DELETE_CATEGORY_GQL = gql`
  mutation deleteCategoryMutation($id: ID!) {
    deleteCategory(id: $id) {
      message
    }
  }
`;

export const CREATE_PRODUCT_GQL = gql`
  mutation createProductMutation($category_id: ID!) {
    createProduct(category_id: $category_id) {
      id
    }
  }
`;
