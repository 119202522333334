import React, { useEffect } from 'react';
import {
  LayoutComponentDataTypePropertyValue,
  PropertyValueVariation,
} from '@wgt/types';
import { useFormContext } from 'react-hook-form';
import VariationOptions from './VariationOptions';

interface VariationProps {
  field: string;
  isMulti?: boolean;
  required?: boolean;
  variation: PropertyValueVariation;
  propertyValue: LayoutComponentDataTypePropertyValue;
  disabled?: boolean;
}

const Variation: React.FC<VariationProps> = ({
  field,
  isMulti,
  required,
  variation,
  propertyValue,
  disabled,
}) => {
  const { watch, setValue } = useFormContext();
  const watchVariations = watch(`${field}.${propertyValue.key}.variations`, {});

  const hasSelected =
    watchVariations && Object.keys(watchVariations).length
      ? Object.values(watchVariations).some(Boolean)
      : false;

  useEffect(
    () => setValue(`${field}.${propertyValue.key}.selected`, hasSelected),
    [hasSelected],
  );

  return (
    <VariationOptions
      options={variation.variationValues}
      field={`${field}.${propertyValue.key}.variations`}
      isMulti={isMulti}
      required={required}
      disabled={disabled}
    />
  );
};

export default Variation;
