import React, { useMemo } from 'react';
import { gql, useApolloClient } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { ProductBase } from '../../../../../../../types';
import setCurrencyMaskInput, {
  removeCurrencyMask,
} from '../../../../../helpers/CurrencyMaskInput';
import CardLayoutText from '../../../../../components/CardLayoutText';

interface Props {
  productId: string;
}

export default function ProductTotals({ productId }: Props): JSX.Element {
  const client = useApolloClient();
  const { t } = useTranslation('product');

  const product = client.readFragment<ProductBase>({
    id: `Product:${productId}`,
    fragment: gql`
      fragment productPagePrices_Part on Product {
        id
        pages {
          ... on ProductPageId {
            id
            total_price
            carat_weight
            price_per_carat
          }
        }
      }
    `,
  });

  const { totalSkuPrice, totalCaratWeight } = useMemo(() => {
    return (
      product?.pages?.reduce(
        (acc, current) => {
          acc.totalCaratWeight += Number(
            removeCurrencyMask(current.carat_weight?.toString() ?? '0'),
          );
          acc.totalSkuPrice += Number(
            removeCurrencyMask(current.total_price?.toString() ?? '0'),
          );
          return acc;
        },
        {
          totalSkuPrice: 0,
          totalCaratWeight: 0,
        },
      ) ?? { totalCaratWeight: 0, totalSkuPrice: 0 }
    );
  }, [product?.pages]);

  return (
    <>
      <CardLayoutText
        content={[
          {
            header: t('Total SKU Price'),
            content: setCurrencyMaskInput(
              totalSkuPrice?.toFixed(2)?.toString(),
            ),
          },
        ]}
        containerClass="w-100 mr-2"
      />
      <CardLayoutText
        content={[
          {
            header: t('Total Carat Weight'),
            content: setCurrencyMaskInput(
              totalCaratWeight?.toFixed(2)?.toString(),
            ),
          },
        ]}
        containerClass="w-100 ml-2"
      />
    </>
  );
}
