import React, { useMemo, useState } from 'react';
import { Pane } from 'evergreen-ui';
import { AlertMessage, Button, Title } from '../../../../../../components';
import { ReportsList } from '../../../types';
import { useMutation, useQuery } from '@apollo/client';
import Loader from '../../../../../../components/_organisms/Loader';
import CreateReportModal from '../../../Update/Reports/CreateReportModal';
import ReportsTable from './ReportsTable';
import { PRODUCT } from '../../graphql';
import { Col, Row } from 'reactstrap';
import ConfirmDialog from '../../../../../../components/_molecules/ConfirmDialog';
import { MediaCenterItemBase, Report } from '@wgt/types';
import { useProductUpdate } from '../../../Update/Provider';
import useToast from '../../../../../../hooks/useToast';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import { DELETE_FILE, LIST_FILES } from '../../../Update/MediaCenter/graphql';

interface ReportsProps {
  productId?: string;
}
const Reports: React.FC<ReportsProps> = ({ productId }) => {
  const { isFormEnabled } = useProductUpdate();
  const { toast } = useToast();
  const { t } = useTranslation(['product', 'common']);
  const [deleteItem, setDeleteItem] = useState<MediaCenterItemBase | null>(
    null,
  );
  const [isCreateModalOpen, setCreateModalOpen] = useState<boolean>(false);

  const { data, loading } = useQuery<{
    productReports: { data: Report[] };
  }>(LIST_FILES, {
    variables: {
      filter: {
        product_id: productId,
        type: 'guide',
      },
    },
  });

  const [deleteProductReport, { loading: removing }] = useMutation(
    DELETE_FILE,
    {
      refetchQueries: [
        {
          query: LIST_FILES,
          variables: {
            filter: {
              product_id: productId,
              type: 'guide',
            },
          },
        },
      ],
      onCompleted: () => {
        toast('success', 'Report deleted');
        setDeleteItem(null);
      },
    },
  );

  const reportListObj: ReportsList = useMemo(() => {
    if (!data?.productReports?.data) {
      return { right: [] as Report[], left: [] as Report[] };
    }
    const separatedList = data.productReports.data.reduce(
      (acc, currentItem, currentIndex) => {
        const transformAcc = { ...acc };

        if (currentIndex % 2 === 0) {
          transformAcc.left.push(currentItem);
        } else {
          transformAcc.right.push(currentItem);
        }
        return transformAcc;
      },
      { right: [] as Report[], left: [] as Report[] },
    );
    return separatedList;
  }, [data]);

  const handleReportCreatedSuccess = () => {
    setCreateModalOpen(false);
    toast('success', t('Report Created'));
  };

  if (loading) {
    return <Loader round small />;
  }

  return (
    <>
      <Pane className="media-and-report">
        <Title heading="h2">Report</Title>
        {isFormEnabled && (
          <button
            type="button"
            className="primary"
            onClick={() => setCreateModalOpen(true)}
          >
            {t('Add laboratory report')}
          </button>
        )}
      </Pane>

      {reportListObj.left.length ? (
        <Pane marginBottom="40px">
          <Row className="reports-content-list">
            <Col xs="12" lg="6">
              <ReportsTable
                reports={reportListObj.left}
                setDeleteItem={setDeleteItem}
              />
            </Col>
            {!!reportListObj.right.length && (
              <Col xs="12" lg="6">
                <ReportsTable
                  reports={reportListObj.right}
                  setDeleteItem={setDeleteItem}
                />
              </Col>
            )}
          </Row>
        </Pane>
      ) : (
        <AlertMessage>
          <> {t('There is no Report registered at the moment.')} </>
          {isFormEnabled && (
            <>
              {t('common:Please')},{' '}
              <Button
                htmlType="button"
                onClick={() => setCreateModalOpen(true)}
                type="link"
              >
                {t('common:click here')}
              </Button>{' '}
              {t('to Add a new Report.')}
            </>
          )}
        </AlertMessage>
      )}

      <ConfirmDialog
        isShown={!!deleteItem}
        onConfirm={() => {
          deleteProductReport({
            variables: {
              id: deleteItem?.id,
            },
          });
        }}
        title={t('Confirm delete report?')}
        onClose={() => setDeleteItem(null)}
        isLoading={removing}
      />
      <CreateReportModal
        query={PRODUCT}
        productId={productId}
        isShown={isCreateModalOpen}
        onClose={() => setCreateModalOpen(false)}
        handleReportCreatedSuccess={handleReportCreatedSuccess}
      />
    </>
  );
};

export default Reports;
