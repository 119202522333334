import React from 'react';
import { BoxId } from '@wgt/types';
import { useBagContext } from '../../../../../../services/bag';
import BoxItem from './BoxItem';

const BoxIds: React.FC = () => {
  const {
    box: { fields },
  } = useBagContext<{
    box: { fields: BoxId[] };
  }>();

  return (
    <div className="simple-input">
      {fields.map((boxId, index) => (
        <BoxItem box={boxId as BoxId} index={index} key={`box-${index}`} />
      ))}
    </div>
  );
};

export default BoxIds;
