import React, { memo, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { convertJsonLayoutToTemplateFields } from '@wgt/converters';
import { CategoryBase } from '../../../../../../../types';
import TemplateFieldsFirstSection from './FirstSection';
import TemplateFieldsSecondSection from './SecondSection';
import { PARCEL_TYPE_PER_DIAMOND_ID } from '@wgt/shared/constants';

interface ProductPageIdTemplateFieldsProps {
  section?: 'first' | 'second';
}
export default memo(function ProductPageIdTemplateFields(
  { section = 'first' }: ProductPageIdTemplateFieldsProps = {
    section: 'first',
  },
): JSX.Element {
  const { watch } = useFormContext();

  const parcelTypeId: number = watch('page.fields.parcel_type_id');
  const category: CategoryBase = watch('page.category');

  const [first, second] = useMemo(() => {
    const templateLayout = category?.template?.layout;
    if (
      !templateLayout ||
      (typeof templateLayout !== 'string' &&
        Object.prototype.toString.call(templateLayout) === '[object Array]')
    ) {
      return [[], []];
    }
    const fields = category?.template?.fields ?? [];

    return Object.values(templateLayout).map((layout) =>
      convertJsonLayoutToTemplateFields({ layout, fields }),
    );
  }, [category?.template]);

  const { rows, component: Component } = useMemo(
    () =>
      ({
        first: { component: TemplateFieldsFirstSection, rows: first },
        second: { component: TemplateFieldsSecondSection, rows: second },
      }[section]),
    [first, second, section],
  );

  return (
    <Component
      rows={rows}
      prefix="page.fields."
      component="component"
      rangeToSingle={Number(parcelTypeId) === PARCEL_TYPE_PER_DIAMOND_ID}
    />
  );
});
