import React, { useEffect } from 'react';
import classnames from 'classnames';
import { FaTimes } from 'react-icons/fa';
import { useBagContext } from '../../../../../../services/bag';
import { MediaCenterController } from '../../../../../../hooks/useMediaCenterController';
import { MediaCenterItemBase } from '@wgt/types';
import './styles.scss';

interface ThumbnailsProps {
  loading?: boolean;
  remove(item: MediaCenterItemBase): Promise<void>;
  items: MediaCenterItemBase[];
  active?: MediaCenterItemBase | null;
  setActiveIndex(index?: number): void;
  hasThumbnail?: boolean;
}

function Thumbnails({
  loading,
  remove,
  items,
  active,
  setActiveIndex,
  hasThumbnail,
}: ThumbnailsProps): JSX.Element {
  const onRemove = async (e: React.MouseEvent, item: MediaCenterItemBase) => {
    e.stopPropagation();
    await remove(item);
  };
  const { setExpanded, setCreating } = useBagContext<MediaCenterController>();
  useEffect(() => setActiveIndex(undefined), []);

  return (
    <div className="thumbnails-list">
      <div className="overflow-x-auto row flex-row flex-nowrap no-gutters">
        {(items ?? []).map((item, key) => (
          <div
            key={`media-${key}`}
            className={classnames(
              'col-auto d-flex align-items-center p-1 bg-white mx-2',
              {
                active: active?.id === item.id,
              },
            )}
          >
            <div
              className="card card-block"
              onClick={() => [
                setCreating(false),
                active?.id === item.id
                  ? setActiveIndex(undefined)
                  : setActiveIndex(key),
                setExpanded(true),
              ]}
            >
              <img
                src={hasThumbnail ? item.thumbnail_url : item.file_url}
                className="card-img-top"
              />
              {!loading && (
                <FaTimes
                  className="remove-media-button"
                  onClick={(e) => onRemove(e, item)}
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Thumbnails;
