import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import classnames from 'classnames';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import TemplateLayoutCharacteristicVariations from './Variations';
import {
  LayoutComponentBase,
  LayoutComponentDataTypePropertyValue,
} from '@wgt/types';

interface Props {
  isOpen: boolean;
  field?: string;
  setOpenVariations(open: boolean): void;
  propertyValue: LayoutComponentDataTypePropertyValue;
  dataType?: LayoutComponentBase;
}

const PropertyValueWithVariations: React.FC<Props> = ({
  isOpen,
  propertyValue,
  setOpenVariations,
  field,
  dataType,
}) => {
  const { watch, setValue } = useFormContext();

  const watchVariations = watch(`${field}.${propertyValue.key}.variations`, {});

  const hasSelected =
    watchVariations && Object.keys(watchVariations).length
      ? Object.values(watchVariations).some(Boolean)
      : false;

  useEffect(
    () => setValue(`${field}.${propertyValue.key}.selected`, hasSelected),
    [hasSelected],
  );

  return (
    <div
      className={classnames('template-characteristic-value-label', {
        hasSelected,
      })}
      onClick={() => setOpenVariations(!isOpen)}
      onMouseLeave={() => setOpenVariations(false)}
    >
      {propertyValue.name}
      {isOpen ? <FaAngleUp /> : <FaAngleDown />}
      <TemplateLayoutCharacteristicVariations
        isOpen={isOpen}
        setOpen={setOpenVariations}
        variations={propertyValue.variations ?? []}
        field={`${field}.${propertyValue.key}.variations`}
        dataType={dataType}
        propertyField={field}
      />
    </div>
  );
};

export default PropertyValueWithVariations;
