import React from 'react';
import useCrmRoutes from '../../../hooks/useCrmRoutes';
import List from './List';
import Put from './Put';
import InternalRoutes from '../../../components/_organisms/InternalRoutes';

const Users: React.FC = (props) => {
  const { isCrmInternalRoute, actualRoute } = useCrmRoutes();

  if (isCrmInternalRoute) {
    const routes = [
      {
        component: Put,
        name: 'Property Value Variation',
        path: `/crm/${actualRoute}/create`,
      },
      {
        component: Put,
        name: 'Property Value Variation',
        path: `/crm/${actualRoute}/:id/edit`,
      },
    ];

    return <InternalRoutes routes={routes} />;
  }

  return <List {...props} />;
};

export default Users;
