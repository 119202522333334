import React from 'react';
import { Row, Col } from 'reactstrap';
import { useFormContext } from 'react-hook-form';
import { concat } from '@wgt/shared';
import Title from '../Title';
import NoProfilePhoto from '../../assets/no-profile-photo.jpg';

export default function WorkerInfo(): JSX.Element {
  const { watch } = useFormContext();
  const worker = watch('worker', {});

  return (
    <>
      <Col xs={{ size: 9, order: 0 }} lg={{ size: 3, order: 0 }}>
        <div className="d-flex">
          <div>
            <img
              src={NoProfilePhoto}
              alt={worker?.name}
              className="img-thumbnail thumb-size-job-order-worker rounded p-0"
            />
          </div>
          <div className="ml-2">
            <Title heading="h4">{worker?.name}</Title>
            <p className="mt-2 mb-0">{worker?.jobTitles?.[0]?.name}</p>
          </div>
        </div>
      </Col>
      <Col
        xs={{ size: 12, order: 2 }}
        lg={{ size: 8, order: 1 }}
        className="d-flex align-items-center"
      >
        <Row noGutters className="w-100">
          <Col
            xs="12"
            md="5"
            className="d-flex align-items-center flex-column justify-content-start"
          >
            <div className="d-flex justify-content-start flex-column w-100">
              <p className="mb-0 mt-1">
                {concat(
                  worker?.country?.name,
                  worker?.state,
                  worker?.city,
                  worker?.number,
                )}
              </p>
              <p className="mb-0 mt-1">
                {worker?.street}, {worker?.zip_code}
              </p>
            </div>
          </Col>
          <Col
            xs="12"
            md="3"
            className="d-flex align-items-center justify-content-start justify-content-md-center"
          >
            <p className="mb-0 mt-1 mt-md-0">{worker?.phone}</p>
          </Col>
          <Col
            xs="12"
            md="4"
            className="d-flex align-items-center justify-content-start justify-content-md-center"
          >
            <p className="mb-0 mt-1 mt-md-0">{worker?.email}</p>
          </Col>
        </Row>
      </Col>
    </>
  );
}
