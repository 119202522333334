import { Pane } from 'evergreen-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { Title } from '../../../../../../../components';
import OptionValue from './OptionValue';

const FieldsSchedule: React.FC = () => {
  const { t } = useTranslation('pointOfSales');
  const fields = [
    {
      title: 'Discount',
      options: [
        { field: 'discount_shipping', label: t('Shipping & Handling') },
        { field: 'discount_interest', label: t('Interest') },
        { field: 'discount_custom_duty', label: t('Custom Duty') },
        { field: 'discount_tax', label: t('Tax') },
      ],
    },
    {
      title: 'Shipping & Handling',
      options: [
        { field: 'shipping_interest', label: t('Intest') },
        { field: 'shipping_tax', label: t('Tax') },
        { field: 'shipping_free', label: t('Shipping & Handling Free') },
      ],
    },
    {
      title: 'Interest',
      options: [
        { field: 'interest_shipping', label: t('Shipping & Handling') },
        { field: 'interest_interest', label: t('Interest') },
        { field: 'interest_custom_duty', label: t('Custom Duty') },
        { field: 'interest_tax', label: t('Tax') },
      ],
    },
    {
      title: 'Custom Duty ',
      options: [
        { field: 'custom_duty_interest', label: t('Intest') },
        { field: 'custom_duty_tax', label: t('Tax') },
        { field: 'custom_duty_free', label: t('Custom Duty Free') },
      ],
    },
    {
      title: 'Tax',
      options: [
        { field: 'tax_interest', label: t('Interest') },
        { field: 'tax_free', label: t('Tax free') },
      ],
    },
  ];

  return (
    <div className="h-100 overflow-auto policy-content-bordered mb-20">
      <Title className="muted" heading="h2">
        {t('Select Field Schedule for this Policy')}
      </Title>
      <Row className="h-100" noGutters>
        {fields.map((field, key) => (
          <Col key={`field-${key}`} className="p-2 mx-2 d-flex flex-column">
            <Pane flex="1">
              <Title heading="h2">{t(`Include ${field.title} on`)}</Title>
              {field.options.map((option, optionKey) => (
                <OptionValue
                  key={`option-${optionKey}`}
                  field={option.field}
                  label={option.label}
                />
              ))}
            </Pane>
            <Pane className="field-schedule-item-bottom d-flex align-items-center justify-content-center">
              <div className="mr-3 text-right">
                {/* waiting for future currency integration  */}
                0.00 USD <br />
                {t(field.title)}
              </div>
              <span>{'-'}</span>
            </Pane>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default FieldsSchedule;
