import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import {
  CREATE_WORK_ORDER_TYPE_GQL,
  UPDATE_WORK_ORDER_TYPE_GQL,
  GET_WORK_ORDER_TYPE_GQL,
} from '../graphql';
import SimpleForm from '../../../../components/_organisms/SimpleForm';
import useToast from '../../../../hooks/useToast';

interface Props {
  id?: string;
  parentId?: string;
  setOpenModal: (openModal: boolean) => void;
  refetch: () => void;
  isEdit: boolean;
}

export default function Put({
  id,
  parentId,
  setOpenModal,
  refetch,
  isEdit,
}: Props): JSX.Element {
  const { toast } = useToast();

  const { t } = useTranslation(['workOrder', 'common']);

  const [opened, setOpen] = useState<boolean>(true);

  const toggle = () => setOpen((isOpen) => !isOpen);

  useEffect(() => {
    if (!opened) {
      setTimeout(() => setOpenModal(false), 1000);
    }
  }, [opened]);

  return (
    <Modal isOpen={opened} toggle={toggle} className="work-order-type-modal">
      <ModalHeader toggle={toggle}>{t('Create Work Order Type')}</ModalHeader>
      <ModalBody>
        <SimpleForm
          isModal
          query={GET_WORK_ORDER_TYPE_GQL}
          queryOptions={{
            variables: { isEdit, id: id ?? '' },
          }}
          parseQuery={({ workOrderType }) => ({
            ...workOrderType,
          })}
          mutation={
            isEdit ? UPDATE_WORK_ORDER_TYPE_GQL : CREATE_WORK_ORDER_TYPE_GQL
          }
          parseMutationArgs={(workOrderType) =>
            isEdit
              ? {
                  id: Number(id),
                  workOrderType: {
                    ...workOrderType,
                    is_selectable:
                      workOrderType.is_selectable === ''
                        ? false
                        : workOrderType.is_selectable,
                  },
                }
              : {
                  ...workOrderType,
                  is_selectable:
                    workOrderType.is_selectable === ''
                      ? false
                      : workOrderType.is_selectable,
                  ...(parentId && { parent_id: parentId }),
                }
          }
          mutationOptions={{
            onCompleted: () => [refetch(), toast('success'), toggle()],
            onError: () => {
              toast('error');
            },
          }}
          fields={[
            {
              label: t('common:Name'),
              name: 'name',
              required: true,
              type: 'text',
            },
            {
              label: t('Selectable'),
              name: 'is_selectable',
              type: 'switcher',
            },
          ]}
        />
      </ModalBody>
    </Modal>
  );
}
