/* eslint-disable @typescript-eslint/no-explicit-any */
import { Firm, InvoiceFull } from '@wgt/types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import moment from 'moment';

import WgtLogo from './assets/img/wgt-logo.png';
import PlaceholderDiamond from './assets/img/placeholder-diamond.png';

export default function aml({
  invoice,
  totalPageIDs,
  executiveBuyerFirm,
  pageIDsColumns,
}: {
  invoice: InvoiceFull;
  totalPageIDs: any;
  pageIDsColumns: any;
  executiveBuyerFirm?: Firm;
}): JSX.Element {
  const { t } = useTranslation('invoicesReport');
  const COUNT = {
    totalSKUs: 0,
    totalPageIDs: 0,
    totalGemologicalReports: 0,
    totalAppraisalsReports: 0,
    totalPaymentSchedules: 0,
  };

  const BALANCE = {
    previousMonthStatementBalance: 2500.0,
    otherAccountsPayablePending: 42.15,
    retainerAvailable: 10000.0,
    total: 12542.15,
  };

  const ANTI_MONEY_LAUNDERING = {
    purchaseTraced: 'Yes',
    purchaseFirm: 'Firm Name',
    purchaseFirmNumber: 12345678919,
    subTotal: 0.0,
    currency: 'Canadian',
    invoiceNumber: 231321,
    traceCode: 1231231,
    traceFee: 0.0,
    soldDate: 'Month Day, Year',
    chargeDiscountAfter: 'Policy Rules field',
    discount: 0.0,
    chargeShipping: 'Policy rules field',
    shippingHandlingName: 'Firm Name',
    shippingHandlingTrackingNumber: 87686,
    shippingFee: 0.0,
    expectedArrivalDate: 'Month Day, Year',
    arrivalDate: 'Month Day, Year',
    chargeInterestAfter: 'Month Day, Year',
    noneCompoundInterest: 'Data',
    interest: 0.0,
    chargeCustomDutyAfter: 'Policy rules field',
    customsFirm: 'Firm Name',
    customDutyAmount: 0.0,
    chargeTaxAfter: 'Policy rules field',
    tax: 0.0,
    totalDue: 0.0,
    expiryDate: 'Month Day, Year',
    returnDate: 'Pending',
    pointOfSalesOption: 'Sold',
  };

  const AML_PAYMENT_SCHEDULE = [
    {
      payee: 'Firm Name',
      payeeFirmIdentificationNumber: 545466,
      personalIdentificationNumber: 232345454,
      transactionNumber: 1231321321,
      accountReceivableDate: 'Month Day, Year',
      accountsReceived: '0.0',
      paymentType: 'data',
      lastFourNumbersCard: 'xxxx-xxxx-xxxx-####',
      receiptNumber: '34327423',
      nextAccountRecievable: 0.0,
      nextAccountReceivableDate: 'Month Day, Year',
      netAccountRecievable: 0.0,
    },
    {
      payee: 'Firm Name',
      payeeFirmIdentificationNumber: 545466,
      personalIdentificationNumber: 232345454,
      transactionNumber: 1231321321,
      accountReceivableDate: 'Late',
      accountsReceived: 'Pending',
      paymentType: 'data',
      lastFourNumbersCard: 'Pending',
      receiptNumber: 'Pending',
      nextAccountRecievable: 0.0,
      nextAccountReceivableDate: 'Month Day, Year',
      netAccountRecievable: 0.0,
    },
  ];

  const STOCK_KEEPING_UNIT_DETAILS = {
    stockKeepingUnit: 23213,
    totalPageIDs: 2,
    totalSKUPrice: 1000.0,
    totalGemologicalReports: 2,
    totalAppraisalReports: 0,
  };
  return (
    <>
      <Row>
        <Col md="4">
          <img src={WgtLogo} className="img-fluid" alt="Company Name" />
        </Col>
        <Col md="4" className="pt-4">
          <p className="text-uppercase">
            <strong>{t('Office')}</strong>
          </p>
          <p className="font-small">
            {t('United States of America')}: 213 986 3526
          </p>
          <p className="font-small">{t('Canada')}: 778.373.6350</p>
        </Col>
        <Col md="4" className="pt-4">
          <p>
            <strong>{t('WEBSITE')}</strong>
          </p>
          <p className="font-small">www.worldgemtrade.com</p>
        </Col>
      </Row>
      <p className="mt-5 mb-3">
        {t('World Gem Trade is a Crime Prevention Network that provides')}
      </p>
      <h1 className="text-uppercase">
        {t('Anti Money Laundering Transaction Statement')}
      </h1>
      <Row className="border p-3 mt-2" noGutters>
        <Col md="6">
          <p className="mb-1">{t('Client Firm Name')}</p>
          <p>
            <strong>{executiveBuyerFirm?.name}</strong>
          </p>
        </Col>
        <Col md="6">
          <p className="mb-1">{t('Client Firm Number')}</p>
          <p>
            <strong>#{executiveBuyerFirm?.id}</strong>
          </p>
        </Col>
      </Row>
      <Row
        className="border-bottom border-color-primary mt-3 mb-2 pb-1"
        noGutters
      >
        <Col>
          <p className="font-large">
            <strong>{t('Statement Start Date')}</strong>
          </p>
        </Col>
        <Col>
          <p className="font-large text-right">
            <strong>{moment().format('LL')}</strong>
          </p>
        </Col>
      </Row>

      <Row noGutters>
        <Col md="6" className="pr-1">
          <Row className="p-3 border border-color-primary box-totals" noGutters>
            <Col md="12" className="border-bottom border-color-primary mb-2">
              <strong>{t('Count')}</strong>
            </Col>
            <Col md="8">{t('Total SKUs')}</Col>
            <Col md="4" className="text-right">
              {invoice.snapshot.products?.length}
            </Col>
            <Col md="8">{t('Total Page IDs')}</Col>
            <Col md="4" className="text-right">
              {totalPageIDs}
            </Col>
            <Col md="8">{t('Total Gemological Reports')}</Col>
            <Col md="4" className="text-right">
              {COUNT.totalGemologicalReports}
            </Col>
            <Col md="8">{t('Total Appraisals Reports')}</Col>
            <Col md="4" className="text-right">
              {COUNT.totalAppraisalsReports}
            </Col>
            <Col md="8">{t('Total Payment Schedules')}</Col>
            <Col md="4" className="text-right">
              {COUNT.totalPaymentSchedules}
            </Col>
          </Row>
        </Col>
        <Col md="6" className="pl-1">
          <Row className="p-3 border border-color-primary box-totals" noGutters>
            <Col md="12" className="border-bottom border-color-primary mb-2">
              <strong>{t('Balance')}</strong>
            </Col>
            <Col md="12">
              <Row noGutters>
                <Col md="11" className="text-right">
                  <strong>{BALANCE.previousMonthStatementBalance} USD</strong>
                  <p className="font-small">
                    {t('Previous Month Statement Balance')}
                  </p>
                </Col>

                <Col md="1" className="text-center">
                  <p>+</p>
                </Col>
              </Row>
              <Row noGutters>
                <Col md="11" className="text-right">
                  <strong>{BALANCE.otherAccountsPayablePending} USD</strong>
                  <p className="font-small">
                    {t('Other accounts Payable Pending')}
                  </p>
                </Col>
                <Col md="1" className="text-center">
                  <p>+</p>
                </Col>
              </Row>
              <Row noGutters>
                <Col md="11" className="text-right">
                  <strong>{BALANCE.retainerAvailable} USD</strong>
                  <p className="font-small">{t('Retainer available')}</p>
                </Col>
                <Col md="1" className="text-center">
                  <p>+</p>
                </Col>
              </Row>
              <Row noGutters className="border-top pt-2">
                <Col md="11" className="text-right">
                  <p className="font-xlarge">
                    <strong className="color-primary">
                      {BALANCE.total} USD
                    </strong>
                  </p>

                  <p className="font-small">{t('Total')}</p>
                </Col>
                <Col md="1" className="text-center">
                  <p>=</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Container fluid="lg">
        <Row className="box-report-yellow-blue mt-3">
          <Col md="12" className="header">
            {t('Anti Money Laundering')}
          </Col>
          <Col md="12" className="body">
            <Row>
              <Col md="4">{t('Was this Purchase traced?')}</Col>
              <Col md="4">{t('Purchase Firm')}</Col>
              <Col md="4">{t('Purchase Firm Number')}</Col>
            </Row>
            <Row>
              <Col md="4">{ANTI_MONEY_LAUNDERING.purchaseTraced}</Col>
              <Col md="4">{invoice?.pointOfSale?.firm?.name}</Col>
              <Col md="4">{invoice?.pointOfSale?.firm?.id}</Col>
            </Row>
            <Row>
              <Col md="4">{t('Sub Total')}</Col>
              <Col md="4">{t('Currency')}</Col>
              <Col md="4">{t('Invoice Number')}</Col>
            </Row>
            <Row>
              <Col md="4">{invoice.snapshot?.sub_total ?? 0}</Col>
              <Col md="4">{ANTI_MONEY_LAUNDERING.currency}</Col>
              <Col md="4">{invoice.id}</Col>
            </Row>
            <Row>
              <Col md="4">{t('Trace Code')}</Col>
              <Col md="4">{t('Trace Fee')}</Col>
              <Col md="4">{t('Sold Date')}</Col>
            </Row>
            <Row>
              <Col md="4">{ANTI_MONEY_LAUNDERING.traceCode}</Col>
              <Col md="4">{ANTI_MONEY_LAUNDERING.traceFee}</Col>
              <Col md="4">{ANTI_MONEY_LAUNDERING.soldDate}</Col>
            </Row>
            <Row>
              <Col md="4">{t('Charge Discount After')}</Col>
              <Col md="4">{t('Discount')}</Col>
              <Col md="4">{t('Charge Shipping & Handling After')}</Col>
            </Row>
            <Row>
              <Col md="4">{ANTI_MONEY_LAUNDERING.chargeDiscountAfter}</Col>
              <Col md="4">{invoice.snapshot?.discount ?? 0}</Col>
              <Col md="4">{ANTI_MONEY_LAUNDERING.chargeShipping}</Col>
            </Row>
            <Row>
              <Col md="4">{t('Shipping & Handling')}</Col>
              <Col md="4">{t('Shipping & Handling Tracking Number')}</Col>
              <Col md="4">{t('Shipping Fee')}</Col>
            </Row>
            <Row>
              <Col md="4">{ANTI_MONEY_LAUNDERING.shippingHandlingName}</Col>
              <Col md="4">
                {ANTI_MONEY_LAUNDERING.shippingHandlingTrackingNumber}
              </Col>
              <Col md="4">{invoice.snapshot?.shipping ?? 0}</Col>
            </Row>
            <Row>
              <Col md="4">{t('Expected Arival Date')}</Col>
              <Col md="4">{t('Arival Date')}</Col>
            </Row>
            <Row>
              <Col md="4">{ANTI_MONEY_LAUNDERING.expectedArrivalDate}</Col>
              <Col md="4">{ANTI_MONEY_LAUNDERING.arrivalDate}</Col>
            </Row>
            <Row>
              <Col md="4">{t('Charge Interest After')}</Col>
              <Col md="4">{t('None Compound Interest')}</Col>
              <Col md="4">{t('Interest')}</Col>
            </Row>
            <Row>
              <Col md="4">{ANTI_MONEY_LAUNDERING.chargeInterestAfter}</Col>
              <Col md="4">{ANTI_MONEY_LAUNDERING.noneCompoundInterest}</Col>
              <Col md="4">{invoice.snapshot?.interest ?? 0}</Col>
            </Row>
            <Row>
              <Col md="4">{t('Charge Custom Duty After')}</Col>
              <Col md="4">{t('Customs Firm')}</Col>
              <Col md="4">{t('Custom Duty Amount')}</Col>
            </Row>
            <Row>
              <Col md="4">{ANTI_MONEY_LAUNDERING.chargeCustomDutyAfter}</Col>
              <Col md="4">{ANTI_MONEY_LAUNDERING.customsFirm}</Col>
              <Col md="4">{invoice.snapshot?.custom_duty ?? 0}</Col>
            </Row>
            <Row>
              <Col md="4">{t('Charge Tax After')}</Col>
              <Col md="4">{t('Tax')}</Col>
              <Col md="4">{t('Total Due')}</Col>
            </Row>
            <Row>
              <Col md="4">{ANTI_MONEY_LAUNDERING.chargeTaxAfter}</Col>
              <Col md="4">{invoice.snapshot?.tax ?? 0}</Col>
              <Col md="4">{invoice.snapshot?.total ?? 0}</Col>
            </Row>
            <Row>
              <Col md="8">
                <Row>
                  <Col md="6">{t('Expiry Date')}</Col>
                  <Col md="6">{t('Return Date')}</Col>
                </Row>
                <Row>
                  <Col md="6">{ANTI_MONEY_LAUNDERING.expiryDate}</Col>
                  <Col md="6">{ANTI_MONEY_LAUNDERING.returnDate}</Col>
                </Row>
              </Col>
              <Col
                md="4"
                className="d-flex justify-content-center align-items-center"
              >
                <p className="font-large color-danger">
                  <strong>{invoice.pointOfSale.intent}</strong>
                </p>
              </Col>
            </Row>
          </Col>
        </Row>

        {AML_PAYMENT_SCHEDULE.map((item, index) => (
          <Row className="box-report-yellow-blue mt-3" key={index}>
            <Col md="12" className="header">
              {t('AML Payment Schedule')}
              <div className="float-right">{index + 1}</div>
            </Col>

            <Col md="12" className="body">
              <Row>
                <Col md="4">{t('Payee')}</Col>
                <Col md="4">{t('Payee Firm Identification Number')}</Col>
                <Col md="4">{t('Personal Identification Number')}</Col>
              </Row>
              <Row>
                <Col md="4">{item.payee}</Col>
                <Col md="4">{item.payeeFirmIdentificationNumber}</Col>
                <Col md="4">{item.personalIdentificationNumber}</Col>
              </Row>
              <Row>
                <Col md="4">{t('Transaction Number')}</Col>
                <Col md="4">{t('Account Receivable Date')}</Col>
                <Col md="4">{t('Accounts Received')}</Col>
              </Row>
              <Row>
                <Col md="4">{item.transactionNumber}</Col>
                <Col md="4">{item.accountReceivableDate}</Col>
                <Col md="4">{item.accountsReceived}</Col>
              </Row>
              <Row>
                <Col md="4">{t('Payment Type')}</Col>
                <Col md="4">{t('Last Four Numbers on the Card')}</Col>
                <Col md="4">{t('Receipt Number')}</Col>
              </Row>
              <Row>
                <Col md="4">{item.paymentType}</Col>
                <Col md="4">{item.lastFourNumbersCard}</Col>
                <Col md="4">{item.receiptNumber}</Col>
              </Row>
              <Row>
                <Col md="4">{t('Next Account Recievable')}</Col>
                <Col md="4">{t('Next Account Receivable Date')}</Col>
                <Col md="4">{t('Net Account Recievable')}</Col>
              </Row>
              <Row>
                <Col md="4">{item.nextAccountRecievable}</Col>
                <Col md="4">{item.nextAccountReceivableDate}</Col>
                <Col md="4">{item.netAccountRecievable}</Col>
              </Row>
            </Col>
          </Row>
        ))}

        <Row className="box-report-blue mt-3">
          <Col md="12" className="header">
            {t('Stock Keeping Unit Details')}
          </Col>
          <Col md="12" className="body">
            <Row>
              <Col md="4">{t('Stock Keeping Unit')}</Col>
              <Col md="4">{t('Total Page IDs')}</Col>
              <Col md="4">{t('Total SKU Price')}</Col>
            </Row>
            <Row>
              <Col md="4">{STOCK_KEEPING_UNIT_DETAILS.stockKeepingUnit}</Col>
              <Col md="4">{STOCK_KEEPING_UNIT_DETAILS.totalPageIDs}</Col>
              <Col md="4">{STOCK_KEEPING_UNIT_DETAILS.totalSKUPrice}</Col>
            </Row>
            <Row>
              <Col md="4">{t('Total Gemological Reports')}</Col>
              <Col md="4">{t('Total Appraisal Reports')}</Col>
            </Row>
            <Row>
              <Col md="4">
                {STOCK_KEEPING_UNIT_DETAILS.totalGemologicalReports}
              </Col>
              <Col md="4">
                {STOCK_KEEPING_UNIT_DETAILS.totalAppraisalReports}
              </Col>
            </Row>
          </Col>
        </Row>

        {pageIDsColumns.map((page: any, index: number) => (
          <Row className="box-report-blue-mixed mt-3" key={index}>
            <Col md="12" className="header">
              {t('Page ID')}
              <div className="float-right">{index + 1}</div>
            </Col>
            <Col md="12" className="body">
              {page.column.map((col: any, keyCol: number) => (
                <React.Fragment key={keyCol}>
                  <Row>
                    {col.map((field: any, keyField: number) => (
                      <Col md="2" key={keyField}>
                        {field.key}
                      </Col>
                    ))}
                  </Row>
                  <Row>
                    {col.map((field: any, keyField: number) => (
                      <Col md="2" key={keyField}>
                        {field.value}
                      </Col>
                    ))}
                  </Row>
                </React.Fragment>
              ))}
            </Col>
          </Row>
        ))}

        <Row className="box-report-blue-mixed mt-3">
          <Col md="12" className="header">
            {t('Page ID')}
            <div className="float-right">1</div>
          </Col>
          <Col md="12" className="body">
            <Row>
              <Col md="2">Stock Price</Col>
              <Col md="2">Single</Col>
              <Col md="2">Category</Col>
              <Col md="2">TD%</Col>
              <Col md="2">TS%</Col>
              <Col md="2">ATS</Col>
            </Row>
            <Row>
              <Col md="2">1,000.00</Col>
              <Col md="2" />
              <Col md="2">Diamond</Col>
              <Col md="2">data</Col>
              <Col md="2">data</Col>
              <Col md="2">data</Col>
            </Row>
            <Row>
              <Col md="2">Color</Col>
              <Col md="2">Carat</Col>
              <Col md="2">Clarity</Col>
              <Col md="2">PA</Col>
              <Col md="2">PD</Col>
              <Col md="2">APD</Col>
            </Row>
            <Row>
              <Col md="2">data</Col>
              <Col md="2">data</Col>
              <Col md="2">data</Col>
              <Col md="2">data</Col>
              <Col md="2">data</Col>
              <Col md="2">data</Col>
            </Row>
            <Row>
              <Col md="2">Measurements</Col>
              <Col md="2">(length)</Col>
              <Col md="2">(width)</Col>
              <Col md="2">(depth)</Col>
              <Col md="2">CA</Col>
              <Col md="2">CH%</Col>
            </Row>
            <Row>
              <Col md="2" />
              <Col md="2">data</Col>
              <Col md="2">data</Col>
              <Col md="2">data</Col>
              <Col md="2">data</Col>
              <Col md="2">data</Col>
            </Row>
            <Row>
              <Col md="2">Cut Grade</Col>
              <Col md="2">Polish</Col>
              <Col md="2">Symmetry</Col>
              <Col md="2">L-H L%</Col>
              <Col md="2">S-L%</Col>
              <Col md="2">GtoTD</Col>
            </Row>
            <Row>
              <Col md="2">data</Col>
              <Col md="2">data</Col>
              <Col md="2">data</Col>
              <Col md="2">data</Col>
              <Col md="2">data</Col>
              <Col md="2">data</Col>
            </Row>
            <Row>
              <Col md="2">Drill</Col>
              <Col md="2">Bulge</Col>
              <Col md="2">Need Polish</Col>
              <Col md="2">AGD</Col>
              <Col md="2">GT%</Col>
            </Row>
            <Row>
              <Col md="2">Girdle Thin</Col>
              <Col md="2">Thick</Col>
              <Col md="2">Condition</Col>
              <Col md="2">data</Col>
              <Col md="2">data</Col>
            </Row>
            <Row>
              <Col md="2">Girdle Thin</Col>
              <Col md="2">Thick</Col>
              <Col md="2">Condition</Col>
              <Col md="2">data</Col>
              <Col md="2">data</Col>
            </Row>
            <Row>
              <Col md="10">
                <Row>
                  <Col md="6">Fluorescence Color</Col>
                  <Col md="6">Laboratory Firm</Col>
                </Row>
                <Row>
                  <Col md="6">data</Col>
                  <Col md="6">data</Col>
                </Row>
                <Row>
                  <Col md="6">External Characterisitics</Col>
                  <Col md="6">Report Identificatioin</Col>
                </Row>
                <Row>
                  <Col md="6">data</Col>
                  <Col md="6">32423423423</Col>
                </Row>
                <Row>
                  <Col md="6">Internal Characterisitics</Col>
                  <Col md="6">Was this Report traced? </Col>
                </Row>
                <Row>
                  <Col md="6">data</Col>
                  <Col md="6" />
                </Row>
              </Col>
              <Col
                md="2"
                className="d-flex justify-content-center align-items-center"
              >
                <img
                  src={PlaceholderDiamond}
                  alt="Placeholder diamond"
                  className="img-fluid"
                />
              </Col>
            </Row>
            <Row>
              <Col md="10">
                <Row>
                  <Col md="6">Treatment</Col>
                  <Col md="6">Laboratory Firm</Col>
                </Row>
                <Row>
                  <Col md="6">data</Col>
                  <Col md="6">data</Col>
                </Row>
                <Row>
                  <Col md="6">Symmetry Features</Col>
                  <Col md="6">Report Identificatioin</Col>
                </Row>
                <Row>
                  <Col md="6">data</Col>
                  <Col md="6">32423423423</Col>
                </Row>
                <Row>
                  <Col md="6">Polish Features</Col>
                  <Col md="6">Was this Report traced?</Col>
                </Row>
                <Row>
                  <Col md="6">data</Col>
                  <Col md="6" />
                </Row>
              </Col>
              <Col
                md="2"
                className="d-flex justify-content-center align-items-center"
              >
                <img
                  src={PlaceholderDiamond}
                  alt="Placeholder diamond"
                  className="img-fluid"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <img src={WgtLogo} className="img-fluid w-20 mt-3" alt="Company Name" />
    </>
  );
}
