/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  useMutation,
  gql,
  MutationResult,
  MutationFunctionOptions,
  FetchResult,
  useQuery,
  QueryResult,
  RefetchQueriesFunction,
  PureQueryOptions,
} from '@apollo/client';
import { InputPayment, Paginator, PaymentBase } from '@wgt/types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import useToast from '../../../../../../hooks/useToast';

function usePaymentsMutations({
  refetchQueries,
}: UsePaymentMutationsProps): UsePaymentsMutations {
  const [create, createResult] = useMutation<
    PaymentCreation,
    PaymentCreationVariables
  >(CREATE_GQL, { refetchQueries });

  const [update, updateResult] = useMutation<
    PaymentUpdate,
    PaymentUpdateVariables
  >(UPDATE_GQL, { refetchQueries });

  const [remove, removeResult] = useMutation<
    PaymentRemove,
    PaymentRemoveVariables
  >(REMOVE_GQL, { refetchQueries });

  return {
    update,
    create,
    remove,
    createResult,
    updateResult,
    removeResult,
  };
}

export default function usePayments({
  invoiceNumber,
  skip,
  fetchPolicy,
}: UsePaymentProps): UsePayment {
  const { t } = useTranslation(['pointOfSales', 'common']);
  const { toast } = useToast();
  const queryVariables = {
    filter: {
      invoice: {
        id: [invoiceNumber],
      },
    },
  };

  const queryResult = useQuery<Paginator<PaymentBase>, PaymentListFilter>(
    LIST_GQL,
    {
      fetchPolicy,
      skip: skip ?? !invoiceNumber,
      variables: queryVariables,
    },
  );

  const {
    create,
    update,
    createResult,
    updateResult,
    remove,
    removeResult,
  } = usePaymentsMutations({
    refetchQueries: [
      {
        query: LIST_GQL,
        variables: queryVariables,
      },
    ],
  });

  const put = async ({
    id,
    paymentGroup,
    plan_next_payment,
    amount,
    compound,
    notCompound,
    compound_interest_date,
    ...variables
  }: InputPayment) => {
    const mainPayment = {
      ...variables,
      amount: Number(amount),
      field_values: JSON.stringify(paymentGroup),
      invoice_id: invoiceNumber,
      payment_compound: compound ? Number(compound) : undefined,
      payment_not_compound: notCompound ? Number(notCompound) : undefined,
      compound_interest_date: compound_interest_date
        ? moment(compound_interest_date).format('YYYY-MM-DD')
        : undefined,
    };

    if (!id) {
      const plannedPayments: InputPayment[] =
        plan_next_payment
          ?.filter((x) => !!x.payment_expected_amount)
          ?.map((plannedPayment) => ({
            amount: Number(plannedPayment.payment_expected_amount),
            schedule_data: plannedPayment.payment_after_date
              ? moment(plannedPayment.payment_after_date).format('YYYY-MM-DD')
              : undefined,
            invoice_id: invoiceNumber,
            status: 'open',
          })) ?? [];

      const paymentsToCreate = [mainPayment, ...plannedPayments];
      const newPaymentsMutations = await Promise.allSettled(
        paymentsToCreate.map((newPayment) => create({ variables: newPayment })),
      );

      if (
        newPaymentsMutations.every((result) => result.status === 'fulfilled')
      ) {
        toast('success', t('Payment created'));
      } else {
        toast('error', t('An error ocurred'));
      }
    } else {
      await update({ variables: { id, payment: mainPayment } });
      toast('success', t('Payment updated'));
    }
  };

  return {
    put,
    query: queryResult,
    create: [put, createResult],
    update: [update, updateResult],
    remove: [remove, removeResult],
  };
}

const CREATE_GQL = gql`
  mutation createPayment(
    $invoice_id: ID!
    $amount: Float!
    $receipt_number: String
    $field_values: JSON
    $planned: Boolean
    $calculate_interest: Boolean
    $payment_compound: Int
    $payment_not_compound: Int
    $interest: Float
    $interest_after: Date
  ) {
    createPayment(
      invoice_id: $invoice_id
      amount: $amount
      receipt_number: $receipt_number
      field_values: $field_values
      planned: $planned
      calculate_interest: $calculate_interest
      payment_compound: $payment_compound
      payment_not_compound: $payment_not_compound
      interest: $interest
      interest_after: $interest_after
    ) {
      id
    }
  }
`;

const UPDATE_GQL = gql`
  mutation updatePayment($id: ID!, $payment: UpdatePaymentInput!) {
    updatePayment(id: $id, payment: $payment) {
      id
    }
  }
`;

const REMOVE_GQL = gql`
  mutation deletePayment($id: ID!) {
    deletePayment(id: $id) {
      message
    }
  }
`;

const LIST_GQL = gql`
  query payments($filter: PaymentFilterInput) {
    payments(filter: $filter) {
      data {
        id
        amount
        field_values
        planned
        calculate_interest
        payment_compound
        payment_not_compound
        interest
        interest_after
        scheduled_date
        status
        invoice {
          id
        }
        receipts {
          id
          files {
            url
            id
            filename
          }
        }
      }
    }
  }
`;

interface PaymentCreationVariables extends InputPayment {
  payment_compound?: number;
  payment_not_compound?: number;
}

interface PaymentCreation {
  createPayment: PaymentBase;
}

interface PaymentUpdate {
  updatePayment: PaymentBase;
}

interface PaymentUpdateVariables {
  id: string;
  payment: any;
}

interface PaymentRemoveVariables {
  id: string;
}

interface PaymentRemove {
  deletePayment: {
    message: string;
  };
}

type CreateAction = (variables: InputPayment) => Promise<void>;
type PutAction = CreateAction;
type CreateResult = MutationResult<PaymentCreation>;

type CreateMutator = (
  options?:
    | MutationFunctionOptions<PaymentCreation, PaymentCreationVariables>
    | undefined,
) => Promise<FetchResult<PaymentCreation>>;
type UpdateMutator = (
  options?:
    | MutationFunctionOptions<PaymentUpdate, PaymentUpdateVariables>
    | undefined,
) => Promise<FetchResult<PaymentUpdate>>;
type UpdateResult = MutationResult<PaymentUpdate>;

type RemoveMutator = (
  options?:
    | MutationFunctionOptions<PaymentRemove, PaymentRemoveVariables>
    | undefined,
) => Promise<FetchResult<PaymentRemove>>;
type RemoveResult = MutationResult<PaymentRemove>;

type PaymentListQueryResult = QueryResult<
  Paginator<PaymentBase>,
  PaymentListFilter
>;

interface UsePayment {
  create: [CreateAction, CreateResult];
  update: [UpdateMutator, UpdateResult];
  remove: [RemoveMutator, RemoveResult];
  query: PaymentListQueryResult;
  put: PutAction;
}

// type UsePayment = [CreateAction, CreateResult];

interface UsePaymentProps {
  invoiceNumber: string;
  skip?: boolean;
  fetchPolicy?:
    | 'cache-first'
    | 'network-only'
    | 'cache-only'
    | 'no-cache'
    | 'standby'
    | 'cache-and-network';
}

interface UsePaymentsMutations {
  create: CreateMutator;
  update: UpdateMutator;
  remove: RemoveMutator;
  createResult: CreateResult;
  updateResult: UpdateResult;
  removeResult: RemoveResult;
}

interface PaymentListFilter {
  first?: number;
  page?: number;
  filter?: {
    invoice?: {
      id?: string[];
      status?: 'active' | 'disabled';
    };
  };
}

interface UsePaymentMutationsProps {
  refetchQueries?: (string | PureQueryOptions)[] | RefetchQueriesFunction;
}
