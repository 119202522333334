import React from 'react';

import TemplateLayout, {
  TemplateLayoutProps,
} from '../../../../../components/TemplateLayout';
import { Title, Line } from '../../../../../components';
import ShapeAndPrice from '../ShapeAndPrice';
import { Row, Col } from 'reactstrap';

const TemplateFieldsFirstSection: React.FC<TemplateLayoutProps> = ({
  rows,
  prefix,
  rangeToSingle,
  disabled,
}) => {
  return (
    <div className="mt-4">
      <Title heading="h2">Advanced</Title>
      <Line />
      <Row className="product-advanced-content">
        <ShapeAndPrice />
        <Col lg="9">
          <TemplateLayout
            rows={rows}
            prefix={prefix}
            component="component"
            rangeToSingle={rangeToSingle}
            disabled={disabled}
            hasDependencies
          />
        </Col>
      </Row>
    </div>
  );
};

export default TemplateFieldsFirstSection;
