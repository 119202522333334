import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Col, ListGroup, ListGroupItem, Row } from 'reactstrap';
import CustomHeader from '../../../../components/_molecules/CustomHeader';
import Loader from '../../../../components/_organisms/Loader';
import useCrmRoutes from '../../../../hooks/useCrmRoutes';
import { GET_CATEGORY_GQL, CREATE_PRODUCT_GQL } from '../graphql';
import { Button } from '../../../../components';
import { CategoryDetailsQuery } from '../types';

const Details: React.FC = () => {
  const { setCustomTitle, routes } = useCrmRoutes();
  const params = useParams<ParamTypes>();
  const history = useHistory();
  const { loading, data } = useQuery<CategoryDetailsQuery>(GET_CATEGORY_GQL, {
    skip: !params?.id,
    variables: {
      id: Number(params.id),
      isEdit: true,
    },
  });
  useEffect(() => {
    if (data?.category?.id) {
      setCustomTitle(data.category.name);
    }
  }, [routes, data]);

  const [createProduct, { loading: creatingProduct }] = useMutation(
    CREATE_PRODUCT_GQL,
    {
      variables: {
        category_id: Number(data?.category.id),
      },
      onCompleted: ({ createProduct: dataResult }) =>
        history.push(`/crm/products/${dataResult.id}/edit`),
    },
  );

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <CustomHeader title={data?.category?.name ?? 'Details'} showEdit />
      <hr />
      <Row>
        <Col>
          <ListGroup>
            <ListGroupItem>
              <b>ID:</b> {data?.category.id}
            </ListGroupItem>
            <ListGroupItem>
              <b>Description:</b> {data?.category.description}
            </ListGroupItem>
            <ListGroupItem>
              <b> Category Type:</b>
              {data?.category.categoryType?.name}
            </ListGroupItem>
            <ListGroupItem>
              <b> Template:</b> {data?.category.template?.name}
            </ListGroupItem>
            <ListGroupItem>
              <b> Is Mixed:</b> {data?.category.mixed ? 'Yes' : 'No'}
            </ListGroupItem>
          </ListGroup>
        </Col>
        <Col>
          <Button isLoading={creatingProduct} onClick={createProduct}>
            {creatingProduct ? 'Setting up new product' : 'Add product'}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default Details;
