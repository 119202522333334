import { gql } from '@apollo/client';

const mineralFragment = gql`
  fragment mineral_Part on Mineral {
    id
    type
    classification {
      id
      label: name
    }
    group {
      id
      label: name
    }
    subgroup {
      id
      label: name
    }
    species {
      id
      label: name
    }
    variety {
      id
      label: name
    }
    series {
      id
      label: name
    }
  }
`;
export const MINERAL_DETAILS = gql`
  query mineral($id: ID!) {
    mineral(id: $id) {
      ...mineral_Part
      files {
        id
        file_url
        type
      }
      propertyValue {
        id
      }
      formula
      color
      color_of_streak
      crystal_system
      absorption
      hardness
      transparency
      fluorescence
      density
      luster
      refractive_index
      cleavage
      dispersion
      double_refraction
      pleochroism
      fracture
      specific_gravity
    }
  }
  ${mineralFragment}
`;

export const LIST_MINERALS = gql`
  query minerals($filter: MineralFilterInput) {
    minerals(filter: $filter, first: 1000) {
      data {
        ...mineral_Part
      }
    }
  }
  ${mineralFragment}
`;

export const UPDATE_MINERAL = gql`
  mutation updateMineral($id: ID!, $mineral: UpdateMineralInput!) {
    updateMineral(id: $id, mineral: $mineral) {
      id
    }
  }
`;
export const DELETE_MINERAL = gql`
  mutation deleteMineral($id: ID!) {
    deleteMineral(id: $id) {
      message
    }
  }
`;

export const CREATE_FILE = gql`
  mutation createFile(
    $product_id: ID!
    $file: Upload!
    $thumbnail: Upload
    $type: FileTypes
    $title: String!
  ) {
    createFile(
      product_id: $product_id
      file: $file
      thumbnail: $thumbnail
      type: $type
      title: $title
    ) {
      id
      title
      file_url
      thumbnail_url
      background
    }
  }
`;

export const ATTACH_FILE_TO_MINERAL = gql`
  mutation attachFilesToMineral($id: ID!, $firm_id: ID!, $file_ids: [ID!]!) {
    attachFilesToMineral(id: $id, firm_id: $firm_id, file_ids: $file_ids) {
      message
    }
  }
`;
export const DETACH_FILE_TO_MINERAL = gql`
  mutation detachFilesToMineral($id: ID!, $file_ids: [ID!]!) {
    detachFilesToMineral(id: $id, file_ids: $file_ids) {
      message
    }
  }
`;
export const REMOVE_MINERAL_FILE = gql`
  mutation deleteFile($id: ID!) {
    deleteFile(id: $id) {
      message
    }
  }
`;
