import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Row, Col } from 'reactstrap';
import MineralGroupSelector, {
  LIST_MINERAL_GROUPS,
} from '../../../../../../components/MineralGroupSelector';
import ConfirmDialog from '../../../../../../components/_molecules/ConfirmDialog';
import { REMOVE_MINERAL_GROUP, UPDATE_MINERAL_GROUP } from '../../../graphql';
import MineralGroupUpdateModal from '../MineralForm/MineralGroupUpdateModal';

export default function Minerals(): JSX.Element {
  const { t } = useTranslation(['property', 'common']);
  const [mineralGroupIdToDelete, setMineralGroupIdToDelete] = useState<
    string | number | undefined
  >();
  const [mineralGroupIdToUpdate, setMineralGroupIdToUpdate] = useState<
    string | number | undefined
  >();

  const { watch, getValues, setValue } = useFormContext();
  const state = watch([
    'mineral.classification',
    'mineral.group',
    'mineral.subgroup',
    'mineral.species',
    'mineral.variety',
    'mineral.series',
  ]);

  const [removeMineralGroup, { loading }] = useMutation(REMOVE_MINERAL_GROUP, {
    onCompleted: () => [
      setMineralGroupIdToDelete(undefined),
      Object.keys(state).forEach(
        (mineralGroupKey) =>
          getValues(mineralGroupKey)?.id && setValue(mineralGroupKey, ''),
      ),
    ],
    refetchQueries: [{ query: LIST_MINERAL_GROUPS }],
  });

  const [updateMineralGroup, { loading: updating }] = useMutation(
    UPDATE_MINERAL_GROUP,
    {
      onCompleted: () => [
        setMineralGroupIdToUpdate(undefined),
        Object.keys(state).forEach(
          (mineralGroupKey) =>
            getValues(mineralGroupKey)?.id && setValue(mineralGroupKey, ''),
        ),
      ],
      refetchQueries: [{ query: LIST_MINERAL_GROUPS }],
    },
  );

  const onSubmitUpdateMineralGroup = (name: string) =>
    updateMineralGroup({
      variables: { id: mineralGroupIdToUpdate, mineralGroup: { name } },
    });

  return (
    <>
      <ConfirmDialog
        isShown={!!mineralGroupIdToDelete}
        onConfirm={() =>
          removeMineralGroup({ variables: { id: mineralGroupIdToDelete } })
        }
        title={t('Are you sure you want remove this Mineral Group')}
        onClose={() => setMineralGroupIdToDelete(undefined)}
        isLoading={loading}
      />
      <MineralGroupUpdateModal
        mineralId={mineralGroupIdToUpdate}
        onCancel={() => setMineralGroupIdToUpdate(undefined)}
        onSubmit={onSubmitUpdateMineralGroup}
        isLoading={updating}
      />
      <Row>
        <Col>
          <Controller
            name={`mineral.classification`}
            rules={{ required: true }}
            defaultValue={getValues(`mineral.classification`) ?? ''}
            render={({ onChange, value, name }) => (
              <>
                <MineralGroupSelector
                  name={name}
                  label={t('Classification')}
                  value={value}
                  onChange={onChange}
                  handleDelete={setMineralGroupIdToDelete}
                  isLoadingDelete={loading}
                  handleUpdate={setMineralGroupIdToUpdate}
                  type="classification"
                />
              </>
            )}
          />
        </Col>
        <Col>
          <Controller
            name={`mineral.group`}
            defaultValue={getValues(`mineral.group`) ?? ''}
            render={({ onChange, value, name }) => (
              <>
                <MineralGroupSelector
                  name={name}
                  label={t('Group')}
                  value={value}
                  onChange={onChange}
                  handleDelete={setMineralGroupIdToDelete}
                  isLoadingDelete={loading}
                  handleUpdate={setMineralGroupIdToUpdate}
                  type="group"
                  parentId={state['mineral.classification']?.id}
                  skip={!state['mineral.classification']?.id}
                />
              </>
            )}
          />
        </Col>
        <Col>
          <Controller
            name={`mineral.subgroup`}
            defaultValue={getValues(`mineral.subgroup`) ?? ''}
            render={({ onChange, value, name }) => (
              <MineralGroupSelector
                name={name}
                label={t('Subgroup')}
                value={value}
                onChange={onChange}
                handleDelete={setMineralGroupIdToDelete}
                isLoadingDelete={loading}
                handleUpdate={setMineralGroupIdToUpdate}
                type="subgroup"
                parentId={
                  state['mineral.group']?.id ??
                  state['mineral.classification']?.id
                }
                skip={!state['mineral.classification']?.id}
              />
            )}
          />
        </Col>
        <Col>
          <Controller
            name={`mineral.species`}
            defaultValue={getValues(`mineral.species`) ?? ''}
            render={({ onChange, value, name }) => (
              <MineralGroupSelector
                name={name}
                label={t('Species')}
                value={value}
                onChange={onChange}
                handleDelete={setMineralGroupIdToDelete}
                isLoadingDelete={loading}
                handleUpdate={setMineralGroupIdToUpdate}
                type="species"
                parentId={
                  state['mineral.subgroup']?.id ??
                  state['mineral.group']?.id ??
                  state['mineral.classification']?.id
                }
                skip={!state['mineral.classification']?.id}
              />
            )}
          />
        </Col>
        <Col>
          <Controller
            name={`mineral.variety`}
            defaultValue={getValues(`mineral.variety`) ?? ''}
            render={({ onChange, value, name }) => (
              <MineralGroupSelector
                name={name}
                label={t('Variety')}
                value={value}
                onChange={onChange}
                handleDelete={setMineralGroupIdToDelete}
                isLoadingDelete={loading}
                handleUpdate={setMineralGroupIdToUpdate}
                type="variety"
                parentId={
                  state['mineral.species']?.id ??
                  state['mineral.subgroup']?.id ??
                  state['mineral.group']?.id ??
                  state['mineral.classification']?.id
                }
                skip={!state['mineral.classification']?.id}
              />
            )}
          />
        </Col>
        <Col>
          <Controller
            name={`mineral.series`}
            defaultValue={getValues(`mineral.series`) ?? ''}
            render={({ onChange, value, name }) => (
              <MineralGroupSelector
                name={name}
                label={t('Series')}
                value={value}
                onChange={onChange}
                handleDelete={setMineralGroupIdToDelete}
                isLoadingDelete={loading}
                handleUpdate={setMineralGroupIdToUpdate}
                type="series"
                parentId={
                  state['mineral.variety']?.id ??
                  state['mineral.species']?.id ??
                  state['mineral.subgroup']?.id ??
                  state['mineral.group']?.id ??
                  state['mineral.classification']?.id
                }
                skip={!state['mineral.classification']?.id}
              />
            )}
          />
        </Col>
      </Row>
    </>
  );
}
