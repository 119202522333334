/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import {
  LayoutComponentBase,
  LayoutComponentDataTypePropertyValue,
} from '@wgt/types';
import { Controller, useFormContext } from 'react-hook-form';
import PropertyValueWithVariations from './PropertyValueWithVariations';

interface PropertyValueItemProps {
  propertyValue: LayoutComponentDataTypePropertyValue;
  field?: string;
  dataType?: LayoutComponentBase;
}

interface RenderItemProps extends PropertyValueItemProps {
  isOpen: boolean;
  setOpenVariations(open: boolean): void;
  dataType?: LayoutComponentBase;
}

const RenderItem: React.FC<RenderItemProps> = ({
  propertyValue,
  field,
  dataType,
}) => {
  const { getValues, setValue } = useFormContext();
  const registeredField = `${field}.${propertyValue.key}.selected`;

  const handleChange = (onChange: (...event: any[]) => void) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (dataType?.key === 'single-select') {
      const values = getValues(registeredField ?? '');
      Object.keys(values ?? {}).forEach((propertyValueKey) => {
        if (registeredField === propertyValueKey) {
          return;
        }
        values[propertyValueKey].selected = false;

        if (!!values[propertyValueKey]?.variations) {
          Object.keys(values[propertyValueKey].variations).forEach(
            (variationKey) => {
              values[propertyValueKey].variations[variationKey] = false;
            },
          );
        }
      });
      setValue(field ?? '', values);
    }
    onChange(event.target.checked);
  };

  return (
    <div>
      <Controller
        name={registeredField}
        defaultValue={getValues(registeredField) ?? false}
        render={({ onChange, value }) => (
          <>
            <input
              type="checkbox"
              id={`characteristic-${propertyValue.id}`}
              checked={value}
              className={'template-characteristic-value'}
              onChange={handleChange(onChange)}
            />
            <label
              htmlFor={`characteristic-${propertyValue.id}`}
              className="template-characteristic-value-label"
            >
              {propertyValue.name}
            </label>
          </>
        )}
      />
    </div>
  );
};

const PropertyValueItem: React.FC<PropertyValueItemProps> = ({
  propertyValue,
  field,
  dataType,
}) => {
  const [isOpenVariations, setOpenVariations] = useState(false);
  if (!!propertyValue.variations?.length) {
    return (
      <PropertyValueWithVariations
        isOpen={isOpenVariations}
        setOpenVariations={setOpenVariations}
        field={field}
        propertyValue={propertyValue}
        dataType={dataType}
      />
    );
  }
  return (
    <RenderItem
      dataType={dataType}
      setOpenVariations={setOpenVariations}
      isOpen={isOpenVariations}
      propertyValue={propertyValue}
      field={field}
    />
  );
};

export default PropertyValueItem;
