/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';
import { Shape } from '@wgt/types';
import classnames from 'classnames';

interface ShapeItemProps {
  shape: Shape;
  value: any[];
  onChange: any;
  isMulti: boolean;
  disabled?: boolean;
}
export default function ShapeItem({
  shape,
  value,
  onChange,
  isMulti,
  disabled,
}: ShapeItemProps): JSX.Element {
  const isSelected = useMemo(
    () => value.some((x: Shape) => x.id === shape.id),
    [value, isMulti, shape],
  );

  return (
    <div
      key={shape.id}
      className="col-auto d-flex align-items-center pb-2 bg-white mr-2 "
    >
      <div
        className={classnames('card card-block h-100', {
          selected: isSelected,
        })}
        onClick={() => !disabled && onChange(shape)}
      >
        <img
          className="card-img-top"
          src={
            isSelected
              ? shape.image_selected_url ?? shape.image_url
              : shape.image_url
          }
          alt={shape.title}
        />
        <p className="text-footer mb-0 mt-auto d-flex justify-content-center text-center align-items-center">
          {shape.title}
        </p>
      </div>
    </div>
  );
}
