import React from 'react';
import { Shape, PagesOptions } from '@wgt/types';
import { Pane, SegmentedControl } from 'evergreen-ui';
import { Controller } from 'react-hook-form';
import ShapeSelector from '../ShapeSelector';

interface ShapeProps {
  field: string;
  items: Shape[];
  isMulti?: boolean;
  disabled?: boolean;
  numberOfPagesOptions?: PagesOptions[];
}

export default function Shapes({
  items,
  field,
  isMulti,
  disabled,
  numberOfPagesOptions = [],
}: ShapeProps): JSX.Element {
  return (
    <Pane>
      <ShapeSelector
        name={field}
        options={items}
        isMulti={isMulti}
        disabled={disabled}
      />
      {!!numberOfPagesOptions.length && (
        <div className="d-flex">
          <div className="ml-auto">
            <Controller
              name="pages_number"
              render={({ onChange, value }) => (
                <SegmentedControl
                  className="segment-control"
                  width={350}
                  options={numberOfPagesOptions}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </div>
        </div>
      )}
    </Pane>
  );
}
