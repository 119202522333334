import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  CREATE_PROPERTY_VALUE_VARIATION_VALUE_GQL,
  UPDATE_PROPERTY_VALUE_VARIATION_VALUE_GQL,
  LIST_PROPERTY_VALUE_VARIATION_VALUES_GQL,
  GET_PROPERTY_VALUE_VARIATION_VALUE_GQL,
} from '../../../graphql';
import SimpleForm from '../../../../../../components/_organisms/SimpleForm';
import useToast from '../../../../../../hooks/useToast';

interface PutProps {
  toggle?: () => void;
  isModal?: boolean;
}

const Put: React.FC<PutProps> = ({ toggle, isModal }) => {
  const params = useParams<{ id: string; variationValueId: string }>();
  const { toast } = useToast();
  const isEdit = useMemo<boolean>(() => !!params?.variationValueId, [params]);

  return (
    <div>
      <SimpleForm
        isModal={isModal}
        query={GET_PROPERTY_VALUE_VARIATION_VALUE_GQL}
        queryOptions={{
          variables: { isEdit, id: params.variationValueId ?? '' },
        }}
        parseQuery={({ propertyValueVariationValue }) => ({
          ...propertyValueVariationValue,
          property_value_variation_id: Number(params.id),
        })}
        mutation={
          isEdit
            ? UPDATE_PROPERTY_VALUE_VARIATION_VALUE_GQL
            : CREATE_PROPERTY_VALUE_VARIATION_VALUE_GQL
        }
        parseMutationArgs={(propertyValueVariationValue) =>
          isEdit
            ? {
                id: Number(params.variationValueId),
                propertyValueVariationValue: {
                  ...propertyValueVariationValue,
                },
              }
            : {
                ...propertyValueVariationValue,
                property_value_variation_id: Number(params.id),
              }
        }
        mutationOptions={{
          refetchQueries: [
            {
              query: LIST_PROPERTY_VALUE_VARIATION_VALUES_GQL,
              variables: {
                filter: { property_value_variation_id: Number(params.id) },
              },
            },
          ],
          onCompleted: () => [toast('success'), toggle && toggle()],
          onError: () => {
            toast('error');
          },
        }}
        fields={[
          {
            label: 'Name',
            name: 'name',
            required: true,
            type: 'text',
          },
          {
            label: 'Key',
            name: 'key',
            required: true,
            type: 'text',
          },
          {
            label: 'Image',
            name: 'image',
            type: 'upload',
            isEdit,
            accept: '.jpg,.png,.jpeg,.gif',
          },
        ]}
      />
    </div>
  );
};

export default Put;
