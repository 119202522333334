import { gql } from '@apollo/client';
import { paginatorInfoFragment } from '../../../services/graphql/paginator.gql';

export const LIST_CATEGORY_GROUPS_GQL = gql`
  query categoryGroupsQuery($page: Int) {
    categoryGroups(page: $page) {
      data {
        id
        name
      }
      paginatorInfo {
        ...paginatorInfo_Part
      }
    }
  }
  ${paginatorInfoFragment}
`;

export const CREATE_CATEGORY_GROUPS_GQL = gql`
  mutation createCategoryGroupMutation($name: String!) {
    createCategoryGroup(name: $name) {
      id
    }
  }
`;

export const UPDATE_CATEGORY_GROUPS_GQL = gql`
  mutation updateCategoryGroupMutation(
    $id: ID!
    $category_group: UpdateCategoryInput!
  ) {
    updateCategoryGroup(id: $id, category_group: $category_group) {
      id
    }
  }
`;

export const GET_CATEGORY_GROUPS_GQL = gql`
  query getCategoryGroup($id: ID!) {
    categoryGroup(id: $id) {
      id
      name
    }
  }
`;

export const DELETE_CATEGORY_GROUP_GQL = gql`
  mutation deleteCategoryGroupMutation($id: ID!) {
    deleteCategoryGroup(id: $id) {
      message
    }
  }
`;
