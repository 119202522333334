import React, { useMemo, useEffect, useState } from 'react';
import { Nav, NavItem, TabContent, TabPane, NavLink } from 'reactstrap';
import CustomHeader from '../../../../components/_molecules/CustomHeader';
import { useParams, useHistory } from 'react-router-dom';
import useCrmRoutes from '../../../../hooks/useCrmRoutes';
import Form from './Form';
import { SubmitHandler } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import {
  CREATE_USER_GQL,
  ASSING_ROLE_TO_USER_GQL,
  REMOVE_ROLE_FROM_USER_GQL,
  LIST_USERS_GQL,
  EDIT_USER_SCREEN,
} from '../graphql';
import Loader from '../../../../components/_organisms/Loader';
import TabRoles from './TabRoles';
import TabPermissions from './TabPermissions';
import { User } from '@wgt/types';
import { useTranslation } from 'react-i18next';
import useToast from '../../../../hooks/useToast';

const Put: React.FC = () => {
  const { t } = useTranslation(['common', 'users']);
  const history = useHistory();
  const params = useParams<ParamTypes>();
  const [activeTab, setActiveTab] = useState('content');
  const { setCustomTitle, actualRoute } = useCrmRoutes();
  const { toast } = useToast();
  const [createUser, { loading: submitting }] = useMutation(CREATE_USER_GQL, {
    refetchQueries: [{ query: LIST_USERS_GQL }],
    onCompleted: ({ register }) => [
      toast('success'),
      history.push(`/crm/${actualRoute}/${register.id}/edit`),
    ],
  });

  const isEdit = useMemo(() => !!params?.id, [params]);

  const { data, refetch, loading } = useQuery(EDIT_USER_SCREEN, {
    variables: {
      isEdit,
      id: params?.id,
    },
  });

  const { user } = useMemo(
    () => (data ? { user: data.user } : { user: undefined }),
    [data],
  );

  function reload() {
    toast('success');
    refetch();
  }

  const [assignRoleToUser, { loading: assingingRole }] = useMutation(
    ASSING_ROLE_TO_USER_GQL,
    {
      onCompleted: (assignData) => {
        if (assignData?.assignRoleToUser?.message) {
          reload();
        }
      },
    },
  );
  const [removeRoleFromUser, { loading: removingRoleFromUser }] = useMutation(
    REMOVE_ROLE_FROM_USER_GQL,
    {
      onCompleted: (removeData) => {
        if (removeData?.removeRoleToUser?.message) {
          reload();
        }
      },
    },
  );

  useEffect(() => {
    setCustomTitle(
      `${isEdit ? t('common:Edit') : t('common:Create')} ${t('common:User')}`,
    );
  }, []);

  const onSubmit: SubmitHandler<User> = async (variables) => {
    if (isEdit) {
      // there is no backend updateUser mutation
      console.log('edit');
    } else {
      await createUser({
        variables,
      });
    }
  };

  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const onAddRoleToUser: SubmitHandler<{ role_id: string }> = async (
    variables,
  ) =>
    assignRoleToUser({
      variables: {
        id: user?.id,
        ...variables,
      },
    });

  const onRemoveRoleFromUser: (roleId: number | undefined) => void = async (
    roleId,
  ) => {
    removeRoleFromUser({
      variables: {
        id: user?.id,
        role_id: roleId,
      },
    });
  };

  if (loading || assingingRole || removingRoleFromUser) {
    return <Loader />;
  }

  return (
    <div>
      <CustomHeader
        title={`${isEdit ? t('common:Edit') : t('commonCreate')}`}
        showList
      />
      <Nav tabs>
        <NavItem>
          <NavLink
            active={activeTab === 'content'}
            onClick={() => toggle('content')}
          >
            {t('users:Content')}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            disabled={!isEdit}
            active={activeTab === 'roles'}
            onClick={() => toggle('roles')}
          >
            {`${t('users:Roles')} ${
              !isEdit ? `(${t('common:save content first')})` : ''
            }`}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            disabled={!isEdit}
            active={activeTab === 'permissions'}
            onClick={() => toggle('permissions')}
          >
            {`${t('users:Permissions')} ${
              !isEdit ? `(${t('common:save content first')})` : ''
            }`}
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="content">
          <Form
            onSubmit={onSubmit}
            isSubmitting={submitting}
            data={user}
            isEdit={isEdit}
          />
        </TabPane>
        <TabPane tabId="roles">
          {activeTab === 'roles' && user && (
            <TabRoles
              user={user}
              onSubmit={onAddRoleToUser}
              onRemove={onRemoveRoleFromUser}
            />
          )}
        </TabPane>
        <TabPane tabId="permissions">
          {activeTab === 'permissions' && user && (
            <TabPermissions user={user} />
          )}
        </TabPane>
      </TabContent>
    </div>
  );
};

export default Put;
