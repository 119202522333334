import React from 'react';
import { Col, Row, Container } from 'reactstrap';
import TemplateLayoutCharacteristic from '../TemplateLayoutCharacteristic';
import { ComponentEnum } from '../_organisms/RenderLayoutComponent/types';
import TemplateLayoutRow from './TemplateLayoutRow';
import { Title, Line } from '../../components';
import { TemplateLayoutRow as TemplateLayoutRowType } from '@wgt/types';

export interface TemplateLayoutProps {
  rows: TemplateLayoutRowType[];
  prefix?: string;
  hasDependencies?: boolean;
  component: ComponentEnum;
  rangeToSingle?: boolean;
  resultsForm?: boolean;
  disabled?: boolean;
  borderedError?: boolean;
  useLegacy?: boolean;
}

export default function TemplateLayout({
  rows,
  prefix = '',
  hasDependencies,
  component = 'component',
  rangeToSingle = false,
  resultsForm,
  disabled,
  borderedError,
  useLegacy,
}: TemplateLayoutProps): JSX.Element {
  return (
    <>
      {rows?.map((row, iRow) => (
        <Row key={iRow}>
          {row.map((col, iColumn, array) => (
            <Col key={`line-${iRow}-column-${iColumn}`} md={col.size}>
              {col.group.title ? (
                <>
                  <Title heading="h2" className="mt-4">
                    {col.group.title}
                  </Title>
                  <Line />
                </>
              ) : array[iColumn - 1]?.group?.title ||
                array[iColumn - 2]?.group?.title ||
                array[iColumn - 3]?.group?.title ? (
                <>
                  <Title heading="h2" className="mt-4">
                    &nbsp;
                  </Title>
                  <Line />
                </>
              ) : (
                <></>
              )}
              {col.group.isCharacteristic ? (
                <TemplateLayoutCharacteristic
                  col={col}
                  prefix={prefix}
                  disabled={disabled}
                />
              ) : (
                <Container fluid className="px-0 mx-0">
                  <TemplateLayoutRow
                    iRow={iRow}
                    iColumn={iColumn}
                    col={col}
                    prefix={prefix}
                    hasDependencies={hasDependencies}
                    component={component}
                    rangeToSingle={rangeToSingle}
                    resultsForm={resultsForm}
                    disabled={disabled}
                    borderedError={borderedError}
                    useLegacy={useLegacy}
                  />
                </Container>
              )}
            </Col>
          ))}
        </Row>
      ))}
    </>
  );
}
