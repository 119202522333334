import React from 'react';
import { Row, Col } from 'reactstrap';
import TemplateLayout from '../../../../../components/TemplateLayout';
import { SearchTabsProps } from '.';
type AdvancedTabProps = SearchTabsProps;

const AdvancedTab: React.FC<AdvancedTabProps> = ({ row }) => {
  return (
    <Row className="available-tab-template-fields">
      <Col md={12}>
        <TemplateLayout rows={row} prefix="page." component="searchComponent" />
      </Col>
    </Row>
  );
};

export default AdvancedTab;
