import { useMutation } from '@apollo/client';
import { IconButton } from 'evergreen-ui';
import React, { useContext } from 'react';
import { FaTrash } from 'react-icons/fa';
import { Text } from '../../../../../../../../components';
import { TemplateFieldsContext } from '../../../../../../../../hooks/useTemplateFieldsContext';
import { DETACH_TEMPLATE_FIELD_VALUE_VARIATION } from '../../../../../graphql';
import { FieldPropertyValueVariationListItemProps } from '../../../../../types';
import { GET_TEMPLATE_FIELD_VALUES } from './graphql';

const FieldPropertyValueVariationListItem: React.FC<FieldPropertyValueVariationListItemProps> = ({
  variation,
  propertyValueId,
}) => {
  const { active } = useContext(TemplateFieldsContext);

  const [detach, { loading }] = useMutation(
    DETACH_TEMPLATE_FIELD_VALUE_VARIATION,
    {
      refetchQueries: [
        {
          query: GET_TEMPLATE_FIELD_VALUES,
          variables: { templateId: active?.id },
        },
      ],
      variables: {
        template_field_id: active?.id,
        property_value_id: propertyValueId,
        property_value_variation_id: variation.id,
      },
    },
  );

  return (
    <li className="list-group-item d-flex justify-content-between align-items-center">
      <Text>{variation.name}</Text>
      <IconButton
        onClick={() => detach()}
        icon={FaTrash}
        appearance="minimal"
        intent="danger"
        isLoading={loading}
        disabled={loading}
      />
    </li>
  );
};

export default FieldPropertyValueVariationListItem;
