import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Button } from '../..';

const BreadcrumbSearchInput: React.FC = () => {
  const methods = useForm();
  const history = useHistory();
  const onSubmit: SubmitHandler<{ search: string }> = (variables) =>
    history.push(`/crm/search/${variables.search}`);
  return (
    <FormProvider {...methods}>
      <div className="bc-search">
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <input
            required
            placeholder="search by sku / IDs / upc"
            ref={methods.register}
            name="search"
          />
          <Button small htmlType="submit">
            search
          </Button>
        </form>
      </div>
    </FormProvider>
  );
};

export default BreadcrumbSearchInput;
