import React from 'react';
import { Pane } from 'evergreen-ui';
import { useProductUpdate } from '../../../Provider';
import { PageId } from '@wgt/types';

export default function Content(): JSX.Element {
  const { pages } = useProductUpdate();
  const getImageFile = (page: PageId) =>
    page.files?.find((file) => file.type?.toLowerCase() === 'image');

  return pages.fields?.length ? (
    <Pane className="layout-content-pages-images">
      {pages.fields?.map((page, index) =>
        getImageFile(page) ? (
          <Pane key={`page-content-${index}`}>
            {getImageFile(page)?.image_url && (
              <img src={getImageFile(page)?.image_url} />
            )}
            <span> {`${index + 1}`}</span>
            <br />
          </Pane>
        ) : (
          <React.Fragment key={`page-content-${index}`} />
        ),
      )}
    </Pane>
  ) : (
    <React.Fragment />
  );
}
