import React from 'react';
import { FieldPropertyValueVariationListProps } from '../../../../../types';
import FieldPropertyValueVariationListItem from './FieldPropertyValueVariationListItem';

const FieldPropertyValueVariationList: React.FC<FieldPropertyValueVariationListProps> = ({
  variations,
  propertyValueId,
}) => {
  return (
    <ul className="list-group p-3">
      {variations.map((variation) => (
        <FieldPropertyValueVariationListItem
          key={variation.id}
          variation={variation}
          propertyValueId={propertyValueId}
        />
      ))}
    </ul>
  );
};

export default FieldPropertyValueVariationList;
