import React from 'react';
import { Popover, PopoverBody } from 'reactstrap';
import { DropTargetMonitor, useDrag, useDrop, XYCoord } from 'react-dnd';
import { useParams } from 'react-router-dom';
import { useApolloClient, useQuery } from '@apollo/client';
import { useProductUpdate } from '../../Provider';
import { productIdentifierFragment } from '../../../graphql';
import { PRODUCT } from '../../../ProductUpdateRow/graphql';
import { FaChevronDown } from 'react-icons/fa';
import './style.scss';

interface PageIDProps {
  value: {
    __id: string;
    name: string;
    id: string;
  };
  close(): void;
}
interface PageIDsProps {
  productId?: string | null;
}

interface Draggable {
  type: 'card';
  page: {
    __id: string;
    name: string;
    id: string;
  };
}

const PageID: React.FC<PageIDProps> = ({ value, close }) => {
  const ref = React.useRef<HTMLButtonElement>(null);
  const { pages } = useProductUpdate();
  const [, drop] = useDrop({
    accept: 'card',
    hover: (item: Draggable, monitor: DropTargetMonitor) => {
      if (!ref.current) {
        return;
      }
      const fromPosition = pages.fields.findIndex(
        (x) => x.__id === item.page.__id,
      );
      const toPosition = pages.fields.findIndex((x) => x.__id === value.__id);
      if (fromPosition === toPosition) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (fromPosition < toPosition && hoverClientY < hoverMiddleY) {
        return;
      }
      if (fromPosition > toPosition && hoverClientY > hoverMiddleY) {
        return;
      }

      pages.move(fromPosition, toPosition);
    },
  });
  const [{ isDragging }, drag] = useDrag({
    item: { type: 'card', page: value },
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <button
      type="button"
      ref={ref}
      className="btn btn-sm btn-clear btn-block text-left page-id-button-content"
      onClick={() => [
        close(),
        pages.setActive(pages.fields.findIndex((x) => x.__id === value.__id)),
      ]}
      style={{ opacity }}
    >
      &nbsp;{value.name}
    </button>
  );
};

const PageIDs: React.FC<PageIDsProps> = ({ productId }) => {
  const { pages, filters } = useProductUpdate();
  const params = useParams<ParamTypes>();
  const client = useApolloClient();
  const productData = productId
    ? useQuery(PRODUCT, {
        variables: filters,
        fetchPolicy: 'cache-only',
      })
    : client.readFragment({
        fragment: productIdentifierFragment,
        id: `Product:${params.id}`,
      });
  const product = productData?.data?.product ?? productData;

  const [popoverOpen, setPopoverOpen] = React.useState(false);

  const toggle = () => setPopoverOpen(!popoverOpen);

  if (Number(product?.category?.categoryType?.id) === 1) {
    return <div style={{ marginRight: '1rem' }} />;
  }

  return (
    <div className="page-ids p-1 rounded" id="field-page-id">
      <div className="input-group">
        <div className="input-group-prepend">
          <span className="input-group-text rounded">
            <p className="m-0">Page ID</p>
          </span>
        </div>
        {pages.active && (
          <div className="input-group-prepend ml-2">
            <span className="input-group-text rounded page-id-index">
              <p className="m-0">{(pages.activeIndex ?? 0) + 1}</p>
            </span>
          </div>
        )}
        <button
          id="pageId"
          className="btn btn-clear text-center d-flex align-items-center justify-content-between my-0 mx-2 p-0 "
          type="button"
        >
          {pages.active && (
            <>
              <p className="mb-0 text-truncate">{pages.active?.name ?? ''}</p>
              <FaChevronDown size={14} />
            </>
          )}
        </button>
        <Popover
          placement="bottom"
          isOpen={popoverOpen}
          target="pageId"
          toggle={toggle}
          popperClassName="max-width-100"
          trigger="hover"
        >
          <PopoverBody>
            {pages.fields.map((page) => (
              <React.Fragment key={`page-id-header-${page.__id}`}>
                <PageID value={page} close={toggle} />
              </React.Fragment>
            ))}
          </PopoverBody>
        </Popover>
      </div>
    </div>
  );
};

export default PageIDs;
