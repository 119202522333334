import React from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { useMutation, gql } from '@apollo/client';
import ForgotPasswordForm, {
  FormValues,
} from '../../../components/_molecules/ForgotPasswordForm';
import useToast from '../../../hooks/useToast';
import { useTranslation } from 'react-i18next';

export const FORGOT_PASSWORD_MUTATION = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email) {
      message
    }
  }
`;

const ForgotPassword: React.FC = () => {
  const { t } = useTranslation('authentication');
  const history = useHistory();
  const { toast } = useToast();
  const [forgotPassword, { loading }] = useMutation(FORGOT_PASSWORD_MUTATION, {
    onError: (errors) => toast('error', t(errors.message)),
    onCompleted: ({ forgotPassword: response }) => [
      toast('success', response.message),
      history.push('/auth'),
    ],
  });

  const handleForgot: SubmitHandler<FormValues> = async (values: FormValues) =>
    forgotPassword({
      variables: {
        email: values.email,
      },
    });
  return (
    <ForgotPasswordForm handleForgot={handleForgot} isSubmitting={loading} />
  );
};

export default ForgotPassword;
