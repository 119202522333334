import React, { useEffect } from 'react';
import classnames from 'classnames';
import { gql, useApolloClient, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import {
  CategoryBase,
  PageId,
  ProductBase,
  ShapeDescription,
} from '../../../../../../types';
import {
  CATEGORY_TYPE_STONE_ID,
  PRODUCT_TYPE_PARCEL_ID,
} from '@wgt/shared/constants';
import SelectSearch from '../../../../components/SelectSearch';
import { ParcelType } from '../Update/ParcelType';
import { Controller, FormProvider, useFormContext } from 'react-hook-form';
import ProductPageIdShapes from './ProductPageIdShapes';
import ProductPageIdTemplateFields from './ProductPageIdTemplateFields';
import Spinner from '../../../../components/_organisms/Loader';
import useToggler from '@wgt/shared/hooks/useToggler';
import BagProvider from '../../../../services/bag';
import ProductPageIdReports from './ProductPageIdReports';
import { useTranslation } from 'react-i18next';
import { uid } from 'uid';
import { GET_GQL, SEARCH_CATEGORIES_GQL } from './graphql';

export default function ProductPageIdPut(): JSX.Element {
  const { t } = useTranslation('product');
  const [
    isShapeDescriptionsVisible,
    toggleShapeDescriptionVisible,
  ] = useToggler(false);
  const params = useParams<{
    productId: string;
    pageId?: string;
    categoryId: string;
  }>();
  const methods = useFormContext();
  const { control, reset, getValues } = methods;
  const client = useApolloClient();

  const product = client.readFragment<ProductBase>({
    id: `Product:${params.productId}`,
    fragment: gql`
      fragment product__AddStock on Product {
        id
        name
        description
        sku
        category {
          id
          name
          mixed
          productType {
            id
          }
        }
      }
    `,
  });

  const parcelTypes = client.readQuery<{ parcelTypes: { data: ParcelType[] } }>(
    {
      query: gql`
        query Q {
          parcelTypes(filter: $parcelTypeFilter) {
            data {
              ... on ParcelType {
                id
                title
                description
              }
            }
          }
        }
      `,
    },
  )?.parcelTypes;

  const { data, loading } = useQuery<{
    productPageId: PageId;
    category: CategoryBase;
  }>(GET_GQL, {
    variables: {
      pageId: params.pageId ?? 'nullable',
      isEdit: !!params.pageId,
      categoryId: params.categoryId,
    },
  });

  useEffect(() => {
    if (!data?.productPageId) {
      reset({
        ...getValues(),
        page: {
          name: uid(),
          category: data?.category,
          shapes: [],
          fields: {
            parcel_type_id: data?.category?.productType?.id,
          },
        },
      });
      return;
    }
    reset({
      ...getValues(),
      page: {
        ...data.productPageId,
        shapes:
          data.productPageId.shapes?.map((shape) => ({
            ...shape,
            descriptions: shape.shapeDescriptionGroups?.reduce<
              ShapeDescription[]
            >((acc, curr) => {
              acc.push(...curr.shapeDescriptions);
              return acc;
            }, []),
          })) ?? [],
      },
    });
  }, [data?.productPageId, data?.category?.productType]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <BagProvider
      value={{
        ...params,
        isShapeDescriptionsVisible,
        toggleShapeDescriptionVisible,
      }}
    >
      <div className="update-product-screen">
        {product?.category?.mixed && (
          <div className="d-flex">
            <div className="form-group w-100">
              <label>{t('Select Stone Type')}</label>
              <Controller
                name="page.category"
                defaultValue={getValues('page.category') ?? ''}
                control={control}
                render={({ onChange, value }) => (
                  <SelectSearch
                    getOptionLabel={(e) => e.name}
                    getOptionValue={(e) => e.id}
                    onChange={onChange}
                    defaultInputValue={value?.name ?? value}
                    parseVariables={({ filter }) => ({
                      filter: {
                        ...filter,
                        category_type_id: CATEGORY_TYPE_STONE_ID,
                      },
                    })}
                    gql={SEARCH_CATEGORIES_GQL}
                    name="name"
                  />
                )}
              />
            </div>
          </div>
        )}
        {Number(product?.category?.productType?.id) ===
          PRODUCT_TYPE_PARCEL_ID && (
          <div className="parcel-type-selector">
            <h3 className="mt-4">{t('Select parcel type')}</h3>
            <div className="parcel-type-holder">
              <Controller
                name="page.fields.parcel_type_id"
                control={control}
                defaultValue={getValues('page.fields.parcel_type_id') ?? ''}
                render={({ onChange, value }) => (
                  <>
                    {parcelTypes?.data.map((parcelType) => (
                      <div
                        className={classnames('parcel-type-item', {
                          active: value === parcelType.id,
                        })}
                        key={parcelType.id}
                        onClick={() => onChange(parcelType.id)}
                      >
                        <span className="parcel-type-title">
                          {parcelType.title}
                        </span>
                        <span className="parcel-type-description">
                          {parcelType.description}
                        </span>
                      </div>
                    ))}
                  </>
                )}
              />
            </div>
          </div>
        )}
        <FormProvider {...methods}>
          <ProductPageIdShapes />
          <ProductPageIdTemplateFields section="first" />
        </FormProvider>

        {params.pageId && params.productId && <ProductPageIdReports />}
        <FormProvider {...methods}>
          <ProductPageIdTemplateFields section="second" />
        </FormProvider>
      </div>
    </BagProvider>
  );
}
