import React from 'react';

import CustomHeader from '../../../../components/_molecules/CustomHeader';

import { LIST_USERS_GQL } from '../graphql';
import Table from '../../../../components/_organisms/Table';

const List: React.FC = () => {
  return (
    <div>
      <CustomHeader title={'List Users'} showCreate />
      <Table
        striped
        hover
        node="users"
        query={LIST_USERS_GQL}
        showDetail={false}
        columns={[
          { label: 'First Name', field: 'first_name' },
          { label: 'Last Name', field: 'last_name' },
          { label: 'E-mail', field: 'email' },
        ]}
      />
    </div>
  );
};

export default List;
