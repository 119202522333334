import React, { useMemo, useEffect } from 'react';
import CustomHeader from '../../../../components/_molecules/CustomHeader';
import { useParams, useHistory } from 'react-router-dom';
import useCrmRoutes from '../../../../hooks/useCrmRoutes';
import Form from './Form';
import { SubmitHandler } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import {
  CREATE_ROLE_GQL,
  GET_ROLE_GQL,
  UPDATE_ROLE_GQL,
  LIST_PERMISSIONS_GQL,
  LIST_ROLES_GQL,
} from '../graphql';
import Loader from '../../../../components/_organisms/Loader';
import { Role } from '../types';
import useToast from '../../../../hooks/useToast';

const Put: React.FC = () => {
  const history = useHistory();
  const params = useParams<ParamTypes>();
  const { setCustomTitle, actualRoute } = useCrmRoutes();
  const { toast } = useToast();
  const [createRole, { loading, data }] = useMutation(CREATE_ROLE_GQL, {
    refetchQueries: [{ query: LIST_ROLES_GQL }],
  });
  const [updateRole, { loading: isUpdating, data: updateData }] = useMutation(
    UPDATE_ROLE_GQL,
    {
      refetchQueries: [{ query: LIST_ROLES_GQL }],
      variables: {
        id: parseInt(params?.id, 10),
      },
    },
  );
  const { loading: fetchinRole, data: editData } = useQuery(GET_ROLE_GQL, {
    skip: !params?.id,
    variables: {
      id: params.id,
    },
  });

  const { loading: fetchingPermissions, data: permissionsData } = useQuery(
    LIST_PERMISSIONS_GQL,
  );

  const isEdit = useMemo(() => {
    return params?.id;
  }, [params]);

  const isLoading = useMemo(() => {
    return loading || fetchinRole || isUpdating || fetchingPermissions;
  }, [loading, fetchinRole, isUpdating, fetchingPermissions]);

  useEffect(() => {
    setCustomTitle(`${isEdit ? 'Edit' : 'Create'} Role`);
  }, []);

  useEffect(() => {
    if (data?.createRole?.id || updateData?.updateRole?.id) {
      toast('success');
      history.push(`/crm/${actualRoute}`);
    }
  }, [data, updateData]);

  const onSubmit: SubmitHandler<Role> = async (variables) => {
    if (isEdit) {
      updateRole({
        variables,
      });
    } else {
      createRole({
        variables,
      });
    }
  };
  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <CustomHeader title={`${isEdit ? 'Edit' : 'Create'} Role`} showList />
      <Form
        onSubmit={onSubmit}
        isSubmitting={loading}
        data={editData?.role}
        permissions={permissionsData?.permissions?.data || []}
      />
    </div>
  );
};

export default Put;
