import React, { useMemo, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import { gql, useApolloClient } from '@apollo/client';
import { Permission } from '@wgt/types';
import PermissionComponent from './PermissionComponent';
import { SwitchBox } from '../../../../../../components';

interface Props {
  scope: string;
  permissions: Permission[];
  userPermissions?: Permission[];
  userId: number;
  refetchUserPermission: () => void;
}

export default function PermissionScope({
  scope,
  permissions,
  userPermissions,
  userId,
  refetchUserPermission,
}: Props): JSX.Element {
  const client = useApolloClient();
  const [loadingMutation, setLoadingMutation] = useState<boolean>(false);

  const selectAllScope = async () => {
    if (!permissions.length) {
      return;
    }
    setLoadingMutation(true);

    const idPermissions = permissions.map(
      (permission: Permission) => permission.id,
    );
    const PERMISSION_SCOPE = gql`
      mutation permissionToUserMutation {
        ${idPermissions.map(
          (idPermission: number) =>
            `permissionToUserMutation${idPermission.toString()}: ${
              allChecked ? 'revokePermissionToUser' : 'givePermissionToUser'
            } (id: ${userId}, permission_id: ${idPermission}) { message }`,
        )}
      }
    `;

    await client.mutate({
      mutation: PERMISSION_SCOPE,
    });

    refetchUserPermission();
    setLoadingMutation(false);
  };

  const allChecked = useMemo(
    () =>
      userPermissions?.length ===
      permissions.filter((item) => item.scope === scope).length,
    [userPermissions, permissions],
  );

  return (
    <Card>
      <CardBody>
        <SwitchBox
          type="switch"
          id={`scope-${userId}-${scope}`}
          label={scope.split('-').join(' ')}
          onChange={selectAllScope}
          checked={allChecked}
          className="text-capitalize"
          isLoading={loadingMutation}
        />

        <div className="ml-4">
          {permissions.map((permission: Permission) => (
            <div
              className="d-block mt-2"
              key={`switch-${userId}-${permission.id}`}
            >
              <PermissionComponent
                permission={permission}
                userId={userId}
                hasPermission={userPermissions?.some(
                  (item: Permission) =>
                    Number(item.id) === Number(permission.id),
                )}
                refetchUserPermission={refetchUserPermission}
              />
            </div>
          ))}
        </div>
      </CardBody>
    </Card>
  );
}
