/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Dialog } from 'evergreen-ui';
import './style.scss';

interface Props {
  isShown: boolean;
  onConfirm: any;
  onClose: any;
  title: string;
  isLoading?: boolean | undefined;
}

const ConfirmDialog: React.FC<Props> = ({
  isShown,
  onConfirm,
  onClose,
  title,
  isLoading,
}) => {
  return (
    <Dialog
      isShown={isShown}
      hasHeader={false}
      onCloseComplete={onClose}
      isConfirmLoading={isLoading}
      onConfirm={onConfirm}
      confirmLabel={isLoading ? 'Loading...' : 'Confirm'}
      containerProps={{ className: 'wgt-dialog' }}
    >
      {title}
    </Dialog>
  );
};

export default ConfirmDialog;
