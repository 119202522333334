import React from 'react';
import { useTranslation } from 'react-i18next';
import { Title } from '../../../../../components';

export default function Security(): JSX.Element {
  const { t } = useTranslation('hr');
  return (
    <div className="border shadow p-4 security">
      <Title heading="h3" className="mb-3">
        {t('Security')}
      </Title>
      <ul>
        <li>{t('Credit Check')}</li>
        <li>{t('Criminal Check')}</li>
        <li>{t('Drug Test')}</li>
        <li>{t('Personality Review')}</li>
        <li>{t('Incident Report')}</li>
      </ul>
    </div>
  );
}
