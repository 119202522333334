export const DEFAULT_PAGE_TITLE = 'World Gem Trade Inc.';
export const BACKGROUND_OPTIONS = ['#5f5e5e', '#fff', '#0074a0'];

export const productStatusToSearch = [1, 2, 4, 5];

export const LOCAL_STORAGE = {
  token: '@wgt:token',
  user: '@wgt:user',
  currentToken: 'currentToken',
  activeContext: 'wgt-active-context',
};
