import { gql } from '@apollo/client';

export const GET_WORK_ORDER_ITEMS_WITH_SCHEDULE = gql`
  query workOrderItemForm(
    $id: ID!
    $filter: WorkOrderItemFilterInput
    $isWorkOrder: Boolean!
  ) {
    schedule(id: $id) @skip(if: $isWorkOrder) {
      id
      urgency
    }
    workOrder(id: $id) @include(if: $isWorkOrder) {
      id
      urgency
    }
    workOrderItems(filter: $filter, first: 1000) {
      data {
        id
        urgency
        start
        end
        date
        arrival_date
        return_date
        hour_cost
        hour_price
        details
        private_details
        status
        title
        pageid {
          id
        }
        worker {
          id
          name: first_name
        }
        executiveBuyer {
          id
          name: first_name
        }
        currency {
          id
          name
          code
        }
        workOrderType {
          id
          name
        }
      }
    }
  }
`;

export const UPDATE_WORK_ORDER_ITEM = gql`
  mutation updateWorkOrderItem(
    $id: ID!
    $workOrderItem: UpdateWorkOrderItemInput!
  ) {
    updateWorkOrderItem(id: $id, workOrderItem: $workOrderItem) {
      id
    }
  }
`;
