/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import Dropzone from '../../Dropzone';
import useReceiptForm from '../useReceiptForm';

export default function ReceiptForm(): JSX.Element {
  const { upload, uploading } = useReceiptForm();
  const { reset, watch } = useFormContext();

  const receiptId = watch('id');

  const onDrop = useCallback(
    async (files) => {
      if (!files.length) {
        return;
      }

      await Promise.allSettled(
        files.map((file: any) =>
          upload({
            variables: {
              file,
              receipt_id: receiptId,
              title: file.filename ?? 'untitled',
            },
          }),
        ),
      );

      reset({ id: receiptId });
    },
    [receiptId],
  );

  return (
    <Row className="mt-4">
      <Col lg="12" className="bg-dark p-2">
        <Dropzone
          multiple
          accept="image/*, application/pdf"
          onDrop={onDrop}
          disabled={uploading || !receiptId}
        />
      </Col>
    </Row>
  );
}
