import { useMemo, useCallback } from 'react';
import { gql, useQuery } from '@apollo/client';
import useMe from './useMe';
import { Employment } from '@wgt/types';
import { LOCAL_STORAGE } from '../utils/constants';

interface LoggedContext {
  active: Employment;
  list: Employment[];
}

interface LoggedContextHook extends LoggedContext {
  select(e: Employment): void;
}

export default function useLoggedContext(): LoggedContextHook {
  const [me] = useMe();
  const { data, client } = useQuery(
    gql`
      query loggedContext {
        me {
          id
          employments {
            ...loggedContext_Part
          }
        }

        activeContext {
          id
          name
        }
      }
      ${useLoggedContext.fragment}
    `,
    {
      fetchPolicy: 'cache-only',
      skip: !me?.id,
    },
  );

  const { active, list } = useMemo<LoggedContext>(() => {
    if (!data) {
      return { active: null, list: [] };
    }

    return {
      active: data.activeContext,
      list: data.me?.employments ?? [],
    };
  }, [data]);

  const select = useCallback(
    (selected: Employment) => {
      const stored = list.find((item) => selected.id === item.id);

      client.writeQuery({
        query: gql`
          {
            activeContext @client
          }
        `,
        data: {
          activeContext: stored,
        },
      });
      localStorage.setItem(LOCAL_STORAGE.activeContext, JSON.stringify(stored));
    },
    [data],
  );

  return { active, list, select };
}

export const fragment = gql`
  fragment loggedContext_Part on Firm {
    id
    name
    status
  }
`;

useLoggedContext.fragment = fragment;
