import { useQuery } from '@apollo/client';
import { ProductBase, WorkOrderItem } from '@wgt/types';
import React, { createContext, useCallback, useContext, useState } from 'react';
import useWorkOrderSchedule from '../useWorkOrderSchedule';
import { LIST_WORK_ORDER_ITEMS_BY_PAGE } from '../graphql';
const WorkOrderProductsContext = createContext({});

interface WorkOrderProductsProviderProps {
  children: JSX.Element;
  product: ProductBase;
}

interface WorkOrderItemQueryFilters {
  workOrderId?: string;
  scheduleId?: string;
  pageId?: number;
}

type FilterModifier = (value?: string | number) => void;

type FilterDispatch = (name: 'pageId' | 'workOrderId') => FilterModifier;

export function WorkOrderProductProvider({
  children,
  product,
}: WorkOrderProductsProviderProps): JSX.Element {
  const [{ scheduleId, workOrderId }] = useWorkOrderSchedule();
  const [isDetailShown, setIsDetailShown] = useState(false);

  const toggleDetail = useCallback(() => setIsDetailShown((x) => !x), []);

  const [filters, dispatch] = useState<WorkOrderItemQueryFilters>({
    scheduleId,
    workOrderId,
    pageId: product.pages?.[0]?.id,
  });

  const setFilters: FilterDispatch = useCallback(
    (name) => (value) => dispatch((current) => ({ ...current, [name]: value })),
    [],
  );

  const { data, loading } = useQuery<{
    workOrderItems: { data: WorkOrderItem[] };
  }>(LIST_WORK_ORDER_ITEMS_BY_PAGE, {
    skip: !isDetailShown || (!scheduleId && !workOrderId),
    variables: {
      filter: {
        pageid: { id: filters.pageId },
        ...(!!scheduleId
          ? { schedule: { id: [scheduleId] } }
          : { workOrder: { id: [workOrderId] } }),
      },
    },
  });

  return (
    <WorkOrderProductsContext.Provider
      value={[
        {
          product,
          filters,
          isDetailShown,
          workOrderItems: data?.workOrderItems.data ?? [],
          loading,
        },
        { toggleDetail, setFilters },
      ]}
    >
      {children}
    </WorkOrderProductsContext.Provider>
  );
}

interface WorkOrderProductState {
  product: ProductBase;
  filters: WorkOrderItemQueryFilters;
  workOrderItems: WorkOrderItem[];
  isDetailShown: boolean;
  loading: boolean;
}

interface WorkOrderProductHandlers {
  toggleDetail: () => void;
  setFilters: FilterDispatch;
}

type UseWorkOrderProduct = [WorkOrderProductState, WorkOrderProductHandlers];

export default function useWorkOrderProduct(): UseWorkOrderProduct {
  return useContext(WorkOrderProductsContext) as UseWorkOrderProduct;
}
