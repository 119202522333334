import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { useProductUpdate } from '../../../Update/Provider';
import { PRODUCT } from '../../graphql';
import { Office } from '@wgt/types';
import { Line, SimpleDropdown, Title } from '../../../../../../components';
import { Pane } from 'evergreen-ui';
import { Col, Row } from 'reactstrap';

const OfficeComponent: React.FC = () => {
  const { filters, isFormEnabled } = useProductUpdate();
  const { data } = useQuery<{ offices: { data: Office[] } }>(PRODUCT, {
    variables: filters,
    fetchPolicy: 'cache-only',
  });

  const offices = useMemo(() => data?.offices?.data ?? [], [data?.offices]);

  return (
    <Pane>
      <Title heading="h2">Office</Title>
      <Line />
      <Row>
        <Col>
          <SimpleDropdown
            field={'office_id'}
            label="Office location"
            options={offices}
            controller="id"
            text="name"
            disabled={!isFormEnabled}
          />
        </Col>
        <Col>
          <SimpleDropdown
            field={'sales_representative_id'}
            label="Sales Representative"
            options={offices}
            controller="id"
            text="name"
            disabled={!isFormEnabled}
          />
        </Col>
        <Col>
          <SimpleDropdown
            field={'executive_buyer_id'}
            label="Executive Buyer"
            options={offices}
            controller="id"
            text="name"
            disabled={!isFormEnabled}
          />
        </Col>
      </Row>
    </Pane>
  );
};

export default OfficeComponent;
