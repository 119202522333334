import React from 'react';
import { FaAngleLeft } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './styles.scss';
interface ReturnButtonProps {
  title?: string;
  route: string;
}
const ReturnButton: React.FC<ReturnButtonProps> = ({ title, route }) => {
  return (
    <div className="return-button">
      <Link to={route}>
        <FaAngleLeft />
        {title ?? 'back'}
      </Link>
    </div>
  );
};

export default ReturnButton;
