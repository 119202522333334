import React from 'react';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Text } from '../../../../../../../components';
import { ProductType } from './ProductDetails';
import { useFormContext } from 'react-hook-form';

export default function ProductIds(): JSX.Element {
  const { getValues } = useFormContext<ProductType>();
  const product = getValues();

  const { t } = useTranslation(['product', 'common']);
  return (
    <Row className="mt-3 product-ids">
      <Col>
        <Text>{t('Lot Id')}</Text>
        <Text type="muted">{product.lot_id ?? t('common:Not Available')}</Text>
      </Col>
      <Col>
        <Text>{t('Tray ID')}</Text>
        <Text type="muted">{product.tray_id ?? t('common:Not Available')}</Text>
      </Col>
      {product.boxes?.map((boxId, key) => (
        <Col key={`pos-product-${key}`}>
          <Text>
            {t('Box ID')} {key + 1}
          </Text>
          <Text type="muted">{boxId.name ?? t('common:Not Available')}</Text>
        </Col>
      ))}
      {product.crates?.map((crateId, key) => (
        <Col key={`pos-product-${key}`}>
          <Text>
            {t('Create ID')} {key + 1}
          </Text>
          <Text type="muted">{crateId.name ?? t('common:Not Available')}</Text>
        </Col>
      ))}
    </Row>
  );
}
