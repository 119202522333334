import React, { useState } from 'react';
import { Row, Col, FormGroup, Label, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { SimpleInput, Button } from '../';
import CanadaFlag from '../../assets/canada_flag.svg';
import PaymentType from './PaymentType';
import CalculateInterest from './CalculateInterest';
import PlanNextPayment from './PlanNextPayment';
import PaymentFieldsValues from './PaymentFieldsValues';
import Amounts from './Amounts';
import Text from '../Text';
import { FiFileText } from 'react-icons/fi';
import usePayments from '../../pages/Crm/PointOfSales/Details/Resume/Payment/usePayments';
import { useFormContext } from 'react-hook-form';

interface PaymentFormProps {
  setReceiptModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function PaymentForm({
  setReceiptModalOpen,
}: PaymentFormProps): JSX.Element {
  const { t } = useTranslation(['pointOfSales', 'common']);
  const [interestOpen, setInterestOpen] = useState<boolean>(false);
  const [planPaymenttOpen, setPlanPaymenttOpen] = useState<boolean>(false);
  const { getValues, trigger, watch } = useFormContext();
  const invoice = watch('invoice_id');
  const {
    update: [update, { loading }],
  } = usePayments({ invoiceNumber: invoice });
  const toggleInterestOpen = () => setInterestOpen(!interestOpen);
  const togglePlanPaymenttOpen = () => setPlanPaymenttOpen(!planPaymenttOpen);

  const onPaidClick = async () => {
    const isValid = await trigger();

    if (!isValid) {
      return;
    }
    const { amount, invoice_id, id } = getValues();

    const payment = {
      invoice_id,
      amount: Number(amount),
      status: 'paid',
    };

    update({
      variables: {
        id,
        payment,
      },
    });
  };

  return (
    <>
      <div>
        <Row>
          <Col xs="8">
            <SimpleInput
              type="number"
              field="amount"
              label={t('Amount')}
              placeholder={t('Set this Invoice as PAID')}
              required
            />
          </Col>
          <Col xs="4">
            <div className="simple-input currency-input">
              <label>
                <div className="d-inline-block">{t('common:Currency')}</div>
                <img
                  src={CanadaFlag}
                  alt="currency"
                  className="d-inline-block flag-currency ml-1 rounded"
                />
              </label>
              {!!getValues('id') && (
                <Button
                  small
                  className="text-uppercase"
                  disabled={loading}
                  isLoading={loading}
                  onClick={onPaidClick}
                >
                  {t('Paid')}
                </Button>
              )}
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs="8">
            <FormGroup className="simple-input mb-0 w-75 mt-4">
              <PaymentType />
            </FormGroup>
          </Col>
          {!!setReceiptModalOpen && (
            <Col xs="4" className="upload-receipts">
              <Button
                type="text"
                className="h-100"
                onClick={() => setReceiptModalOpen(true)}
              >
                <Text className="text-danger">{t('Upload receipts')}</Text>
                <div className="button-box">
                  <FiFileText size="45" />
                  <div className="badge-upload">0</div>
                </div>
              </Button>
            </Col>
          )}
        </Row>
        <Row>
          <Col>
            <PaymentFieldsValues />
          </Col>
        </Row>

        {!!setReceiptModalOpen && (
          <Row className="plan-pay-calculate-checkbox mt-3 mb-2">
            <Col
              xs="6"
              className="text-right d-flex align-self-center justify-content-end"
            >
              <Label className="mr-2" htmlFor="plan-next-payment-id">
                {t('Plan next PAYMENT')}
              </Label>
              <div>
                <Input
                  type="checkbox"
                  onChange={() => togglePlanPaymenttOpen()}
                  className="checkbox-options-open"
                  id="plan-next-payment-id"
                />
              </div>
            </Col>
            <Col
              xs="6"
              className="text-right d-flex align-self-center justify-content-end"
            >
              <Label className="mr-2" htmlFor="calculate-interest-id">
                {t('Calculate Interest')}
              </Label>
              <div className="mr-3">
                <Input
                  type="checkbox"
                  onChange={() => toggleInterestOpen()}
                  className="checkbox-options-open"
                  id="calculate-interest-id"
                />
              </div>
            </Col>
          </Row>
        )}
        {interestOpen && <CalculateInterest />}
        {planPaymenttOpen && <PlanNextPayment />}
      </div>

      {!!setReceiptModalOpen && <Amounts />}
    </>
  );
}
