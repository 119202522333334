import { IconType } from 'react-icons';

export enum StatusActive {
  active,
  disabled,
}
export enum PaymentStatus {
  open,
  paid,
}

export enum HorizontalAlign {
  left = 'left',
  right = 'right',
  center = 'center',
}

export interface NavbarItemSubtitle {
  value?: number;
  label?: string;
}

export interface NavbarPageBase {
  name: string;
  title: string;
  url?: string;
  onClick?: (props?: unknown) => void;
  children?: NavbarPageBase[];
  backgroundColor?: string;
  align?: HorizontalAlign;
  icon?: IconType;
  subtitle?: NavbarItemSubtitle;
  comingSoon?: boolean;
  isOnClick?: boolean;
}
