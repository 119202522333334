import { PaymentStatus } from '@wgt/types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../components';
import useStatementsContext, { InvoiceResult } from './useStatements';

export default function StatementResultRow({
  invoice,
}: {
  invoice: InvoiceResult;
}): JSX.Element {
  const { t } = useTranslation(['statements', 'common']);
  const { setActiveInvoice, setActiveInvoicePayments } = useStatementsContext();
  const payments = useMemo(
    () => ({
      total: invoice.payments.reduce(
        (total, payment) => total + payment.amount ?? 0,
        0,
      ),
      open_payments: invoice?.payments?.filter(
        (payment) =>
          PaymentStatus[payment.status.toString()] === PaymentStatus.open,
      ),
      paid_payments: invoice?.payments?.filter(
        (payment) =>
          PaymentStatus[payment.status.toString()] === PaymentStatus.paid,
      ),
    }),
    [invoice],
  );
  return (
    <tr>
      <td>{invoice.pointOfSale.office?.firm?.name}</td>
      <td>
        {invoice.id}{' '}
        <Button
          small
          className="ml-5"
          onClick={() => setActiveInvoice(invoice)}
        >
          {t('common:view')}
        </Button>
      </td>
      <td>{invoice.date}</td>
      <td>{invoice.transaction}</td>
      <td>
        {payments.total}
        <Button
          small
          className="ml-5"
          badgeRight={
            payments.paid_payments.length
              ? { color: 'red', text: payments.paid_payments.length.toString() }
              : undefined
          }
          badgeLeft={
            payments.open_payments.length
              ? {
                  color: 'orange',
                  text: payments.open_payments.length.toString(),
                }
              : undefined
          }
          onClick={() => setActiveInvoicePayments(invoice)}
        >
          {!payments.open_payments?.length && payments.paid_payments?.length > 0
            ? t('Paid full')
            : t('payment')}
        </Button>
      </td>
      <td>
        {payments.paid_payments.reduce(
          (total, payment) => total + payment.amount ?? 0,
          0,
        )}
      </td>
    </tr>
  );
}
