import React from 'react';
import FieldDependency from './FieldDependency';
import FieldValueDependency from './FieldValueDependency';
import { CollapseProvider } from '../../../../../../../../services/collapse';

const Settings: React.FC = () => {
  return (
    <div>
      <hr />

      <CollapseProvider
        options={['field_dependency', 'field_value_dependency']}
      >
        <FieldDependency />
        <FieldValueDependency />
      </CollapseProvider>
    </div>
  );
};

export default Settings;
