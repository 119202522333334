import React, { useEffect, useState } from 'react';
import { Pane } from 'evergreen-ui';
import classnames from 'classnames';
import { useQuery } from '@apollo/client';
import { MediaCenterItemBase } from '@wgt/types';
import ButtonControllers from '../../ButtonControllers';
import Content from './Content';
import LayoutPages from './LayoutPages';
import { useBagContext } from '../../../../../../../services/bag';
import { MediaCenterController } from '../../../../../../../hooks/useMediaCenterController';
import { useProductUpdate } from '../../../Provider';
import Thumbnails from './Thumbnails';
import { LIST_FILES } from '../../graphql';

export default function MediaCenterSlider(): JSX.Element {
  const { isExpanded } = useBagContext<MediaCenterController>();
  const { pages } = useProductUpdate();
  const [activePage, setActivePage] = useState<string | null>(null);

  const { media, productId } = useBagContext<MediaCenterController>();
  const { data } = useQuery(LIST_FILES, {
    skip: !productId,
    variables: {
      filter: {
        product_id: productId,
        type: 'image',
      },
    },
  });

  useEffect(() => {
    const filesToRemove = pages.fields.reduce(
      (filesList, currentPage) => [
        ...filesList,
        ...(currentPage.files
          ?.filter(
            (file: MediaCenterItemBase) => file.type?.toLowerCase() === 'image',
          )
          .map((file: MediaCenterItemBase) => file.id) ?? []),
      ],
      [],
    );

    media.setItems(
      data?.files?.data.filter(
        (image: MediaCenterItemBase) => !filesToRemove.includes(image.id),
      ),
    );
  }, [data?.files, pages.fields]);

  return (
    <>
      <Pane
        className={classnames('media-center-content', {
          open: isExpanded,
        })}
      >
        <Pane className="media-center-content-holder">
          <div className="content-side" />
          {isExpanded && (
            <>
              <div className="media-center-content-image">
                <Content />
              </div>

              <div className="content-side">
                <LayoutPages active={activePage} setActive={setActivePage} />
              </div>
            </>
          )}
        </Pane>
      </Pane>
      <ButtonControllers />
      <Thumbnails
        items={media.items}
        active={activePage}
        loading={false}
        imageProp="image_url"
      />
    </>
  );
}
