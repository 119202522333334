// this is the initial structure to build a hook to handle the user selected currency
import { useMemo } from 'react';

interface UseCurrency {
  convertAmount(amount?: string | number): string;
}
export default function useCurrency(): UseCurrency {
  return useMemo(
    () => ({
      convertAmount: (amount: string | number) => {
        if (!amount || Number.isNaN(Number(amount))) {
          return parseFloat('000').toFixed(2);
        }
        return parseFloat(amount.toString()).toFixed(2);
      },
    }),
    [],
  );
}
