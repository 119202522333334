/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  useMutation,
  MutationFunctionOptions,
  FetchResult,
} from '@apollo/client';
import { useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  CREATE_TEMPLATE_FIELD_GQL,
  UPDATE_TEMPLATE_FIELD_GQL,
  GET_TEMPLATE_DETAILS_GQL,
} from '../../../../graphql';
import { TemplateFieldsContext } from '../../../../../../../hooks/useTemplateFieldsContext';
import useToast from '../../../../../../../hooks/useToast';

interface UseFieldActionsHook {
  create: (
    options?: MutationFunctionOptions<any, Record<string, any>> | undefined,
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;

  update: (
    options?: MutationFunctionOptions<any, Record<string, any>> | undefined,
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;

  submitting: boolean;
}

export default function useFieldAction(): UseFieldActionsHook {
  const { select } = useContext(TemplateFieldsContext);
  const params = useParams<ParamTypes>();
  const { toast } = useToast();

  const mutationOptions = {
    refetchQueries: [
      {
        query: GET_TEMPLATE_DETAILS_GQL,
        variables: { id: params.id },
      },
    ],
    onCompleted: () => [toast('success', 'Field saved'), select(null)],
  };

  const [create, { loading: creating }] = useMutation(
    CREATE_TEMPLATE_FIELD_GQL,
    {
      ...mutationOptions,
      onCompleted: () => [toast('success', 'Field saved'), select(null)],
    },
  );
  const [update, { loading: updating }] = useMutation(
    UPDATE_TEMPLATE_FIELD_GQL,
    {
      ...mutationOptions,
    },
  );

  const submitting = useMemo(() => updating || creating, [updating, creating]);

  return {
    create,
    update,
    submitting,
  };
}
