import React from 'react';
import { TabRolesProps } from '../../types';
import {
  SimpleTable,
  SimpleDropdown,
  Title,
  Button,
} from '../../../../../components';
import { Row, Col, Form, FormGroup } from 'reactstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useQuery } from '@apollo/client';
import { Role } from '@wgt/types';
import { ROLES_TAB_SCREEN } from '../../graphql';

interface RoleQuery {
  data: Role[];
}

interface TabQuery {
  options: RoleQuery;
  userRoles: RoleQuery;
}

const TabRoles: React.FC<TabRolesProps> = ({ user, onSubmit, onRemove }) => {
  const { data } = useQuery<TabQuery>(ROLES_TAB_SCREEN, {
    variables: {
      userId: user.id,
    },
  });
  const methods = useForm();

  return (
    <Row className="mt-5">
      <Col>
        <Title size={300}>{`Add new role to ${user.first_name}`}</Title>
        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(onSubmit)} className="mt-3">
            <FormGroup>
              <SimpleDropdown
                required
                field="role_id"
                options={data?.options.data ?? []}
                label="New role"
                controller="id"
                text="name"
              />
            </FormGroup>
            <Row>
              <Col>
                <Button htmlType="submit">Save</Button>
              </Col>
            </Row>
          </Form>
        </FormProvider>
      </Col>
      <Col>
        <SimpleTable
          module="roles"
          onDelete={onRemove}
          header="User roles"
          data={
            data?.userRoles.data.map((role) => ({
              id: role.id,
              label: role.name,
            })) ?? []
          }
        />
      </Col>
    </Row>
  );
};

export default TabRoles;
