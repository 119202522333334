import React from 'react';
import { SearchLayoutResultColumn } from '@wgt/types';
import { Pane } from 'evergreen-ui';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import { SimpleInput } from '../../../../../../components';
import { useProductUpdate } from '../../../Update/Provider';
import MediaImage from '../../../../../../assets/media-dummy.png';

const Headers: React.FC<{ headers: SearchLayoutResultColumn[] }> = ({
  headers,
}) => {
  const { t } = useTranslation('product');
  const { pages } = useProductUpdate();
  return (
    <>
      <Row className="bg-white p-3">
        <Col className="ml-5">{t('Media')}</Col>
        {headers.map((header, key) => (
          <Col key={`header-${key}`}>{t(header.title ?? '')}</Col>
        ))}
      </Row>

      <Row className="header-form p-3">
        <Col className="ml-5">
          <Pane
            className="img-holder"
            style={{
              backgroundImage: `url(${MediaImage})`,
            }}
          />
        </Col>
        {headers.map((header, key) => (
          <Col key={`header-${key}`}>
            <>
              {header.type === 'templateField' &&
                typeof pages.activeIndex !== 'undefined' && (
                  <SimpleInput
                    prefix={`pages[${pages.activeIndex}].fields.`}
                    field={header.key}
                    placeholder={header.title}
                    disabled
                  />
                )}
              {header.type === 'productField' && (
                <SimpleInput
                  field={header.key}
                  placeholder={header.title}
                  defaultValue=""
                  required={header.key === 'sku'}
                  disabled={header.key !== 'sku'}
                />
              )}
            </>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default Headers;
