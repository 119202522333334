import { gql } from '@apollo/client';

export const UPDATE_SCHEDULE = gql`
  mutation updateSchedule($id: ID!, $schedule: UpdateScheduleInput!) {
    updateSchedule(id: $id, schedule: $schedule) {
      id
    }
  }
`;

export const CREATE_SCHEDULE = gql`
  mutation createSchedule(
    $title: String
    $urgency: WorkOrderUrgency!
    $start: DateTime
    $end: DateTime
    $notify: String
    $notify_type: ScheduleNotifyType
    $notes: String
    $product_ids: [ID!]!
  ) {
    createSchedule(
      title: $title
      urgency: $urgency
      start: $start
      end: $end
      notify: $notify
      notify_type: $notify_type
      notes: $notes
      product_ids: $product_ids
    ) {
      id
      urgency
    }
  }
`;

const productFragment = gql`
  fragment product_Part on Product {
    id
    name
    sku
    total_price
    pages {
      id
      name
      shapes {
        id
        title
      }
    }
  }
`;

export const GET_SCHEDULE = gql`
  query schedule($id: ID!, $skipSchedule: Boolean!, $skipWorkOrder: Boolean!) {
    schedule(id: $id) @include(if: $skipWorkOrder) {
      id
      start
      end
      notify_type
      notify
      urgency
      title
      products {
        ...product_Part
      }
    }
    workOrder(id: $id) @include(if: $skipSchedule) {
      id
      urgency
      title
      posProducts {
        product {
          ...product_Part
        }
      }
    }
  }
  ${productFragment}
`;

export const LIST_WORK_ORDER_ITEMS_BY_PAGE = gql`
  query workOrderItems($filter: WorkOrderItemFilterInput) {
    workOrderItems(filter: $filter, first: 1000) {
      data {
        id
        title
        start
        end
        workOrderType {
          id
          name
          label: name
          value: id
        }
      }
    }
  }
`;

export const CREATE_WORK_ORDER_ITEM = gql`
  mutation createWorkOrderItem(
    $workorder_id: ID!
    $workordertype_id: ID!
    $pageid_id: ID!
    $status: StatusActive!
    $urgency: WorkOrderUrgency
    $start: DateTime
    $end: DateTime
    $date: Date
  ) {
    createWorkOrderItem(
      workorder_id: $workorder_id
      workordertype_id: $workordertype_id
      pageid_id: $pageid_id
      status: $status
      urgency: $urgency
      start: $start
      end: $end
      date: $date
    ) {
      id
      urgency
    }
  }
`;
