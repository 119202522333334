import { gql } from '@apollo/client/core';
import { paginatorInfoFragment } from '../../../services/graphql/paginator.gql';

export const LIST_CATEGORY_GROUPS_GQL = gql`
  query categoryGroupsQuery($page: Int, $first: Int) {
    categoryGroups(page: $page, first: $first) {
      data {
        id
        name
        categories {
          id
          name
          description
          mixed
          template {
            id
            name
          }
          categoryType {
            id
            name
          }
          categoryGroup {
            id
            name
          }
          productType {
            id
            name
          }
        }
      }
      paginatorInfo {
        ...paginatorInfo_Part
      }
    }
  }
  ${paginatorInfoFragment}
`;
