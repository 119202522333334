/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useCrmRoutes from '../../../../../hooks/useCrmRoutes';

import Loader from '../../../../../components/_organisms/Loader';
import SidebarComponent from './Sidebar';

import ContentArea from './ContentArea';
import { TemplateFieldsProvider } from '../../../../../hooks/useTemplateFieldsContext';

import { useQuery } from '@apollo/client';
import { GET_TEMPLATE_DETAILS_GQL } from '../../graphql';
import { TemplateWithFields } from '@wgt/types';

const Edit: React.FC = () => {
  const params = useParams<ParamTypes>();
  const { setCustomRoutes, routes } = useCrmRoutes();

  const { data, loading } = useQuery(GET_TEMPLATE_DETAILS_GQL, {
    skip: !params.id,
    variables: {
      id: params.id,
    },
  });

  const fetchedTemplate = useMemo(() => {
    if (!data?.template?.id) {
      return null;
    }
    const { template } = data;

    const fields = template.fields.map((fieldToConvert: any) => {
      const field = {
        ...fieldToConvert.component,
        id: fieldToConvert.id,
        propertyName: fieldToConvert.property?.name,
        dataTypeName: fieldToConvert.dataType?.name,
        type: fieldToConvert.type,
        formValues: {
          component_id: fieldToConvert.component.id,
          search_component_id: fieldToConvert.searchComponent?.id,
          data_type_id: fieldToConvert.dataType.id,
          is_required: fieldToConvert.is_required,
          is_highlight: fieldToConvert.is_highlight,
          is_single_shape: fieldToConvert.is_single_shape,
          is_searchable: fieldToConvert.is_searchable,
          template_id: template.id,
          label: fieldToConvert.label,
          property_id: fieldToConvert.property.id,
          config: fieldToConvert.config,
        },
      };
      return field;
    });

    return {
      fields,
      id: template.id,
      name: template.name,
      description: template.description,
    } as TemplateWithFields;
  }, [data?.template]);

  useEffect(() => {
    setCustomRoutes([
      { name: 'Templates', path: '/crm/templates' },
      { name: 'Edit Template', path: `/crm/templates/${params.id}/edit` },
    ]);
  }, [routes]);

  if (loading && !fetchedTemplate) {
    return <Loader />;
  }

  return (
    <TemplateFieldsProvider defaultTemplate={fetchedTemplate}>
      <div className="template-put-container">
        <SidebarComponent />
        <ContentArea />
      </div>
    </TemplateFieldsProvider>
  );
};

export default Edit;
