import { Shape, ShapeGroup } from '@wgt/types';
import { useMemo, useState } from 'react';
import useDebounce from './useDebounce';

interface UseShapeGroupsProps {
  shapes?: Shape[];
}

interface UseShapeGroups {
  shapeGroups: ShapeGroup[];
  filterBy(value: string): void;
}

export default function useShapeGroups({
  shapes,
}: UseShapeGroupsProps): UseShapeGroups {
  const [filter, setFilter] = useState('');
  const debounce = useDebounce();

  const shapeGroups = useMemo<ShapeGroup[]>(() => {
    if (!shapes) {
      return [];
    }

    const groups = Object.values(
      shapes.reduce((acc, shape) => {
        const draft = { ...acc };
        if (!shape.shapeGroup?.id) {
          return draft;
        }

        if (!draft[shape.shapeGroup.id]) {
          draft[shape.shapeGroup.id] = { ...shape.shapeGroup, shapes: [] };
        }
        if (!!filter.length) {
          if (shape.title.match(new RegExp(filter, 'ig'))) {
            draft[shape.shapeGroup.id].shapes.push(shape);
          }
        } else {
          draft[shape.shapeGroup.id].shapes.push(shape);
        }

        return draft;
      }, {}),
    ) as ShapeGroup[];
    return groups.filter((x) => x.shapes.length > 0);
  }, [shapes, filter]);

  const filterBy = (value: string) => debounce(() => setFilter(value));

  return { shapeGroups, filterBy };
}
