/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { LayoutComponentBase, PropertyValueVariation } from '@wgt/types';
import { Controller, useFormContext } from 'react-hook-form';

interface VariationItemProps {
  variationValue: PropertyValueVariation;
  field?: string;
  dataType?: LayoutComponentBase;
  propertyField?: string;
}

const VariationItem: React.FC<VariationItemProps> = ({
  variationValue,
  field,
  dataType,
  propertyField,
}) => {
  const { getValues, setValue } = useFormContext();
  const registeredField = `${field}.${variationValue.id}-${variationValue.key}`;

  const handleChange = (onChange: (...event: any[]) => void) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (dataType?.key === 'single-select') {
      const values = getValues(propertyField ?? '');

      Object.keys(values ?? {}).forEach((propertyValueKey) => {
        if (registeredField === propertyValueKey) {
          return;
        }
        values[propertyValueKey].selected = false;

        if (!!values[propertyValueKey]?.variations) {
          Object.keys(values[propertyValueKey].variations).forEach(
            (variationKey) => {
              values[propertyValueKey].variations[variationKey] = false;
            },
          );
        }
      });
      setValue(propertyField ?? '', values);
    }
    onChange(event.target.checked);
  };

  return (
    <li>
      <Controller
        name={registeredField}
        defaultValue={getValues(registeredField) ?? false}
        render={({ onChange, value }) => (
          <>
            <input
              type="checkbox"
              id={`variation-${variationValue.id}`}
              checked={value}
              onChange={handleChange(onChange)}
            />
            <label htmlFor={`variation-${variationValue.id}`}>
              {variationValue.name}
            </label>
          </>
        )}
      />
    </li>
  );
};

export default React.memo(VariationItem);
