import { useQuery } from '@apollo/client';
import {
  BoxId,
  Category,
  CategoryBase,
  CrateId,
  MediaCenterItemBase,
  PageId,
  ProductBase,
  Report,
} from '@wgt/types';
import React, { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import Loader from '../../../../components/_organisms/Loader';
import useLoggedContext from '../../../../hooks/useLoggedContext';
import { useBagContext } from '../../../../services/bag';
import Content from '../../Products/ProductUpdateRow/Content';
import { PRODUCT } from '../../Products/ProductUpdateRow/graphql';
import { ProductQueryType, ProductStatus } from '../../Products/types';
import Provider from '../../Products/Update/Provider';
import { GET_CATEGORY_GQL } from '../graphql';

interface ProductInput {
  id: number;
  boxes: BoxId[];
  crates: CrateId[];
  category: Category;
  description: string;
  name: string;
  office: { id: number; name: string };
  lot_id: string;
  sku: string;
  tray_id: string;
  reports: Report[];
  pages: PageId[];
  product_status_id?: string;
  office_id?: number;
  files?: MediaCenterItemBase[];
}

interface ComponentsProps {
  product: ProductBase;
  filters?: unknown;
  statuses: ProductStatus[];
}

const ComponentRow: React.FC<ComponentsProps> = ({
  product,
  filters,
  statuses,
}) => {
  const params = useParams<{ category: string }>();
  const methods = useForm<ProductInput>({
    defaultValues: {
      ...product,
      product_status_id: product?.productStatus?.id,
      crates: product.crates?.length ? [...product.crates] : [{ name: '' }],
      boxes: product.boxes?.length ? [...product.boxes] : [{ name: '' }],
      office_id: product.office?.id,
      files: product.files,
    },
    shouldUnregister: false,
  });

  const { data } = useQuery<{ category: CategoryBase }>(GET_CATEGORY_GQL, {
    fetchPolicy: 'cache-only',
    skip: !params?.category,
    variables: {
      id: params.category,
    },
  });

  if (!product.id) {
    return <React.Fragment />;
  }

  const headers =
    useMemo(
      () =>
        typeof data?.category.template?.result_header === 'string' // typescript fix
          ? []
          : data?.category.template?.result_header,
      [data],
    ) ?? [];

  return (
    <FormProvider {...methods}>
      <Provider filters={filters}>
        <Content statuses={statuses} headers={headers} />
      </Provider>
    </FormProvider>
  );
};

const AddProductFromSearch: React.FC = () => {
  const { active } = useLoggedContext();
  const { newProductId } = useBagContext<{ newProductId: string }>();

  const UPDATE_STOCK_FILTERS = useMemo(
    () => ({
      id: newProductId,
      officeFilter: active ? { firm_id: [active.id] } : null,
    }),
    [active, newProductId],
  );

  const { data, loading } = useQuery<ProductQueryType>(PRODUCT, {
    skip: !newProductId,
    variables: UPDATE_STOCK_FILTERS,
  });

  if (loading || !data?.product?.id) {
    return (
      <div className="d-flex justify-content-center">
        <Loader round />
      </div>
    );
  }

  return (
    <ComponentRow
      product={data?.product}
      filters={UPDATE_STOCK_FILTERS}
      statuses={data?.productsStatus?.data ?? []}
    />
  );
};

export default AddProductFromSearch;
