import React, { useState } from 'react';
import { Pane, Tablist, Tab } from 'evergreen-ui';
import useShapeGroups from '@wgt/shared/hooks/useShapeGroups';
import { PagesOptions, Shape } from '@wgt/types';
import Shapes from './Shapes';

interface ShapeGroupsFormProp {
  field: string;
  shapes?: Shape[];
  isMulti?: boolean;
  disabled?: boolean;
  numberOfPagesOptions?: PagesOptions[];
}

export default function ShapeGroupsForm({
  field,
  isMulti,
  disabled,
  shapes = [],
  numberOfPagesOptions,
}: ShapeGroupsFormProp): JSX.Element {
  const { shapeGroups, filterBy } = useShapeGroups({ shapes });
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <>
      <Pane display="flex" alignItems="center" className="mb-3">
        <Pane>
          <input
            type="text"
            className="form-control w-100"
            placeholder="Filter shape"
            onChange={(e) => filterBy(e.target.value)}
          />
        </Pane>
        <Tablist>
          {shapeGroups.map((shapeGroup, index) => (
            <Tab
              key={shapeGroup.id}
              isSelected={index === activeIndex}
              onSelect={() => setActiveIndex(index)}
              aria-controls={`panel-${shapeGroup?.title}`}
            >
              {shapeGroup?.title}
            </Tab>
          ))}
        </Tablist>
      </Pane>
      <Pane>
        {shapeGroups.map((shapeGroup, index) => (
          <Pane
            key={shapeGroup.id}
            id={`panel-${shapeGroup.title}`}
            role="tabpanel"
            aria-labelledby={shapeGroup.title}
            aria-hidden={index !== activeIndex}
            display={index === activeIndex ? 'block' : 'none'}
            className="expanded-dropdown-image"
          >
            <Shapes
              items={shapeGroup.shapes}
              isMulti={isMulti}
              field={field}
              disabled={disabled}
              numberOfPagesOptions={numberOfPagesOptions}
            />
          </Pane>
        ))}
      </Pane>
    </>
  );
}
