import { gql } from '@apollo/client';
import {
  categoryFragment,
  officeFragment,
  officeProductPaginatorFragment,
  productPageFragment,
  productStatusFragment,
  templateFragment,
} from '../Products/graphql';
import { paginatorInfoFragment } from '../../../services/graphql/paginator.gql';

export const productCommonAttributesFragment = gql`
  fragment productCommonPOSAttributes_Part on Product {
    sku
    name
    description
    lot_id
    tray_id
    total_price
    confirmed
    crates {
      id
      name
    }
    boxes {
      id
      name
    }
  }
`;

export const productIdentifierFragment = gql`
  fragment productIdentifierPOS_Part on Product {
    id
    draft
    category {
      id
      name
      categoryType {
        id
        name
      }
      productType {
        id
        numberPages
      }
    }
  }
`;
export const productEntityFragment = gql`
  fragment productEntityPOS_Part on Product {
    ...productIdentifierPOS_Part
    ...productCommonPOSAttributes_Part
    productStatus {
      ...productStatusIdentifier_Part
    }
    pages {
      ...productPage_Part
      fields
      price_per_carat
      carat_weight
    }
    office {
      ...officePOS_Part
    }
    category {
      ...productCategory_Part
      template {
        ...template_Part
      }
      categoryGroup {
        id
        name
      }
    }
  }
  ${productStatusFragment}
  ${categoryFragment}
  ${productIdentifierFragment}
  ${productCommonAttributesFragment}
  ${productPageFragment}
  ${officeFragment}
  ${templateFragment}
`;

export const LIST_POINT_OF_SALES = gql`
  query pointOfSales($filter: PointOfSaleFilterInput, $first: Int, $page: Int) {
    pointOfSales(filter: $filter, first: $first, page: $page) {
      data {
        id
        has_discount
        has_shipping
        has_interest
        has_custom_duty
        has_tax
        intent
        policy {
          id
          title
          net_day_count
          expiry_date
        }
        products {
          ...productEntityPOS_Part
        }
      }
    }
  }
  ${productEntityFragment}
`;
export const GET_POINT_OF_SALE_GQL = gql`
  query pointOfSale($id: ID!, $officeFilter: OfficeFilterInput) {
    pointOfSale(id: $id) {
      id
      has_discount
      has_shipping
      has_interest
      has_custom_duty
      has_tax
      intent
      policy {
        id
        title
        net_day_count
        expiry_date
      }
      products {
        ...productEntityPOS_Part
      }
    }
    offices(filter: $officeFilter) {
      ...officeProductPaginator_Part
    }
  }
  ${productEntityFragment}
  ${officeProductPaginatorFragment}
`;

export const CREATE_POINT_OF_SALE_GQL = gql`
  mutation createPointOfSale($point_of_sale: CreatePointOfSaleInput!) {
    createPointOfSale(point_of_sale: $point_of_sale) {
      id
    }
  }
`;

export const USERS_GQL = gql`
  query users($filter: UserFilterInput) {
    users(filter: $filter, first: 1000) {
      data {
        id
        first_name
        middle_name
        last_name
        email
      }
    }
  }
`;

export const POS_PRODUCT = gql`
  mutation attachProductToPointOfSale(
    $id: ID!
    $product_id: ID!
    $confirmed: Boolean
  ) {
    attachProductToPointOfSale(
      id: $id
      product_id: $product_id
      confirmed: $confirmed
    ) {
      message
    }
  }
`;

export const GET_POINT_OF_SALE_PRODUCTS_GQL = gql`
  query productsPointOfSale($id: ID!) {
    productsPointOfSale(id: $id) {
      products {
        ...productEntityPOS_Part
      }
    }
  }
  ${productEntityFragment}
`;

export const SHIPPINGS = gql`
  query shippingsQuery(
    $point_of_sale_id: ID!
    $office_id: ID!
    $executive_buyer_id: ID!
  ) {
    shippings(
      point_of_sale_id: $point_of_sale_id
      office_id: $office_id
      executive_buyer_id: $executive_buyer_id
    ) {
      type
      tracking
      arrival
      total
    }
  }
`;

export const UPDATE_POINT_OF_SALE_GQL = gql`
  mutation updatePointOfSale($id: ID!, $point_of_sale: UpdatePointOfSaleInput) {
    updatePointOfSale(id: $id, point_of_sale: $point_of_sale) {
      id
    }
  }
`;

export const LIST_POINT_OF_SALES_SHORT = gql`
  query pointOfSales($filter: PointOfSaleFilterInput, $first: Int, $page: Int) {
    pointOfSales(filter: $filter, first: $first, page: $page) {
      data {
        id
      }
      paginatorInfo {
        ...paginatorInfo_Part
      }
    }
  }
  ${paginatorInfoFragment}
`;
