import React from 'react';
import { FormGroup, Label, Col, Input } from 'reactstrap';

const CommonAttributesSectionLayout: React.FC = () => (
  <div className="common-attributes m-3 float-left">
    <FormGroup row className="no-gutters mb-4">
      <Label sm={3} className="text-right pr-3">
        SKU
      </Label>
      <Col sm={9}>
        <Input type="text" name="sku" placeholder="Enter the SKU" disabled />
      </Col>
    </FormGroup>
    <FormGroup row className="no-gutters mb-4">
      <Label sm={3} className="text-right pr-3">
        Name
      </Label>
      <Col sm={9}>
        <Input type="text" name="name" placeholder="Enter the name" disabled />
      </Col>
    </FormGroup>
    <FormGroup row className="no-gutters mb-4">
      <Label sm={3} className="text-right pr-3">
        Total Price
      </Label>
      <Col sm={9}>
        <Input
          type="text"
          name="total"
          placeholder="Enter the Total Price"
          disabled
        />
      </Col>
    </FormGroup>
    <FormGroup row className="no-gutters mb-4">
      <Label sm={3} className="text-right pr-3">
        Price Per Crate
      </Label>
      <Col sm={9}>
        <Input
          type="text"
          name="crate"
          placeholder="Enter the Price Per Crate"
          disabled
        />
      </Col>
    </FormGroup>
  </div>
);

export default CommonAttributesSectionLayout;
