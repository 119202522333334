import { useQuery } from '@apollo/client';
import { Mineral } from '@wgt/types';
import React from 'react';
import Loader from '../../../../../../../components/_organisms/Loader';

import { MINERAL_DETAILS } from './graphql';
import MineralItemForm from './MineralItemForm';

export function MineralItemContent({
  mineral,
}: {
  mineral: Mineral;
}): JSX.Element {
  const { data, loading } = useQuery<{ mineral: Mineral }>(MINERAL_DETAILS, {
    variables: {
      id: mineral.id,
    },
  });

  if (loading || !data?.mineral?.id) {
    return <Loader />;
  }

  return <MineralItemForm mineral={data.mineral} />;
}
