import React from 'react';
import { Shipping } from '@wgt/types';
import ShutterStock from '../../../../../../assets/shutterstock.svg';
import { Text } from '../../../../../../components';
import { useTranslation } from 'react-i18next';
import usePointOfSaleUpdate from '../../usePointOfSaleUpdate';
import { useFormContext } from 'react-hook-form';

interface ShippingOption {
  option: Shipping;
}

const ShippingOptionItem: React.FC<ShippingOption> = ({ option }) => {
  const { t } = useTranslation('pointOfSale');
  const { setValue } = useFormContext();
  const { setContentTab } = usePointOfSaleUpdate();
  const onSelectShippingOption = () => [
    setValue('shipping', option),
    setContentTab('results'),
  ];
  return (
    <div
      className="border rounded p-2 bg-white mr-0"
      id="shipping"
      onClick={onSelectShippingOption}
    >
      <div className="d-flex header">
        <img src={ShutterStock} alt="Shipping" className="iconShipping" />
        <Text className="box-title ml-2">
          {option?.type ?? t('Shipping & Handling')}
        </Text>
      </div>
      <div className="pl-2 mt-4">
        <Text>
          {t('Tracking')}: {option?.tracking ?? '#234342423423'}
        </Text>
        <Text>
          {t('Arrival')}:{' '}
          <strong>{option?.arrival ?? t('common:emptyDate')}</strong>
        </Text>
      </div>
    </div>
  );
};

export default ShippingOptionItem;
