import React from 'react';
import { Row, Col, Container, Form } from 'reactstrap';
import {
  useForm,
  FormProvider,
  Controller,
  useFieldArray,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Country, OfficeInput } from '@wgt/types';
import { useMutation } from '@apollo/client';
import { SimpleInput, Button } from '../../../../../../components';
import BagProvider from '../../../../../../services/bag';
import { COUNTRIES } from '../../../../Firms/graphql';
import SelectSearch from '../../../../../../components/SelectSearch';
import TollFreePhones from './TollFreePhones';
import OfficeCategories from './OfficeCategories';
import { CREATE_OFFICE_GQL } from './graphql';
import useToast from '../../../../../../hooks/useToast';

export default function AddNewOffice(): JSX.Element {
  const { t } = useTranslation(['hr', 'common']);
  const { toast } = useToast();

  const methods = useForm<OfficeInput>({
    defaultValues: {
      toll_free_phones: [{ name: '' }],
      types: [],
      category_ids: [],
    },
    shouldUnregister: false,
  });

  const { control, handleSubmit, getValues, setError, errors } = methods;

  const [create] = useMutation(CREATE_OFFICE_GQL, {
    onCompleted: () => toast('success'),
  });

  const onSubmit = handleSubmit((values: OfficeInput) => {
    const formValues = getValues(['types', 'category_ids']);
    if (!formValues.types?.length) {
      return setError('types', { type: 'required' });
    }

    const variables = {
      name: values.name,
      address: values.address,
      address_2: values.address_2,
      phone: values.phone,
      postal_code: values.postal_code,
      city: values.city,
      state: values.state,
      country_id: values.country?.id,
      firm_id: 1,
      types: formValues.types,
      category_ids: formValues.category_ids,
    };

    create({ variables });
  });

  const {
    fields: tollFreePhones,
    append: appendTollFreePhone,
    remove: removeTollFreePhone,
  } = useFieldArray({
    control,
    name: 'toll_free_phones',
    keyName: '__id',
  });

  return (
    <Container fluid className="border h-100 py-3">
      <FormProvider {...methods}>
        <Form noValidate onSubmit={onSubmit}>
          <Row>
            <Col lg="6">
              <p>{t('Office Name')}</p>
              <SimpleInput
                field="name"
                placeholder={t('Office Name')}
                className="my-3"
                required
              />

              <p>{t('Office Location')}</p>
              <SimpleInput
                field="address"
                placeholder={`${t('Address')} 1`}
                className="mb-2 mt-3"
                required
              />
              <SimpleInput
                field="address_2"
                placeholder={`${t('Address')} 2`}
                className="mb-2"
              />
              <div className="simple-dropdown mb-2">
                <Controller
                  name="country"
                  defaultValue=""
                  rules={{
                    required: true,
                  }}
                  render={({ onChange }) => (
                    <SelectSearch
                      gql={COUNTRIES}
                      getOptionLabel={(e: Country) => `${e.name ?? ''} `}
                      getOptionValue={(e: Country) => `${e.id}`}
                      name="name"
                      placeholder={t('Country')}
                      onChange={onChange}
                      invalid={Boolean(errors.country)}
                    />
                  )}
                />
              </div>
              <SimpleInput
                field="state"
                placeholder={t('State / Province')}
                className="mb-2"
                required
              />
              <SimpleInput
                field="city"
                placeholder={t('City')}
                className="mb-2"
                required
              />
              <SimpleInput
                field="postal_code"
                placeholder={t('Zip / Postal Code')}
                className="mb-2"
              />
              <OfficeCategories />
            </Col>
            <Col lg="6">
              <p>{t('Communication')}</p>
              <BagProvider
                value={{
                  tollFreePhone: {
                    fields: tollFreePhones,
                    remove: removeTollFreePhone,
                  },
                }}
              >
                <>
                  <TollFreePhones />
                  <Button
                    small
                    htmlType="button"
                    onClick={() => appendTollFreePhone({ name: '' })}
                  >
                    {t('common:Add')}
                  </Button>

                  <SimpleInput
                    field="phone"
                    placeholder={t('Local Phone')}
                    className="mb-2 mt-4"
                  />
                  <Button small htmlType="button">
                    {t('common:Add')}
                  </Button>

                  <SimpleInput
                    field="fax"
                    placeholder={t('Fax')}
                    className="mb-2 mt-4"
                  />
                  <Button small htmlType="button">
                    {t('common:Add')}
                  </Button>

                  <SimpleInput
                    field="email"
                    placeholder={t('E-mail')}
                    className="mb-2 mt-4"
                  />
                  <Button small htmlType="button">
                    {t('common:Add')}
                  </Button>
                </>
              </BagProvider>
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </Container>
  );
}
