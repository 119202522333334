import React, { useCallback, useContext, useMemo, useState } from 'react';
import { FetchResult, gql, useApolloClient } from '@apollo/client';
import SelectSearch from '../SelectSearch';
import { Pane, Popover, Button, Alert, Text } from 'evergreen-ui';
import { PageId } from '../../pages/Crm/Products/types';
import { ProductBase } from '@wgt/types';
import { Col, Modal, ModalBody, Row } from 'reactstrap';
import useToggler from '@wgt/shared/hooks/useToggler';
import PageIDList from './PageIDList';
import useToast from '../../hooks/useToast';
import { RefetchQueryDescription } from '@apollo/client/core/watchQueryOptions';
import { useTranslation } from 'react-i18next';

const SEARCH = gql`
  query products($filter: ProductFilterInput) {
    products(filter: $filter) {
      data {
        id
        name
        sku
        description
      }
    }
  }
`;

interface MovePageIDToSKUProps {
  inline?: boolean;
  pageId: PageId;
}

type MovePageIDContextType = {
  products: ProductBase[];
  toggle: () => void;
  onAdd: (args: { product: ProductBase; pageId: PageId }) => void;
};

const MovePageIDContext = React.createContext({});

function ConfirmationAlert({
  close,
  pageId,
  selected,
}: {
  pageId: PageId;
  selected?: ProductBase;
  close: () => void;
}): JSX.Element {
  const { t } = useTranslation(['product', 'common']);
  const { onAdd } = useContext(MovePageIDContext) as MovePageIDContextType;

  const onConfirmation = () => {
    if (!selected) {
      return;
    }
    onAdd({ pageId, product: selected });
    close();
  };

  return (
    <Alert intent="danger" display="flex" flexDirection="column">
      <Text>
        {t(
          'Would you like to verify if this Item ID is in the correct location?',
        )}
      </Text>
      <Button
        appearance="primary"
        intent="success"
        className="button danger small mx-2"
        onClick={close}
      >
        {t('common:No')}
      </Button>
      <Button
        appearance="primary"
        intent="success"
        className="button small primary"
        onClick={onConfirmation}
      >
        {t('common:Yes')}
      </Button>
    </Alert>
  );
}

interface ProductListBase extends ProductBase {
  pageIds: PageId[];
}

export function MovePageIDToSKUProvider({
  children,
  refetchQueries,
  source,
}: {
  children: JSX.Element;
  source: PageId[];
  refetchQueries?:
    | RefetchQueryDescription
    | ((
        result: FetchResult<
          unknown,
          Record<string, unknown>,
          Record<string, unknown>
        >,
      ) => RefetchQueryDescription);
}): JSX.Element {
  const [products, setProducts] = useState<ProductListBase[]>([]);
  const [isConfirmationShown, toggle] = useToggler(false);
  const { toast } = useToast();
  const { t } = useTranslation('common');
  const onAdd = ({
    pageId,
    product,
  }: {
    pageId: PageId;
    product: ProductBase;
  }) => {
    const storedIndexOfProduct = products.findIndex((_product) =>
      _product.pageIds.some((page) => page.id === pageId.id),
    );
    if (storedIndexOfProduct >= 0) {
      onRemove(products[storedIndexOfProduct])(pageId);
    }

    setProducts((current) => {
      const draft = [...current];

      const indexOf = draft.findIndex((x) => x.sku === product.sku);
      if (indexOf >= 0) {
        draft[indexOf].pageIds.push(pageId);
      } else {
        draft.push({ ...product, pageIds: [pageId] });
      }

      return draft;
    });
    toggle();
  };

  const onRemove = useCallback(
    (product: ProductBase) => (pageId: PageId) => {
      setProducts((current) => {
        const indexOfProduct = current.findIndex((x) => x.id === product.id);

        if (indexOfProduct < 0) {
          return current;
        }

        const draft = [...current];

        draft[indexOfProduct].pageIds.splice(
          draft[indexOfProduct].pageIds.findIndex((x) => x.id === pageId.id),
          1,
        );

        if (!draft[indexOfProduct].pageIds.length) {
          draft.splice(indexOfProduct, 1);
        }
        return draft;
      });
    },
    [],
  );

  const client = useApolloClient();

  const filteredSource = useMemo(
    () =>
      source.filter(
        (sourcePageId) =>
          !products.some((targetProduct) =>
            targetProduct.pageIds.some(
              (targetPageId) => targetPageId.id === sourcePageId.id,
            ),
          ),
      ),
    [source, products],
  );

  const onFinish = async () => {
    try {
      await client.mutate({
        refetchQueries,
        mutation: gql`
          mutation associateSKUS {
              ${products.reduce((acc, curr) => {
                const val = curr.pageIds.map(
                  (pageId) =>
                    `associate_${curr.id}_${pageId.id}: associateSKU(id: ${pageId.id}, sku: "${curr.sku}") { message } `,
                );

                return `${acc} ${val.join('')}`;
              }, '')}
          }
      
      `,
      });
      setProducts([]);
      toggle();
      toast('success');
    } catch (e) {
      toast('error', e.message);
    }
  };

  return (
    <MovePageIDContext.Provider value={{ products, onAdd, toggle }}>
      {children}
      <Modal isOpen={isConfirmationShown} size="xl">
        <ModalBody>
          <Row className="align-items-center">
            <Col md={5}>
              <span>{t('Current SKU')}</span>
              <PageIDList pages={filteredSource} value={filteredSource?.[0]} />
            </Col>
            <Col md={2}>
              <Button
                className="button primary"
                type="button"
                appearance="primary"
                onClick={onFinish}
                disabled={!products.length}
              >
                {t('Finish')}
              </Button>
            </Col>
            <Col md={5}>
              <span>{t('Targets')}</span>
              {products.map((product) => (
                <Row key={product.sku}>
                  <Col>SKU: {product.sku}</Col>
                  <Col>
                    <PageIDList
                      pages={product.pageIds}
                      value={product.pageIds?.[0]}
                      remove={onRemove(product)}
                    />
                  </Col>
                </Row>
              ))}
            </Col>
          </Row>
          <Button
            type="button"
            onClick={toggle}
            appearance="primary"
            className="button danger"
          >
            {t('Close')}
          </Button>
        </ModalBody>
      </Modal>
    </MovePageIDContext.Provider>
  );
}

export default React.memo(function MovePageIDToSKU({
  inline,
  pageId,
}: MovePageIDToSKUProps): JSX.Element {
  const [selected, setSelected] = useState<ProductBase>();
  const { toggle } = useContext(MovePageIDContext) as MovePageIDContextType;
  const { t } = useTranslation(['product', 'common']);
  return (
    <Pane className="sku-form" display={inline ? 'flex' : 'initial'}>
      <div style={{ width: '280px', fontSize: inline ? 10 : 14, zIndex: 4 }}>
        <SelectSearch
          gql={SEARCH}
          name="sku"
          getOptionLabel={(e) => `${e.name ?? ''} - ${e.sku ?? ''}`}
          getOptionValue={(e) => `${e.id}`}
          placeholder={t('Enter SKU and add this Page ID')}
          onChange={setSelected}
        />
      </div>
      <Popover
        content={({ close }) => (
          <ConfirmationAlert
            close={close}
            pageId={pageId}
            selected={selected}
          />
        )}
      >
        <Button
          type="button"
          appearance="primary"
          intent="success"
          className="button primary small mx-3"
          disabled={!selected}
        >
          {t('common:Move')}
        </Button>
      </Popover>
      {!inline && (
        <button
          type="button"
          className="btn btn-outline-primary mr-2 btn-sm"
          onClick={toggle}
        >
          {t('common:Menu')}
        </button>
      )}
    </Pane>
  );
});
