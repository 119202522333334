import React from 'react';
import { ExpandedDropdownOptions } from '@wgt/types';
import ButtonCheckbox from '../../../ButtonCheckbox';
import PropertyValueVariationCheckBox from '../../../PropertyValueVariationCheckBox';

interface Props {
  label: string;
  field: string;
  values: ExpandedDropdownOptions[];
  required?: boolean;
  isMulti?: boolean;
  disabled?: boolean;
}

const ExpandedDropdownText: React.FC<Props> = ({
  field,
  isMulti,
  required,
  label,
  values,
  disabled,
}) => {
  if (values.some((value) => value.variations?.length)) {
    return (
      <PropertyValueVariationCheckBox
        label={label}
        field={field}
        isMulti={isMulti}
        options={values}
        required={required}
        disabled={disabled}
        controller={{ identifier: 'key', label: 'name' }}
      />
    );
  }

  return (
    <ButtonCheckbox
      label={label}
      field={field}
      isMulti={isMulti}
      disabled={disabled}
      options={values}
      required={required}
      controller={{ identifier: 'key', label: 'name' }}
    />
  );
};
export default ExpandedDropdownText;
