import React, { useRef } from 'react';

import { Button } from '../../../../../components';
import { useMutation } from '@apollo/client';
import StateManager from 'react-select';
import { useProductUpdate } from '../Provider';
import { ADD_STOCK_GQL } from '../../graphql';
import SelectSearch from '../../../../../components/SelectSearch';
import { PRODUCT } from '../../ProductUpdateRow/graphql';
import { Pane } from 'evergreen-ui';
import { PageId, CategoryBase } from '@wgt/types';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { SEARCH, ASSOCIATE } from './graphql';
import { SearcherProps } from './types';

const PageIdComponent: React.FC<SearcherProps> = ({ inline }) => {
  const { pages, filters } = useProductUpdate();
  const { getValues } = useFormContext();
  const { t } = useTranslation(['product', 'common']);
  const category = getValues('category') as CategoryBase;

  const [associateSKU, { loading: submitting }] = useMutation(ASSOCIATE, {
    refetchQueries: [
      { query: inline ? PRODUCT : ADD_STOCK_GQL, variables: filters },
    ],
  });
  const skuRef = useRef<StateManager<PageId> | null>(null);

  const associate = () => {
    if (!skuRef.current?.state.value) {
      return;
    }

    const selected = skuRef.current?.state.value as PageId;
    associateSKU({
      variables: {
        id: selected.id,
        sku: getValues('sku'),
      },
    });
  };

  return (
    <Pane className="sku-form" display={inline ? 'flex' : 'initial'}>
      <div style={{ width: '280px', fontSize: inline ? 10 : 14, zIndex: 4 }}>
        <SelectSearch
          gql={SEARCH}
          name="name"
          getOptionLabel={(e) =>
            `${e.name ?? ''} ${
              e.product?.sku ? `- ${t('SKU')}: ${e.product.sku}` : ''
            }`
          }
          getOptionValue={(e) => `${e.id}`}
          ref={skuRef}
          placeholder={t('Enter Page ID and add to this SKU')}
          parseResults={(items: PageId[]) =>
            items.filter(
              (item) =>
                !pages.fields
                  ?.map((pageId: PageId) => Number(pageId.id))
                  .includes(Number(item.id)),
            )
          }
          parseVariables={(variables) => ({
            filter: {
              name: variables.filter.name,
              category_id: category?.id,
            },
          })}
        />
      </div>
      <Button
        small
        htmlType="button"
        onClick={associate}
        disabled={submitting}
        isLoading={submitting}
        className="mx-3"
      >
        {t('common:Move')}
      </Button>
      {!inline && (
        <button type="button" className="btn btn-outline-primary mr-2 btn-sm">
          {t('common:Menu')}
        </button>
      )}
    </Pane>
  );
};

export default PageIdComponent;
