import { gql } from '@apollo/client';

export const employeeIdentityFragment = gql`
  fragment employeeIdentity_Part on User {
    id
    first_name
    middle_name
    last_name
    phone
    email
    addresses {
      address
      address_2
      city
      state
      country {
        name
      }
      zip_code
    }
    jobTitles {
      name
    }
  }
`;

export const GET_EMPLOYEES_GQL = gql`
  query employees($filter: UserFilterInput) {
    employees(first: 1000, filter: $filter) {
      data {
        ...employeeIdentity_Part
      }
    }
  }
  ${employeeIdentityFragment}
`;

export const LIST_FIRMS_GQL = gql`
  query firms($filter: FirmFilterInput) {
    firms(first: 1000, filter: $filter) {
      data {
        id
        name
        address {
          number
          address
          state
          city
          country {
            name
          }
          phone
          postal_code
        }
        extra {
          email
          logo_url
        }
        employees {
          ...employeeIdentity_Part
        }
      }
    }
  }
  ${employeeIdentityFragment}
`;
