import { useMutation } from '@apollo/client';
import { IconButton, Pane } from 'evergreen-ui';
import React from 'react';
import { FaTrash } from 'react-icons/fa';
import classnames from 'classnames';
import { DETACH_TAG } from './graphql';
import { InclusionTag, MediaCenterFileType } from '@wgt/types';
import { useBagContext } from '../../../../../../../services/bag';
import { MediaCenterController } from '../../../../../../../hooks/useMediaCenterController';
import { LIST_FILES } from '../../graphql';
import { useTranslation } from 'react-i18next';
import useToast from '../../../../../../../hooks/useToast';

interface InclusionPointProps {
  inclusion: InclusionTag;
  type: MediaCenterFileType;
}

export default function InclusionPoint({
  inclusion,
  type,
}: InclusionPointProps): JSX.Element {
  const { productId } = useBagContext<MediaCenterController>();
  const { t } = useTranslation('mediaCenter');
  const { toast } = useToast();
  const [remove, { loading }] = useMutation(DETACH_TAG, {
    refetchQueries: [
      {
        query: LIST_FILES,
        variables: {
          filter: {
            product_id: productId,
            type,
          },
        },
      },
    ],
    variables: {
      id: inclusion.id,
    },
    onCompleted: () => toast('success', t('Successfully removed tag')),
    onError: () => toast('error', t('Failed to remove tag. Try again')),
  });
  return (
    <Pane
      className={classnames('inclusion-pointer', { loading })}
      style={{
        marginLeft: `${inclusion.position_x}px`,
        marginTop: `${inclusion.position_y}px`,
      }}
    >
      <Pane className="inclusion-pointer-content">
        <span>{inclusion.inclusionTag.title}</span>
        <IconButton
          icon={FaTrash}
          appearance="minimal"
          intent="danger"
          disabled={loading}
          isLoading={loading}
          onClick={() => remove()}
        />
      </Pane>
    </Pane>
  );
}
