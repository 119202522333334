/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'reactstrap';
import { PropertyValue } from '@wgt/types';
import ConfirmDialog from '../../../../../../components/_molecules/ConfirmDialog';
import useToast from '../../../../../../hooks/useToast';
import ValuesTableItem from './ValuesTableItem';
import { useMutation, useQuery } from '@apollo/client';
import {
  DELETE_PROPERTY_VALUE_GQL,
  LIST_PROPERTY_VALUE_GQL,
  SORT_PROPERTY_VALUE_GQL,
} from '../../../graphql';
import MineralsTable from './MineralsTable';
import { AlertMessage } from '../../../../../../components';
import { PropertyValueType } from '../../../types';

interface PropertyValuesTableProps {
  propertyId?: number;
  propertyType?: PropertyValueType;
}

const List: React.FC<PropertyValuesTableProps> = ({
  propertyId,
  propertyType,
}) => {
  const filters = {
    filter: { property_id: propertyId },
  };

  const refetchQueries = [
    { query: LIST_PROPERTY_VALUE_GQL, variables: filters },
  ];

  const { data, loading } = useQuery<{
    propertyValues: { data: PropertyValue[] };
  }>(LIST_PROPERTY_VALUE_GQL, { variables: filters });

  const onError = (response: any) => {
    const message = response?.message ?? 'Request error. Try again';
    toast('error', message);
  };

  const [deletePropertyValue, { loading: removing }] = useMutation(
    DELETE_PROPERTY_VALUE_GQL,
    {
      onError,
      refetchQueries,
      onCompleted: () => [
        toast('success', t('Record successfully deleted.')),
        setDeleteItem(null),
      ],
    },
  );

  const [
    sortMutation,
    { loading: sorting },
  ] = useMutation(SORT_PROPERTY_VALUE_GQL, { refetchQueries });

  const [deleteItem, setDeleteItem] = useState<PropertyValue | null>(null);
  const { toast } = useToast();
  const { t } = useTranslation(['common']);

  const confirmDelete = async () => {
    if (!!deleteItem) {
      deletePropertyValue({ variables: { id: deleteItem.id } });
    }
  };

  const sorted = useMemo(
    () =>
      [...(data?.propertyValues.data ?? [])].sort(
        (x, y) => (x.position ?? 0) - (y.position ?? 0),
      ),
    [data?.propertyValues.data, removing, sorting],
  );

  const handleSort = ({ id, position }: { id: number; position: number }) => {
    const propertyValueIds = sorted.map((x) => x.id) ?? [];

    propertyValueIds.splice(
      position,
      0,
      propertyValueIds.splice(
        propertyValueIds.findIndex((x) => x === id),
        1,
      )[0],
    );

    sortMutation({ variables: { propertyValueIds } });
  };

  return (
    <>
      <ConfirmDialog
        isShown={!!deleteItem}
        onConfirm={confirmDelete}
        title={t(`Are you sure you want to delete this record`)}
        onClose={() => setDeleteItem(null)}
      />
      {propertyType === 'is_minerals' && <MineralsTable />}
      {!propertyType && (
        <Table striped hover>
          <thead>
            <tr>
              <th>{t('Label')}</th>
              <th>{t('Abbreviation')}</th>
              <th>{t('Value')}</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {sorted.map((propertyValue, index) => (
              <ValuesTableItem
                key={propertyValue.id}
                propertyValue={{
                  ...propertyValue,
                  position: propertyValue.position ?? index + 1,
                }}
                setDeleteItem={setDeleteItem}
                handleSort={handleSort}
              />
            ))}
          </tbody>
        </Table>
      )}
      {!sorted.length && !loading && (
        <AlertMessage>{t('There is no records in the list.')}</AlertMessage>
      )}
    </>
  );
};

export default List;
