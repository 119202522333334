/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { ShapeDescription, ShapeDescriptionGroup } from '@wgt/types';

/**
 *
 * @param object
 * @param keys
 * @param defaultVal
 * https://lodash.com/docs/#get
 */
export const getProp = (object: any, keys: any, defaultVal?: any): any => {
  if (object === undefined) {
    return defaultVal;
  }
  keys = Array.isArray(keys)
    ? keys
    : keys.replace(/(\[(\d)\])/g, '.$2').split('.');
  object = object[keys[0]];
  if (object && keys.length > 1) {
    return getProp(object, keys.slice(1), defaultVal);
  }
  return object === undefined ? defaultVal : object;
};

export const buildShapeGroupsStructure = (
  shapeDescriptions?: ShapeDescription[],
): ShapeDescriptionGroup[] => {
  return (
    shapeDescriptions?.reduce(
      (groups: ShapeDescriptionGroup[], description) => {
        const foundIndex = groups.findIndex(
          (found) => found.name === description.group,
        );
        if (foundIndex === -1) {
          groups.push({
            name: description.group,
            shapeDescriptions: [description],
            display_type: description.display_type,
          });
        } else {
          groups[foundIndex] = {
            ...groups[foundIndex],
            shapeDescriptions: [
              ...groups[foundIndex].shapeDescriptions,
              description,
            ],
          };
        }
        return groups;
      },
      [],
    ) ?? []
  );
};

// JUST HANDLING A BACKEND FIX TO BE FASTER TO SHOW THIS CHANGE TO FRANCIS
export const handleCategoryTypeName = (categoryTypeName: string) => {
  let newName;
  switch (categoryTypeName.toLocaleLowerCase()) {
    case 'finished product':
      newName = 'General Product';
      break;
    case 'stone':
      newName = 'Product';
      break;

    default:
      newName = categoryTypeName;
      break;
  }

  return newName;
};
