import { useApolloClient } from '@apollo/client';
import React, { useMemo } from 'react';
import { Title, Line } from '../../../../../components';
import { ADD_STOCK_GQL } from '../../graphql';
import { useProductUpdate } from '../Provider';

import SelectOfficeCard from '../../../../../components/SelectOfficeCard';
import { Row, Col } from 'reactstrap';

const Office: React.FC = () => {
  const client = useApolloClient();
  const { filters, isFormEnabled } = useProductUpdate();
  const data = client.readQuery({ query: ADD_STOCK_GQL, variables: filters });

  const offices = useMemo(() => data?.offices?.data ?? [], [data?.offices]);

  return (
    <div className="mt-4">
      <Title heading="h2">Office</Title>
      <Line />
      <Row>
        <Col xs="4">
          <div className="d-flex justify-content-between">
            <SelectOfficeCard
              title="Office Location"
              options={offices}
              field="office"
              placeholder="Choose an Office"
              disabled={!isFormEnabled}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Office;
