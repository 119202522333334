import React, { useState, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaTrash } from 'react-icons/fa';
import { Button } from '../../../../../../../components';
import ConfirmDialog from '../../../../../../../components/_molecules/ConfirmDialog';
import { useBagContext } from '../../../../../../../services/bag';

interface MiddleName {
  id?: number;
  name?: string;
}

interface MiddleNameProps {
  middleName: MiddleName;
  index: number;
}

export default function MiddleNameItem({
  middleName,
  index,
}: MiddleNameProps): JSX.Element {
  const { t } = useTranslation('hr');
  const { register } = useFormContext();

  const {
    middleName: { remove },
  } = useBagContext<{ middleName: { remove(i: number): void } }>();

  const [showRemoveConfirm, setShowRemoveConfirm] = useState<boolean>(false);

  const onConfirm = useCallback(async () => {
    remove(index);
    setShowRemoveConfirm(false);
  }, [middleName, index]);

  const onClickRemove = (e: React.MouseEvent) => {
    e.preventDefault();
    setShowRemoveConfirm(true);
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-end mb-2">
        <input
          type="hidden"
          name={`middle_names[${index}].id`}
          ref={register()}
          defaultValue={middleName.id}
        />
        <input
          name={`middle_names[${index}].name`}
          ref={register()}
          defaultValue={middleName.name}
          className="form-control w-100"
          placeholder={t('Middle Name')}
        />

        {!!index && (
          <Button
            htmlType="button"
            onClick={onClickRemove}
            small
            type="danger"
            className="ml-2"
          >
            <FaTrash />
          </Button>
        )}
      </div>
      <ConfirmDialog
        isShown={showRemoveConfirm}
        onConfirm={onConfirm}
        title={t('Are you sure you want to delete this record?')}
        onClose={() => setShowRemoveConfirm(false)}
      />
    </>
  );
}
