import React from 'react';
import { CategoryGroup } from '@wgt/types';
import { Pane } from 'evergreen-ui';
import Text from '../../Text';
import { Link } from 'react-router-dom';
import { useBagContext } from '../../../services/bag';
import useNavbar from '../../../hooks/useNavbar';
interface CategoriesListProps {
  categoryGroup: CategoryGroup;
  title?: string;
  productTypeId: number;
}

const CategoriesList: React.FC<CategoriesListProps> = ({
  categoryGroup,
  title,
  productTypeId,
}) => {
  const { setShown } = useBagContext<{ setShown(isShown: boolean): void }>();
  const { routeArgs } = useNavbar();

  return (
    <Pane className="ml-3 d-flex flex-column mx-2">
      {title && <Text className="navbar-categories-title">{title}</Text>}
      {categoryGroup.categories
        ?.filter(
          (category) =>
            Number(category.productType?.id) === Number(productTypeId),
        )
        .map((category) => (
          <Link
            onClick={() => setShown?.(false)}
            to={{
              pathname: `/crm/search/categories/${category.id}/products`,
              state: routeArgs,
            }}
            key={`category-item-${category.id}`}
          >
            <Text>{category.name}</Text>
          </Link>
        ))}
    </Pane>
  );
};

export default CategoriesList;
