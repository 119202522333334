import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormGroup, Label, Row, Col, Form } from 'reactstrap';
import { Button, SimpleInput, Text } from '../../../../../components';
import usePointOfSaleUpdate, { ProductFilter } from '../usePointOfSaleUpdate';
import IncludeInPaymentSelector from '../IncludeInPayment';

const DEFAULT_VALUES = {
  sku: '',
  lot_id: '',
  tray_id: '',
  boxes: [],
  crates: [],
};

const HeaderInputs: React.FC = () => {
  const { t } = useTranslation(['product', 'common']);
  const { filter, filters } = usePointOfSaleUpdate();
  const methods = useForm<ProductFilter>({
    defaultValues: DEFAULT_VALUES,
  });

  useEffect(() => {
    methods.reset(filters ?? DEFAULT_VALUES);
  }, [filters]);

  const onSubmit = methods.handleSubmit((e) => filter(e));

  return (
    <Row className="d-flex align-items-end">
      <Col xs="2">
        <IncludeInPaymentSelector />
      </Col>
      <Col xs="10">
        <FormProvider {...methods}>
          <Form onBlur={onSubmit}>
            <Row>
              <Col xs="7" className="d-flex justify-content-end">
                <FormGroup className="simple-input pr-3 mb-0">
                  <Label for="sku">{t('product:SKU')}</Label>
                  <SimpleInput field="sku" placeholder={t('product:SKU')} />
                </FormGroup>

                <FormGroup className="simple-input pr-3 mb-0">
                  <Label for="lotId">{t('product:Lot ID')}</Label>
                  <SimpleInput
                    field="lot_id"
                    placeholder={t('product:Lot ID')}
                  />
                </FormGroup>

                <FormGroup className="simple-input pr-3 mb-0">
                  <Label for="trayId">{t('product:Tray ID')}</Label>
                  <SimpleInput
                    field="tray_id"
                    placeholder={t('product:Tray ID')}
                  />
                </FormGroup>
              </Col>
              <Col
                xs="5"
                className="d-flex justify-content-end align-items-end mb-2"
              >
                <div className="w-50">
                  <Text className="mr-2">{t('product:Add Box ID')}</Text>
                  <Button small>{t('common:Add')}</Button>
                </div>

                <div className="w-50">
                  <Text className="mr-2">{t('product:Add Crate ID')}</Text>
                  <Button small>{t('common:Add')}</Button>
                </div>
              </Col>
            </Row>
          </Form>
        </FormProvider>
      </Col>
    </Row>
  );
};

export default HeaderInputs;
