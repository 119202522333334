/* eslint-disable @typescript-eslint/no-explicit-any */
import { Pane } from 'evergreen-ui';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/lib/ReactCrop.scss';
import { Button } from '..';

interface ImageCropperProps {
  file: string | File;
  onConfirm?: () => void;
  onCancel?: () => void;
  onChange?: (...event: unknown[]) => void;
  aspect?: number;
}

const noop = () => undefined;

export default function ImageCropper({
  file,
  onChange = noop,
  onConfirm = noop,
  onCancel = noop,
  aspect = 3 / 4,
}: ImageCropperProps): JSX.Element {
  const [image, setImage] = useState<string>();
  const [crop, setCrop] = useState<Crop>({ aspect });
  const [cropped, setCroppedImage] = useState<string>();
  const imageRef = useRef<HTMLImageElement>();
  const { t } = useTranslation('common');

  useEffect(() => {
    if (typeof file === 'string') {
      return;
    }

    const fileReader = new FileReader();

    fileReader.onload = () => {
      setImage(fileReader.result as string);
    };

    fileReader.readAsDataURL(file);
  }, [file]);

  useEffect(() => {
    if (typeof file === 'string') {
      return;
    }

    if (!imageRef.current) {
      return;
    }

    const canvas = document.createElement('canvas');
    const ref = imageRef.current;

    const ctx = canvas.getContext('2d');
    canvas.width = crop.width ?? 500;
    canvas.height = crop.height ?? 500;

    ctx?.drawImage(
      ref,
      crop.x ?? 0,
      crop.y ?? 0,
      crop.width ?? 0,
      crop.height ?? 0,
      0,
      0,
      crop.width ?? 0,
      crop.height ?? 0,
    );

    canvas.toBlob(
      (blob: any) => {
        if (!blob) {
          return;
        }
        setCroppedImage(window.URL.createObjectURL(blob));
      },
      'image/jpeg',
      1,
    );
  }, [crop, file]);

  const onSubmit = async () => {
    if (!cropped) {
      onConfirm();
      return;
    }
    const newFile = await fetch(cropped)
      .then((r) => r.blob())
      .then((blob) => new File([blob], (file as File).name));

    onChange(newFile);
    onConfirm();
  };

  if (!image) {
    return <></>;
  }
  return (
    <Pane display="flex" flexDirection="column">
      <Pane display="flex" justifyContent="center" width="100%">
        <ReactCrop
          ruleOfThirds
          src={image}
          crop={crop}
          onChange={setCrop}
          onImageLoaded={(e) => (imageRef.current = e)}
        />
      </Pane>
      {cropped && <img src={cropped} />}

      <Pane marginY={10} display="flex" justifyContent="flex-end">
        <Pane margin={5}>
          <Button type="secondary" onClick={onCancel}>
            {t('Cancel')}
          </Button>
        </Pane>
        <Pane margin={5}>
          <Button onClick={onSubmit}>{t('Confirm')}</Button>
        </Pane>
      </Pane>
    </Pane>
  );
}
