/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import useAuthenticationCookies from '@wgt/authentication/useAuthenticationCookies';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const withAuth = (Component: React.FC) => (args: JSX.IntrinsicAttributes) => {
  const [token] = useAuthenticationCookies();
  const history = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    (async () => {
      if (!token.access_token) {
        history.push('/auth', { pathname });
      }
    })();
  }, [token]);

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Component {...args} />;
};

export default withAuth;
