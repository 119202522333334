import { useMutation } from '@apollo/client';
import React, { useState, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import classnames from 'classnames';
import { FaTrash } from 'react-icons/fa';
import { Button } from '../../../../../../components';
import ConfirmDialog from '../../../../../../components/_molecules/ConfirmDialog';
import { useBagContext } from '../../../../../../services/bag';
import { DELETE_PRODUCT_BOX_ID } from '../../../graphql';
import { BoxId } from '@wgt/types';
import useToast from '../../../../../../hooks/useToast';

interface BoxItemProps {
  box: BoxId;
  index: number;
}

export default function BoxItem({ box, index }: BoxItemProps): JSX.Element {
  const { register, errors } = useFormContext();
  const { toast } = useToast();

  const {
    box: { remove, fields },
  } = useBagContext<{ box: { fields: BoxId[]; remove(i: number): void } }>();

  const [removeFn, { loading: removing }] = useMutation(DELETE_PRODUCT_BOX_ID, {
    variables: { id: box.id },
    onCompleted: () => {
      toast('success', 'Box ID successfully deleted.');
      remove(index);
    },
  });
  const [showRemoveConfirm, setShowRemoveConfirm] = useState<boolean>(false);

  const onConfirm = useCallback(async () => {
    if (box.id?.toString().match(/^\d+$/)) {
      await removeFn();
    } else {
      remove(index);
    }
    setShowRemoveConfirm(false);
  }, [box, index]);

  const onClickRemove = (e: React.MouseEvent) => {
    e.preventDefault();
    setShowRemoveConfirm(true);
  };

  return (
    <>
      <div
        className={classnames('d-flex align-items-center justify-content-end', {
          'mt-2': index > 0,
        })}
      >
        <input
          type="hidden"
          name={`boxes[${index}].id`}
          ref={register()}
          defaultValue={box.id}
        />
        <input
          name={`boxes[${index}].name`}
          ref={register({ required: fields.length > 1 })}
          defaultValue={box.name}
          className={classnames('form-control w-100', {
            'is-invalid': errors.boxes?.[index]?.name,
          })}
        />

        <Button
          htmlType="button"
          disabled={removing}
          onClick={onClickRemove}
          small
          type="danger"
          className="ml-2"
        >
          <FaTrash />
        </Button>
      </div>
      <ConfirmDialog
        isShown={showRemoveConfirm}
        onConfirm={onConfirm}
        title={'Are you sure you want to delete Box Id?'}
        onClose={() => setShowRemoveConfirm(false)}
        isLoading={removing}
      />
    </>
  );
}
