/* eslint-disable @typescript-eslint/no-explicit-any */
import { MineralGroup } from '@wgt/types';
import React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ValueType } from 'react-select';
import { Col, FormGroup, Input, Label } from 'reactstrap';
import { Button } from '../../../../../../../components';
import MineralGroupSelector from '../../../../../../../components/MineralGroupSelector';
import useMineralsContext from './useMinerals';

type GroupForm = MineralGroup | null;

export default function MineralsFilters(): JSX.Element {
  const { t } = useTranslation(['common']);
  const { setFilters } = useMineralsContext();

  const methods = useForm<{
    classification: GroupForm;
    group: GroupForm;
    subgroup: GroupForm;
    species: GroupForm;
    variety: GroupForm;
    series: GroupForm;
    type: string;
    formula: string;
  }>({
    defaultValues: {
      group: null,
      subgroup: null,
      species: null,
      variety: null,
      series: null,
      type: '',
    },
  });
  const { control, getValues, register, watch } = methods;

  const { classification, group, subgroup, species, variety } = watch([
    'classification',
    'group',
    'subgroup',
    'species',
    'variety',
    'series',
  ]);

  const onSearch = () => {
    const { type, formula } = getValues(['type', 'formula']);

    setFilters((oldFilters) => ({
      ...oldFilters,
      filter: {
        ...oldFilters.filter,
        type: type ? `%${type}%` : undefined,
        formula: formula ? `%${formula}%` : undefined,
      },
    }));
  };

  const handleChange = (
    e: ValueType<MineralGroup>,
    type: string,
    onChange: (...event: any[]) => void,
  ) => {
    const value = (e as unknown) as { id: string };
    onChange(e);
    setFilters((oldFilters) => ({
      ...oldFilters,
      filter: {
        ...oldFilters?.filter,
        [type]: value?.id ? { id: value.id } : undefined,
      },
    }));
  };
  return (
    <FormProvider {...methods}>
      <>
        <FormGroup row>
          <Col>
            <Controller
              name="classification"
              control={control}
              defaultValue={getValues('classification') ?? ''}
              render={({ onChange, name, value }) => (
                <MineralGroupSelector
                  label={t('Classification')}
                  onChange={(e) => handleChange(e, 'classification', onChange)}
                  value={value}
                  name={name}
                  type="classification"
                />
              )}
            />
          </Col>
          <Col>
            <Controller
              name="group"
              control={control}
              defaultValue={getValues('group') ?? ''}
              render={({ onChange, name, value }) => (
                <MineralGroupSelector
                  label={t('Group')}
                  onChange={(e) => handleChange(e, 'group', onChange)}
                  value={value}
                  name={name}
                  type="group"
                  parentId={classification?.id}
                />
              )}
            />
          </Col>
          <Col>
            <Controller
              name="subgroup"
              control={control}
              defaultValue={getValues('subgroup') ?? ''}
              render={({ onChange, name, value }) => (
                <MineralGroupSelector
                  label={t('Subgroup')}
                  onChange={(e) => handleChange(e, 'subgroup', onChange)}
                  value={value}
                  name={name}
                  type="subgroup"
                  parentId={group?.id ?? classification?.id}
                />
              )}
            />
          </Col>
          <Col>
            <Controller
              name="species"
              control={control}
              defaultValue={getValues('species') ?? ''}
              render={({ onChange, name, value }) => (
                <MineralGroupSelector
                  label={t('Species')}
                  onChange={(e) => handleChange(e, 'species', onChange)}
                  value={value}
                  name={name}
                  type="species"
                  parentId={subgroup?.id ?? group?.id ?? classification?.id}
                />
              )}
            />
          </Col>
          <Col>
            <Controller
              name="variety"
              control={control}
              defaultValue={getValues('variety') ?? ''}
              render={({ onChange, name, value }) => (
                <MineralGroupSelector
                  label={t('Variety')}
                  onChange={(e) => handleChange(e, 'variety', onChange)}
                  value={value}
                  name={name}
                  type="variety"
                  parentId={
                    species?.id ??
                    subgroup?.id ??
                    group?.id ??
                    classification?.id
                  }
                />
              )}
            />
          </Col>
          <Col>
            <Controller
              name="series"
              control={control}
              defaultValue={getValues('series') ?? ''}
              render={({ onChange, name, value }) => (
                <MineralGroupSelector
                  label={t('Series')}
                  onChange={(e) => handleChange(e, 'series', onChange)}
                  value={value}
                  name={name}
                  type="series"
                  parentId={
                    variety?.id ??
                    species?.id ??
                    subgroup?.id ??
                    group?.id ??
                    classification?.id
                  }
                />
              )}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md={3} sm={12}>
            <Label>{t('Mineral Type')}</Label>
            <Input type="text" name="type" innerRef={register()} />
          </Col>
          <Col md={3} sm={12}>
            <Label>{t('Chemical Composition')}</Label>
            <Input type="text" name="formula" innerRef={register()} />
          </Col>
          <Col className="d-flex align-items-end">
            <Button small onClick={onSearch}>
              {t('Search')}
            </Button>
          </Col>
        </FormGroup>
      </>
    </FormProvider>
  );
}
