import { gql, useQuery } from '@apollo/client';
import { InvoiceBase } from '@wgt/types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InvoiceSideSheet from '../InvoiceSideSheet';

export default function ReceiptsModalHeader({
  invoiceId,
  isOpen,
}: {
  invoiceId?: string;
  isOpen?: boolean;
}): JSX.Element {
  const { t } = useTranslation('pointOfSales');
  const [invoiceType, setInvoiceType] = useState<'invoice' | 'aml' | null>(
    null,
  );
  const { data } = useQuery<{ invoice: InvoiceBase }>(GET_INVOICE_GQL, {
    skip: !isOpen,
    variables: { id: invoiceId },
  });
  return (
    <>
      {data?.invoice.id && (
        <InvoiceSideSheet
          invoiceType={invoiceType}
          invoiceId={data?.invoice.id}
          setShown={() => setInvoiceType(null)}
        />
      )}
      <div className="modal-header d-flex align-items-center">
        <h2 className="modal-title">
          {t('Invoice Number')}:
          <br />
          {data?.invoice.id}
        </h2>
        <div>
          <button
            className="btn btn-outline-secondary mr-3"
            onClick={() => setInvoiceType('aml')}
          >
            {t('AML Statement')}
          </button>
          <button
            className="btn btn-outline-secondary mr-3"
            onClick={() => setInvoiceType('invoice')}
          >
            {t('Invoice')}
          </button>
          <h2 className="modal-title d-inline-block">{t('Receipt Count')}</h2>
        </div>
      </div>
    </>
  );
}

const GET_INVOICE_GQL = gql`
  query invoice($id: ID!) {
    invoice(id: $id) {
      id
      status
      total_paid
      total_open_payments
    }
  }
`;
