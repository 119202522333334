import React, { useContext } from 'react';

export const BagContext = React.createContext({});

interface Props<T> {
  children: JSX.Element;
  value: T;
}

export default function BagProvider<T>({
  children,
  value,
}: Props<T>): JSX.Element {
  return <BagContext.Provider value={value}>{children}</BagContext.Provider>;
}

export function useBagContext<T>(): T {
  return useContext(BagContext) as T;
}
