import React, { useMemo, useState } from 'react';
import { Shape } from '@wgt/types';
import { FiChevronRight, FiChevronDown } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import { useFieldArray } from 'react-hook-form';
import { Button } from '..';
import ShapeItem from './ShapeItem';
import './index.scss';

interface ShapeSelectorProps {
  name: string;
  options: Shape[];
  isMulti?: boolean;
  disabled?: boolean;
}

export default function ShapeSelector({
  name,
  options,
  disabled = false,
  isMulti = false,
}: ShapeSelectorProps): JSX.Element {
  const [isHidden, setIsHidden] = useState(true);
  const { t } = useTranslation(['shapes', 'common']);
  const { first, second } = useMemo(
    () =>
      options.reduce<{ first: Shape[]; second: Shape[] }>(
        (acc, curr) => {
          if (!curr.row_position) {
            return acc;
          }
          acc[curr.row_position].push(curr);
          return acc;
        },
        { first: [], second: [] },
      ),
    [options],
  );

  const { fields, append, remove } = useFieldArray({
    name,
    keyName: '__id',
  });

  const onChange = (shape: Shape) => {
    const indexOf = fields.findIndex((x) => x.id === shape.id);
    if (indexOf >= 0) {
      remove(indexOf);
    }
    if (isMulti) {
      if (indexOf < 0) {
        append({ ...shape, descriptions: [] });
      }
    } else {
      if (indexOf >= 0) {
        remove(indexOf);
      } else {
        remove();
        append({ ...shape, descriptions: [] });
      }
    }
  };

  return (
    <div className="shape-selector">
      <div className="overflow-x-auto row flex-row flex-nowrap no-gutters align-items-stretch">
        {first.map((shape) => (
          <ShapeItem
            key={shape.id}
            shape={shape}
            value={fields}
            onChange={onChange}
            isMulti={isMulti}
            disabled={disabled}
          />
        ))}
      </div>

      <Button
        iconBefore={isHidden ? FiChevronRight : FiChevronDown}
        type="blue"
        className="outline my-3"
        onClick={() => setIsHidden((x) => !x)}
      >
        {t(isHidden ? 'Show more shapes' : 'common:Hide')}
      </Button>

      {!isHidden && (
        <div className="overflow-x-auto row flex-row flex-nowrap no-gutters align-items-stretch">
          {second.map((shape) => (
            <ShapeItem
              shape={shape}
              key={shape.id}
              value={fields}
              onChange={onChange}
              isMulti={isMulti}
              disabled={disabled}
            />
          ))}
        </div>
      )}
    </div>
  );
}
