import React, { useMemo } from 'react';
import { useParams, Route } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { CategoryBase, PageIdDetail } from '@wgt/types';
import { convertJsonLayoutToTemplateFields } from '@wgt/converters';
import { GET_PAGE_ID_GQL } from '../graphql';
import FirstSection from './FirstSection';
import SecondSection from './SecondSection';
import ProductPageIdReports from '../ProductPageIdReports';
import Loader from '../../../../../components/_organisms/Loader';

export default function TemplateFields(): JSX.Element {
  const params = useParams<{ pageId: string }>();

  const { data, loading } = useQuery(GET_PAGE_ID_GQL, {
    variables: {
      pageId: params.pageId,
    },
  });

  const category: CategoryBase = useMemo(() => data?.productPageId?.category, [
    data?.productPageId?.category,
  ]);

  const pageIdDetails: PageIdDetail = useMemo(
    () =>
      ({
        shapes: data?.productPageId.shapes ?? [],
        total_price: data?.productPageId.total_price ?? 0,
        price_per_carat: data?.productPageId.price_per_carat ?? 0,
        carat_weight: data?.productPageId.carat_weight ?? 0,
      } ?? {}),
    [data?.productPageId],
  );

  const [first, second] = useMemo(() => {
    const templateLayout = category?.template?.layout;
    if (
      !templateLayout ||
      (typeof templateLayout !== 'string' &&
        Object.prototype.toString.call(templateLayout) === '[object Array]')
    ) {
      return [[], []];
    }
    const fields = category?.template?.fields ?? [];

    return Object.values(templateLayout).map((layout) =>
      convertJsonLayoutToTemplateFields({ layout, fields }),
    );
  }, [category?.template]);

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <>
          <FirstSection
            rows={first}
            pageIdDetails={pageIdDetails}
            values={data?.productPageId?.form_values}
            fields={data?.productPageId?.fields}
          />

          <Route
            path="/crm/products/:productId/pages/:pageId?"
            component={ProductPageIdReports}
          />

          <SecondSection
            rows={second}
            values={data?.productPageId?.form_values}
            fields={data?.productPageId?.fields}
          />
        </>
      )}
    </>
  );
}
