import React, { useMemo, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import CustomHeader from '../../../../components/_molecules/CustomHeader';
import useCrmRoutes from '../../../../hooks/useCrmRoutes';
import {
  CREATE_CATEGORY_GQL,
  GET_CATEGORY_GQL,
  LIST_CATEGORIES_GQL,
  UPDATE_CATEGORY_GQL,
} from '../graphql';
import SimpleForm from '../../../../components/_organisms/SimpleForm';
import Loader from '../../../../components/_organisms/Loader';
import useToast from '../../../../hooks/useToast';
import { handleCategoryTypeName } from '../../../../utils/methods';
import { CategoryType } from '@wgt/types';

const Put: React.FC = () => {
  const history = useHistory();
  const params = useParams<ParamTypes>();
  const isEdit = useMemo<boolean>(() => !!params?.id, [params]);
  const { setCustomTitle, actualRoute } = useCrmRoutes();
  const { toast } = useToast();

  useEffect(() => {
    setCustomTitle(`${isEdit ? 'Edit' : 'Create'} Category`);
  }, []);

  const { data, loading } = useQuery(GET_CATEGORY_GQL, {
    variables: {
      isEdit,
      id: params.id ?? '',
    },
  });

  if (loading && !data) {
    return <Loader />;
  }

  return (
    <div>
      <CustomHeader title={`${isEdit ? 'Edit' : 'Create'}`} showList />
      <SimpleForm
        query={GET_CATEGORY_GQL}
        queryOptions={{ variables: { isEdit, id: params.id ?? '' } }}
        parseQuery={({ category }) => ({
          ...category,
          template_id: category?.template?.id,
          category_type_id: category?.categoryType?.id,
          category_group_id: category?.categoryGroup?.id,
          product_type_id: category?.productType?.id,
        })}
        mutation={isEdit ? UPDATE_CATEGORY_GQL : CREATE_CATEGORY_GQL}
        parseMutationArgs={(category) =>
          isEdit
            ? { id: params.id, category }
            : { input: { ...category, mixed: !!category.mixed } }
        }
        mutationOptions={{
          refetchQueries: [{ query: LIST_CATEGORIES_GQL }],
          onCompleted: () => [
            toast('success'),
            history.push(`/crm/${actualRoute}`),
          ],
          onError: () => {
            toast('error');
          },
        }}
        fields={[
          {
            label: 'Name',
            name: 'name',
            required: true,
            type: 'text',
          },
          {
            label: 'Template',
            name: 'template_id',
            required: true,
            type: 'select',
            options: data?.templates?.data ?? [],
            text: 'name',
            controller: 'id',
          },
          {
            label: 'Category Type',
            name: 'category_type_id',
            required: true,
            type: 'select',
            options:
              data?.categoryTypes?.data?.map((categoryType: CategoryType) => ({
                ...categoryType,
                // JUST HANDLING A BACKEND FIX TO BE FASTER TO SHOW THIS CHANGE TO FRANCIS
                name: handleCategoryTypeName(categoryType.name),
              })) ?? [],
            text: 'name',
            controller: 'id',
          },
          {
            label: 'Category Group',
            name: 'category_group_id',
            required: true,
            type: 'select',
            options: data?.categoryGroups?.data ?? [],
            text: 'name',
            controller: 'id',
          },
          {
            label: 'Product Type',
            name: 'product_type_id',
            required: true,
            type: 'select',
            options: data?.productTypes?.data ?? [],
            text: 'name',
            controller: 'id',
          },
          {
            label: 'Mixed',
            name: 'mixed',
            type: 'switcher',
          },
          {
            label: 'Description',
            name: 'description',
            required: true,
            type: 'textarea',
          },
        ]}
      />
    </div>
  );
};

export default Put;
