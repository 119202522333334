import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../../components';

const PosActions: React.FC = () => {
  const { t } = useTranslation('pointOfSales');
  const loading = false;
  const handleCreate = (intent: string) => console.log(intent);
  const list = [];
  return (
    <>
      <Button
        isLoading={loading}
        onClick={() => handleCreate('sold')}
        disabled={!list?.length}
      >
        {t('Sold')}
      </Button>
      <Button
        isLoading={loading}
        onClick={() => handleCreate('consignment')}
        disabled={!list?.length}
      >
        {t('Consignment')}
      </Button>
    </>
  );
};

export default PosActions;
