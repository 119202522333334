import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Shape } from '@wgt/types';
import Table from '../../../../components/_organisms/Table';
import CustomHeader from '../../../../components/_molecules/CustomHeader';
import { LIST_SHAPES_GQL, DELETE_SHAPES_GQL } from '../graphql';

import Details from '../Details';
const ShapeList: React.FC = () => {
  const params = useParams<ParamTypes>();
  const { t } = useTranslation(['shapes', 'common']);

  return (
    <div>
      <CustomHeader title={t('Shapes')} />
      <Table
        columns={[
          {
            label: t('Image'),
            field: 'image_url',
            type: 'collapse',
            size: 100,
          },
          { label: t('Name'), field: 'title' },
          {
            label: t('Abbreviation'),
            field: 'abbreviations',
            type: 'multiple',
          },
          { label: t('Shape Group'), field: 'shapeGroup.title' },
          { label: t('Row'), field: 'row_position' },
          { label: t('Position'), field: 'id' },
        ]}
        query={LIST_SHAPES_GQL}
        node="shapes"
        deleteMutation={DELETE_SHAPES_GQL}
        variables={{ filter: { shapeGroup: { id: [params.id] } } }}
        getMutationArgs={(item: Shape) => ({ id: item.id })}
        getConfirmMessage={(item: Shape) =>
          t(`Confirm delete of ${item?.title}?`)
        }
        RenderDetails={Details}
        isExpandable
      />
    </div>
  );
};

export default ShapeList;
