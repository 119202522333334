import React from 'react';
import { MediaCenterItemBase } from '@wgt/types';
import Thumbnails from '../Thumbnails';
import AddButton from '../AddButton';

interface Props {
  active?: MediaCenterItemBase;
  loading: boolean;
  setActiveIndex(itemKey: number): void;
  onRemove(item: MediaCenterItemBase): Promise<void>;
  items: MediaCenterItemBase[];
  hasThumbnail?: boolean;
}

const MediaCenterBottom: React.FC<Props> = ({
  hasThumbnail,
  loading,
  onRemove,
  setActiveIndex,
  active,
  items,
}) => {
  return (
    <div className="media-center-bottom">
      <div className="nav-button">
        {/* In the future I bealive that we will use the icons to move scroll */}
      </div>
      <div className="thumbnails-holder">
        <AddButton />
        <Thumbnails
          items={items}
          active={active}
          setActiveIndex={setActiveIndex}
          remove={onRemove}
          loading={loading}
          hasThumbnail={hasThumbnail}
        />
      </div>
      <div className="nav-button">
        {/* In the future I bealive that we will use the icons to move scroll */}
      </div>
    </div>
  );
};

export default MediaCenterBottom;
