import React from 'react';

import { useProductUpdate } from '../../Provider';
import MovePageIDToSKU from '../../../../../../components/MovePageIDToSKU';

interface SKUProps {
  inline?: boolean;
}

const SKU: React.FC<SKUProps> = () => {
  const { pages } = useProductUpdate();
  if (!pages.active) {
    return <></>;
  }

  return <MovePageIDToSKU pageId={pages.active} />;
};

export default SKU;
