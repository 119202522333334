import { gql, useQuery } from '@apollo/client';
import { InvoiceBase } from '@wgt/types';
import { Pane } from 'evergreen-ui';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { Form } from 'reactstrap';
import Button from '../../Button';
import useReceipt from '../useReceipt';

export default function ChangeInvoiceContext(): JSX.Element {
  const { t } = useTranslation(['pointOfSales', 'common']);
  const params = useParams<ParamTypes>();
  const { handleSubmit, control } = useForm({
    defaultValues: { invoice: { id: '' } },
  });
  const { setSelectedInvoice } = useReceipt();

  const { data } = useQuery<{ invoices: { data: InvoiceBase[] } }>(
    SEARCH_INVOICES_GQL,
    {
      variables: {
        pointOfSaleId: params.id,
      },
    },
  );

  const onSubmit = handleSubmit(({ invoice }) =>
    setSelectedInvoice(invoice?.id),
  );
  return (
    <Form className="d-flex align-items-center w-100" onSubmit={onSubmit}>
      <Pane width="60%" marginX={10}>
        <Controller
          name="invoice"
          control={control}
          render={({ onChange }) => (
            <Select
              options={data?.invoices.data}
              getOptionLabel={(e) => e.id}
              getOptionValue={(e) => e.id}
              onChange={onChange}
            />
          )}
        />
      </Pane>

      <Button small htmlType="submit">
        {t('View')}
      </Button>
    </Form>
  );
}

const SEARCH_INVOICES_GQL = gql`
  query invoices($pointOfSaleId: ID!) {
    invoices(first: 10, filter: { point_of_sale_id: [$pointOfSaleId] }) {
      data {
        id
        status
      }
    }
  }
`;
