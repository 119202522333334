import React from 'react';
import SidebarComponents from './SidebarComponents';
import ActiveItem from './ActiveItem';
import useTemplateFieldsContext from '../../../../../../hooks/useTemplateFieldsContext';

const SidebarComponent: React.FC = () => {
  const { active } = useTemplateFieldsContext();

  return (
    <div className="template-put-sidebar">
      <div className="template-put-sidebar-content">
        {active ? <ActiveItem /> : <SidebarComponents />}
      </div>
    </div>
  );
};

export default SidebarComponent;
