import React from 'react';
import { FormGroup, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';

export default function FavouriteBody(): JSX.Element {
  const { t } = useTranslation('pointOfSales');
  return (
    <div className="favourite-body rounded p-1">
      <FormGroup className="simple-input flex-grow-1 d-flex flex-column mb-0">
        <Input
          type="textarea"
          name="policyText"
          id="policyText"
          className="flex-grow-1 shadow"
          placeholder={t('Enter the policy text...')}
        />
      </FormGroup>
    </div>
  );
}
