import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SidebarPane from '../../../components/SidebarPane';
import { Button } from '../../../components';
import ManageAlert from './ManageAlert';

export default function LearnToUse(): JSX.Element {
  const [openSidebar, setOpenSidebar] = useState<boolean>(false);
  const { t } = useTranslation('learnToUse');

  return (
    <>
      <Button
        htmlType="button"
        type="link"
        className="btn btn-outline-secondary btn-outline-learn-to-use bg-white"
        onClick={() => setOpenSidebar(true)}
      >
        {t('Learn to Use')}
      </Button>

      <SidebarPane
        isShown={openSidebar}
        setShown={setOpenSidebar}
        size={850}
        header={t('Learn to Use Manual')}
      >
        <ManageAlert />
      </SidebarPane>
    </>
  );
}
