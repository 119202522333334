import React, { useContext, useMemo } from 'react';
import { TemplateFieldsContext } from '../../../../../../../hooks/useTemplateFieldsContext';
import { FieldComponentProps } from '../../../../types';
import classnames from 'classnames';

const FieldComponent: React.FC<FieldComponentProps> = ({ field }) => {
  const { select, active } = useContext(TemplateFieldsContext);

  const selected = useMemo(() => active?.id === field.id, [active, field]);

  return (
    <div
      onClick={() => select(field)}
      className={classnames('template-put-content-field-item', {
        selected,
      })}
    >
      <b>{`${field.formValues?.label || 'Waiting infos...'}`}</b>
      <div>
        Stock Component: <span>{field.name} </span>
        <br />
        Property:<span> {field.propertyName} </span>
        <br />
        Data Type: <span>{field.dataTypeName} </span>
      </div>
    </div>
  );
};

export default FieldComponent;
