import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  GIVE_PERMISSION_TO_USER_GQL,
  REVOKE_PERMISSION_FROM_USER_GQL,
} from '../../graphql';
import BtnLoading from '../../../../../components/_molecules/BtnLoading';
import { Checkbox } from 'evergreen-ui';
import { PermissionProps } from '../../types';

const PermissionComponent: React.FC<PermissionProps> = ({
  user,
  permission,
  hasPermission,
}) => {
  const [isChecked, setChecked] = useState(false);

  const [addPermission, { loading: givingPermission }] = useMutation(
    GIVE_PERMISSION_TO_USER_GQL,
    {
      variables: {
        id: user?.id,
        permission_id: permission?.id,
      },
      onCompleted: () => setChecked(true),
    },
  );
  const [revokePermission, { loading: revokingPermission }] = useMutation(
    REVOKE_PERMISSION_FROM_USER_GQL,
    {
      variables: {
        id: user?.id,
        permission_id: permission?.id,
      },
      onCompleted: () => setChecked(false),
    },
  );

  const onClick = () => {
    if (!isChecked) {
      addPermission();
    } else {
      revokePermission();
    }
  };

  useEffect(() => {
    setChecked(!!hasPermission);
  }, [hasPermission]);

  if (givingPermission || revokingPermission) {
    return <BtnLoading />;
  }

  if (!permission) {
    return <></>;
  }

  return (
    <Checkbox label={permission.name} checked={isChecked} onChange={onClick} />
  );
};

export default PermissionComponent;
