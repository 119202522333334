import React, { memo, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { uid } from 'uid';
import { useTranslation } from 'react-i18next';
import { CustomInput } from 'reactstrap';

export const PROPERTY_VALUE_TYPES = [
  {
    id: 'is_seive_chart',
    name: 'Sieve Chart',
  },
  {
    id: 'is_minerals',
    name: 'Minerals',
  },
  {
    id: 'is_other',
    name: 'Other',
  },
];

export default memo(function PropertyValueTypeSelector(): JSX.Element {
  const { t } = useTranslation('property');
  const id = useMemo(() => uid(), []);
  const { getValues } = useFormContext();

  const defaultValue = useMemo(() => {
    const values = getValues(PROPERTY_VALUE_TYPES.map((x) => x.id));
    return Object.keys(values).find((x) => values[x]) ?? '';
  }, []);

  return (
    <Controller
      name="type"
      defaultValue={defaultValue}
      render={({ onChange, value }) => (
        <>
          {PROPERTY_VALUE_TYPES.map((type) => (
            <div className="d-block mb-3" key={type.id}>
              <CustomInput
                type="switch"
                id={`${type.id}_${id}`}
                label={t(type.name)}
                onChange={(e) =>
                  e.target.checked ? onChange(type.id) : onChange('')
                }
                checked={value === type.id}
              />
            </div>
          ))}
        </>
      )}
    />
  );
});
