import React, { useState } from 'react';
import { Pane, Tab, Tablist } from 'evergreen-ui';
import { Button, SimpleSwitcher } from '../../../../../components';
import classnames from 'classnames';
import AdvancedTab from './AdvancedTab';
import PageTab from './PageTab';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import { TemplateLayoutRow } from '@wgt/types';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LearnToUse from '../../../LearnToUse';

export interface SearchTabsProps {
  row: TemplateLayoutRow[];
  loading?: boolean;
}
export interface ArrowProps {
  open?: boolean;
}

const Arrow: React.FC<ArrowProps> = ({ open }) =>
  open ? <FaAngleUp /> : <FaAngleDown />;

const SearchTabs: React.FC<SearchTabsProps> = ({ row, loading }) => {
  const [activeIndex, setActiveIndex] = useState<number | undefined>();
  const { t } = useTranslation('search');
  const params = useParams<{ category: string }>();
  return (
    <Pane className="search-form-holder">
      <Pane className="search-form-tablist d-flex align-items-center justify-content-between p-2 mb-4 ">
        <Tablist>
          <Tab
            className={classnames({ active: activeIndex === 0 })}
            isSelected={activeIndex === 0}
            onSelect={() =>
              setActiveIndex((activeTab) => (activeTab === 0 ? undefined : 0))
            }
            aria-controls={`panel-0`}
          >
            <Arrow open={activeIndex === 0} />
            {t('Available')}
          </Tab>
          <Tab
            className={classnames({ active: activeIndex === 1 })}
            isSelected={activeIndex === 1}
            onSelect={() =>
              setActiveIndex((activeTab) => (activeTab === 1 ? undefined : 1))
            }
            aria-controls={`panel-1`}
          >
            <Arrow open={activeIndex === 1} /> {t('IDs')}
          </Tab>
          <Tab
            className={classnames({ active: activeIndex === 2 })}
            isSelected={activeIndex === 2}
            onSelect={() =>
              setActiveIndex((activeTab) => (activeTab === 2 ? undefined : 2))
            }
            aria-controls={`panel-2`}
          >
            <Arrow open={activeIndex === 2} /> {t('Contact')}
          </Tab>
        </Tablist>
        <Pane className="d-flex align-items-center ">
          <SimpleSwitcher
            field="myStock"
            label="My Stock"
            className="d-flex align-items-center mb-0"
          />
          <div className="ml-3">
            <LearnToUse />
          </div>
          <Link
            className="btn btn-outline-secondary ml-4"
            to={`/crm/import/category/${params.category}`}
          >
            {t('Import')}
          </Link>
          <Button
            htmlType="submit"
            className="ml-4"
            isLoading={loading}
            disabled={loading}
          >
            {t('Search')}
          </Button>
        </Pane>
      </Pane>
      <Pane className="p-3">
        <Pane
          role="tabpanel"
          aria-labelledby={'available'}
          aria-hidden={activeIndex !== 0}
          display={activeIndex === 0 ? 'block' : 'none'}
        >
          <AdvancedTab row={row} />
        </Pane>
        <Pane
          role="tabpanel"
          aria-labelledby={'pageID'}
          aria-hidden={activeIndex !== 1}
          display={activeIndex === 1 ? 'block' : 'none'}
        >
          <PageTab />
        </Pane>
        <Pane
          role="tabpanel"
          aria-labelledby={'contact'}
          aria-hidden={activeIndex !== 2}
          display={activeIndex === 2 ? 'block' : 'none'}
        >
          {`@ todo`}
        </Pane>
      </Pane>
    </Pane>
  );
};

export default SearchTabs;
