import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import Title from '../Title';
import Text from '../Text';
import useProfileContext from '../../hooks/useProfile';
import NoProfilePhoto from '../../assets/no-profile-photo.jpg';

export default function ProfileFirmCard(): JSX.Element {
  const { t } = useTranslation('profile');
  const { user } = useProfileContext();
  return (
    <div className="card p-4  mt-3">
      <Row>
        <Col md="2">
          <img src={NoProfilePhoto} className="w-100" />
        </Col>
        <Col>
          <Title heading="h4" className="mb-5">
            {user?.firm.name}
          </Title>
          <Text>{user?.firm.slogan}</Text>
        </Col>
      </Row>
      <Row className="mt-4 mg-5">
        <Col>
          <Text>{user?.firm.description}</Text>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col className="d-flex flex-column flex-1 justify-content-between">
          <Text bold>{t('Location')}</Text>
          <Text>{user?.firm.address?.address1}</Text>
          <Text>{user?.firm.address?.address2}</Text>
          <Text>{user?.firm.address?.country.name}</Text>
          <Text>{user?.firm.address?.state}</Text>
          <Text>{user?.firm.address?.city}</Text>
          <Text>{user?.firm.address?.postal_code}</Text>
        </Col>
        <Col className="d-flex flex-column">
          <Text bold>{t('Toll Free Phone')}</Text>
          <Text>{user?.firm.toll_free_phone}</Text>
          <Text className="mt-4" bold>
            {t('Fax')}
          </Text>
          <Text>{user?.firm.fax}</Text>
          <Text className="mt-4" bold>
            {t('E-mail')}
          </Text>
          <Text>{user?.firm.email}</Text>
        </Col>
        <Col className="d-flex flex-column">
          <Text bold>{t('Local Phone')}</Text>
          <Text>{user?.firm.local_phone}</Text>
        </Col>
      </Row>
    </div>
  );
}
