import React from 'react';
import { Text as TextUI } from 'evergreen-ui';
import classnames from 'classnames';

interface Props {
  type?: 'link' | 'muted' | 'text' | undefined;
  className?: string;
  bold?: boolean;
  color?: string;
}

const Text: React.FC<Props> = ({ type, className, bold, color, ...props }) => (
  <TextUI
    {...props}
    className={classnames(className, {
      'text-link': type === 'link',
      'text-muted': type === 'muted',
      'font-weight-bold': bold,
    })}
    style={{ color }}
  />
);

export default Text;
