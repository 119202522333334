import React from 'react';
import SKUPrice from './SKUPrice';
import CaratWeight from './CaratWeight';
import { useFormContext } from 'react-hook-form';
import setCurrencyMaskInput from '../../../../../helpers/CurrencyMaskInput';

const Total: React.FC = () => {
  const { watch } = useFormContext();
  const watchTotals = watch(['total_price', 'total_carat_weight']);

  return (
    <>
      <SKUPrice
        displayText={
          watchTotals?.total_price
            ? setCurrencyMaskInput(
                watchTotals?.total_price.toFixed(2).toString(),
              )
            : '--'
        }
      />
      <CaratWeight
        displayText={
          watchTotals?.total_carat_weight
            ? setCurrencyMaskInput(
                watchTotals?.total_carat_weight.toFixed(2).toString(),
              )
            : '--'
        }
      />
    </>
  );
};

export default Total;
