import { NavbarPageBase } from '@wgt/types';
import React, { useMemo } from 'react';
import { Collapse } from 'reactstrap';
import { CollapseProvider, useCollapseContext } from '../../services/collapse';
import classnames from 'classnames';
import { useBagContext } from '../../services/bag';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const RenderLink: React.FC<{ page: NavbarPageBase; isChildren?: boolean }> = ({
  page,
  isChildren,
}) => {
  const { t } = useTranslation('menus');
  const { setShown } = useBagContext<{ setShown(isShown: boolean): void }>();

  return page.onClick ? (
    <button
      onClick={() => [setShown(false), page.onClick?.()]}
      className={classnames({
        'child-link': isChildren,
      })}
    >
      {t(page.title)}
    </button>
  ) : (
    <Link
      to={page.url ?? ''}
      onClick={() => setShown(false)}
      className={classnames({
        'child-link': isChildren,
      })}
    >
      {t(page.title)}
    </Link>
  );
};

const SidebarExpandItem: React.FC<{ page: NavbarPageBase }> = ({ page }) => {
  const { active, set } = useCollapseContext();
  const { t } = useTranslation('menus');
  const isActive = useMemo<boolean>(() => active === page.name, [
    page.name,
    active,
  ]);

  if (!page.children?.length) {
    return <RenderLink page={page} />;
  }
  return (
    <>
      <button
        onClick={() => set(page.name)}
        className={classnames({ active: isActive })}
      >
        {t(page.title)}
      </button>

      <Collapse isOpen={isActive}>
        <div className="ml-3 mt-2 d-flex">
          <div>
            {page.children.map((pageItem, key) => (
              <RenderLink
                page={pageItem}
                key={`pageItem-title-${pageItem.name}${key}`}
                isChildren
              />
            ))}
          </div>
        </div>
      </Collapse>
    </>
  );
};

const SidebarExpandComponent: React.FC<{
  pages: NavbarPageBase[];
  title?: string;
}> = ({ pages, title }) => {
  return (
    <CollapseProvider options={pages.map((x) => x.name ?? '') ?? []}>
      <div className="navbar-expandable-container">
        {title && <p>{title}</p>}
        <div
          className={classnames({
            'ml-5': !!title,
          })}
        >
          {pages.map((pageItem, key) => (
            <div
              className="navbar-expandable-container-item"
              key={`page-holder-${key}`}
            >
              <SidebarExpandItem page={pageItem} />
            </div>
          ))}
        </div>
      </div>
    </CollapseProvider>
  );
};

export default SidebarExpandComponent;
