import React from 'react';
import './styles.scss';
import classnames from 'classnames';

interface Props {
  loading?: boolean;
  round?: boolean;
  small?: boolean;
}

const Loader: React.FC<Props> = ({ small, round }) => {
  if (round) {
    return <div className={classnames('round-loader', { small })} />;
  }
  return (
    <div className={classnames('bouncing-loader', { small })}>
      <div />
      <div />
      <div />
    </div>
  );
};

export default Loader;
