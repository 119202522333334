import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'reactstrap';
import useStatementsContext from './useStatements';
import StatementResultRow from './StatementResultRow';
import Loader from '../../../components/_organisms/Loader';
import { PaymentStatus } from '@wgt/types';

export default function StatementsResults(): JSX.Element {
  const { t } = useTranslation('statements');
  const { results, searching } = useStatementsContext();
  const sums = useMemo(
    () =>
      results?.reduce(
        (acc, result) => {
          return {
            aml:
              acc.aml +
                result.payments.reduce(
                  (total, payment) => total + payment.amount ?? 0,
                  0,
                ) ?? 0,
            fee:
              acc.fee +
                result.payments
                  .filter(
                    (payment) =>
                      PaymentStatus[payment.status.toString()] ===
                      PaymentStatus.paid,
                  )
                  .reduce((total, payment) => total + payment.amount ?? 0, 0) ??
              0,
          };
        },
        { aml: 0, fee: 0 },
      ),
    [results],
  );
  return (
    <Table striped>
      <thead>
        <tr>
          <td>{t('Firm')}</td>
          <td>{t('Invoice Number')}</td>
          <td>{t('Date')}</td>
          <td>{t('Transaction')}</td>
          <td>{t('AML Price (USD)')}</td>
          <td>{t('Fee (USD)')}</td>
        </tr>
      </thead>
      <tbody>
        {searching && (
          <tr>
            <td colSpan={6}>
              <Loader round />
            </td>
          </tr>
        )}
        {!searching &&
          results?.map((invoice, key) => (
            <StatementResultRow
              key={`invoice-result-${key}`}
              invoice={invoice}
            />
          ))}
      </tbody>
      {!searching && !!results?.length && (
        <tfoot>
          <tr>
            <td colSpan={3} />
            <td>{t('SUM')}</td>
            <td>{`${sums?.aml} USD`}</td>
            <td>{`${sums?.fee} USD`}</td>
          </tr>
        </tfoot>
      )}
    </Table>
  );
}
