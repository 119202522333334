import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Button, Form, FormGroup } from 'reactstrap';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaSpinner } from 'react-icons/fa';

export interface FormValues {
  email: string;
  password: string;
}

interface FormProps {
  handleSignIn: SubmitHandler<FormValues>;
  isSubmitting: boolean;
  hint: string | null;
}

const LoginForm: React.FC<FormProps> = ({
  handleSignIn,
  isSubmitting,
  hint,
}) => {
  const { register, handleSubmit, errors } = useForm();
  const { t } = useTranslation('authentication');
  return (
    <Form onSubmit={handleSubmit(handleSignIn)}>
      <FormGroup>
        <label htmlFor="email">{t('email.label')}</label>
        <input
          className={`form-control ${errors?.email && 'is-invalid'}`}
          id="email"
          name="email"
          placeholder={t('email.placeholder')}
          ref={register({
            required: true,
          })}
          defaultValue=""
          aria-invalid={errors?.email ? 'true' : 'false'}
        />
      </FormGroup>
      <FormGroup>
        <label htmlFor="password">{t('password.label')}</label>
        <input
          className={`form-control ${errors?.password && 'is-invalid'}`}
          id="password"
          name="password"
          type="password"
          ref={register({ required: true })}
          placeholder={t('password.placeholder')}
          aria-invalid={errors?.password ? 'true' : 'false'}
        />
        <Link to="auth/forgot" className="forgot-link">
          {t('forgot-password')}
        </Link>
      </FormGroup>
      <Button
        type="submit"
        disabled={isSubmitting}
        size="sm"
        color="success"
        block
      >
        {t(`login.${isSubmitting ? 'loading' : 'text'}`)}
        &nbsp;
        {isSubmitting && <FaSpinner className="fa-spin" />}
      </Button>
      {hint && <p>{hint}</p>}
    </Form>
  );
};

export default LoginForm;
