import React, { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { EmployeeJoinRequest } from '@wgt/types';
import { Container } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import { Title, Line, AlertMessage } from '../../../../components';
import Loader from '../../../../components/_organisms/Loader';
import { LIST_EMPLOYEE_JOIN_REQUESTS } from '../graphql';
import Item from './Item';
import useCrmRoutes from '../../../../hooks/useCrmRoutes';
import useAnchor from '../../../../hooks/useAnchor';

export default function List(): JSX.Element {
  const { t } = useTranslation(['hr', 'common']);
  const { actualRoute, setCustomRoutes, routes } = useCrmRoutes();
  const anchor = useAnchor();

  const location = useLocation();

  useEffect(() => {
    setCustomRoutes([
      { name: t('Human Resources'), path: `/crm/${actualRoute}` },
      {
        name: t('Request to Join Results'),
        path: `/crm/${actualRoute}/request-to-join`,
      },
    ]);
  }, [routes]);

  const { data, loading } = useQuery<{
    employeeJoinRequests: { data: EmployeeJoinRequest[] };
  }>(LIST_EMPLOYEE_JOIN_REQUESTS);

  const employees = useMemo(() => data?.employeeJoinRequests?.data ?? [], [
    data?.employeeJoinRequests?.data,
  ]);

  useEffect(() => {
    if (location.hash) {
      setTimeout(anchor.bind(null, location.hash.substr(1)), 300);
    }
  }, [location.hash]);

  return (
    <Container fluid className="request-to-join" id="request-to-join-results">
      <Title>{t('Request to Join Results')}</Title>
      <Line />

      {!!loading && <Loader />}
      {!loading &&
        (employees.length ? (
          employees.map((employee: EmployeeJoinRequest, index: number) => (
            <Item employee={employee} key={`employee-item-${index}`} />
          ))
        ) : (
          <AlertMessage>{t('common:There are no records.')}</AlertMessage>
        ))}
    </Container>
  );
}
