import { SearchLayoutResultColumn, UrgencyType } from '@wgt/types';

interface ExportProps {
  id: number;
  name: string;
}
export type ProductType = ExportProps;
export type CategoryType = ExportProps;

export const [
  { id: PRODUCT_TYPE_SINGLE_ID },
  { id: PRODUCT_TYPE_PARCEL_ID },
] = [
  { id: 1, name: 'Single' },
  { id: 2, name: 'Parcel' },
] as ProductType[];

export const [
  { id: CATEGORY_TYPE_FINISHED_ID },
  { id: CATEGORY_TYPE_STONE_ID },
] = [
  { id: 1, name: 'Finished Product' },
  { id: 2, name: 'Stone' },
] as CategoryType[];

export const [
  { id: PARCEL_TYPE_MULTIPLE_ID },
  { id: PARCEL_TYPE_PER_SHAPE_ID },
  { id: PARCEL_TYPE_PER_DIAMOND_ID },
] = [
  { id: 1, name: 'Add Multiple Shapes' },
  { id: 2, name: 'Add Per Shape' },
  { id: 3, name: 'Add Per diamond' },
] as CategoryType[];

export const COMPOUND_LIST = ['1', '7', '14', '15', '30', '120', '182', '365'];

export const NOT_COMPOUND_LIST = [
  '0',
  '1',
  '7',
  '14',
  '15',
  '30',
  '120',
  '182',
  '365',
  '-1',
];
export const URGENCIES = Object.freeze([
  {
    key: 'high',
    title: 'High',
    color: '#8EDD11',
    backgroundColor: 'rgba(142, 221, 17, 0.24)',
  },
  {
    key: 'medium',
    title: 'Medium',
    color: '#FD6801',
    backgroundColor: 'rgba(253, 104, 1, 0.24)',
  },
  {
    key: 'normal',
    title: 'Normal',
    color: '#1E87F6',
    backgroundColor: 'rgba(30, 135, 246, 0.24)',
  },
  {
    key: 'less-urgent',
    title: 'Less urgent',
    color: '#DBFF00',
    backgroundColor: 'rgba(219, 255, 0, 0.24)',
  },
  {
    key: 'no-urgency',
    title: 'No urgency',
    color: '#CA26C3',
    backgroundColor: 'rgba(202, 38, 195, 0.24)',
  },
]) as UrgencyType[];

export const SEARCH_HEADER_COLUMN_PRODUCT_OPTIONS: SearchLayoutResultColumn[] = [
  {
    type: 'productField',
    title: 'SKU',
    selected: false,
    key: 'sku',
  },
  {
    type: 'productField',
    title: 'Price',
    selected: false,
    key: 'total_price',
  },
];
