import React, { useMemo, useState, useEffect } from 'react';
import { ExpandedDropdownOptions } from '@wgt/types';
import { FiChevronDown } from 'react-icons/fi';
import { Popover, Button } from 'evergreen-ui';
import PropertiesContent from './PropertiesContent';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import classnames from 'classnames';
import { getProp } from '../../../utils/methods';
import './style.scss';

interface Props {
  label: string;
  field: string;
  values: ExpandedDropdownOptions[];
  required?: boolean;
  isMulti?: boolean;
  disabled?: boolean;
  className?: string;
}

export default function LayoutComponentDropdownPopover({
  field,
  className,
  label,
  values,
  required,
  disabled,
}: Props): JSX.Element {
  const { t } = useTranslation('common');
  const { errors, watch, setError, clearErrors, formState } = useFormContext();
  const fieldValues = watch(field, {});

  const totalSelected = !fieldValues
    ? 0
    : Object.values(fieldValues)
        .map(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (option: any) =>
            option?.variations
              ? Object.values(option?.variations).filter(
                  (value) => value === true,
                ).length
              : 0,
        )
        .reduce((acc, total) => acc + total, 0);

  const { isSubmitting } = formState;

  useEffect(() => {
    if (required) {
      if (isSubmitting && !totalSelected) {
        setError(field, { type: 'required' });
      }
      if (totalSelected) {
        clearErrors(field);
      }
    }
  }, [field, totalSelected, isSubmitting, required]);

  const [closeOnExternalClick, setCloseOnExternalClick] = useState(true);

  const toggleCloseOnExternalClick = () => {
    setCloseOnExternalClick(!closeOnExternalClick);
  };

  const numberColumns = useMemo(() => {
    return values.length === 1 ? 1 : values.length / 3 >= 3 ? 3 : 2;
  }, [values]);

  return (
    <div className="ml-3 mb-3 dropdown-popover-select">
      <Popover
        position="bottom-left"
        shouldCloseOnExternalClick={closeOnExternalClick}
        statelessProps={{
          className: 'dropdown-popover-dialog',
        }}
        {...(disabled && { isShown: false })}
        content={
          <PropertiesContent
            values={values}
            numberColumns={numberColumns}
            label={label}
            toggleCloseOnExternalClick={toggleCloseOnExternalClick}
            field={field}
          />
        }
      >
        <div
          className={classnames(className, {
            'is-invalid': getProp(errors, field) || !!errors?.[field],
          })}
        >
          <span>{label}</span>
          <Button
            className="button combo box w-100"
            type="button"
            disabled={disabled}
          >
            <div className="d-flex justify-content-between w-100">
              <div>
                {totalSelected
                  ? `${totalSelected} ${t('Selected')}`
                  : t('Select')}
              </div>
              <div>
                <FiChevronDown />
              </div>
            </div>
          </Button>
        </div>
      </Popover>
    </div>
  );
}
