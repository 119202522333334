import React from 'react';
import { Pane } from 'evergreen-ui';
import { FaTimes } from 'react-icons/fa';
import { useBagContext } from '../../../../../services/bag';
import classnames from 'classnames';

import SearchInputField from '../../CommonForm/SearchInputField';
import { CrateId } from '@wgt/types';

const BoxIds: React.FC = () => {
  const {
    crate: { fields, remove },
  } = useBagContext<{
    crate: { fields: CrateId[]; remove(index: number): void };
  }>();

  return (
    <div className="simple-input">
      <div className="d-flex flex-column">
        {fields.map((_, index) => (
          <Pane key={`crate-${index}`} className="d-flex">
            <SearchInputField
              className="flex-1"
              label={`Crate ID ${index > 0 ? index : ''}`}
              fields={[
                {
                  field: `crates[${index}].name`,
                  placeholder: 'Identification',
                },
              ]}
            />
            <FaTimes
              onClick={() => remove(index)}
              className={classnames({
                hidden: fields.length === 1,
              })}
            />
          </Pane>
        ))}
      </div>
    </div>
  );
};

export default BoxIds;
