import React, { useCallback, useEffect } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Collapse } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { FiChevronDown, FiChevronLeft } from 'react-icons/fi';
import { IconButton } from 'evergreen-ui';
import { Title, Text, SimpleTextArea } from '../';
import FormContent from './FormContent';
import CompanyOptionSelect from './CompanyOptionSelect';
import useToggler from '@wgt/shared/hooks/useToggler';
import useWorkOrderItemFormContext from './useWorkOrderItemContext';
import { FormProvider, useForm } from 'react-hook-form';
import WorkerInfo from './WorkerInfo';
import moment from 'moment';

export default function WorkOrderItemFormData(): JSX.Element {
  const [collapse, toggle] = useToggler(true);
  const [{ activeWorkOrderItem }] = useWorkOrderItemFormContext();
  const { t } = useTranslation(['workOrder', 'common']);

  const getInitialValues = useCallback(() => {
    if (!activeWorkOrderItem) {
      return {};
    }

    const start = activeWorkOrderItem.start
      ? new Date(moment(activeWorkOrderItem.start).format('Y-M-D HH:mm'))
      : '';

    const end = activeWorkOrderItem.end
      ? new Date(moment(activeWorkOrderItem.end).format('Y-M-D HH:mm'))
      : '';

    return {
      ...activeWorkOrderItem,
      executiveBuyer: activeWorkOrderItem.executiveBuyer,
      worker: activeWorkOrderItem.worker,
      details: activeWorkOrderItem.details,
      hour_price: activeWorkOrderItem.hour_price,
      start,
      end,
    };
  }, [activeWorkOrderItem]);

  const methods = useForm({
    defaultValues: getInitialValues(),
  });

  const { reset } = methods;
  useEffect(() => {
    reset(getInitialValues());
  }, [activeWorkOrderItem]);

  return (
    <FormProvider {...methods}>
      <CompanyOptionSelect />
      <div className="job-order-worker">
        <Card className="mt-4">
          <CardHeader className="bg-white">
            <Row>
              <WorkerInfo />
              <Col
                xs={{ size: 3, order: 1 }}
                lg={{ size: 1, order: 2 }}
                className="d-flex align-items-center px-0"
              >
                <div className="d-flex flex-row align-items-center justify-content-end w-100">
                  <Text className="text-muted">{t('common:Time')}</Text>
                  <IconButton
                    appearance="minimal"
                    icon={collapse ? FiChevronDown : FiChevronLeft}
                    iconSize={26}
                    onClick={toggle}
                    type="button"
                    className="icon-collapse-job-order-worder"
                  />
                </div>
              </Col>
            </Row>
          </CardHeader>
          <Collapse isOpen={collapse}>
            <CardBody className="p-0">
              <Row className="bg-light p-3" noGutters>
                <Col className="text-right" xs="11">
                  <strong className="color-wgt-primary">{t('Add Time')}</strong>
                </Col>
              </Row>

              <FormContent />
            </CardBody>
          </Collapse>
        </Card>
        <div className="d-flex justify-content-between py-2">
          <Title heading="h4">{t('common:Details')}</Title>
        </div>

        <SimpleTextArea
          field="details"
          placeholder={t('common:Information')}
          className="textarea-detail-height mb-3"
        />
      </div>
    </FormProvider>
  );
}
