import { gql, useApolloClient, useQuery } from '@apollo/client';
import { mergeDeep, Reference } from '@apollo/client/utilities';
import { PropertyValue } from '@wgt/types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, CustomInput, FormGroup } from 'reactstrap';
import useInfiniteScroll from '../../../../../../../../hooks/useInfiniteScroll';
import useTemplateFieldsContext from '../../../../../../../../hooks/useTemplateFieldsContext';
const ATTACH = gql`
  mutation attachValuesToTemplateField($id: ID!, $property_value_ids: [ID!]!) {
    attachValuesToTemplateField(
      id: $id
      property_value_ids: $property_value_ids
    ) {
      message
    }
  }
`;

const DETACH = gql`
  mutation detachValuesToTemplateField($id: ID!, $property_value_ids: [ID!]!) {
    detachValuesToTemplateField(
      id: $id
      property_value_ids: $property_value_ids
    ) {
      message
    }
  }
`;
function PropertyValueForm({
  value,
  checked,
}: {
  value: PropertyValue;
  checked: boolean;
}): JSX.Element {
  const client = useApolloClient();
  const { active } = useTemplateFieldsContext();

  const onChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const isAttach = e.target.checked;
      await client.mutate({
        mutation: isAttach ? ATTACH : DETACH,
        variables: {
          id: active?.id,
          property_value_ids: [value.id],
        },
        update: (cache) => {
          cache.modify({
            fields: {
              templateField: (existing, { readField }) => {
                if (isAttach) {
                  const newPropertyValue = cache.writeFragment({
                    data: active,
                    id: `PropertyValue:${active?.id}`,
                    fragment: gql`
                      fragment propertyValue on PropertyValue {
                        id
                        name
                      }
                    `,
                  });

                  return {
                    ...existing,
                    values: [...existing.values, newPropertyValue],
                  };
                }

                return {
                  ...existing,
                  values: existing.values.filter(
                    (ref: Reference) => value.id !== readField('id', ref),
                  ),
                };
              },
            },
          });
        },
      });
    },
    [client],
  );

  return (
    <FormGroup row className="mb-1 ml-1">
      <CustomInput
        type="switch"
        name={value.key}
        id={value.key}
        onChange={onChange}
        checked={checked}
        label={value.name}
      />
    </FormGroup>
  );
}

interface QueryResult {
  propertyValues: {
    data: PropertyValue[];
  };
}

export default function PropertyValuesTab(): JSX.Element {
  const { active } = useTemplateFieldsContext();
  const { t } = useTranslation('template');

  const { data, loading, fetchMore } = useQuery(
    gql`
      query propertyValuesTemplateField(
        $filter: PropertyValueFilterInput
        $offset: Int
        $limit: Int
        $templateId: ID!
      ) {
        propertyValues(filter: $filter, page: $offset, first: $limit) {
          data {
            id
            name
            key
          }
          paginatorInfo {
            currentPage
            hasMorePages
          }
        }
        templateField(id: $templateId) {
          values {
            id
            name
          }
        }
      }
    `,
    {
      nextFetchPolicy: 'cache-first',
      skip: !active?.id || !active?.formValues.property_id,
      variables: {
        offset: 1,
        templateId: active?.id,
        filter: {
          property_id: active?.formValues.property_id,
        },
      },
    },
  );

  const trigger = useCallback(
    () =>
      fetchMore({
        variables: {
          offset: 1 + data?.propertyValues.paginatorInfo.currentPage,
        },
        updateQuery: (prev: QueryResult, { fetchMoreResult }) =>
          mergeDeep(prev, fetchMoreResult, {
            propertyValues: {
              data: [
                ...prev.propertyValues.data,
                ...(fetchMoreResult?.propertyValues.data ?? []),
              ],
            },
          }),
      }),
    [data],
  );

  const [containerRef, loaderRef] = useInfiniteScroll({
    trigger,
    hasMore: data?.propertyValues.paginatorInfo.hasMorePages,
    distance: 30,
  });

  return (
    <div className="p-2">
      <label>Property Values</label>
      <div className="component-values-list" ref={containerRef}>
        {data?.propertyValues.data.map((propertyValue: PropertyValue) => (
          <PropertyValueForm
            value={propertyValue}
            key={propertyValue.id}
            checked={data?.templateField.values.some(
              (x: PropertyValue) => x.id === propertyValue.id,
            )}
          />
        ))}

        {data?.propertyValues.paginatorInfo.hasMorePages && (
          <div ref={loaderRef}>loading</div>
        )}

        {!data?.propertyValues.data.length && !loading && (
          <Alert color="info">
            {t('No property values avaiable for selected property')}
          </Alert>
        )}
      </div>
    </div>
  );
}
