import { InputPayment } from '@wgt/types';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaVideo } from 'react-icons/fa';
import { Form, Label, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { Button } from '..';
import SimpleInput from '../SimpleInput';
import Text from '../Text';
import usePayments from '../../pages/Crm/PointOfSales/Details/Resume/Payment/usePayments';
import PaymentForm from '../PaymentForm';
import ReceiptsModal from '../ReceiptsModal';
import './style.scss';

interface PaymentFormModalProps {
  isOpen: boolean;
  invoiceNumber: string;
  toggle: () => void;
}

export default function PaymentFormModal({
  isOpen,
  toggle,
  invoiceNumber,
}: PaymentFormModalProps): JSX.Element {
  const { t } = useTranslation(['pointOfSales', 'common']);
  const [isReceiptModalOpen, setReceiptModalOpen] = useState(false);
  const methods = useForm<InputPayment>({
    shouldUnregister: false,
    defaultValues: {
      invoice_id: invoiceNumber,
      payment_types: [],
      compound_interest_date: new Date(),
      plan_next_payment: [
        {
          payment_after_date: new Date(),
        },
      ],
    },
  });
  const { handleSubmit } = methods;
  const {
    create: [createPayment, { loading: creatingPayment }],
  } = usePayments({
    invoiceNumber,
  });

  const onSubmit = handleSubmit(createPayment);

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        className="shadow"
        id="modal-payment"
      >
        <div className="modal-header d-flex align-items-center">
          <div>
            <h2 className="modal-title">{t('Paid')}</h2>
            <Text className="sub-title">
              {t('Invoice Number')}: {invoiceNumber}
            </Text>
          </div>
          <div>
            <Button
              htmlType="button"
              type="link"
              className="btn btn-outline-secondary mr-3"
            >
              <FaVideo /> {t('common:Intro')}
            </Button>

            <Button onClick={toggle} type="secondary">
              {t('common:Cancel')}
            </Button>
          </div>
        </div>

        <FormProvider {...methods}>
          <Form onSubmit={onSubmit}>
            <ModalBody>
              <PaymentForm setReceiptModalOpen={setReceiptModalOpen} />
            </ModalBody>
            <ModalFooter className="mt-3">
              <Label className="label-password">
                {t('common:Re-enter password to confirm changes')}
              </Label>
              <SimpleInput
                type="password"
                required
                name="password"
                field="password"
              />
              <Button
                htmlType="submit"
                isLoading={creatingPayment}
                disabled={creatingPayment}
              >
                {t('common:Save')}
              </Button>
            </ModalFooter>
          </Form>
        </FormProvider>
        <ReceiptsModal
          isOpen={isReceiptModalOpen}
          toggle={() => setReceiptModalOpen((x) => !x)}
          invoiceNumber={invoiceNumber}
        />
      </Modal>
    </>
  );
}
