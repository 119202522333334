import React, { useState, useCallback } from 'react';
import { Row, Col, Container, Form } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import {
  useForm,
  FormProvider,
  Controller,
  useFieldArray,
} from 'react-hook-form';
import { Country } from '@wgt/types';
import { COUNTRIES } from '../../../../Firms/graphql';
import {
  SimpleDropdown,
  Button,
  Title,
  SimpleInput,
} from '../../../../../../components';
import SelectOfficeCard from '../../../../../../components/SelectOfficeCard';
import SelectSearch from '../../../../../../components/SelectSearch';
import Dropzone from '../../../../../../components/Dropzone';
import BagProvider from '../../../../../../services/bag';
import MiddleNames from './MiddleNames';
import AddDepartment from './AddDepartment';
import AddJobTitle from './AddJobTitle';
import AddJobTitleModalForm from './AddJobTitle/AddJobTitleModalForm';

const FAKE_LIST = [
  { id: 1, name: 'Description 1' },
  { id: 2, name: 'Description 2' },
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FAKE_LIST_OFFICES: any = [
  {
    id: 1,
    name: 'Office demo',
    address: 'Paul Lake Rd',
    state: 'British Columbia',
    phone: '213-2131-232',
    country: {
      name: 'Canada',
    },
  },
];

export default function AddNewEmployee(): JSX.Element {
  const { t } = useTranslation(['hr', 'common']);
  const [preview, setPreview] = useState<string>();
  const [isFormVisible, setFormVisible] = useState<boolean>(false);

  const methods = useForm({
    defaultValues: {
      middle_names: [{ name: '' }],
      departments: [],
      jobTitles: [],
    },
    shouldUnregister: false,
  });

  const { control } = methods;

  const {
    fields: middleNames,
    append: appendMiddleName,
    remove: removeMiddleName,
  } = useFieldArray({
    control,
    name: 'middle_names',
    keyName: '__id',
  });

  const onDrop = useCallback(async (acceptedFiles) => {
    if (!acceptedFiles.length) {
      return;
    }
    setPreview(URL.createObjectURL(acceptedFiles[0]));
  }, []);

  return (
    <Container fluid>
      <FormProvider {...methods}>
        <Form noValidate>
          <Row className="border py-3">
            <Col lg="6">
              <AddDepartment />
            </Col>
            <Col lg="6" className="text-right">
              <AddJobTitle />
              <Button
                small
                htmlType="button"
                className="mt-2"
                onClick={() => setFormVisible(true)}
              >
                {t('common:Add')}
              </Button>
              {isFormVisible && (
                <AddJobTitleModalForm
                  toggle={() => setFormVisible((x) => !x)}
                />
              )}
            </Col>
          </Row>

          <Row className="border py-3 mt-4">
            <Col lg="6">
              <Title heading="h3">{t('Office Location')}</Title>
            </Col>
            <Col lg="6">
              <SelectOfficeCard
                options={FAKE_LIST_OFFICES}
                field="office"
                placeholder={t('Choose an office')}
              />
            </Col>
          </Row>
          <BagProvider
            value={{
              middleName: {
                fields: middleNames,
                remove: removeMiddleName,
              },
            }}
          >
            <Row className="border py-3 mt-4">
              <Col lg="6">
                <SimpleInput
                  field="first_name"
                  placeholder={t('First Name')}
                  className="mb-2"
                />

                <MiddleNames />
                <Button
                  small
                  htmlType="button"
                  onClick={() => appendMiddleName({ name: '' })}
                >
                  {t('common:Add')}
                </Button>

                <SimpleInput
                  field="last_name"
                  placeholder={t('Last Name')}
                  className="my-4"
                />

                <p>{t('Home Location 1')}</p>
                <SimpleInput
                  field="address_1"
                  placeholder={`${t('Address')} 1`}
                  className="my-2"
                />
                <SimpleInput
                  field="address_2"
                  placeholder={`${t('Address')} 2`}
                  className="mb-2"
                />
                <div className="simple-dropdown mb-2">
                  <Controller
                    name="country_id"
                    defaultValue={methods.getValues('country_id') ?? ''}
                    render={({ onChange }) => (
                      <SelectSearch
                        gql={COUNTRIES}
                        getOptionLabel={(e: Country) => `${e.name ?? ''} `}
                        getOptionValue={(e: Country) => `${e.id}`}
                        name="name"
                        placeholder={t('Country')}
                        onChange={onChange}
                      />
                    )}
                  />
                </div>
                <SimpleInput
                  field="state"
                  placeholder={t('State / Province')}
                  className="mb-2"
                />
                <SimpleInput
                  field="city"
                  placeholder={t('City')}
                  className="mb-2"
                />
                <SimpleInput
                  field="postal_code"
                  placeholder={t('Zip / Postal Code')}
                  className="mb-2"
                />
                <Button small htmlType="button">
                  {t('common:Add')}
                </Button>
              </Col>
              <Col lg="6">
                <SimpleDropdown
                  placeholder={t('Description')}
                  controller="id"
                  field="description"
                  options={FAKE_LIST}
                  text="name"
                  className="mb-4"
                />

                {preview ? (
                  <div className="photo-preview py-3 d-flex align-items-center flex-column">
                    <img src={preview} />
                    <Button
                      small
                      type="danger"
                      htmlType="button"
                      onClick={() => setPreview('')}
                      className="mt-2"
                    >
                      {t('common:Remove')}
                    </Button>
                  </div>
                ) : (
                  <Dropzone
                    multiple
                    accept="image/*"
                    onDrop={onDrop}
                    contentMessage={t('your photo identification')}
                    className="hr-employee-drag-drop"
                    maxFiles={1}
                  />
                )}

                <p className="mt-4">{t('Communication')}</p>

                <SimpleInput
                  type="email"
                  field="business_email"
                  placeholder={t('Business Email')}
                  className="my-2"
                />
                <Button small htmlType="button">
                  {t('common:Add')}
                </Button>

                <SimpleInput
                  type="email"
                  field="personal_email"
                  placeholder={t('Personal Email')}
                  className="mb-2 mt-4"
                />
                <Button small htmlType="button">
                  {t('common:Add')}
                </Button>

                <SimpleInput
                  field="direct_business_phone"
                  placeholder={t('Direct Business Phone')}
                  className="mb-2 mt-4"
                />
                <Button small htmlType="button">
                  {t('common:Add')}
                </Button>

                <div className="d-flex mt-4">
                  <SimpleInput
                    field="phone"
                    placeholder={`${t('eg')} (123) 456-7890`}
                    className="w-75 mb-2"
                  />
                  <div className="d-flex">
                    <div className="ml-3 mr-1 align-self-center">
                      <p>{t('Ext')}</p>
                    </div>
                    <SimpleInput
                      field="ext"
                      placeholder={`${t('eg')} 123`}
                      className="w-100"
                      type="number"
                    />
                  </div>
                </div>

                <SimpleInput
                  field="phone_title"
                  placeholder={t('Phone Title')}
                  className="mb-2"
                />
                <Button small htmlType="button">
                  {t('common:Add')}
                </Button>

                <SimpleInput
                  field="business_mobile"
                  placeholder={t('Business Mobile')}
                  className="mb-2 mt-4"
                />
                <Button small htmlType="button">
                  {t('common:Add')}
                </Button>

                <SimpleInput
                  field="personal_mobile"
                  placeholder={t('Personal Mobile')}
                  className="mb-2 mt-4"
                />
                <Button small htmlType="button">
                  {t('common:Add')}
                </Button>
                <SimpleInput
                  field="business_fax"
                  placeholder={t('Business Fax')}
                  className="mb-2 mt-4"
                />
                <Button small htmlType="button">
                  {t('common:Add')}
                </Button>
              </Col>
            </Row>
          </BagProvider>
        </Form>
      </FormProvider>
    </Container>
  );
}
