import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Title } from '../../';
import LogoDummy from '../../../assets/logo-dummy.png';
import ExecutiveBuyerSelect from './ExecutiveBuyerSelect';
import WorkerSelect from './WorkerSelect';

const JOB_ORDER_FAKE = {
  jobOrderId: 123,
  firmAvatar: LogoDummy,
  firmName: 'Company Name',
  description: 'Lapidary (cutter)',
};

export default function CompanyOptionSelect(): JSX.Element {
  const { t } = useTranslation('pointOfSales');

  return (
    <Container fluid className="mt-4">
      <Row className="border rounded bg-white py-3">
        <Col xs="12" lg="3">
          <div className="d-flex">
            <div>
              <img
                src={JOB_ORDER_FAKE.firmAvatar}
                alt={JOB_ORDER_FAKE.firmName}
                className="img-thumbnail border-0"
              />
            </div>
            <div className="ml-2 d-flex flex-column align-self-center">
              <Title heading="h4">{t('Work Order')}</Title>
              <p className="mt-1 mb-0">ID: {JOB_ORDER_FAKE.jobOrderId}</p>
              <p className="mt-1 mb-0">{JOB_ORDER_FAKE.description}</p>
            </div>
          </div>
        </Col>
        <Col className="mt-2">
          <Row>
            <Col xs="12" md="6">
              <WorkerSelect />
            </Col>
            <Col xs="12" md="6">
              <ExecutiveBuyerSelect />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
