import React from 'react';
import { CustomInput, CustomInputProps } from 'reactstrap';
import classnames from 'classnames';
import './styles.scss';

export interface Props extends CustomInputProps {
  id: string;
  className?: string;
  isLoading?: boolean;
}

export default function SwitchBox({
  className,
  isLoading,
  ...props
}: Props): JSX.Element {
  return (
    <CustomInput
      {...props}
      disabled={props.disabled || isLoading}
      className={classnames('switch-box', className, {
        selected: props.checked,
      })}
    >
      {!!isLoading && (
        <span className="spinner-border spinner-border-sm loading" />
      )}
    </CustomInput>
  );
}
