import React from 'react';

import TemplateLayout, {
  TemplateLayoutProps,
} from '../../../../../components/TemplateLayout';
import { Pane } from 'evergreen-ui';

function TemplateFieldsSecondSection({
  rows,
  prefix,
  rangeToSingle,
  disabled,
}: TemplateLayoutProps): JSX.Element {
  return (
    <Pane flex="1">
      <TemplateLayout
        rows={rows}
        prefix={prefix}
        component="component"
        rangeToSingle={rangeToSingle}
        disabled={disabled}
        hasDependencies
        useLegacy={false}
      />
    </Pane>
  );
}

export default TemplateFieldsSecondSection;
