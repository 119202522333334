import React, { useState, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaTrash } from 'react-icons/fa';
import { Button } from '../../../../../../../components';
import ConfirmDialog from '../../../../../../../components/_molecules/ConfirmDialog';
import { useBagContext } from '../../../../../../../services/bag';

interface TollFreePhone {
  id?: number | undefined;
  name?: string | undefined;
}

interface TollFreePhoneProps {
  tollFreePhone: TollFreePhone;
  index: number;
}

export default function TollFreePhoneItem({
  tollFreePhone,
  index,
}: TollFreePhoneProps): JSX.Element {
  const { t } = useTranslation('hr');
  const { register } = useFormContext();

  const {
    tollFreePhone: { remove },
  } = useBagContext<{ tollFreePhone: { remove(i: number): void } }>();

  const [showRemoveConfirm, setShowRemoveConfirm] = useState<boolean>(false);

  const onConfirm = useCallback(async () => {
    remove(index);
    setShowRemoveConfirm(false);
  }, [tollFreePhone, index]);

  const onClickRemove = (e: React.MouseEvent) => {
    e.preventDefault();
    setShowRemoveConfirm(true);
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-end mb-2">
        <input
          type="hidden"
          name={`toll_free_phones[${index}].id`}
          ref={register()}
          defaultValue={tollFreePhone.id}
        />
        <input
          name={`toll_free_phones[${index}].name`}
          ref={register()}
          defaultValue={tollFreePhone.name}
          className="form-control w-100"
          placeholder={t('Toll Free Phone')}
        />

        {!!index && (
          <Button
            htmlType="button"
            onClick={onClickRemove}
            small
            type="danger"
            className="ml-2"
          >
            <FaTrash />
          </Button>
        )}
      </div>
      <ConfirmDialog
        isShown={showRemoveConfirm}
        onConfirm={onConfirm}
        title={t('Are you sure you want to delete this record?')}
        onClose={() => setShowRemoveConfirm(false)}
      />
    </>
  );
}
