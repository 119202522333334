import { gql } from '@apollo/client';
import { paginatorInfoFragment } from '../../../services/graphql/paginator.gql';

export const userIdentityFragment = gql`
  fragment userIdentity_Part on User {
    id
    first_name
    middle_name
    last_name
    email
  }
`;

export const LIST_USERS_GQL = gql`
  query user($page: Int) {
    users(page: $page) {
      data {
        ...userIdentity_Part
      }
      paginatorInfo {
        ...paginatorInfo_Part
      }
    }
  }
  ${paginatorInfoFragment}
  ${userIdentityFragment}
`;

export const CREATE_USER_GQL = gql`
  mutation createUser(
    $first_name: String!
    $middle_name: String!
    $last_name: String!
    $email: String!
    $password: String!
    $password_confirmation: String!
    $secret_phrase: String!
  ) {
    register(
      first_name: $first_name
      middle_name: $middle_name
      last_name: $last_name
      email: $email
      password: $password
      password_confirmation: $password_confirmation
      secret_phrase: $secret_phrase
    ) {
      id
    }
  }
`;

export const EDIT_USER_SCREEN = gql`
  query editUserScreen($id: ID!, $isEdit: Boolean!) {
    user(id: $id) @include(if: $isEdit) {
      ...userIdentity_Part

      employments {
        id
        name
        sales_representative
        positions {
          id
          name
          firm {
            id
          }
        }
      }
    }

    firms(first: 1000) @include(if: $isEdit) {
      data {
        id
        name
        positions {
          id
          name
        }
      }
    }
  }
  ${userIdentityFragment}
`;

export const ROLES_TAB_SCREEN = gql`
  query tabRoles($userId: ID!) {
    options: roles(first: 1000) {
      data {
        id
        name
      }
    }
    userRoles: roles(first: 1000, filter: { users: { id: [$userId] } }) {
      data {
        id
        name
      }
    }
  }
`;

export const PERMISSIONS_TAB_SCREEN = gql`
  query permissionTab($userId: ID!) {
    options: permissions(first: 1000) {
      data {
        id
        name
      }
    }
    userPermissions: permissions(
      first: 1000
      filter: { users: { id: [$userId] } }
    ) {
      data {
        id
        name
      }
    }
  }
`;

export const GET_USER_GQL = gql`
  query getUser($id: ID!) {
    user(id: $id) {
      id
      first_name
      middle_name
      last_name
      email
      roles {
        id
        name
      }
      permissions {
        id
        name
      }
    }
  }
`;

export const LIST_ROLES_GQL = gql`
  query rolesQuery {
    roles(first: 10000) {
      data {
        id
        name
      }
    }
  }
`;

export const LIST_PERMISSIONS_GQL = gql`
  query permissionsQuery {
    permissions(first: 10000) {
      data {
        id
        name
      }
    }
  }
`;

export const ASSING_ROLE_TO_USER_GQL = gql`
  mutation assignRoleToUserMutation($id: ID!, $role_id: ID!) {
    assignRoleToUser(id: $id, role_id: $role_id) {
      message
    }
  }
`;
export const REMOVE_ROLE_FROM_USER_GQL = gql`
  mutation removeRoleToUserMutation($id: ID!, $role_id: ID!) {
    removeRoleToUser(id: $id, role_id: $role_id) {
      message
    }
  }
`;

export const GIVE_PERMISSION_TO_USER_GQL = gql`
  mutation givePermissionToUserMutation($id: ID!, $permission_id: ID!) {
    givePermissionToUser(id: $id, permission_id: $permission_id) {
      message
    }
  }
`;
export const REVOKE_PERMISSION_FROM_USER_GQL = gql`
  mutation revokePermissionToUserMutation($id: ID!, $permission_id: ID!) {
    revokePermissionToUser(id: $id, permission_id: $permission_id) {
      message
    }
  }
`;
