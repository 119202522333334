import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaQrcode } from 'react-icons/fa';
import { Heading } from 'evergreen-ui';
import logo from '../../../assets/logo-trace.png';
import signedDocument from '../../../assets/trace-image-full.png';

export default function TraceDocumentSectionHeader(): JSX.Element {
  const { t } = useTranslation('traceDocuments');
  return (
    <div className="trace-documents-sidesheet-document-header d-flex justify-content-between p-5">
      <div className="d-flex align-items-center">
        <div
          className="trace-documents-sidesheet-document-header-img"
          style={{
            backgroundImage: `url(${logo})`,
          }}
        />
        <div
          className="trace-documents-sidesheet-document-header-img"
          style={{
            backgroundImage: `url(${signedDocument})`,
          }}
        />
      </div>
      <div className="d-flex align-items-center">
        <Heading>
          {t('Trace Document')} <FaQrcode />
        </Heading>
      </div>
    </div>
  );
}
