/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useEffect, useState } from 'react';
import { DocumentNode, useMutation, useQuery } from '@apollo/client';
import { TableProps } from 'reactstrap';
import TableMol, { BaseTable } from '../../_molecules/Table';
import { AlertMessage } from '../../';
import Loader from '../Loader';
import { CollapseProvider } from '../../../services/collapse';
import useToast from '../../../hooks/useToast';

interface Props extends TableProps, BaseTable {
  query: DocumentNode;
  node: string;
  variables?: any;
  deleteMutation?: DocumentNode;
  parseItems?(data: any): any;
  editAction?(item: any): void;
  isExpandable?: boolean;
  RenderDetails?: React.FC<{ item: any }>;
}

const Table: React.FC<Props> = ({
  columns,
  query,
  variables,
  node,
  deleteMutation,
  getConfirmMessage,
  getMutationArgs,
  parseItems = (x) => x,
  ...props
}) => {
  const { data, loading, refetch } = useQuery(query, {
    variables,
  });
  const { toast } = useToast();
  const [checkDeleted, setCheckDeleted] = useState<boolean>(false);

  let mutator;
  let mutating = false;

  if (deleteMutation) {
    [mutator, { loading: mutating }] = useMutation(deleteMutation, {
      refetchQueries: [{ query, variables }],
      onError: () => toast('error'),
    });
  }

  const { items, paginator } = useMemo(() => {
    setCheckDeleted(false);

    if (!data) {
      return { items: [] };
    }
    const result = data[node];
    const currentItems = parseItems(result?.data || result);
    const paginatorInfo = result?.paginatorInfo;

    return { items: currentItems, paginator: paginatorInfo };
  }, [data?.[node]?.data, data?.[node]?.paginatorInfo]);

  useEffect(() => {
    if (items.length === 0 && paginator?.currentPage > 1 && !loading) {
      refetch?.({ page: paginator.currentPage - 1 });
      setCheckDeleted(true);
    }
  }, [items, paginator, loading]);

  if (loading || checkDeleted) {
    return <Loader />;
  }

  return items.length ? (
    <CollapseProvider options={items.map((item: any) => item.id)}>
      <TableMol
        {...props}
        getConfirmMessage={getConfirmMessage}
        columns={columns}
        items={items}
        loading={loading}
        getMutationArgs={getMutationArgs}
        mutator={mutator}
        mutating={mutating}
        paginator={paginator}
        fetchMore={refetch}
        node={node}
      />
    </CollapseProvider>
  ) : (
    <AlertMessage>
      There is no records in the list. To create a new record click on the{' '}
      <strong>Create</strong> button
    </AlertMessage>
  );
};

export default Table;
