import React from 'react';

import CustomHeader from '../../../../components/_molecules/CustomHeader';

import { LIST_TEMPLATES_GQL, DELETE_TEMPLATE_GQL } from '../graphql';

import Table from '../../../../components/_organisms/Table';
import { TemplateBase } from '@wgt/types';

const List: React.FC = () => {
  return (
    <div>
      <CustomHeader title={'List Templates'} showCreate />
      <Table
        node="templates"
        columns={[{ label: 'Name', field: 'name' }]}
        showDetail={false}
        query={LIST_TEMPLATES_GQL}
        deleteMutation={DELETE_TEMPLATE_GQL}
        getMutationArgs={(item: TemplateBase) => ({ id: item.id })}
        getConfirmMessage={(item: TemplateBase) =>
          `Confirm delete of ${item?.name}?`
        }
      />
    </div>
  );
};

export default List;
