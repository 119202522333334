import React from 'react';
import { useFormContext } from 'react-hook-form';
import classnames from 'classnames';
import { getProp } from '../../../utils/methods';
import './style.scss';

interface Props {
  label: string;
  field: string;
  required?: boolean;
  disabled?: boolean;
  className?: string;
}

const LayoutComponentMinMax: React.FC<Props> = ({
  field,
  label,
  required,
  disabled,
  className,
}) => {
  const { register, getValues, errors } = useFormContext();
  return (
    <div
      className={classnames(
        'simple-input template-min-max-component',
        className,
      )}
    >
      <div className="label-wrap">
        <label>{label}</label>
      </div>

      <div className="fields-wrap">
        <input
          placeholder="from"
          className={classnames('form-control', {
            'is-invalid': getProp(errors, `${field}.from`),
          })}
          ref={register({ required })}
          name={`${field}.from`}
          defaultValue={getValues(`${field}.from`)}
          disabled={disabled}
        />

        <input
          placeholder="to"
          className={classnames('ml-2 form-control', {
            'is-invalid': getProp(errors, `${field}.to`),
          })}
          ref={register({ required })}
          name={`${field}.to`}
          defaultValue={getValues(`${field}.to`)}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default LayoutComponentMinMax;
