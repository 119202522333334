/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'reactstrap';
import { useQuery } from '@apollo/client';
import { Employee } from '@wgt/types';
import { Title, Line } from '../../../../../components';
import Item from './Item';
import { GET_EMPLOYEES_GQL } from '../graphql';
import Loader from '../../../../../components/_organisms/Loader';

export default function Result(): JSX.Element {
  const { t } = useTranslation('hr');

  const { data, loading } = useQuery<{ employees: { data: Employee[] } }>(
    GET_EMPLOYEES_GQL,
  );

  const employees = useMemo(() => data?.employees?.data ?? [], [
    data?.employees?.data,
  ]);

  return (
    <Container fluid className="results">
      <Title>{t('Results')}</Title>
      <Line />
      {loading && <Loader />}
      {employees.map((employee: any) => (
        <Item employee={employee} key={employee.id} />
      ))}
    </Container>
  );
}
