import { gql } from '@apollo/client';
import { paginatorInfoFragment } from '../../../services/graphql/paginator.gql';

export const LIST_SHAPE_GROUPS_GQL = gql`
  query shapeGroups($page: Int) {
    shapeGroups(page: $page) {
      data {
        id
        title
      }
      paginatorInfo {
        ...paginatorInfo_Part
      }
    }
  }
  ${paginatorInfoFragment}
`;

export const DELETE_SHAPE_GROUP_GQL = gql`
  mutation deleteShapeGroup($id: ID!) {
    deleteShapeGroup(id: $id) {
      message
    }
  }
`;

export const GET_SHAPE_GROUPS_GQL = gql`
  query shapeGroup($id: ID!, $isEdit: Boolean!) {
    shapeGroup(id: $id) @include(if: $isEdit) {
      id
      title
      value
    }
  }
`;

export const CREATE_SHAPE_GROUPS_GQL = gql`
  mutation createShapeGroup($title: String!, $value: String!) {
    createShapeGroup(title: $title, value: $value) {
      id
    }
  }
`;

export const UPDATE_SHAPE_GROUPS_GQL = gql`
  mutation updateShapeGroup($id: ID!, $shapeGroup: UpdateShapeGroupInput!) {
    updateShapeGroup(id: $id, shapeGroup: $shapeGroup) {
      id
    }
  }
`;
