import React, {
  NamedExoticComponent,
  useCallback,
  useContext,
  useState,
} from 'react';
import { ProductBase, Schedule, UrgencyEnum, UrgencyType } from '@wgt/types';
import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import useToast from '../../hooks/useToast';
import { UPDATE_SCHEDULE, CREATE_SCHEDULE, GET_SCHEDULE } from './graphql';

const context = React.createContext({});
type Toggler = () => void;

interface WorkOrderScheduleProviderProps {
  children: JSX.Element;
  toggle: Toggler;
  scheduleId?: string;
  workOrderId?: string;
  products?: ProductBase[];
  onCompleted?: (args: { id: string }) => void;
  tabs: Tab[];
}

interface PointOfSale {
  id: string;
}
interface PointOfSaleProduct {
  pointOfSale: PointOfSale;
  product: ProductBase;
  confirmed: boolean;
}

interface WorkOrder {
  id: string;
  title: string;
  date: Date;
  start: string;
  end: string;
  urgency: UrgencyEnum | UrgencyType;
  products: ProductBase[];
  posProducts: PointOfSaleProduct[];
}

interface Tab {
  id: string;
  label: string;
  component: NamedExoticComponent;
  passwordProtected?: boolean;
  context?: Record<string, string>;
}

export function WorkOrderScheduleProvider({
  children,
  toggle,
  products,
  scheduleId,
  workOrderId,
  onCompleted,
  tabs,
}: WorkOrderScheduleProviderProps): JSX.Element {
  const [activeTab, setActiveTab] = useState(tabs[0]);

  const selectTab = useCallback(
    (tabId: string, ctx?: Record<string, string>) => {
      const tab = tabs.find((x) => x.id === tabId) ?? tabs[0];

      setActiveTab({ ...tab, context: ctx });
    },
    [],
  );

  const { toast } = useToast();
  const [mutate, { loading: mutating }] = useMutation(
    scheduleId ? UPDATE_SCHEDULE : CREATE_SCHEDULE,
    {
      onCompleted: (args) => [
        toast('success'),
        toggle(),
        onCompleted?.(args.createSchedule ?? args.updateSchedule),
      ],
    },
  );

  const { data } = useQuery<{ schedule?: Schedule; workOrder?: WorkOrder }>(
    GET_SCHEDULE,
    {
      skip: !scheduleId && !workOrderId,
      variables: {
        id: scheduleId ?? workOrderId,
        skipSchedule: !scheduleId,
        skipWorkOrder: !!scheduleId,
      },
    },
  );

  const submit = useCallback(
    ({ start, end, date, urgency, ...values }: WorkOrder) => {
      const startDate = moment(`${date} ${start}`);
      const endDate = moment(`${date} ${end}`);
      const parsed = {
        ...values,

        urgency: (urgency as UrgencyType)?.key,
        start: startDate.format('Y-M-D HH:mm:ss'),
        end: endDate.format('Y-M-D HH:mm:ss'),
      };
      console.log(parsed);
      if (!scheduleId && products) {
        return mutate({
          variables: { ...parsed, product_ids: products.map((x) => x.id) },
        });
      }
      return mutate({
        variables: {
          id: scheduleId,
          schedule: parsed,
        },
      });
    },
    [],
  );

  return (
    <context.Provider
      value={[
        {
          scheduleId,
          workOrderId,
          workOrder: data?.workOrder ?? data?.schedule,
          mutating,
          activeTab,
          products,
        },
        { toggle, submit, selectTab },
      ]}
    >
      {children}
    </context.Provider>
  );
}

interface WorkOrderScheduleState {
  scheduleId?: string;
  workOrderId?: string;
  workOrder?: WorkOrder;
  mutating: boolean;
  activeTab: Tab;
  products?: ProductBase[];
}

interface WorkOrderScheduleActions {
  toggle: Toggler;
  submit: (values: WorkOrder) => void;
  selectTab: (tabId: string, context?: Record<string, string>) => void;
}

type UseWorkOrderSchedule = [WorkOrderScheduleState, WorkOrderScheduleActions];
export default function useWorkOrderSchedule(): UseWorkOrderSchedule {
  return useContext(context) as UseWorkOrderSchedule;
}
