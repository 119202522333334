import React from 'react';
import ResetPasswordForm, {
  FormValues,
} from '../../../components/_molecules/ResetPasswordForm';
import { SubmitHandler } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useMutation, gql } from '@apollo/client';
import useToast from '../../../hooks/useToast';

export const RESET_PASSWORD_MUTATION = gql`
  mutation resetPassword(
    $email: String!
    $token: String!
    $secret_phrase: String!
    $password: String!
    $password_confirmation: String!
  ) {
    resetPassword(
      email: $email
      token: $token
      secret_phrase: $secret_phrase
      password: $password
      password_confirmation: $password_confirmation
    ) {
      message
    }
  }
`;

const ResetPassword: React.FC = () => {
  const history = useHistory();
  const [resetPassword, { loading }] = useMutation(RESET_PASSWORD_MUTATION, {
    onCompleted: ({ resetPassword: data }) => [
      toast('success', data.message),
      history.push('/auth'),
    ],
    onError: (error) => toast('error', error.message),
  });
  const { toast } = useToast();

  const handleResetPassword: SubmitHandler<FormValues> = async (
    variables: FormValues,
  ) =>
    resetPassword({
      variables,
    });
  return (
    <ResetPasswordForm
      handleResetPassword={handleResetPassword}
      isSubmitting={loading}
    />
  );
};

export default ResetPassword;
