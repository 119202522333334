/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import {
  DocumentNode,
  MutationHookOptions,
  QueryHookOptions,
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { Col, Modal, Row } from 'reactstrap';
import {
  SimpleInput,
  Button,
  SimpleDropdown,
  UploadButton,
  SimpleTextArea,
  SimpleSwitcher,
} from '../..';
import classnames from 'classnames';
import ImageCropper from '../../ImageCropper';
import { useTranslation } from 'react-i18next';
interface SimpleInputFormProps {
  name: string;
  label: string;
  controller?: string;
  text?: string;
  type: 'text' | 'select' | 'upload' | 'textarea' | 'switcher';
  options?: any[];
  defaultValue?: any;
  required?: boolean;
  isEdit?: boolean;
  accept?: string;
  optionsNode?: {
    gqlNode: string;
    text: string;
  };
}

const SimpleInputForm: React.FC<SimpleInputFormProps> = (props) => {
  const { errors } = useFormContext();
  const {
    name,
    label,
    type,
    options,
    controller,
    text,
    required,
    isEdit,
    accept,
    optionsNode,
  } = props;
  const [isModalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation('common');
  switch (type) {
    case 'text':
      return (
        <SimpleInput
          field={name}
          hint={errors[name]?.message || ''}
          {...props}
        />
      );
    case 'textarea':
      return (
        <SimpleTextArea
          field={name}
          hint={errors[name]?.message || ''}
          {...props}
        />
      );
    case 'switcher':
      return <SimpleSwitcher field={name} {...props} />;
    case 'select':
      return (
        <SimpleDropdown
          field={name}
          options={options}
          text={(text || optionsNode?.text) ?? ''}
          controller={controller ?? 'id'}
          hint={errors[name]?.message || ''}
          {...props}
        />
      );
    case 'upload':
      return (
        <Controller
          name={name}
          rules={{
            required: required && !isEdit,
          }}
          render={({ onChange, value }) => (
            <>
              <UploadButton
                setImageToUpload={onChange}
                label={`${label} ${errors?.[name] ? `(Required)` : ``} `}
                accept={accept}
                name={name}
              />
              {typeof value !== 'string' && (
                <>
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={() => setModalOpen(true)}
                  >
                    {t('crop image')}
                  </button>
                  <Modal
                    size="lg"
                    isOpen={isModalOpen}
                    toggle={() => setModalOpen((x) => !x)}
                  >
                    <ImageCropper
                      file={value}
                      onChange={onChange}
                      onConfirm={() => setModalOpen(false)}
                      onCancel={() => setModalOpen(false)}
                    />
                  </Modal>
                </>
              )}
            </>
          )}
          defaultValue=""
        />
      );
    default:
      return <></>;
  }
};

interface SimpleFormProps {
  fields: SimpleInputFormProps[];
  mutation: DocumentNode;
  mutationOptions?: MutationHookOptions;
  query: DocumentNode;
  queryOptions?: QueryHookOptions;
  shouldUnregister?: boolean;
  parseQuery?(data: any): any;
  parseMutationArgs?(data: any): any;
  isModal?: boolean;
}

const SimpleForm: React.FC<SimpleFormProps> = ({
  fields,
  mutation,
  mutationOptions,
  query,
  queryOptions,
  parseQuery,
  parseMutationArgs,
  shouldUnregister = true,
  isModal = false,
}) => {
  const methods = useForm({
    shouldUnregister,
    defaultValues: fields.reduce((acc, curr) => {
      acc[curr.name] = curr.defaultValue ?? '';
      return acc;
    }, {}),
  });

  const [mutator, { loading, data: result }] = useMutation(
    mutation,
    mutationOptions,
  );

  const { data } = useQuery(query, {
    fetchPolicy: 'no-cache',
    ...queryOptions,
  });

  useEffect(() => {
    if (parseQuery && data) {
      const parsed = parseQuery(data);
      if (parsed) {
        methods.reset(parsed);
      }
    }
  }, [data]);

  useEffect(() => {
    if (result) {
      methods.reset();
    }
  }, [result, data]);

  const onSubmit: SubmitHandler<any> = (values) => {
    fields.map((field) =>
      field.type === 'upload'
        ? (values[field.name] =
            typeof values[field.name] === 'string'
              ? undefined
              : values[field.name])
        : values[field.name],
    );

    mutator({
      variables: (parseMutationArgs && parseMutationArgs(values)) ?? values,
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Row>
          {fields.map((field) => (
            <div
              className={classnames('col-12 my-2', {
                'col-md-4': !isModal && field.type !== 'textarea',
              })}
              key={field.name}
            >
              <SimpleInputForm
                {...field}
                options={
                  field.optionsNode
                    ? data?.[field.optionsNode?.gqlNode]?.data ?? []
                    : field.options
                }
              />
            </div>
          ))}
        </Row>
        <Row>
          <Col>
            <br />
          </Col>
        </Row>
        <Row>
          <Col className={classnames({ 'text-right': isModal })}>
            <Button isLoading={loading} htmlType="submit">
              Save
            </Button>
          </Col>
        </Row>
      </form>
    </FormProvider>
  );
};

export default SimpleForm;
