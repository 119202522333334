import React from 'react';
import { useFormContext } from 'react-hook-form';
import classnames from 'classnames';

import './styles.scss';

export interface Props
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  field: string;
  label?: string;
  parent?: string;
  hint?: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  registerProps?: object;
}

const SimpleTextArea: React.FC<Props> = ({
  field,
  label,
  hint,
  registerProps,
  parent,
  className,
  required,
  ...props
}) => {
  const { register, errors } = useFormContext();

  const isError = () =>
    parent ? !!errors?.[parent]?.[field] : errors?.[field];

  const fieldRegistered = parent ? `${parent}[${field}]` : field;

  const htmlFor = parent ? `label-${parent}-${field}` : `label-${field}`;

  const ariaInvalid = parent
    ? errors && errors[parent] && errors[parent][field]
      ? 'true'
      : 'false'
    : errors && errors[field]
    ? 'true'
    : 'false';

  return (
    <div className="simple-textarea">
      {label && <label htmlFor={htmlFor}>{label}</label>}
      <textarea
        {...props}
        className={classnames('form-control', className, {
          'is-invalid': isError(),
        })}
        id={htmlFor}
        name={fieldRegistered}
        ref={register({
          required: {
            message: 'Field required',
            value: required || false,
          },
          ...registerProps,
        })}
        aria-invalid={ariaInvalid}
      />
      {!!hint && <span>{hint}</span>}
    </div>
  );
};

export default SimpleTextArea;
