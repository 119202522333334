import React from 'react';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Button, TooltipHelp } from '../../../../../../../components';
import usePointOfSaleUpdate from '../../../usePointOfSaleUpdate';

export default function Footer(): JSX.Element {
  const {
    setContentTab,
    setContentPolicyTab,
    contentPolicyTab,
  } = usePointOfSaleUpdate();
  const { t } = useTranslation(['pointOfSales', 'common']);
  return (
    <Row className="border-top p-4" noGutters>
      <Col
        xs={{ size: 9, offset: 3 }}
        className="d-flex justify-content-between pl-5"
      >
        <div>
          <Button
            type={
              contentPolicyTab === 'create-policy' ? 'secondary' : 'primary'
            }
            onClick={() =>
              setContentPolicyTab(
                contentPolicyTab === 'create-policy'
                  ? 'shared'
                  : 'create-policy',
              )
            }
          >
            {t('Office Shared Policies')}
          </Button>
          <TooltipHelp
            className="mx-2"
            description="waiting for description..."
          />
        </div>
        <div>
          <Button
            type={
              contentPolicyTab === 'fields-schedule' ? 'secondary' : 'primary'
            }
            onClick={() =>
              setContentPolicyTab(
                contentPolicyTab === 'fields-schedule'
                  ? 'shared'
                  : 'fields-schedule',
              )
            }
          >
            {t('Fields Schedule')}
          </Button>
          <TooltipHelp
            className="mx-2"
            description={t('Select Field Schedule for this Policy')}
          />
        </div>
        <div>
          <Button
            type="secondary"
            className="mr-5"
            onClick={() => setContentTab('results')}
          >
            {t('common:Cancel')}
          </Button>
          <Button>{t('common:Save')}</Button>
        </div>
      </Col>
    </Row>
  );
}
