/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MineralsQueryVariables } from '../types';

interface MineralsContextData {
  filters: MineralsQueryVariables;
  setFilters: Dispatch<SetStateAction<MineralsQueryVariables>>;
}

export const MineralsContext = React.createContext<MineralsContextData>(
  {} as MineralsContextData,
);

export const MineralsProvider: React.FC = ({ children }) => {
  const params = useParams<{ id: string }>();
  const [filters, setFilters] = useState<MineralsQueryVariables>({
    filter: {
      propertyValue: {
        property_id: params.id,
      },
    },
  });

  const contextValues: MineralsContextData = {
    filters,
    setFilters,
  };

  return (
    <MineralsContext.Provider value={contextValues}>
      {children}
    </MineralsContext.Provider>
  );
};

export default function useMineralsContext(): MineralsContextData {
  return useContext(MineralsContext);
}
