import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiFileText } from 'react-icons/fi';
import {
  Col,
  Container,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  Row,
} from 'reactstrap';

import ReceiptList from './ReceiptList';
import ReceiptsMail from '../../assets/receipts-mail.svg';
import PaymentList from './PaymentList';
import { ReceiptContext } from './useReceipt';
import './style.scss';
import ReceiptForm from './ReceiptForm';
import { ReceiptFormProvider } from './useReceiptForm';
import ReceiptPaymentForm from './ReceiptPaymentForm';
import { PaymentBase } from '@wgt/types';
import ChangeInvoiceContext from './ChangeInvoiceContext';
import ReceiptsModalHeader from './ReceiptsModalHeader';

interface ReceiptsModalProps {
  isOpen: boolean;
  invoiceNumber: string;
  toggle: () => void;
}

export default function ReceiptsModal({
  isOpen,
  invoiceNumber,
  toggle,
}: ReceiptsModalProps): JSX.Element {
  const { t } = useTranslation(['pointOfSales', 'common']);

  const [selectedInvoice, setSelectedInvoiceState] = useState<string>(
    invoiceNumber,
  );

  const [selectedPayment, setSelectedPayment] = useState<PaymentBase>();

  const [isFormVisible, setIsFormVisible] = useState(false);

  const setSelectedInvoice = (invoice: string) => {
    setSelectedPayment(undefined);
    setIsFormVisible(false);
    setSelectedInvoiceState(invoice);
  };
  return (
    <ReceiptContext.Provider
      value={{
        setIsFormVisible,
        setSelectedInvoice,
        invoice: selectedInvoice,
        editPayment: (payment: PaymentBase) => [
          setIsFormVisible(true),
          setSelectedPayment(payment),
        ],
      }}
    >
      <Modal id="modal-receipt" isOpen={isOpen} toggle={toggle} size="lg">
        <ReceiptsModalHeader invoiceId={selectedInvoice} isOpen={isOpen} />
        <ModalBody>
          <Container fluid>
            <Row>
              <Col className="d-flex align-items-center">
                <FormGroup className="mb-0 w-100">
                  <Input type="select" name="select" style={{ height: '36px' }}>
                    <option>{t('Receipts')}</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col className="d-flex flex-row align-items-center pr-0">
                <ChangeInvoiceContext />
              </Col>
              <Col lg="1" className="pl-0">
                <div className="icons-option">
                  <div className="d-block text-right">
                    <img
                      src={ReceiptsMail}
                      alt="Receipts Mail"
                      className="icon-mail"
                    />
                  </div>
                  <FiFileText size="35" className="d-block icon-file" />
                </div>
              </Col>
            </Row>
            {selectedPayment?.id && (
              <ReceiptFormProvider paymentId={`${selectedPayment.id}`}>
                <ReceiptForm />

                <ReceiptList />
              </ReceiptFormProvider>
            )}

            <PaymentList />
          </Container>
        </ModalBody>
        {isFormVisible && (
          <ReceiptPaymentForm
            toggle={toggle}
            paymentId={selectedPayment?.id?.toString()}
          />
        )}
      </Modal>
    </ReceiptContext.Provider>
  );
}
