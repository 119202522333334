import React from 'react';

import { useTranslation } from 'react-i18next';
import { Button } from '../../../../components';
import TopModal from '../../../../components/TopModal';
import useCsvImportContext from '../useCsvImport';
import UploadImportFile from './UploadImportFile';
export default function ImportModal(): JSX.Element {
  const { isModalOpen, category, setModalOpen, file } = useCsvImportContext();

  const { t } = useTranslation(['csvImport', 'common']);

  return (
    <>
      <TopModal
        isShown={isModalOpen}
        shouldCloseOnEscapePress={false}
        shouldCloseOnOverlayClick={false}
      >
        <div className="d-flex align-items-center flex-column ">
          <div className="mb-3">{t('Import Diamond CSV')}</div>
          <div className="flex-1 d-flex justify-content-between w-100">
            <div className="d-flex flex-column align-items-center">
              <Button type="white" disabled={!file?.name}>
                {category?.name}
              </Button>
              <div className="mt-5 d-flex align-items-end">
                <Button type="danger" onClick={() => setModalOpen(false)} small>
                  {t('common:close')}
                </Button>
                <Button>
                  {t('Select to Import Description with Abbrications')}
                </Button>
              </div>
            </div>
            <div>
              <UploadImportFile />
            </div>
          </div>
        </div>
      </TopModal>
    </>
  );
}
