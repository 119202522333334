import { MediaCenterItemBase, Mineral } from '@wgt/types';
import React, { useMemo, useState } from 'react';
import { FaPlusCircle } from 'react-icons/fa';
import FilesList from './FilesList';
import CreateFileModal from './CreateFileModal';
import { useTranslation } from 'react-i18next';
import { FileTypeStr } from '../../../../../../Products/types';
import ConfirmDialog from '../../../../../../../../components/_molecules/ConfirmDialog';
import { useMutation } from '@apollo/client';
import {
  DETACH_FILE_TO_MINERAL,
  REMOVE_MINERAL_FILE,
  MINERAL_DETAILS,
} from '../graphql';
import useToast from '../../../../../../../../hooks/useToast';

interface MineralsMediaCenterFilesProps {
  type: 'image' | 'video';
  mineral: Mineral;
}
const MineralsMediaCenterFiles: React.FC<MineralsMediaCenterFilesProps> = ({
  type,
  mineral,
}) => {
  const { t } = useTranslation('minerals');
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [deleteItemIndex, setDeleteItemIndex] = useState<string | null>(null);
  const { toast } = useToast();

  const { accept, filesDescription, filesList } = useMemo<{
    accept: FileTypeStr;
    filesDescription: string;
    filesList: MediaCenterItemBase[];
  }>(
    () => ({
      accept: type === 'image' ? 'image/*' : 'video/*',
      filesList: mineral?.files?.filter((file) => file.type === type) ?? [],
      filesDescription: t(`New Mineral ${type}`),
    }),
    [type, mineral?.files],
  );

  const [removeFile, { loading: removing }] = useMutation(REMOVE_MINERAL_FILE, {
    onCompleted: () => [
      setDeleteItemIndex(null),
      toast('success', t('FIle removed successfully')),
    ],
    refetchQueries: [{ query: MINERAL_DETAILS, variables: { id: mineral.id } }],
  });
  const [detach, { loading: detaching }] = useMutation(DETACH_FILE_TO_MINERAL, {
    onCompleted: () =>
      removeFile({
        variables: {
          id: deleteItemIndex,
        },
      }),
  });

  const toggle = () => setModalOpen(!modalOpen);
  return (
    <>
      <div className="add-media-button" onClick={toggle}>
        <div className="mx-5">
          <FaPlusCircle />
        </div>
      </div>

      <FilesList
        files={filesList}
        setDeleteItemIndex={setDeleteItemIndex}
        isLoading={detaching || removing}
      />

      <CreateFileModal
        mineral={mineral}
        isOpen={modalOpen}
        accept={accept}
        filesDescription={filesDescription}
        toggle={toggle}
      />
      <ConfirmDialog
        isShown={!!deleteItemIndex}
        isLoading={detaching || removing}
        onConfirm={() =>
          detach({
            variables: {
              id: mineral.id,
              file_ids: [deleteItemIndex],
            },
          })
        }
        title={t('Are you sure you want to delete this record?')}
        onClose={() => setDeleteItemIndex(null)}
      />
    </>
  );
};

export default MineralsMediaCenterFiles;
