import React, { useContext, ChangeEvent, useState, useMemo } from 'react';
import { FaTrash } from 'react-icons/fa';
import { Table } from 'reactstrap';
import { TemplateLayoutContext } from '../../../../../../../hooks/useTemplateLayout';
import { Button } from '../../../../../../../components';
import {
  TemplateLayoutGroupContent,
  SectionStringType,
  LayoutTemplateFieldComponent,
} from '@wgt/types';

interface Props {
  content: TemplateLayoutGroupContent[];
  rowNumber: number;
  colNumber: number;
  section: SectionStringType;
}
interface Props2 {
  groupContent: TemplateLayoutGroupContent;
  positionProps: {
    rowNumber: number;
    colNumber: number;
    fieldIndex: number;
  };
  section: SectionStringType;
}

const RenderGroupContentComponent: React.FC<Props2> = ({
  groupContent,
  positionProps,
  section,
}) => {
  const {
    onRemoveField,
    onRemoveFieldByPosition,
    onEditTemplateField,
    layoutField,
  } = useContext(TemplateLayoutContext);

  const [styleValue, setStyleValue] = useState<string>(
    groupContent.searchLayoutClassStyle ?? '',
  );

  const handleStyle = (e: ChangeEvent<HTMLSelectElement>) => {
    const values = {
      colNumber: positionProps.colNumber,
      rowNumber: positionProps.rowNumber,
      fieldIndex: positionProps.fieldIndex,
      section,
      searchLayoutClassStyle: e.target.value,
    };
    setStyleValue(e.target.value);
    onEditTemplateField(values);
  };

  const content = groupContent.content as LayoutTemplateFieldComponent;

  const isHandleStyle = useMemo(() => {
    if (layoutField === 'layout') {
      return ['simple-text', 'min-max'].includes(content?.component?.key ?? '');
    } else if (layoutField === 'search_layout') {
      return content?.searchComponent?.name === 'Min & Max';
    }
    return false;
  }, [content, layoutField]);

  return (
    <tr>
      <td className="w-100">
        <b>
          {groupContent.type}:{' '}
          {groupContent.content?.label || groupContent.content?.name}
        </b>
        <br />
        <small>{groupContent.content?.property?.name}</small>
        {isHandleStyle && (
          <>
            <br />

            <select
              className="form-control form-control-sm"
              value={styleValue}
              onChange={handleStyle}
            >
              <option value="">Default</option>
              <option value="component-secondary">Secondary</option>
              <option value="component-third">Third</option>
            </select>
          </>
        )}
      </td>
      <td>
        <Button
          small
          type="danger"
          onClick={() => {
            if (groupContent.content?.id) {
              onRemoveField(groupContent.content?.id, section);
            } else {
              onRemoveFieldByPosition(positionProps, section);
            }
          }}
        >
          <FaTrash />
        </Button>
      </td>
    </tr>
  );
};

const TemplateLayoutStructureItemContent: React.FC<Props> = ({
  content,
  rowNumber,
  colNumber,
  section,
}) => {
  return (
    <Table striped className="w-100">
      <tbody>
        {content.map((groupContent, key) => (
          <RenderGroupContentComponent
            key={key}
            groupContent={groupContent}
            positionProps={{
              rowNumber,
              colNumber,
              fieldIndex: key,
            }}
            section={section}
          />
        ))}
      </tbody>
    </Table>
  );
};

export default TemplateLayoutStructureItemContent;
