import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Route, useHistory, useLocation, useParams } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import {
  Button,
  Line,
  SimpleInput,
  SimpleTextArea,
  Text,
  Title,
} from '../../../../components';
import { MovePageIDToSKUProvider } from '../../../../components/MovePageIDToSKU';
import ButtonCheckbox from '../../../../components/ButtonCheckbox';
import SelectOfficeCard from '../../../../components/SelectOfficeCard';
import BoxIds from '../Update/CommonAttributes/BoxIds';
import CrateIds from '../Update/CommonAttributes/CrateIds';
import Provider from '../Update/Provider';
import WorkOrders from '../Update/WorkOrders';
import BagProvider from '../../../../services/bag';
import { gql, useApolloClient, useQuery } from '@apollo/client';
import Loader from '../../../../components/_organisms/Loader';
import { BoxId, CrateId, Office, PageId, Shape } from '../../../../../../types';
import useToast from '../../../../hooks/useToast';
import useErrorMessage from '../../../../hooks/useErrorMessage';
import useToggler from '@wgt/shared/hooks/useToggler';
import WorkOrderSchedule from '../../../../components/WorkOrderSchedule';
import {
  CREATE_PRODUCT_GQL,
  CREATE_PRODUCT_LEGACY_GQL,
  PRODUCT_GET_GQL,
  PRODUCT_PAGES_GQL,
} from './graphql';
import MediaCenter from '../Update/MediaCenter';
import ProductPageIdPut from '../ProductPageIdPut';
import { PRODUCT_PAGE_ID } from '../ProductPageIdPut/graphql';
import { removeCurrencyMask } from '../../../../helpers/CurrencyMaskInput';
import ProductPageIdRemoveButton from '../ProductPageIdPut/ProductPageIdRemoveButton';
import { convertPageIdFields } from '@wgt/converters';
import LearnToUse from '../../LearnToUse';
import Swal from 'sweetalert2';
import ProductTotals from './ProductTotals';
import './styles.scss';
import MovePageIDToCurrentSku from './MovePageIDToCurrentSku';

const removeId = ({ id, name }: BoxId | CrateId) => ({
  name,
  id: id?.toString().match(/^\d+$/) ? id : undefined,
});

interface ProductInput {
  name: string;
  sku: string;
  description: '';
  product_status_id: string;
  office?: Office;
  tray_id: string;
  lot_id: string;
  crates: CrateId[];
  boxes: BoxId[];
  category_id: string;
  pages: PageId[];
  page: PageId;
}

export default function ProductPut(): JSX.Element {
  const params = useParams<{ productId?: string; categoryId: string }>();
  const methods = useForm<ProductInput>({
    shouldUnregister: false,
    defaultValues: {
      name: '',
      sku: '',
      description: '',
      product_status_id: '',
      tray_id: '',
      crates: [{ name: '' }],
      boxes: [{ name: '' }],
      category_id: params.categoryId,
    },
  });
  const { t } = useTranslation(['product', 'common']);
  const history = useHistory();
  const location = useLocation();
  const { toast } = useToast();
  const [isScheduleFormOpen, toggleScheduleForm] = useToggler(false);
  const [activeScheduleId, setActiveScheduleId] = useState<string>();

  const { control, getValues, register, handleSubmit, formState } = methods;
  const { isSubmitting } = formState;

  const _crates = useFieldArray({
    control,
    name: 'crates',
    keyName: '__id',
  });

  const _boxes = useFieldArray({
    control,
    name: 'boxes',
    keyName: '__id',
  });

  const isEdit = !!params.productId;
  const { data, loading } = useQuery(PRODUCT_GET_GQL, {
    onCompleted: ({ product }) => {
      if (!product) {
        return;
      }

      methods.reset({
        office: product.office,
        sku: product.sku,
        name: product.name,
        tray_id: product.tray_id,
        lot_id: product.lot_id,
        description: product.description,
        pages: product.pages,
        product_status_id: product.productStatus?.id,
        category_id: product.category?.id,
        crates: product.crates?.length ? product.crates : [{ name: '' }],
        boxes: product.boxes?.length ? product.boxes : [{ name: '' }],
      });
    },

    variables: {
      id: params.productId ?? 'nullable',
      categoryId: params.categoryId,
      isEdit,
    },
    nextFetchPolicy: 'cache-first',
  });
  const errorHandler = useErrorMessage(getValues);

  const client = useApolloClient();
  const onSubmit = handleSubmit(
    async ({
      name,
      description,
      sku,
      tray_id,
      office,
      lot_id,
      boxes,
      crates,
      product_status_id,
      category_id,
      page,
    }) => {
      const product = {
        description,
        sku,
        tray_id: tray_id?.length ? tray_id : null,
        lot_id: lot_id?.length ? lot_id : null,
        product_status_id,
        category_id,
        name: name ?? '',
        boxes: boxes.map(removeId),
        crates: crates.map(removeId),
        office_id: Number(office?.id),
      };

      if (!isEdit) {
        try {
          const res = await client.mutate({
            mutation: CREATE_PRODUCT_GQL,
            variables: product,
          });
          history.push(
            `/crm/categories/${params.categoryId}/products/${res?.data.createProduct?.id}/`,
          );
          return res;
        } catch (e) {
          return errorHandler(e);
        }
      }

      const productPageId = page.id;
      const productPage = {
        name: page.name,
        category_id: page.category?.id ?? params.categoryId,
        position: !productPageId
          ? (data?.product?.pages?.length ?? 0) + 1
          : undefined,
        carat_weight: Number(
          removeCurrencyMask(
            typeof page.carat_weight === 'number' ||
              typeof page.carat_weight === 'string'
              ? `${page.carat_weight}`
              : '0',
          ),
        ),
        price_per_carat: Number(
          removeCurrencyMask(
            typeof page.price_per_carat === 'number' ||
              typeof page.price_per_carat === 'string'
              ? `${page.price_per_carat}`
              : '0',
          ),
        ),
        form_values: JSON.stringify(convertPageIdFields(page.fields) ?? {}),
        fields: JSON.stringify(page.fields ?? {}),
        shapes: page.shapes?.map((shape: Shape) => ({
          id: shape.id,
          descriptions: shape.descriptions?.map((desc) => desc.id),
        })),
      };
      try {
        const result = await client.mutate({
          mutation: gql`
            mutation updateProductAndPageId ($productId: ID!, $product: UpdateProductInput!, $productPage: ${
              !!productPageId
                ? 'UpdateProductPageIdInput'
                : 'ProductPageIdInput'
            }! ${!!productPageId ? ', $productPageId: ID!' : ''} ) {
              updateProduct(id: $productId, product: $product) {
                id
                pages {
                  ...productPageId_Part
                }
              }

              ${
                !!productPageId
                  ? 'updateProductPageId(id: $productPageId, page: $productPage)'
                  : 'createProductPageId(product_id: $productId, page: $productPage)'
              } {
                ...productPageId_Part
              }
            }
          ${PRODUCT_PAGE_ID}
          `,
          variables: {
            product,
            productPage,
            productPageId,
            productId: params.productId,
          },
          refetchQueries: [
            {
              query: PRODUCT_PAGES_GQL,
              variables: {
                id: params.productId,
              },
            },
          ],
        });

        if (!productPageId) {
          methods.reset();
          history.push(
            `/crm/categories/${params.categoryId}/products/${params.productId}/pages/${result.data?.createProductPageId.id}`,
          );
        }

        toast('success');

        return result;
      } catch (e) {
        errorHandler(e);
      }
    },
  );

  const isNewPageIDVisible = useMemo<boolean>(() => {
    const totalPages = data?.product?.category?.productType?.numberPages;
    return (
      Number(data?.product?.category?.categoryType?.id) !== 1 &&
      (totalPages === 0 || data?.product?.pages.length < totalPages)
    );
  }, [data?.product]);

  /** WORKAROUND - CREATED FOR MEDIA CENTER WORK IN PRODUCT CREATION */
  async function getProductId(message?: string): Promise<string | undefined> {
    if (!getValues('sku')) {
      toast('error', t('Please, provide your SKU first'));
      methods.setError('sku', { type: 'required' });
      return undefined;
    }
    const { isConfirmed } = await Swal.fire({
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: t('Ok, save then'),
      cancelButtonText: t('I will do it later'),
      confirmButtonColor: '#014a6a',
      icon: 'warning',
      title: t('Hey'),
      text:
        message ?? t('We will save current product form to upload your media'),
    });

    if (!isConfirmed) {
      return undefined;
    }

    const {
      description,
      sku,
      tray_id,
      lot_id,
      product_status_id,
      category_id,
      name,
      boxes,
      crates,
      office,
    } = getValues();
    try {
      const filteredBoxes = boxes.map(removeId).filter((x) => x.name?.length);
      const filteredCrates = crates.map(removeId).filter((x) => x.name?.length);
      const product = {
        description: description?.length ? description : undefined,
        sku: sku ?? undefined,
        tray_id: tray_id?.length ? tray_id : undefined,
        lot_id: lot_id?.length ? lot_id : undefined,
        product_status_id: product_status_id?.length
          ? product_status_id
          : undefined,
        category_id: category_id ?? params.categoryId,
        name: name?.length ? name : undefined,
        boxes: filteredBoxes.length ? filteredBoxes : undefined,
        crates: filteredCrates.length ? filteredCrates : undefined,
        office_id: office?.id ? Number(office?.id) : undefined,
      };
      const res = await client.mutate({
        mutation: CREATE_PRODUCT_LEGACY_GQL,
        variables: product,
      });

      return res.data?.createProductLegacy.id;
    } catch (e) {
      errorHandler(e);
      return undefined;
    }
  }

  useEffect(() => {
    if (!data?.product?.pages?.length || location.pathname.match(/pages/gi)) {
      return;
    }

    const firstPage = data?.product.pages[0];
    history.push(
      `/crm/categories/${params.categoryId}/products/${params.productId}/pages/${firstPage.id}`,
    );
  }, [location.pathname, data?.product]);

  const onNewPageId = async (
    e: // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | React.BaseSyntheticEvent<React.MouseEvent<HTMLButtonElement>, any, any>
      | undefined,
  ) => {
    const isValid = await methods.trigger();

    if (isValid) {
      await onSubmit(e);
      history.push(
        `/crm/categories/${params.categoryId}/products/${params.productId}/pages`,
      );
    }
  };

  return (
    <MovePageIDToSKUProvider source={[]}>
      <div className="update-product-screen">
        {loading && <Loader />}
        {!loading && (
          <>
            <div className="d-flex justify-content-between align-items-center header py-4">
              <div className="d-flex align-items-center">
                <Title heading="h1" className="mr-5">
                  {data?.category.name}
                </Title>
              </div>

              <div className="d-flex justify-content-end">
                <LearnToUse />

                <Route
                  path="/crm/categories/:categoryId/products/:productId/pages/:pageId"
                  component={ProductPageIdRemoveButton}
                />

                {isNewPageIDVisible && (
                  <Button
                    htmlType="button"
                    type="orange"
                    className="ml-3 outline"
                    onClick={onNewPageId}
                  >
                    {t('New Page ID')}
                  </Button>
                )}
                <Button
                  className="ml-3"
                  isLoading={isSubmitting}
                  disabled={isSubmitting}
                  onClick={onSubmit}
                >
                  {t('Finish')}
                </Button>
              </div>
            </div>

            {isEdit && (
              <Provider control={control}>
                <MediaCenter
                  productId={params.productId}
                  categoryId={params.categoryId}
                  getProductId={getProductId}
                />
              </Provider>
            )}
            {!isEdit && (
              <Row>
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={{ size: 6 }}
                  xl={{ size: 3, offset: 6 }}
                >
                  <div className="d-flex flex-column">
                    <div className="d-flex border flex-column p-4">
                      <div className="d-flex">
                        <Text type="muted" className="font-italic">
                          Tutorial
                        </Text>
                      </div>
                      <div className="d-flex">
                        <Text type="muted" className="font-italic">
                          How to Create a new Product
                        </Text>
                      </div>
                    </div>
                    <div
                      className="d-flex border flex-column p-4 rounded bg-secondary"
                      style={{ backgroundColor: '#90AEBA' }}
                    >
                      <Text className="font-italic text-white py-2">
                        1st register product by entering SKU and required IDS
                        2nd
                      </Text>
                      <Text className="font-italic text-white py-1">
                        2nd select Save to start recording product details with
                        media
                      </Text>
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} xl={3}>
                  <div className="d-flex flex-column border rounded p-3 ">
                    <Text className="p-2 font-italic">
                      1st. Register Product by entering SKU and required IDs
                    </Text>
                    <Text className="p-2 font-italic">
                      2nd. Enter pre-recorded Page ID located in different SKU.
                    </Text>
                    <Text className="p-2 font-italic">
                      3rd. Select (Add) and you moved Page ID into new product.
                    </Text>
                    <MovePageIDToCurrentSku disabled />
                  </div>
                </Col>
              </Row>
            )}

            <Route
              path="/crm/products/:id/edit/workOrders/:workOrderId/items/:workOrderItemId"
              component={WorkOrders}
            />
            <input type="hidden" ref={register} name="category_id" />
            <div className="common-attributes mt-4">
              <form onSubmit={onSubmit}>
                <FormProvider {...methods}>
                  <Row>
                    <Col lg={6} className="d-flex flex-column">
                      {isEdit && (
                        <SimpleInput
                          field="name"
                          label={t('Search name')}
                          required
                        />
                      )}
                      <Row className="mt-auto">
                        <Col className="d-flex flex-row" lg={8} md={10} xs={12}>
                          <Route
                            path={[
                              '/crm/categories/:categoryId/products/:productId/pages/:pageId',
                              '/crm/categories/:categoryId/products/create',
                            ]}
                            component={ProductTotals}
                          />
                        </Col>
                      </Row>
                    </Col>
                    {isEdit && (
                      <Col lg={6}>
                        <SimpleTextArea
                          field="description"
                          label={t('Description')}
                          className="textarea-description"
                        />
                      </Col>
                    )}
                  </Row>
                  {!isEdit && (
                    <Row className="mt-3">
                      <Col
                        md={{ size: 6, offset: 6 }}
                        lg={{ size: 4, offset: 8 }}
                        xl={{ size: 4, offset: 8 }}
                        sm={12}
                      >
                        <MovePageIDToCurrentSku getProductId={getProductId} />
                      </Col>
                    </Row>
                  )}
                  <div className="d-flex justify-content-end mt-3">
                    <Button
                      className="ml-3"
                      isLoading={isSubmitting}
                      disabled={isSubmitting}
                      onClick={onSubmit}
                    >
                      {t('Save')}
                    </Button>
                  </div>

                  <div className="card-group my-5 shadow-sm common-attributes-card">
                    <div className="card border-right-0">
                      <div className="card-header p-2">{t('SKU')}</div>
                      <div className="card-body p-2 pr-5">
                        <SimpleInput field="sku" required />
                      </div>
                    </div>
                    <div className="card border-right-0">
                      <div className="card-header p-2">{t('Lot ID')}</div>
                      <div className="card-body p-2 pr-5">
                        <SimpleInput field="lot_id" />
                      </div>
                    </div>
                    <div className="card border-right-0">
                      <div className="card-header p-2">{t('Tray ID')}</div>
                      <div className="card-body p-2 pr-5">
                        <SimpleInput field="tray_id" />
                      </div>
                    </div>
                    <BagProvider
                      value={{
                        crate: _crates,
                        box: _boxes,
                      }}
                    >
                      <>
                        <div className="card border-right-0 pb-5 pb-lg-0">
                          <div className="card-header p-2">{t('Box IDs')}</div>
                          <div className="card-body p-2 pr-5 position-relative">
                            <BoxIds />
                            <div className="box-id-btn-wrap">
                              <label>{t('Add Box Id')}</label>
                              <Button
                                small
                                onClick={() => _boxes.append({ name: '' })}
                                className="ml-2"
                              >
                                {t('common:Add')}
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header p-2">
                            {t('Crate IDs')}
                          </div>
                          <div className="card-body p-2 pr-5 position-relative">
                            <CrateIds />
                            <div className="crate-id-btn-wrap">
                              <label>{t('Add Crate Id')}</label>
                              <Button
                                small
                                onClick={() => _crates.append({ name: '' })}
                                className="ml-2"
                              >
                                {t('common:Add')}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </>
                    </BagProvider>
                  </div>

                  <ButtonCheckbox
                    field="product_status_id"
                    label={t('Status')}
                    options={data?.productsStatus.data ?? []}
                    controller={{ identifier: 'id', label: 'name' }}
                    required
                  />
                </FormProvider>
              </form>

              <FormProvider {...methods}>
                <form onSubmit={onSubmit}>
                  <Route
                    path="/crm/categories/:categoryId/products/:productId/pages/:pageId?"
                    component={ProductPageIdPut}
                  />
                </form>
              </FormProvider>

              <form onSubmit={onSubmit}>
                <FormProvider {...methods}>
                  <div className="mt-4">
                    <Title heading="h2">Office</Title>
                    <Line />
                    <Row>
                      <Col xs={4}>
                        <div className="d-flex justify-content-between">
                          <SelectOfficeCard
                            title={t('Office Location')}
                            options={data?.offices.data ?? []}
                            field="office"
                            placeholder={t('Choose an Office')}
                            required
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </FormProvider>
                <br />
              </form>
            </div>
          </>
        )}
        <div className="d-flex justify-content-end mt-3">
          <Route
            path="/crm/categories/:categoryId/products/:productId/pages/:pageId"
            component={ProductPageIdRemoveButton}
          />
          <Button
            className="ml-3"
            isLoading={isSubmitting}
            disabled={isSubmitting}
            onClick={onSubmit}
          >
            {t('Save')}
          </Button>
        </div>
        {isEdit && isScheduleFormOpen && (
          <WorkOrderSchedule
            scheduleId={activeScheduleId}
            products={[data?.product]}
            toggle={toggleScheduleForm}
            onCompleted={({ id }) => setActiveScheduleId(id)}
          />
        )}
      </div>
    </MovePageIDToSKUProvider>
  );
}
