import React from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button, FormGroup } from 'reactstrap';
import BtnLoading from '../BtnLoading';
import { Link } from 'react-router-dom';
import useQueryString from '../../../hooks/useQueryString';
import { useTranslation } from 'react-i18next';

interface FormProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleResetPassword: any;
  isSubmitting: boolean;
}
export interface FormValues {
  token: string;
  email: string;
  secret_phrase: string;
  password: string;
  password_confirmation: string;
}

const ResetPasswordForm: React.FC<FormProps> = ({
  handleResetPassword,
  isSubmitting,
}) => {
  const { t } = useTranslation(['authentication', 'common']);
  const { register, handleSubmit, errors, watch } = useForm();
  const [params] = useQueryString();

  return (
    <Form
      onSubmit={handleSubmit((values) => {
        handleResetPassword({
          ...values,
          token: params.token,
          secret_phrase: params?.newUser === '1' ? '' : values.secret_phrase,
        });
      })}
    >
      <Link to="/auth" className="forgot-link">
        {t('common:cancel')}
      </Link>
      <br />
      <FormGroup>
        <label htmlFor="email">{t('email.label')}</label>
        <input
          className={`form-control ${errors?.email && 'is-invalid'}`}
          id="email"
          name="email"
          placeholder={t('email.placeholder')}
          ref={register({
            required: true,
          })}
          defaultValue=""
          aria-invalid={errors?.email ? 'true' : 'false'}
        />
      </FormGroup>
      <FormGroup>
        <label htmlFor="password">{t('newPassword.label')}</label>
        <input
          className={`form-control ${errors?.password && 'is-invalid'}`}
          id="password"
          name="password"
          type="password"
          placeholder={t('newPassword.placeholder')}
          ref={register({
            maxLength: {
              message: 'Password must be a maximum of 12 characters',
              value: 12,
            },
            minLength: {
              message: 'Password must have at least 8 characters',
              value: 8,
            },
            required: true,
          })}
          defaultValue=""
          aria-invalid={errors?.password ? 'true' : 'false'}
        />
        {errors?.password && <span>{errors.password.message}</span>}
      </FormGroup>
      <FormGroup>
        <label htmlFor="password">{t('confirmNewPassword.label')}</label>
        <input
          className={`form-control ${
            errors?.password_confirmation && 'is-invalid'
          }`}
          id="password_confirmation"
          name="password_confirmation"
          placeholder={t('confirmNewPassword.placeholder')}
          type="password"
          ref={register({
            required: true,
            validate: (value) => {
              return value === watch('password');
            },
          })}
          defaultValue=""
          aria-invalid={errors?.password_confirmation ? 'true' : 'false'}
        />
        {errors?.password_confirmation && (
          <span>{t('Passwords do not match')}</span>
        )}
      </FormGroup>
      {params?.newUser !== '1' && (
        <FormGroup>
          <label htmlFor="secret_phrase">{t('secretPhrase.label')}</label>
          <input
            className={`form-control ${errors?.secret_phrase && 'is-invalid'}`}
            id="secret_phrase"
            name="secret_phrase"
            placeholder={t('secretPhrase.placeholder')}
            ref={register({
              required: true,
            })}
            defaultValue=""
            aria-invalid={errors?.secret_phrase ? 'true' : 'false'}
          />
        </FormGroup>
      )}
      <Button
        type="submit"
        disabled={isSubmitting}
        size="sm"
        color="success"
        block
      >
        {isSubmitting ? <BtnLoading /> : t('Update password')}
      </Button>
    </Form>
  );
};

export default ResetPasswordForm;
