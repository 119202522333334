import React from 'react';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { Form } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Country } from '@wgt/types';
import {
  SimpleInput,
  SimpleDropdown,
  Button,
} from '../../../../../../components';
import { COUNTRIES } from '../../../../Firms/graphql';
import SelectSearch from '../../../../../../components/SelectSearch';

const DEPARTMENTS = [
  { id: 1, name: 'Departament 1' },
  { id: 2, name: 'Departament 2' },
];

interface FilterProps {
  person_name?: string;
  departament_id?: number;
  phone?: string;
  state?: string;
  city?: string;
  postal_code?: string;
  country_id: { id: number };
}

export default function Filters(): JSX.Element {
  const { t } = useTranslation(['hr', 'common']);

  const methods = useForm();
  const { handleSubmit } = methods;

  const onSubmit = (variables: FilterProps) => {
    const data = {
      ...variables,
      country_id: variables.country_id.id,
    };
    console.log({ data });
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <SimpleInput
          field="person_name"
          placeholder={t('Person Name')}
          className="mb-4"
        />
        <SimpleInput field="firm" placeholder={t('Firm')} className="mb-2" />
        <SimpleDropdown
          placeholder={t('Department')}
          controller="id"
          field="departament_id"
          options={DEPARTMENTS}
          text="name"
          className="mb-4"
        />

        <SimpleInput field="phone" placeholder={t('Phone')} className="mb-2" />
        <SimpleInput
          field="address"
          placeholder={t('Address')}
          className="mb-2"
        />
        <div className="simple-dropdown mb-2">
          <Controller
            name="country_id"
            defaultValue={methods.getValues('country_id') ?? ''}
            render={({ onChange }) => (
              <SelectSearch
                gql={COUNTRIES}
                getOptionLabel={(e: Country) => `${e.name ?? ''} `}
                getOptionValue={(e: Country) => `${e.id}`}
                name="name"
                placeholder={t('Country')}
                onChange={onChange}
              />
            )}
          />
        </div>
        <SimpleInput
          field="state"
          placeholder={t('State / Province')}
          className="mb-2"
        />
        <SimpleInput field="city" placeholder={t('City')} className="mb-2" />
        <SimpleInput
          field="postal_code"
          placeholder={t('Zip / Postal Code')}
          className="mb-2"
        />
        <Button
          type="secondary"
          htmlType="submit"
          className="w-100 text-center d-block"
        >
          {t('common:Apply')}
        </Button>
      </Form>
    </FormProvider>
  );
}
