import React, { useState, useEffect } from 'react';
import { FiPlus } from 'react-icons/fi';
import { ExpandedDropdownOptions } from '@wgt/types';
import { UncontrolledPopover } from 'reactstrap';
import classnames from 'classnames';
import { useFormContext } from 'react-hook-form';
import Variations from './Variations';

interface Props {
  option: ExpandedDropdownOptions;
  uuid: string;
  toggleCloseOnExternalClick: () => void;
  field: string;
}

export default function PropertyValueItem({
  option,
  uuid,
  toggleCloseOnExternalClick,
  field,
}: Props): JSX.Element {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const toggle = () => {
    setPopoverOpen(!popoverOpen);
    toggleCloseOnExternalClick();
  };

  const { watch, setValue } = useFormContext();

  const variationFields = watch(`${field}.${option.key}.variations`, {});

  const hasSelected = Object.values(variationFields).some(Boolean);
  const total = Object.values(variationFields).filter((item) => item === true)
    .length;

  useEffect(() => setValue(`${field}.${option.key}.selected`, hasSelected), [
    hasSelected,
  ]);

  return (
    <div className="d-flex justify-content-between mb-2">
      <div className="font-regular d-flex align-items-center">
        <input type="checkbox" disabled={true} checked={hasSelected} />
        <span
          className={classnames({
            disabled: !option.variations?.length,
          })}
        />
        <label
          className={classnames('mb-0', {
            'text-muted': !option.variations?.length,
          })}
        >
          {option.name}
        </label>
      </div>
      <div>
        <button
          type="button"
          className="btn btn-circle"
          id={uuid}
          disabled={!option.variations?.length}
        >
          {total > 0 ? total : <FiPlus />}
        </button>
        <UncontrolledPopover
          trigger="legacy"
          target={uuid}
          placement="bottom"
          disabled={!option.variations?.length}
          className="dropdown-popover-sub"
          isOpen={popoverOpen}
          toggle={toggle}
        >
          <Variations
            option={option}
            field={`${field}.${option.key}.variations`}
          />
        </UncontrolledPopover>
      </div>
    </div>
  );
}
