import React from 'react';
import NavbarComponent from '../Navbar';
import CustomFooter from '../CustomFooter';
import { Container } from 'reactstrap';
import '../../../assets/scss/main.wgt.scss';
import '../../../assets/scss/layoutComponents.scss';

const WgtLayout: React.FC = ({ children }) => {
  return (
    <>
      <NavbarComponent />
      <Container className="full-content" fluid={true}>
        {children}
      </Container>
      <CustomFooter />
    </>
  );
};

export default WgtLayout;
