import { TFunctionResult } from 'i18next';
import { toast as showToast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

type Methods = 'success' | 'error' | 'warning';

interface UseToast {
  toast(method: Methods, msg?: TFunctionResult): void;
}

export default function useToast(): UseToast {
  const { t } = useTranslation();
  const messages = {
    error(msg: TFunctionResult) {
      return msg || t('Something went wrong. Please try again later');
    },
    success(msg: TFunctionResult) {
      return msg || t('Record Successfully Saved');
    },
    warning(msg: TFunctionResult) {
      return msg || t('Warning');
    },
  };

  const toast = (method: Methods, msg?: TFunctionResult) => {
    const methodFn = showToast[method];

    return methodFn(messages[method](msg), {
      autoClose: 2000,
      position: 'top-center',
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
    });
  };
  return { toast };
}
