import React from 'react';
import InternalRoutes from '../../../components/_organisms/InternalRoutes';
import useCrmRoutes from '../../../hooks/useCrmRoutes';
import List from './List';
import Put from './Put';

export default function FirmOffice(): JSX.Element {
  const { actualRoute } = useCrmRoutes();

  const routes = [
    {
      component: Put,
      name: 'Create Firm Office',
      path: `/crm/${actualRoute}/:firmId/offices/create`,
      exact: true,
    },
    {
      component: List,
      name: 'List Firm Office',
      path: `/crm/${actualRoute}/:firmId/offices`,
      exact: true,
    },
    {
      component: Put,
      name: 'Edit Firm Office',
      path: `/crm/${actualRoute}/:firmId/offices/:officeId/edit`,
      exact: true,
    },
  ];

  return <InternalRoutes routes={routes} />;
}
