import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import { useForm, FormProvider } from 'react-hook-form';
import { Button } from '../';
import UrgencySelector from '../UrgencySelector';
import { FaGlobe } from 'react-icons/fa';

interface Props {
  totalPrice: number;
}

export default function WorkOrderItemsFooter({
  totalPrice,
}: Props): JSX.Element {
  const methods = useForm({
    defaultValues: {
      urgency: '',
      page_id: '',
    },
  });

  const { handleSubmit } = methods;

  const onSubmit = handleSubmit(() => {
    alert(
      'We will make the Page ID available soon, so you to be able to set the urgency of the work order.',
    );
  });

  const handleTraceDocument = () => {
    alert('Available soon!');
  };

  const { t } = useTranslation('common');
  return (
    <Row className="mt-2" noGutters>
      <Col
        xs={{ size: 12, order: 3 }}
        xl={{ size: 8, order: 0 }}
        className="mt-2 mt-xl-0 d-flex justify-content-between"
      >
        <div>
          <Button htmlType="button" onClick={onSubmit}>
            {t('common:Finish')}
          </Button>
        </div>
        <div>
          <FormProvider {...methods}>
            <div className="d-flex align-items-center">
              <p className="mb-0 mr-2 text-right text-nowrap">
                Page ID Urgency
              </p>
              <UrgencySelector field="urgency" />
            </div>
          </FormProvider>
        </div>
        <div>
          <Button
            iconBefore={FaGlobe}
            type="primary"
            className="btn-trace-document text-nowrap"
            onClick={handleTraceDocument}
          >
            {t('common:Trace Document')}
          </Button>
        </div>
      </Col>
      <Col
        lg={{ size: 1, offset: 8 }}
        xl={{ offset: 0 }}
        xs="4"
        md={{ size: 2, offset: 6 }}
        className="d-flex align-items-center justify-content-start justify-content-md-end pr-0 pr-md-4"
      >
        <strong>{t('common:Total')}</strong>
      </Col>
      <Col
        md="2"
        xs="4"
        className="pl-xl-4 d-flex align-items-center justify-content-start"
      >
        <strong>4:00</strong>
      </Col>
      <Col
        lg="1"
        md="2"
        xs="4"
        className="d-flex align-items-center justify-content-end"
      >
        <strong>${totalPrice}</strong>
      </Col>
    </Row>
  );
}
