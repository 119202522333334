import React from 'react';
import useCrmRoutes from '../../../hooks/useCrmRoutes';
import List from './List';
import Details from './Details';
import Edit from './Edit';
import InternalRoutes from '../../../components/_organisms/InternalRoutes';
import Create from './Create';

import EditTemplateField from './Edit/TemplateFields';
import EditTemplateLayout from './Edit/TemplateLayout';
import TemplateShapes from './Edit/TemplateShapes';

const Templates: React.FC = () => {
  const { isCrmInternalRoute, actualRoute } = useCrmRoutes();

  const routes = [
    {
      component: Create,
      name: 'Templates',
      path: `/crm/${actualRoute}/create`,
    },
    {
      component: Details,
      exact: true,
      name: 'Templates',
      path: `/crm/${actualRoute}/:id`,
    },

    {
      component: Edit,
      name: 'Templates',
      path: `/crm/${actualRoute}/:id/edit`,
      routes: [
        {
          name: 'Edit field template',
          path: `/crm/${actualRoute}/:id/edit`,
          exact: true,
          component: EditTemplateField,
        },
        {
          name: 'ADD STOCK template',
          path: `/crm/${actualRoute}/:id/edit/layout/:layout`,
          exact: true,
          component: EditTemplateLayout,
        },
        {
          name: 'Shapes',
          path: `/crm/${actualRoute}/:id/edit/shapes`,
          exact: true,
          component: TemplateShapes,
        },
      ],
    },
  ];

  if (isCrmInternalRoute) {
    return <InternalRoutes routes={routes} />;
  }

  return <List />;
};

export default Templates;
