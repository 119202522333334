import React from 'react';
import CustomHeader from '../../../../components/_molecules/CustomHeader';
import { LIST_ROLES_GQL, DELETE_ROLE_GQL } from '../graphql';
import { Role } from '../types';
import Table from '../../../../components/_organisms/Table';

const List: React.FC = () => {
  return (
    <div>
      <CustomHeader title={'List Roles'} showCreate />
      <Table
        hover
        striped
        node="roles"
        columns={[{ label: 'Name', field: 'name' }]}
        query={LIST_ROLES_GQL}
        deleteMutation={DELETE_ROLE_GQL}
        getMutationArgs={(item: Role) => ({ id: item.id })}
        getConfirmMessage={(item: Role) => `Confirm delete of ${item?.name}?`}
      />
    </div>
  );
};

export default List;
