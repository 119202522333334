import React, { useState, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaTrash } from 'react-icons/fa';
import { Abbreviation } from '@wgt/types';
import { Button } from '../../../../../../../components';
import ConfirmDialog from '../../../../../../../components/_molecules/ConfirmDialog';
import { useBagContext } from '../../../../../../../services/bag';

interface AbbreviationProps {
  abbreviation: Abbreviation;
  index: number;
  total: number;
}

export default function AbbreviationsInput({
  abbreviation,
  index,
  total,
}: AbbreviationProps): JSX.Element {
  const { t } = useTranslation('common');
  const { register, getValues, setValue } = useFormContext();

  const {
    abbreviation: { remove },
  } = useBagContext<{ abbreviation: { remove(i: number): void } }>();

  const [showRemoveConfirm, setShowRemoveConfirm] = useState<boolean>(false);

  const onConfirm = useCallback(async () => {
    remove(index);
    setShowRemoveConfirm(false);
  }, [abbreviation, index]);

  const onClickRemove = (e: React.MouseEvent) => {
    e.preventDefault();
    setShowRemoveConfirm(true);
  };

  const handleKeywordKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 32) {
      const name = getValues(`abbreviations[${index}].name`);
      setValue(`abbreviations[${index}].name`, name.replace(/ /g, '').trim());
    }
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-end mb-2">
        <input
          name={`abbreviations[${index}].name`}
          ref={register()}
          defaultValue={abbreviation.name}
          className="form-control w-100"
          maxLength={50}
          onKeyUp={handleKeywordKeyPress}
        />

        {total > 1 && (
          <Button
            htmlType="button"
            onClick={onClickRemove}
            small
            type="danger"
            className="ml-2"
          >
            <FaTrash />
          </Button>
        )}
      </div>
      <ConfirmDialog
        isShown={showRemoveConfirm}
        onConfirm={onConfirm}
        title={t('Are you sure you want to delete this record')}
        onClose={() => setShowRemoveConfirm(false)}
      />
    </>
  );
}
