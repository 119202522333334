import React from 'react';
import { MediaCenterItemBase, PageId } from '@wgt/types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ATTACH_PAGE_ID_TO_FILE, CREATE_PAGE_ID } from '../../graphql';
import { useBagContext } from '../../../../../../../services/bag';
import { MediaCenterController } from '../../../../../../../hooks/useMediaCenterController';
import { useMutation } from '@apollo/client';
import { ADD_STOCK_GQL } from '../../../../graphql';
import { useProductUpdate } from '../../../Provider';
import useToast from '../../../../../../../hooks/useToast';

interface ThumbnailItemProps {
  item: MediaCenterItemBase;
  imageProp: string;
  loading?: boolean;
  active?: string | null;
}

const ThumbnailItem: React.FC<ThumbnailItemProps> = ({
  item,
  loading,
  imageProp,
  active,
}) => {
  const { setExpanded, productId } = useBagContext<MediaCenterController>();
  const { t } = useTranslation('mediaCenter');
  const { filters, pages } = useProductUpdate();
  const { toast } = useToast();

  const refetchQueries = [{ query: ADD_STOCK_GQL, variables: filters }];

  const [attachPageIdToFile] = useMutation(ATTACH_PAGE_ID_TO_FILE, {
    refetchQueries,
  });
  const [createPageId] = useMutation<{ createProductPageId: PageId }>(
    CREATE_PAGE_ID,
    {
      onCompleted: ({ createProductPageId }) => [
        attachPageIdToFile({
          variables: {
            id: Number(item.id),
            page_id: createProductPageId?.id,
          },
        }),
      ],
    },
  );

  const handleClick = () => {
    if (!active) {
      return [setExpanded(true), toast('error', t('Please select a PageID'))];
    }
    const pageIndex = pages?.fields?.findIndex((page) => page.__id === active);
    const pageSelected: PageId = pages?.fields?.[pageIndex];

    return pageSelected.id
      ? attachPageIdToFile({
          variables: {
            id: Number(item.id),
            page_id: pageSelected.id,
          },
        })
      : createPageId({
          variables: {
            product_id: Number(productId),
            page: {
              name: pageSelected.name,
              position: pageIndex + 1,
              category_id: Number(pageSelected.category?.id),
            },
          },
        });
  };

  return (
    <div
      className={classnames('thumbnail-item', {
        disabled: loading,
      })}
      onClick={handleClick}
    >
      <img src={item[imageProp]} />
    </div>
  );
};

export default ThumbnailItem;
