import React, { useContext, useState } from 'react';
import { TemplateFieldsContext } from '../../../../../../../hooks/useTemplateFieldsContext';
import { FaEdit } from 'react-icons/fa';
import TemplateTitleForm from './TemplateTitleForm';
import { Button } from '../../../../../../../components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const TemplateTitle: React.FC = () => {
  const { template, fields } = useContext(TemplateFieldsContext);
  const [isEditing, setEditing] = useState(false);
  const { t } = useTranslation('template');
  return (
    <div className="template-title">
      {!isEditing && template?.name && (
        <h3>
          {template?.name}
          <button onClick={() => setEditing(true)} className="controller">
            <FaEdit />
          </button>
        </h3>
      )}
      {isEditing && template?.name && (
        <TemplateTitleForm setEditing={setEditing} template={template} />
      )}
      {!!fields?.length && (
        <div>
          <Link
            to={`/crm/templates/${template?.id}/edit/shapes`}
            className="text-decoration-none"
          >
            <Button type="secondary">Shapes</Button>
          </Link>
          <Link
            to={`/crm/templates/${template?.id}/edit/layout/add-stock`}
            className="ml-2 text-decoration-none"
          >
            <Button>{t('Add Stock layout')}</Button>
          </Link>
          <Link
            to={`/crm/templates/${template?.id}/edit/layout/search-stock`}
            className="text-decoration-none"
          >
            <Button className="ml-2">{t('Search Stock Layout')}</Button>
          </Link>
          <Link
            to={`/crm/templates/${template?.id}/edit/layout/results`}
            className="text-decoration-none"
          >
            <Button className="ml-2">
              {t('Add Stock from Search Stock Layout')}
            </Button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default TemplateTitle;
