import React, { ChangeEvent } from 'react';
import { Row, Col, FormGroup, Input, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Title, TooltipHelp } from '../../../../../../../components';
import usePointOfSaleUpdate from '../../../usePointOfSaleUpdate';
import CreatePolicy from '../CreatePolicy';

export default function Header(): JSX.Element {
  const { t } = useTranslation(['pointOfSales', 'common']);
  const { setFilterFavouritePolicies } = usePointOfSaleUpdate();

  function handleInputChange(event: ChangeEvent<HTMLInputElement>): void {
    const { value } = event.target;
    setFilterFavouritePolicies(value);
  }

  return (
    <Row noGutters className="p-4 favourite-header">
      <Col xs="3">
        <Title heading="h2">{t('Favourites')}</Title>
        <FormGroup className="simple-input mt-4 mb-0">
          <Input
            type="text"
            name="filter"
            id="filter"
            placeholder={t('common:Filter')}
            onChange={handleInputChange}
          />
        </FormGroup>
      </Col>
      <Col xs="2" className="pl-5 d-flex align-items-end">
        <FormGroup className="simple-input mb-0 w-100">
          <Label>{t('Policy')}</Label>
          <CreatePolicy />
        </FormGroup>
      </Col>
      <Col xs="7" className="d-flex align-items-end w-100">
        <FormGroup className="simple-input mb-0 d-flex w-100">
          <Label
            for="securityReference"
            xs={1}
            className="text-right px-0 mb-0"
          >
            {t('Security Reference')}
            <br />
            <TooltipHelp
              description={t('Security Reference Description Tooltip')}
            />
          </Label>
          <Col xs={11}>
            <Input
              type="textarea"
              name="securityReference"
              id="securityReference"
              placeholder={t('Event Description')}
            />
          </Col>
        </FormGroup>
      </Col>
    </Row>
  );
}
