import React from 'react';
import { useTranslation } from 'react-i18next';
import { Paragraph } from 'evergreen-ui';

export default function TraceDocumentSectionFooter(): JSX.Element {
  const { t } = useTranslation('traceDocuments');
  return (
    <div className="trace-documents-sidesheet-document-footer p-5 d-flex justify-content-between">
      <div className="mr-5">
        <Paragraph>trace@worldgemtrade.com worldgemtrade.com</Paragraph>
        <Paragraph>
          {t(`Copyrights`, {
            year: '2021',
          })}
        </Paragraph>
      </div>
      <div>
        <Paragraph>{t('Documents Fairtrade Information')}</Paragraph>
        <Paragraph>{t('Anti Money Laudering Statement')}</Paragraph>
      </div>
    </div>
  );
}
