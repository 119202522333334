import React, { useMemo } from 'react';
import { CardColumns } from 'reactstrap';
import { Permission, Employee } from '@wgt/types';
import { useQuery } from '@apollo/client';
import Loader from '../../../../../../components/_organisms/Loader';
import { LIST_PERMISSIONS, LIST_USER_PERMISSIONS } from './graphql';
import PermissionScope from './PermissionScope';

interface Props {
  employee: Employee;
}

export default function Permissions({ employee }: Props): JSX.Element {
  const { data, loading } = useQuery<{ permissions: { data: Permission[] } }>(
    LIST_PERMISSIONS,
  );

  const { data: dataUserPermissions, refetch } = useQuery<{
    userPermissions: { data: Permission[] };
  }>(LIST_USER_PERMISSIONS, {
    fetchPolicy: 'no-cache',
    variables: {
      userId: employee.id,
    },
  });

  const listPermissions = useMemo(() => {
    const scope = 'scope' || 0;
    return data?.permissions?.data?.reduce((result, currentValue) => {
      (result[currentValue[scope] || ''] =
        result[currentValue[scope] || ''] || []).push(currentValue);
      return result;
    }, {});
  }, [data?.permissions?.data]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center my-3">
        <Loader round />
      </div>
    );
  }

  return (
    <CardColumns className="mt-3 mx-3">
      {Object.keys(listPermissions || {}).map((scope, index) => (
        <PermissionScope
          key={`card-${index}`}
          scope={scope}
          permissions={listPermissions?.[scope]}
          userId={employee.id}
          userPermissions={dataUserPermissions?.userPermissions?.data?.filter(
            (permission: Permission) => permission.scope === scope,
          )}
          refetchUserPermission={refetch}
        />
      ))}
    </CardColumns>
  );
}
