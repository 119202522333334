import React, { useMemo } from 'react';
import { ParcelType } from '.';
import classnames from 'classnames';
import { useFormContext } from 'react-hook-form';
import { useProductUpdate } from '../Provider';

interface ParcelTypeItemProps {
  parcelType: ParcelType;
  name: string;
  onChange(value: number): void;
}

const ParcelTypeItem: React.FC<ParcelTypeItemProps> = ({
  parcelType,
  name,
  onChange,
}) => {
  const { isFormEnabled } = useProductUpdate();
  const { watch } = useFormContext();
  const watchValue = watch(name);

  const active = useMemo(() => Number(watchValue) === Number(parcelType.id), [
    watchValue,
  ]);

  const onClick = () => {
    if (!isFormEnabled) {
      return;
    }

    onChange(parcelType.id);
  };

  return (
    <div
      className={classnames('parcel-type-item', {
        active,
        disabled: !isFormEnabled,
      })}
      onClick={onClick}
    >
      <span className="parcel-type-title">{parcelType.title}</span>
      <span className="parcel-type-description">{parcelType.description}</span>
    </div>
  );
};

export default ParcelTypeItem;
