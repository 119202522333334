import React from 'react';
import { Row, Col, FormGroup, Label } from 'reactstrap';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { FiX } from 'react-icons/fi';
import { IconButton } from 'evergreen-ui';
import { SimpleInput } from '../../';
import { useBagContext } from '../../../services/bag';

interface PlanNextPaymentProps {
  index: number;
}

export default function PlanNextPaymentInputs({
  index,
}: PlanNextPaymentProps): JSX.Element {
  const { t } = useTranslation('pointOfSales');

  const {
    planNextPayment: { remove },
  } = useBagContext<{
    planNextPayment: { remove(i: number): void };
  }>();

  const { setValue, watch } = useFormContext();

  const watchInputs = watch([
    `plan_next_payment[${index}].payment_after_date`,
    `plan_next_payment[${index}].payment_expected_amount`,
  ]);

  return (
    <Row>
      <Col xs="5">
        <FormGroup className="simple-input">
          <Label className="label-plan-next-payment">
            {t('Payment after')}
          </Label>
          <DatePicker
            dateFormat="MMMM d, yyyy"
            selected={
              watchInputs[`plan_next_payment[${index}].payment_after_date`]
            }
            onChange={(date: Date) =>
              setValue(`plan_next_payment[${index}].payment_after_date`, date)
            }
            className="form-control"
          />
        </FormGroup>
      </Col>
      <Col xs="6" className="pt-4">
        <SimpleInput
          type="number"
          placeholder={t('Expected Amount')}
          field={`plan_next_payment[${index}].payment_expected_amount`}
          defaultValue={
            watchInputs[`plan_next_payment[${index}].payment_expected_amount`]
          }
          required
        />
      </Col>
      <Col xs="1" className="pt-4 d-flex justify-content-end">
        {!!index && (
          <IconButton
            type="button"
            appearance="minimal"
            icon={FiX}
            onClick={() => remove(index)}
            className="btn-icon-remove"
          />
        )}
      </Col>
    </Row>
  );
}
