import React from 'react';
import { useLocation } from 'react-router-dom';
import CustomHeader from '../../../../components/_molecules/CustomHeader';
import Table from '../../../../components/_organisms/Table';
import { LIST } from '../graphql';

export default function FirmOfficeList(): JSX.Element {
  const location = useLocation();
  return (
    <div>
      <CustomHeader
        title={'List Firm Offices'}
        showCreate
        createLink={`${location.pathname}/create`}
      />
      <Table
        hover
        striped
        columns={[{ label: 'Name', field: 'name' }]}
        query={LIST}
        node="offices"
      />
    </div>
  );
}
