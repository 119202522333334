import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { EmployeeJoinRequest } from '@wgt/types';
import { useMutation } from '@apollo/client';
import { Row, Col, Collapse } from 'reactstrap';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';
import ConfirmDialog from '../../../../components/_molecules/ConfirmDialog';
import { Button } from '../../../../components';
import {
  DELETE_EMPLOYEE_JOIN_REQUEST,
  LIST_EMPLOYEE_JOIN_REQUESTS,
} from '../graphql';
import useToast from '../../../../hooks/useToast';
import useAnchor from '../../../../hooks/useAnchor';
import ApprovalForm from './ApprovalForm';

interface Props {
  employee: EmployeeJoinRequest;
}

export default function Item({ employee }: Props): JSX.Element {
  const [showRemoveConfirm, setShowRemoveConfirm] = useState<boolean>(false);
  const { t } = useTranslation(['hr', 'common']);

  const { toast } = useToast();
  const location = useLocation();
  const anchor = useAnchor();

  const [collapse, setCollapse] = useState(false);
  const toggle = () => setCollapse(!collapse);

  const [removeRequestToJoin] = useMutation(DELETE_EMPLOYEE_JOIN_REQUEST, {
    variables: { id: employee.id },
    onCompleted: () => {
      toast('success');
    },
    refetchQueries: [{ query: LIST_EMPLOYEE_JOIN_REQUESTS }],
  });

  const employeeBase64 = useMemo(() => `e${btoa(employee.id)}`, [employee]);

  useEffect(() => {
    if (location.hash) {
      if (location.hash.substr(1) === employeeBase64) {
        toggle();
        setTimeout(anchor.bind(null, employeeBase64), 300);
      }
    }
  }, [location.hash, employeeBase64]);

  const onClickDelete = () => {
    setShowRemoveConfirm(true);
  };

  const onConfirm = useCallback(async () => {
    await removeRequestToJoin();
    setShowRemoveConfirm(false);
  }, [employee]);

  return (
    <>
      <Row
        id={employeeBase64}
        className={classnames('border rounded mb-3 mx-0 py-3', {
          'bg-light': collapse,
        })}
      >
        <Col lg="3" className="d-flex align-items-center">
          <p className="mb-0">
            <strong>
              {employee.first_name} {employee.last_name}
            </strong>
          </p>
        </Col>
        <Col lg="2" className="d-flex align-items-center">
          <p className="mb-0">{employee.phone}</p>
        </Col>
        <Col lg="2" className="d-flex align-items-center">
          <p className="mb-0">{employee.email}</p>
        </Col>

        <Col lg="5" className="d-flex align-items-center justify-content-end">
          <Button onClick={toggle}>{t('Request to Join')}</Button>
          <div className="ml-3 btn-delete-width">
            {!collapse && (
              <Button small type="danger" onClick={onClickDelete}>
                {t('common:Delete')}
              </Button>
            )}
          </div>
        </Col>

        <Col lg="12">
          <Collapse
            isOpen={collapse}
            className="border rounded mt-2 bg-white p-3"
          >
            <ApprovalForm
              employee={employee}
              onDelete={onClickDelete}
              onToggle={toggle}
            />
          </Collapse>
        </Col>
      </Row>

      <ConfirmDialog
        isShown={showRemoveConfirm}
        onConfirm={onConfirm}
        title={t('Are you sure you want to delete this employee')}
        onClose={() => setShowRemoveConfirm(false)}
      />
    </>
  );
}
