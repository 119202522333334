import { PointOfSaleBase, PointOfSaleAmounts } from '@wgt/types';

export const pointOfSaleTotals = (
  pointOfSale: PointOfSaleAmounts,
): { total: number; total_due: number } => {
  const {
    sub_total = 0,
    discount = 0,
    shipping = 0,
    interest = 0,
    custom_duty = 0,
    tax = 0,
    paid = 0,
  } = pointOfSale;
  const total =
    Number(sub_total) -
    Number(discount) +
    Number(shipping) +
    Number(interest) +
    Number(custom_duty) +
    Number(tax);
  const totalDue = Number(total) - Number(paid);
  return { total, total_due: totalDue };
};

export const convertPointOfSale = (
  pointOfSale: PointOfSaleBase,
): PointOfSaleBase => {
  const { products } = pointOfSale;
  const subTotal =
    products?.reduce(
      (total, product) => total + (product.total_price ?? 0),
      0,
    ) ?? 0;

  const convertedPointOfSale: PointOfSaleBase = {
    ...pointOfSale,
    office_id: `${pointOfSale.office?.id}` ?? '0',
    sub_total: subTotal,
  };

  return {
    ...convertedPointOfSale,
    ...pointOfSaleTotals(convertedPointOfSale),
  };
};
