import React from 'react';
import { Row, Col } from 'reactstrap';
import { ExpandedDropdownOptions } from '@wgt/types';
import { useTranslation } from 'react-i18next';
import PropertyValueItem from './PropertyValueItem';

interface Props {
  label: string;
  numberColumns: number;
  values: ExpandedDropdownOptions[];
  toggleCloseOnExternalClick: () => void;
  field: string;
}

export default function PropertiesContent({
  label,
  numberColumns,
  values,
  toggleCloseOnExternalClick,
  field,
}: Props): JSX.Element {
  const { t } = useTranslation('common');

  return (
    <>
      <Row
        className={`d-flex popover-titles row-cols-${numberColumns}`}
        style={{ width: `${320 * numberColumns}px` }}
      >
        {[...Array(numberColumns)].map((_, key) => (
          <Col className="d-flex justify-content-between" key={key}>
            <p>{label}</p>
            <p>
              <i>{t('Modifier')}</i>
            </p>
          </Col>
        ))}
      </Row>

      <Row
        className={`popover-body row-cols-${numberColumns}`}
        style={{ width: `${320 * numberColumns}px` }}
      >
        {values.map((item: ExpandedDropdownOptions, keyValue: number) => (
          <Col className="d-flex flex-column" key={`col-option-${keyValue}`}>
            <PropertyValueItem
              option={item}
              uuid={`option-${keyValue}`}
              toggleCloseOnExternalClick={toggleCloseOnExternalClick}
              field={field}
            />
          </Col>
        ))}
      </Row>
    </>
  );
}
