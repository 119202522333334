import { gql } from '@apollo/client';
import { paginatorInfoFragment } from '../../../services/graphql/paginator.gql';

export const LIST_NEWSLETTER_GQL = gql`
  query newsletterSubscriptions($page: Int, $first: Int) {
    newsletterSubscriptions(page: $page, first: $first) {
      data {
        name
        email
        company_name
      }
      paginatorInfo {
        ...paginatorInfo_Part
      }
    }
  }
  ${paginatorInfoFragment}
`;
