import { gql } from '@apollo/client';
import { paginatorInfoFragment } from '../../../services/graphql/paginator.gql';

export const officeDisplayFragment = gql`
  fragment officeDisplay_Part on Office {
    id
    name
    phone
    firm {
      id
    }
  }
`;

export const officeAddressFragment = gql`
  fragment officeAddress_Part on Office {
    address
    postal_code
    city
    state
    country {
      id
      name
    }
  }
`;

export const CREATE = gql`
  mutation createOffice(
    $name: String!
    $address: String!
    $phone: String
    $postal_code: String
    $city: String!
    $state: String!
    $country_id: ID!
    $firm_id: ID!
  ) {
    createOffice(
      name: $name
      address: $address
      phone: $phone
      postal_code: $postal_code
      city: $city
      state: $state
      country_id: $country_id
      firm_id: $firm_id
    ) {
      ...officeDisplay_Part
      ...officeAddress_Part
    }
  }
  ${officeDisplayFragment}
  ${officeAddressFragment}
`;

export const UPDATE = gql`
  mutation updateOffice($id: ID!, $office: UpdateOfficeInput) {
    updateOffice(id: $id, office: $office) {
      ...officeDisplay_Part
      ...officeAddress_Part
    }
  }
  ${officeDisplayFragment}
  ${officeAddressFragment}
`;

export const LIST = gql`
  query listOffices($page: Int, $filter: OfficeFilterInput) {
    offices(page: $page, filter: $filter) {
      data {
        ...officeDisplay_Part
      }
      paginatorInfo {
        ...paginatorInfo_Part
      }
    }
  }
  ${paginatorInfoFragment}
  ${officeDisplayFragment}
`;

export const GET = gql`
  query getOffice($id: ID!) {
    office(id: $id) {
      ...officeDisplay_Part
      ...officeAddress_Part
    }
  }
  ${officeDisplayFragment}
  ${officeAddressFragment}
`;

export const GET_UPDATE_SCREEN = gql`
  query updateScreen($id: ID!, $isEdit: Boolean!) {
    office(id: $id) @include(if: $isEdit) {
      ...officeDisplay_Part
      ...officeAddress_Part
    }
    countries(first: 1000) {
      data {
        id
        name
      }
    }
  }
  ${officeDisplayFragment}
  ${officeAddressFragment}
`;
