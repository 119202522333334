import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import useCrmRoutes from '../../../../hooks/useCrmRoutes';
import { GET_SHAPE_GROUPS_GQL } from '../graphql';

import Loader from '../../../../components/_organisms/Loader';
import CustomHeader from '../../../../components/_molecules/CustomHeader';

const Details: React.FC = () => {
  const { setCustomTitle } = useCrmRoutes();

  const params = useParams<ParamTypes>();

  const { loading, data } = useQuery(GET_SHAPE_GROUPS_GQL, {
    skip: !params?.id,
    variables: {
      id: params.id,
    },
  });

  useEffect(() => {
    if (data?.shapeGroup?.title) {
      setCustomTitle(data.shapeGroup.title);
    }
  }, [data]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <CustomHeader
        title={`${data?.shapeGroup?.title || 'Details'}`}
        showList
      />
    </div>
  );
};

export default Details;
