import React, { useState } from 'react';
import ShapesSelector from './ShapesSelector';
import TemplateShapesList from './TemplateShapesList';
import './styles.scss';
import { useMutation, useQuery } from '@apollo/client';
import { DETACH_SHAPE, GET_TEMPLATE_SHAPES_GQL } from './graphql';
import ReturnButton from '../../../../../components/ReturnButton';
import { useParams } from 'react-router-dom';
import ConfirmDialog from '../../../../../components/_molecules/ConfirmDialog';
import useToast from '../../../../../hooks/useToast';
import { Col, Row } from 'reactstrap';
import { Shape } from '@wgt/types';

const TemplateShapes: React.FC = () => {
  const params = useParams<ParamTypes>();
  const { toast } = useToast();

  const { data, loading } = useQuery(GET_TEMPLATE_SHAPES_GQL, {
    nextFetchPolicy: 'cache-first',
    variables: {
      id: params.id,
    },
  });

  const [shapeToRemove, setShapeToRemove] = useState<Shape | null>(null);

  const [detachShapeToTemplate, { loading: removing }] = useMutation(
    DETACH_SHAPE,
    {
      refetchQueries: [
        {
          query: GET_TEMPLATE_SHAPES_GQL,
          variables: {
            id: params.id,
          },
        },
      ],
      onCompleted: () => toast('success', 'Shape successfully removed'),
    },
  );

  const onConfirmDelete = () => [
    detachShapeToTemplate({
      variables: {
        id: shapeToRemove?.id,
        template_id: params.id,
      },
    }),
    setShapeToRemove(null),
  ];
  return (
    <>
      <div className="template-shapes">
        <ReturnButton
          route={`/crm/templates/${params.id}/edit`}
          title="Return to template"
        />

        <Row className="w-100">
          <Col sm="4">
            <ShapesSelector shapeGroups={data?.shapeGroups?.data ?? []} />
          </Col>
          <Col>
            <TemplateShapesList
              setShapeToRemove={setShapeToRemove}
              shapes={data?.template?.shapes ?? []}
              templateId={data?.template.id}
              loading={loading}
            />
          </Col>
        </Row>
      </div>
      <ConfirmDialog
        isShown={!!shapeToRemove}
        onConfirm={onConfirmDelete}
        onClose={() => setShapeToRemove(null)}
        title={`Confirm delete of ${shapeToRemove?.title}?`}
        isLoading={removing}
      />
    </>
  );
};

export default TemplateShapes;
