/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo } from 'react';
import {
  DroppedTemplateComponent,
  LayoutFieldType,
  LayoutStructure,
  SearchLayoutResultColumn,
  SectionStringType,
  TemplateSearchLayoutColumn,
  TemplateSearchLayoutRow,
  TemplateWithFieldsLayout,
  LayoutTemplateFieldComponent,
} from '@wgt/types';
import { SEARCH_HEADER_COLUMN_PRODUCT_OPTIONS } from '@wgt/shared/constants';

interface RowAndColType {
  rowNumber: number;
  colNumber: number;
}

export interface SectionPropertiesProps {
  groupTitle?: string;
  size?: string;
}

interface FieldByPositionType extends RowAndColType {
  fieldIndex: number;
}

interface SectionPropertiesUpdate
  extends RowAndColType,
    SectionPropertiesProps {
  section: SectionStringType;
}

interface OnAddNewRowProps {
  newRowStructure: TemplateSearchLayoutRow;
  section: SectionStringType;
}

interface ToggleOptionHeaderSectionProps extends RowAndColType {
  value: boolean;
  section: SectionStringType;
  field: string;
}

interface TemplateFieldEdit extends RowAndColType {
  fieldIndex: number;
  section: SectionStringType;
  searchLayoutClassStyle?: string;
}

interface TemplateLayoutContextData {
  template: TemplateWithFieldsLayout | null;
  fields?: DroppedTemplateComponent[];
  filteredFields?: DroppedTemplateComponent[];
  searchHeaders?: SearchLayoutResultColumn[];
  structure: LayoutStructure;
  onAddNewRow(props: OnAddNewRowProps): void;
  onDropToColumn(
    templateComponent: DroppedTemplateComponent,
    section: SectionStringType,
  ): void;
  onRemoveField(
    templateFieldId: number | undefined,
    section: SectionStringType,
  ): void;
  onRemoveFieldByPosition(
    fieldByPosition: FieldByPositionType,
    section: SectionStringType,
  ): void;
  onEditSectionProperties(editSectionProperties: SectionPropertiesUpdate): void;
  onRemoveRow(rowIndex: number, section: SectionStringType): void;
  onRemoveColumn(rowAndCol: RowAndColType): void;
  onChangeResultHeader(headerOption: SearchLayoutResultColumn): void;
  toggleOptionHeaderSection(prop: ToggleOptionHeaderSectionProps): void;
  layoutField: LayoutFieldType;
  sections: SectionStringType[];
  onEditTemplateField(values: TemplateFieldEdit): void;
}
const TemplateLayoutContext = React.createContext<TemplateLayoutContextData>(
  {} as TemplateLayoutContextData,
);
interface Props {
  context: {
    template?: TemplateWithFieldsLayout | null;
    layoutField: LayoutFieldType;
    layoutSections: SectionStringType[];
  };
}
const TemplateLayoutProvider: React.FC<Props> = ({
  children,
  context: { template, layoutField = 'layout', layoutSections = [] },
}) => {
  const [fields, setFields] = React.useState<DroppedTemplateComponent[]>([
    ...(template?.fields || []),
  ]);
  const [structure, setStructure] = React.useState<LayoutStructure>(
    layoutSections.reduce(
      (sections, section) => ({
        ...sections,
        [section]: [],
      }),
      {},
    ) as LayoutStructure,
  );
  const [searchHeaders, setSearchHeaders] = React.useState<
    SearchLayoutResultColumn[]
  >([]);
  const onAddNewRow = (props: OnAddNewRowProps) => {
    const { section, newRowStructure } = props;
    if (!section) {
      return;
    }
    const newStructure = { ...structure };
    newStructure[section] = [...newStructure[section], newRowStructure];
    setStructure((currentStructure) => ({
      ...currentStructure,
      ...newStructure,
    }));
  };
  const onDropToColumn = (
    templateComponent: DroppedTemplateComponent,
    section: SectionStringType,
  ) => {
    const { colNumber, rowNumber } = templateComponent;
    if (!section) {
      return;
    }
    setStructure((currentStructure) => {
      const newStructure = { ...currentStructure };
      newStructure[section][rowNumber][colNumber].group.content.push({
        size: '12',
        type: templateComponent.type,
        content: templateComponent,
      });
      return newStructure;
    });
  };
  const onRemoveField = (
    templateFieldId: number | undefined,
    section: SectionStringType,
  ) => {
    if (!section) {
      return;
    }
    const sectionsArr = Object.values(structure).map((structureItem) =>
      structureItem.map((rowItems: TemplateSearchLayoutColumn[]) =>
        rowItems.map((rowItem) => ({
          ...rowItem,
          group: {
            ...rowItem.group,
            content: rowItem.group.content.filter(
              (groupContent) => groupContent.content?.id !== templateFieldId,
            ),
          },
        })),
      ),
    );
    setStructure(
      layoutSections.reduce((newStructure, layoutSection, layoutSectionKey) => {
        newStructure[layoutSection] = sectionsArr[layoutSectionKey];
        return newStructure;
      }, {}) as LayoutStructure,
    );
  };

  const onEditTemplateField = (values: TemplateFieldEdit) => {
    const {
      colNumber,
      rowNumber,
      fieldIndex,
      section,
      searchLayoutClassStyle,
    } = values;

    setStructure((currentStructure) => {
      const newStructure = { ...currentStructure };
      if (
        newStructure[section][rowNumber][colNumber].group.content[fieldIndex]
          .content
      ) {
        const structureContent =
          newStructure[section][rowNumber][colNumber].group.content[fieldIndex];
        structureContent.content = {
          ...structureContent.content,
          searchLayoutClassStyle,
        } as LayoutTemplateFieldComponent;
        structureContent.searchLayoutClassStyle = searchLayoutClassStyle;

        newStructure[section][rowNumber][colNumber].group.content[
          fieldIndex
        ] = structureContent;
      }

      return newStructure;
    });
  };

  const onRemoveFieldByPosition = (
    fieldByPosition: FieldByPositionType,
    section: SectionStringType,
  ) => {
    if (!section) {
      return;
    }
    const { rowNumber, colNumber, fieldIndex } = fieldByPosition;
    const structureWithoutContent = { ...structure };
    structureWithoutContent[section][rowNumber][colNumber].group.content.splice(
      fieldIndex,
      1,
    );
    setStructure(structureWithoutContent);
  };
  const filteredFields: DroppedTemplateComponent[] = useMemo(() => {
    const sectionFieldIds = Object.values(structure).reduce(
      (fieldIds: number[], structureItem) => {
        const foundIds = structureItem.reduce(
          (
            accDroppedFieldIds: number[],
            rowItems: TemplateSearchLayoutColumn[],
          ) => {
            const rowValues: number[] = rowItems.reduce(
              (acc2: number[], rowItem) => [
                ...acc2,
                ...rowItem.group.content
                  .filter((item) => !!item.content?.id)
                  .map((item) => item.content?.id ?? 0),
              ],
              [],
            );
            return [...accDroppedFieldIds, ...rowValues];
          },
          [],
        );
        return [...fieldIds, ...foundIds];
      },
      [],
    );
    return fields.filter(
      (fieldItem) => !!fieldItem.id && !sectionFieldIds?.includes(fieldItem.id),
    );
  }, [fields, structure]);
  const buildInitialStructureFromDatabase = (
    structureFromDatabase: any,
    fieldsFromDatabase: DroppedTemplateComponent[],
  ) => {
    const newStructure = Object.keys(structureFromDatabase)?.reduce(
      (acc, item) => ({
        ...acc,
        [item]: structureFromDatabase[item]?.map(
          (rowItems: TemplateSearchLayoutRow) =>
            rowItems.map((rowItem) => ({
              ...rowItem,
              group: {
                ...rowItem.group,
                content: rowItem.group.content.map((groupContent) => ({
                  ...groupContent,
                  content: fieldsFromDatabase.find(
                    (field) =>
                      field.id?.toString() === groupContent.content?.toString(),
                  ),
                })),
              },
            })),
        ),
      }),
      {},
    );
    setStructure(newStructure as LayoutStructure);
  };

  const onEditSectionProperties = (
    {
      groupTitle,
      colNumber,
      rowNumber,
      section,
      size,
    } = {} as SectionPropertiesUpdate,
  ) => {
    if (!section) {
      return;
    }
    setStructure((currentStructure) => {
      const newStructure = { ...currentStructure };

      if (groupTitle) {
        newStructure[section][rowNumber][colNumber].group.title = groupTitle;
      }

      if (size) {
        newStructure[section][rowNumber][colNumber].size = size;
      }
      return newStructure;
    });
  };
  const onRemoveRow = (rowIndex: number, section: SectionStringType) => {
    if (!section) {
      return;
    }
    setStructure((currentStructure) => {
      const newStructure = { ...currentStructure };
      newStructure[section].splice(rowIndex, 1);
      return newStructure;
    });
  };
  const onRemoveColumn = ({ rowNumber, colNumber } = {} as RowAndColType) => {
    console.log({ rowNumber, colNumber });
  };
  const onChangeResultHeader = (headerOption: SearchLayoutResultColumn) => {
    setSearchHeaders((currentHeaders) => {
      return currentHeaders.map((x) => ({
        ...x,
        selected:
          x.key === headerOption.key ? !headerOption.selected : x.selected,
      }));
    });
  };
  const toggleOptionHeaderSection = ({
    section,
    colNumber,
    rowNumber,
    value,
    field,
  }: ToggleOptionHeaderSectionProps) => {
    setStructure((currentStructure) => {
      if (!section) {
        return currentStructure;
      }
      const newStructure = { ...currentStructure };
      newStructure[section][rowNumber][colNumber].group[field] = !value;
      return newStructure;
    });
  };
  const contextValues: TemplateLayoutContextData = {
    fields,
    filteredFields,
    searchHeaders,
    template: template ?? null,
    structure,
    onAddNewRow,
    onDropToColumn,
    onRemoveField,
    onEditSectionProperties,
    onRemoveRow,
    onRemoveColumn,
    onRemoveFieldByPosition,
    onChangeResultHeader,
    toggleOptionHeaderSection,
    layoutField,
    sections: layoutSections,
    onEditTemplateField,
  };
  const buildSearchHeaderFields = () => {
    const allFields = [...(template?.fields ?? [])];
    const searchFields: SearchLayoutResultColumn[] = allFields.map(
      (field, key) => ({
        title: field.label,
        selected: false,
        type: 'templateField',
        key: field.property?.key?.toString() ?? `templateField-${key}`,
      }),
    );
    const selectedHeaderKeys =
      typeof template?.result_header !== 'string'
        ? template?.result_header?.map((resultItem) => resultItem.key)
        : [];
    setSearchHeaders(
      [...searchFields, ...SEARCH_HEADER_COLUMN_PRODUCT_OPTIONS].map((x) => ({
        ...x,
        selected: selectedHeaderKeys?.includes(x.key),
      })),
    );
  };
  useEffect(() => {
    const allFields = [...(template?.fields ?? [])];
    setFields(allFields);
    buildSearchHeaderFields();
    if (layoutField && template?.[layoutField] && template?.fields.length) {
      buildInitialStructureFromDatabase(
        template[layoutField],
        template?.fields,
      );
    }
  }, [template]);
  return (
    <TemplateLayoutContext.Provider value={contextValues}>
      {children}
    </TemplateLayoutContext.Provider>
  );
};
export { TemplateLayoutProvider, TemplateLayoutContext };
