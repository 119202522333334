import React from 'react';

import TemplateLayout, {
  TemplateLayoutProps,
} from '../../../../../components/TemplateLayout';
import { Pane } from 'evergreen-ui';

const TemplateFieldsSecondSection: React.FC<TemplateLayoutProps> = ({
  rows,
  prefix,
  rangeToSingle,
  disabled,
}) => {
  return (
    <Pane flex="1">
      <TemplateLayout
        rows={rows}
        prefix={prefix}
        component="component"
        rangeToSingle={rangeToSingle}
        disabled={disabled}
        hasDependencies
      />
    </Pane>
  );
};

export default TemplateFieldsSecondSection;
