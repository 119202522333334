import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { Form, Row, Col } from 'reactstrap';
import { useFormContext } from 'react-hook-form';
import { BoxId, CrateId, SearchLayoutResultColumn } from '@wgt/types';
import { convertPageIdFields } from '@wgt/converters';
import ProductInlineForm from './ProductInlineForm';
import TemplateFields from './TemplateFields';
import MediaFiles from './MediaFiles';
import Reports from './Reports';
import CommonAttributes from './CommonAttributes';
import Headers from './Headers';
import PageIdActions from './PageIdActions';
import Office from './Office';
import { useProductUpdate } from '../../Update/Provider';
import { PageId, ProductStatus } from '../../types';
import { Pane } from 'evergreen-ui';
import './styles.scss';
import { UPDATE_PRODUCT_SEARCH_GQL } from '../../graphql';
import useErrorMessage from '../../../../../hooks/useErrorMessage';
import useToast from '../../../../../hooks/useToast';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useBagContext } from '../../../../../services/bag';
import { useTranslation } from 'react-i18next';

const Content: React.FC<{
  statuses: ProductStatus[];
  headers?: SearchLayoutResultColumn[];
}> = ({ statuses, headers }) => {
  const methods = useFormContext();
  const { handleSubmit, getValues } = methods;
  const { setFormEnabled } = useProductUpdate();
  const { t } = useTranslation('product');
  const productId = getValues('id');
  const removeId = ({ id, name }: BoxId | CrateId) => ({
    name,
    id: id?.toString().match(/^\d+$/) ? id : undefined,
  });
  const { toast } = useToast();
  const handleError = useErrorMessage(methods.getValues);
  const params = useParams<{ category: string }>();
  const { setNewProductId } = useBagContext<{
    setNewProductId: Dispatch<SetStateAction<number | null>>;
  }>();
  const [update, { loading }] = useMutation(UPDATE_PRODUCT_SEARCH_GQL, {
    onCompleted: () => {
      toast('success', t('Product saved succesfully'));
      setNewProductId(null);
    },
    onError: handleError,
  });

  useEffect(() => setFormEnabled(true), []);

  const onSubmit = handleSubmit(
    ({
      pages,
      boxes,
      crates,
      description,
      name,
      office_id,
      lot_id,
      sku,
      tray_id,
      product_status_id,
      id,
    }) => {
      update({
        variables: {
          productId: id,
          product: {
            name,
            description,
            lot_id,
            sku,
            tray_id,
            product_status_id,
            category_id: Number(params?.category),
            office_id: Number(office_id),
            boxes: boxes.map(removeId),
            crates: crates.map(removeId),
            pages: pages?.map((page: PageId, i: number) => ({
              id: page.id,
              name: page.name,
              position: i + 1,
              shapes: page.shapes,
              category_id: page.category?.id ?? Number(params?.category),
              carat_weight: Number(page.carat_weight),
              price_per_carat: Number(page.price_per_carat),

              form_values: JSON.stringify(
                convertPageIdFields(page.fields) ?? {},
              ),
              fields: JSON.stringify(page.fields ?? {}),
            })),
          },
        },
      });
    },
  );
  if (!productId) {
    return <React.Fragment />;
  }

  return (
    <Form onSubmit={onSubmit}>
      {headers?.length && <Headers headers={headers} />}
      <Pane className="new-product-form">
        <Row className="border rounded bg-white px-2 py-3 inline-update-product">
          <Col lg="12">
            <CommonAttributes statuses={statuses} />
            <MediaFiles />
            <TemplateFields section="first" headers={headers} />
            <Reports productId={productId} />
            <TemplateFields section="second" headers={headers} />
            <Office />
          </Col>
        </Row>
        <Row className="px-2 py-3">
          <Col lg="12">
            <ProductInlineForm />
            <PageIdActions productId={productId} loading={loading} />
          </Col>
        </Row>
      </Pane>
    </Form>
  );
};

export default Content;
