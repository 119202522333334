import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { Button } from '../../../../../components';

import WorkOrderItemForm from '../../../../../components/WorkOrderItemForm';
import PosActions from './PosActions';

const WorkOrders: React.FC = () => {
  const { t } = useTranslation('workorders');

  return (
    <div className="product-workorders my-5">
      <Row>
        <Col>
          <Button type="orange">{t('Work Order')}</Button>
          <Button type="orange" className="outline ml-4">
            {t('History')}
          </Button>
        </Col>
        <Col>
          <Button type="secondary">{t('Calendar')}</Button>
          <span>{t('Trace Stock')}</span>
          <PosActions />
        </Col>
      </Row>
      <WorkOrderItemForm />
    </div>
  );
};

export default WorkOrders;
