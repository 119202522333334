import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'reactstrap';
import { Title, Button } from '../../';
import { useQuery } from '@apollo/client';
import useWorkOrderItemContext from '../useWorkOrderItemContext';
import WorkOrderDocumentsForm from './WorkOrderDocumentsForm';
import DocumentItem from './DocumentItem';
import { LIST_WORK_ORDER_DOCUMENTS } from './graphql';
import { WorkOrderDocument } from '@wgt/types';
import useToggler from '@wgt/shared/hooks/useToggler';

export default function Documents(): JSX.Element {
  const { t } = useTranslation(['pointOfSales', 'common']);
  const [isFormVisible, toggle] = useToggler();
  const [{ workOrder }] = useWorkOrderItemContext();
  const { data } = useQuery<{
    workOrderDocuments: { data: WorkOrderDocument[] };
  }>(LIST_WORK_ORDER_DOCUMENTS, {
    variables: { workOrderId: workOrder?.id },
    skip: !workOrder?.id,
  });

  return (
    <>
      {isFormVisible && <WorkOrderDocumentsForm toggle={toggle} />}
      <div className="d-flex align-items-center mt-3">
        <Title heading="h4">{t('Documents')}</Title>
        <Button className="mr-3 ml-3" type="secondary" onClick={toggle}>
          {t('Add Documents')}
        </Button>
      </div>

      <Row className="justify-content-between row-cols-2">
        {data?.workOrderDocuments.data.map((document, index) => (
          <DocumentItem document={document} key={index} />
        ))}
      </Row>
    </>
  );
}
