import React, { useState } from 'react';
import { FaSort } from 'react-icons/fa';
import classnames from 'classnames';
import {
  MediaCenterController,
  ZoomProps,
} from '../../../../../../hooks/useMediaCenterController';
import { useBagContext } from '../../../../../../services/bag';

interface ZoomOptionsProps {
  isShown: boolean;
  setShown(isShown: boolean): void;
}

const ZoomOptions: React.FC<ZoomOptionsProps> = ({ isShown, setShown }) => {
  const zoomOps: ZoomProps[] = [100, 125, 150];
  const { setZoom } = useBagContext<MediaCenterController>();

  const handleSelectZoom = (selectedZoom: ZoomProps) => {
    setShown(false);
    setZoom(selectedZoom);
  };

  return (
    <div
      className={classnames('zoom-options', {
        open: isShown,
      })}
    >
      {zoomOps.map((zoom) => (
        <div
          key={`zoom-option-${zoom}`}
          onClick={() => handleSelectZoom(zoom)}
        >{`${zoom}%`}</div>
      ))}
    </div>
  );
};

const ZoomController: React.FC = () => {
  const { zoom } = useBagContext<MediaCenterController>();
  const [showOptions, setShowOptions] = useState(false);

  return (
    <div>
      <ZoomOptions isShown={showOptions} setShown={setShowOptions} />
      <div
        className="button-expand-media-center"
        onClick={() => setShowOptions((isShown) => !isShown)}
      >
        {`${zoom}%`} <FaSort />
      </div>
    </div>
  );
};

export default ZoomController;
