import { gql, useApolloClient } from '@apollo/client';
import React, { memo, useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { ProductBase } from '../../../../../../types';
import setCurrencyMaskInput, {
  removeCurrencyMask,
} from '../../../../helpers/CurrencyMaskInput';
import CaratWeight from '../Update/Total/CaratWeight';
import SKUPrice from '../Update/Total/SKUPrice';

export default memo(function ProductTotals(): JSX.Element {
  const client = useApolloClient();
  const params = useParams<{ productId: string; pageId?: string }>();

  const product = client.readFragment<ProductBase>({
    id: `Product:${params.productId}`,
    fragment: gql`
      fragment productPagePrices_Part on Product {
        id
        pages {
          ... on ProductPageId {
            id
            total_price
            carat_weight
            price_per_carat
          }
        }
      }
    `,
  });
  const fields = useWatch({
    name: ['page.total_price', 'page.carat_weight'],
  });

  const { totalSkuPrice, totalCaratWeight } = useMemo(() => {
    return (
      product?.pages?.reduce(
        (totals, current) => {
          if (params.pageId && params.pageId === current.id?.toString()) {
            return totals;
          }
          totals.totalCaratWeight += Number(
            removeCurrencyMask(current.carat_weight?.toString() ?? '0'),
          );
          totals.totalSkuPrice += Number(
            removeCurrencyMask(current.total_price?.toString() ?? '0'),
          );

          return totals;
        },
        {
          totalSkuPrice: Number(
            removeCurrencyMask(fields['page.total_price'] ?? 0),
          ),
          totalCaratWeight: Number(
            removeCurrencyMask(fields['page.carat_weight'] ?? '0'),
          ),
        },
      ) ?? { totalCaratWeight: 0, totalSkuPrice: 0 }
    );
  }, [
    product?.pages,
    params?.pageId,
    fields['page.total_price'],
    fields['page.carat_weight'],
  ]);

  return (
    <>
      <SKUPrice
        displayText={setCurrencyMaskInput(
          totalSkuPrice?.toFixed(2)?.toString(),
        )}
      />
      <CaratWeight displayText={totalCaratWeight.toFixed(2)} />
    </>
  );
});
