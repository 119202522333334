/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Container } from 'reactstrap';
import { useQuery } from '@apollo/client';
import { Firm } from '@wgt/types';
import FirmList from './FirmList';
import { LIST_FIRMS_GQL } from '../../graphql';
import Loader from '../../../../../../components/_organisms/Loader';

export default function ListOffices(): JSX.Element {
  const { data, loading } = useQuery<{ firms: { data: Firm[] } }>(
    LIST_FIRMS_GQL,
  );

  if (loading) {
    return <Loader />;
  }

  return (
    <Container fluid>
      {data?.firms?.data?.map((firm: Firm, key: number) => (
        <FirmList firm={firm} key={key} />
      ))}
    </Container>
  );
}
