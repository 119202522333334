/* eslint-disable @typescript-eslint/no-explicit-any */
import { gql, useMutation } from '@apollo/client';
import React, { createContext, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { GET_PAYMENT } from './graphql';

const receiptFormContext = createContext({});

interface ReceiptFormProviderProps {
  children: JSX.Element | JSX.Element[];
  paymentId: string;
}

export function ReceiptFormProvider({
  children,
  paymentId,
}: ReceiptFormProviderProps): JSX.Element {
  const methods = useForm({
    shouldUnregister: false,
  });
  const [create, { loading: creating }] = useMutation(
    gql`
      mutation createReceipt($payment_id: ID!) {
        createReceipt(payment_id: $payment_id) {
          id
        }
      }
    `,
    {
      variables: {
        payment_id: paymentId,
      },
      refetchQueries: [{ query: GET_PAYMENT, variables: { id: paymentId } }],
      onCompleted: ({ createReceipt }) => [
        methods.reset({ id: createReceipt.id }),
      ],
    },
  );

  const [upload, { loading: uploading }] = useMutation(
    gql`
      mutation createReceiptFile(
        $receipt_id: ID
        $title: String
        $file: Upload!
      ) {
        createReceiptFile(receipt_id: $receipt_id, title: $title, file: $file) {
          id
        }
      }
    `,
  );

  return (
    <receiptFormContext.Provider
      value={{
        paymentId,
        create,
        creating,
        upload,
        uploading,
      }}
    >
      <FormProvider {...methods}>{children}</FormProvider>
    </receiptFormContext.Provider>
  );
}

export default function useReceiptForm(): any {
  return useContext(receiptFormContext);
}
