import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CustomInput } from 'reactstrap';

export default React.memo(function FieldOptions(): JSX.Element {
  const { t } = useTranslation('template');

  return (
    <>
      <Controller
        name="is_required"
        render={({ onChange, value, name }) => (
          <CustomInput
            type="switch"
            name={name}
            id={name}
            onChange={(e) => onChange(e.target.checked)}
            checked={value}
            label={t('Required')}
          />
        )}
      />

      <Controller
        name="is_searchable"
        render={({ onChange, value, name }) => (
          <CustomInput
            type="switch"
            name={name}
            id={name}
            onChange={(e) => onChange(e.target.checked)}
            checked={value}
            label={t('Searchable')}
          />
        )}
      />

      <Controller
        name="is_highlight"
        render={({ onChange, value, name }) => (
          <CustomInput
            type="switch"
            name={name}
            id={name}
            onChange={(e) => onChange(e.target.checked)}
            checked={value}
            label={t('Highlight')}
          />
        )}
      />

      <Controller
        name="is_single_shape"
        render={({ onChange, value, name }) => (
          <CustomInput
            type="switch"
            name={name}
            id={name}
            onChange={(e) => onChange(e.target.checked)}
            checked={value}
            label={t('Single Shape')}
          />
        )}
      />
    </>
  );
});
