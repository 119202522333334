import { useApolloClient } from '@apollo/client';
import React from 'react';
import { useParams } from 'react-router-dom';
import ButtonCheckbox from '../../../../../components/ButtonCheckbox';

import { GET_CATEGORY_GQL } from '../../graphql';

const ProductStatus: React.FC = () => {
  const client = useApolloClient();
  const params = useParams<ParamTypes>();
  const { productsStatus } = client.readQuery({
    query: GET_CATEGORY_GQL,
    variables: { id: params.category },
  });

  return (
    <div className="row">
      <div className="col-12">
        <div className="px-0 mx-0 container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="field-container d-flex align-items-center flex-1 mr-0">
                <ButtonCheckbox
                  field="product_status_id"
                  label="Status"
                  options={productsStatus?.data ?? []}
                  controller={{ identifier: 'id', label: 'name' }}
                  required
                  isMulti
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductStatus;
