import React, { useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import CustomHeader from '../../../../components/_molecules/CustomHeader';
import SimpleForm from '../../../../components/_organisms/SimpleForm';
import useCrmRoutes from '../../../../hooks/useCrmRoutes';
import useToast from '../../../../hooks/useToast';
import {
  CREATE_PROPERTY_VALUE_VARIATIONS_GQL,
  UPDATE_PROPERTY_VALUE_VARIATIONS_GQL,
  LIST_PROPERTY_VALUE_VARIATIONS_GQL,
  GET_PROPERTY_VALUE_VARIATION_GQL,
} from '../graphql';
import PropertyValueVariationValues from './PropertyValueVariationValues';

const PropertyValueVariationPut: React.FC = () => {
  const params = useParams<ParamTypes>();
  const isEdit = useMemo<boolean>(() => !!params?.id, [params]);
  const history = useHistory();
  const { setCustomTitle, actualRoute } = useCrmRoutes();
  const { toast } = useToast();

  const pageTitle = useMemo<string>(
    () => `${isEdit ? 'Edit' : 'Create'} Property value variation`,
    [isEdit],
  );

  useEffect(() => {
    setCustomTitle(pageTitle);
  }, [pageTitle]);

  return (
    <div>
      <CustomHeader showList title={pageTitle} />
      <SimpleForm
        query={GET_PROPERTY_VALUE_VARIATION_GQL}
        queryOptions={{ variables: { isEdit, id: params.id ?? '' } }}
        parseQuery={({ propertyValueVariation }) => propertyValueVariation}
        mutation={
          isEdit
            ? UPDATE_PROPERTY_VALUE_VARIATIONS_GQL
            : CREATE_PROPERTY_VALUE_VARIATIONS_GQL
        }
        parseMutationArgs={(propertyValueVariation) =>
          isEdit
            ? { id: params.id, propertyValueVariation }
            : propertyValueVariation
        }
        mutationOptions={{
          refetchQueries: [{ query: LIST_PROPERTY_VALUE_VARIATIONS_GQL }],
          onCompleted: () => [
            toast('success'),
            history.push(`/crm/${actualRoute}`),
          ],
        }}
        fields={[
          {
            name: 'name',
            label: 'Name',
            required: true,
            type: 'text',
          },
        ]}
      />

      {isEdit && <PropertyValueVariationValues />}
    </div>
  );
};

export default PropertyValueVariationPut;
