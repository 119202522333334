import Products from './Products';
import Users from './Users';
import Firms from './Firms';
import Properties from './Properties';
import Templates from './Templates';
import Categories from './Categories';
import CategoryGroups from './CategoryGroups';
import Roles from './Roles';
import Search from './Search';
import Shapes from './Shapes';
import ShapeGroups from './ShapeGroups';
import PropertyValueVariation from './PropertyValueVariation';
import PointOfSales from './PointOfSales';
import Calendar from './Calendar';
import WorkOrderType from './WorkOrderType';
import HumanResources from './HumanResources';
import Storage from './Storage';
import Statements from './Statements';
import Profile from './Profile';
import WebsiteAnalytics from './WebsiteAnalytics';
import WebsiteTemplate from './WebsiteTemplate';
import ImportPage from './ImportPage';
import Newsletter from './Newsletter';
import MonerisTest from './MonerisTest';

export default [
  {
    component: Products,
    name: 'Products',
    path: '/crm/products',
  },
  {
    component: Users,
    name: 'Users',
    path: '/crm/users',
  },
  {
    component: Firms,
    name: 'Firms',
    path: '/crm/firms',
  },
  {
    component: Properties,
    name: 'Properties',
    path: '/crm/properties',
  },
  {
    component: PropertyValueVariation,
    name: 'Property value variation',
    path: '/crm/property-value-variation',
  },
  {
    component: Templates,
    name: 'Templates',
    path: '/crm/templates',
  },
  {
    component: Roles,
    name: 'Roles',
    path: '/crm/roles',
  },
  {
    component: Categories,
    name: 'Categories',
    path: '/crm/categories',
  },
  {
    component: Search,
    name: 'Search',
    path: '/crm/search/:term',
  },
  {
    component: CategoryGroups,
    name: 'Category Groups',
    path: '/crm/category-groups',
  },
  {
    component: ShapeGroups,
    name: 'Shape Groups',
    path: '/crm/shape-groups',
  },
  {
    component: Shapes,
    name: 'Shapes',
    path: '/crm/shapes',
  },
  {
    component: PointOfSales,
    name: 'Point Of Sales',
    path: '/crm/point-sales',
  },
  {
    component: Calendar,
    name: 'Calendar',
    path: '/crm/calendar',
  },
  {
    component: WorkOrderType,
    name: 'Work Order Type',
    path: '/crm/work-order-type',
  },
  {
    component: HumanResources,
    name: 'Human Resources',
    path: '/crm/human-resources',
  },
  {
    component: Storage,
    name: 'Storage',
    path: '/crm/storage',
  },
  {
    component: Statements,
    name: 'Statements',
    path: '/crm/statements',
  },
  {
    component: Profile,
    name: 'Profile Account',
    path: '/crm/profile',
  },
  {
    component: WebsiteAnalytics,
    name: 'Website Analytics',
    path: '/crm/website-analytics',
  },
  {
    component: WebsiteTemplate,
    name: 'Website Template',
    path: '/crm/website-template',
  },
  {
    component: ImportPage,
    name: 'Import',
    path: '/crm/import',
  },
  {
    component: Newsletter,
    name: 'Newsletter',
    path: '/crm/newsletter',
  },
  {
    component: MonerisTest,
    name: 'Moneris Test Page',
    path: '/crm/moneris',
  },
];
