import { ExpandedDropdownOptions } from '@wgt/types';
import React from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
  value: ExpandedDropdownOptions;
  field: string;
  required?: boolean;
  isMulti?: boolean;
  disabled?: boolean;
}

const ColorOption: React.FC<Props> = ({ value, field, isMulti, disabled }) => {
  const { options } = value;

  const fieldToHandle = `${field}.${value.key}.selected`;
  const { register, watch, getValues } = useFormContext();
  const isSelected = watch(fieldToHandle, false);

  return (
    <div>
      <label
        className="custom-checkbox-color p-3"
        style={{
          backgroundColor: options?.color ?? '#fff',
          borderColor: isSelected ? '#0074a0' : '#fff',
          opacity: isSelected ? 0.6 : 1,
        }}
      >
        <input
          type={isMulti ? 'checkbox' : 'radio'}
          name={fieldToHandle}
          ref={register()}
          defaultChecked={getValues(fieldToHandle)}
          disabled={disabled}
        />
        <span className="custom-checkbox-color-controller" />
      </label>
    </div>
  );
};

export default ColorOption;
