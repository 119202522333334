import React from 'react';
import { MediaCenterItemBase } from '@wgt/types';
import { MediaCenterController } from '../../../../../../hooks/useMediaCenterController';
import { useBagContext } from '../../../../../../services/bag';
import BackgroundSelector from '../BackgroundSelector';

interface ActiveItemImageProps {
  image: string | undefined;
  active?: MediaCenterItemBase | null;
  onUpdate(background: string): void;
}
const ActiveItemImage: React.FC<ActiveItemImageProps> = ({
  image,
  active,
  onUpdate,
}) => {
  const { zoom } = useBagContext<MediaCenterController>();
  const zoomOptions = {
    '100': 'contain',
    '125': '50%',
    '150': '80%',
  };
  return (
    <>
      <BackgroundSelector active={active} update={onUpdate} />
      <div className="media-center-content-holder">
        {active && (
          <div
            className="media-center-content-image"
            style={{
              backgroundImage: `url(${image})`,
              backgroundSize: `${zoomOptions[zoom]}`,
            }}
          />
        )}
      </div>
    </>
  );
};

export default ActiveItemImage;
