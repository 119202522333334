import React from 'react';
import { Alert, AlertProps } from 'reactstrap';
import classnames from 'classnames';
import './style.scss';

const AlertMessage: React.FC<AlertProps> = ({
  className,
  color = 'light',
  ...props
}) => (
  <Alert
    {...props}
    color={color}
    className={classnames('wgt-alert-message', className)}
  />
);

export default AlertMessage;
