import React, { useState } from 'react';
import { Pane } from 'evergreen-ui';
import { Modal, Button } from 'reactstrap';
import MediaCenter from '../../../Update/MediaCenter';
import { Title } from '../../../../../../components';
import { FiXCircle } from 'react-icons/fi';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const MediaFiles: React.FC = () => {
  const [modal, setModal] = useState(false);
  const { t } = useTranslation('product');

  const toggle = () => setModal(!modal);
  const { getValues } = useFormContext();

  return (
    <Pane className="media-and-report">
      <Title heading="h2">{t('Advanced')}</Title>
      <button type="button" onClick={toggle}>
        {t('Media Files')}
        <i>{getValues('files')?.length ?? 0}</i>
      </button>

      <Modal isOpen={modal} toggle={toggle} size="xl">
        <Button
          className="btn-close-media-center"
          color="link"
          onClick={toggle}
        >
          <FiXCircle />
        </Button>
        <MediaCenter productId={getValues('id')} />
      </Modal>
    </Pane>
  );
};

export default MediaFiles;
