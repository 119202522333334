import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Input, FormGroup } from 'reactstrap';
import { FiHelpCircle } from 'react-icons/fi';
import { Title, Button, Line, Text } from '../../../../../../../components';
import BadgeTotal from './BadgeTotal';
import NavbarCategories from '../../../../../../../components/_molecules/NavbarCategories';
import usePointOfSaleUpdate from '../../../usePointOfSaleUpdate';
import LearnToUse from '../../../../../LearnToUse';

export default function Header(): JSX.Element {
  const { t } = useTranslation(['pointOfSales', 'common']);
  const { pointOfSale } = usePointOfSaleUpdate();

  return (
    <div id="header-results">
      <Row>
        <Col xs="4" className="d-flex align-items-center">
          <Title>{t('Results')}</Title>
        </Col>
        <Col xs="2">
          <FormGroup className="simple-input">
            <Input type="text" name="sku" id="sku" placeholder="Barscan SKU" />
          </FormGroup>
        </Col>
        <Col xs="6" className="text-right">
          <div className="d-flex align-items-center justify-content-end">
            <div className="learn-to-use-margin-left">
              <LearnToUse />
            </div>
            <Text className="font-size-14">{t('Add stock')}</Text>
            <FiHelpCircle className="mx-2 text-muted" />
            <NavbarCategories
              title={t('common:Menu')}
              header={pointOfSale.intent?.toUpperCase()}
              routeArgs={{ pointOfSaleId: pointOfSale.id }}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs="10">
          <BadgeTotal description={t('Total Counts')} value={4935} />
          <BadgeTotal description={t('Total Weight')} value={345} />
          <BadgeTotal description={t('SKUs')} value={2532} />
          <BadgeTotal description={t('Lot IDs')} value={943} />
          <BadgeTotal description={t('Tray IDs')} value={345} />
          <BadgeTotal description={t('Box 1 IDs')} value={23} />
          <BadgeTotal description={t('Box 2 IDs')} value={4} />
          <BadgeTotal description={t('Box 3 IDs')} value={5} />
        </Col>
        <Col xs="2" className="text-right">
          <Button type="secondary">{t('common:Calendar')}</Button>
        </Col>
      </Row>
      <Line className="line-margin-bottom" />
    </div>
  );
}
