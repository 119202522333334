import { useQuery } from '@apollo/client';
import { LayoutComponentDataTypePropertyValue } from '@wgt/types';
import React, { useState } from 'react';
import { Button, Collapse } from 'reactstrap';
import { Title } from '../../../../../../../../components';
import useTemplateFieldsContext from '../../../../../../../../hooks/useTemplateFieldsContext';
import PropertyValueVariationForm from './FieldPropertyValueVariationForm';
import PropertyValueVariationList from './FieldPropertyValueVariationList';
import { GET_TEMPLATE_FIELD_VALUES } from './graphql';

export default function FieldPropertyValueVariation(): JSX.Element {
  const { active } = useTemplateFieldsContext();

  const { data } = useQuery(GET_TEMPLATE_FIELD_VALUES, {
    nextFetchPolicy: 'cache-first',
    skip: !active?.id || !active?.formValues.property_id,
    variables: {
      offset: 1,
      templateId: active?.id,
      filter: {
        property_id: active?.formValues.property_id,
      },
    },
  });

  const [activeCollapse, setActiveCollapse] = useState(data?.values?.[0]?.id);

  return (
    <div>
      <hr />

      {data?.templateField.values?.map(
        (propertyValue: LayoutComponentDataTypePropertyValue) => (
          <React.Fragment key={propertyValue.id}>
            <Button
              color="clear"
              onClick={() => setActiveCollapse(propertyValue.id)}
              className="dependency-title-holder "
            >
              <Title size={200}>{propertyValue.name}</Title>
            </Button>
            <Collapse
              key={propertyValue.id}
              isOpen={propertyValue.id === activeCollapse}
            >
              <PropertyValueVariationForm
                propertyValue={propertyValue}
                variations={propertyValue.variations ?? []}
              />
              <hr />

              <PropertyValueVariationList
                propertyValueId={propertyValue.id}
                variations={propertyValue.variations ?? []}
              />
            </Collapse>
          </React.Fragment>
        ),
      )}
    </div>
  );
}
