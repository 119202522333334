import React from 'react';
import { Table } from 'reactstrap';
import { Text, Button } from '..';
import { Link } from 'react-router-dom';
import { FaEdit, FaEye, FaTrash } from 'react-icons/fa';
import { SimpleTableProps } from '@wgt/types';

const SimpleTable: React.FC<SimpleTableProps> = ({
  data,
  header,
  hasView,
  module,
  hasEdit,
  onDelete,
}) => {
  return (
    <Table striped hover>
      <thead>
        <tr>
          <th>{header}</th>

          <th />
        </tr>
      </thead>
      <tbody>
        {data.map((item, key) => (
          <tr key={key}>
            <td>
              <Text>{item.label}</Text>
            </td>
            <td className={`edit-col`}>
              <div>
                {hasEdit && (
                  <Link to={`/crm/${module}/${item.id}/edit`}>
                    <FaEdit size={16} />
                  </Link>
                )}
                {hasView && (
                  <Link to={`/crm/${module}/${item.id}`}>
                    <FaEye size={16} />
                  </Link>
                )}
                {!!onDelete && (
                  <Button small type="danger" onClick={() => onDelete(item.id)}>
                    <FaTrash size={12} />
                  </Button>
                )}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default SimpleTable;
