/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation } from '@apollo/client';
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useFieldArray, Control } from 'react-hook-form';
import { PageId } from '../types';
import { CREATE_PAGE_ID } from '../graphql';
import { uid } from 'uid';

const Context = createContext({});

export default function Provider({
  filters,
  children,
  setFormEnabled: handleFormEnabling,
  isFormEnabled = true,
  control,
}: {
  filters?: any;
  isFormEnabled?: boolean;
  children: React.ReactNode;
  setFormEnabled?: Dispatch<SetStateAction<boolean | undefined>>;
  control: Control;
}): JSX.Element {
  const pages = useFieldArray({
    control,
    name: 'pages',
    keyName: '__id',
  });

  const setFormEnabled = (enabled?: boolean) => handleFormEnabling?.(enabled);

  const [isShapeDescriptionsVisible, setShapeDescriptionsVisible] = useState(
    false,
  );

  const [activeIndex, setActiveIndex] = useState<number | undefined>(
    pages.fields.length ? 0 : undefined,
  );

  const [createProductPageId, { loading: creatingPage }] = useMutation(
    CREATE_PAGE_ID,
    {
      onCompleted: ({ createProductPageId: data }) => {
        pages.append(data);
        setActive(data.position);
      },
    },
  );

  const active = useMemo(
    () =>
      !!pages.fields.length && activeIndex !== undefined
        ? pages.fields[activeIndex]
        : undefined,
    [pages.fields, activeIndex],
  );

  const setActive = (index: number | undefined) => {
    setActiveIndex(undefined);

    if (typeof index !== 'undefined') {
      setTimeout(() => setActiveIndex(index));
    }
  };

  const createPage = async ({
    productId,
    categoryId,
  }: {
    productId: number | string;
    categoryId: number | string;
  }) => {
    const name = uid();

    createProductPageId({
      variables: {
        product_id: productId,
        page: {
          name,
          category_id: categoryId,
          position: pages.fields.length,
        },
      },
    });
  };

  return (
    <Context.Provider
      value={{
        filters,
        pages: {
          ...pages,
          active,
          activeIndex,
          setActive,
        },
        createPage,
        creatingPage,
        isFormEnabled,
        setFormEnabled,
        setShapeDescriptionsVisible,
        isShapeDescriptionsVisible,
      }}
    >
      {children}
    </Context.Provider>
  );
}

interface Page {
  id: string;
  name: string;
}

interface ProductUpdate {
  filters?: any;
  pages: {
    active?: PageId;
    activeIndex: number | undefined;
    setActive(active: number): void;
    fields: any[];
    move(from: number, to: number): void;
    append(page: Page, shouldFocus?: boolean): void;
    remove(pageIndex: number): void;
    insert(pageIndex: number, page: PageId, shouldFocus?: boolean): void;
  };
  createPage({
    productId,
    categoryId,
  }: {
    productId: string | number;
    categoryId: string | number;
  }): Page;
  creatingPage: boolean;
  isFormEnabled?: boolean;
  setFormEnabled: (isEnabled: boolean) => void;
  isShapeDescriptionsVisible?: boolean;
  setShapeDescriptionsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export function useProductUpdate(): ProductUpdate {
  const context = useContext(Context) as ProductUpdate;
  return context;
}
