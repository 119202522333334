import { ProductBase } from '@wgt/types';
import { createContext, Dispatch, SetStateAction, useContext } from 'react';

export const Context = createContext({});

interface UseTraceDocumentsState {
  isSideSheetShown: boolean;
  product: ProductBase;
}

interface UseTraceDocumentsHandlers {
  setSideSheetShown: Dispatch<SetStateAction<boolean>>;
}

type UseTraceDocuments = [UseTraceDocumentsState, UseTraceDocumentsHandlers];

export default function useTraceDocuments(): UseTraceDocuments {
  return useContext(Context) as UseTraceDocuments;
}
