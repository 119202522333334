/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Pane, Heading, Card } from 'evergreen-ui';

export interface Page {
  name: string;
  title: string;
  url: string;
}

interface SidebarMenuProps {
  setShown: any;
}

const SidebarMenu: React.FC<SidebarMenuProps> = () => {
  return (
    <Pane zIndex={1} flexShrink={0} elevation={0} backgroundColor="white">
      <Pane padding={16} borderBottom="muted">
        <Heading size={600}>World Gem Trade</Heading>
      </Pane>
      <Pane flex="1" overflowY="scroll" background="tint1" padding={16}>
        <Card
          backgroundColor="white"
          elevation={0}
          height={240}
          display="flex"
          alignItems="start"
          justifyContent="start"
        />
      </Pane>
    </Pane>
  );
};

export default SidebarMenu;
