import React from 'react';
import './style.scss';

interface Props {
  className?: string;
}
const Line: React.FC<Props> = ({ className }) => {
  return <hr className={`mt-2 mb-4 ${className ?? ''}`} />;
};

export default Line;
