import React, { useMemo, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import CustomHeader from '../../../../components/_molecules/CustomHeader';
import { useParams } from 'react-router-dom';
import useCrmRoutes from '../../../../hooks/useCrmRoutes';
import {
  GET_SHAPES_GQL,
  UPDATE_SHAPES_GQL,
  CREATE_SHAPES_GQL,
  LIST_SHAPES_GQL,
} from '../graphql';
import Loader from '../../../../components/_organisms/Loader';
import useToast from '../../../../hooks/useToast';
import { useTranslation } from 'react-i18next';
import { Pane } from 'evergreen-ui';
import { Button, SimpleInput } from '../../../../components';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Col, CustomInput, Row } from 'reactstrap';
import ShapeImages from './ShapeImages';
import ShapeAbbreviations from './ShapeAbbreviations';
import { ShapeDescription } from '@wgt/types';
import { uid } from 'uid';
import ShapeDescriptionSelector from '../../../../components/ShapeDescriptionSelector';
interface ShapeInput {
  id?: string;
  title: string;
  value?: string;
  row_position: 'first' | 'second';
  abbreviations: [{ name: string }];
  descriptions: ShapeDescription[];
  image: File;
  image_selected: File;
  image_highlight: File;
}

const ShapePut: React.FC<{ shapeId?: string }> = ({ shapeId }) => {
  const params = useParams<ParamTypes>();
  const isEdit = useMemo<boolean>(() => !!shapeId, [shapeId]);
  const { t } = useTranslation('shapes');
  const { setCustomTitle } = useCrmRoutes();
  const { toast } = useToast();

  const methods = useForm<ShapeInput>({
    defaultValues: {
      title: '',
      value: '',
      row_position: 'first',
      abbreviations: [{ name: '' }],
      descriptions: [],
      image_highlight: '',
      image: '',
      image_selected: '',
    },
    shouldUnregister: false,
  });
  const { handleSubmit, reset, getValues } = methods;

  const [mutate, { loading: mutating }] = useMutation(
    isEdit ? UPDATE_SHAPES_GQL : CREATE_SHAPES_GQL,
    {
      onError: () => toast('error'),
      onCompleted: () => [toast('success'), !isEdit && reset()],
      refetchQueries: [
        {
          query: LIST_SHAPES_GQL,
          variables: { filter: { shapeGroup: { id: [params.id] } } },
        },
      ],
    },
  );

  useEffect(() => {
    setCustomTitle(`${isEdit ? 'Edit' : 'Create'} Shape`);
  }, []);

  const { data, loading } = useQuery(GET_SHAPES_GQL, {
    variables: {
      isEdit,
      id: shapeId ?? '',
    },
    onCompleted: ({ shape }) => {
      if (shape) {
        reset({
          ...shape,
          abbreviations:
            shape.abbreviations?.map((name: string) => ({ name })) ?? [],
        });
      }
    },
  });

  const onSubmit = handleSubmit(
    ({
      abbreviations,
      image,
      image_highlight,
      image_selected,
      descriptions,
      ...values
    }) => {
      const shape = {
        ...values,
        image: image === null || typeof image === 'string' ? undefined : image,
        image_highlight:
          image_highlight === null || typeof image_highlight === 'string'
            ? undefined
            : image_highlight,
        image_selected:
          image_selected === null || typeof image_selected === 'string'
            ? undefined
            : image_selected,
        shape_group_id: params.id,
        abbreviations: abbreviations
          ?.filter((abbr) => !!abbr.name)
          .map((x) => x.name),
        image_url: undefined,
        image_selected_url: undefined,
        image_highlight_url: undefined,
        id: undefined,
        __typename: undefined,
        shapeGroup: undefined,
        descriptions: descriptions
          .map(
            (description) =>
              data.shapeDescriptions?.data?.find(
                (dataDescriptions: ShapeDescription) =>
                  description.id === dataDescriptions.id,
              ) ?? [],
          )
          .map(({ id, name, group, display_type }) => ({
            id,
            name,
            group,
            display_type,
          })),
      };

      const variables = isEdit ? { shape, id: data.shape.id } : shape;

      mutate({
        variables,
      });
    },
  );

  if (loading && !data) {
    return <Loader />;
  }

  return (
    <div>
      <CustomHeader title={t(`${isEdit ? 'Edit' : 'Create'} Shape`)} />

      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          <Row>
            <Col>
              <Row>
                <Col sm={12} md={3}>
                  <SimpleInput
                    field="value"
                    placeholder={t('Value')}
                    label={t('Value')}
                  />
                </Col>
                <Col sm={12} md={3}>
                  <SimpleInput
                    field="title"
                    placeholder={t('Label')}
                    label={t('Label')}
                  />
                </Col>
              </Row>
              <ShapeAbbreviations />
              <ShapeImages />
              <Row>
                <Col>
                  <Controller
                    name="row_position"
                    defaultValue={getValues('row_position') ?? 'first'}
                    render={({ onChange, value }) => (
                      <>
                        <CustomInput
                          type="switch"
                          id={`${uid()}_first_row`}
                          label={t('First row')}
                          checked={value === 'first'}
                          onChange={(e) =>
                            onChange(e.target.checked ? 'first' : 'second')
                          }
                        />
                        <CustomInput
                          type="switch"
                          id={`${uid()}_second_row`}
                          label={t('Second row')}
                          checked={value === 'second'}
                          onChange={(e) =>
                            onChange(e.target.checked ? 'second' : 'first')
                          }
                        />
                      </>
                    )}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col>
              <ShapeDescriptionSelector
                shapeDescriptions={data?.shapeDescriptions.data ?? []}
                selectorProps={{ name: 'descriptions', isVisible: true }}
              />
            </Col>
          </Row>
          <Pane display="flex" justifyContent="flex-end" marginTop="20px">
            <Button htmlType="submit" isLoading={mutating} disabled={mutating}>
              {t('Save')}
            </Button>
          </Pane>
        </form>
      </FormProvider>
    </div>
  );
};

export default ShapePut;
