import React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { Button, Text } from '../../../../../../components';
import usePointOfSaleUpdate from '../../usePointOfSaleUpdate';

const Options: React.FC = () => {
  const { pointOfSale } = usePointOfSaleUpdate();
  const { t } = useTranslation('pointOfSale');

  return (
    <div className="buttons-option ml-2 p-2 border">
      <div className="background-shallow pb-3">
        <div className="background-dive p-2">
          <Text className="header-title mb-2">{t('Point of Sales')}</Text>
          <div>
            <Button
              type="secondary"
              className={classnames('custom-button mx-2 px-2', {
                selected: pointOfSale.intent === 'sold',
              })}
            >
              {t('Sold')}
            </Button>
            <Button
              type="secondary"
              className={classnames('custom-button px-2', {
                selected: pointOfSale.intent !== 'consignment',
              })}
            >
              {t('Consignment')}
            </Button>
            <Button
              type="secondary"
              className={classnames('custom-button mx-2 px-2', {
                selected: pointOfSale.intent === 'received',
              })}
            >
              {t('Received')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Options;
