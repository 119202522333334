import { gql } from '@apollo/client';
import { paginatorInfoFragment } from '../../../services/graphql/paginator.gql';
import { propertyIdentityFragment } from '../Properties/graphql';

export const LIST_TEMPLATES_GQL = gql`
  query templatesQuery($page: Int) {
    templates(page: $page) {
      data {
        id
        name
      }
      paginatorInfo {
        ...paginatorInfo_Part
      }
    }
  }
  ${paginatorInfoFragment}
`;
export const LIST_PROPERTIES_GQL = gql`
  query propertiesQuery {
    properties(first: 1000) {
      data {
        id
        name
      }
    }
  }
`;
export const LIST_LAYOUT_COMPONENTS_GQL = gql`
  query componentsQuery {
    components(first: 1000) {
      data {
        id
        key
        name
      }
    }
  }
`;

export const GET_TEMPLATE_DETAILS_GQL = gql`
  query templateQuery($id: ID) {
    template(id: $id) {
      id
      name
      description
      layout
      search_layout
      result_layout
      result_header
      fields {
        id
        label
        type @client
        component {
          id
          name
          key
        }
        searchComponent {
          id
          name
        }
        dataType {
          id
          name
        }
        property {
          ...propertyIdentity_Part
        }
        is_required
        is_searchable
        is_highlight
        is_single_shape
        config
      }
    }
  }
  ${propertyIdentityFragment}
`;

export const LIST_COMPONENT_CONTENT_GQL = gql`
  query componentContentQuery($id: ID) {
    component(id: $id) {
      id
      name
      key
      dataTypes {
        id
        name
        key
      }
    }
    components(first: 1000) {
      data {
        id
        key
        name
        dataTypes {
          id
          name
          key
        }
      }
    }
  }
`;

export const CREATE_TEMPLATE_GQL = gql`
  mutation createTemplate($name: String!, $description: String) {
    createTemplate(name: $name, description: $description) {
      id
    }
  }
`;
export const UPDATE_TEMPLATE_GQL = gql`
  mutation updateTemplateMutation($id: ID!, $template: UpdateTemplateInput!) {
    updateTemplate(id: $id, template: $template) {
      id
    }
  }
`;

export const DELETE_TEMPLATE_GQL = gql`
  mutation deleteTemplateMutation($id: ID!) {
    deleteTemplate(id: $id) {
      message
    }
  }
`;

export const CREATE_TEMPLATE_FIELD_GQL = gql`
  mutation createTemplateFieldMutation($input: TemplateFieldInput!) {
    createTemplateField(input: $input) {
      id
      dataType {
        id
        name
      }
      property {
        id
        name
      }
      values {
        id
        name
      }
      config
    }
  }
`;
export const DELETE_TEMPLATE_FIELD_GQL = gql`
  mutation deleteTemplateFieldMutation($id: ID!) {
    deleteTemplateField(id: $id) {
      message
    }
  }
`;

export const UPDATE_TEMPLATE_FIELD_GQL = gql`
  mutation updateTemplateFieldMutation(
    $fieldId: ID!
    $field: UpdateTemplateFieldInput!
  ) {
    updateTemplateField(id: $fieldId, field: $field) {
      id
      dataType {
        id
        name
      }
      property {
        ...propertyIdentity_Part
      }
      values {
        id
        name
      }
    }
  }
  ${propertyIdentityFragment}
`;

export const TEMPLATE_FIELD_VALUE_DEPENDENCIES = gql`
  query templateFieldValueDependencies(
    $template_field_id: ID!
    $property_value_id: ID!
  ) {
    templateFieldValueDependencies(
      template_field_id: $template_field_id
      property_value_id: $property_value_id
    ) {
      fieldDependencies {
        id
        label
      }
      valueDependencies {
        field {
          id
          label
        }
        value {
          id
          name
        }
      }
    }
  }
`;
export const TEMPLATE_FIELD_DEPENDENCIES = gql`
  query templateFieldDependenciesQuery($template_field_id: ID!) {
    templateFieldDependencies(template_field_id: $template_field_id) {
      fieldDependencies {
        id
        label
      }
      valueDependencies {
        field {
          id
          label
        }
        value {
          id
          name
        }
      }
    }
  }
`;
export const CREATE_TEMPLATE_FIELD_VALUE_DEPENDENCY = gql`
  mutation createTemplateFieldValueDependencyMutation(
    $template_field_id: ID!
    $property_value_id: ID!
    $dependency: DependencyInput!
  ) {
    createTemplateFieldValueDependency(
      template_field_id: $template_field_id
      property_value_id: $property_value_id
      dependency: $dependency
    ) {
      message
    }
  }
`;
export const CREATE_TEMPLATE_FIELD_DEPENDENCY = gql`
  mutation createTemplateFieldDependencyMutation(
    $template_field_id: ID!
    $dependency: DependencyInput!
  ) {
    createTemplateFieldDependency(
      template_field_id: $template_field_id
      dependency: $dependency
    ) {
      message
    }
  }
`;

export const REMOVE_TEMPLATE_FIELD_DEPENDENCY = gql`
  mutation removeTemplateFieldDependencyMutation(
    $template_field_id: ID!
    $dependency: DependencyInput!
  ) {
    removeTemplateFieldDependency(
      template_field_id: $template_field_id
      dependency: $dependency
    ) {
      message
    }
  }
`;
export const REMOVE_TEMPLATE_FIELD_VALUE_DEPENDENCY = gql`
  mutation removeTemplateFieldValueDependencyMutation(
    $template_field_id: ID!
    $property_value_id: ID!
    $dependency: DependencyInput!
  ) {
    removeTemplateFieldValueDependency(
      template_field_id: $template_field_id
      property_value_id: $property_value_id
      dependency: $dependency
    ) {
      message
    }
  }
`;
export const ATTACH_TEMPLATE_FIELD_VALUE_VARIATION = gql`
  mutation attachPropertyValueVariation(
    $template_field_id: ID!
    $property_value_id: ID!
    $property_value_variation_id: ID!
  ) {
    attachPropertyValueVariation(
      template_field_id: $template_field_id
      property_value_id: $property_value_id
      property_value_variation_id: $property_value_variation_id
    ) {
      message
    }
  }
`;
export const DETACH_TEMPLATE_FIELD_VALUE_VARIATION = gql`
  mutation detachPropertyValueVariation(
    $template_field_id: ID!
    $property_value_id: ID!
    $property_value_variation_id: ID!
  ) {
    detachPropertyValueVariation(
      template_field_id: $template_field_id
      property_value_id: $property_value_id
      property_value_variation_id: $property_value_variation_id
    ) {
      message
    }
  }
`;
