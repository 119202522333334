import React from 'react';
import Table from '../../../../components/_organisms/Table';
import CustomHeader from '../../../../components/_molecules/CustomHeader';
import {
  LIST_CATEGORY_GROUPS_GQL,
  DELETE_CATEGORY_GROUP_GQL,
} from '../graphql';
import { CategoryGroup } from '@wgt/types';

const List: React.FC = () => (
  <div>
    <CustomHeader title={'List Category Groups'} showCreate />
    <Table
      hover
      striped
      columns={[{ label: 'Name', field: 'name' }]}
      query={LIST_CATEGORY_GROUPS_GQL}
      node="categoryGroups"
      deleteMutation={DELETE_CATEGORY_GROUP_GQL}
      getMutationArgs={(item: CategoryGroup) => ({ id: item.id })}
      getConfirmMessage={(item: CategoryGroup) =>
        `Confirm delete of ${item?.name}?`
      }
    />
  </div>
);

export default List;
