import React, { useState, useMemo } from 'react';
import { Tooltip } from 'reactstrap';
import { FiHelpCircle } from 'react-icons/fi';
import classnames from 'classnames';
import { uid } from 'uid';

interface TooltipHelpProps {
  className?: string;
  description: string;
}

export default function TooltipHelp({
  className,
  description,
}: TooltipHelpProps): JSX.Element {
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const id = useMemo(() => {
    return uid();
  }, []);

  return (
    <>
      <FiHelpCircle
        className={classnames('text-muted', className)}
        id={`tooltip-${id}`}
      />
      <Tooltip
        placement="bottom"
        isOpen={tooltipOpen}
        target={`tooltip-${id}`}
        toggle={toggle}
      >
        {description}
      </Tooltip>
    </>
  );
}
