import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useProductUpdate } from '../Provider';
import PagePrice from './PagePrice';
import { PRODUCT_TYPE_PARCEL_ID } from '@wgt/shared/constants';
import { TemplateWithFieldsLayout, Shape, ShapeDescription } from '@wgt/types';
import { Button } from '../../../../../components';
import { useTranslation } from 'react-i18next';
import { Col } from 'reactstrap';

interface TemplateWithShape extends TemplateWithFieldsLayout {
  shapes?: Shape[];
}

const ShapeAndPrice: React.FC = () => {
  const { pages, setShapeDescriptionsVisible } = useProductUpdate();
  const { watch } = useFormContext();
  const { t } = useTranslation('product');

  if (typeof pages.activeIndex === 'undefined') {
    return <></>;
  }
  const template: TemplateWithShape | undefined = useMemo(
    () => pages.active?.category?.template,
    [pages.active?.category?.template],
  );

  const SHAPE_VALUE = `pages[${pages.activeIndex}].shapes`;
  const DESCRIPTIONS = `pages[${pages.activeIndex}].shapes[0].descriptions`;
  const PARCEL_TYPE_VALUE = `pages[${pages.activeIndex}].category.productType.id`;

  const w = watch([SHAPE_VALUE, PARCEL_TYPE_VALUE, DESCRIPTIONS]);

  const shape = useMemo<Shape | undefined>(
    () => template?.shapes?.find((x) => x.id === w[SHAPE_VALUE]?.[0]?.id),
    [w[SHAPE_VALUE], template],
  );
  const selectedShapeDescriptions = useMemo(
    () =>
      w[DESCRIPTIONS]?.map((description: ShapeDescription) =>
        shape?.descriptions?.find(
          (shapeDescription) => shapeDescription.id === description.id,
        ),
      )
        ?.map((desc: ShapeDescription) => desc.name)
        ?.join(', ') ?? '',
    [w[DESCRIPTIONS]],
  );

  return (
    <>
      <Col lg="1" className="pr-0 shape-and-price">
        <PagePrice />
      </Col>
      <Col lg="2" className="shape-and-price">
        {!Number.isNaN(w[PARCEL_TYPE_VALUE]) &&
          Number(w[PARCEL_TYPE_VALUE]) !== PRODUCT_TYPE_PARCEL_ID && (
            <div className="mx-5">
              {shape ? (
                <div className="d-flex flex-column">
                  <img src={shape.image_highlight_url ?? shape.image_url} />
                  <p className="text-center mt-4">{shape.title}</p>
                  <Button
                    small
                    type="primary"
                    htmlType="button"
                    onClick={() => setShapeDescriptionsVisible((x) => !x)}
                    className="d-flex justify-content-center"
                  >
                    {t('Set Shape Description')}
                  </Button>
                  <p className="mt-3">{selectedShapeDescriptions}</p>
                </div>
              ) : (
                <div className="no-shape text-center">
                  <p>{t('No shape selected')}</p>
                </div>
              )}
            </div>
          )}
      </Col>
    </>
  );
};

export default ShapeAndPrice;
