import React from 'react';

import { MediaCenterItemBase } from '@wgt/types';

import ThumbnailItem from './ThumnailItem';

interface ThumbnailsProps {
  loading?: boolean;
  items: MediaCenterItemBase[];
  active?: string | null;
  imageProp: string;
}

function Thumbnails({
  loading,
  items,
  imageProp,
  active,
}: ThumbnailsProps): JSX.Element {
  return (
    <div className="media-center-bottom">
      <div className="thumbnails-holder">
        <div className="thumbnail-items-holder">
          {items?.map((item, key) => (
            <ThumbnailItem
              key={`media-${key}`}
              item={item}
              imageProp={imageProp}
              loading={loading}
              active={active}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Thumbnails;
