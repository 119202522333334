import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Form, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import { useQuery, useMutation } from '@apollo/client';
import {
  UpdateEmployeeJoinRequestInput,
  OfficeOption,
  EmployeeJoinRequest,
  EmployeeJoinRequestInput,
  ApproveEmployeeJoinRequest,
  JobTitle,
  Department,
} from '@wgt/types';
import { useTranslation } from 'react-i18next';
import { SimpleInput, Button, SimpleTextArea } from '../../../../components';
import SelectOfficeCard from '../../../../components/SelectOfficeCard';
import {
  APPROVE_EMPLOYEE_JOIN_REQUEST,
  LIST_OFFICES_GQL,
  LIST_EMPLOYEE_JOIN_REQUESTS,
} from '../graphql';
import useToast from '../../../../hooks/useToast';
import useLoggedContext from '../../../../hooks/useLoggedContext';
import AddDepartment from '../AccountManagement/AddEmployeeModal/AddNewEmployee/AddDepartment';
import AddJobTitle from '../AccountManagement/AddEmployeeModal/AddNewEmployee/AddJobTitle';

interface Props {
  employee?: EmployeeJoinRequest;
  onDelete: () => void;
  onToggle: () => void;
}

export default function AddEmployee({
  employee,
  onDelete,
  onToggle,
}: Props): JSX.Element {
  const { t } = useTranslation(['hr', 'common', 'menus']);
  const { toast } = useToast();
  const { active } = useLoggedContext();

  const methods = useForm<UpdateEmployeeJoinRequestInput>({
    defaultValues: {
      department_ids: employee?.departments?.map((item: Department) =>
        Number(item.id),
      ),
      job_title_ids: employee?.jobTitles?.map((item: JobTitle) =>
        Number(item.id),
      ),
      office: employee?.office,
    },
    shouldUnregister: false,
  });

  const { handleSubmit, errors, register } = methods;

  const [create, { loading: loadingApprove }] = useMutation<
    ApproveEmployeeJoinRequest
  >(APPROVE_EMPLOYEE_JOIN_REQUEST, {
    onCompleted: () => toast('success'),
    onError: () => toast('error'),
    refetchQueries: [{ query: LIST_EMPLOYEE_JOIN_REQUESTS }],
  });

  const { data: dataOffices, loading: loadingOffices } = useQuery<{
    offices: { data: OfficeOption[] };
  }>(LIST_OFFICES_GQL, {
    variables: {
      filter: {
        firm_id: active?.id,
      },
    },
  });

  const onSubmit = handleSubmit((data: EmployeeJoinRequestInput) => {
    const variables = {
      id: employee?.id,
      employee_join_request: {
        ...data,
        office: undefined,
        password: undefined,
        comments: undefined,
        office_id: data.office?.id,
      },
    };

    create({ variables });
  });

  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <p className="text-danger ml-2">
            {t(
              'Only accept Request to Join by paid employees or contractors of firm name.',
            )}
          </p>
        </div>
        <div>
          <Button small type="danger" onClick={onToggle}>
            {t('common:Close')}
          </Button>
        </div>
      </div>
      <FormProvider {...methods}>
        <Form noValidate onSubmit={onSubmit}>
          <Row className="mt-3">
            <Col lg="8">
              <Row>
                <Col lg="6" className="d-flex flex-column">
                  <SimpleInput
                    field="first_name"
                    label={t('First Name')}
                    required
                    defaultValue={employee?.first_name}
                  />
                  <div className="mt-auto">
                    <div className="simple-input">
                      <label>{t('Job Title')}</label>
                    </div>
                    <AddJobTitle autoScroll={false} field="job_title_ids" />
                    <div className="simple-input mt-3">
                      <label>{t('Department')}</label>
                    </div>
                    <AddDepartment autoScroll={false} field="department_ids" />
                  </div>
                </Col>
                <Col lg="6">
                  <SimpleInput
                    field="last_name"
                    label={t('Last Name')}
                    required
                    className="mb-3"
                    defaultValue={employee?.last_name}
                  />
                  <SimpleInput
                    field="email"
                    label={t('common:Email')}
                    required
                    className="mb-3"
                    defaultValue={employee?.email}
                  />

                  <FormGroup className="simple-input mb-3">
                    <Label
                      htmlFor="business_phone-field"
                      xs="12"
                      md="3"
                      className="p-0"
                    >
                      {t('Direct Phone')}
                    </Label>
                    <Row noGutters>
                      <div className="col-12 col-md-7">
                        <Input
                          type="text"
                          name="business_phone"
                          id="business_phone-field"
                          innerRef={register}
                          required
                          invalid={Boolean(errors?.business_phone)}
                          defaultValue={employee?.business_phone}
                        />
                      </div>
                      <div className="col-12 col-md-2 d-flex justify-content-end align-items-center">
                        <Label htmlFor="ext-field" className="mb-0 mr-3">
                          {t('Ext')}
                        </Label>
                      </div>
                      <div className="col-12 col-md-3">
                        <Input
                          type="text"
                          name="business_phone_extension"
                          id="business_phone_extension-field"
                          innerRef={register}
                          defaultValue={employee?.business_phone_extension}
                        />
                      </div>
                    </Row>
                  </FormGroup>

                  <SimpleInput
                    field="business_mobile"
                    label={t('Office Mobile')}
                    required
                    defaultValue={employee?.business_mobile}
                  />
                </Col>
              </Row>
            </Col>
            <Col lg="4" className="d-flex flex-column pt-1">
              <div className="mt-4">
                {!loadingOffices && dataOffices?.offices?.data && (
                  <SelectOfficeCard
                    options={dataOffices?.offices?.data}
                    field="office"
                    placeholder={t('Choose an office')}
                  />
                )}
              </div>
            </Col>
            <Col lg="7" className="mt-3">
              <SimpleTextArea
                label={t('Registration Purposes')}
                field="comments"
                rows={4}
                placeholder={t(
                  'Provide details of this person relationship with firm name.Thank you.',
                )}
              />
            </Col>

            <Col lg="5" className="d-flex align-items-end">
              <div className="d-flex justify-content-between w-100">
                <Button type="danger" onClick={onDelete}>
                  {t('Delete Request')}
                </Button>
                <div>
                  <div className="d-flex flex-row align-items-center">
                    <Label className="label-password">
                      {t('common:Re-enter password to confirm changes')}
                    </Label>
                    <SimpleInput
                      type="password"
                      name="password"
                      field="password"
                    />
                  </div>
                </div>
                <Button
                  htmlType="submit"
                  disabled={loadingApprove}
                  isLoading={loadingApprove}
                >
                  {t('Add Employee')}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </>
  );
}
