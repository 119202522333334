/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Row, Col, Collapse } from 'reactstrap';
import { IconButton } from 'evergreen-ui';
import { FiMoreHorizontal, FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { Firm, Employee } from '@wgt/types';
import { useTranslation } from 'react-i18next';
import PlaceholderCompany from '../../../../../../assets/placeholder-company.png';
import { Title, AlertMessage } from '../../../../../../components';
import EmployeeList from './EmployeeList';

interface Props {
  firm: Firm;
}

export default function FirmList({ firm }: Props): JSX.Element {
  const [collapse, setCollapse] = useState(false);
  const toggle = () => setCollapse(!collapse);

  const { t } = useTranslation(['hr', 'common']);

  return (
    <Row className="border rounded py-2 mb-3">
      <Col lg="auto" className="pl-2 pr-0">
        <img
          src={firm.extra?.logo_url ?? PlaceholderCompany}
          className="img-thumbnail-regular"
        />
      </Col>
      <Col lg="4" className="d-flex align-items-start flex-column py-1">
        <Title heading="h3" className="mb-auto">
          {firm.name}
        </Title>
        <div className="mb-auto">
          <p>
            <strong>{firm.address?.phone}</strong>
          </p>
        </div>
        <p>{firm.extra?.email}</p>
      </Col>
      <Col lg="4" className="pt-2">
        <p>
          {firm.address?.number} {firm.address?.address}
        </p>
        <p>
          {firm.address?.city}
          {!!firm.address?.city && ','} {firm.address?.state}
        </p>
        <p>
          {firm.address?.country.name}
          {!!firm.address?.country.name && ','} {firm.address?.postal_code}
        </p>
      </Col>
      <Col lg="auto" className="ml-auto">
        <IconButton
          appearance="minimal"
          icon={FiMoreHorizontal}
          iconSize={36}
          className="px-4"
        />

        <IconButton
          appearance="minimal"
          icon={collapse ? FiChevronUp : FiChevronDown}
          iconSize={26}
          className="px-4 mt-2"
          onClick={toggle}
        />
      </Col>
      <Col lg="12" className="px-2">
        <Collapse isOpen={collapse}>
          {firm.employees?.length ? (
            <div className="px-3">
              {firm.employees.map((employee: Employee, key: number) => (
                <EmployeeList employee={employee} key={key} />
              ))}
            </div>
          ) : (
            <AlertMessage className="mt-2 mb-0">
              {t('common:There is no value registered at the moment', {
                value: t('Employee'),
              })}
            </AlertMessage>
          )}
        </Collapse>
      </Col>
    </Row>
  );
}
