import React, { SetStateAction, Dispatch } from 'react';
import { FaTimes } from 'react-icons/fa';
import { Button, SimpleInput } from '../../../../../../../components';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import { Form, Row } from 'reactstrap';
import { useMutation } from '@apollo/client';
import {
  UPDATE_TEMPLATE_GQL,
  GET_TEMPLATE_DETAILS_GQL,
} from '../../../../graphql';
import BtnLoading from '../../../../../../../components/_molecules/BtnLoading';
import { TemplateBase } from '@wgt/types';
import useToast from '../../../../../../../hooks/useToast';

interface Props {
  setEditing: Dispatch<SetStateAction<boolean>>;
  template: TemplateBase;
}

const TemplateTitleForm: React.FC<Props> = ({ setEditing, template }) => {
  const { toast } = useToast();
  const methods = useForm({
    defaultValues: template,
  });
  const [updateTemplate, { loading }] = useMutation(UPDATE_TEMPLATE_GQL, {
    onCompleted: () => {
      toast('success');
      setEditing(false);
    },
    refetchQueries: [
      {
        query: GET_TEMPLATE_DETAILS_GQL,
        variables: { id: template.id },
      },
    ],
  });

  const onSubmit: SubmitHandler<TemplateBase> = async (templateInput) => {
    const { id, description } = template;
    updateTemplate({
      variables: {
        id,
        template: {
          ...templateInput,
          description: description ?? undefined,
        },
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <Row>
        <Form
          className="d-flex align-items-center  mb-3"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <SimpleInput field="name" />
          <Button htmlType="submit" disabled={loading}>
            {loading ? <BtnLoading /> : 'Save'}
          </Button>
        </Form>

        <button onClick={() => setEditing(false)} className="controller">
          <FaTimes />
        </button>
      </Row>
    </FormProvider>
  );
};

export default TemplateTitleForm;
