import React, { CSSProperties } from 'react';
import { Button, Pill, PillProps } from 'evergreen-ui';
import classnames from 'classnames';

interface BagdeProps extends PillProps {
  text: string;
}
export interface Props {
  type?:
    | 'primary'
    | 'secondary'
    | 'danger'
    | 'link'
    | 'blue'
    | 'orange'
    | 'gray'
    | 'combobox'
    | 'text'
    | 'white'
    | 'danger-outline'
    | undefined;
  disabled?: boolean;
  htmlType?: 'submit' | 'reset' | 'button';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: any;
  small?: boolean | undefined;
  className?: string;
  isLoading?: boolean;
  id?: string;
  iconBefore?: React.ElementType | JSX.Element | null | false;
  style?: CSSProperties;
  badgeRight?: BagdeProps;
  badgeLeft?: BagdeProps;
}

const ButtonComponent: React.FC<Props> = ({
  children,
  type,
  disabled,
  small,
  onClick,
  htmlType,
  className,
  isLoading,
  id,
  iconBefore,
  style,
  badgeLeft,
  badgeRight,
}) => {
  return (
    <Button
      style={style}
      iconBefore={iconBefore}
      isLoading={isLoading}
      type={htmlType || 'button'}
      onClick={onClick}
      disabled={disabled}
      appearance="primary"
      intent="success"
      id={id}
      className={classnames('button', className, {
        small,
        inactive: disabled,
        [type || 'primary']: true,
      })}
    >
      <>
        {badgeLeft && (
          <Pill color={badgeLeft.color} className="button-pill left" isSolid>
            {badgeLeft.text}
          </Pill>
        )}
        {children}
        {badgeRight && (
          <Pill color={badgeRight.color} className="button-pill right" isSolid>
            {badgeRight.text}
          </Pill>
        )}
      </>
    </Button>
  );
};

export default ButtonComponent;
