import React, { useState } from 'react';
import {
  UncontrolledPopover,
  PopoverBody,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Button, Text } from '../../';
import { COMPOUND_LIST } from '@wgt/shared/constants';

export default function Compound(): JSX.Element {
  const [popoverCompound, setPopoverCompound] = useState<boolean>(false);
  const { t } = useTranslation(['pointOfSalesCompounds', 'pointOfSales']);
  const togglePopoverCompound = () => setPopoverCompound(!popoverCompound);

  const { register, watch } = useFormContext();
  const compound = watch('compound');

  return (
    <>
      <Button type="combobox" id="listCompoundPopover">
        <div className="d-flex justify-content-between w-100">
          <div>{t(compound ? compound : 'pointOfSales:Compound')}</div>
          <div>{popoverCompound ? <FiChevronUp /> : <FiChevronDown />}</div>
        </div>
      </Button>
      <UncontrolledPopover
        trigger="legacy"
        isOpen={popoverCompound}
        target="listCompoundPopover"
        toggle={togglePopoverCompound}
      >
        <PopoverBody className="popover-list-payment">
          <Text type="muted">{t('pointOfSales:Compounding Period')}</Text>
          <Row className="mt-2 payment-type-checkbox">
            <Col xs="12">
              {COMPOUND_LIST.map((period) => (
                <FormGroup check key={period} className="mt-1">
                  <Label check>
                    <Input
                      type="radio"
                      name="compound"
                      defaultValue={period}
                      defaultChecked={compound === period}
                      innerRef={register()}
                    />
                    {t(period)}
                  </Label>
                </FormGroup>
              ))}
            </Col>
          </Row>
        </PopoverBody>
      </UncontrolledPopover>
    </>
  );
}
