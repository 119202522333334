import React, { useMemo } from 'react';
import { Pane, Popover, Button } from 'evergreen-ui';
import { FaChevronDown } from 'react-icons/fa';
import useLoggedContext from '../../../hooks/useLoggedContext';
import './styles.scss';

const NavbarFirmMenu: React.FC = () => {
  const { active, list, select } = useLoggedContext();
  const listWithoutActive = useMemo(
    () => list.filter((employment) => employment.id !== active?.id),
    [list, active],
  );
  return (
    <Popover
      content={
        <Pane display="flex" flexDirection="column">
          {listWithoutActive?.map((employment) => (
            <Button
              appearance="minimal"
              key={employment.id}
              onClick={() => select(employment)}
            >
              {employment.name}
            </Button>
          )) ?? []}
        </Pane>
      }
    >
      <Button
        appearance="minimal"
        margin={0}
        padding={0}
        fontSize={14}
        fontFamily="noto_sansregular"
        disabled={!listWithoutActive.length}
      >
        {active?.name ?? 'World Gem Trade'}&nbsp;
        {listWithoutActive.length > 0 ? <FaChevronDown /> : <React.Fragment />}
      </Button>
    </Popover>
  );
};

export default NavbarFirmMenu;
