import React, { useContext, useMemo, useState } from 'react';
import { Title } from '../../../../../../../../../components';
import { Collapse, Button, Col, Row } from 'reactstrap';
import { TemplateFieldsContext } from '../../../../../../../../../hooks/useTemplateFieldsContext';
import { useCollapseContext } from '../../../../../../../../../services/collapse';
import FieldDependenciesForm from '../../FieldDependenciesForm';
import { SubmitHandler } from 'react-hook-form';
import FieldDependenciesList from '../../FieldDependenciesList';
import {
  SubmitDependenciesFormProps,
  TemplateFieldDependencyGraphql,
} from '../../../../../../types';
import {
  CREATE_TEMPLATE_FIELD_DEPENDENCY,
  TEMPLATE_FIELD_DEPENDENCIES,
  REMOVE_TEMPLATE_FIELD_DEPENDENCY,
} from '../../../../../../graphql';
import { useMutation, useQuery } from '@apollo/client';
import ConfirmDialog from '../../../../../../../../../components/_molecules/ConfirmDialog';
import { TemplateFieldBase } from '@wgt/types';
import useToast from '../../../../../../../../../hooks/useToast';

const FieldDependency: React.FC = () => {
  const { fields, active: field } = useContext(TemplateFieldsContext);
  const activeField = field as TemplateFieldBase;
  const { active, set } = useCollapseContext();
  const { toast } = useToast();
  const [
    dependencyToRemove,
    setDependencyToRemove,
  ] = useState<TemplateFieldDependencyGraphql | null>(null);

  const { data, loading, refetch } = useQuery(TEMPLATE_FIELD_DEPENDENCIES, {
    skip: active !== 'field_dependency' || !activeField?.id,
    variables: {
      template_field_id: activeField?.id,
    },
  });

  const [createFieldDependency, { loading: creating }] = useMutation(
    CREATE_TEMPLATE_FIELD_DEPENDENCY,
    {
      onCompleted: () => {
        refetch();
        toast('success');
      },
    },
  );
  const [removeFieldDependency, { loading: removing }] = useMutation(
    REMOVE_TEMPLATE_FIELD_DEPENDENCY,
    {
      onCompleted: () => {
        refetch();
        setDependencyToRemove(null);
        toast('success');
      },
    },
  );
  const filteredFields = useMemo(
    () =>
      fields?.filter(
        (x) => x.formValues?.label !== activeField?.formValues?.label,
      ) ?? [],
    [fields, activeField],
  );

  const onSubmit: SubmitHandler<SubmitDependenciesFormProps> = (values) => {
    if (!activeField || !values) {
      return;
    }

    createFieldDependency({
      variables: {
        template_field_id: Number(activeField.id),

        dependency: {
          template_field_id: Number(values.fieldId),
          property_value_id: Number(values.valueId) || null,
        },
      },
    });
  };

  const onDeleteDependency = () => {
    removeFieldDependency({
      variables: {
        template_field_id: activeField?.id,
        ...dependencyToRemove,
      },
    });
  };

  if (!filteredFields.length) {
    return <></>;
  }

  return (
    <div>
      <ConfirmDialog
        isShown={!!dependencyToRemove}
        onConfirm={onDeleteDependency}
        isLoading={removing}
        title="Confirm delete dependency?"
        onClose={() => setDependencyToRemove(null)}
      />

      <Button
        color="clear"
        onClick={() => set('field_dependency')}
        className="dependency-title-holder"
      >
        <Title size={200}>Field Dependencies</Title>
      </Button>

      <Collapse isOpen={active === 'field_dependency'} className=" m-3">
        <Row>
          <Col>
            <FieldDependenciesForm
              loading={creating}
              filteredFields={filteredFields}
              onSubmit={onSubmit}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <FieldDependenciesList
              loading={loading}
              fieldDependencies={
                data?.templateFieldDependencies?.fieldDependencies
              }
              valueDependencies={
                data?.templateFieldDependencies?.valueDependencies
              }
              setDependencyToRemove={setDependencyToRemove}
            />
          </Col>
        </Row>
      </Collapse>
    </div>
  );
};

export default FieldDependency;
