import React, { useMemo } from 'react';
import { TemplateWithFieldsLayout, Shape } from '@wgt/types';
import { Pane } from 'evergreen-ui';
import { useFormContext } from 'react-hook-form';
import ShapeGroupsForm from '../../../../../components/ShapeGroupsForm';
import { PARCEL_TYPE_MULTIPLE_ID } from '@wgt/shared/constants';

import { useProductUpdate } from '../Provider';
import ShapeDescriptionSelector from '../../../../../components/ShapeDescriptionSelector';

interface TemplateWithShape extends TemplateWithFieldsLayout {
  shapes?: Shape[];
}

export default function ShapeGroupSelector(): JSX.Element {
  const {
    pages,
    isFormEnabled,
    isShapeDescriptionsVisible,
    setShapeDescriptionsVisible,
  } = useProductUpdate();

  const template: TemplateWithShape | undefined = useMemo(
    () => pages.active?.category?.template,
    [pages.active?.category?.template],
  );

  const shapes = useMemo<Shape[]>(() => template?.shapes ?? [], [template]);

  const { watch } = useFormContext();
  const shapePath = `pages[${pages.activeIndex}].shapes`;
  const parcelTypePath = `pages[${pages.activeIndex}].fields.parcel_type_id`;
  const w = watch([parcelTypePath, shapePath]);
  const parcelTypeId = w[parcelTypePath];

  const selectedShape = useMemo<Shape | undefined>(
    () => template?.shapes?.find((x) => x.id === w[shapePath]?.[0]?.id),
    [w[shapePath], template],
  );

  if (!pages.fields.length || typeof pages.activeIndex === 'undefined') {
    return <></>;
  }
  return (
    <div className="mt-5">
      {selectedShape && (
        <Pane
          padding={15}
          position="absolute"
          right={0}
          maxHeight={350}
          zIndex={2}
        >
          <ShapeDescriptionSelector
            shapeDescriptions={selectedShape?.descriptions}
            selectorProps={{
              name: `${shapePath}[0].descriptions`,
              isVisible: isShapeDescriptionsVisible,
            }}
            onClose={() => setShapeDescriptionsVisible(false)}
            isAddStock
          />
        </Pane>
      )}
      <ShapeGroupsForm
        shapes={shapes}
        field={`pages[${pages.activeIndex}].shapes.`}
        isMulti={Number(parcelTypeId) === PARCEL_TYPE_MULTIPLE_ID}
        disabled={!isFormEnabled}
      />
    </div>
  );
}
