import React from 'react';
import { Row, Col, NavLink } from 'reactstrap';
import classnames from 'classnames';
import PolicyType from './PolicyType';
import Shipping from './Shipping';
import Options from './Options';
import usePointOfSaleUpdate from '../usePointOfSaleUpdate';
import { FormProvider, useForm } from 'react-hook-form';

export default function StockOptions(): JSX.Element {
  const { contentTab, setContentTab } = usePointOfSaleUpdate();
  const methods = useForm();
  return (
    <FormProvider {...methods}>
      <Row noGutters className="bg-light" id="stock-options">
        <Col>
          <NavLink
            className={classnames('pr-0', {
              'option-selected': contentTab === 'policy',
            })}
            onClick={() => {
              setContentTab('policy');
            }}
          >
            <PolicyType />
          </NavLink>
        </Col>
        <Col>
          <NavLink
            className={classnames({
              'option-selected': contentTab === 'shipping',
            })}
            onClick={() => {
              setContentTab('shipping');
            }}
          >
            <Shipping />
          </NavLink>
        </Col>
        <Col
          xs="5"
          className="d-flex align-items-center justify-content-end pr-2"
        >
          <Options />
        </Col>
      </Row>
    </FormProvider>
  );
}
