import withAuth from '../hocs/withAuth';
import HomeScreen from './Home';
import AuthScreen from './Auth';
import ForgotPassword from './Auth/ForgotPassword';
import ResetPassword from './Auth/ResetPassword';
import Logout from './Auth/Logout';
import CRMScreen from './Crm';
import Reports from './Reports';

export default [
  {
    component: HomeScreen,
    exact: true,
    path: '/',
  },
  {
    component: AuthScreen,
    path: '/auth',
    routes: [
      {
        component: ForgotPassword,
        path: '/auth/forgot',
      },
      {
        component: ResetPassword,
        path: '/auth/recovery',
      },
      {
        component: Logout,
        path: '/auth/logout',
      },
    ],
  },
  {
    component: withAuth(CRMScreen),
    name: 'Dashboard',
    path: '/crm',
  },
  {
    component: withAuth(Reports),
    name: 'Reports',
    path: '/reports',
  },
];
