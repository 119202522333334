import { gql } from '@apollo/client';

export const GET_TEMPLATE_FIELD_VALUES = gql`
  query propertyValuesTemplateField($templateId: ID!) {
    templateField(id: $templateId) {
      values {
        id
        name
        variations {
          id
          name
          variationValues {
            id
            name
            key
          }
        }
      }
    }
  }
`;
