import { Pane, Switch } from 'evergreen-ui';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import classnames from 'classnames';

interface SimpleSwitcherProps {
  field: string;
  label?: string;
  className?: string;
}

const SimpleSwitcher: React.FC<SimpleSwitcherProps> = ({
  field,
  label,
  className,
}) => {
  const { getValues } = useFormContext();
  return (
    <Controller
      name={field}
      defaultValue={!!getValues(field)}
      render={({ onChange, value }) => (
        <Pane className={classnames('simple-input', className)}>
          {label && <label htmlFor={`switch-${field}`}>{label}</label>}
          <Switch
            id={`switch-${field}`}
            onChange={(e) => onChange(e.target.checked)}
            checked={value}
          />
        </Pane>
      )}
    />
  );
};

export default SimpleSwitcher;
