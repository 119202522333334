import React, { useState, useMemo, useRef } from 'react';
import { UncontrolledPopover, PopoverBody, Row, Col, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { useQuery, useMutation } from '@apollo/client';
import classnames from 'classnames';
import { useFormContext, useForm } from 'react-hook-form';
import { Department, DepartmentInput } from '@wgt/types';
import { uid } from 'uid';
import { Button } from '../../../../../../../components';
import { GET_DEPARTMENTS_GQL, CREATE_DEPARTMENT_GQL } from '../graphql';
import useToast from '../../../../../../../hooks/useToast';
import Item from './Item';
import useLoggedContext from '../../../../../../../hooks/useLoggedContext';
import Loader from '../../../../../../../components/_organisms/Loader';

interface Props {
  autoScroll?: boolean;
  field?: string;
}

export default function AddDepartment({
  autoScroll = true,
  field = 'departments',
}: Props): JSX.Element {
  const { t } = useTranslation(['hr', 'common']);
  const collapseDepartmentEnd = useRef<null | HTMLDivElement>(null);
  const { active } = useLoggedContext();

  const [popoverOpen, setPopoverOpen] = useState(false);

  const toggle = () => setPopoverOpen(!popoverOpen);
  const { toast } = useToast();

  const { watch } = useFormContext();

  const departmentValues = watch(field);

  const { register, errors, handleSubmit, reset } = useForm({
    defaultValues: {
      name: '',
      parent_id: '',
    },
  });

  const { data, loading } = useQuery<{ departments: { data: Department[] } }>(
    GET_DEPARTMENTS_GQL,
  );

  const [create] = useMutation(CREATE_DEPARTMENT_GQL, {
    refetchQueries: [{ query: GET_DEPARTMENTS_GQL }],
    onCompleted: () => [toast('success'), reset()],
  });

  const departments = useMemo(() => {
    return (
      data?.departments?.data?.filter(
        (department: Department) => !department.parent,
      ) || []
    );
  }, [data?.departments?.data]);

  const idPopover = useMemo(() => uid(), []);

  const scrollToBottom = () => {
    setTimeout(() => {
      collapseDepartmentEnd.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }, 300);
  };

  const handleAddDepartment = handleSubmit(
    ({ name, parent_id }: DepartmentInput) => {
      create({
        variables: {
          name,
          parent_id: parent_id ? parent_id : undefined,
          tenant_id: active?.id,
        },
      });
    },
  );

  return (
    <>
      <Button id={`idPopover${idPopover}`} type="combobox">
        <div className="d-flex justify-content-between w-100">
          <div className="text-truncate">
            {departmentValues.length
              ? data?.departments?.data
                  .filter((department) =>
                    departmentValues.includes(Number(department.id)),
                  )
                  .map((department) => department.name)
                  .join(', ')
              : t('Click to Select Department')}
          </div>
          <div>{popoverOpen ? <FiChevronUp /> : <FiChevronDown />}</div>
        </div>
      </Button>

      <UncontrolledPopover
        trigger="legacy"
        target={`idPopover${idPopover}`}
        toggle={toggle}
        isOpen={popoverOpen}
        className="popover-department"
        placement="bottom"
      >
        <PopoverBody className="px-4 pt-3 pb-2">
          <Row>
            <Col xs="12">
              <p className="title">{t('Department')}</p>
              {loading ? (
                <Loader small />
              ) : (
                <div className="popover-scroll">
                  {departments.map((department: Department) => (
                    <Item
                      department={department}
                      key={department.id}
                      field={field}
                      {...(autoScroll && { scrollToBottom })}
                    />
                  ))}
                  <div id="collapseDepartmentEnd" ref={collapseDepartmentEnd} />
                </div>
              )}
            </Col>
          </Row>
          <Row className="mb-2">
            <Col xs="12" className="d-flex align-items-center mt-3">
              <div>
                <div className="simple-input">
                  <Input
                    type="text"
                    name="name"
                    className={classnames('mb-2', {
                      'is-invalid': errors?.name,
                    })}
                    innerRef={register({ required: true })}
                    placeholder={t('Type the department name')}
                  />
                </div>
                <div className="simple-input">
                  <Input
                    type="select"
                    name="parent_id"
                    className="mb-2 px-1"
                    innerRef={register()}
                  >
                    <option value="">{t('Select a parent department')}</option>
                    {departments.map((department: Department) => (
                      <option value={department.id} key={department.id}>
                        {department.name}
                      </option>
                    ))}
                  </Input>
                </div>
              </div>
              <Button
                small
                htmlType="button"
                className="ml-2 align-self-end mb-2"
                onClick={handleAddDepartment}
              >
                {t('common:Add')}
              </Button>
            </Col>
          </Row>
        </PopoverBody>
      </UncontrolledPopover>
    </>
  );
}
