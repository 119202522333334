import { SideSheet, Position, Pane } from 'evergreen-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FiInfo } from 'react-icons/fi';
import { Button } from '../../../../../../../components';

interface ConfirmModalProps {
  isShown: boolean;
  onConfirm(): void;
  onCancel(): void;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  isShown,
  onCancel,
  onConfirm,
}) => {
  const { t } = useTranslation(['pointOfSales', 'common']);
  return (
    <SideSheet
      position={Position.TOP}
      isShown={isShown}
      onCloseComplete={onCancel}
      shouldCloseOnOverlayClick={false}
      preventBodyScrolling
    >
      <Pane className="confirm-modal">
        <Pane
          padding="40px"
          display="flex"
          alignItems="center"
          flexDirection="column"
        >
          <p>
            {t('Stock rows have not been confirmed for shipping package')}
            <br />
            {t(
              'Do you still want to record payment for invoice without confirming?',
            )}
          </p>
          <Pane>
            <Button onClick={onConfirm} className="text-uppercase">
              {t('common:yes')}
            </Button>
            <Button type="danger" className="text-uppercase" onClick={onCancel}>
              {t('common:no')}
            </Button>
          </Pane>
        </Pane>
        <Pane
          display="flex"
          justifyContent="space-between"
          paddingY="5px"
          paddingX="30px"
        >
          <small>
            <FiInfo className="mx-2 text-muted" />
            {t(
              'Bar scan each sku to confirm all items are in shipping package',
            )}
          </small>
          <small>
            {t('World Gem Trade is an anti money laundering network')}
          </small>
        </Pane>
      </Pane>
    </SideSheet>
  );
};

export default ConfirmModal;
