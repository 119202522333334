import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { MediaCenterItemBase, Report } from '@wgt/types';
import { useTranslation } from 'react-i18next';
import { Title, Line } from '../../../../../components';
import { ReportsList } from '../../types';
import ReportsTable from '../../ProductPageIdPut/ProductPageIdReports/ReportsTable';
import { LIST_FILES } from '../../Update/MediaCenter/graphql';

export default function ProductPageIdReports(): JSX.Element {
  const params = useParams<{ productId: string; pageId: string }>();
  const { t } = useTranslation(['product', 'common']);

  const filter = useMemo(
    () => ({
      product_id: params.productId,
      type: 'guide',
      pages: {
        id: [params.pageId],
      },
    }),
    [params],
  );

  const { data } = useQuery<{
    files: { data: Report[] };
  }>(LIST_FILES, {
    variables: {
      filter,
    },
    skip: !params.pageId,
  });

  const reportListObj: ReportsList = useMemo(() => {
    if (!data?.files?.data) {
      return {
        right: [] as MediaCenterItemBase[],
        left: [] as MediaCenterItemBase[],
      };
    }
    const separatedList = data.files.data.reduce(
      (acc, currentItem, currentIndex) => {
        const transformAcc = { ...acc };

        if (currentIndex >= 4 && currentIndex % 4 === 0) {
          transformAcc.right.push(currentItem);
        } else {
          transformAcc.left.push(currentItem);
        }
        return transformAcc;
      },
      { right: [] as MediaCenterItemBase[], left: [] as MediaCenterItemBase[] },
    );
    return separatedList;
  }, [data?.files?.data]);

  if (!data?.files?.data.length) {
    return <></>;
  }

  return (
    <div className="reports-content mt-4">
      <div className="d-flex justify-content-between d-flex align-items-center">
        <Title heading="h2">{t('Reports')}</Title>
      </div>

      <Line />

      <Row>
        <Col lg={6} xs={12} className="reports-content-list">
          {!!reportListObj.left.length && (
            <ReportsTable reports={reportListObj.left} />
          )}
        </Col>
        <Col lg={6} xs={12} className="reports-content-list">
          {!!reportListObj.right.length && (
            <ReportsTable reports={reportListObj.right} />
          )}
        </Col>
      </Row>
    </div>
  );
}
