/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useFormContext } from 'react-hook-form';
import './styles.scss';
import classnames from 'classnames';
import Variation from './Variation';
import PropertyValueCheckBox from './PropertyValueCheckBox';
import { getProp } from '../../utils/methods';

interface ButtonController {
  identifier: string;
  label: string;
  image?: string;
}

export interface PropertyValueVariationCheckBoxProps {
  label?: string;
  field: string;
  isMulti?: boolean;
  required?: boolean;
  imageOption?: boolean;
  options: any[];
  controller?: ButtonController;
  className?: string;
  disabled?: boolean;
}

export default function PropertyValueVariationCheckBox({
  label,
  field,
  required,
  isMulti,
  options,
  className,
  disabled,
  controller = { identifier: 'id', label: 'name', image: '' },
}: PropertyValueVariationCheckBoxProps): JSX.Element {
  const { errors } = useFormContext();

  const optionsFiltered: {
    withVariations: any[];
    emptyVariations: any[];
  } = options.reduce(
    (acc, option) => {
      if (option.variations?.length) {
        acc.withVariations.push(option);
      } else {
        acc.emptyVariations.push(option);
      }
      return acc;
    },
    { withVariations: [], emptyVariations: [] },
  );

  return (
    <>
      <div
        className={classnames(
          'form-group wgt-check-button-variations',
          className,
          {
            invalid: getProp(errors, field) || !!errors?.[field],
          },
        )}
      >
        {label && <label className="form-check-label disabled">{label}</label>}
        {optionsFiltered.emptyVariations.map((option) => (
          <div
            key={`empty-${option[controller.identifier]}`}
            className="variations-options"
          >
            {optionsFiltered.withVariations.length && <label />}
            <PropertyValueCheckBox
              field={field}
              option={option}
              disabled={disabled}
              required={required}
            />
          </div>
        ))}
        {optionsFiltered.withVariations.map((option) => (
          <div
            key={option[controller.identifier]}
            className="variations-options"
          >
            <label>{option[controller.label]}</label>
            {option.variations.map((variation: any, key: number) => (
              <Variation
                key={`variation-${key}`}
                field={field}
                variation={variation}
                isMulti={isMulti}
                required={required}
                propertyValue={option}
                disabled={disabled}
              />
            ))}
          </div>
        ))}
      </div>
    </>
  );
}
