/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { ApolloClient, from } from '@apollo/client';
import { auth, http, error } from './links';
import cache from './cache';

declare global {
  interface Window {
    React: any;
  }
}

export default function createClient() {
  const link = from([error(), auth(), http()]);

  const client = new ApolloClient({
    link,
    cache,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
    },
  });

  return client;
}
