import { gql } from '@apollo/client';
import { employeeIdentityFragment } from './AccountManagement/graphql';
import { paginatorInfoFragment } from '../../../services/graphql/paginator.gql';

export const LIST_DEPARTMENTS_GQL = gql`
  query departmentsQuery {
    workOrderTypeGroups(first: 1000) {
      data {
        id
        name
        workOrderTypes {
          id
          name
        }
      }
    }
  }
`;

export const CREATE_DEPARTMENT_GQL = gql`
  mutation createDepartment($name: String!) {
    createWorkOrderTypeGroup(name: $name) {
      id
    }
  }
`;

export const LIST_OFFICES_GQL = gql`
  query($filter: OfficeFilterInput) {
    offices(first: 1000, filter: $filter) {
      data {
        id
        name
        address
        state
        phone
        country {
          name
        }
      }
    }
  }
`;

export const GET_EMPLOYEE = gql`
  query($id: ID!) {
    employee(id: $id) {
      ...employeeIdentity_Part
    }
  }
  ${employeeIdentityFragment}
`;

export const LIST_EMPLOYEE_JOIN_REQUESTS = gql`
  query($page: Int) {
    employeeJoinRequests(first: 1000, page: $page) {
      data {
        id
        first_name
        last_name
        email
        phone
        business_phone
        business_phone_extension
        business_mobile
        message
        office {
          id
          name
          address
          phone
          postal_code
          city
          state
          country {
            name
          }
        }
        jobTitles {
          id
          name
        }
        departments {
          id
          name
        }
      }
      paginatorInfo {
        ...paginatorInfo_Part
      }
    }
  }
  ${paginatorInfoFragment}
`;

export const DELETE_EMPLOYEE_JOIN_REQUEST = gql`
  mutation deleteEmployeeJoinRequest($id: ID!) {
    deleteEmployeeJoinRequest(id: $id) {
      message
    }
  }
`;

export const APPROVE_EMPLOYEE_JOIN_REQUEST = gql`
  mutation approveEmployeeJoinRequest(
    $id: ID!
    $employee_join_request: UpdateEmployeeJoinRequestInput!
  ) {
    approveEmployeeJoinRequest(
      id: $id
      employee_join_request: $employee_join_request
    ) {
      message
    }
  }
`;
