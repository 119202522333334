import React from 'react';
import LogoImage from '../../../assets/logo.png';

const Logo: React.FC = () => {
  return (
    <div className="image-logo">
      <img src={LogoImage} alt="wgt logo" />
    </div>
  );
};

export default Logo;
