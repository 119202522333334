import { gql } from '@apollo/client';

export const INVOICE_DETAILS = gql`
  query invoice($id: ID!) {
    invoice(id: $id) {
      id
      snapshot
      pointOfSale {
        firm {
          name
          website
          vatcode
          address {
            address
            number
            phone
            postal_code
            city
            state
            country {
              name
            }
          }
          extra {
            logo_url
          }
        }
        executiveBuyer {
          employments {
            name
            website
            vatcode
            address {
              address
              number
              phone
              postal_code
              city
              state
              country {
                name
              }
            }
          }
        }
      }
    }
  }
`;
export const INVOICES = gql`
  query invoices($filter: InvoiceFilterInput) {
    invoices(filter: $filter, first: 1000) {
      data {
        id
        status
        total_paid
        total_open_payments
        pointOfSale {
          office {
            firm {
              name
            }
          }
        }
        payments {
          status
          amount
          types {
            name
          }
        }
      }
    }
  }
`;

export const TRANSACTION_TYPES = gql`
  query transactionTypes {
    transactionTypes(first: 1000) {
      id
      name
    }
  }
`;
