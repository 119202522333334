/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  LayoutComponentFormValuesGraphqlCreateInput,
  LayoutComponentFormValuesGraphqlUpdateInput,
} from '@wgt/types';
import {
  ConvertFormValuesToGraphqlCreateInput,
  ConvertFormValuesToGraphqlUpdateInput,
  ConvertJsonLayoutToTemplateFields,
} from './types';
import { concat } from '@wgt/shared';

export const convertLayoutComponentFormValuesToGraphqlCreateInput: ConvertFormValuesToGraphqlCreateInput = (
  input,
) => {
  if (!input.component_id || !input.data_type_id || !input.property_id) {
    return false;
  }
  const returnInput: LayoutComponentFormValuesGraphqlCreateInput = {
    label: input.label,
    template_id: input.template_id,
    is_required: input.is_required,
    is_searchable: input.is_searchable,
    is_highlight: input.is_highlight,
    is_single_shape: input.is_single_shape,
    component_id: parseInt(input.component_id.toString(), 10),
    search_component_id: parseInt(
      input?.search_component_id?.toString() ?? '',
      10,
    ),
    data_type_id: parseInt(input.data_type_id.toString(), 10),
    property_id: parseInt(input.property_id.toString(), 10),
    values:
      input?.values
        .filter((value) => value.selected)
        .map((value) => value.id) ?? [],
    ...(input.config && { config: JSON.stringify(input.config) }),
  };
  return returnInput;
};
export const convertLayoutComponentFormValuesToGraphqlUpdateInput: ConvertFormValuesToGraphqlUpdateInput = (
  input,
) => {
  if (!input.component_id || !input.data_type_id || !input.property_id) {
    return false;
  }
  const returnInput: LayoutComponentFormValuesGraphqlUpdateInput = {
    label: input.label,
    component_id: parseInt(input.component_id.toString(), 10),
    search_component_id: parseInt(
      input?.search_component_id?.toString() ?? '',
      10,
    ),
    data_type_id: parseInt(input.data_type_id.toString(), 10),
    property_id: parseInt(input.property_id.toString(), 10),
    is_required: input.is_required,
    is_searchable: input.is_searchable,
    is_highlight: input.is_highlight,
    is_single_shape: input.is_single_shape,
    ...(input.config && { config: JSON.stringify(input.config) }),
  };
  return returnInput;
};

export const convertJsonLayoutToTemplateFields: ConvertJsonLayoutToTemplateFields = ({
  layout,
  fields,
}) => {
  const structureWithContent = layout?.map((rowItems) =>
    rowItems?.map((rowItem) => ({
      ...rowItem,
      group: {
        ...rowItem.group,
        content: rowItem.group.content.map((groupContent) => ({
          ...groupContent,
          content: fields.find(
            (field) =>
              field.id?.toString() === groupContent.content?.toString(),
          ),
        })),
      },
    })),
  );
  return structureWithContent;
};

export const convertPageIdFields = (fields: any) => {
  const jsonValues =
    !fields && !fields?.length
      ? undefined
      : Object.entries(fields).reduce((acc, [key, content]) => {
          const valueContent = content as any;

          switch (Object.prototype.toString.call(valueContent)) {
            case '[object Array]':
              if (valueContent.length) {
                acc[key] = { values: valueContent };
              }
              break;

            case '[object String]':
              if (valueContent.length) {
                acc[key] = { values: [valueContent] };
              }
              break;

            case '[object Number]':
              if (valueContent.toString().length) {
                acc[key] = { values: [valueContent] };
              }
              break;

            case '[object Object]':
              Object.entries(valueContent)
                .filter(([, { selected }]: any) => !!selected)
                .forEach(([valueKey, { variations }]: any) => {
                  acc[key] = {
                    ...acc[key],
                    values: [...(acc[key]?.values ?? []), valueKey],
                  };
                  Object.entries(variations ?? {})
                    .filter(([, selected]: any) => selected)
                    .forEach(([variationKey]: any) => {
                      acc[key].variations = {
                        ...acc[key].variations,
                      };
                      acc[key].variations[valueKey] = [
                        ...(acc[key].variations[valueKey] ?? []),
                        variationKey,
                      ];
                    });
                });
              break;

            default:
              return acc;
          }

          return acc;
        }, {});

  return jsonValues;
};

export const convertPageIdFieldsToArray = (formValues: any) => {
  const fields = JSON.parse(formValues);
  const fieldsObjectArray = Object.entries(fields);

  return fieldsObjectArray.map(([key, values]: [any, any]) => {
    const typeOfObject = Object.prototype.toString.call(values);
    const value =
      typeOfObject === '[object Object]' || '[object Array]'
        ? values.values.toString()
        : values;
    return { key, value };
  });
};

export const convertPageIdFieldsSearch = (fields: any) => {
  const jsonValues =
    !fields && !fields?.length
      ? undefined
      : Object.entries(fields).reduce((acc, [key, content]) => {
          const valueContent = content as any;

          switch (Object.prototype.toString.call(valueContent)) {
            case '[object Array]':
              if (valueContent.length) {
                acc[key] = { values: valueContent };
              }
              break;

            case '[object String]':
              if (valueContent.length) {
                acc[key] = { values: [valueContent] };
              }
              break;

            case '[object Number]':
              if (valueContent.toString().length) {
                acc[key] = { values: [valueContent] };
              }
              break;

            case '[object Object]':
              if (valueContent.from || valueContent.to) {
                acc[key] = valueContent;
                break;
              }

              Object.entries(valueContent)
                .filter(([, { selected }]: any) => !!selected)
                .forEach(([valueKey, { variations }]: any) => {
                  acc[key] = {
                    ...acc[key],
                    values: [...(acc[key]?.values ?? []), valueKey],
                  };
                  Object.entries(variations ?? {})
                    .filter(([, selected]: any) => selected)
                    .forEach(([variationKey]: any) => {
                      acc[key].variations = {
                        ...acc[key].variations,
                      };
                      acc[key].variations[valueKey] = [
                        ...(acc[key].variations[valueKey] ?? []),
                        variationKey,
                      ];
                    });
                });
              break;

            default:
              return acc;
          }

          return acc;
        }, {});

  return jsonValues;
};

export default function convertValueField(
  value: any,
  fields?: any,
  key?: string,
): string {
  if (!value) {
    if (key && fields[key]) {
      if (fields[key].from || fields[key].to) {
        return concat(fields[key].from, fields[key].to).replace(', ', ' - ');
      }
    }
    return '';
  }

  if (value.variations) {
    return Object.keys(value.variations ?? {})
      .reduce((acc: any, item) => {
        const items = value.variations[item];
        const variations = items.map((variation: any) =>
          variation.substring(variation.indexOf('-') + 1),
        );
        acc.push(variations.join(', '));
        return acc;
      }, [])
      .join(', ');
  }

  return value.values.join(', ');
}
