import { gql } from '@apollo/client';

export const LIST_PAYMENT_TYPE_GROUPS_GQL = gql`
  query paymentTypeGroupsQuery {
    paymentTypeGroups(first: 1000) {
      data {
        id
        name
        fields
        paymentTypes {
          id
          name
        }
      }
    }
  }
`;
