import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import { ProductBase, SearchLayoutResultColumn } from '@wgt/types';
import { useTranslation } from 'react-i18next';
import { CollapseProvider } from '../../services/collapse';
import ProductResultRow from './ProductResultRow';
import './styles.scss';

interface SearchResultsTableProps {
  products: ProductBase[];
  headers: string | SearchLayoutResultColumn[];
}

const SearchResultsTable: React.FC<SearchResultsTableProps> = ({
  products,
  headers,
}) => {
  const { t } = useTranslation('product');
  const headersList = headers as SearchLayoutResultColumn[];
  const productsList = products.map((x) => x.id?.toString() ?? '');
  return (
    <CollapseProvider options={productsList}>
      <Container fluid id="result-search">
        <Row className="header">
          <Col lg="auto" className="text-right">
            {t('Media')}
          </Col>
          {headersList?.map((header, key) => (
            <Col key={key}>{header.title}</Col>
          ))}
        </Row>
        <div className="table-striped">
          {products.map((product) => (
            <ProductResultRow
              key={`product-${product.id}`}
              product={product}
              headers={headersList}
            />
          ))}
        </div>
      </Container>
    </CollapseProvider>
  );
};

export default SearchResultsTable;
