import React, { useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import { FormProvider, useForm } from 'react-hook-form';
import CustomHeader from '../../../../components/_molecules/CustomHeader';
import {
  SimpleInput,
  Title,
  SimpleDropdown,
  Button,
} from '../../../../components';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE, GET_UPDATE_SCREEN, UPDATE } from '../graphql';
import Loader from '../../../../components/_organisms/Loader';
import useToast from '../../../../hooks/useToast';

interface FirmOfficeRouteParams {
  firmId: string;
  officeId?: string;
}
const layoutProps = {
  md: '4',
  sm: '12',
  xs: '4',
};

export default function FirmOfficePut(): JSX.Element {
  const params = useParams<FirmOfficeRouteParams>();
  const history = useHistory();
  const { toast } = useToast();
  const isEdit = !!params.officeId;
  const hookForm = useForm({
    defaultValues: {
      name: '',
      address: '',
      number: '',
      postal_code: '',
      phone: '',
      city: '',
      state: '',
      country_id: '',
      firm_id: params.firmId,
    },
  });

  const { data, loading } = useQuery(GET_UPDATE_SCREEN, {
    variables: {
      isEdit,
      id: params.officeId ?? '',
    },
  });

  const { errors, handleSubmit, register, getValues, reset } = hookForm;
  const [put, { loading: submitting }] = useMutation(isEdit ? UPDATE : CREATE, {
    onCompleted: () => [
      toast(
        'success',
        `Office ${getValues('name')} successfully ${
          isEdit ? 'updated' : 'created'
        }!`,
      ),
      history.goBack(),
    ],
  });

  const onSubmit = handleSubmit((office) => {
    const variables = isEdit ? { office, id: params.officeId } : office;

    put({ variables });
  });

  const countries = useMemo(() => data?.countries?.data ?? [], [
    data?.countries,
  ]);

  useEffect(() => {
    if (!data?.office) {
      return;
    }
    reset({
      ...data.office,
      country_id: data.office.country.id,
      firm_id: data.office.firm.id,
    });
  }, [data?.office]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <CustomHeader
        title={'Create Firm Office'}
        showList
        listLink={`/crm/firms/${params.firmId}/offices`}
      />

      <FormProvider {...hookForm}>
        <form onSubmit={onSubmit}>
          <input type="hidden" name="firm_id" ref={register} />
          <Row>
            <Col>
              <SimpleInput field="name" label="Name" type="text" />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col md={12} xl={12} sm={12} className="mb-4">
              <Title>Address</Title>
            </Col>
            <Col {...layoutProps} className="mb-4">
              <SimpleInput
                required
                type="text"
                field="address"
                label="Address"
                hint={errors.address?.message || ''}
              />
            </Col>

            <Col {...layoutProps} className="mb-4">
              <SimpleInput
                type="text"
                field="phone"
                label="Phone"
                hint={errors.phone?.message || ''}
              />
            </Col>
            <Col {...layoutProps} className="mb-4">
              <SimpleInput
                type="text"
                field="postal_code"
                label="Postal Code"
                hint={errors.postal_code?.message || ''}
              />
            </Col>

            <Col {...layoutProps} className="mb-4">
              <SimpleInput
                type="text"
                required
                field="city"
                label="City"
                hint={errors.city?.message || ''}
              />
            </Col>
            <Col {...layoutProps} className="mb-4">
              <SimpleInput
                type="text"
                required
                field="state"
                label="State"
                hint={errors.state?.message || ''}
              />
            </Col>
            <Col {...layoutProps} className="mb-4">
              <SimpleDropdown
                placeholder="Select"
                controller="id"
                field="country_id"
                options={countries}
                label="Country"
                text="name"
                required
              />
            </Col>
          </Row>
          <Button
            isLoading={submitting}
            disabled={submitting}
            htmlType="submit"
          >
            Save
          </Button>
        </form>
      </FormProvider>
    </div>
  );
}
