/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Select, { OptionProps, components, ControlProps } from 'react-select';
import { IconButton, Pane, Paragraph, Strong } from 'evergreen-ui';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import classnames from 'classnames';
import Button from '../Button';
import Text from '../Text';
import Title from '../Title';
import { useTranslation } from 'react-i18next';

interface OfficeOption {
  id: number;
  name: string;
  address: string;
  state: string;
  phone: string;
  country: {
    name: string;
  };
}

interface SelectCardProps {
  title?: string;
  options: OfficeOption[];
  field: string;
  isMulti?: boolean;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
}

type CustomOptionType = OptionProps<OfficeOption>;

const CustomOption: React.FC<CustomOptionType> = ({
  isDisabled,
  isSelected,
  data,
  selectOption,
  clearValue,
}) => {
  return (
    <div className="d-flex m-1 justify-content-between align-items-center p-1">
      <div className="d-flex flex-column">
        <div>{data.name}</div>
        {!!data.phone && (
          <div>
            <Text type="muted">{data.phone}</Text>
          </div>
        )}
      </div>
      <Button
        small
        type={isSelected ? 'danger' : 'primary'}
        onClick={() => (isSelected ? clearValue() : selectOption(data))}
        disabled={isDisabled}
      >
        {isSelected ? 'remove' : 'add'}
      </Button>
    </div>
  );
};

const SelectContext = React.createContext<
  { placeholder?: string; toggleMenu(): void } | any
>({});

const CustomControl: React.FC<ControlProps<CustomOptionType>> = (props) => {
  const { toggleMenu, placeholder } = React.useContext(SelectContext);
  const { hasValue, menuIsOpen, getValue } = props;

  const value = useMemo(() => {
    if (!hasValue) {
      return undefined;
    }
    const val = getValue();
    return val ? val[0] : undefined;
  }, [hasValue, menuIsOpen]);

  return (
    <Pane>
      <Pane display="flex" justifyContent="space-between">
        <Title heading="h3" className="ml-2 mb-1">
          {!menuIsOpen && hasValue
            ? value?.name
            : placeholder ?? 'Choose an option'}
        </Title>
        <IconButton
          icon={menuIsOpen ? FaChevronUp : FaChevronDown}
          type="button"
          appearance="minimal"
          onClick={toggleMenu}
        />
      </Pane>
      {hasValue && !menuIsOpen && value ? (
        <Pane padding="10px">
          <Paragraph marginY="10px">Email: {value.email ?? 'N/A'}</Paragraph>
          <Paragraph marginY="10px">
            Phone: <Strong>{value.phone ?? 'N/A'}</Strong>
          </Paragraph>
          <Paragraph>{value.address}</Paragraph>
          <Paragraph>
            <Strong>{value.state}</Strong>
          </Paragraph>
          <Paragraph>{value.country?.name}</Paragraph>
        </Pane>
      ) : (
        <components.Control {...props} />
      )}
    </Pane>
  );
};

export default function SelectCard({
  title,
  options,
  field,
  isMulti,
  placeholder,
  disabled,
  required,
}: SelectCardProps): JSX.Element {
  const [isMenuOpen, setMenuOpen] = React.useState(false);
  const toggleMenu = () => setMenuOpen((x) => !x);
  const { t } = useTranslation();

  const method = useFormContext();

  const { getValues, errors } = method;

  return (
    <SelectContext.Provider value={{ toggleMenu, placeholder }}>
      <div className="w-100">
        {!!title && (
          <Title heading="h4" className="ml-2 mb-1">
            {title}
          </Title>
        )}
        <div
          className={classnames('card border rounded', {
            'border border-danger': required && errors?.[field],
          })}
        >
          <div className="card-body p-2">
            <Controller
              defaultValue={getValues(field) ?? ''}
              isClearable
              as={Select}
              isMulti={isMulti}
              closeMenuOnSelect={!isMulti}
              options={options}
              noOptionsMessage={() => t('No options')}
              name={field}
              isDisabled={disabled}
              rules={{ required: true }}
              getOptionLabel={(e: { name: string }) => e.name}
              getOptionValue={(e: { id: string }) => e.id}
              onMenuOpen={() => setMenuOpen(true)}
              onMenuClose={() => setMenuOpen(false)}
              components={{
                Option: CustomOption,
                Control: CustomControl,
                DropdownIndicator: undefined,
              }}
              styles={{
                placeholder: (base: any) => ({
                  ...base,
                  fontSize: '14px',
                  whiteSpace: 'nowrap',
                }),
                input: (base: any) => ({
                  ...base,
                  fontSize: '14px',
                }),
                valueContainer: (base: any) => ({
                  ...base,
                  padding: '2px 4px',
                }),
              }}
              menuIsOpen={isMenuOpen}
              placeholder={placeholder}
            />
          </div>
        </div>
      </div>
    </SelectContext.Provider>
  );
}
