import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Title, Line, Button, Text } from '../../../../../components';
import usePointOfSaleUpdate, { PAYMENT_OPTIONS } from '../usePointOfSaleUpdate';
import Value from './Value';
import Payment from './Payment';
import { FormProvider, useForm } from 'react-hook-form';
import { PointOfSaleAmounts } from '@wgt/types';
import { pointOfSaleTotals } from '@wgt/converters';
import { Row, Col } from 'reactstrap';
import PaymentFormModal from '../../../../../components/PaymentFormModal';

const Resume: React.FC = () => {
  const { t } = useTranslation('pointOfSales');
  const { pointOfSale } = usePointOfSaleUpdate();
  const [isPaymentModalOpen, setPaymentModalOpen] = useState(false);
  const [invoiceId, setInvoiceId] = useState<string | undefined>();
  const toggleModal = () => setPaymentModalOpen((x) => !x);

  const methods = useForm<PointOfSaleAmounts>({
    shouldUnregister: false,
    defaultValues: {
      sub_total: pointOfSale.sub_total,
      discount: pointOfSale.discount,
      shipping: pointOfSale.shipping,
      interest: pointOfSale.interest,
      custom_duty: pointOfSale.custom_duty,
      tax: pointOfSale.tax,
      total: pointOfSale.total,
      paid: pointOfSale.paid,
      total_due: pointOfSale.total_due,
    },
  });

  useEffect(() => {
    methods.reset({
      sub_total: pointOfSale.sub_total,
      discount: pointOfSale.discount,
      shipping: pointOfSale.shipping,
      interest: pointOfSale.interest,
      custom_duty: pointOfSale.custom_duty,
      tax: pointOfSale.tax,
      total: pointOfSale.total,
      paid: pointOfSale.paid,
      total_due: pointOfSale.total_due,
    });
  }, [pointOfSale]);

  const amounts = methods.watch([
    'paid',
    'total',
    'discount',
    'shipping',
    'custom_duty',
    'tax',
  ]);

  useEffect(() => {
    const { total_due } = pointOfSaleTotals(amounts);
    methods.setValue('total_due', (pointOfSale?.total_due ?? 0) + total_due);
  }, [
    amounts.paid,
    amounts.custom_duty,
    amounts.discount,
    amounts.shipping,
    amounts.tax,
    amounts.total,
    pointOfSale?.total_due,
  ]);

  const paymentFields = useMemo(
    () => PAYMENT_OPTIONS.filter((o) => pointOfSale[o.field]),
    [
      pointOfSale.has_custom_duty,
      pointOfSale.has_discount,
      pointOfSale.has_interest,
      pointOfSale.has_shipping,
      pointOfSale.has_tax,
    ],
  );

  return (
    <>
      <FormProvider {...methods}>
        <div className="border shadow p-4" id="resume">
          <Row>
            <Col lg="7" className="d-flex flex-column pr-0">
              <Title heading="h2" className="title">
                {t('New Invoice')}
              </Title>
              <Text type="muted" className="descrition-title">
                {t('Number')}:
              </Text>
            </Col>

            <Col lg="5" className="pl-0 pt-1">
              <Value
                operator="+"
                description={t('Sub total')}
                className="sub-total"
                field={`sub_total`}
              />
              {paymentFields.map(({ value, label, operator }) => (
                <Value
                  isEditable
                  key={value}
                  operator={operator}
                  field={value}
                  description={t(label)}
                  className={value}
                />
              ))}
            </Col>
          </Row>

          <Line />

          <Row>
            <Col lg="12" className="pl-0 pt-1 section-totals">
              <Value
                operator="="
                description={t('Total')}
                className="total"
                field={`total`}
              />
              <Value
                description={t('Paid')}
                className="paid"
                isEditable
                field={`paid`}
              />
              <Value
                description={t('Total due')}
                className="total-due"
                field={`total_due`}
              />
            </Col>
          </Row>

          <Line />

          <div className="d-flex justify-content-between">
            <Button type="secondary">{t('Sample')}</Button>
            <Payment
              setPaymentModalOpen={setPaymentModalOpen}
              setInvoiceId={setInvoiceId}
            />
          </div>
        </div>
      </FormProvider>
      {invoiceId && (
        <PaymentFormModal
          isOpen={isPaymentModalOpen}
          toggle={toggleModal}
          invoiceNumber={invoiceId}
        />
      )}
    </>
  );
};

export default Resume;
