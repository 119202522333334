import React, { useMemo } from 'react';
import { FiInfo } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { Row, Col, Container } from 'reactstrap';
import { POS_PRODUCT } from '../../../graphql';
import { SearchLayoutResultColumn } from '@wgt/types';
import { Button, Text } from '../../../../../../components';
import Item from './Item';
import usePointOfSaleUpdate from '../../usePointOfSaleUpdate';
import Loader from '../../../../../../components/_organisms/Loader';
import Header from './Header';
import useToast from '../../../../../../hooks/useToast';

const Results: React.FC = () => {
  const {
    products,
    template,
    pointOfSale,
    selectedProducts,
    setSelectedProducts,
    refetchProducts,
    fetchingProducts,
    filter,
  } = usePointOfSaleUpdate();
  const headersList = template.result_header as SearchLayoutResultColumn[];
  const { t } = useTranslation(['pointOfSales', 'common']);
  const { toast } = useToast();
  const [updatePosProduct, { loading }] = useMutation(POS_PRODUCT);

  const productsToUpdate = useMemo(
    () =>
      selectedProducts.filter((selectedProduct) => !selectedProduct.confirmed),
    [selectedProducts],
  );

  const onConfirm = async () => {
    if (productsToUpdate.length) {
      await Promise.allSettled(
        productsToUpdate.map((selectedProduct) =>
          updatePosProduct({
            variables: {
              id: pointOfSale.id,
              product_id: selectedProduct.id,
              confirmed: true,
            },
          }),
        ),
      );
      refetchProducts();
      toast('success', t('Products confirmed successfully'));
      setSelectedProducts([]);
    }
  };

  return (
    <>
      <Header />

      <Container fluid id="result">
        <div className="d-flex justify-content-between mb-3">
          <div>
            <Button
              isLoading={loading}
              disabled={
                loading ||
                !selectedProducts.length ||
                products.some((x) => x.highlight)
              }
              small
              onClick={onConfirm}
            >
              {t('common:Confirm')}
            </Button>
            <FiInfo className="mx-2 text-muted" />
            <Text className="text-muted d-inline-block">
              {t(
                'Bar scan each sku to confirm all items are in shipping package',
              )}
            </Text>
          </div>
          <Button onClick={() => filter(undefined)}>{t('Unhighlight')}</Button>
        </div>

        <Row className="header">
          <Col lg="auto" className="text-right">
            {t('Media')}
          </Col>
          {headersList?.map((header, key) => (
            <Col key={`header-${key}`}>{header.title}</Col>
          ))}
        </Row>

        {fetchingProducts ? (
          <Loader round />
        ) : (
          products.map((product) => (
            <Item
              key={`product-${product.id}`}
              product={product}
              headers={headersList}
            />
          ))
        )}
      </Container>
    </>
  );
};

export default Results;
