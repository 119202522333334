import { useMutation } from '@apollo/client';
import { WorkOrderDocument } from '@wgt/types';
import { IconButton } from 'evergreen-ui';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiFileText, FiX } from 'react-icons/fi';
import { Col, Row } from 'reactstrap';
import { Button } from '../../';
import PDFViewer from '../../PDFViewer';
import useWorkOrderItemContext from '../useWorkOrderItemContext';
import {
  LIST_WORK_ORDER_DOCUMENTS,
  REMOVE_WORK_ORDER_DOCUMENT,
} from './graphql';

interface DocumentItemProps {
  document: WorkOrderDocument;
}

export default function DocumentItem({
  document,
}: DocumentItemProps): JSX.Element {
  const { t } = useTranslation(['pointOfSales', 'common']);
  const [{ workOrder }] = useWorkOrderItemContext();
  const [isViewerOpened, setViewerOpened] = useState(false);
  const toggle = () => setViewerOpened((x) => !x);

  const [remove, { loading }] = useMutation(REMOVE_WORK_ORDER_DOCUMENT, {
    variables: { id: document.id },
    refetchQueries: [
      {
        query: LIST_WORK_ORDER_DOCUMENTS,
        variables: { workOrderId: workOrder.id },
      },
    ],
  });

  return (
    <Col xs="12" lg="5" className="d-flex align-items-center mt-3 pr-0">
      <Row className="w-100">
        <Col xs="auto">
          <Button
            type="gray"
            iconBefore={FiFileText}
            className="outline"
            onClick={toggle}
          >
            {t('View')}
          </Button>
        </Col>
        <Col xs="5" className="d-flex align-items-center px-0">
          {document.file_name}
        </Col>

        <Col xs="auto" className="d-flex align-items-center px-0 ml-auto">
          <IconButton
            type="button"
            appearance="minimal"
            icon={FiX}
            disabled={loading}
            isLoading={loading}
            onClick={() => remove()}
          />
        </Col>
      </Row>
      {isViewerOpened && (
        <PDFViewer
          fileUrl={document.file_url}
          toggle={toggle}
          title={t('Document')}
        />
      )}
    </Col>
  );
}
