import { Mineral } from '@wgt/types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import { Button } from '../../../../../../../../components';
import MineralsMediaCenterFiles from './Files';
import './styles.scss';

const MediaCenterMinerals: React.FC<{ mineral: Mineral }> = ({ mineral }) => {
  const { t } = useTranslation('mideaCenter');
  const [tab, setTab] = useState<'image' | 'video'>('image');
  return (
    <Container fluid>
      <Row className="media-center-minerals">
        <Col md="2" className="d-flex align-items-center">
          <Button
            onClick={() => setTab('image')}
            htmlType="button"
            type={tab === 'image' ? 'primary' : 'secondary'}
          >
            {t('Images')}
          </Button>
          <Button
            onClick={() => setTab('video')}
            htmlType="button"
            className="ml-2"
            type={tab === 'video' ? 'primary' : 'secondary'}
          >
            {t('Videos')}
          </Button>
        </Col>
        <Col md={10} className="d-flex align-items-center ">
          <div className="d-flex align-items-center ">
            <MineralsMediaCenterFiles type={tab} mineral={mineral} />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default MediaCenterMinerals;
