/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState } from 'react';
import { IconButton } from 'evergreen-ui';
import { FiMoreHorizontal } from 'react-icons/fi';
import { Row, Col, Collapse } from 'reactstrap';
import { Employee } from '@wgt/types';
import { concat } from '@wgt/shared';
import Permissions from './Permissions';
import NoProfilePhoto from '../../../../../assets/no-profile-photo.jpg';

interface Props {
  employee: Employee;
}

export default function Item({ employee }: Props): JSX.Element {
  const [collapse, setCollapse] = useState(false);
  const toggle = () => setCollapse(!collapse);

  return (
    <Row className="border rounded mb-3 mx-0 py-2" key={employee.id}>
      <Col lg="auto" className="d-flex align-items-center">
        <img
          src={NoProfilePhoto}
          alt="Title from Media"
          className="rounded-circle image-media"
        />
      </Col>
      <Col lg="3" className="align-self-center">
        <p>
          <strong>
            {employee.first_name} {employee.last_name}
          </strong>
        </p>
      </Col>
      <Col lg="2" className="align-self-center">
        <p>
          <strong>{employee.phone}</strong>
        </p>
      </Col>
      <Col lg="2" className="align-self-center">
        <p>{employee.email}</p>
      </Col>
      <Col lg="3" className="align-self-center">
        <p>
          {concat(
            employee.addresses?.[0]?.address,
            employee.addresses?.[0]?.address_2,
          )}
        </p>
        <p>
          {concat(
            employee.addresses?.[0]?.city,
            employee.addresses?.[0]?.state,
          )}
        </p>
        <p>
          {concat(
            employee.addresses?.[0]?.country?.name,
            employee.addresses?.[0]?.zip_code,
          )}
        </p>
      </Col>
      <Col lg="auto" className="flex-fill text-right align-self-center">
        <IconButton
          appearance="minimal"
          icon={FiMoreHorizontal}
          iconSize={26}
          onClick={toggle}
          className="d-inline-block icon-toggle"
        />
      </Col>

      <Col lg="12">
        <Collapse isOpen={collapse} className="border rounded mt-2">
          <Permissions employee={employee} />
        </Collapse>
      </Col>
    </Row>
  );
}
