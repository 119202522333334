import React, { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Row, Col, Form } from 'reactstrap';
import { Button, SimpleInput } from '../../../../components';
import BtnLoading from '../../../../components/_molecules/BtnLoading';
import { isObject } from 'util';
import PermissionsComponent from './Permissions';
import { FormProps } from '../types';

const layoutProps = {
  md: '4',
  sm: '12',
  xs: '4',
};
const FormComponent: React.FC<FormProps> = ({
  onSubmit,
  isSubmitting,
  data,
  permissions,
}) => {
  const methods = useForm();
  const { errors, setValue } = methods;

  useEffect(() => {
    if (data?.id) {
      for (const [idx, value] of Object.entries(data)) {
        if (!isObject(value)) {
          setValue(idx, value);
        }
      }
    }
  }, [data]);

  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(onSubmit)}>
        <Row>
          <Col {...layoutProps} className="mb-4">
            <SimpleInput
              required={true}
              field="name"
              label="Name"
              hint={errors?.name?.message || ''}
            />
          </Col>

          <Col>
            <br />
            <Button htmlType="submit">
              {isSubmitting ? <BtnLoading /> : 'Save'}
            </Button>
          </Col>
        </Row>
        {data?.id && (
          <>
            <Row>
              <Col>
                <hr />
              </Col>
            </Row>

            <Row>
              <PermissionsComponent permissions={permissions} role={data} />
            </Row>
          </>
        )}
      </Form>
    </FormProvider>
  );
};

export default FormComponent;
