import { ShapeDescription, ShapeDescriptionGroup } from '@wgt/types';
import { Pane } from 'evergreen-ui';
import React, { useMemo } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import BagProvider, { useBagContext } from '../../services/bag';
import { buildShapeGroupsStructure } from '../../utils/methods';
import Text from '../Text';
import CustomHeader from '../_molecules/CustomHeader';
import ShapeDescriptionCheckbox from './ShapeDescriptionCheckbox';
import classnames from 'classnames';
import { Button } from '..';
import { FaTimes } from 'react-icons/fa';

interface ShapeDescriptionSelectorProps {
  name: string;
  shapeDescriptionGroups?: ShapeDescriptionGroup[];
  editable?: boolean;
  isVisible?: boolean;
  isMultiCabochon?: boolean;
}

function ShapeDescriptionSelectorContent({
  shapeDescriptionGroups,
  isVisible = true,
  editable = true,
  isMultiCabochon = false,
}: ShapeDescriptionSelectorProps): JSX.Element {
  const { fields, append, remove } = useBagContext<{
    fields: { id: string | number }[];
    append({ id }: { id: string | number }): void;
    remove(index: number): void;
  }>();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      append({ id: e.target.value });
    } else {
      const indexOf = fields.findIndex((x) => x.id === e.target.value);
      if (indexOf >= 0) {
        remove(indexOf);
      }
    }
  };
  const onChangeImageType = (descriptionId: string) => {
    if (isMultiCabochon) {
      const indexOf = fields.findIndex((x) => x.id === descriptionId);
      if (indexOf >= 0) {
        remove(indexOf);
      } else {
        append({ id: descriptionId });
      }
    } else {
      fields.forEach((_, key) => remove(key));
      append({ id: descriptionId });
    }
  };

  if (!isVisible) {
    return <React.Fragment />;
  }
  if (
    shapeDescriptionGroups?.every(
      (everyGroup) => everyGroup.display_type === 'label',
    )
  ) {
    return (
      <Pane border="default" borderRadius={5} display="flex" flexWrap="wrap">
        {shapeDescriptionGroups.map((shapeDescriptionGroupMapped, key) => (
          <Pane
            key={`mapped-${key}`}
            flexBasis="40%"
            flexGrow={1}
            border="default"
            margin={8}
            flexWrap="wrap"
            padding={5}
            borderRadius={5}
          >
            <Pane flexBasis="30%" flexGrow={1} display="flex">
              <Pane
                flexDirection="row"
                display="flex"
                flexWrap="wrap"
                justifyContent="space-between"
                flex={1}
              >
                {shapeDescriptionGroupMapped.shapeDescriptions?.map(
                  (description, checkBoxKey) => (
                    <ShapeDescriptionCheckbox
                      key={`shape-desc-checkbox-${checkBoxKey}`}
                      description={description}
                      onChange={onChange}
                      editable={editable}
                      fields={fields}
                    />
                  ),
                )}
              </Pane>
              <p>{shapeDescriptionGroupMapped.name}</p>
            </Pane>
          </Pane>
        ))}
      </Pane>
    );
  }

  return (
    <Pane
      border="default"
      padding={3}
      borderRadius={5}
      display="flex"
      flexDirection="column"
    >
      {shapeDescriptionGroups?.map((group) => (
        <Pane
          border="default"
          margin={3}
          padding={3}
          borderRadius={5}
          key={`shape-description-group-${group.name}`}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          {group.shapeDescriptions?.map((description) => (
            <Pane
              key={description.id}
              display="flex"
              flexDirection="column"
              alignItems="center"
              cursor="pointer"
              onClick={() => onChangeImageType(description.id)}
            >
              <Pane
                backgroundImage={`url('${description.icon_url}')`}
                height={100}
                width="100%"
                backgroundSize="contain"
                backgroundRepeat="no-repeat"
                backgroundPosition="center"
              />
              <Text bold={fields.some((found) => found.id === description.id)}>
                {description.name}
              </Text>
            </Pane>
          ))}
          <p>{group.name}</p>
        </Pane>
      ))}
    </Pane>
  );
}

export default function ShapeDescriptionSelector({
  selectorProps,
  shapeDescriptions,
  isAddStock,
  onClose,
}: {
  selectorProps: ShapeDescriptionSelectorProps;
  shapeDescriptions: ShapeDescription[];
  isAddStock?: boolean;
  onClose?: () => void;
}): JSX.Element {
  const { t } = useTranslation(['shapes', 'common']);
  const { fields, append, remove } = useFieldArray({
    name: selectorProps.name,
    keyName: '__id',
  });

  const shapeDescriptionGroups: ShapeDescriptionGroup[] = useMemo(
    () => buildShapeGroupsStructure(shapeDescriptions),
    [shapeDescriptions],
  );
  const cabochons: ShapeDescriptionGroup[] = useMemo(() => {
    const findCabochon = shapeDescriptionGroups.find(
      (group) => group.name.toLowerCase() === 'cabochon',
    );

    return (
      findCabochon?.shapeDescriptions?.reduce(
        (accDesc: ShapeDescriptionGroup[], desc) => {
          const groupName = desc?.name?.split(' ')[0] ?? '';
          const searchIndex = accDesc?.findIndex(
            (findIndexContent) => findIndexContent.name === groupName,
          );
          const groupIndex = searchIndex === -1 ? accDesc.length : searchIndex;

          accDesc[groupIndex] = {
            ...findCabochon,
            name: groupName,
            shapeDescriptions: accDesc[groupIndex]?.shapeDescriptions ?? [],
          };
          accDesc[groupIndex].shapeDescriptions?.push(desc);
          return accDesc;
        },
        [],
      ) ?? []
    );
  }, [shapeDescriptionGroups]);

  if (!selectorProps.isVisible) {
    return <React.Fragment />;
  }
  return (
    <BagProvider value={{ fields, append, remove }}>
      <div className={classnames('bg-white', { isAddStock })}>
        {isAddStock ? (
          <Button
            htmlType="button"
            onClick={onClose}
            type="blue"
            className="mb-3"
            small
          >
            <FaTimes className="mr-1" /> {t('common:close')}
          </Button>
        ) : (
          <CustomHeader title={t('Shape Description')} />
        )}
        <Row>
          {!!shapeDescriptionGroups?.length && (
            <Col>
              <ShapeDescriptionSelectorContent
                {...selectorProps}
                shapeDescriptionGroups={shapeDescriptionGroups?.filter(
                  (x) => x.display_type === 'label',
                )}
              />
            </Col>
          )}
          {!!cabochons?.length && (
            <Col>
              <ShapeDescriptionSelectorContent
                {...selectorProps}
                shapeDescriptionGroups={cabochons}
                isMultiCabochon
              />
            </Col>
          )}
        </Row>
      </div>
    </BagProvider>
  );
}
