import React, { useContext, useMemo } from 'react';
import { Title } from '../../../../../../../../../components';
import { TemplateFieldsContext } from '../../../../../../../../../hooks/useTemplateFieldsContext';
import { Button, Collapse } from 'reactstrap';
import { useCollapseContext } from '../../../../../../../../../services/collapse';

import FieldValueDependencyContent from './FieldValueDependencyContent';
import {
  LayoutComponentDataTypePropertyValue,
  TemplateFieldBase,
} from '@wgt/types';
import { gql, useQuery } from '@apollo/client';
import { dependencyFragment } from './fragment';
import Loader from '../../../../../../../../../components/_organisms/Loader';

const getNode = (values: LayoutComponentDataTypePropertyValue[]) =>
  !values.length
    ? `hello`
    : values.map(
        (value) =>
          `
            field_${value.id}: templateFieldValueDependencies(template_field_id: $template_field_id, property_value_id: ${value.id}) {
              ...dependency_Part
            }
          `,
      );

const FieldValueDependency: React.FC = () => {
  const { fields, active: fieldActive } = useContext(TemplateFieldsContext);
  const { active, set } = useCollapseContext();
  const { data } = useQuery(
    gql`
      query templateField($templateId: ID!) {
        templateField(id: $templateId) {
          values {
            id
            name
          }
        }
      }
    `,
    {
      skip: !fieldActive?.id,
      variables: {
        templateId: fieldActive?.id,
      },
    },
  );

  const activeField = fieldActive as TemplateFieldBase;
  const filteredFields = useMemo(
    () =>
      fields?.filter(
        (x) => x.formValues?.label !== activeField?.formValues?.label,
      ) ?? [],
    [fields, activeField],
  );

  const hasValues = !!data?.templateField.values?.length;

  const { loading } = useQuery(
    gql`
      query templateFieldValueDependencies ($template_field_id: ID!) {
        ${getNode(data?.templateField.values ?? [])}
      }    
      ${hasValues ? dependencyFragment : ''}
    `,
    {
      skip:
        active !== 'field_value_dependency' || !fieldActive?.id || !hasValues,
      variables: {
        template_field_id: Number(fieldActive?.id),
      },
    },
  );

  if (!filteredFields.length) {
    return <></>;
  }

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <Button
        color="clear"
        type="button"
        onClick={() => set('field_value_dependency')}
        className="dependency-title-holder"
      >
        <Title size={200}>Field Value Dependencies</Title>
      </Button>
      <div>
        <Collapse isOpen={active === 'field_value_dependency'}>
          {data?.templateField.values.map(
            (value: LayoutComponentDataTypePropertyValue) => (
              <FieldValueDependencyContent
                key={value.id}
                filteredFields={filteredFields}
                field={value}
              />
            ),
          )}
        </Collapse>
      </div>
    </>
  );
};

export default FieldValueDependency;
