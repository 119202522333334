import React from 'react';
import { TabContent, TabPane } from 'reactstrap';
import Policy from './Policy';
import Results from './Results';
import Shipping from './Shipping';
import usePointOfSaleUpdate from '../usePointOfSaleUpdate';

export default function Content(): JSX.Element {
  const { contentTab } = usePointOfSaleUpdate();
  return (
    <TabContent activeTab={contentTab}>
      <TabPane tabId="results">
        <Results />
      </TabPane>
      <TabPane tabId="policy">
        <Policy />
      </TabPane>
      <TabPane tabId="shipping">
        <Shipping />
      </TabPane>
    </TabContent>
  );
}
