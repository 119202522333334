import { SectionStringType, TemplateSearchLayoutRow } from '@wgt/types';
import React, { useContext, useMemo } from 'react';
import { Button, Title } from '../../../../../../../components';
import { TemplateLayoutContext } from '../../../../../../../hooks/useTemplateLayout';
import AddNewLayoutStructure from '../AddNewLayoutStructure';
import CommonAttributesSectionLayout from './CommonAttributesSectionLayout';
import TemplateLayoutStructureItem from './TemplateLayoutStructureItem';
import classnames from 'classnames';

interface LayoutSectionProps {
  loading: boolean;
  updateTemplate: () => void;
  section: SectionStringType;
}

const LayoutSection: React.FC<LayoutSectionProps> = ({
  updateTemplate,
  loading,
  section,
}) => {
  const { structure, layoutField } = useContext(TemplateLayoutContext);

  const sectionTitle = section?.replace(/^\w/, (c: string) => c.toUpperCase());

  const isInvalid = useMemo(() => {
    if (!section || !structure) {
      return true;
    }

    const rows = structure[section] ?? [];

    return rows.some(
      (cols) => cols.reduce((acc, col) => Number(col.size) + acc, 0) > 12,
    );
  }, [section, structure]);

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-3 mt-5">
        <Title heading="h2">{`${sectionTitle} Section`}</Title>
        <Button
          onClick={updateTemplate}
          isLoading={loading}
          disabled={isInvalid}
        >
          Save template layout structure
        </Button>
      </div>
      <div
        className={classnames('border rounded ', {
          'second-section': section !== 'first',
          'main-section': section === 'first',
        })}
      >
        {section === 'first' && layoutField === 'search_layout' && (
          <CommonAttributesSectionLayout />
        )}

        {section &&
          structure[
            section
          ]?.map((row: TemplateSearchLayoutRow, key: number) => (
            <TemplateLayoutStructureItem
              rowItems={row}
              key={key}
              rowNumber={key}
              section={section}
            />
          ))}
        <AddNewLayoutStructure section={section} />
      </div>
    </>
  );
};

export default LayoutSection;
