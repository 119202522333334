import React from 'react';
import PaymentFormModal from '../../../components/PaymentFormModal';
import useStatementsContext from './useStatements';

export default function InvoicePaymentsDetails(): JSX.Element {
  const {
    activeInvoicePayments,
    setActiveInvoicePayments,
  } = useStatementsContext();
  const toggleModal = () =>
    setActiveInvoicePayments((invoice) => (invoice?.id ? undefined : invoice));

  if (!activeInvoicePayments?.id) {
    return <React.Fragment />;
  }
  return (
    <PaymentFormModal
      isOpen={!!activeInvoicePayments?.id}
      toggle={toggleModal}
      invoiceNumber={activeInvoicePayments?.id}
    />
  );
}
