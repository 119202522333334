import React, { useMemo, useEffect } from 'react';
import CustomHeader from '../../../../components/_molecules/CustomHeader';
import { useParams, useHistory } from 'react-router-dom';
import useCrmRoutes from '../../../../hooks/useCrmRoutes';
import {
  GET_SHAPE_GROUPS_GQL,
  UPDATE_SHAPE_GROUPS_GQL,
  CREATE_SHAPE_GROUPS_GQL,
  LIST_SHAPE_GROUPS_GQL,
} from '../graphql';
import SimpleForm from '../../../../components/_organisms/SimpleForm';
import useToast from '../../../../hooks/useToast';
import ShapeList from '../../Shapes/List';
import ShapePut from '../../Shapes/Put';
const Put: React.FC = () => {
  const history = useHistory();
  const params = useParams<ParamTypes>();
  const isEdit = useMemo<boolean>(() => !!params?.id, [params]);
  const { setCustomTitle, actualRoute } = useCrmRoutes();
  const { toast } = useToast();

  useEffect(() => {
    setCustomTitle(`${isEdit ? 'Edit' : 'Create'} Shape Group`);
  }, []);

  return (
    <div>
      <CustomHeader
        title={`${isEdit ? 'Edit' : 'Create'} Shape Group`}
        showList
      />
      <SimpleForm
        query={GET_SHAPE_GROUPS_GQL}
        queryOptions={{ variables: { isEdit, id: params.id ?? '' } }}
        parseQuery={({ shapeGroup }) => shapeGroup}
        mutation={isEdit ? UPDATE_SHAPE_GROUPS_GQL : CREATE_SHAPE_GROUPS_GQL}
        parseMutationArgs={(shapeGroup) =>
          isEdit ? { id: params.id, shapeGroup } : shapeGroup
        }
        mutationOptions={{
          refetchQueries: [{ query: LIST_SHAPE_GROUPS_GQL }],
          onCompleted: () => [
            toast('success'),
            history.push(`/crm/${actualRoute}`),
          ],
          onError: () => {
            toast('error');
          },
        }}
        fields={[
          {
            label: 'Title',
            name: 'title',
            required: true,
            type: 'text',
          },
          {
            label: 'Value',
            name: 'value',
            required: true,
            type: 'text',
          },
        ]}
      />

      {isEdit && (
        <>
          <ShapePut />
          <ShapeList />
        </>
      )}
    </div>
  );
};

export default Put;
