import React, { useMemo } from 'react';
import Slider from 'react-rangeslider';
import { Controller, useFormContext } from 'react-hook-form';
import classnames from 'classnames';
import { InputProps } from '../../SimpleInput';
import './styles.scss';

const InputRange: React.FC<InputProps> = ({
  field,
  label,
  hint,
  registerProps,
  parent,
  className,
  required,
  templateField,
  disabled,
}) => {
  const { getValues } = useFormContext();
  const fieldRegistered = parent ? `${parent}[${field}]` : field;

  const [min, max] = useMemo(
    () => [
      Number(templateField?.config?.min ?? 0),
      Number(templateField?.config?.max ?? 100),
    ],
    [templateField?.config],
  );

  return (
    <div className={classnames('input-slider', { disabled })}>
      <label>{label}</label>
      <Controller
        className={classnames('input-slider-component', className)}
        name={fieldRegistered}
        defaultValue={getValues(fieldRegistered) ?? min}
        rules={{
          ...registerProps,
          required,
        }}
        render={({ onChange, value }) => (
          <Slider
            min={min}
            max={max}
            value={value}
            {...(!disabled && { onChange })}
            handleLabel={value > min && value < max && value}
            labels={{
              0: min.toString(),
              100: max.toString(),
            }}
          />
        )}
      />
      {hint && <small>{hint}</small>}
    </div>
  );
};

export default InputRange;
