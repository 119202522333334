import { useLayoutEffect, useRef } from 'react';

export default function useInfiniteScroll({
  hasMore,
  distance,
  trigger,
}: {
  hasMore: boolean;
  distance: number;
  trigger: () => void;
}): [React.RefObject<HTMLDivElement>, React.RefObject<HTMLDivElement>] {
  const containerRef = useRef<HTMLDivElement>(null);
  const loaderRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const loaderNode = loaderRef.current;
    const scrollContainerNode = containerRef.current;
    if (!scrollContainerNode || !loaderNode || !hasMore) {
      return;
    }

    const options = {
      root: scrollContainerNode,
      rootMargin: `0px 0px ${distance}px 0px`,
    };

    let previousY: number;
    let previousRatio = 0;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const listener = (entries: any) => {
      entries.forEach(
        ({
          isIntersecting,
          intersectionRatio,
          boundingClientRect = { y: 0 },
        }: {
          isIntersecting: boolean;
          intersectionRatio: number;
          boundingClientRect: { y: number };
        }) => {
          const { y } = boundingClientRect;
          if (
            isIntersecting &&
            intersectionRatio >= previousRatio &&
            (!previousY || y < previousY)
          ) {
            trigger();
          }
          previousY = y;
          previousRatio = intersectionRatio;
        },
      );
    };

    const observer = new IntersectionObserver(listener, options);
    observer.observe(loaderNode);

    return () => observer.disconnect();
  }, [hasMore, distance, trigger]);

  return [containerRef, loaderRef];
}
