import { MineralGroup } from '@wgt/types';
import { Button, Popover } from 'evergreen-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaEdit, FaTrash, FaAngleDown } from 'react-icons/fa';
import { Button as CustomButton } from '..';

export interface EditActionsProps {
  handleDelete?: (mineralGroupId: string | number) => void;
  handleUpdate?: (mineralGroupId: string | number) => void;
  isLoadingDelete?: boolean;
  value?: MineralGroup;
}

export default function EditActions({
  skip,
  value,
  handleDelete,
  handleUpdate,
  isLoadingDelete,
}: EditActionsProps & { skip?: boolean }): JSX.Element {
  const { t } = useTranslation();
  return (
    <div>
      {!skip && value && handleDelete && (
        <Popover
          content={({ close }) => (
            <div
              className={
                'w-100 d-flex p-4 align-items-center justify-content-between'
              }
            >
              <CustomButton
                type="secondary"
                onClick={() => [handleUpdate?.(value.id), close()]}
                isLoading={isLoadingDelete}
                small
              >
                {t('update')} <FaEdit />
              </CustomButton>
              <CustomButton
                type="danger"
                onClick={() => [handleDelete(value.id), close()]}
                isLoading={isLoadingDelete}
                small
              >
                {t('remove')} <FaTrash />
              </CustomButton>
            </div>
          )}
        >
          <Button type="button">
            <FaAngleDown />
          </Button>
        </Popover>
      )}
    </div>
  );
}
