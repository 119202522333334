import { Pane } from 'evergreen-ui';
import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { Row, Col } from 'reactstrap';
import { Button } from '../../../../../components';
import BagProvider from '../../../../../services/bag';

import SearchInputField from '../../CommonForm/SearchInputField';
import BoxIds from './BoxIds';
import CrateIds from './CrateIds';

const PageTab: React.FC = () => {
  const {
    fields: crates,
    append: appendCrate,
    remove: removeCrate,
  } = useFieldArray({
    name: 'crates',
    keyName: '__id',
  });

  const { fields: boxes, append: appendBox, remove: removeBox } = useFieldArray(
    {
      name: 'boxes',
      keyName: '__id',
    },
  );

  return (
    <Pane className="common-form-holder">
      <Row>
        <Col>
          <SearchInputField
            label="Page ID"
            fields={[{ field: 'page.id', placeholder: 'Identification' }]}
          />

          <SearchInputField
            label="Lot ID"
            fields={[{ field: 'lot_id', placeholder: 'Identification' }]}
          />

          <SearchInputField
            label="Tray ID"
            fields={[{ field: 'tray_id', placeholder: 'Identification' }]}
          />
        </Col>
        <BagProvider
          value={{
            crate: { fields: crates, remove: removeCrate },
            box: { fields: boxes, remove: removeBox },
          }}
        >
          <>
            <Col>
              <BoxIds />
              <div className="box-id-btn-wrap d-flex align-items-center justify-content-end mr-4">
                <small className="text-muted">Add Box Id</small>
                <Button
                  small
                  onClick={() => appendBox({ name: '' })}
                  className="ml-2"
                >
                  Add
                </Button>
              </div>
            </Col>
            <Col>
              <CrateIds />
              <div className="box-id-btn-wrap d-flex align-items-center justify-content-end mr-4">
                <small className="text-muted">Add Crate Id</small>
                <Button
                  small
                  onClick={() => appendCrate({ name: '' })}
                  className="ml-2"
                >
                  Add
                </Button>
              </div>
            </Col>
          </>
        </BagProvider>
      </Row>
    </Pane>
  );
};

export default PageTab;
