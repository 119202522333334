import React from 'react';
import { gql } from '@apollo/client';
import TraceDocumentsButton from './TraceDocumentsButton';
import TraceDocumentsSideSheet from './TraceDocumentsSideSheet';
import { Context } from './useTraceDocuments';
import useFakeQuery from '../../hooks/useFakeQuery';
import './styles.scss';

interface TraceDocumentsProps {
  productId: number | string;
}

export default function TraceDocuments({
  productId,
}: TraceDocumentsProps): JSX.Element {
  const [isSideSheetShown, setSideSheetShown] = React.useState(false);
  const { data: product } = useFakeQuery(
    gql`
      query product {
        product {
          id
          name
          pages {
            fields
          }
        }
      }
    `,
    {
      defaultValue: {
        id: 1,
        pages: [{}, {}, {}],
      },
      variables: {
        productId,
      },
    },
  );
  return (
    <Context.Provider
      value={[{ isSideSheetShown, product }, { setSideSheetShown }]}
    >
      <TraceDocumentsButton />
      <TraceDocumentsSideSheet />
    </Context.Provider>
  );
}
