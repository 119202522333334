import React, { useMemo, useState } from 'react';
import {
  Card,
  CardHeader,
  Row,
  Col,
  Label,
  Input,
  Collapse,
  CardBody,
} from 'reactstrap';
import { FiChevronDown, FiChevronRight } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import { IconButton } from 'evergreen-ui';
import { useLocation, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import useToggler from '@wgt/shared/hooks/useToggler';
import {
  Schedule,
  WorkOrder,
  WorkOrderItem as WorkOrderItemType,
} from '@wgt/types';
import { Button, Text } from '../';
import Documents from './Documents';
import PageIDCommonActions from '../PageIDCommonActions';
import WorkOrderItems from './WorkOrderItems';
import WorkOrderItemContext from './WorkOrderItemContext';
import WorkOrderItemFormData from './Form';
import Loader from '../_organisms/Loader';
import { GET_WORK_ORDER_ITEMS_WITH_SCHEDULE } from './graphql';
import './style.scss';

interface WorkOrderItemFormProps {
  workOrderId?: string;
  pageId?: string;
  isWorkOrder?: boolean;
}

export default function WorkOrderItemForm({
  workOrderId,
  pageId,
  isWorkOrder = true,
}: WorkOrderItemFormProps): JSX.Element {
  const { t } = useTranslation('workOrder');
  const [collapse, toggle] = useToggler(true);
  const params = useParams<{
    id: string;
    workOrderId: string;
    workOrderItemId: string;
  }>();

  const [activeWorkOrderItemId, setActiveWorkOrderItemId] = useState(
    params.workOrderItemId,
  );

  const querystring = new URLSearchParams(useLocation().search);

  const fetchArgs = useMemo(
    () => ({
      isWorkOrder,
      id: params.workOrderId ?? workOrderId,
      filter: {
        [isWorkOrder ? 'workOrder' : 'schedule']: {
          id: [params?.workOrderId ?? workOrderId],
        },
        ...(querystring.has('pageId') || pageId
          ? { pageid: { id: [pageId ?? querystring.get('pageId')] } }
          : {}),
      },
    }),
    [params, querystring, isWorkOrder],
  );

  const { data, loading } = useQuery<{
    schedule: Schedule;
    workOrder: WorkOrder;
    workOrderItems: { data: WorkOrderItemType[] };
  }>(GET_WORK_ORDER_ITEMS_WITH_SCHEDULE, {
    variables: fetchArgs,
  });

  const { workOrder, workOrderItems } = useMemo(
    () => ({
      workOrder: data?.schedule ?? data?.workOrder,
      workOrderItems: data?.workOrderItems.data ?? [],
    }),
    [data],
  );

  const activeWorkOrderItem = useMemo(
    () => workOrderItems.find((x) => x.id === activeWorkOrderItemId),
    [workOrderItems, activeWorkOrderItemId],
  );

  if (loading) {
    return <Loader />;
  }

  return (
    <WorkOrderItemContext.Provider
      value={[
        {
          fetchArgs,
          activeWorkOrderItem,
          workOrderItems,
          workOrder,
          pageId: pageId ?? querystring.get('pageId'),
        },
        {
          setActiveWorkOrderItemId,
        },
      ]}
    >
      <Card className="border rounded mt-4 open-margins work-order-section shadow-sm">
        <CardHeader className="border-bottom-0">
          <Row>
            <Col
              xs="5"
              md="4"
              className="d-flex align-items-start align-items-sm-center pr-0"
            >
              <div className="d-flex align-items-center">
                <IconButton
                  appearance="minimal"
                  icon={collapse ? FiChevronDown : FiChevronRight}
                  iconSize={26}
                  type="button"
                  onClick={toggle}
                />
                <Text className="card-header-title">{t('Open Margins')}</Text>
              </div>
            </Col>
            <Col
              xs="7"
              md="8"
              className="d-flex align-items-center justify-content-end"
            >
              <Row noGutters>
                <Col
                  xs="12"
                  md="6"
                  className="d-flex align-items-center justify-content-start justify-content-md-end mr-2"
                >
                  <Label for="password" className="m-0 pr-0 text-muted">
                    {t('Re-enter password to open margins')}
                  </Label>
                </Col>
                <Col>
                  <Input
                    type="text"
                    name="password"
                    id="password"
                    className="password-input"
                  />
                </Col>
                <Col
                  xs="auto"
                  className="d-flex align-items-center justify-content-end ml-2"
                >
                  <div>
                    <Button small>{t('common:View')}</Button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </CardHeader>
        <Collapse isOpen={collapse}>
          <CardBody className="p-3">
            <PageIDCommonActions />
            <div className="mt-4 job-order-list pl-2 pr-2 pb-2">
              <WorkOrderItems />
            </div>

            {!!activeWorkOrderItemId && <WorkOrderItemFormData />}
            <Documents />
            <div className="text-right">
              <Button type="secondary" className="mt-3 mb-0">
                {t('common:Save')}
              </Button>
            </div>
          </CardBody>
        </Collapse>
      </Card>
    </WorkOrderItemContext.Provider>
  );
}
