import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Col, Container, Form, Row } from 'reactstrap';

import CustomHeader from '../../../../components/_molecules/CustomHeader';
import useFakeMutation from '../../../../hooks/useFakeMutation';
import useToast from '../../../../hooks/useToast';
import { MAKE_SERVICE_PAYMENT } from '../graphql';
import DomainForm from './DomainForm';
import PaymentForm from './PaymentForm';
import { DomainRegisterVariables } from './types';

export default function PurchaseWebsite(): JSX.Element {
  const { t } = useTranslation('website');
  const { toast } = useToast();
  const methods = useForm<DomainRegisterVariables>();
  const history = useHistory();
  const [makeServicePayment, { loading }] = useFakeMutation(
    MAKE_SERVICE_PAYMENT,
    {
      defaultValue: true,
      onCompleted: () => [
        history.push('/crm/website-template'),
        toast('success'),
      ],
    },
  );
  const onSubmit = (variables: DomainRegisterVariables) => {
    if (!variables.app_service && !variables.website_service) {
      return toast('error', t('Please select a service'));
    }

    makeServicePayment({
      variables: {
        ...variables, // fix temporary typescript validation. it is not accepting short hand variables here
      },
    });
  };

  return (
    <Container>
      <CustomHeader title={t('Purchase')} />
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <Row>
            <Col>
              <DomainForm />
            </Col>
            <Col>
              <PaymentForm isLoading={loading} />
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </Container>
  );
}
