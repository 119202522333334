import React from 'react';
import { Col, Row, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import PointOfSalesButton from '../../../assets/learn-to-use/point-of-sales-button.png';
import calendar from '../../../assets/learn-to-use/calendar.png';
import dateField from '../../../assets/learn-to-use/date-field.png';
import messageCard from '../../../assets/learn-to-use/message-card.png';
import notifyAlert from '../../../assets/learn-to-use/notify-alert.png';
import notifyField from '../../../assets/learn-to-use/notify-field.png';
import notify from '../../../assets/learn-to-use/notify.png';
import rangeDate from '../../../assets/learn-to-use/range-date.png';
import searchSku from '../../../assets/learn-to-use/search-sku.png';
import startEndDate from '../../../assets/learn-to-use/start-end-date.png';
import titleInput from '../../../assets/learn-to-use/title-input.png';
import urgencyMenu from '../../../assets/learn-to-use/urgency-menu.png';
import './style.scss';

export default function ManageAlert(): JSX.Element {
  const { t } = useTranslation('learnToUse');
  return (
    <div className="learn-to-use">
      <div className="button-container">
        <div className="button-section">
          <Button type="button" className="btn-learn-to-use-default">
            {t('ALERT')}
          </Button>
          <Button type="button" className="btn-learn-to-use-default">
            {t('ADD STOCK')}
          </Button>
          <Button type="button" className="btn-learn-to-use-default">
            {t('CALENDAR')}
          </Button>
          <Button type="button" className="btn-learn-to-use-default">
            {t('POINT OF SALES')}
          </Button>
          <Button type="button" className="btn-learn-to-use-default">
            {t('INVOICE')}
          </Button>
          <Button type="button" className="btn-learn-to-use-default">
            {t('WORK ORDER')}
          </Button>
          <Button type="button" className="btn-learn-to-use-default">
            {t('WEBSITE')}
          </Button>
        </div>
      </div>

      <div className="border">
        <div className="button-container mt-3">
          <div className="button-section">
            <Button type="button" className="btn-learn-to-use-secondary">
              {t('CONNECT')}
            </Button>
            <Button type="button" className="btn-learn-to-use-secondary">
              {t('MANAGE')}
            </Button>
          </div>
        </div>

        <div className="d-flex justify-content-center mt-4 mb-5">
          <div>{t('LEARN TO USE | POINT OF SALES')}</div>
          <div className="bullet">&bull;</div>
          <div>{t('LEARN TO USE | URGENCY')}</div>
          <div className="bullet">&bull;</div>
          <div>{t('LEARN TO USE | ADD SKU')}</div>
          <div className="bullet">&bull;</div>
          <div>{t('LEARN TO USE | CALENDAR SCHEDULE')}</div>
        </div>

        <div className="section-title">
          <div>{t('LEARN TO USE | POINT OF SALES')}</div>
        </div>

        <Row className="mt-4" noGutters>
          <Col xs="4" className="text-right">
            <img
              src={PointOfSalesButton}
              alt={`${t('Point of Sales Button')}`}
              height="34px"
            />
          </Col>
          <Col xs={{ size: 6, offset: 1 }}>
            <p>
              <strong>{t('ACTION')}</strong>
            </p>
            <p>{t('SELECT BUTTON| SENDS STOCK TO POINT OF SALES.')}</p>

            <p className="mt-4">
              <strong>{t('REACTION')}</strong>
            </p>
            <p>{t('CONNECT STOCK TO POINT OF SALES')}</p>
          </Col>
        </Row>

        <div className="section-title mt-5">
          <div>{t('LEARN TO USE | URGENCY')}</div>
        </div>

        <Row className="mt-4" noGutters>
          <Col xs="4" className="text-right">
            <img src={urgencyMenu} alt={`${t('Urgency')}`} width="137px" />
          </Col>
          <Col xs={{ size: 6, offset: 1 }}>
            <p>
              <strong>{t('ACTION')}</strong>
            </p>
            <p>{t('SELECT DROPDOWN | URGENCY TYPE BY LEVEL.')}</p>

            <p className="mt-4">
              <strong>{t('REACTION')}</strong>
            </p>
            <p>{t('PROVIDES LEVEL OF URGENCY TO ALERT.')}</p>
            <p>{t('ALERT IS COLOR CODED TO URGENCY SELECT IN CALENDAR')}</p>
          </Col>
        </Row>

        <div className="section-title mt-5">
          <div>{t('LEARN TO USE | ADD SKU')}</div>
        </div>

        <Row className="mt-4" noGutters>
          <Col xs="4" className="text-right">
            <img src={searchSku} alt={`${t('Search SKU')}`} height="36px" />
          </Col>
          <Col xs={{ size: 6, offset: 1 }}>
            <p>
              <strong>{t('ACTION')}</strong>
            </p>
            <p>{t('ENTER SKU | ADD STOCK TO ALERT.')}</p>

            <p className="mt-4">
              <strong>{t('REACTION')}</strong>
            </p>
            <p>
              {t(
                'ADDS UNLIMITED AMOUNT OF STOCK TO ALERT. SUPPORT THE ORGANIZATION OF AN INVOICE.',
              )}
            </p>
          </Col>
        </Row>

        <div className="section-title mt-5">
          <div>{t('LEARN TO USE | CALENDAR SCHEDULE')}</div>
        </div>

        <Row className="mt-4" noGutters>
          <Col xs="4" className="text-right">
            <img
              src={startEndDate}
              alt={`${t('Start End Date')}`}
              height="170px"
            />
          </Col>
          <Col xs={{ size: 6, offset: 1 }}>
            <p>
              <strong>{t('ACTION')}</strong>
            </p>
            <p>{t('CALENDAR SCHEDULE | CONNECT DATE AND TIME')}</p>

            <p className="mt-4">
              <strong>{t('REACTION')}</strong>
            </p>
            <p>{t('START AND END TIME CONNECTS TO DURATION OF ALERT.')}</p>
            <p>{t('DATE TO ORGANIZE TASK IN CALENDAR')}</p>
          </Col>
        </Row>

        <Row className="mt-5" noGutters>
          <Col xs="4" className="text-right">
            <img src={calendar} alt={`${t('Calendar')}`} height="242px" />
          </Col>
          <Col xs={{ size: 6, offset: 1 }}>
            <p>
              <strong>{t('ACTION')}</strong>
            </p>
            <p>{t('SELECT DAY | CONNECT DATE AND TIME')}</p>

            <p className="mt-4">
              <strong>{t('REACTION')}</strong>
            </p>
            <p>{t('ALERT IS CONNECTED IN CALENDAR ON THAT DATE')}</p>
          </Col>
        </Row>

        <Row className="mt-5" noGutters>
          <Col xs="4" className="text-right">
            <img src={dateField} alt={`${t('Date Field')}`} height="36px" />
          </Col>
          <Col xs={{ size: 6, offset: 1 }}>
            <p>
              <strong>{t('ACTION')}</strong>
            </p>
            <p>{t('SELECT TODAY | CONNECT ALERT TODAY')}</p>

            <p className="mt-4">
              <strong>{t('REACTION')}</strong>
            </p>
            <p>{t('ALERT IS CONNECTED IN CALENDAR ON THAT DATE')}</p>
          </Col>
        </Row>

        <Row className="mt-5" noGutters>
          <Col xs="4" className="text-right">
            <img src={rangeDate} alt={`${t('Custom range')}`} width="242px" />
          </Col>
          <Col xs={{ size: 6, offset: 1 }}>
            <p>
              <strong>{t('ACTION')}</strong>
            </p>
            <p>{t('SELECT TODAY | CONNECT ALERT TO CUSTOM RANGE')}</p>

            <p className="mt-4">
              <strong>{t('REACTION')}</strong>
            </p>
            <p>{t('ALERT IS CONNECTED IN CALENDAR')}</p>
          </Col>
        </Row>

        <Row className="mt-5" noGutters>
          <Col xs="4" className="text-right">
            <img src={notifyAlert} alt={`${t('Notify Alert')}`} width="240px" />
          </Col>
          <Col xs={{ size: 6, offset: 1 }}>
            <p>
              <strong>{t('ACTION')}</strong>
            </p>
            <p>{t('ALARM | ADD TO TIMER')}</p>

            <p className="mt-4">
              <strong>{t('REACTION')}</strong>
            </p>
            <p>
              {t(
                'ALERT APPEARS ON SCREAN FROM THE AMOUNT ENTERED INTO TIMER BEFORE ALERT STARTS.',
              )}
            </p>
          </Col>
        </Row>

        <Row className="mt-5" noGutters>
          <Col xs="4" className="text-right">
            <img src={notify} alt={`${t('Notify')}`} width="240px" />
          </Col>
          <Col xs={{ size: 6, offset: 1 }}>
            <p>
              <strong>{t('ACTION')}</strong>
            </p>
            <p>{t('SELECT BUTTON | MANAGE TIMER')}</p>

            <p className="mt-4">
              <strong>{t('REACTION')}</strong>
            </p>
            <p>
              {t(
                'OK BUTTON AND TIMER KEEPS RUNNING. NOTIFICATION APPEARS AGAIN AT END OF TIMER.',
              )}
            </p>
            <p>{t('START BUTTON SELECTED PROMPTS BEGINING OF ALERT')}</p>
            <p>
              {t(
                'PAUSE BUTTON SELECTED TIMER IS PAUSED. RESELECT PAUSE BUTTON TO CONTINUE TIMER.',
              )}
            </p>
          </Col>
        </Row>

        <Row className="mt-5" noGutters>
          <Col xs="4" className="text-right">
            <img src={notifyField} width="240px" alt={`${t('Notify Field')}`} />
          </Col>
          <Col xs={{ size: 6, offset: 1 }}>
            <p>
              <strong>{t('ACTION')}</strong>
            </p>
            <p>{t('ALARM |NO TIME ENTERED')}</p>

            <p className="mt-4">
              <strong>{t('REACTION')}</strong>
            </p>
            <p>{t('ALERT DOES NOT APPEAR ON SCREEN AS A REMINDER')}</p>
          </Col>
        </Row>

        <div className="section-title">
          <div>{t('LEARN TO USE | CONTACT ALERT MESSENGER')}</div>
        </div>

        <Row className="mt-4" noGutters>
          <Col xs="4" className="text-right">
            <img src={titleInput} alt={`${t('Title')}`} width="200px" />
          </Col>
          <Col xs={{ size: 6, offset: 1 }}>
            <p>
              <strong>{t('ACTION')}</strong>
            </p>
            <p>{t('TITLE |NAME OF ALERT')}</p>

            <p className="mt-4">
              <strong>{t('REACTION')}</strong>
            </p>
            <p>{t('DESCRIBES THE ALERT')}</p>
          </Col>
        </Row>

        <Row className="mt-4" noGutters>
          <Col xs="4" className="text-right">
            <img src={messageCard} alt={`${t('Message')}`} width="200px" />
          </Col>
          <Col xs={{ size: 6, offset: 1 }}>
            <p>
              <strong>{t('ACTION')}</strong>
            </p>
            <p>{t('ENTER TEXT |RECORD MESSAGE')}</p>

            <p className="mt-4">
              <strong>{t('REACTION')}</strong>
            </p>
            <p>{t('SHARED MESSAGE BETWEEN CONTACTS OF ALERT')}</p>
          </Col>
        </Row>
      </div>
    </div>
  );
}
