import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Row, Col, Form } from 'reactstrap';
import { Button, SimpleInput } from '../../../../components';
import BtnLoading from '../../../../components/_molecules/BtnLoading';
import { FormProps } from '../types';

const layoutProps = {
  md: '4',
  sm: '12',
  xs: '4',
};
const FormComponent: React.FC<FormProps> = ({
  onSubmit,
  isSubmitting,
  data,
}) => {
  const methods = useForm({
    defaultValues: data,
  });
  const { errors } = methods;

  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(onSubmit)}>
        <Row>
          <Col {...layoutProps} className="mb-4">
            <SimpleInput
              required={true}
              field="name"
              label="Name"
              hint={errors?.name?.message ?? ''}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <Button htmlType="submit">
              {isSubmitting ? <BtnLoading /> : 'Save'}
            </Button>
          </Col>
        </Row>
      </Form>
    </FormProvider>
  );
};

export default FormComponent;
