import React from 'react';
import CustomHeader from '../../../../components/_molecules/CustomHeader';
import { LIST_NEWSLETTER_GQL } from '../graphql';
import Table from '../../../../components/_organisms/Table';

export default function List(): JSX.Element {
  return (
    <div>
      <CustomHeader title="Newsletter" />

      <Table
        hover
        striped
        query={LIST_NEWSLETTER_GQL}
        columns={[
          { label: 'E-mail', field: 'email' },
          { label: 'Name', field: 'name' },
          { label: 'Company', field: 'company_name' },
        ]}
        node="newsletterSubscriptions"
        showEdit={false}
        showDetail={false}
      />
    </div>
  );
}
