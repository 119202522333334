import React from 'react';
import { Text } from '../../../../../../../../components';

interface Props {
  description: string;
  value: number;
}

const BadgeTotal: React.FC<Props> = ({ description, value }) => (
  <div className="d-inline-block mr-3 my-2 badge-total">
    <Text>
      {description}: <strong>{value}</strong>
    </Text>
  </div>
);

export default BadgeTotal;
