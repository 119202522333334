/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Pane } from 'evergreen-ui';
import { Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import SelectActivity from './SelectActivity';
import { Button } from '..';
import SelectWorkOrderTypeTree from '../SelectWorkOrderTypeTree';
import UrgencySelector from '../UrgencySelector';
import LearnToUse from '../../pages/Crm/LearnToUse';

export default function UserCalendarFilters(): JSX.Element {
  const [isFilterActive, setIsFilterActive] = useState(false);
  const { t } = useTranslation(['calendar', 'common']);
  const { getValues } = useFormContext();
  return (
    <div className="d-flex justify-content-end flex-column mb-3">
      <div className="d-flex justify-content-end">
        <LearnToUse />

        <Button
          type={isFilterActive ? 'primary' : 'secondary'}
          onClick={() => setIsFilterActive((x) => !x)}
          className="ml-3"
        >
          {t('Work')}
        </Button>
      </div>
      {isFilterActive && (
        <div>
          <Pane
            display="flex"
            alignItems="flex-end"
            justifyContent="flex-end"
            flexGrow={0.5}
            flex={1}
            zIndex={5}
          >
            <div className="mr-2">
              <Controller
                name="workOrderType"
                defaultValue={getValues('workOrderType') ?? ''}
                render={({ onChange, value }) => (
                  <SelectWorkOrderTypeTree
                    field="workOrderType"
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </div>
            <div>
              <Label>{t('Urgency')}</Label>
              <UrgencySelector field="urgency" />
            </div>
            <Pane marginX={5} flex={0.2}>
              <Controller
                name="activity"
                defaultValue=""
                render={({ onChange, value }) => (
                  <SelectActivity value={value} onChange={onChange} />
                )}
              />
            </Pane>
          </Pane>
        </div>
      )}
    </div>
  );
}
