import useToggler from '@wgt/shared/hooks/useToggler';
import PageIds from '../../Products/Update/Header/PageIDs';
import React, { useEffect } from 'react';
import { Col, Card, Row, Container, Form } from 'reactstrap';
import { Button } from '../../../../components';
import { yupResolver } from '@hookform/resolvers/yup';
import Provider from '../../Products/Update/Provider';
import { FormProvider, useForm } from 'react-hook-form';
import TemplateFields from '../../Products/ProductUpdateRow/Content/TemplateFields';
import CommonAttributes from '../../Products/ProductUpdateRow/Content/CommonAttributes';
import useCsvImportContext from '../useCsvImport';
import { useTranslation } from 'react-i18next';
import { Pane } from 'evergreen-ui';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function RowError({ data, schema }: { data: any; schema: any }): JSX.Element {
  const [isOpened, toggle] = useToggler();
  const { t } = useTranslation('csvImport');

  const methods = useForm({
    shouldUnregister: false,
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: data?.value ?? {},
  });

  const { trigger, handleSubmit } = methods;

  useEffect(() => {
    if (isOpened) {
      trigger();
    }
  }, [isOpened]);

  const onSubmit = handleSubmit(console.log);

  return (
    <>
      <Card className="p-2 m-2 border-danger">
        <Row>
          <Col md={3} sm={12}>
            {data.value.name}
          </Col>
          <Col md={2} sm={12}>
            CSV row: {data.row}
          </Col>
          <Col md={1} sm={12}>
            {t('Number of errors')} {data.errors.length}
          </Col>
          <Col md={1} sm={12}>
            {t('Errors are in red text')}
          </Col>
          <Col md={3} sm={12}>
            {t('Fix by selecting from the dropdown beside Red text')}
          </Col>
          <Col md={2} sm={12}>
            <Button className="float-right" small onClick={toggle}>
              {t('Edit Error')}
            </Button>
          </Col>
        </Row>
      </Card>
      {isOpened && (
        <Container fluid>
          <FormProvider {...methods}>
            <Form onSubmit={onSubmit}>
              <Provider>
                <CommonAttributes statuses={[]} />
                <TemplateFields section="first" borderedError />
                <TemplateFields section="second" borderedError />
                <Pane display="flex" marginY={5}>
                  <PageIds />
                </Pane>
                <div className="d-flex justify-content-end">
                  <Button htmlType="submit" className="my-5">
                    {t('Confirm')}
                  </Button>
                </div>
              </Provider>
            </Form>
          </FormProvider>
        </Container>
      )}
    </>
  );
}

export default function ImportProductsTable(): JSX.Element {
  const { products, errors, schema } = useCsvImportContext();
  return (
    <>
      {errors?.map((error, index) => (
        <RowError data={error} key={`error-${index}`} schema={schema} />
      ))}
      {products?.map((product, index) => (
        <Card key={`product-${index}`} className="p-2 m-2">
          <Row>
            <Col>{product.sku}</Col>
            <Col>12.00 ct</Col>
            <Col>Round Brilliant</Col>
            <Col>D</Col>
            <Col>VVS1</Col>
            <Col>VG Ex Ex</Col>
            <Col>AGL</Col>
            <Col>12.00 (l)</Col>
            <Col>123.00</Col>
          </Row>
        </Card>
      ))}
    </>
  );
}
