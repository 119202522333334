import { gql } from '@apollo/client';
import {
  propertyValueVariationFragment,
  propertyValueVariationValueFragment,
} from '../PropertyValueVariation/graphql';
import { paginatorInfoFragment } from '../../../services/graphql/paginator.gql';

export const fieldDependenciesFragment = gql`
  fragment fieldDependencies_Part on FieldDependency {
    label
    property {
      key
    }
  }
`;
export const componentFragment = gql`
  fragment componentSearchLayout_Part on Component {
    id
    name
    key
  }
`;

export const productStatusFragment = gql`
  fragment productStatusSearchIdentifier_Part on ProductStatus {
    id
    name
  }
`;

export const templateFragment = gql`
  fragment templateSearchLayout_Part on Template {
    fields {
      id
      label
      is_required
      is_searchable
      is_highlight
      is_single_shape
      property {
        name
        key
        dataType {
          name
          key
        }
      }
      component {
        ...componentSearchLayout_Part
      }
      searchComponent {
        ...componentSearchLayout_Part
      }
      values {
        id
        name
        key
        options
        image
        variations {
          ...propertyValueVariationInfo_Part
          variationValues {
            ...propertyValueVariationValueInfo_Part
          }
        }
      }
      fieldDependencies {
        ...fieldDependencies_Part
      }
      valueDependencies {
        field {
          ...fieldDependencies_Part
        }
        value {
          key
        }
      }
    }
    search_layout
    name
    id
  }
  ${propertyValueVariationFragment}
  ${propertyValueVariationValueFragment}
  ${propertyValueVariationValueFragment}
  ${fieldDependenciesFragment}
  ${componentFragment}
`;

export const shapeCategoryFragment = gql`
  fragment shapeCategory_Part on Shape {
    id
    title
    image_url
    row_position
    shapeGroup {
      id
      title
    }
  }
`;

export const GET_CATEGORY_GQL = gql`
  query categoryQuery($id: ID!) {
    category(id: $id) {
      id
      name
      productType {
        id
      }
      template {
        id
        search_layout
        result_header
        result_layout
        name
        shapes {
          ...shapeCategory_Part
        }
        fields {
          id
          label
          is_required
          is_searchable
          is_highlight
          is_single_shape
          config
          property {
            name
            key
            dataType {
              name
              key
            }
          }
          component {
            ...componentSearchLayout_Part
          }
          searchComponent {
            ...componentSearchLayout_Part
          }
          values {
            id
            name
            key
            options
            image
            variations {
              ...propertyValueVariationInfo_Part
              variationValues {
                ...propertyValueVariationValueInfo_Part
              }
            }
            dependencies {
              fieldDependencies {
                label
                property {
                  key
                }
              }
              valueDependencies {
                field {
                  label
                  property {
                    key
                  }
                }
                value {
                  key
                }
              }
            }
          }
          fieldDependencies {
            label
            property {
              key
            }
          }
          valueDependencies {
            field {
              label
              property {
                key
              }
            }
            value {
              key
            }
          }
        }
      }
      categoryType {
        id
        name
      }
      description
    }
    productsStatus {
      data {
        ...productStatusSearchIdentifier_Part
      }
    }
  }

  ${componentFragment}
  ${propertyValueVariationFragment}
  ${propertyValueVariationValueFragment}
  ${shapeCategoryFragment}
  ${productStatusFragment}
`;

export const productPageFragment = gql`
  fragment productSearchPage_Part on ProductPageId {
    id
    name
    position
  }
`;

export const SEARCH = gql`
  query products($filter: ProductFilterInput, $page: Int) {
    products(filter: $filter, page: $page) {
      data {
        id
        name
        sku
        description
        total_price
        category {
          id
        }
        pages {
          ...productSearchPage_Part
          fields
        }
      }
      paginatorInfo {
        ...paginatorInfo_Part
      }
    }
  }
  ${productPageFragment}
  ${paginatorInfoFragment}
`;
