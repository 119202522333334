import { gql } from '@apollo/client';
import { paginatorInfoFragment } from '../../../services/graphql/paginator.gql';

export const LIST_ROLES_GQL = gql`
  query rolesQuery($page: Int) {
    roles(page: $page) {
      data {
        id
        name
      }
      paginatorInfo {
        ...paginatorInfo_Part
      }
    }
  }
  ${paginatorInfoFragment}
`;

export const CREATE_ROLE_GQL = gql`
  mutation createRoleMutation($name: String!) {
    createRole(name: $name) {
      id
    }
  }
`;

export const UPDATE_ROLE_GQL = gql`
  mutation updateRoleMutation(
    $id: ID!
    $name: String!
    $description: String!
    $firm_id: ID!
  ) {
    updateRole(
      id: $id
      name: $name
      description: $description
      firm_id: $firm_id
    ) {
      id
    }
  }
`;

export const GET_ROLE_GQL = gql`
  query getRole($id: ID!) {
    role(id: $id) {
      id
      name
      permissions {
        id
        name
      }
      users {
        id
        first_name
      }
    }
  }
`;

export const LIST_PERMISSIONS_GQL = gql`
  query permissionsQuery {
    permissions(first: 1000) {
      data {
        id
        name
      }
    }
  }
`;
export const DELETE_ROLE_GQL = gql`
  mutation deletePermissionMutation($id: ID!) {
    deletePermission(id: $id) {
      message
    }
  }
`;
export const GIVE_PERMISSION_TO_ROLE_GQL = gql`
  mutation givePermissionToRoleMutation($id: ID!, $permission_id: ID!) {
    givePermissionToRole(id: $id, permission_id: $permission_id) {
      message
    }
  }
`;

export const REVOKE_PERMISSION_FROM_ROLE_GQL = gql`
  mutation revokePermissionToRoleMutation($id: ID!, $permission_id: ID!) {
    revokePermissionToRole(id: $id, permission_id: $permission_id) {
      message
    }
  }
`;
