import React from 'react';
import DropArea from './DropArea';
import TemplateTitle from './TemplateTitle';

const ContentArea: React.FC = () => {
  return (
    <div className="template-put-content">
      <TemplateTitle />
      <DropArea />
    </div>
  );
};

export default ContentArea;
