import React from 'react';
import { CrateId } from '@wgt/types';
import { useBagContext } from '../../../../../../services/bag';
import CrateItem from './CrateItem';

const CrateIds: React.FC = () => {
  const {
    crate: { fields },
  } = useBagContext<{
    crate: { fields: CrateId[] };
  }>();

  return (
    <div className="simple-input">
      {fields.map((crateId, index) => (
        <CrateItem
          crate={crateId as CrateId}
          index={index}
          key={`crate-${index}`}
        />
      ))}
    </div>
  );
};

export default CrateIds;
