import { useQuery } from '@apollo/client';
import { InvoiceFull } from '@wgt/types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { INVOICE_DETAILS } from '../../pages/Crm/Statements/graphql';
import InvoiceDetailsContent from '../../pages/Crm/Statements/InvoiceDetailsContent';
import SidebarPane from '../SidebarPane';
import Loader from '../_organisms/Loader';

export default function InvoiceSideSheet({
  invoiceId,
  setShown,
  invoiceType,
}: {
  invoiceId: number | string;
  setShown(isOpen?: boolean): void;
  invoiceType?: 'invoice' | 'aml' | null;
}): JSX.Element {
  const { t } = useTranslation('invoiceReports');

  const { data, loading } = useQuery<{ invoice: InvoiceFull }>(
    INVOICE_DETAILS,
    {
      variables: {
        id: invoiceId,
      },
      skip: !invoiceId,
    },
  );
  return (
    <SidebarPane
      isShown={!!invoiceId && !!invoiceType}
      setShown={setShown}
      size={800}
      header={t('Crime Prevention Network')}
    >
      <>
        {loading && <Loader round />}
        {!loading && data?.invoice.id && (
          <InvoiceDetailsContent
            invoice={data.invoice}
            type={invoiceType ?? 'invoice'}
          />
        )}
      </>
    </SidebarPane>
  );
}
