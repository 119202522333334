import React from 'react';
import { ExpandedDropdownOptions } from '@wgt/types';
import ButtonCheckbox from '../../../ButtonCheckbox';

interface Props {
  label?: string;
  field: string;
  values: ExpandedDropdownOptions[];
  required?: boolean;
  isMulti?: boolean;
  disabled?: boolean;
}

const ExpandedDropdownImage: React.FC<Props> = ({
  label,
  values,
  field,
  required,
  isMulti,
  disabled,
}) => {
  return (
    <ButtonCheckbox
      imageOption
      field={field}
      options={values}
      required={required}
      isMulti={isMulti}
      disabled={disabled}
      label={label}
      controller={{ identifier: 'key', label: 'name', image: 'image' }}
    />
  );
};

export default ExpandedDropdownImage;
