import { InMemoryCacheConfig, Reference } from '@apollo/client';
import { LOCAL_STORAGE } from '../../../utils/constants';
import runtime from '../../../utils/runtime';
import moment from 'moment';
import { URGENCIES } from '@wgt/shared/constants';

export default {
  typePolicies: {
    Query: {
      fields: {
        activeContext: {
          read: (existing, { readField }) => {
            if (existing) {
              return existing;
            }
            if (typeof localStorage === 'undefined') {
              return null;
            }
            const employments = readField<Reference[]>(
              'employments',
              readField('me'),
            );
            const storedContext = localStorage.getItem(
              LOCAL_STORAGE.activeContext,
            );

            if (storedContext === null) {
              if (!!employments?.length) {
                return employments[0];
              }
              return null;
            }
            const activeContext = JSON.parse(storedContext);
            return employments?.find(
              (employment) => readField('id', employment) === activeContext.id,
            );
          },
        },
        active: {
          merge: (_, incoming) => incoming,
        },
        fields: {
          merge: (_, incoming) => incoming,
        },
      },
    },
    Product: {
      fields: {
        pages: {
          merge: (_, incoming) => incoming,
        },
      },
    },
    PropertyValue: {
      fields: {
        variations: {
          merge: (_, incoming) => incoming,
        },
        image: {
          /**
           *  @TODO check merge to debug cache when change Template.fields
           */
          // merge(),
          read(image: string | undefined): string {
            if (!image) {
              return '';
            }
            return runtime.RAZZLE_IMAGES_URL + image;
          },
        },
      },
    },
    TemplateField: {
      fields: {
        type: {
          read: () => 'field',
        },
      },
    },
    User: {
      fields: {
        employments: {
          merge: (_, incoming) => incoming,
        },
      },
    },
    Firm: {
      fields: {
        types: {
          merge: (_, incoming) => incoming,
        },
      },
    },
    Category: {
      fields: {
        template: {
          merge: (_, incoming) => incoming,
        },
      },
    },
    WorkOrderItem: {
      fields: {
        urgency: {
          read: (field) => {
            return URGENCIES.find((x) => x.key === field);
          },
        },
      },
    },
    WorkOrder: {
      fields: {
        start: {
          read: (field) => moment(field).toDate(),
        },
        end: {
          read: (field) => moment(field).toDate(),
        },
      },
    },
    Schedule: {
      fields: {
        start: {
          read: (field) => moment(field).toDate(),
        },
        end: {
          read: (field) => moment(field).toDate(),
        },
        urgency: {
          read: (field) => {
            return URGENCIES.find((x) => x.key === field);
          },
        },
      },
    },
  },
} as InMemoryCacheConfig;
