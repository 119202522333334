import React from 'react';
import {
  useForm,
  FormProvider,
  Controller,
  SubmitHandler,
} from 'react-hook-form';
import { Row, Col, Form } from 'reactstrap';
import classnames from 'classnames';
import Select from 'react-select';
import {
  Button,
  SimpleInput,
  SimpleDropdown,
  Title,
  UploadButton,
} from '../../../../components';
import BtnLoading from '../../../../components/_molecules/BtnLoading';
import { InputFirm } from '../types';
import SelectSearch from '../../../../components/SelectSearch';
import { LOCALES, TIMEZONES, CURRENCIES, COUNTRIES } from '../graphql';
import { Locale, Firm, FirmType } from '@wgt/types';
import { useTranslation } from 'react-i18next';

const layoutProps = {
  md: '4',
  sm: '12',
  xs: '4',
};

interface FormProps {
  onSubmit: SubmitHandler<Firm>;
  isSubmitting?: boolean;
  data: Firm | undefined;
  types: FirmType[] | undefined;
}

const FormComponent: React.FC<FormProps> = ({
  onSubmit,
  isSubmitting,
  data,
  types,
}) => {
  const { t } = useTranslation(['firms', 'common']);

  const methods = useForm<InputFirm>({
    defaultValues: {
      ...data,
      address: {
        ...data?.address,
        country_id: data?.address?.country?.id,
      },
      extra: {
        ...data?.extra,
        currency_id: data?.extra?.currency?.id,
        locale_id: data?.extra?.locale?.id,
        timezone_id: data?.extra?.timezone?.id,
      },
    },
  });

  const { errors } = methods;

  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(onSubmit)}>
        <Row>
          <Col {...layoutProps} className="mb-4">
            <SimpleInput
              required={true}
              field="name"
              label={t('Name')}
              hint={errors?.name?.message || ''}
            />
          </Col>
          <Col {...layoutProps} className="mb-4">
            <SimpleInput field="description" label={t('Description')} />
          </Col>
          <Col {...layoutProps} className="mb-4">
            <SimpleInput field="website" label={t('Website')} />
          </Col>
          <Col {...layoutProps} className="mb-4">
            <SimpleInput type="number" field="discount" label={t('Discount')} />
          </Col>

          <Col {...layoutProps} className="mb-4">
            <SimpleInput field="supplier" label={t('Supplier')} />
          </Col>
          <Col {...layoutProps} className="mb-4">
            <SimpleDropdown
              placeholder={t('common:Select')}
              controller="id"
              field="status"
              options={[
                { id: 'active', name: t('common:Active') },
                { id: 'disabled', name: t('common:Disabled') },
              ]}
              label={t('Status')}
              text="name"
              required={true}
              hint={errors?.status?.message || ''}
            />
          </Col>
          <Col {...layoutProps} className="mb-4">
            <div className="simple-dropdown">
              <label htmlFor={'firm_types_id'}>{t('Types')}</label>
              <Controller
                as={Select}
                options={types}
                isMulti
                closeMenuOnSelect={false}
                rules={{
                  required: true,
                  minLength: 1,
                }}
                getOptionLabel={(type: FirmType) => type.name}
                getOptionValue={(type: FirmType) => type.id}
                name="types"
                className={classnames('basic-multi-select', {
                  'is-invalid': errors?.types,
                })}
              />
            </div>
          </Col>
          <Col {...layoutProps} className="mb-4">
            <SimpleInput field="vatcode" label={t('VAT Code')} />
          </Col>
        </Row>
        <Row>
          <Col>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col md={12} xl={12} sm={12} className="mb-4">
            <Title>{t('Address')}</Title>
          </Col>
          <Col {...layoutProps} className="mb-4">
            <SimpleInput
              parent="address"
              required={true}
              type="text"
              field="address"
              label={t('Address')}
              hint={errors.address?.address?.message || ''}
            />
          </Col>
          <Col {...layoutProps} className="mb-4">
            <SimpleInput parent="address" field="number" label={t('Number')} />
          </Col>
          <Col {...layoutProps} className="mb-4">
            <SimpleInput
              parent="address"
              type="text"
              field="fax"
              label={t('Fax')}
            />
          </Col>
          <Col {...layoutProps} className="mb-4">
            <SimpleInput
              parent="address"
              type="text"
              field="phone"
              label={t('Phone')}
            />
          </Col>
          <Col {...layoutProps} className="mb-4">
            <SimpleInput
              parent="address"
              type="text"
              field="postal_code"
              label={t('Post Code')}
            />
          </Col>
          <Col {...layoutProps} className="mb-4">
            <SimpleInput
              parent="address"
              type="text"
              field="alt_phone"
              label={t('Alternate phone')}
            />
          </Col>
          <Col {...layoutProps} className="mb-4">
            <SimpleInput
              parent="address"
              type="text"
              required={true}
              field="city"
              label={t('City')}
              hint={errors.address?.city?.message || ''}
            />
          </Col>
          <Col {...layoutProps} className="mb-4">
            <SimpleInput
              parent="address"
              type="text"
              required={true}
              field="state"
              label={t('State')}
              hint={errors.address?.state?.message || ''}
            />
          </Col>
          <Col {...layoutProps} className="mb-4">
            <label>Country</label>
            <Controller
              name="address.country"
              rules={{
                required: true,
              }}
              defaultValue={methods.getValues('address.country') ?? ''}
              render={({ onChange }) => (
                <SelectSearch
                  gql={COUNTRIES}
                  getOptionLabel={(e: Locale) => `${e.name ?? ''} `}
                  getOptionValue={(e: Locale) => `${e.id}`}
                  name="name"
                  placeholder={t('Country')}
                  onChange={onChange}
                  defaultInputValue={
                    methods.watch('address.country.name') ?? ''
                  }
                  invalid={Boolean(errors?.address?.country)}
                />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12} xl={12} sm={12} className="mb-4">
            <Title>{t('Extras')}</Title>
          </Col>
          <Col {...layoutProps} className="mb-4">
            <label>{t('Locale')}</label>
            <Controller
              name="extra.locale"
              defaultValue={methods.getValues('extra.locale') ?? ''}
              render={({ onChange }) => (
                <SelectSearch
                  gql={LOCALES}
                  getOptionLabel={(e: Locale) => `${e.name ?? ''} `}
                  getOptionValue={(e: Locale) => `${e.id}`}
                  name="name"
                  placeholder={t('Locale')}
                  onChange={onChange}
                  defaultInputValue={methods.watch('extra.locale.name') ?? ''}
                />
              )}
            />
          </Col>
          <Col {...layoutProps} className="mb-4">
            <label>Timezone</label>
            <Controller
              name="extra.timezone"
              defaultValue={methods.getValues('extra.timezone') ?? ''}
              render={({ onChange }) => (
                <SelectSearch
                  gql={TIMEZONES}
                  getOptionLabel={(e: Locale) => `${e.name ?? ''} `}
                  getOptionValue={(e: Locale) => `${e.id}`}
                  name="name"
                  placeholder={t('Timezone')}
                  onChange={onChange}
                  defaultInputValue={methods.watch('extra.timezone.name') ?? ''}
                />
              )}
            />
          </Col>
          <Col {...layoutProps} className="mb-4">
            <label>{t('Currency')}</label>
            <Controller
              name="extra.currency"
              defaultValue={methods.getValues('extra.currency') ?? ''}
              render={({ onChange }) => (
                <SelectSearch
                  name="name"
                  gql={CURRENCIES}
                  getOptionLabel={(e: Locale) => `${e.name ?? ''} `}
                  getOptionValue={(e: Locale) => `${e.id}`}
                  placeholder={t('Currency')}
                  onChange={onChange}
                  defaultInputValue={methods.watch('extra.currency.name') ?? ''}
                />
              )}
            />
          </Col>
          <Col {...layoutProps} className="mb-4">
            <SimpleInput
              parent="extra"
              type="text"
              field="contact_name"
              label={t('Contact Name')}
            />
          </Col>
          <Col {...layoutProps} className="mb-4">
            <SimpleInput
              parent="extra"
              type="email"
              field="email"
              label={t('Email')}
            />
          </Col>
          <Col {...layoutProps} className="mb-4">
            <SimpleInput
              parent="extra"
              type="text"
              field="headline"
              label={t('Headline')}
            />
          </Col>
          <Col {...layoutProps} className="mb-4">
            <SimpleInput
              parent="extra"
              type="number"
              field="discount_fee"
              label={t('Discount Fee')}
            />
          </Col>
          <Col {...layoutProps} className="mb-4">
            <SimpleInput
              parent="extra"
              type="number"
              field="as_discount_fee"
              label={t('As Discount Fee')}
            />
          </Col>
          <Col {...layoutProps} className="mb-4">
            <SimpleInput
              parent="extra"
              type="number"
              field="default_association"
              label={t('Default Association')}
            />
          </Col>
        </Row>
        <Row>
          <Col {...layoutProps} className="mb-4">
            <Controller
              name="extra.logo"
              render={({ onChange }) => (
                <UploadButton
                  setImageToUpload={onChange}
                  label={t('Logo')}
                  accept="image/*"
                  name="extra.logo"
                />
              )}
              defaultValue=""
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col>
            <Button htmlType="submit">
              {isSubmitting ? <BtnLoading /> : t('common:Save')}
            </Button>
          </Col>
        </Row>
      </Form>
    </FormProvider>
  );
};

export default FormComponent;
