import { useApolloClient } from '@apollo/client';
import { useAuthenticationCookies } from '@wgt/authentication';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { asyncLocalStorage } from '../../../services/db';
import { LOCAL_STORAGE } from '../../../utils/constants';

export default function Logout(): JSX.Element {
  const client = useApolloClient();
  const [, setCookie] = useAuthenticationCookies();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      await Promise.all([
        asyncLocalStorage.removeItem(LOCAL_STORAGE.activeContext),
        setCookie(undefined),
        client.clearStore(),
      ]);
      history.push('/auth', { pathname: '/crm/profile' });
    })();
  }, []);
  return <React.Fragment />;
}
