import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { FileItem } from '@wgt/types';
import { useDropzone } from 'react-dropzone';
import { Button } from '../../../../../../components';

import ConfirmDialog from '../../../../../../components/_molecules/ConfirmDialog';
import FileListItem from '../../Reports/UploadReportFiles/FileListItem';
import { MediaImage } from '.';
interface UploadThumbnailProps {
  setNewMedia: Dispatch<SetStateAction<MediaImage | null>>;
  newMedia: MediaImage | null;
}

const UploadThumbnail: React.FC<UploadThumbnailProps> = ({
  setNewMedia,
  newMedia,
}) => {
  const [deleteItemIndex, setDeleteItemIndex] = useState<string | null>();

  const filesList: FileItem[] = useMemo(
    () => (!newMedia?.thumbnails?.length ? [] : newMedia?.thumbnails),
    [newMedia?.thumbnails],
  );

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file: Blob) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        const binaryStr = reader.result;
        const newFile = { image: binaryStr, fileToUpload: acceptedFiles };

        setNewMedia((oldMedia: MediaImage | null) => ({
          ...oldMedia,
          thumbnails: [newFile as FileItem],
        }));
      };
      reader.readAsDataURL(file);
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: 'image/*',
  });

  const handleRemoveFile = () => {
    const fileListCopy = [...filesList];

    fileListCopy.splice(Number(deleteItemIndex), 1);

    setNewMedia((oldMedia: MediaImage | null) => ({
      ...oldMedia,
      thumbnails: fileListCopy,
    }));
    setDeleteItemIndex(null);
  };

  return (
    <>
      <ConfirmDialog
        isShown={!!deleteItemIndex}
        onConfirm={handleRemoveFile}
        title={`Confirm delete`}
        onClose={() => setDeleteItemIndex(null)}
      />
      <div className="ibox float-e-margins">
        <div className="ibox-content">
          <div {...getRootProps()} className="dropzone">
            <input {...getInputProps()} />
            <div className="dropzone-content">
              {isDragActive ? (
                <h2>Drop files here to add a thumbnail</h2>
              ) : (
                <>
                  <h2>Drag file to add a thumbnail</h2>
                  <span>Image files only </span>
                  <Button small>or click to search</Button>
                </>
              )}
            </div>
          </div>
        </div>
        {filesList.map((fileItem, key) => (
          <FileListItem
            file={fileItem}
            key={key}
            handleRemoveFile={() => setDeleteItemIndex(key.toString())}
          />
        ))}
      </div>
    </>
  );
};

export default UploadThumbnail;
