import React, { useEffect } from 'react';
import useCrmRoutes from '../../../../hooks/useCrmRoutes';

const Details: React.FC = () => {
  const { setCustomTitle, routes } = useCrmRoutes();

  useEffect(() => {
    setCustomTitle('Custom Title');
  }, [routes]);

  return <h1>Details</h1>;
};

export default Details;
