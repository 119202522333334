import React, {
  useRef,
  SetStateAction,
  Dispatch,
  useState,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../Button';
import { FaCheck, FaTimes } from 'react-icons/fa';
import './styles.scss';
import { useFormContext } from 'react-hook-form';
import { getProp } from '../../utils/methods';

interface UploadButtonProps {
  label?: string;
  accept?: string;
  buttonText?: string;
  setImageToUpload: Dispatch<SetStateAction<File | null>>;
  name: string;
}

const UploadButton: React.FC<UploadButtonProps> = ({
  label,
  accept,
  setImageToUpload,
  buttonText,
  name,
}) => {
  const { t } = useTranslation('common');
  const { watch, errors } = useFormContext();
  const [hasImage, setImage] = useState<SetStateAction<boolean>>(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let watchValue: any;
  if (name) {
    watchValue = watch(name, false);
  }

  useEffect(() => {
    if (hasImage && !watchValue) {
      setImage(false);
    }
  }, [hasImage, watchValue]);

  const uploadInput = useRef<HTMLInputElement>(null);

  const handleChangeUploadField = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      setImage(true);
      setImageToUpload(e.target.files[0]);
    }
  };

  const handleCancel = () => {
    setImage(false);
    setImageToUpload(null);
  };

  return (
    <div className="simple-upload-button">
      {label && <label className="d-block">{label}</label>}

      <Button
        onClick={() => uploadInput.current?.click()}
        type={getProp(errors, name) ? 'danger' : 'primary'}
      >
        {buttonText ?? t('Choose an Image')}
        {hasImage && <FaCheck className="ml-3" />}
      </Button>
      {hasImage && <FaTimes onClick={handleCancel} />}
      <input
        accept={accept || '*'}
        type="file"
        className="opacity-0"
        ref={uploadInput}
        onChange={handleChangeUploadField}
      />
    </div>
  );
};

export default UploadButton;
