import { useMutation } from '@apollo/client';
import React, { useMemo, useRef } from 'react';
import { FaPlay } from 'react-icons/fa';
import ReactPlayerComponent, { ReactPlayerProps } from 'react-player';
import { MediaCenterController } from '../../../../../../../hooks/useMediaCenterController';
import { useBagContext } from '../../../../../../../services/bag';
import BackgroundSelector from '../../BackgroundSelector';
import { UPDATE_FILE } from '../../graphql';
import { MediaContentProps } from '../Inclusions/Content';

const VideoContent: React.FC<MediaContentProps> = ({ type }) => {
  const {
    media: { active, selectBackground },
  } = useBagContext<MediaCenterController>();
  const [update] = useMutation(UPDATE_FILE);

  const onUpdate = (background: string) => {
    update({
      variables: {
        id: Number(active?.id),
        file: { background, type },
      },
    });
    selectBackground(background);
  };

  const videoRef = useRef<ReactPlayerProps | null>(null);
  const videoUrl = useMemo(() => {
    videoRef?.current?.showPreview();
    return active?.file_url;
  }, [active?.file_url]);

  return (
    <>
      <BackgroundSelector active={active} update={onUpdate} />

      <div className="media-center-content-holder">
        {active && (
          <>
            <div className="content-side" />
            <div className="media-center-content-image">
              {videoUrl && (
                <ReactPlayerComponent
                  ref={(e) => (videoRef.current = e)}
                  controls
                  url={videoUrl}
                  light={active.thumbnail_url}
                  playIcon={<FaPlay size="42" color="#fff" />}
                />
              )}
            </div>
            <div className="content-side" />
          </>
        )}
      </div>
    </>
  );
};

export default VideoContent;
