/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import './styles.scss';
import classnames from 'classnames';
import { getProp } from '../../utils/methods';
interface ButtonController {
  identifier: string;
  label: string;
  image?: string;
}

export interface ButtonCheckboxProps {
  label?: string;
  field: string;
  isMulti?: boolean;
  required?: boolean;
  imageOption?: boolean;
  options: any[];
  controller?: ButtonController;
  className?: string;
  disabled?: boolean;
}

export default function ButtonCheckbox({
  label,
  field,
  isMulti,
  options,
  required,
  imageOption,
  className,
  disabled,

  controller = { identifier: 'id', label: 'name', image: '' },
}: ButtonCheckboxProps): JSX.Element {
  const { getValues, watch, setValue, errors } = useFormContext();

  const w = watch(field, isMulti ? [] : '');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOnChange = (option: any) => {
    const clickedOptionIdentifier = option[controller.identifier];
    if (isMulti && typeof w === 'object') {
      const includedIndex = w.indexOf(clickedOptionIdentifier ?? -1);

      if (includedIndex >= 0) {
        return w.filter((x: number) => x !== clickedOptionIdentifier);
      }
      return [...w, clickedOptionIdentifier];
    }
    return w === clickedOptionIdentifier ? '' : clickedOptionIdentifier;
  };

  useEffect(() => {
    let value = getValues(field);
    if (isMulti) {
      if (Object.prototype.toString.call(value) !== '[object Array]') {
        value = [];
      }
    } else {
      if (Object.prototype.toString.call(value) === '[object Array]') {
        value = '';
      }
    }

    setValue(field, value);
  }, [isMulti, field]);

  return (
    <div
      className={classnames('wgt-check-button', className, {
        invalid: getProp(errors, field) || !!errors?.[field],
      })}
    >
      {label && (
        <div className="field-label">
          <p className="form-check-label form-check-label-info">{label}</p>
        </div>
      )}

      <div className="label-options">
        {options.map((option) => (
          <div
            key={option[controller.identifier]}
            className="form-check-inline"
          >
            <Controller
              name={field}
              rules={{ required }}
              defaultValue={getValues(field) ?? ''}
              render={({ onChange }) => (
                <div className={classnames({ 'image-holder': !!imageOption })}>
                  <label
                    className={classnames('form-check-label', {
                      'form-check-image': imageOption,
                      disabled,
                      selected:
                        isMulti && w && !!w.includes
                          ? w.includes(option[controller.identifier])
                          : w === option[controller.identifier],
                    })}
                    style={{
                      backgroundImage: imageOption
                        ? `url('${option[controller.image ?? '']}')`
                        : 'initial',
                    }}
                    htmlFor={option[controller.identifier]}
                    onClick={() =>
                      !disabled && onChange(handleOnChange(option))
                    }
                  >
                    {!imageOption ? option[controller.label] : ''}
                  </label>
                  {!!imageOption && <span> {option[controller.label]}</span>}
                </div>
              )}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
