import React from 'react';
import routes from './routes';
import InternalRoutes from '../../components/_organisms/InternalRoutes';
import WgtReportLayout from '../../components/_organisms/ReportLayout';

const Reports: React.FC = () => {
  return (
    <WgtReportLayout>
      <InternalRoutes routes={routes} />
    </WgtReportLayout>
  );
};

export default Reports;
