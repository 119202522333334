import React, { useState, useMemo, useRef } from 'react';
import { UncontrolledPopover, PopoverBody, Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { FiChevronDown, FiChevronUp, FiX } from 'react-icons/fi';
import { WorkOrderType, WorkOrderTypeTree } from '@wgt/types';
import { useQuery } from '@apollo/client';
import { uid } from 'uid';
import Button from '../Button';
import Loader from '../_organisms/Loader';
import WorkOrderTypeList from './WorkOrderTypeList';
import { LIST_WORK_ORDER_TYPES_GQL } from './graphql';
import './styles.scss';

const workOrderTypeConvertToTree = (workOrderTypeValue: WorkOrderType[]) => {
  const tree = [];
  const mappedArr = {};

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let arrElem: any;
  let mappedElem;

  for (let i = 0, len = workOrderTypeValue.length; i < len; i++) {
    arrElem = workOrderTypeValue[i];
    mappedArr[arrElem.id] = arrElem;
    mappedArr[arrElem.id].children = [];
  }

  for (const id in mappedArr) {
    if (mappedArr.hasOwnProperty(id)) {
      mappedElem = mappedArr[id];

      if (mappedElem.parent_id) {
        mappedArr[mappedElem.parent_id].children.push(mappedElem);
      } else {
        tree.push(mappedElem);
      }
    }
  }
  return tree;
};

interface Props {
  autoScroll?: boolean;
  field?: string;
  onChange: (workOrderTypeId: string) => void;
  value?: string;
  isDisabled?: boolean;
  workOrderItemId?: string;
}

export default function SelectWorkOrderTypeTree({
  autoScroll = false,
  field = 'workordertype_id',
  onChange,
  value,
  isDisabled = false,
  workOrderItemId,
}: Props): JSX.Element {
  const { t } = useTranslation('workOrder');
  const [popoverOpen, setPopoverOpen] = useState(false);

  const toggle = () => setPopoverOpen(!popoverOpen);

  const collapseEnd = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    setTimeout(() => {
      collapseEnd.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }, 300);
  };

  const { data, loading } = useQuery<{
    workOrderTypes: { data: WorkOrderType[] };
  }>(LIST_WORK_ORDER_TYPES_GQL);

  const workOrderTypeTree = useMemo(() => {
    const workOrderTypeSerialized =
      data?.workOrderTypes?.data?.map((item: WorkOrderType) => {
        return {
          ...item,
          parent_id: item.parent?.id ? Number(item.parent.id) : 0,
          id: Number(item.id),
        };
      }) || [];
    return workOrderTypeConvertToTree(workOrderTypeSerialized);
  }, [data?.workOrderTypes?.data]);

  const idPopover = useMemo(() => uid(), []);

  const clearSelect = () => {
    onChange('');
  };

  return (
    <>
      <div className="d-flex align-items-center">
        <Button
          id={`workOrderTypePopover${idPopover}`}
          type="combobox"
          disabled={isDisabled}
          className="px-2"
        >
          <div className="d-flex justify-content-between w-100">
            <div>
              <div className="text-truncate">
                <p className="mb-0">
                  {value
                    ? data?.workOrderTypes?.data.filter(
                        (workOrderType: WorkOrderType) =>
                          value === workOrderType?.id?.toString(),
                      )[0].name
                    : t('Click to Select Work Order Type')}
                </p>
              </div>
              <div className="text-left">
                <p className="mb-0 font-weight-bold">
                  {!!workOrderItemId && 'WO '}ID {workOrderItemId}
                </p>
              </div>
            </div>

            <div>{popoverOpen ? <FiChevronUp /> : <FiChevronDown />}</div>
          </div>
        </Button>
        <div>
          <button
            onClick={clearSelect}
            className="btn btn-link btn-sm"
            type="button"
          >
            <FiX />
          </button>
        </div>
      </div>
      <UncontrolledPopover
        trigger="legacy"
        target={`workOrderTypePopover${idPopover}`}
        toggle={toggle}
        isOpen={popoverOpen}
        className="popover-workOrderType"
        placement="bottom"
      >
        <PopoverBody className="px-4 pt-3 pb-2">
          <Row>
            <Col xs="12" className="list-checkbox">
              <p className="title">{t('Work Order Type')}</p>
              {loading ? (
                <Loader small />
              ) : (
                <div className="popover-scroll">
                  {workOrderTypeTree.map(
                    (item: WorkOrderTypeTree, index: number) => (
                      <WorkOrderTypeList
                        key={`work-order-type-list-${index}`}
                        workOrderType={item}
                        index={0}
                        first={index}
                        field={field}
                        {...(autoScroll && { scrollToBottom })}
                        onChange={onChange}
                        value={value}
                      />
                    ),
                  )}
                  <div id="collapseEnd" ref={collapseEnd} />
                </div>
              )}
            </Col>
          </Row>
        </PopoverBody>
      </UncontrolledPopover>
    </>
  );
}
