/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo } from 'react';
import { RenderProps } from './types';
import AddStockLegacy from './AddStockComponent';
import AddStockComponent from './AddStock';
import ComponentElement from './ComponentElement';
import classnames from 'classnames';
import { useFormContext } from 'react-hook-form';
import { getProp } from '../../../utils/methods';
import { Text } from 'evergreen-ui';

interface Props extends RenderProps {
  useLegacy?: boolean;
}

const RenderLayoutComponent: React.FC<Props> = ({
  field,
  prefix = '',
  showAllFields,
  component = 'component',
  rangeToSingle,
  borderedError,
  className,
  useLegacy = true,
  ...props
}) => {
  const componentToRender = field?.[component]?.key;

  const fieldToManage = `${field.property?.key}`;
  const isMulti =
    component === 'searchComponent' ||
    field.property?.dataType?.key === 'multi-select';
  const { setValue, errors } = useFormContext();

  const Component = useMemo(() => {
    if (showAllFields) {
      return ComponentElement;
    }

    return useLegacy ? AddStockLegacy : AddStockComponent;
  }, [showAllFields]);

  useEffect(() => {
    if (!!rangeToSingle && componentToRender === 'min-max') {
      setValue(`${prefix}${fieldToManage}`, '');
    }
  }, [componentToRender, rangeToSingle, fieldToManage]);

  return (
    <div
      className={classnames(
        'field-container d-flex align-items-center flex-1',

        {
          'field-container-bordered-error': borderedError,
          'field-container-invalid': getProp(
            errors,
            `${prefix}${fieldToManage}`,
          ),
        },
      )}
    >
      <Component
        label={field.label}
        field={`${prefix}${fieldToManage}`}
        values={field.values}
        required={field.is_required}
        templateField={field}
        {...(componentToRender !== 'simple-text' && { isMulti })}
        component={component}
        className={className}
        {...props}
      />
      {borderedError &&
        getProp(errors, `${prefix}${fieldToManage}.message`) && (
          <Text className="error-message">
            {getProp(errors, `${prefix}${fieldToManage}.message`)}
          </Text>
        )}
    </div>
  );
};

export default RenderLayoutComponent;
