import { gql } from '@apollo/client';

export const GET_FIRM_SERVICES = gql`
  query getFirmServices {
    getFirmServices {
      website
      app
    }
  }
`;

export const GET_WEBSITE_PRICES = gql`
  query getWebsitePrices {
    getWebsitePrices {
      website
      app
    }
  }
`;

export const MAKE_SERVICE_PAYMENT = gql`
  mutation makeServicePayment {
    makeServicePayment {
      message
    }
  }
`;
