import React from 'react';
import { Col, Row, Container } from 'reactstrap';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SimpleInput } from '../../../../../../components';

export default function SieveChart(): JSX.Element {
  const { watch } = useFormContext();
  const { t } = useTranslation(['property', 'common']);
  const {
    plate_size: plateSize,
    stone_number: stoneNumber,
    carat_weight: caratWeight,
    mm_size: mmSize,
  } = watch([
    'is_seive_chart',
    'is_minerals',
    'is_other',
    'plate_size',
    'stone_number',
    'carat_weight',
    'mm_size',
  ]);
  return (
    <div>
      <Container fluid>
        <Row className="resume-values border rounded pt-3 mb-3">
          <Col>
            <p className="highlight">{t('Plate Size')}</p>
            <p>{plateSize?.toString() !== '' ? plateSize : '-'}</p>
          </Col>
          <Col>
            <p className="highlight">{t('Stone Number')}</p>
            <p>{stoneNumber?.toString() !== '' ? stoneNumber : '-'}</p>
          </Col>
          <Col>
            <p className="highlight">{t('Carat Weight')}</p>
            <p>{caratWeight?.toString() !== '' ? caratWeight : '-'}</p>
          </Col>
          <Col>
            <p className="highlight">{t('mm Size')}</p>
            <p>{mmSize?.toString() !== '' ? mmSize : '-'}</p>
          </Col>
        </Row>
      </Container>
      <Row>
        <Col lg="6" className="mb-4">
          <SimpleInput
            field="plate_size"
            label={t('Plate Size')}
            className="mb-3"
          />
          <SimpleInput field="stone_number" label={t('Stone Number')} />
        </Col>
        <Col lg="6" className="mb-4">
          <SimpleInput
            field="carat_weight"
            label={t('Carat Weight')}
            className="mb-3"
          />
          <SimpleInput field="mm_size" label={t('mm Size')} />
        </Col>
      </Row>
    </div>
  );
}
