import { gql } from '@apollo/client';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import useFakeQuery from '../../hooks/useFakeQuery';
import './styles.scss';

const defaultValue = {
  total: 1000,
  used: 700,
};
interface StorageTotals {
  total: number;
  used: number;
}

const StorageUsage: React.FC = () => {
  const { t } = useTranslation('storage');
  const { data } = useFakeQuery<StorageTotals>(
    gql`
      query getStorage {
        storage {
          total
          used
        }
      }
    `,
    {
      defaultValue,
    },
  );

  const percent = useMemo(
    () => (data?.used && data?.total ? (data.used * 100) / data.total : 0),
    [data],
  );

  return (
    <div>
      {t('Storage usage')}
      <Row>
        <Col className="m-5">
          <div className="storage-row">
            <div
              className="storage-row-content"
              style={{ width: `${percent}%` }}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default StorageUsage;
