import { useMutation } from '@apollo/client';
import { Pane } from 'evergreen-ui';
import React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { Button, SimpleInput } from '../../';
import Dropzone from '../../Dropzone';
import useToast from '../../../hooks/useToast';
import useWorkOrderItemContext from '../useWorkOrderItemContext';
import {
  CREATE_WORK_ORDER_DOCUMENT,
  LIST_WORK_ORDER_DOCUMENTS,
} from './graphql';

interface WorkOrderDocumentsFormProps {
  documentId?: string;
  toggle: () => void;
}

export default function WorkOrderDocumentsForm({
  toggle,
  documentId,
}: WorkOrderDocumentsFormProps): JSX.Element {
  const [{ workOrder }] = useWorkOrderItemContext();
  const methods = useForm();
  const { t } = useTranslation('pointOfSales');
  const { handleSubmit } = methods;
  const { toast } = useToast();
  const [mutate, { loading }] = useMutation(CREATE_WORK_ORDER_DOCUMENT, {
    onCompleted: () => [toast('success'), toggle()],
    onError: () => toast('error'),
  });

  const onSubmit = handleSubmit((values) => {
    if (documentId) {
      return;
    }

    mutate({
      variables: { ...values, workOrderId: workOrder.id },
      refetchQueries: [
        {
          query: LIST_WORK_ORDER_DOCUMENTS,
          variables: { workOrderId: workOrder.id },
        },
      ],
    });
  });

  return (
    <FormProvider {...methods}>
      <Modal toggle={toggle} isOpen>
        <form onSubmit={onSubmit} noValidate>
          <ModalBody>
            <Pane marginY={5}>
              <SimpleInput field="file_name" label={t('Filename')} required />
            </Pane>
            <Controller
              name="file"
              rules={{ required: true }}
              defaultValue=""
              render={({ onChange, value }) => (
                <>
                  <Dropzone
                    accept="application/pdf"
                    onDrop={(e) => onChange(e?.[0])}
                  />
                  {value && <p>{t('File selected')}</p>}
                </>
              )}
            />
          </ModalBody>
          <ModalFooter>
            <Button htmlType="submit" disabled={loading} isLoading={loading}>
              {t('Save')}
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </FormProvider>
  );
}
