/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Pane } from 'evergreen-ui';
import { FaCalendar, FaChevronDown } from 'react-icons/fa';
import moment from 'moment';

const PickerCustomInput = ({ value, onClick }: any) => (
  <Pane
    marginLeft="10px"
    marginRight="10px"
    onClick={onClick}
    justifyContent="space-between"
    className="created-at-range-selector"
  >
    <FaCalendar />
    <span>{moment(value, 'MM/DD/YYYY').format('MMM D, YYYY')}</span>
    <FaChevronDown />
  </Pane>
);

export default class PickerInputComponent extends React.Component {
  render(): JSX.Element {
    return <PickerCustomInput {...this.props} />;
  }
}
