/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from '@apollo/client';
import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { FiPlusCircle, FiMenu, FiEdit } from 'react-icons/fi';
import { Row, Col, Badge } from 'reactstrap';
import { GET_PAYMENT } from '../graphql';
import useReceiptForm from '../useReceiptForm';

export default function ReceiptList(): JSX.Element {
  const { create, paymentId } = useReceiptForm();
  const { reset } = useFormContext();

  const { data } = useQuery(GET_PAYMENT, {
    fetchPolicy: 'cache-only',
    variables: { id: paymentId },
  });

  const receipts = useMemo(() => data?.payment?.receipts ?? [], [
    data?.payment,
  ]);
  return (
    <Row>
      <Col lg="12" className="d-flex my-2 pl-4 flex-wrap">
        <Badge className="badge-receipt mr-3" onClick={() => create()}>
          <FiPlusCircle size="20" className="blue" />
          <FiMenu size="20" />
        </Badge>
        {receipts.map((receipt: any) => (
          <Badge
            className="badge-receipt mr-3"
            key={receipt.id}
            onClick={() => reset(receipt)}
          >
            <FiEdit size="20" className="blue" />
            <FiMenu size="20" />
          </Badge>
        ))}
      </Col>
    </Row>
  );
}
