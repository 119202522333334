import { gql } from '@apollo/client';

export const LIST_CALENDAR_ITEMS = gql`
  query workOrderItems($workOrderItemFilter: WorkOrderItemFilterInput) {
    workOrderItems(filter: $workOrderItemFilter) {
      data {
        id
        title
        urgency
        start
        end
        workOrderType {
          id
          value: id
          label: name
          name
        }
        workOrder {
          id
          title
        }
        schedule {
          id
          title
        }
      }
    }
  }
`;
