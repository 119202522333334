import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { Col, Modal, ModalBody, Row } from 'reactstrap';
import useCrmRoutes from '../../hooks/useCrmRoutes';
import Text from '../Text';
import modalContent, { ComingSoonOption } from './modalContent';
import './styles.scss';

export default function ComingSoonModals({
  isOpen,
  setOpen,
}: {
  isOpen?: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}): JSX.Element {
  const { actualRoute, actualSubRoute, isCrmInternalRoute } = useCrmRoutes();

  const content: ComingSoonOption | undefined = useMemo(() => {
    return modalContent.find((contentItem) =>
      isCrmInternalRoute
        ? contentItem.key === actualSubRoute
        : contentItem.key === actualRoute,
    );
  }, [actualRoute]);

  if (!content?.key) {
    return <React.Fragment />;
  }
  return (
    <Modal
      isOpen={isOpen}
      toggle={() => setOpen(false)}
      modalClassName="coming-soon-modal"
    >
      <ModalBody>
        <Row>
          <Col className="d-flex flex-column" md="8">
            <div
              className="menu-icon"
              style={{ backgroundColor: content.color }}
            >
              {content.title}
            </div>
            {content.description.map((text, key) => (
              <Text key={`modal-description-${key}`} className="mb-2" bold>
                {text}
              </Text>
            ))}
          </Col>
          <Col className="d-flex flex-column">
            {content.sideDescriptions?.map((text, key) => (
              <Text key={`modal-side-description-${key}`} className="mb-3" bold>
                {text}
              </Text>
            ))}
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
}
