import React, { useState, useMemo } from 'react';
import { WorkOrderType } from '@wgt/types';
import { useQuery } from '@apollo/client';
import { Container } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import CustomHeader from '../../../../components/_molecules/CustomHeader';
import { Button } from '../../../../components';
import { LIST_WORK_ORDER_TYPE_GQL } from '../graphql';
import Item from './Item';
import Put from '../Put';

export default function List(): JSX.Element {
  const { t } = useTranslation(['workOrder', 'common']);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const { data, refetch } = useQuery<{
    workOrderTypes: { data: WorkOrderType[] };
  }>(LIST_WORK_ORDER_TYPE_GQL, {
    variables: {
      filter: {
        parent_id: null,
      },
    },
  });

  const workOrderTypes = useMemo(() => data?.workOrderTypes?.data ?? [], [
    data?.workOrderTypes?.data,
  ]);

  const toggleModal = () => setOpenModal(!openModal);

  return (
    <div>
      <CustomHeader
        title={t('Work Order Type')}
        customActions={
          <Button onClick={toggleModal}>{t('common:Create')}</Button>
        }
      />

      <Container fluid className="work-order-type-list">
        {workOrderTypes.map((type: WorkOrderType, index: number) => (
          <Item
            key={`item-${index}`}
            idWorkOrderType={Number(type.id)}
            color="bg-white"
          />
        ))}
      </Container>

      {openModal && (
        <Put setOpenModal={toggleModal} isEdit={false} refetch={refetch} />
      )}
    </div>
  );
}
