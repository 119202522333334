import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../';
import SidebarPane from '../../SidebarPane';
import useTraceDocuments from '../useTraceDocuments';
import TraceDocumentSectionHeader from './TraceDocumentsSectionHeader';
import TraceDocumentSectionFooter from './TraceDocumentSectionFooter';
import { Col, Row } from 'reactstrap';
import traceItem from '../../../assets/trace-image-item.png';
import traceIcon from '../../../assets/trace-image-icon.png';
import { Heading, Paragraph, Text } from 'evergreen-ui';

const TraceDocumentSection = ({ children }: { children: JSX.Element }) => (
  <div className="border rounded">
    <TraceDocumentSectionHeader />
    <div className="px-5">{children}</div>
    <TraceDocumentSectionFooter />
  </div>
);

function PageIDsInfo(): JSX.Element {
  const [{ product }] = useTraceDocuments();
  return (
    <div className="px-3 trace-documents-sidesheet-document-page-ids">
      {product.pages?.map((a, index) => (
        <Row className="border rounded pt-5 mb-2" key={index}>
          <Col
            md={2}
            sm={12}
            lg={1}
            style={{ backgroundImage: `url(${traceItem})` }}
            className="img"
          />
          <Col>
            <Heading>Diamond</Heading>
            <div className="d-flex align-items-center">
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <th>Shape</th>
                    <th>Carat Weight</th>
                    <th>Color</th>
                    <th>Clarity</th>
                    <th>Measurements</th>
                    <th>Report Count</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Text>Round Brilliant</Text>
                    </td>
                    <td>
                      <Text>0.32 ct</Text>
                    </td>
                    <td>
                      <Text>D</Text>
                    </td>
                    <td>
                      <Text>VVS2</Text>
                    </td>
                    <td>
                      <div className="d-flex flex-column">
                        <Paragraph>09.01 x 09.01 x 09.01</Paragraph>
                        <Paragraph>(length) (width) (depth)</Paragraph>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                style={{ backgroundImage: `url(${traceIcon})` }}
                className="img"
              />
            </div>
          </Col>
        </Row>
      ))}
    </div>
  );
}

export default function TraceDocumentsSideSheet(): JSX.Element {
  const { t } = useTranslation('traceDocuments');
  const [{ isSideSheetShown }, { setSideSheetShown }] = useTraceDocuments();

  return (
    <SidebarPane
      isShown={isSideSheetShown}
      setShown={setSideSheetShown}
      header={t('Crime Prevention Network')}
      size={window.innerWidth * 0.8}
      className="trace-documents-sidesheet"
    >
      <div className="d-flex w-100 p-2">
        <Button type="primary">{t('Trace Document')}</Button>
        <Button type="secondary" className="ml-3">
          {t('KCS')}
        </Button>
      </div>
      <div className="w-100 mt-5 px-2">
        <TraceDocumentSection>
          <PageIDsInfo />
        </TraceDocumentSection>
      </div>
    </SidebarPane>
  );
}
