import { IconTree, IconType, GenIcon } from 'react-icons';

const officeMenuIconJson: IconTree = {
  tag: 'svg',
  attr: {
    version: '1.0',
    viewBox: '0 0 101.000000 69.000000',
    preserveAspectRatio: 'xMidYMid meet',
  },
  child: [
    { tag: 'metadata', attr: {}, child: [] },
    {
      tag: 'g',
      attr: {
        transform: 'translate(0.000000,69.000000) scale(0.100000,-0.100000)',
        stroke: 'none',
      },
      child: [
        {
          tag: 'path',
          attr: {
            d:
              'M213 344 l-202 -324 161 2 162 3 122 195 c67 107 120 199 116 205\n-35 60 -138 223 -146 232 -8 8 -64 -74 -213 -313z',
          },
          child: null,
        },
        {
          tag: 'path',
          attr: {
            d:
              'M583 233 l-130 -208 134 -3 c73 -1 193 -1 266 0 l134 3 -128 205\nc-71 113 -132 206 -137 208 -5 1 -68 -91 -139 -205z',
          },
          child: null,
        },
      ],
    },
  ],
};

const officeMenuIcon = (props: IconBaseProps): IconType =>
  GenIcon(officeMenuIconJson)(props);

export default officeMenuIcon;
