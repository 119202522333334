/* eslint-disable @typescript-eslint/no-explicit-any */
import { SearchLayoutResultColumn } from '@wgt/types';
import { Pane } from 'evergreen-ui';
import React from 'react';
import { useDrop } from 'react-dnd';
import Field from './Field';

interface FieldsProps {
  fields: SearchLayoutResultColumn[];
  type: 'inserted' | 'available';
}

export default function Fields({ fields, type }: FieldsProps): JSX.Element {
  const [, drop] = useDrop({
    accept: ['available', 'inserted'],
    drop: (item: any) => ({
      to: type,
      dragged: item,
      from: item.field.selected ? 'inserted' : 'available',
    }),
    canDrop: (item, monitor) => monitor.isOver({ shallow: true }),
  });

  return (
    <Pane
      display="flex"
      flexWrap="wrap"
      background="tint1"
      overflowY="scroll"
      ref={drop}
    >
      {fields.map((x) => (
        <Field
          field={x}
          key={x.key}
          type={type}
          accept={['inserted', 'available']}
        />
      ))}
    </Pane>
  );
}
