import React from 'react';
import { Row, Col } from 'reactstrap';
import { SimpleInput, SimpleTextArea } from '../../../../../components';
import RowForm from './RowForm';
import Total from '../Total';
import { useProductUpdate } from '../Provider';

const CommonAttributes: React.FC = () => {
  const { isFormEnabled } = useProductUpdate();
  return (
    <div className="common-attributes mt-4">
      <Row>
        <Col lg="6" className="d-flex flex-column">
          <SimpleInput
            field="name"
            label="Search name"
            disabled={!isFormEnabled}
            required
          />
          <Row className="mt-auto">
            <Col lg="8" md="10" className="d-flex flex-row" xs="12">
              <Total />
            </Col>
          </Row>
        </Col>
        <Col lg="6">
          <SimpleTextArea
            field="description"
            label="Description"
            className="textarea-description"
            disabled={!isFormEnabled}
            required
          />
        </Col>
      </Row>

      <RowForm />
    </div>
  );
};

export default CommonAttributes;
