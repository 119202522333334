import React from 'react';

import TemplateLayout, {
  TemplateLayoutProps,
} from '../../../../../components/TemplateLayout';
import { Title, Line } from '../../../../../components';
import { Row, Col } from 'reactstrap';
import ProductPageIdShapesDescriptions from '../ProductPageIdShapesDescriptions';
import ProductPageIdPrice from '../ProductPageIdPrice';

function TemplateFieldsFirstSection({
  rows,
  prefix,
  rangeToSingle,
  disabled,
}: TemplateLayoutProps): JSX.Element {
  return (
    <div className="mt-4">
      <Title heading="h2">Advanced</Title>
      <Line />
      <Row className="product-advanced-content">
        <Col lg={1} className="pr-0 shape-and-price">
          <ProductPageIdPrice />
        </Col>
        <Col lg={2} className="shape-and-price">
          <ProductPageIdShapesDescriptions />
        </Col>
        <Col lg="9">
          <TemplateLayout
            useLegacy={false}
            rows={rows}
            prefix={prefix}
            component="component"
            rangeToSingle={rangeToSingle}
            disabled={disabled}
            hasDependencies
          />
        </Col>
      </Row>
    </div>
  );
}

export default TemplateFieldsFirstSection;
