/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloError } from '@apollo/client';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useToast from './useToast';

type UseError = (data: ApolloError) => void;
type ErrorValuesGetter = () => any;

export default function useErrorMessage(
  getValues: ErrorValuesGetter,
): UseError {
  const { t } = useTranslation('validations');
  const { toast } = useToast();
  return useCallback(
    (data: ApolloError) => {
      const errors = data.graphQLErrors
        .reduce<string[]>(
          (message, error) => [
            ...message,
            ...Object.keys(error.extensions?.validation ?? {}),
          ],
          [],
        )
        .map((e) => t(e, getValues()));

      if (!errors.length) {
        toast('error');
        return;
      }

      errors.forEach(toast.bind(null, 'error'));
    },
    [getValues],
  );
}
