import React, { useState } from 'react';
import { FormGroup, Label, Collapse } from 'reactstrap';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { IconButton } from 'evergreen-ui';
import { JobTitleTree } from '@wgt/types';
import JobTitleSelect from './JobTitleSelect';

interface Props {
  jobTitle: JobTitleTree;
  index?: number;
  scrollToBottom?: () => void;
  field: string;
}

export default function JobTitleList({
  jobTitle,
  index,
  scrollToBottom,
  field,
}: Props): JSX.Element {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggle = () => [setIsOpen(!isOpen), scrollToBottom?.()];

  const marginLevel = Number(index) + 1;

  return (
    <>
      <FormGroup
        check={!jobTitle?.subordinates?.length}
        className={`mb-2 d-flex ml-${marginLevel} mr-1`}
      >
        <Label check>
          {!jobTitle?.subordinates?.length ? (
            <JobTitleSelect
              jobTitleId={Number(jobTitle.id)}
              jobTitleName={jobTitle.name}
              field={field}
            />
          ) : (
            jobTitle.name
          )}
        </Label>
        {!!jobTitle?.subJobTitles?.length && (
          <IconButton
            appearance="minimal"
            icon={isOpen ? FiChevronUp : FiChevronDown}
            iconSize={20}
            className="ml-3 btn-icon-more-items"
            onClick={toggle}
          />
        )}
      </FormGroup>

      {!!jobTitle?.subJobTitles && (
        <Collapse isOpen={isOpen}>
          {jobTitle.subordinates?.map((title: JobTitleTree) => (
            <FormGroup
              check
              key={title.id}
              className={`mb-2 d-flex ml-${marginLevel} mr-1`}
            >
              <Label check>
                <JobTitleSelect
                  jobTitleId={Number(title.id)}
                  jobTitleName={title.name}
                  field={field}
                />
              </Label>
            </FormGroup>
          ))}
          {jobTitle.subJobTitles?.map((title: JobTitleTree) => (
            <JobTitleList
              jobTitle={title}
              key={`sub-job-title-${title.id}`}
              index={marginLevel}
              scrollToBottom={scrollToBottom}
              field={field}
            />
          ))}
        </Collapse>
      )}
    </>
  );
}
