import React, { useMemo } from 'react';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Title, AlertMessage, Button } from '../../../../../../components';
import usePointOfSaleUpdate from '../../usePointOfSaleUpdate';
import ShippingOptionItem from './ShippingOptionItem';
import { Pane } from 'evergreen-ui';
import { FormProvider, useForm } from 'react-hook-form';
import { useQuery } from '@apollo/client';
import { SHIPPINGS } from '../../../graphql';
import { Shipping } from '@wgt/types';

export default function Shippings(): JSX.Element {
  const { t } = useTranslation(['pointOfSales', 'common']);
  const { setContentTab, pointOfSale } = usePointOfSaleUpdate();

  const { data } = useQuery<{
    shippings: Shipping[];
  }>(SHIPPINGS, {
    skip: !(pointOfSale?.executiveBuyer?.id && pointOfSale?.office?.id),
    variables: {
      executive_buyer_id: pointOfSale?.executiveBuyer?.id,
      office_id: pointOfSale?.office?.id,
      point_of_sale_id: pointOfSale?.id,
    },
  });

  const shippingOptions = useMemo(() => data?.shippings ?? [], [data]);

  const methods = useForm();
  return (
    <FormProvider {...methods}>
      <div id="policy" className="border shadow mt-3">
        <Row noGutters className="p-4 favourite-header">
          <Col xs="12" className="d-flex justify-content-between">
            <Title heading="h2">{t('Shipping & Handling')}</Title>
            <Button type="secondary" onClick={() => setContentTab('results')}>
              {t('common:Cancel')}
            </Button>
          </Col>
          <Col xs="12">
            {!shippingOptions?.length && (
              <AlertMessage className="mt-3">
                {t('common:no options')}
              </AlertMessage>
            )}
            <Pane id="stock-options" display="flex">
              {shippingOptions.map((option, key) => (
                <ShippingOptionItem
                  option={option}
                  key={`shipping-${option.type}-${key}`}
                />
              ))}
            </Pane>
          </Col>
        </Row>
      </div>
    </FormProvider>
  );
}
