import React from 'react';
import { useBagContext } from '../../../../../../../services/bag';
import MiddleNameItem from './MiddleNameItem';

interface MiddleName {
  id?: number;
  name?: string;
}

export default function MiddleNames(): JSX.Element {
  const {
    middleName: { fields },
  } = useBagContext<{
    middleName: { fields: MiddleName[] };
  }>();

  return (
    <div className="simple-input mt-3">
      {fields.map((middleName: MiddleName, index) => (
        <MiddleNameItem
          middleName={middleName}
          index={index}
          key={`middle-name-${index}`}
        />
      ))}
    </div>
  );
}
