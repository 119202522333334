/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef } from 'react';
import { Pane } from 'evergreen-ui';
import DatePicker from 'react-datepicker';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import moment from 'moment';
import { FaCalendar, FaChevronDown, FaSearch, FaTimes } from 'react-icons/fa';
import { Button } from '..';

export interface FormProps {
  onSubmit: SubmitHandler<any>;
  loading?: boolean;
}
const CreatedAtRange: React.FC<FormProps> = ({ onSubmit, loading }) => {
  const { setValue, watch, handleSubmit } = useFormContext();

  const onChange = (dates: any) => {
    const [start, end] = dates;
    setValue('created_at.from', start);
    setValue('created_at.to', end);
  };

  const watchDates = watch(['created_at.from', 'created_at.to']);
  const datePicker = useRef<DatePicker | null>(null);

  const DateRangeComponent: React.FC = () => {
    const [start, end] = [
      watchDates['created_at.from'],
      watchDates['created_at.to'],
    ];

    return (
      <Pane display="flex" alignItems="center">
        <Pane
          marginLeft="10px"
          marginRight="10px"
          onClick={() => datePicker.current?.setOpen(true)}
          justifyContent="space-between"
          className="created-at-range-selector"
        >
          <FaCalendar />
          <span>
            {watchDates['created_at.from']
              ? moment(start).format('MMM D, YYYY')
              : 'Start Date'}
          </span>
          {' - '}
          <span>
            {watchDates['created_at.to']
              ? moment(end).format('MMM D, YYYY')
              : 'End Date'}
          </span>
          <FaChevronDown />
          {(start || end) && (
            <FaTimes
              onClick={(e) => {
                e.stopPropagation();
                setValue('created_at.from', null);
                setValue('created_at.to', null);
              }}
            />
          )}
        </Pane>
        {(start || end) && (
          <Button
            htmlType="submit"
            className="ml-4"
            isLoading={loading}
            disabled={loading}
          >
            <FaSearch onClick={() => handleSubmit(onSubmit)} />
          </Button>
        )}
      </Pane>
    );
  };

  return (
    <DatePicker
      ref={datePicker}
      selected={watchDates['created_at.from']}
      onChange={onChange}
      startDate={watchDates['created_at.from']}
      endDate={watchDates['created_at.to']}
      selectsRange
      customInput={<DateRangeComponent />}
    />
  );
};

export default CreatedAtRange;
