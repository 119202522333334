import { Pane } from 'evergreen-ui';
import React from 'react';
import { Paginator } from '../../../../services/graphql/paginator.gql';
import './styles.scss';

interface PaginatorInfoProps {
  paginator: Paginator;
}

const PaginatorInfo: React.FC<PaginatorInfoProps> = ({ paginator }) => {
  return (
    <Pane className="d-flex mb-3">
      <Pane className="results-badge">{`SKU's: ${paginator.total}`}</Pane>
    </Pane>
  );
};

export default PaginatorInfo;
