import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button } from '../../../../../../../components';
import ConfirmDialog from '../../../../../../../components/_molecules/ConfirmDialog';
import { useProductUpdate } from '../../../Provider';
import { DELETE_PAGE_ID } from '../../../../graphql';
import useToast from '../../../../../../../hooks/useToast';

const RemovePageId: React.FC = () => {
  const [isOpen, setOpen] = useState(false);
  const { pages } = useProductUpdate();
  const { toast } = useToast();
  const [remove, { loading }] = useMutation(DELETE_PAGE_ID, {
    variables: {
      id: pages?.active?.id,
    },
    onCompleted: () => [
      pages.remove(Number(pages.activeIndex)),
      setOpen(false),
      toast('success', 'PageId removed successfully'),
    ],
  });

  const handleRemove = () => {
    return !pages?.active?.id
      ? [
          pages.remove(Number(pages.activeIndex)),
          setOpen(false),
          toast('success', 'PageId removed successfully'),
        ]
      : remove();
  };

  return pages.active ? (
    <>
      <Button
        type="danger"
        htmlType="button"
        className="ml-3"
        onClick={() => setOpen(true)}
      >
        Remove
      </Button>
      <ConfirmDialog
        onClose={() => setOpen(false)}
        onConfirm={handleRemove}
        isLoading={loading}
        isShown={isOpen}
        title={`Confirm delete of active PageId?`}
      />
    </>
  ) : (
    <React.Fragment />
  );
};

export default RemovePageId;
