import React, { useMemo } from 'react';
import { Pane } from 'evergreen-ui';
import { useProductUpdate } from '../../../Provider';
import { useDrop, useDrag, DropTargetMonitor, XYCoord } from 'react-dnd';
import classnames from 'classnames';
import IMAGE from '../../../../../../../assets/media-dummy.png';
import { useTranslation } from 'react-i18next';

interface PageIDProps {
  value: {
    __id: string;
    name: string;
    id: string;
  };
  position: number;
  active: string | null;
  setActive(index: string | null): void;
}
interface Draggable {
  type: 'card';
  page: {
    __id: string;
    name: string;
    id: string;
  };
}

const PageID: React.FC<PageIDProps> = ({
  value,
  position,
  active,
  setActive,
}) => {
  const ref = React.useRef<HTMLButtonElement>(null);
  const { pages } = useProductUpdate();
  const isActive = useMemo(() => active === value.__id ?? '', [active, value]);

  const [, drop] = useDrop({
    accept: 'card',
    hover: (item: Draggable, monitor: DropTargetMonitor) => {
      if (!ref.current) {
        return;
      }
      const fromPosition = pages.fields.findIndex(
        (x) => x.__id === item.page.__id,
      );
      const toPosition = pages.fields.findIndex((x) => x.__id === value.__id);
      if (fromPosition === toPosition) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (fromPosition < toPosition && hoverClientY < hoverMiddleY) {
        return;
      }
      if (fromPosition > toPosition && hoverClientY > hoverMiddleY) {
        return;
      }

      pages.move(fromPosition, toPosition);
    },
  });
  const [{ isDragging }, drag] = useDrag({
    item: { type: 'card', page: value },
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <button
      className={classnames('btn btn-sm btn-clear btn-block text-left d-flex', {
        active: isActive,
      })}
      type="button"
      ref={ref}
      style={{ opacity }}
      onClick={() => setActive(isActive ? null : value.__id ?? '')}
    >
      <span className="position">{position}</span>
      <span className="page-name">
        <Pane
          style={{
            backgroundImage: `url(${IMAGE})`,
          }}
        />
        {value.name}
      </span>
    </button>
  );
};

interface LayoutPagesProps {
  active: string | null;
  setActive(pageId: string | null): void;
}

const LayoutPages: React.FC<LayoutPagesProps> = ({ active, setActive }) => {
  const { pages } = useProductUpdate();
  const { t } = useTranslation(['mediaCenter', 'product']);

  return (
    <Pane className="layout-content-pages">
      <Pane className="page-layout-header">
        <span>{t('Position')}</span>
        <span>{t('product:PageId')}</span>
      </Pane>
      {pages.fields.map((page, index) => (
        <PageID
          key={`page-id-layout-${page.__id}`}
          value={page}
          position={index + 1}
          active={active}
          setActive={setActive}
        />
      ))}
    </Pane>
  );
};

export default LayoutPages;
