/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Header from '../Header';
import CommonAttributes from '../CommonAttributes';
import { ProductBaseProps, PageId } from '../../types';
import { Button } from '../../../../../components';

import Searcher from '../Searcher';
import Office from '../Office';
import ProductPageList from '../PageList';
import Reports from '../Reports';
import TemplateFields from '../TemplateFields';
import { Form } from 'reactstrap';
import Provider from '../Provider';
import { useMutation } from '@apollo/client';
import { UPDATE_PRODUCT_GQL } from '../../graphql';
import MediaCenter from '../MediaCenter';
import CategorySelector from '../CategorySelector';
import ShapeGroups from '../ShapeGroups';
import ParcelType from '../ParcelType';
import { PRODUCT_TYPE_PARCEL_ID } from '@wgt/shared/constants';
import useErrorMessage from '../../../../../hooks/useErrorMessage';
import {
  BoxId,
  Category,
  CrateId,
  Report,
  Shape,
  ShapeDescription,
} from '@wgt/types';
import { convertPageIdFields } from '@wgt/converters';
import useToast from '../../../../../hooks/useToast';
import WorkOrders from '../WorkOrders';
import { Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useToggler from '@wgt/shared/hooks/useToggler';
import WorkOrderSchedule from '../../../../../components/WorkOrderSchedule';
import { removeCurrencyMask } from '../../../../../helpers/CurrencyMaskInput';

const removeId = ({ id, name }: BoxId | CrateId) => ({
  name,
  id: id?.toString().match(/^\d+$/) ? id : undefined,
});

interface ProductInput {
  id: number;
  boxes: BoxId[];
  crates: CrateId[];
  category: Category;
  description: string;
  name: string;
  office: { id: number; name: string };
  lot_id: string;
  sku: string;
  tray_id: string;
  reports: Report[];
  pages: PageId[];
  product_status_id?: string;
}

export interface ShapeDescriptionsInput extends Shape {
  descriptions: ShapeDescription[];
}

const ComponentsContent: React.FC<ProductBaseProps> = ({
  product,
  filters,
  parcelTypes = [],
}) => {
  const { t } = useTranslation('product');
  const [createdScheduleId, setCreatedScheduleId] = React.useState<string>();
  const [isFormEnabled, setFormEnabled] = useState(product.draft);

  const methods = useForm<ProductInput>({
    defaultValues: {
      ...product,
      product_status_id: product?.productStatus?.id,
      crates: product.crates?.length ? [...product.crates] : [{ name: '' }],
      boxes: product.boxes?.length ? [...product.boxes] : [{ name: '' }],
      pages: product.pages?.map((pageId) => ({
        ...pageId,
        shapes: pageId.shapes?.map((shape) => ({
          ...shape,
          descriptions: shape.shapeDescriptionGroups?.reduce<
            ShapeDescription[]
          >((acc, curr) => {
            acc.push(...curr.shapeDescriptions);
            return acc;
          }, []),
        })),
      })),
    },
    shouldUnregister: false,
  });
  const [isScheduleFormOpen, toggleScheduleForm] = useToggler();
  const handleError = useErrorMessage(methods.getValues);
  const { toast } = useToast();

  const [update] = useMutation(UPDATE_PRODUCT_GQL, {
    onCompleted: () => toast('success'),
    onError: handleError,
  });

  const onSubmit = methods.handleSubmit(
    ({
      pages,
      boxes,
      crates,
      category,
      description,
      name,
      office,
      lot_id,
      sku,
      tray_id,
      product_status_id,
      id,
    }) =>
      update({
        variables: {
          productId: id,
          product: {
            name,
            description,
            lot_id,
            sku,
            tray_id,
            product_status_id,
            category_id: Number(category?.id),
            office_id: Number(office?.id),
            boxes: boxes.map(removeId),
            crates: crates.map(removeId),
            pages: pages.map((page: PageId, i: number) => ({
              id: page.id,
              name: page.name,
              position: i + 1,
              shapes: page.shapes?.map((shape: ShapeDescriptionsInput) => ({
                id: shape.id,
                descriptions: shape.descriptions?.map((x) => x.id),
              })),
              category_id: page.category?.id ?? category?.id,
              carat_weight: Number(
                removeCurrencyMask(
                  page.carat_weight ? page.carat_weight.toString() : '0',
                ),
              ),
              price_per_carat: Number(
                removeCurrencyMask(
                  page.price_per_carat ? page.price_per_carat.toString() : '0',
                ),
              ),
              form_values: JSON.stringify(
                convertPageIdFields(page.fields) ?? {},
              ),
              fields: JSON.stringify(page.fields ?? {}),
            })),
          },
        },
      }),
  );

  return (
    <div className="update-product-screen">
      <Provider
        filters={filters}
        isFormEnabled={isFormEnabled}
        setFormEnabled={setFormEnabled}
        control={methods.control}
      >
        <Form onSubmit={onSubmit}>
          <FormProvider {...methods}>
            <Header />
            <MediaCenter />
          </FormProvider>
          <Route
            path="/crm/products/:id/edit/workOrders/:workOrderId/items/:workOrderItemId"
            component={WorkOrders}
          />
          <FormProvider {...methods}>
            <CommonAttributes />

            <div className="d-flex justify-content-between mt-4">
              <Searcher />
              <Button type="secondary" onClick={toggleScheduleForm}>
                {t('Calendar')}
              </Button>
            </div>

            {product.category.mixed && <CategorySelector />}
            {Number(product.category.productType?.id) ===
              PRODUCT_TYPE_PARCEL_ID && (
              <ParcelType parcelTypes={parcelTypes} />
            )}
            <ProductPageList />
            <ShapeGroups />
            <TemplateFields section="first" />
            <Reports />
            <TemplateFields section="second" />
            <Office />
          </FormProvider>
        </Form>
      </Provider>
      {isScheduleFormOpen && product?.id && (
        <WorkOrderSchedule
          scheduleId={createdScheduleId}
          products={[product]}
          toggle={toggleScheduleForm}
          onCompleted={({ id }) => setCreatedScheduleId(id)}
        />
      )}
    </div>
  );
};

export default ComponentsContent;
