import React from 'react';
import List from './List';
import Put from './Put';
import PutModal from './PutModal';
import useCrmRoutes from '../../../../../hooks/useCrmRoutes';
import InternalRoutes from '../../../../../components/_organisms/InternalRoutes';

const PropertyValueVariationValues: React.FC = () => {
  const { isCrmInternalSubRoute, actualRoute } = useCrmRoutes();

  const routes = [
    {
      component: PutModal,
      name: 'Variation Values',
      path: `/crm/${actualRoute}/:id/edit/variation-values/:variationValueId`,
    },
  ];

  return (
    <div className="border my-4 px-4 pt-4 rounded">
      <h3>Create a new Variation Value</h3>
      <Put />
      <h3 className="my-4">Variation Values</h3>
      <List />
      {isCrmInternalSubRoute && <InternalRoutes routes={routes} />}
    </div>
  );
};

export default PropertyValueVariationValues;
