import { CategoryGroup } from '@wgt/types';
import React from 'react';
import { CollapseProvider } from '../../../services/collapse';
import CategoryGroupitem from './CategoryGroupitem';

const NavbarCategoriesCollapse: React.FC<{
  categoryGroups: CategoryGroup[];
}> = ({ categoryGroups = [] }) => {
  return (
    <CollapseProvider
      options={categoryGroups.map((x) => x.id?.toString() ?? '') ?? []}
    >
      <div className="navbar-categories-container">
        {categoryGroups
          .filter((categoryGroup) => categoryGroup.categories?.length)
          .map((categoryGroup) => (
            <CategoryGroupitem
              key={`category-group-${categoryGroup.id}`}
              categoryGroup={categoryGroup}
            />
          ))}
      </div>
    </CollapseProvider>
  );
};

export default NavbarCategoriesCollapse;
