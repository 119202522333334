import React from 'react';
import { Worker } from '@react-pdf-viewer/core';
import { Pane } from 'evergreen-ui';
import { ReportTableProps } from '../../../Update/Reports/ReportsTable';
import ReportItem from './ReportItem';

const ReportsTable: React.FC<ReportTableProps> = ({
  reports,
  setDeleteItem,
}) => {
  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.5.207/build/pdf.worker.min.js">
      <Pane>
        {reports.map((report) => (
          <ReportItem
            report={report}
            key={report.id}
            setDeleteItem={setDeleteItem}
          />
        ))}
      </Pane>
    </Worker>
  );
};

export default ReportsTable;
