import React from 'react';
import useFakeQuery from '../../../hooks/useFakeQuery';
import PurchaseWebsite from './PurchaseWebsite';
import MenageAnalytics from './ManageAnalytics';
import { GET_FIRM_SERVICES } from './graphql';
import Loader from '../../../components/_organisms/Loader';

export default function Website(): JSX.Element {
  const { data, loading } = useFakeQuery(GET_FIRM_SERVICES, {
    defaultValue: { app: true, website: false },
  });

  if (loading) {
    return <Loader />;
  }

  if (data?.website) {
    return <MenageAnalytics />;
  }

  return <PurchaseWebsite />;
}
