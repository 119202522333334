import React from 'react';
import useProfileContext from '../../hooks/useProfile';
import ProfileAddressItem from '../ProfileAddressItem';

export default function ProfileAddresses(): JSX.Element {
  const { user } = useProfileContext();
  return (
    <div>
      {user?.homeLocations?.map((address, key) => (
        <ProfileAddressItem key={`profile-address-${key}`} address={address} />
      ))}
    </div>
  );
}
