import { useQuery } from '@apollo/client';
import { CategoryGroup } from '@wgt/types';
import React from 'react';
import { Col, Row } from 'reactstrap';
import { LIST_CATEGORY_GROUPS_GQL } from '../../../../components/_molecules/NavbarCategories/graphql';
import NavbarCategoriesCollapse from '../../../../components/_molecules/NavbarCategories/NavbarCategoriesCollapse';
import Loader from '../../../../components/_organisms/Loader';

const ProductList: React.FC = () => {
  const { data, loading } = useQuery<{
    categoryGroups: { data: CategoryGroup[] };
  }>(LIST_CATEGORY_GROUPS_GQL, {
    variables: { first: 100 },
    fetchPolicy: 'cache-and-network',
  });

  if (loading) {
    return <Loader />;
  }
  return (
    <Row>
      <Col>
        <NavbarCategoriesCollapse
          categoryGroups={data?.categoryGroups?.data ?? []}
        />
      </Col>
    </Row>
  );
};

export default ProductList;
