import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const { NODE_ENV, REACT_APP_CONSOLE_TRANSLATION } = process.env;

if (!i18n.isInitialized) {
  i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      fallbackLng: 'en-US',
      debug: !!REACT_APP_CONSOLE_TRANSLATION && NODE_ENV === 'development',
      defaultNS: 'common',
      react: {
        useSuspense: false,
      },
      backend: {
        loadPath: `/locales/{{lng}}/{{ns}}.json`,
      },
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
    });
}
export default i18n;
