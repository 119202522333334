import React, { memo, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { PRODUCT_TYPE_PARCEL_ID } from '@wgt/shared/constants';
import { CategoryBase, Shape, ShapeDescription } from '../../../../../../types';
import { Button } from '../../../../components';
import { useTranslation } from 'react-i18next';
import { useBagContext } from '../../../../services/bag';

export default memo(function ProductPageIdShapesDescriptions(): JSX.Element {
  const { t } = useTranslation('product');
  const { watch } = useFormContext();
  const { toggleShapeDescriptionVisible } = useBagContext();
  const w = watch([
    'page.shapes',
    'page.shapes[0].descriptions',
    'page.category',
  ]);

  const descriptions: ShapeDescription[] = w['page.shapes[0].descriptions'];
  const shapes: Shape[] = w['page.shapes'];
  const category: CategoryBase = w['page.category'];

  const shape = useMemo(() => shapes?.[0], [shapes, category]);

  const selectedDescriptions = useMemo(
    () =>
      descriptions
        ?.reduce<ShapeDescription[]>((acc, description) => {
          const selected = shape?.descriptions?.find(
            (x) => x.id === description.id,
          );
          if (selected) {
            acc.push(description);
          }
          return acc;
        }, [])
        .map((x) => x.name)
        .join(', '),
    [descriptions],
  );

  return (
    <>
      {!Number.isNaN(category?.productType?.id) &&
        Number(category?.productType?.id) !== PRODUCT_TYPE_PARCEL_ID && (
          <div className="mx-5">
            {shape ? (
              <div className="d-flex flex-column">
                <img src={shape.image_highlight_url ?? shape.image_url} />
                <p className="text-center mt-4">{shape.title}</p>
                <Button
                  small
                  type="primary"
                  htmlType="button"
                  onClick={toggleShapeDescriptionVisible}
                  className="d-flex justify-content-center"
                >
                  {t('Set Shape Description')}
                </Button>
                <p className="mt-3">{selectedDescriptions}</p>
              </div>
            ) : (
              <div className="no-shape text-center">
                <p>{t('No shape selected')}</p>
              </div>
            )}
          </div>
        )}
    </>
  );
});
