import { gql } from '@apollo/client';
import {
  propertyValueVariationFragment,
  propertyValueVariationValueFragment,
} from '../PropertyValueVariation/graphql';
import { componentFragment, shapeCategoryFragment } from '../Search/graphql';

export const CATEGORY_GQL = gql`
  query category($id: ID!) {
    category(id: $id) {
      id
      name
      template {
        id
        search_layout
        result_header
        result_layout
        name
        shapes {
          ...shapeCategory_Part
        }
        fields {
          id
          label
          is_required
          is_searchable
          is_highlight
          is_single_shape
          config
          property {
            name
            key
            dataType {
              name
              key
            }
          }
          component {
            ...componentSearchLayout_Part
          }
          searchComponent {
            ...componentSearchLayout_Part
          }
          values {
            id
            name
            key
            options
            image
            abbreviations
            variations {
              ...propertyValueVariationInfo_Part
              variationValues {
                ...propertyValueVariationValueInfo_Part
              }
            }
            dependencies {
              fieldDependencies {
                label
                property {
                  key
                }
              }
              valueDependencies {
                field {
                  label
                  property {
                    key
                  }
                }
                value {
                  key
                }
              }
            }
          }
          fieldDependencies {
            label
            property {
              key
            }
          }
          valueDependencies {
            field {
              label
              property {
                key
              }
            }
            value {
              key
            }
          }
        }
      }
    }
  }
  ${componentFragment}
  ${propertyValueVariationFragment}
  ${propertyValueVariationValueFragment}
  ${shapeCategoryFragment}
`;
