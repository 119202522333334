import React from 'react';
import { FaSpinner } from 'react-icons/fa';

const BtnLoading: React.FC = () => {
  return (
    <>
      <FaSpinner className="spinner" /> loading
    </>
  );
};

export default BtnLoading;
