import React from 'react';
import CustomHeader from '../../../../components/_molecules/CustomHeader';
import { LIST_FIRMS_GQL } from '../graphql';
import Table from '../../../../components/_organisms/Table';

const List: React.FC = () => {
  return (
    <div>
      <CustomHeader title={'List Firms'} showCreate />
      <Table
        hover
        striped
        columns={[{ label: 'Name', field: 'name' }]}
        query={LIST_FIRMS_GQL}
        node="firms"
      />
    </div>
  );
};

export default List;
