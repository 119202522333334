/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import {
  FaEye,
  FaTrash,
  FaChevronDown,
  FaChevronUp,
  FaPlusCircle,
  FaMinusCircle,
} from 'react-icons/fa';
import { FiEdit } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';
import {
  Table as TableBase,
  Pagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap';
import { IconButton, Avatar } from 'evergreen-ui';
import Loader from '../../_organisms/Loader';
import ConfirmDialog from '../ConfirmDialog';
import { useCollapseContext } from '../../../services/collapse';
import { useTranslation } from 'react-i18next';

export interface Column {
  label: string;
  field: string;
  type?: 'image' | 'boolean' | 'empty' | 'multiple' | 'collapse';
  size?: number;
}

export interface BaseTable {
  columns: Column[];
  node: string;
  showDetail?: boolean;
  showEdit?: boolean;
  getMutationArgs?(item: any): any;
  getConfirmMessage?(item: any): string;
}

export interface Props extends BaseTable {
  items: any[];
  loading: boolean;
  ItemComponent?: any;
  mutator: any;
  mutating: boolean;
  paginator: any;
  fetchMore(options: any): void;
  editAction?(item: any): void;
  isExpandable?: boolean;
  RenderDetails?: React.FC<{ item: any }>;
}

interface CellDataProps {
  column: Column;
  item: any;
  active: string | null;
  set(item: string): void;
}

const ValueImage: React.FC<{
  image?: string;
  size?: number;
}> = ({ image, size }) => {
  if (!image) {
    return <></>;
  }

  return <Avatar src={image} size={size ?? 50} />;
};

const getProp = ({
  item,
  column,
}: {
  item: Record<string, any>;
  column: Column;
}) =>
  column?.field.includes('.')
    ? column?.field
        .split('.')
        .reduce((acc, itemStr) => acc[itemStr] ?? acc, item) ?? ''
    : item[column?.field];

const CellData: React.FC<CellDataProps> = ({ item, column, active, set }) => {
  const { t } = useTranslation();
  const fieldToHandle = getProp({ item, column });
  switch (column?.type) {
    case 'empty':
      return (
        <td width={column?.size}>
          <React.Fragment />
        </td>
      );
    case 'image':
      return (
        <td width={column?.size}>
          <ValueImage image={fieldToHandle} size={column?.size} />
        </td>
      );
    case 'boolean':
      return (
        <td width={column?.size}>{!!fieldToHandle ? t('Yes') : t('No')}</td>
      );
    case 'multiple':
      return (
        <td width={column?.size}>
          {Object.prototype.toString.call(fieldToHandle) === '[object Array]'
            ? fieldToHandle.map((itemStr: string, key: number) => (
                <span key={`multiple-${key}`}>
                  {itemStr} {key + 1 < fieldToHandle.length && ', '}
                </span>
              ))
            : ''}
        </td>
      );
    case 'collapse':
      return (
        <td width={column?.size}>
          <IconButton
            icon={item.id === active ? FaMinusCircle : FaPlusCircle}
            onClick={() => set(item.id)}
          />
        </td>
      );

    default:
      return <td>{fieldToHandle}</td>;
  }
};

const Table: React.FC<Props> = ({
  columns,
  items,
  ItemComponent,
  loading,
  mutator,
  mutating,
  getMutationArgs,
  getConfirmMessage,
  paginator,
  fetchMore,
  editAction,
  showDetail = true,
  showEdit = true,
  isExpandable,
  RenderDetails,
  ...props
}) => {
  const [deleteItem, setDeleteItem] = useState(null);
  const history = useHistory();
  const { active, set } = useCollapseContext();

  return (
    <>
      <ConfirmDialog
        isShown={!!deleteItem}
        onConfirm={() =>
          mutator({
            variables: getMutationArgs && getMutationArgs(deleteItem),
            update: () => setDeleteItem(null),
          })
        }
        title={
          (getConfirmMessage && deleteItem && getConfirmMessage(deleteItem)) ||
          ''
        }
        onClose={() => setDeleteItem(null)}
        isLoading={mutating}
      />

      <div className="table-component mt-4">
        <TableBase {...props}>
          {columns && (
            <thead>
              <tr>
                {columns.map((column, key) => (
                  <th key={`header-${key}`}>
                    {column.label === '#' ? '' : column.label}
                  </th>
                ))}
                <th>&nbsp;</th>
              </tr>
            </thead>
          )}
          <tbody>
            {items.map((item, key) => (
              <React.Fragment key={`row-${key}`}>
                <tr>
                  {ItemComponent ? (
                    <ItemComponent item={item} />
                  ) : (
                    columns.map((column, cKey) => (
                      <CellData
                        item={item}
                        column={column}
                        key={`cell-data${cKey}`}
                        active={active}
                        set={set}
                      />
                    ))
                  )}
                  <td className="edit-col">
                    <div className="d-flex justify-content-end align-items-center">
                      {isExpandable ? (
                        <IconButton
                          icon={
                            item.id === active ? FaChevronUp : FaChevronDown
                          }
                          onClick={() => set(item.id)}
                        />
                      ) : (
                        <>
                          {showDetail && (
                            <Link
                              to={`${history.location.pathname}${
                                history.location.pathname.endsWith('/')
                                  ? ''
                                  : '/'
                              }${item.id}`}
                              className="mx-1"
                            >
                              <FaEye />
                            </Link>
                          )}
                          {showEdit && (
                            <IconButton
                              appearance="minimal"
                              className="mx-1"
                              onClick={() =>
                                editAction
                                  ? editAction(item)
                                  : history.push(
                                      `${history.location.pathname}${
                                        history.location.pathname.endsWith('/')
                                          ? ''
                                          : '/'
                                      }${item.id}/edit`,
                                    )
                              }
                              icon={FiEdit}
                            />
                          )}
                        </>
                      )}
                      {mutator && !isExpandable && (
                        <IconButton
                          appearance="minimal"
                          intent="danger"
                          className="mx-1 my-0 p-0"
                          icon={FaTrash}
                          onClick={() => setDeleteItem(item)}
                        />
                      )}
                    </div>
                  </td>
                </tr>
                {isExpandable && item.id === active && (
                  <tr>{RenderDetails && <RenderDetails item={item} />}</tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </TableBase>
        {paginator && (
          <Pagination className="d-flex justify-content-end">
            <PaginationItem disabled={paginator.currentPage === 1}>
              <PaginationLink
                previous
                onClick={() => fetchMore({ page: paginator.currentPage - 1 })}
              />
            </PaginationItem>
            <PaginationItem disabled={!paginator.hasMorePages}>
              <PaginationLink
                next
                onClick={() => fetchMore({ page: paginator.currentPage + 1 })}
              />
            </PaginationItem>
          </Pagination>
        )}
      </div>

      {loading && <Loader />}
    </>
  );
};
export default Table;
