import React, { useState } from 'react';
import { FormGroup, Label, Input, Collapse } from 'reactstrap';
import { useFormContext } from 'react-hook-form';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { IconButton } from 'evergreen-ui';
import { Department } from '@wgt/types';

interface Props {
  department: Department;
  scrollToBottom?: () => void;
  field: string;
}

export default function Item({
  department,
  scrollToBottom,
  field,
}: Props): JSX.Element {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { getValues, setValue } = useFormContext();

  const toggle = () => [setIsOpen(!isOpen), scrollToBottom?.()];

  const handleCheckDepartment = (item: Department) => {
    const departmentListValues = getValues(field);
    const index = departmentListValues.indexOf(Number(item.id));
    let values = [];
    if (index !== -1) {
      departmentListValues.splice(index, 1);
      values = departmentListValues;
    } else {
      values = [...departmentListValues, Number(item.id)];
    }
    setValue(field, values);
  };

  return (
    <>
      <FormGroup check key={department.id} className="mb-2 d-flex">
        <Label check>
          <Input
            type="checkbox"
            name="departments"
            onChange={() => handleCheckDepartment(department)}
            defaultChecked={getValues(field).includes(department.id)}
          />{' '}
          {department.name}
        </Label>
        {!!department?.children?.length && (
          <IconButton
            appearance="minimal"
            icon={isOpen ? FiChevronUp : FiChevronDown}
            iconSize={20}
            className="ml-3 btn-icon-more-items"
            onClick={toggle}
          />
        )}
      </FormGroup>
      {!!department.children?.length && (
        <Collapse isOpen={isOpen}>
          {department.children?.map((child: Department) => (
            <FormGroup
              check
              key={`department-${child.id}`}
              className="mb-2 ml-4"
            >
              <Label check>
                <Input
                  type="checkbox"
                  name="departments"
                  onChange={() =>
                    handleCheckDepartment({
                      id: child.id,
                      name: child.name,
                    } as Department)
                  }
                  defaultChecked={getValues(field).includes(Number(child.id))}
                />{' '}
                {child.name}
              </Label>
            </FormGroup>
          ))}
        </Collapse>
      )}
    </>
  );
}
