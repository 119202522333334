import React from 'react';
import { Pane, Spinner } from 'evergreen-ui';
import { Label, Form } from 'reactstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import usePointOfSaleUpdate, {
  ProductHighlight,
} from '../../../usePointOfSaleUpdate';
import { SimpleInput } from '../../../../../../../components';
import { POS_PRODUCT } from '../../../../graphql';
import { useTranslation } from 'react-i18next';
import useToast from '../../../../../../../hooks/useToast';

export default function ItemVerificationInput({
  product,
}: {
  product: ProductHighlight;
}): JSX.Element {
  const { t } = useTranslation('pointOfSales');
  const { pointOfSale } = usePointOfSaleUpdate();
  const { toast } = useToast();

  const methods = useForm({
    defaultValues: {
      sku: '',
    },
  });

  const [updatePosProduct, { loading }] = useMutation(POS_PRODUCT, {
    variables: {
      id: pointOfSale.id,
      product_id: product.id,
      confirmed: true,
    },
    onCompleted: () => [
      toast('success', t('Product confirmed successfully')),
      methods.reset(),
    ],
  });

  const onSubmit = methods.handleSubmit(({ sku }) => {
    if (sku !== product.sku) {
      methods.setError('sku', { type: 'pattern' });
      return;
    }

    updatePosProduct();
  });

  return (
    <FormProvider {...methods}>
      <Form className="d-flex" onBlur={onSubmit}>
        <Pane flexDirection="row" display="flex" alignItems="center">
          <Label>{t('Verification')}</Label>
          <SimpleInput
            placeholder={t('Enter SKU')}
            field="sku"
            disabled={loading}
          />
          {loading && <Spinner size={18} />}
        </Pane>
      </Form>
    </FormProvider>
  );
}
