/* eslint-disable @typescript-eslint/no-explicit-any */
import { TemplateLayoutRow } from '@wgt/types';
import React from 'react';

import Section from '../Components/Section';

interface Props {
  rows: TemplateLayoutRow[];
  values: any;
  fields: any;
}

export default function SecondSection({
  rows,
  values,
  fields,
}: Props): JSX.Element {
  return <Section rows={rows} values={values} fields={fields} />;
}
