import { IconTree, IconType, GenIcon } from 'react-icons';

const marketingMenuIconJson: IconTree = {
  tag: 'svg',
  attr: {
    version: '1.0',
    viewBox: '0 0 102.000000 97.000000',
    preserveAspectRatio: 'xMidYMid meet',
  },
  child: [
    { tag: 'metadata', attr: {}, child: [] },
    {
      tag: 'g',
      attr: {
        transform: 'translate(0.000000,97.000000) scale(0.100000,-0.100000)',
        stroke: 'none',
      },
      child: [
        {
          tag: 'path',
          attr: {
            d:
              'M20 480 l0 -470 485 0 485 0 0 50 0 50 -435 0 -435 0 0 420 0 420\n-50 0 -50 0 0 -470z',
          },
          child: null,
        },
        {
          tag: 'path',
          attr: {
            d:
              'M524 906 c-39 -39 -45 -86 -19 -136 15 -29 13 -33 -52 -165 -64 -128\n-69 -135 -98 -135 -74 -1 -119 -94 -81 -169 17 -33 54 -51 104 -51 46 0 80 22\n97 64 13 32 14 32 162 58 127 23 149 25 156 13 11 -21 61 -45 92 -45 115 0\n157 162 55 215 -60 31 -135 6 -159 -53 -12 -30 -16 -31 -157 -57 -195 -37\n-192 -41 -107 130 62 123 69 134 99 140 73 13 115 78 95 145 -16 53 -52 80\n-108 80 -38 0 -50 -5 -79 -34z',
          },
          child: null,
        },
      ],
    },
  ],
};

const marketingMenuIcon = (props: IconBaseProps): IconType =>
  GenIcon(marketingMenuIconJson)(props);

export default marketingMenuIcon;
