/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button } from '../../../../../../components';
import FileListItem from './FileListItem';
import classnames from 'classnames';
import './styles.scss';
import ConfirmDialog from '../../../../../../components/_molecules/ConfirmDialog';
import { FileItem } from '@wgt/types';
import { useTranslation } from 'react-i18next';

interface UploadReportFilesProps {
  onChange(data?: FileItem): void;
  value?: FileItem;
  errors?: any;
}

export default function UploadReportFiles({
  onChange,
  value,
  errors,
}: UploadReportFilesProps): JSX.Element {
  const [showConfirmDialog, setShowConfirm] = useState(false);
  const { t } = useTranslation('product');
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file: Blob) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        const binaryStr = reader.result;
        const newFile = {
          image: binaryStr,
          fileToUpload: acceptedFiles,
        } as FileItem;
        onChange(newFile);
      };
      reader.readAsDataURL(file);
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: 'application/pdf',
  });

  const handleRemoveFile = () => {
    onChange();
    setShowConfirm(false);
  };

  return (
    <>
      <ConfirmDialog
        isShown={showConfirmDialog}
        onConfirm={handleRemoveFile}
        title={`Confirm delete`}
        onClose={() => setShowConfirm(false)}
      />
      <div className="ibox float-e-margins">
        <div className="ibox-content">
          <div
            {...getRootProps()}
            className={classnames('dropzone', {
              'border-danger': !!errors?.files,
            })}
          >
            <input {...getInputProps()} />
            <div className="dropzone-content">
              {isDragActive ? (
                <h2>{t('Drop file here')}</h2>
              ) : (
                <>
                  <Button small type="secondary">
                    {t('Add Gemology Report')}
                  </Button>
                  <br />
                  <h2>{t('Drag and Drop file to add')}</h2>
                  <span>{t('PDF files only')}</span>
                </>
              )}
            </div>
          </div>
          {value && (
            <FileListItem
              file={value}
              handleRemoveFile={() => setShowConfirm(true)}
            />
          )}
        </div>
      </div>
    </>
  );
}
