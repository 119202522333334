import { gql, useMutation } from '@apollo/client';
import { Shape, TemplateBase } from '@wgt/types';
import React, { Dispatch, SetStateAction, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { FaTrash } from 'react-icons/fa';
import { Button } from '../../../../../components';
import { GET_TEMPLATE_SHAPES_GQL } from './graphql';

export default function TemplateShapesListItem({
  shape,
  templateId,
  setShapeToRemove,
}: {
  setShapeToRemove: Dispatch<SetStateAction<Shape | null>>;
  shape: Shape;
  templateId: string | number;
}): JSX.Element {
  const rootNode = useRef<HTMLDivElement>(null);

  const [sort, { loading: sorting }] = useMutation(gql`
    mutation attachShapeToTemplate(
      $templateId: ID!
      $shapeId: ID!
      $position: Int
    ) {
      attachShapeToTemplate(
        id: $templateId
        shape_id: $shapeId
        position: $position
      ) {
        message
      }
    }
  `);

  const [, drag] = useDrag<
    { type: 'shape'; value: Shape },
    { dragged: Shape; droppedAt?: Shape },
    null
  >({
    item: {
      type: 'shape',
      value: shape,
    },
    end: (item, monitor) => {
      const result = monitor.getDropResult();

      if (!result || sorting) {
        return;
      }
      const { dragged, droppedAt } = result;

      sort({
        variables: {
          templateId,
          shapeId: dragged.id,
          position: droppedAt.position,
        },
        update: (cache) => {
          const current = cache.readQuery<{ template: TemplateBase }>({
            query: GET_TEMPLATE_SHAPES_GQL,
            variables: {
              id: templateId,
            },
          });

          const shapes = [...(current?.template.shapes ?? [])];

          shapes.splice(
            droppedAt.position - 1,
            0,
            shapes.splice(
              shapes.findIndex((x) => x.id === dragged.id),
              1,
            )[0],
          );

          cache.writeQuery({
            query: GET_TEMPLATE_SHAPES_GQL,
            variables: {
              id: templateId,
            },
            data: {
              ...current,
              template: {
                ...current?.template,
                shapes: shapes.map((shape, index) => ({
                  ...shape,
                  position: index + 1,
                })),
              },
            },
          });
        },
      });
    },
  });
  const [, drop] = useDrop<
    { type: 'shape'; value: Shape },
    { dragged: Shape; droppedAt?: Shape },
    null
  >({
    accept: 'shape',
    drop: (dragged) => ({
      dragged: dragged.value,
      droppedAt: shape,
    }),
  });

  drag(drop(rootNode));

  return (
    <div ref={rootNode} className="template-shapes-list-item">
      <div
        style={{
          backgroundImage: `url(${shape.image_url})`,
        }}
      />
      <span>
        {shape.title} ({shape.shapeGroup.title})
      </span>
      <Button small type="danger" onClick={() => setShapeToRemove(shape)}>
        <FaTrash />
      </Button>
    </div>
  );
}
