/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo } from 'react';
import { Form, Col, Row, Label } from 'reactstrap';
import {
  useForm,
  FormProvider,
  Controller,
  useFieldArray,
} from 'react-hook-form';
import { PropertyValue } from '@wgt/types';
import { useTranslation } from 'react-i18next';
import {
  Button,
  SimpleInput,
  UploadButton,
  ColorButton,
} from '../../../../../../components';
import BtnLoading from '../../../../../../components/_molecules/BtnLoading';
import BagProvider from '../../../../../../services/bag';
import Abbreviations from './Abbreviations';
import useToast from '../../../../../../hooks/useToast';
import PropertyValueTypeSelector, {
  PROPERTY_VALUE_TYPES,
} from './PropertyValueTypeSelector';
import SieveChart from './SieveChart';
import Minerals from './Minerals';
import { DocumentNode, useApolloClient, useMutation } from '@apollo/client';
import {
  CREATE_MINERAL,
  CREATE_PROPERTY_VALUE_GQL,
  LIST_PROPERTY_VALUE_GQL,
  PROPERTY_EDIT_SCREEN_GQL,
  UPDATE_MINERAL,
  UPDATE_PROPERTY_VALUE_GQL,
} from '../../../graphql';
import { useParams } from 'react-router-dom';
import { PropertyValueType } from '../../../types';
import { LIST_MINERALS } from '../List/MineralsTable/graphql';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  propertyValue?: any;
  isLoading?: boolean;
  toggle?: () => void;
  propertyType?: PropertyValueType;
}

const Put: React.FC<Props> = ({
  propertyValue,
  isLoading,
  toggle,
  propertyType,
}) => {
  const params = useParams<ParamTypes>();
  const defaultTypes = propertyType
    ? { [propertyType]: true }
    : { is_seive_chart: false, is_minerals: false, is_other: false };

  const refetchQueries: {
    query: DocumentNode;
    variables: any;
  }[] = useMemo(() => {
    const defaultQuery = {
      query: PROPERTY_EDIT_SCREEN_GQL,
      variables: { isEdit: true, id: params.id ?? '' },
    };
    switch (propertyType) {
      case 'is_minerals':
        return [
          {
            query: LIST_MINERALS,
            variables: {
              filters: { propertyValue: { property_id: params.id } },
            },
          },
          defaultQuery,
        ];

      default:
        return [
          {
            query: LIST_PROPERTY_VALUE_GQL,
            variables: { filters: { filter: { property_id: params.id } } },
          },
          defaultQuery,
        ];
    }
  }, [propertyType]);

  const methods = useForm<PropertyValue>({
    defaultValues: {
      id: propertyValue?.id,
      name: '',
      key: '',
      options: {},
      abbreviations: [{ name: '' }],
      type: propertyType ?? '',
      mineral: {
        group: '',
        subgroup: '',
        species: '',
      },
      ...defaultTypes,
    },
    shouldUnregister: false,
  });

  const { toast } = useToast();

  const { t } = useTranslation(['property', 'common']);

  const isEditPropertyValue = useMemo(() => !!propertyValue, [propertyValue]);

  const onError = (response?: any) => toast('error', response?.message);

  const client = useApolloClient();

  const { control, errors, watch, reset, setValue } = methods;

  const type = watch('type', '');

  const [mutatePropertyValue, { loading: submitting }] = useMutation(
    isEditPropertyValue ? UPDATE_PROPERTY_VALUE_GQL : CREATE_PROPERTY_VALUE_GQL,
    {
      onError,
      refetchQueries,
    },
  );

  const handleSubmit = methods.handleSubmit(
    async ({
      name,
      key,
      mineral,
      options,
      image,
      plate_size,
      stone_number,
      carat_weight,
      mm_size,
      type: propertyValueType,
      abbreviations: abbrevs,
    }: any) => {
      const lastMineralGroupName = [
        mineral?.classification,
        mineral?.group,
        mineral?.subgroup,
        mineral?.species,
        mineral?.variety,
        mineral?.series,
      ].reduce(
        (mineralGroupName, group) => group?.label ?? mineralGroupName,
        '',
      );

      const parsedPropertyValue = {
        name: propertyType === 'is_minerals' ? lastMineralGroupName : name,
        key: propertyType === 'is_minerals' ? lastMineralGroupName : key,

        abbreviations: abbrevs
          ?.filter((x: { name: string }) => x.name)
          ?.map((x: { name: string }) => x.name),
        image: typeof image === 'string' ? undefined : image,
        ...(options &&
          Object.keys(options).length && {
            options: JSON.stringify(options),
          }),
        ...(!isEditPropertyValue && { property_id: params.id }),
        ...(plate_size && { plate_size }),
        ...(stone_number && { stone_number }),
        ...(carat_weight && { carat_weight }),
        ...(mm_size && { mm_size }),
        ...PROPERTY_VALUE_TYPES.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.id]: propertyValueType === curr.id,
          }),
          {},
        ),
      };

      const variables = isEditPropertyValue
        ? {
            id: propertyValue?.id,
            property_value: parsedPropertyValue,
          }
        : parsedPropertyValue;

      const request = await mutatePropertyValue({
        variables: !propertyType
          ? variables
          : {
              ...variables,
              [propertyType]: true,
            },
      });

      if (
        request?.data?.updatePropertyValue ||
        request?.data?.createPropertyValue
      ) {
        const { data } = request;
        if (propertyType === 'is_minerals') {
          const result = data.updatePropertyValue ?? data.createPropertyValue;

          const parsedMineral = {
            classification_id: mineral.classification.id,
            group_id: mineral.group?.id,
            subgroup_id: mineral.subgroup?.id,
            species_id: mineral.species?.id,
            variety_id: mineral.variety?.id,
            series_id: mineral.series?.id,
            type: lastMineralGroupName,
            property_value_id: result.id,
            abbreviations:
              mineral?.abbreviations
                ?.filter((x: { name: string }) => x.name !== '')
                ?.map((x: { name: string }) => x.name) ?? [],
          };
          const mineralVariables = !!mineral?.id
            ? {
                id: mineral.id,
                mineral: parsedMineral,
              }
            : parsedMineral;

          await client.mutate({
            mutation: !!mineral?.id ? UPDATE_MINERAL : CREATE_MINERAL,
            variables: mineralVariables,
          });
        }

        toggle?.();
        toast('success');
        reset({
          id: propertyValue?.id,
          options: {},
          abbreviations: [{ name: '' }],
          is_seive_chart: propertyType === 'is_seive_chart',
          is_minerals: propertyType === 'is_minerals',
          type: propertyType ?? '',
          is_other:
            propertyType !== 'is_minerals' && propertyType !== 'is_seive_chart',
          mineral: {
            group: null,
            subgroup: null,
            species: null,
            variety: null,
            series: null,
            type: '',
            abbreviations: [{ name: '' }],
          },
        });
        setValue('mineral', {
          id: undefined,
          group: null,
          subgroup: null,
          species: null,
          variety: null,
          series: null,
          type: null,
          abbreviations: [{ name: '' }],
        });
      }
    },
  );

  const {
    fields: abbreviations,
    append: appendAbbreviations,
    remove: removeAbbreviations,
  } = useFieldArray({
    control,
    name: 'abbreviations',
  });

  useEffect(() => {
    const parseAbbreviations = (abbreviation: string) => ({
      name: abbreviation,
    });

    if (propertyValue) {
      const mineral = propertyValue?.minerals?.length
        ? {
            ...propertyValue.minerals[0],
            abbreviations: propertyValue.minerals[0].abbreviations?.map(
              parseAbbreviations,
            ) ?? [{ name: '' }],
          }
        : undefined;

      reset({
        ...propertyValue,
        mineral,
        abbreviations: propertyValue.abbreviations?.map(parseAbbreviations) ?? [
          { name: '' },
        ],
        type: PROPERTY_VALUE_TYPES.reduce((acc, curr) => {
          if (propertyValue[curr.id]) {
            return curr.id;
          }
          return acc;
        }, ''),
      });
    }
  }, [propertyValue]);

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit} className="values-form">
        <Row>
          <Col lg="8">
            <Row>
              {propertyType !== 'is_minerals' && (
                <>
                  <Col lg="6" className="mb-4">
                    <SimpleInput
                      required
                      field="key"
                      label={t('Values')}
                      hint={errors?.key?.message || ''}
                    />
                  </Col>
                  <Col lg="6" className="mb-4">
                    <SimpleInput
                      required
                      field="name"
                      label={t('Label')}
                      hint={errors?.name?.message || ''}
                    />
                  </Col>
                </>
              )}
              <Col lg="6" className="mb-4 abbreviations-col">
                <Label>{t('Abbreviations')}</Label>
                <BagProvider
                  value={{
                    abbreviation: {
                      fields: abbreviations,
                      remove: removeAbbreviations,
                    },
                  }}
                >
                  <>
                    <Abbreviations />
                    <div className="d-flex justify-content-end">
                      <Button
                        small
                        htmlType="button"
                        onClick={() => appendAbbreviations({ name: '' })}
                        className="text-right"
                      >
                        {t('common:Add')}
                      </Button>
                    </div>
                  </>
                </BagProvider>
              </Col>
              <Col
                lg="6"
                className="mb-4 d-flex align-items-start btn-components-col"
              >
                <Controller
                  name="image"
                  render={({ onChange }) => (
                    <UploadButton
                      setImageToUpload={onChange}
                      label={t('common:Image')}
                      accept=".jpg, .png, .jpeg, .gif"
                      name="image"
                    />
                  )}
                  defaultValue=""
                />
                <div className="ml-5">
                  <ColorButton
                    label={t('common:Color')}
                    field="options.color"
                  />
                </div>
              </Col>
            </Row>
            {(type === 'is_seive_chart' ||
              propertyType === 'is_seive_chart') && <SieveChart />}
            {(type === 'is_minerals' || propertyType === 'is_minerals') && (
              <Minerals />
            )}
          </Col>
          <Col lg="4">
            {!propertyType && !propertyValue?.id && (
              <PropertyValueTypeSelector />
            )}
          </Col>
        </Row>

        <Button htmlType="submit" disabled={submitting || isLoading}>
          {submitting || isLoading ? <BtnLoading /> : t('common:Save')}
        </Button>
      </Form>
    </FormProvider>
  );
};

export default Put;
