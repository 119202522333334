import React, { useMemo, useEffect } from 'react';
import CustomHeader from '../../../../components/_molecules/CustomHeader';
import { useParams, useHistory } from 'react-router-dom';
import useCrmRoutes from '../../../../hooks/useCrmRoutes';
import Loader from '../../../../components/_organisms/Loader';
import {
  PROPERTY_EDIT_SCREEN_GQL,
  CREATE_PROPERTY_GQL,
  UPDATE_PROPERTY_GQL,
  LIST_PROPERTIES_GQL,
} from '../graphql';
import { useQuery } from '@apollo/client';
import SimpleForm from '../../../../components/_organisms/SimpleForm';
import PropertyValues from './PropertyValues';
import useToast from '../../../../hooks/useToast';
import './styles.scss';
import { PropertyValueType } from '../types';

const Put: React.FC = () => {
  const history = useHistory();
  const params = useParams<ParamTypes>();
  const { toast } = useToast();
  const isEdit = useMemo<boolean>(() => !!params?.id, [params]);
  const { setCustomTitle, actualRoute } = useCrmRoutes();

  const { data, loading } = useQuery(PROPERTY_EDIT_SCREEN_GQL, {
    variables: {
      isEdit,
      id: params.id ?? '',
    },
  });

  const propertyType: PropertyValueType = useMemo(() => {
    if (!data?.property?.values?.[0]) {
      return null;
    }
    const types: PropertyValueType[] = ['is_minerals', 'is_seive_chart'];
    return (
      types.find((type) => type && data?.property?.values?.[0]?.[type]) ?? null
    );
  }, [data]);

  useEffect(() => {
    setCustomTitle(`${isEdit ? 'Edit' : 'Create'} Property`);
  }, []);

  if (loading && !data) {
    return <Loader />;
  }

  return (
    <div>
      <CustomHeader title={`${isEdit ? 'Edit' : 'Create'} Property`} showList />
      <SimpleForm
        query={PROPERTY_EDIT_SCREEN_GQL}
        queryOptions={{ variables: { isEdit, id: params.id ?? '' } }}
        parseQuery={({ property } = {}) => ({
          ...property,
          data_type_id: property?.dataType?.id,
        })}
        mutation={isEdit ? UPDATE_PROPERTY_GQL : CREATE_PROPERTY_GQL}
        parseMutationArgs={(e) => (isEdit ? { id: params.id, property: e } : e)}
        mutationOptions={{
          refetchQueries: [{ query: LIST_PROPERTIES_GQL }],
          onCompleted: ({ createProperty }) => [
            toast('success'),
            history.push(`/crm/${actualRoute}/${createProperty.id}/edit`),
          ],
        }}
        fields={[
          {
            label: 'Name',
            name: 'name',
            required: true,
            type: 'text',
          },
          {
            label: 'Key',
            name: 'key',
            required: true,
            type: 'text',
          },
          {
            label: 'Data type',
            name: 'data_type_id',
            required: true,
            type: 'select',
            options: data?.dataTypes?.data ?? [],
            text: 'name',
            controller: 'id',
          },
        ]}
      />
      {isEdit && data?.property && (
        <PropertyValues
          propertyId={data?.property?.id}
          propertyType={propertyType}
        />
      )}
    </div>
  );
};

export default Put;
