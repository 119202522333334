import { Viewer } from '@react-pdf-viewer/core';
import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

interface PDFViewerProps {
  fileUrl: string;
  toggle: () => void;
  title?: string;
}

export default function PDFViewer({
  fileUrl,
  title,
  toggle,
}: PDFViewerProps): JSX.Element {
  return (
    <Modal size="lg" isOpen toggle={toggle}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        <Viewer fileUrl={fileUrl} />
      </ModalBody>
    </Modal>
  );
}
