import React from 'react';

import RichTextEditor from 'react-rte';

export default function TextEditor({
  initialValue,
  onChange,
}: {
  initialValue: string;
  onChange: (e: string) => void;
}): JSX.Element {
  const [state, setState] = React.useState(RichTextEditor.createEmptyValue());

  React.useEffect(() => {
    setState(RichTextEditor.createValueFromString(initialValue, 'html'));
  }, []);

  return (
    <RichTextEditor
      toolbarConfig={{
        display: [
          'INLINE_STYLE_BUTTONS',
          'BLOCK_TYPE_BUTTONS',
          'BLOCK_TYPE_DROPDOWN',
          'HISTORY_BUTTONS',
        ],
        INLINE_STYLE_BUTTONS: [
          { label: 'Bold', style: 'BOLD' },
          { label: 'Italic', style: 'ITALIC' },
          { label: 'Underline', style: 'UNDERLINE' },
        ],
        BLOCK_TYPE_DROPDOWN: [
          { label: 'Normal', style: 'unstyled' },
          { label: 'Heading Large', style: 'header-one' },
          { label: 'Heading Medium', style: 'header-two' },
          { label: 'Heading Small', style: 'header-three' },
        ],
        BLOCK_TYPE_BUTTONS: [
          { label: 'UL', style: 'unordered-list-item' },
          { label: 'OL', style: 'ordered-list-item' },
        ],
      }}
      value={state}
      onChange={(e) => [setState(e), onChange(e.toString('html'))]}
    />
  );
}
