import React from 'react';
import { LayoutTemplateFieldComponent, TemplateLayoutColumn } from '@wgt/types';
import { Col, Row } from 'reactstrap';
import classnames from 'classnames';
import RenderLayoutComponent from '../_organisms/RenderLayoutComponent';
import { ComponentEnum } from '../_organisms/RenderLayoutComponent/types';
import './style.scss';

export interface TemplateLayoutRowProps {
  col: TemplateLayoutColumn;
  prefix?: string;
  iRow: number;
  iColumn: number;
  hasDependencies?: boolean;
  component: ComponentEnum;
  rangeToSingle?: boolean;
  resultsForm?: boolean;
  disabled?: boolean;
  borderedError?: boolean;
  useLegacy?: boolean;
}
const TemplateLayoutRow: React.FC<TemplateLayoutRowProps> = ({
  prefix = '',
  col,
  iRow,
  iColumn,
  hasDependencies,
  component,
  rangeToSingle,
  resultsForm,
  disabled,
  borderedError,
  useLegacy,
}) => {
  return (
    <Row>
      {col.group.content.map((content, iContent) => (
        <Col
          md={!col.group.isFieldsInline && content.size}
          key={`line-${iRow}-column-${iColumn}-content-${iContent}`}
          className={classnames({
            'fields-inline': col.group.isFieldsInline,
          })}
        >
          {content.content && (
            <RenderLayoutComponent
              showAllFields={!hasDependencies}
              component={component}
              field={content.content as LayoutTemplateFieldComponent}
              prefix={prefix}
              rangeToSingle={rangeToSingle}
              resultsForm={resultsForm}
              disabled={disabled}
              borderedError={borderedError}
              useLegacy={useLegacy}
              {...(content.searchLayoutClassStyle && {
                className: content.searchLayoutClassStyle,
              })}
            />
          )}
        </Col>
      ))}
    </Row>
  );
};

export default TemplateLayoutRow;
