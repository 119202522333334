import { NavbarPageBase, HorizontalAlign } from '@wgt/types';
import { IoSettingsSharp } from 'react-icons/io5';
import {
  CgProfile,
  CgCreditCard,
  CgFileDocument,
  CgCalendarToday,
  CgMouse,
} from 'react-icons/cg';

export default [
  {
    name: 'profile-account',
    title: 'Profile Account',
    url: '/crm/profile/settings',
    icon: CgProfile,
  },
  {
    name: 'credit-cards',
    title: 'Credit Cards',
    url: '/crm/profile/creditcard',
    icon: CgCreditCard,
  },
  {
    name: 'statements',
    title: 'Statements',
    url: '/crm/statements',
    icon: CgFileDocument,
    backgroundColor: '#F2BB2B',
  },
  {
    name: 'calendar',
    title: 'Calendar',
    url: '/crm/calendar',
    icon: CgCalendarToday,
    backgroundColor: '#02FEB2',
  },
  {
    name: 'storage',
    title: 'Storage',
    url: '/crm/storage',
    icon: CgMouse,
    backgroundColor: '#FBE5C9',
  },
  {
    name: 'learn-to-use',
    title: 'Learn to use',
    url: '/crm/learn-to-use',
    backgroundColor: '#C5DCFA',
    align: HorizontalAlign.left,
    icon: IoSettingsSharp,
    isOnClick: true,
  },
] as NavbarPageBase[];
