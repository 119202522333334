import { gql } from '@apollo/client';

export const LIST_PRODUCT_GUIDES_GQL = gql`
  query productGuides($filter: ProductGuideFilterInput, $first: Int) {
    productGuides(filter: $filter, first: $first) {
      data {
        id
        file_url
        thumbnail_url
        title
        background
      }
    }
  }
`;

export const LIST_FILES = gql`
  query files($filter: FileFilterInput) {
    files(filter: $filter, first: 1000) {
      data {
        id
        file_url
        thumbnail_url
        title
        background
        productInclusionTags {
          id
          inclusionTag {
            title
          }
          position_x
          position_y
        }
      }
    }
  }
`;

export const CREATE_FILE = gql`
  mutation createFile(
    $product_id: ID!
    $file: Upload!
    $thumbnail: Upload
    $type: FileTypes
    $title: String!
  ) {
    createFile(
      product_id: $product_id
      file: $file
      thumbnail: $thumbnail
      type: $type
      title: $title
    ) {
      id
      title
      file_url
      thumbnail_url
      background
    }
  }
`;

export const DELETE_FILE = gql`
  mutation deleteFile($id: ID!) {
    deleteFile(id: $id) {
      message
    }
  }
`;
export const UPDATE_FILE = gql`
  mutation updateFile($id: ID!, $file: UpdateFileInput!) {
    updateFile(id: $id, file: $file) {
      id
    }
  }
`;

export const CREATE_MEDIA_VIDEO_GQL = gql`
  mutation createProductGuide(
    $product_id: Int!
    $laboratory: String!
    $identification: String!
    $firm_id: Int!
  ) {
    createProductReport(
      product_id: $product_id
      laboratory: $laboratory
      identification: $identification
      firm_id: $firm_id
    ) {
      id
    }
  }
`;
export const CREATE_MEDIA_GUIDE_GQL = gql`
  mutation createProductGuide(
    $product_id: ID!
    $file: Upload!
    $thumbnail: Upload!
    $title: String!
    $background: String
  ) {
    createProductGuide(
      product_id: $product_id
      file: $file
      thumbnail: $thumbnail
      title: $title
      background: $background
    ) {
      id
      title
      file_url
      thumbnail_url
      background
    }
  }
`;
export const DELETE_MEDIA_GUIDE_GQL = gql`
  mutation deleteProductGuide($id: ID!) {
    deleteProductGuide(id: $id) {
      message
    }
  }
`;
export const UPDATE_MEDIA_GUIDE_GQL = gql`
  mutation updateProductGuide($id: ID!, $guide: UpdateProductGuideInput) {
    updateProductGuide(id: $id, guide: $guide) {
      id
    }
  }
`;
export const ATTACH_PAGE_ID_TO_FILE = gql`
  mutation attachPageIdToFile($id: ID!, $page_id: ID!) {
    attachPageIdToFile(id: $id, page_id: $page_id) {
      message
    }
  }
`;
export const CREATE_PAGE_ID = gql`
  mutation createProductPageId($product_id: Int!, $page: ProductPageIdInput!) {
    createProductPageId(product_id: $product_id, page: $page) {
      id
    }
  }
`;
