import React from 'react';

import { Button } from '../..';
import WorkOrderItemForm from './WorkOrderItemForm';

import WorkOrderItem from './WorkOrderItem';
import { FaGlobe } from 'react-icons/fa';

import PageIDSelector from '../../PageIDSelector';
import useWorkOrderProduct from './useWorkOrderProduct';
import { WorkOrderItemUpdateContext } from './useWorkOrderItemUpdate';
import { useTranslation } from 'react-i18next';

export default function WorkOrderProductItemDetail(): JSX.Element {
  const [
    { product, filters, workOrderItems, loading },
    { setFilters },
  ] = useWorkOrderProduct();
  const { t } = useTranslation('workOrder');
  const onChangePageId = setFilters('pageId');

  return (
    <WorkOrderItemUpdateContext>
      <div className="col work-order-item-container mx-2">
        <div className="row">
          <div className="col-3 offset-8 d-flex">
            <span>{t('register work order')}</span>
          </div>
        </div>
        {workOrderItems.map((item, i) => (
          <WorkOrderItem item={item} key={i} />
        ))}
        {!loading && <WorkOrderItem />}

        <div className="row align-items-center">
          <WorkOrderItemForm />
          <div className="col-5 ">
            <PageIDSelector
              pages={product.pages ?? []}
              value={product.pages?.find((x) => x.id === filters.pageId)}
              onChange={(pageId) => onChangePageId(pageId.id)}
            />
          </div>
          <div className="col-3">
            <Button
              small
              iconBefore={FaGlobe}
              type="primary"
              className="btn-trace-document mt-3"
            >
              {t('Trace Document')}
            </Button>
          </div>
        </div>
      </div>
    </WorkOrderItemUpdateContext>
  );
}
