export interface ComingSoonOption {
  key: string;
  color: string;
  title: string;
  description: string[];
  sideDescriptions?: string[];
}

const comingSoonContent: ComingSoonOption[] = [
  {
    key: 'statements',
    color: '#F2BB2B',
    title: 'Statements',
    description: [
      'Very User Friendly, Statements, Tutorials. Are Going To Be Available Soon. ',
    ],
    sideDescriptions: ['Firm Purchases', 'Personal Purchases '],
  },
  {
    key: 'website-analytics',
    color: '#FCD0CF',
    title: 'Marketing',
    description: [
      'Very User Friendly, Marketing, Tutorials Are Going To Be Available Soon. ',
      'Fast and Functional website templates!',
      'You can also add your designs.',
    ],
  },
  {
    key: 'storage',
    color: '#FBE5C9',
    title: 'Storage',
    description: [
      'Very User Friendly, Storage, Tutorials Are Going To Be Available Soon. ',
      'Almost all firms have free storage unless over 1T',
    ],
  },
  {
    key: 'calendar',
    color: '#02FEB2',
    title: 'Calendar',
    description: [
      'Very User Friendly, Calendar, Tutorials Are Going To Be Available Soon. ',
      'Manage Everything',
    ],
    sideDescriptions: ['Consignements', 'Sold', 'Returns', 'ect.'],
  },
  {
    key: 'human-resources',
    color: '#000',
    title: 'Office',
    description: [
      'Very User Friendly, Office, Tutorials Are Going To Be Available Soon. ',
      'Human Resource Management',
    ],
  },
  {
    key: 'point-sales',
    color: '#000',
    title: 'Point of Sales',
    description: [
      'Very User Friendly, Point of Sales, Tutorials Are Going To Be Available Soon. ',
    ],
    sideDescriptions: [
      'Advanced Invoice Policies',
      'Consignements',
      'Sold',
      'Returns',
    ],
  },
  {
    key: 'creditcard',
    color: '#000',
    title: 'Credit Cards',
    description: [
      'Very User Friendly, Credit Cards, Tutorials Are Going To Be Available Soon. ',
    ],
    sideDescriptions: [
      'Firm Purchases',
      'Office Location',
      'Per Website',
      'Personal Purchases ',
    ],
  },
];

export default comingSoonContent;
