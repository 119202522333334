import React from 'react';
import { Pane, Button } from 'evergreen-ui';
import { gql, useApolloClient, useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { CREATE_POINT_OF_SALE_GQL } from '../../PointOfSales/graphql';
import { useBagContext } from '../../../../services/bag';
import { PointOfSalesIntents, ProductBase } from '@wgt/types';
import useToast from '../../../../hooks/useToast';

const PointOfSalesActions: React.FC = () => {
  const { t } = useTranslation('pointOfSales');
  const { state } = useLocation<{ pointOfSaleId?: number }>();

  const history = useHistory();
  const {
    selectedProducts: { list },
  } = useBagContext<{
    selectedProducts: {
      list: ProductBase[];
    };
  }>();
  const { toast } = useToast();
  const client = useApolloClient();
  const [createPointOfSale, { loading }] = useMutation(
    CREATE_POINT_OF_SALE_GQL,
    {
      onCompleted: ({ createPointOfSale: pointOfSale }) => [
        toast('success', t('Point of Sale created successfully')),
        history.push(`/crm/point-sales/${pointOfSale.id}`),
      ],
      onError: () => {
        toast('error');
      },
    },
  );

  const { data: pointOfSaleData } = useQuery(
    gql`
      query pointOfSale($id: ID!) {
        pointOfSale(id: $id) {
          products {
            id
          }
        }
      }
    `,
    {
      skip: !state?.pointOfSaleId,
      variables: {
        id: state?.pointOfSaleId,
      },
    },
  );

  const handleCreate = (intent: PointOfSalesIntents) =>
    createPointOfSale({
      variables: {
        point_of_sale: {
          products: list.map((item) => item.id),
          firm_id: 1,
          intent,
        },
      },
    });

  const handleAttach = async () => {
    if (!list.length || !state.pointOfSaleId) {
      return;
    }

    const mutation = gql`
      mutation attachProductToPointOfSale($pointOfSaleId: ID!) {
        ${list.map(
          (product) =>
            `
              product_${product.id}: attachProductToPointOfSale(id: $pointOfSaleId, product_id: ${product.id}) {
                 message
              }
          `,
        )}
      }
    `;
    try {
      const posHasProduct = pointOfSaleData.pointOfSale?.products?.reduce(
        (productsFound: number[], product: { id: string }) => {
          if (
            list
              .map((productItem) => Number(productItem.id))
              .includes(Number(product.id))
          ) {
            productsFound.push(Number(product.id));
          }

          return productsFound;
        },
        [],
      );
      if (!posHasProduct.length) {
        await client.mutate({
          mutation,
          variables: {
            pointOfSaleId: state.pointOfSaleId,
          },
        });
        toast('success', t('Product attached successfully to Point of Sale'));
        history.push(`/crm/point-sales/${state.pointOfSaleId}`);
      } else {
        toast('error', t('Product is already in point of sales '));
      }
    } catch (e) {
      toast('error', t('Error attaching Product to Point of Sale'));
    }
  };

  return (
    <Pane className="pos-actions">
      <span>{t('Point of Sales')}</span>
      <Pane className="pos-buttons">
        {(state?.pointOfSaleId && (
          <Button
            style={{ flex: 1 }}
            onClick={handleAttach}
            disabled={!list.length}
          >
            {t(`Add to Point of Sale ${state.pointOfSaleId}`)}
          </Button>
        )) ?? (
          <>
            <Button
              isLoading={loading}
              onClick={() => handleCreate('sold')}
              disabled={!list?.length}
            >
              {t('Sold')}
            </Button>
            <Button
              isLoading={loading}
              onClick={() => handleCreate('consignment')}
              disabled={!list?.length}
            >
              {t('Consignment')}
            </Button>
          </>
        )}
      </Pane>
    </Pane>
  );
};

export default PointOfSalesActions;
