import { useApolloClient } from '@apollo/client';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Title } from '../../../../../components';
import { productIdentifierFragment } from '../../graphql';
import Actions from './Actions';
import PageIDs from './PageIDs';
import SKU from './SKU';

const ProductUpdateHeader: React.FC = () => {
  const client = useApolloClient();
  const params = useParams<ParamTypes>();
  const product = client.readFragment({
    fragment: productIdentifierFragment,
    id: `Product:${params.id}`,
  });

  return (
    <div className="d-flex justify-content-between align-items-center header py-4">
      <div className="d-flex align-items-center">
        <Title heading="h1" className="mr-5">
          {product?.category?.name}
        </Title>
        <PageIDs />
      </div>
      <div>{!product?.draft && <SKU />}</div>
      <Actions />
    </div>
  );
};

export default ProductUpdateHeader;
