import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { Button } from '../../../../components';
import useFakeQuery from '../../../../hooks/useFakeQuery';
import { GET_WEBSITE_PRICES } from '../graphql';
export interface WebsitePrices {
  website: number;
  app: number;
  setup: number;
}
const servicesTextEnum = {
  website_service: 'Website',
  app_service: 'Apps',
};
export default function PaymentForm({
  isLoading,
}: {
  isLoading?: boolean;
}): JSX.Element {
  const { t } = useTranslation('website');
  const { register, watch } = useFormContext();
  const { data } = useFakeQuery<WebsitePrices>(GET_WEBSITE_PRICES, {
    defaultValue: {
      website: 150,
      app: 50,
      setup: 300,
    },
  });
  const watchServices = watch(['website_service', 'app_service']);

  const calculateTotal = (withSetup?: boolean) => {
    if (!data?.website || !data?.app || !data?.setup) {
      return 0;
    }

    if (!watchServices.app_service || !watchServices.website_service) {
      switch (Object.keys(watchServices).find((key) => !!watchServices[key])) {
        case 'website_service':
          return data?.website + (withSetup ? data.setup : 0);
        case 'app_service':
          return data?.app + (withSetup ? data.setup : 0);
        default:
          return 0;
      }
    }

    return data?.website + data?.app + (withSetup ? data?.setup : 0);
  };

  const conditionalFields = useMemo(() => {
    if (!data?.website && !data?.app) {
      return { servicesText: '', servicesTotal: 0, firstMonth: 0 };
    }
    const servicesText =
      watchServices.app_service && watchServices.website_service
        ? 'Website + Apps'
        : servicesTextEnum[
            Object.keys(watchServices).find((key) => !!watchServices[key]) ?? ''
          ] ?? '';

    return {
      servicesText,
      servicesTotal: calculateTotal(),
      firstMonth: calculateTotal(true),
    };
  }, [watchServices, data]);

  return (
    <>
      <Row>
        <Col md="8">
          <Row className="mb-4">
            <Col>{t(`Monthly payments`)}</Col>
          </Row>
          <Row className="mb-4">
            <Col className="flex-1 d-flex justify-content-between align-items-center">
              {t(`Website Template`)}: ${data?.website} USD
              <input type="checkbox" ref={register} name="website_service" />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col className="flex-1 d-flex justify-content-between align-items-center">
              {`${t('iOS + Android apps')}: $${data?.app}USD ${t('for both')}`}
              <input type="checkbox" ref={register} name="app_service" />
            </Col>
          </Row>
          {!!conditionalFields.servicesTotal && (
            <Row>
              <Col>
                <hr />
                {`${t(conditionalFields.servicesText)} $${
                  conditionalFields.servicesTotal
                } USD`}
              </Col>
            </Row>
          )}
          <Row className="mt-3">
            <Col>{`One time setup fee $${data?.setup} USD`}</Col>
          </Row>
          {!!conditionalFields.firstMonth && (
            <Row>
              <Col>
                <hr />
                {`${t(`First Month`)} $${conditionalFields.firstMonth} USD`}
              </Col>
            </Row>
          )}
        </Col>
        <Col className="d-flex align-items-end">{`${t('monthly')} $${
          conditionalFields.servicesTotal
        } USD`}</Col>
      </Row>
      <Row className="mt-5">
        <Col className="d-flex justify-content-end">
          <Button htmlType="submit" isLoading={isLoading}>
            submit
          </Button>
        </Col>
      </Row>
    </>
  );
}
