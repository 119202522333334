import React, { useMemo } from 'react';
import { Row, Col } from 'reactstrap';
import { IconButton } from 'evergreen-ui';
import { FiMoreHorizontal } from 'react-icons/fi';
import { Employee, JobTitle } from '@wgt/types';
import { concat } from '@wgt/shared';
import { Title } from '../../../../../../components';
import NoProfilePhoto from '../../../../../../assets/no-profile-photo.jpg';

interface Props {
  employee: Employee;
}

export default function EmployeeList({ employee }: Props): JSX.Element {
  const fullname = useMemo(
    () => `${employee.first_name} ${employee.last_name}`,
    [employee],
  );

  return (
    <Row className="border rounded py-2 mt-2">
      <Col lg="auto" className="pl-2 pr-0">
        <img src={NoProfilePhoto} className="img-thumbnail-small" />
      </Col>
      <Col lg="3" className="pr-0 d-flex align-items-center">
        <div>
          <Title heading="h5">{fullname}</Title>
          <p className="font-small">
            {employee.jobTitles &&
              employee.jobTitles
                .map((title: JobTitle) => title.name)
                .join(', ')}
          </p>
        </div>
      </Col>
      <Col lg="3" className="d-flex align-items-center">
        <div className="text-extended">
          <p>
            <strong>{employee.phone}</strong>
          </p>
          <p className="font-small">{employee.email}</p>
        </div>
      </Col>
      <Col lg="4" className="px-0 d-flex align-items-center">
        <div>
          <p className="font-small">
            {concat(
              employee.addresses?.[0]?.address,
              employee.addresses?.[0]?.address_2,
            )}
          </p>
          <p className="font-small">
            {concat(
              employee.addresses?.[0]?.city,
              employee.addresses?.[0]?.state,
            )}
          </p>
          <p className="font-small">
            {concat(
              employee.addresses?.[0]?.country?.name,
              employee.addresses?.[0]?.zip_code,
            )}
          </p>
        </div>
      </Col>
      <Col lg="auto" className="pl-0 ml-auto">
        <IconButton
          appearance="minimal"
          icon={FiMoreHorizontal}
          iconSize={28}
        />
      </Col>
    </Row>
  );
}
