import React from 'react';
import classnames from 'classnames';

export interface Content {
  header: string;
  content?: string;
}

interface Props {
  content: Content[];
  containerClass?: string;
}

export default function CardLayoutText({
  content,
  containerClass,
}: Props): JSX.Element {
  return (
    <div className={classnames('card-group', containerClass)}>
      {content.map((item: Content, index: number) => (
        <div className="card" key={index}>
          <div className="card-header p-2">{item.header}</div>
          <div className="card-body p-2 pr-5">{item.content}</div>
        </div>
      ))}
    </div>
  );
}
