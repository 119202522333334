import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Title from '../Title';
export default function ProfileSecurityMenu(): JSX.Element {
  const { t } = useTranslation('profile');
  const securityMenuItems = [
    { to: '/crm/profile/credit-check', title: t('Credit Check') },
    { to: '/crm/profile/criminal-check', title: t('Criminal Check') },
    { to: '/crm/profile/drug-test', title: t('Drug Test') },
    { to: '/crm/profile/personality-check', title: t('Personality Check') },
    { to: '/crm/profile/incident-report', title: t('Incident Report') },
  ];
  return (
    <div className="card p-4">
      <Title heading="h5">{t('Security')}</Title>
      <ul className="profile-security-menu">
        {securityMenuItems.map((item, key) => (
          <li key={`security-menu-${key}`}>
            <Link to={item.to}>{item.title}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
}
