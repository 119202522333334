/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, Dispatch, SetStateAction } from 'react';
import { WorkOrder, WorkOrderItem } from '@wgt/types';
import context from './WorkOrderItemContext';

interface UseWorkOrderItemFormState {
  workOrder: WorkOrder;
  workOrderItems: WorkOrderItem[];
  pageId?: string;
  activeWorkOrderItem: WorkOrderItem;
  fetchArgs: any;
}

interface UseWorkOrderItemFormActions {
  setActiveWorkOrderItemId: Dispatch<SetStateAction<string | undefined>>;
}

type UseWorkOrderItemFormContext = [
  UseWorkOrderItemFormState,
  UseWorkOrderItemFormActions,
];

export default function useWorkOrderItemFormContext(): UseWorkOrderItemFormContext {
  return useContext(context) as UseWorkOrderItemFormContext;
}
