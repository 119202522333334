import { gql } from '@apollo/client';

import {
  templateFieldFragment,
  propertyFragment,
  dataTypeFragment,
  componentFragment,
  propertyValueFragment,
  shapeFragment,
  variationValuesFragment,
  commonAttributesFragment,
  parcelTypesFragment,
  firmFragment,
  productStatusFragment,
  officeFragment,
} from './fragments';

export const PRODUCT = gql`
  query product(
    $id: ID!
    $parcelTypeFilter: ParcelTypeFilterInput
    $officeFilter: OfficeFilterInput
  ) {
    firms(filter: { types: { id: [10] } }) {
      data {
        ...firmProduct_Part
      }
    }
    parcelTypes(filter: $parcelTypeFilter) {
      data {
        ...parcelTypeIdentifier_Part
      }
    }
    productsStatus {
      data {
        ...productStatusIdentifier_Part
      }
    }
    offices(filter: $officeFilter) {
      data {
        ...office_Part
      }
    }
    product(id: $id) {
      ...productCommonAttributes_Part
      productStatus {
        ...productStatusIdentifier_Part
      }
      category {
        name
        categoryType {
          id
        }
        productType {
          id
          numberPages
        }
        template {
          layout
          result_layout
          shapes {
            ...templateShape_Part
          }
          fields {
            ...templateField_Part
            property {
              ...templateFieldProperty_Part
              dataType {
                ...templateFieldDataType_Part
              }
            }
            component {
              ...templateFieldComponent_Part
            }
            values {
              ...templateFieldPropertyValue_Part
              variations {
                id
                name
                variationValues {
                  ...templateFieldVariationValues_Part
                }
              }
            }
          }
        }
      }
      pages {
        id
        fields
        price_per_carat
        carat_weight
        name
        position
        category {
          template {
            layout
            result_layout
            shapes {
              ...templateShape_Part
            }
            fields {
              ...templateField_Part
              property {
                ...templateFieldProperty_Part
                dataType {
                  ...templateFieldDataType_Part
                }
              }
              component {
                ...templateFieldComponent_Part
              }
              values {
                ...templateFieldPropertyValue_Part
                variations {
                  id
                  name
                  variationValues {
                    ...templateFieldVariationValues_Part
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${officeFragment}
  ${firmFragment}
  ${parcelTypesFragment}

  # product fragments
  ${productStatusFragment}
  ${commonAttributesFragment}
  ${variationValuesFragment}
  ${shapeFragment}
  ${propertyValueFragment}
  ${propertyFragment}
  ${dataTypeFragment}
  ${templateFieldFragment}
  ${componentFragment}
`;
