import React, { useContext, useMemo } from 'react';
// import { useFormContext } from 'react-hook-form';
import { FormGroup } from 'reactstrap';
import TemplateLayoutDustBin from '../TemplateLayoutStructure/TemplateLayoutDustBin';
import { layoutDNDTypes } from '../../layoutDNDTypes';
import { TemplateLayoutGroupFormProps } from '../../../../types';
import {
  TemplateLayoutContext,
  SectionPropertiesProps,
} from '../../../../../../../hooks/useTemplateLayout';
import TemplateLayoutStructureItemContent from '../TemplateLayoutStructure/TemplateLayoutStructureItemContent';
import TemplateLayoutGroupTitle from './TemplateLayoutGroupTitle';
import { DroppedTemplateComponent } from '@wgt/types';

const TemplateLayoutGroupForm: React.FC<TemplateLayoutGroupFormProps> = ({
  rowNumber,
  colNumber,
  rowItem,
  section,
}) => {
  const {
    onDropToColumn,
    onEditSectionProperties,
    toggleOptionHeaderSection,
    structure,
  } = useContext(TemplateLayoutContext);
  const onDrop = (field: DroppedTemplateComponent) =>
    onDropToColumn(
      {
        ...field,
        rowNumber,
        colNumber,
      },
      section,
    );

  const handleEditSectionProperties = (values: SectionPropertiesProps) => {
    const variables = {
      ...values,
      rowNumber,
      colNumber,
      section,
    };
    onEditSectionProperties(variables);
  };

  const isSizeInvalid = useMemo(() => {
    if (!section || !structure) {
      return true;
    }

    const rows = structure[section] ?? [];

    return rows.some(
      (cols) => cols.reduce((acc, col) => Number(col.size) + acc, 0) > 12,
    );
  }, [section, structure]);

  return (
    <div className="template-layout-container-group-form">
      <FormGroup>
        <TemplateLayoutGroupTitle
          isSizeInvalid={isSizeInvalid}
          handleEditSectionProperties={handleEditSectionProperties}
          title={rowItem.group.title}
          size={rowItem.size}
          registerField={`group[${rowNumber}][${colNumber}]`}
          isCharacteristic={rowItem.group.isCharacteristic}
          isFieldsInline={rowItem.group.isFieldsInline}
          toggleIsCharacteristic={() =>
            toggleOptionHeaderSection({
              rowNumber,
              colNumber,
              value: !!rowItem.group.isCharacteristic,
              section,
              field: 'isCharacteristic',
            })
          }
          toggleIsFieldsInline={() =>
            toggleOptionHeaderSection({
              rowNumber,
              colNumber,
              value: !!rowItem.group.isFieldsInline,
              section,
              field: 'isFieldsInline',
            })
          }
        />
      </FormGroup>
      <TemplateLayoutStructureItemContent
        content={rowItem.group?.content ?? []}
        rowNumber={rowNumber}
        colNumber={colNumber}
        section={section}
      />
      <TemplateLayoutDustBin
        onDrop={onDrop}
        accept={[layoutDNDTypes.TEMPLATE_FIELD]}
      />
    </div>
  );
};
export default TemplateLayoutGroupForm;
