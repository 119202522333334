import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useProductUpdate } from '../Provider';
import ParcelTypeItem from './ParcelTypeItem';
import './styles.scss';

export interface ParcelType {
  id: number;
  title: string;
  description: string;
}
interface ParcelTypeProps {
  parcelTypes: ParcelType[];
}

const ParcelType: React.FC<ParcelTypeProps> = ({ parcelTypes }) => {
  const { t } = useTranslation(['hr', 'common']);

  const { pages } = useProductUpdate();
  const { getValues } = useFormContext();
  if (typeof pages.activeIndex === 'undefined') {
    return <React.Fragment />;
  }
  return (
    <div className="parcel-type-selector">
      <h3 className="mt-4">{t('Select parcel type')}</h3>
      <div className="parcel-type-holder">
        {parcelTypes.map((parcelType, key) => (
          <Controller
            key={`parcel-type-${key}`}
            name={`pages[${pages.activeIndex}].fields.parcel_type_id`}
            defaultValue={
              getValues(`pages[${pages.activeIndex}].fields.parcel_type_id`) ??
              ''
            }
            render={({ name, onChange }) => (
              <ParcelTypeItem
                parcelType={parcelType}
                name={name}
                onChange={onChange}
              />
            )}
          />
        ))}
      </div>
    </div>
  );
};

export default ParcelType;
