import React, { useMemo } from 'react';
import InputRangeTemplate from '../InputRangeTemplate';

interface Props {
  component: string;
}

const COMPONENTS = {
  range: InputRangeTemplate,
  fragment: React.Fragment,
};

export default function RenderLayoutComponentTemplate({
  component,
}: Props): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const Comp: any = useMemo(() => {
    return COMPONENTS[component] ?? COMPONENTS.fragment;
  }, [component]);

  return <Comp />;
}
