import { gql } from '@apollo/client';

export const PRODUCT_PAGE_ID = gql`
  fragment productPageId_Part on ProductPageId {
    id
    name
    position
    fields
    price_per_carat
    carat_weight
    shapes {
      id
      title
      value
      image_url
      image_highlight_url
      image_selected_url
      row_position
      shapeGroup {
        id
        title
      }
      descriptions {
        id
        name
        group
        display_type
        icon_url
      }
    }
    product {
      id
    }
    category {
      id
      name
      mixed
      categoryType {
        id
      }
      template {
        layout
        shapes {
          id
          title
          value
          image_url
          image_highlight_url
          image_selected_url
          row_position
          shapeGroup {
            id
            title
          }
          descriptions {
            id
            name
            group
            display_type
            icon_url
          }
        }
        fields {
          id
          label
          is_highlight
          is_required
          property {
            name
            key
            dataType {
              name
              key
            }
          }
          component {
            id
            key
            name
          }
          values {
            id
            name
            key
            options
            image
            variations {
              id
              name
              variationValues {
                id
                name
                key
                options
                image
                image_url
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_GQL = gql`
  query productPageIdPut($pageId: ID!, $categoryId: ID!, $isEdit: Boolean!) {
    productPageId(id: $pageId) @include(if: $isEdit) {
      ...productPageId_Part
    }
    category(id: $categoryId) {
      id
      name
      mixed
      categoryType {
        id
      }
      template {
        layout
        shapes {
          id
          title
          image_url
          image_selected_url
          image_highlight_url
          row_position
          shapeGroup {
            id
            title
          }
          descriptions {
            id
            name
            display_type
            group
            icon_url
          }
        }
        fields {
          id
          label
          is_highlight
          is_required
          component {
            id
            key
            name
          }
          property {
            name
            key
            dataType {
              name
              key
            }
          }
          values {
            id
            name
            key
            options
            image
            variations {
              id
              name
              variationValues {
                id
                name
                key
                options
                image
                image_url
              }
            }
          }
        }
      }
    }
  }
  ${PRODUCT_PAGE_ID}
`;

export const SEARCH_CATEGORIES_GQL = gql`
  query categories($filter: CategoryFilterInput) {
    categories(filter: $filter) {
      paginatorInfo {
        total
        currentPage
        hasMorePages
      }
      data {
        ... on Category {
          id
          name
          mixed
          categoryType {
            id
          }
          template {
            layout
            shapes {
              id
              title
              value
              image_url
              image_highlight_url
              image_selected_url
              row_position
              shapeGroup {
                id
                title
              }
              descriptions {
                id
                name
                group
                display_type
                icon_url
              }
            }
            fields {
              id
              label
              is_highlight
              is_required
              component {
                id
                key
                name
              }
              values {
                id
                name
                key
                options
                image
                variations {
                  id
                  name
                  variationValues {
                    id
                    name
                    key
                    options
                    image
                    image_url
                  }
                }
              }
              property {
                name
                key
                dataType {
                  name
                  key
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const CREATE_GQL = gql`
  mutation createProductPageId($productId: ID!, $page: ProductPageIdInput!) {
    createProductPageId(product_id: $productId, page: $page) {
      id
    }
  }
`;

export const EDIT_GQL = gql`
  mutation updateProductPageId($id: ID!, $page: UpdateProductPageIdInput!) {
    updateProductPageId(id: $id, page: $page) {
      ...productPageId_Part
    }
  }
  ${PRODUCT_PAGE_ID}
`;
