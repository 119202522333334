import { useApolloClient, useQuery } from '@apollo/client';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from '../../../../../../components';
import { productIdentifierFragment } from '../../../graphql';
import { PRODUCT } from '../../../ProductUpdateRow/graphql';
import { useProductUpdate } from '../../Provider';
import RemovePageId from './RemovePageId';
import LearnToUse from '../../../../LearnToUse';

interface UpdateProductHeaderActionsProps {
  productId?: string | null;
  loading?: boolean;
}

const UpdateProductHeaderActions: React.FC<UpdateProductHeaderActionsProps> = ({
  productId,
  loading,
}) => {
  const params = useParams<ParamTypes>();
  const client = useApolloClient();
  const {
    pages,
    createPage,
    filters,
    isFormEnabled,
    setFormEnabled,
    creatingPage,
  } = useProductUpdate();

  const productData = productId
    ? useQuery(PRODUCT, {
        variables: filters,
        fetchPolicy: 'cache-only',
      })
    : client.readFragment({
        fragment: productIdentifierFragment,
        id: `Product:${params.id}`,
      });

  const product = productData?.data?.product ?? productData;

  const isNewPageIDVisible = useMemo<boolean>(() => {
    const totalPages = product?.category?.productType?.numberPages;
    return (
      Number(product?.category?.categoryType?.id) !== 1 &&
      (totalPages === 0 || pages.fields.length < totalPages)
    );
  }, [product, pages]);

  const submitButtonLabel = useMemo(() => {
    if (isFormEnabled) {
      return product?.draft ? 'Publish Draft' : 'Finish';
    }

    return 'Edit';
  }, [isFormEnabled, product]);

  return (
    <div className="d-flex justify-content-end">
      <LearnToUse />

      {isFormEnabled && <RemovePageId />}
      {isFormEnabled && isNewPageIDVisible && (
        <Button
          type="secondary"
          onClick={() =>
            createPage({
              productId: productId ?? params.id,
              categoryId: product?.category.id,
            })
          }
          htmlType="button"
          className=" ml-3"
          isLoading={creatingPage}
          disabled={creatingPage}
        >
          CREATE NEW PAGE ID
        </Button>
      )}
      <Button
        htmlType="submit"
        className="ml-3"
        isLoading={loading}
        disabled={loading}
        onClick={(e: React.MouseEvent) =>
          !isFormEnabled && [e.preventDefault(), setFormEnabled(true)]
        }
      >
        {submitButtonLabel}
      </Button>
    </div>
  );
};

export default UpdateProductHeaderActions;
