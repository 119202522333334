import React from 'react';
import { useQuery } from '@apollo/client';
import { TabPermissionsProps } from '../../types';
import PermissionComponent from './Permission';
import { Col, Row } from 'reactstrap';
import { Permission } from '@wgt/types';
import { PERMISSIONS_TAB_SCREEN } from '../../graphql';

interface PermissionQuery {
  data: Permission[];
}

interface TabQuery {
  options: PermissionQuery;
  userPermissions: PermissionQuery;
}

const TabPermissions: React.FC<TabPermissionsProps> = ({ user }) => {
  const { data } = useQuery<TabQuery>(PERMISSIONS_TAB_SCREEN, {
    variables: {
      userId: user.id,
    },
  });

  return (
    <Row>
      {data?.options?.data.map((permission) => (
        <Col key={permission.id} md="4" xl="4" sm="4">
          <PermissionComponent
            user={user}
            permission={permission}
            hasPermission={data?.userPermissions.data.some(
              (perm) => perm.id === permission.id,
            )}
          />
        </Col>
      ))}
    </Row>
  );
};

export default TabPermissions;
