import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { Button } from '..';

export default function ProfileActionsMenu(): JSX.Element {
  const { t } = useTranslation('profile');
  return (
    <div className="card p-4">
      <Row>
        <Col className="d-flex justify-content-between" md="12">
          <Button type="white" small>
            {t('Profile')}
          </Button>
          <Button type="white" small>
            {t('Home')}
          </Button>
        </Col>
        <hr className="col" />
      </Row>
      <Row>
        <Col className="d-flex justify-content-between" md="12">
          <Button type="white" small>
            {t('Add Address')}
          </Button>
          <Button type="danger-outline" small>
            {t('Emergency Content')}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-end mt-5" md="12">
          <Button type="white" small>
            {t('Security')}
          </Button>
        </Col>
      </Row>
    </div>
  );
}
