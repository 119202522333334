import React from 'react';
import { useTranslation } from 'react-i18next';
import StoragePrices from '../../../components/StoragePrices';
import StorageUsage from '../../../components/StorageUsage';
import StorageDetails from '../../../components/StorageDetails';

import { Col, Container, Row } from 'reactstrap';

const Storage: React.FC = () => {
  const { t } = useTranslation('storage');
  return (
    <Container className="mt-5">
      <div className="m-5">
        {t('Store your videos, photos and documents')}
        <StorageUsage />
      </div>
      <StoragePrices />
      <div className="ml-5">
        <Row>
          <Col sm="4">
            <div className="mr-3">
              <StorageDetails />
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default Storage;
