import React from 'react';
import useCrmRoutes from '../../../hooks/useCrmRoutes';
import List from './List';
import Details from './Details';
import Put from './Put';
import InternalRoutes from '../../../components/_organisms/InternalRoutes';

const Properties: React.FC = () => {
  const { isCrmInternalRoute, actualRoute } = useCrmRoutes();

  const routes = [
    {
      component: Put,
      name: 'Properties',
      path: `/crm/${actualRoute}/create`,
    },
    {
      component: Details,
      exact: true,
      name: 'Properties',
      path: `/crm/${actualRoute}/:id`,
    },
    {
      component: Put,
      name: 'Properties',
      path: `/crm/${actualRoute}/:id/edit`,
    },
  ];

  if (isCrmInternalRoute) {
    return <InternalRoutes routes={routes} />;
  }

  return <List />;
};

export default Properties;
