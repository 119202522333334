import { gql, useQuery } from '@apollo/client';
import { InvoiceBase } from '@wgt/types';
import React, { useState } from 'react';
import { Button } from '../../../components';
import Loader from '../../../components/_organisms/Loader';
import useMoneris from '../../../hooks/useMoneris';

export default function MonerisTest(): JSX.Element {
  const [invoiceId, setInvoiceId] = useState('');
  const { data } = useQuery<{ invoice: InvoiceBase }>(
    gql`
      query invoice($id: ID!) {
        invoice(id: $id) {
          id
          total_open_payments
        }
      }
    `,
    {
      skip: !invoiceId,
      variables: { id: invoiceId },
    },
  );

  const [ref, { checkout, loading }] = useMoneris({
    invoiceId,
    onPageLoad: console.log,
    onCancel: console.log,
    onError: console.log,
    onPaymentReceipt: console.log,
    onPaymentComplete: console.log,
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { invoice } = e.target as typeof e.target & {
      invoice: { value: string };
    };

    setInvoiceId(invoice.value);
  };

  return (
    <div style={{ height: '100vh' }}>
      {!!data?.invoice && (
        <Button
          htmlType="button"
          type="blue"
          onClick={() =>
            checkout({
              variables: {
                total: data?.invoice.total_open_payments,
              },
            })
          }
        >
          Pay
        </Button>
      )}
      <form onSubmit={handleSubmit}>
        <div className="d-flex mt-2">
          <div className="form-group mr-2">
            <input
              type="text"
              placeholder="invoice id"
              name="invoice"
              className="form-control"
            />
          </div>
          <Button htmlType="submit">Search invoice</Button>
        </div>
      </form>
      {loading && <Loader />}
      <div ref={ref} style={{ height: '100%' }} />
    </div>
  );
}
