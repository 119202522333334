import React from 'react';
import classnames from 'classnames';
import { LayoutComponentBase, VariationBase } from '@wgt/types';
import VariationItem from './VariationItem';

interface VariationsProps {
  isOpen: boolean;
  setOpen(isOpen: boolean): void;
  variations: VariationBase[];
  field?: string;
  dataType?: LayoutComponentBase;
  propertyField?: string;
}

export default React.memo(function TemplateLayoutCharacteristicVariations({
  isOpen,
  variations,
  field,
  dataType,
  propertyField,
}: VariationsProps) {
  return (
    <div
      className={classnames('template-characteristic-value-variations', {
        hidden: !isOpen,
      })}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {variations.map((variation) => (
        <div key={variation.id}>
          <h5>{variation.name}</h5>
          <ul className="template-characteristic-value-variations-content">
            {variation.variationValues?.map((variationValue) => (
              <VariationItem
                key={variationValue.id}
                variationValue={variationValue}
                field={`${field}`}
                dataType={dataType}
                propertyField={propertyField}
              />
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
});
