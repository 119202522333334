import { gql, useMutation } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import useToggler from '@wgt/shared/hooks/useToggler';
import { Button } from '../../../../components';
import ConfirmDialog from '../../../../components/_molecules/ConfirmDialog';
import { PRODUCT_PAGES_GQL } from '../ProductPut/graphql';

export default function ProductPageIdRemoveButton({
  small,
  pageId,
  productId,
  categoryId,
}: {
  small?: boolean;
  pageId?: string | number;
  productId?: string;
  categoryId?: string;
}): JSX.Element {
  const { t } = useTranslation('product');
  const history = useHistory();
  const [isConfirmationOpen, toggleConfirmation] = useToggler();
  const params = useParams<{
    categoryId: string;
    productId: string;
    pageId: string;
  }>();
  const [_delete, { loading }] = useMutation(DELETE_GQL, {
    onCompleted: () => [
      history.push(
        `/crm/categories/${categoryId ?? params.categoryId}/products/${
          productId ?? params.productId
        }`,
      ),

      toggleConfirmation(),
    ],
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: PRODUCT_PAGES_GQL,
        variables: {
          id: params.productId,
        },
      },
    ],
    variables: {
      id: pageId ?? params.pageId,
    },
  });

  return (
    <>
      <Button
        type="danger"
        className="ml-3"
        small={small}
        disabled={loading}
        onClick={toggleConfirmation}
        isLoading={loading}
      >
        {t('Remove')}
      </Button>

      <ConfirmDialog
        onClose={() => toggleConfirmation(false)}
        onConfirm={_delete}
        isLoading={loading}
        isShown={isConfirmationOpen}
        title={t(`Are you sure you want to delete?`)}
      />
    </>
  );
}

const DELETE_GQL = gql`
  mutation deleteProductPageId($id: ID!) {
    deleteProductPageId(id: $id) {
      message
    }
  }
`;
