import React, { useState } from 'react';
import { Label } from 'reactstrap';
import { FaCircle } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import Button from '../Button';
import ColorPicker from '../ColorPicker';

interface ColorButtonProps {
  field: string;
  label: string;
  buttonDescription?: string;
}

export default function ColorButton({
  field,
  label,
  buttonDescription,
}: ColorButtonProps): JSX.Element {
  const { t } = useTranslation('common');

  const [colorPopover, setColorPopover] = useState<boolean>(false);
  const toggleColorPopover = () => setColorPopover(!colorPopover);

  const { getValues } = useFormContext();

  return (
    <div>
      <Label>{label}</Label>
      <Button
        htmlType="button"
        onClick={toggleColorPopover}
        className="button-color"
      >
        {!buttonDescription && t('Choose a Color')}
        <span className="color" style={{ color: getValues(field) }}>
          <FaCircle />
        </span>
      </Button>
      {!!colorPopover && (
        <div className="color-popover">
          <div className="color-popover-overlay" onClick={toggleColorPopover} />
          <ColorPicker name={field} />
        </div>
      )}
    </div>
  );
}
