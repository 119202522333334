import React, { useState } from 'react';
import { Row, Col, Container, FormGroup, Label, Input } from 'reactstrap';
import { IoMdPause } from 'react-icons/io';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { useFormContext, Controller } from 'react-hook-form';
import classnames from 'classnames';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import { Button, SimpleInput } from '../';
import {
  GET_WORK_ORDER_ITEMS_WITH_SCHEDULE,
  UPDATE_WORK_ORDER_ITEM,
} from './graphql';
import useWorkOrderItemFormContext from './useWorkOrderItemContext';
import useToast from '../../hooks/useToast';

interface WorkOrderItemInput {
  worker: {
    id: string;
  };
  workOrderType: {
    id: string;
  };
  executiveBuyer: {
    id: string;
  };
  pageid: {
    id: string;
  };

  date: Date;
  start: string;
  end: string;
  hour_cost: number;
  hour_price: number;
  currency_id: number;
  arrival_date?: Date;
  return_date?: Date;
  details?: string;
  notes?: string;
  time?: string;
  time_spent?: string;
  id?: string;
}

export default function WorkOrderItemFormContent(): JSX.Element {
  const [timer, setTimer] = useState<boolean>(false);
  const { t } = useTranslation(['pointOfSales', 'common']);
  const { toast } = useToast();
  const { register, handleSubmit, errors, getValues } = useFormContext();
  const [{ activeWorkOrderItem, fetchArgs }] = useWorkOrderItemFormContext();
  const [mutate] = useMutation(UPDATE_WORK_ORDER_ITEM, {
    refetchQueries: [
      { query: GET_WORK_ORDER_ITEMS_WITH_SCHEDULE, variables: fetchArgs },
    ],
    onCompleted: () => toast('success'),
  });

  const onSubmit = handleSubmit((variables: WorkOrderItemInput) => {
    const start = moment(variables.start).format('Y-M-D HH:mm:ss');
    const end = moment(variables.end).format('Y-M-D HH:mm:ss');

    const values = {
      id: activeWorkOrderItem.id,
      workOrderItem: {
        worker_id: variables.worker.id,
        workordertype_id: activeWorkOrderItem.workOrderType.id,
        pageid_id: variables.pageid?.id,
        executive_buyer_id: variables.executiveBuyer.id,
        start,
        end,
        hour_price: Number(variables.hour_price ?? 0),
        details: variables.details,
      },
    };
    mutate({
      variables: values,
    });
  });

  return (
    <Container fluid className="pb-4 pt-2">
      <Row>
        <Col xs="12" lg="4" className="d-flex align-items-center">
          <FormGroup className="simple-input box-field-work-order w-100 mt-3 mt-lg-0">
            <div className="w-100 px-0 px-xl-3">
              <Label className="d-block text-truncate">
                {t('Estimated Start Date')}
              </Label>
              <Controller
                name="start"
                rules={{
                  required: true,
                }}
                defaultValue={getValues('start')}
                render={({ onChange, value }) => (
                  <DatePicker
                    dateFormat="MMMM d, yyyy h:mm aa"
                    showTimeSelect
                    className={classnames('form-control', {
                      'required-border': errors.start,
                    })}
                    selected={value}
                    onChange={(dateSelected: Date) => onChange(dateSelected)}
                  />
                )}
              />
            </div>
          </FormGroup>
        </Col>

        <Col
          xs="12"
          lg="4"
          className="d-flex align-items-center mb-3 mt-2 mt-lg-0 mb-lg-0"
        >
          <FormGroup className="simple-input box-field-work-order w-100">
            <div className="w-100 px-0 px-xl-3">
              <Label className="d-block text-truncate">
                {t('Estimated Finish Date')}
              </Label>
              <Controller
                name="end"
                rules={{
                  required: true,
                }}
                defaultValue={getValues('end')}
                render={({ onChange, value }) => (
                  <DatePicker
                    dateFormat="MMMM d, yyyy h:mm aa"
                    showTimeSelect
                    className={classnames('form-control', {
                      'required-border': errors.end,
                    })}
                    selected={value}
                    onChange={(dateSelected: Date) => onChange(dateSelected)}
                  />
                )}
              />
            </div>
          </FormGroup>
        </Col>

        <Col xs="12" lg="4">
          <FormGroup className="simple-input h-100 d-flex flex-column">
            <Label className="d-block">{t('common:Notes')}</Label>
            <Input
              type="textarea"
              name="notes"
              placeholder={`${t('common:Notes')}...`}
              className="work-order-notes"
              innerRef={register()}
            />
          </FormGroup>
        </Col>
      </Row>

      <Row className="mt-0 mt-xl-3">
        <Col
          xs={{ order: 3, size: 6 }}
          lg={{ order: 0, size: 2 }}
          className="mt-3 mt-lg-0"
        >
          <div className="ml-0 ml-xl-3">
            {!timer && (
              <Button htmlType="button" onClick={onSubmit}>
                {t('common:Finish')}
              </Button>
            )}
          </div>
        </Col>

        <Col xs={{ order: 0, size: 12 }} md="6" lg="4">
          <div className="d-flex flex-row align-items-center">
            <Label className="mb-0 text-nowrap">{t('Total Price')}</Label>
            <div className="mx-3 w-100">
              <SimpleInput
                field="hour_price"
                name="hour_price"
                type="number"
                required
              />
            </div>
            <Button htmlType="button" small>
              {t('common:Add')}
            </Button>
          </div>
        </Col>

        <Col xs={{ order: 0, size: 12 }} md="6" lg="4" className="mt-3 mt-md-0">
          <div className="d-flex flex-row align-items-center">
            <Label className="mb-0 text-nowrap">{t('Add Time')}</Label>
            <div className="mx-3 w-100">
              <SimpleInput field="time" name="time" type="time" required />
            </div>
            <Button htmlType="button" small>
              {t('common:Add')}
            </Button>
          </div>
        </Col>

        <Col
          xs={{ order: 4, size: 6 }}
          lg="2"
          className="mt-3 mt-lg-0 text-right"
        >
          {timer ? (
            <Button
              iconBefore={IoMdPause}
              type="danger"
              className="btn-time-tracking"
              onClick={() => setTimer(false)}
            >
              00:00:08
            </Button>
          ) : (
            <Button htmlType="button" onClick={() => setTimer(true)}>
              {t('Start Timer')}
            </Button>
          )}
        </Col>
      </Row>
    </Container>
  );
}
