import { gql } from '@apollo/client';

export const inclusionTagFragment = gql`
  fragment inclusion_Part on InclusionTag {
    id
    icon
    title
  }
`;

export const LIST_TAGS = gql`
  query listTags {
    inclusionTags(first: 100) {
      data {
        ...inclusion_Part
      }
    }
  }
  ${inclusionTagFragment}
`;

export const CREATE = gql`
  mutation createProductInclusion(
    $product_id: ID!
    $image: Upload!
    $title: String!
    $background: String
  ) {
    createProductInclusion(
      product_id: $product_id
      image: $image
      title: $title
      background: $background
    ) {
      id
      title
      image_url
      background
    }
  }
`;
export const DELETE = gql`
  mutation deleteProductInclusion($id: ID!) {
    deleteProductInclusion(id: $id) {
      message
    }
  }
`;
export const UPDATE = gql`
  mutation updateProductInclusion(
    $id: ID!
    $inclusion: UpdateProductInclusionInput
  ) {
    updateProductInclusion(id: $id, inclusion: $inclusion) {
      id
    }
  }
`;

export const ATTACH_TAG = gql`
  mutation createProductInclusionTag(
    $file_id: ID!
    $inclusion_tag_id: ID!
    $position_x: Float!
    $position_y: Float!
  ) {
    createProductInclusionTag(
      file_id: $file_id
      inclusion_tag_id: $inclusion_tag_id
      position_x: $position_x
      position_y: $position_y
    ) {
      id
    }
  }
`;

export const DETACH_TAG = gql`
  mutation deleteProductInclusionTag($id: ID!) {
    deleteProductInclusionTag(id: $id) {
      message
    }
  }
`;
