/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';

import Loader from '../../../../components/_organisms/Loader';
import InvoiceDetails from '../../../../components/InvoiceDetails';

const GET_INVOICE_GQL = gql`
  query invoice($id: ID!) {
    invoice(id: $id) {
      id
      snapshot
      pointOfSale {
        intent
        firm {
          id
          name
          website
          vatcode
          address {
            address
            number
            phone
            postal_code
            city
            state
            country {
              name
            }
          }
          extra {
            logo_url
          }
        }
        executiveBuyer {
          employments {
            id
            name
            website
            vatcode
            address {
              address
              number
              phone
              postal_code
              city
              state
              country {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export default function AmlStatement(): JSX.Element {
  const { ready } = useTranslation(['invoicesReport', 'pointOfSales']);

  const params = useParams<ParamTypes>();

  const { data, loading } = useQuery(GET_INVOICE_GQL, {
    variables: {
      id: params.id,
    },
  });

  const invoiceData = useMemo(() => {
    return data?.invoice ?? {};
  }, [data?.invoice]);

  if (!ready || loading) {
    return <Loader />;
  }

  return (
    <Container className="my-3" id="aml-statement">
      <InvoiceDetails invoice={invoiceData} type="aml" />
    </Container>
  );
}
