import React from 'react';
import classnames from 'classnames';
import { SimpleInput } from '../../../../components';

interface SearchInputFields {
  field: string;
  placeholder?: string;
}

interface SearchInputFieldProps {
  label: string;
  fields: SearchInputFields[];
  className?: string;
  type?: string;
}

const CommonSearchInputField: React.FC<SearchInputFieldProps> = ({
  label,
  fields,
  className,
  type,
}) => {
  return (
    <div className={classnames({ 'input-group-between': fields.length > 0 })}>
      {fields.map((field, key) => (
        <SimpleInput
          type={type}
          key={`search-field-${key}`}
          field={field.field}
          placeholder={field.placeholder}
          className={classnames(className, { 'ml-2': key % 2 !== 0 })}
          {...(key === 0 && { label })}
        />
      ))}
    </div>
  );
};

export default CommonSearchInputField;
