/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  FetchResult,
  MutationFunctionOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client';
import { WorkOrderItem } from '@wgt/types';
import React, { createContext, useContext, useState } from 'react';
import { CREATE_WORK_ORDER_ITEM } from '../graphql';
const context = createContext({});

interface WorkOrderItemUpdateContextProps {
  children: JSX.Element;
}
export function WorkOrderItemUpdateContext({
  children,
}: WorkOrderItemUpdateContextProps): JSX.Element {
  const [item, setItem] = useState<WorkOrderItem>();
  const [createItem, { loading: creating }] = useMutation(
    CREATE_WORK_ORDER_ITEM,
  );

  return (
    <context.Provider
      value={[
        { item, creating },
        { setItem, createItem },
      ]}
    >
      {children}
    </context.Provider>
  );
}

interface WorkOrderItemUpdateState {
  item?: WorkOrderItem;
  creating?: boolean;
}

interface WorkOrderItemUpdateHandlers {
  setItem: React.Dispatch<React.SetStateAction<WorkOrderItem | undefined>>;
  createItem: (
    options?: MutationFunctionOptions<any, OperationVariables> | undefined,
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
}

type UseWorkOrderItemUpdate = [
  WorkOrderItemUpdateState,
  WorkOrderItemUpdateHandlers,
];

export default function useWorkOrderItemUpdate(): UseWorkOrderItemUpdate {
  return useContext(context) as UseWorkOrderItemUpdate;
}
