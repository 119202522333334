import { gql } from '@apollo/client';

export const LIST_POLICIES_GQL = gql`
  query policiesQuery($filter: PoliciesFilterInput, $first: Int) {
    policies(filter: $filter, first: $first) {
      data {
        id
        title
        description
        expiry_date
        net_day_count
        firm {
          id
          name
        }
        type {
          id
          name
        }
      }
    }
  }
`;

export const GET_POLICY_GQL = gql`
  query policyQuery($id: ID!) {
    policy(id: $id) {
      id
      title
      description
      expiry_date
      net_day_count
      firm {
        id
        name
      }
      type {
        id
        name
      }
    }
  }
`;

export const CREATE_POLICY_GQL = gql`
  mutation createPolicyMutation(
    $title: String!
    $description: String!
    $expiry_date: Date!
    $net_day_count: Int
    $favorite: Boolean
    $firm_id: ID!
    $policy_type_id: ID
  ) {
    createPolicy(
      title: $title
      description: $description
      expiry_date: $expiry_date
      net_day_count: $net_day_count
      favorite: $favorite
      firm_id: $firm_id
      policy_type_id: $policy_type_id
    ) {
      id
    }
  }
`;

export const UPDATE_POLICY_GQL = gql`
  mutation updatePolicyMutation($id: ID!, $policy: UpdatePropertyInput) {
    updatePolicy(id: $id, policy: $policy) {
      id
    }
  }
`;

export const DELETE_POLICY_GQL = gql`
  mutation deletePolicyValue($id: ID!) {
    deletePolicy(id: $id) {
      message
    }
  }
`;

export const LIST_POLICY_TYPES_GQL = gql`
  query policyTypesQuery($filter: PolicyTypeFilterInput) {
    policyTypes(filter: $filter) {
      data {
        id
        name
      }
    }
  }
`;
