import React from 'react';
import { SideSheet, Position, SideSheetProps } from 'evergreen-ui';
import './styles.scss';

interface TopModalProps extends SideSheetProps {
  isShown?: boolean;
}

const TopModal: React.FC<TopModalProps> = ({ children, ...props }) => {
  return (
    <SideSheet position={Position.TOP} {...props}>
      <div className="top-modal">
        <div className="top-modal-content">{children}</div>
      </div>
    </SideSheet>
  );
};

export default TopModal;
