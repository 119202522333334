import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Text, Badge } from '../../';

export default function Amounts(): JSX.Element {
  const { t } = useTranslation('pointOfSales');
  return (
    <Container className="payment-resume-total">
      <Row>
        <Col xs={{ size: 4, offset: 1 }}>
          <div className="total">
            <Text className="label">{t('Total')}</Text>
            <Text className="value">0,00</Text>
          </div>
          <div className="total-due mt-3">
            <Text className="label">{t('Total due')}</Text>
            <Text className="value">0,00</Text>
          </div>
        </Col>
        <Col xs={{ size: 3 }} className="d-flex align-items-end pb-2">
          =
        </Col>
        <Col xs={{ size: 3 }}>
          <div className="paid">
            <Text className="label">
              {t('Paid')}
              <Badge numberProp={Number(1)} className="d-inline-block ml-2" />
            </Text>

            <Text className="value">0,00</Text>
          </div>

          <div className="planned mt-3">
            <Text className="label">{t('Planned')}</Text>
            <Text className="value">0,00</Text>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
