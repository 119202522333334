import React from 'react';

import Content from './Content';
import MediaCenterContent from '../../MediaCenterContent';
import { useBagContext } from '../../../../../../../services/bag';
import { MediaCenterController } from '../../../../../../../hooks/useMediaCenterController';
import {
  CREATE_FILE,
  LIST_FILES,
  DELETE_FILE,
  UPDATE_FILE,
} from '../../graphql';
import { useTranslation } from 'react-i18next';

export default function MediaCenterSlider(): JSX.Element {
  const { media } = useBagContext<MediaCenterController>();
  const { t } = useTranslation('mediaCenter');
  const type = 'video';

  return (
    <MediaCenterContent
      content={() =>
        Content({
          type,
        })
      }
      hasThumbnail
      deleteGql={DELETE_FILE}
      refetchQuery={LIST_FILES}
      updateGql={UPDATE_FILE}
      createGql={CREATE_FILE}
      acceptFiles="video/*"
      filesDescription={t('Video Files')}
      type={type}
      {...media}
    />
  );
}
