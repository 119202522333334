import { MediaCenterItemBase, ProductBase } from '@wgt/types';
import React, { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaFileAlt } from 'react-icons/fa';
import { createPortal } from 'react-dom';
import { Modal, ModalBody, ModalHeader, Col, Row } from 'reactstrap';
import { Viewer } from '@react-pdf-viewer/core';
import { gql, useQuery } from '@apollo/client';
import { useProductUpdate } from '../../pages/Crm/Products/Update/Provider';

const CommonAttributes: React.FC = () => {
  const { getValues } = useFormContext<ProductBase>();
  const { t } = useTranslation(['product', 'common']);
  const { pages } = useProductUpdate();
  const [reportOpen, setReportOpen] = useState<MediaCenterItemBase | null>(
    null,
  );

  const { data } = useQuery(
    gql`
      query files($filter: FileFilterInput) {
        files(filter: $filter) {
          data {
            id
            file_url
            thumbnail_url
            title
            type
          }
        }
      }
    `,
    {
      variables: {
        filter: {
          pages: {
            id: [pages.active?.id],
          },
          type: 'guide',
        },
      },
      skip: !pages.active?.id,
    },
  );

  const product = getValues();
  const reports: MediaCenterItemBase[] = useMemo(
    () => data?.files?.data ?? [],
    [data],
  );
  return (
    <>
      {reportOpen?.file_url &&
        createPortal(
          <Modal
            size="lg"
            isOpen={!!reportOpen.file_url}
            toggle={() => setReportOpen(null)}
          >
            <ModalHeader toggle={() => setReportOpen(null)}>
              {`${reportOpen.title}`}
            </ModalHeader>
            <ModalBody>
              <Viewer fileUrl={reportOpen.file_url} />
            </ModalBody>
          </Modal>,
          document.getElementById('wgt-modal') ?? document.createElement('div'),
        )}
      <Row>
        <Col>
          <p>
            {t('Name')} <b>{product.name}</b>
          </p>
          <p>
            {t('Description')} <b>{product.description}</b>
          </p>
        </Col>
        <Col>
          <p>
            {t('Total Carat Weight')} <b>{pages.active?.carat_weight}</b>
          </p>
          <p>
            {t('Price Per Carat')} <b>{pages.active?.price_per_carat}</b>
          </p>
        </Col>
        <Col>
          <p>
            {t('Status')} <b>{product.productStatus.name}</b>
          </p>

          {!!reports?.length && (
            <p>
              {t('Reports')}{' '}
              {reports?.map((report, key) => (
                <button
                  type="button"
                  className="download-btn"
                  key={`product-report-${key}`}
                  onClick={() => setReportOpen(report)}
                >
                  <FaFileAlt />
                  {t('common:View')}
                </button>
              ))}
            </p>
          )}
        </Col>
      </Row>
    </>
  );
};

export default CommonAttributes;
