import React from 'react';
import CrateItem from './CrateItem';
import { useBagContext } from '../../../../../../services/bag';
import { CrateId } from '@wgt/types';
import { Row, Col } from 'reactstrap';

const CrateIds: React.FC = () => {
  const {
    crate: { fields },
  } = useBagContext<{
    crate: { fields: CrateId[] };
  }>();

  return (
    <Row className="simple-input">
      {fields.map((crateId, index) => (
        <Col key={`crate-${index}`} md="6">
          <CrateItem crate={crateId as CrateId} index={index} />
        </Col>
      ))}
    </Row>
  );
};

export default CrateIds;
