import { gql } from '@apollo/client';

export const GET_OFFICE_TYPES_GQL = gql`
  query {
    __type(name: "OfficeType") {
      enumValues {
        name
      }
    }
  }
`;

export const GET_OFFICE_CATEGORIES_GQL = gql`
  query categories {
    categories(first: 1000) {
      data {
        id
        name
      }
    }
  }
`;

export const CREATE_OFFICE_GQL = gql`
  mutation createOffice(
    $name: String!
    $address: String!
    $address_2: String
    $phone: String
    $postal_code: String
    $city: String!
    $state: String!
    $country_id: ID!
    $firm_id: ID!
    $types: [OfficeType]!
    $category_ids: [ID]
  ) {
    createOffice(
      name: $name
      address: $address
      address_2: $address_2
      phone: $phone
      postal_code: $postal_code
      city: $city
      state: $state
      country_id: $country_id
      firm_id: $firm_id
      types: $types
      category_ids: $category_ids
    ) {
      id
    }
  }
`;
