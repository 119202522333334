import { TransactionType } from '@wgt/types';
import React from 'react';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import useStatementsContext, { StatementsFilters } from './useStatements';

function StatementsTypesItem({
  type,
}: {
  type: TransactionType;
  onSubmit: SubmitHandler<StatementsFilters>;
}) {
  const { setValue } = useFormContext();
  const { fetch } = useStatementsContext();

  return (
    <button
      type="button"
      onClick={() => {
        setValue('transactionTypes', [type]);
        setTimeout(() => fetch());
      }}
    >
      <span>{type.name}</span>
      {type.description}
    </button>
  );
}

export default function StatementsTypes(): JSX.Element {
  const { transactionTypes, onSubmit } = useStatementsContext();
  const first = [
    'sold',
    'sold return',
    'purchase',
    'purchase return',
    'consigment sent',
    'consignment return',
    'consignment sent',
    'consignment received',
    'broker',
  ];
  return (
    <>
      <div className="statements-footer-items">
        {transactionTypes
          .filter((item) => first.includes(item.name.toLowerCase()))
          .map((type, key) => (
            <StatementsTypesItem
              key={`footer-type-${key}`}
              type={type}
              onSubmit={onSubmit}
            />
          ))}
      </div>
      <div className="statements-footer-items">
        {transactionTypes
          .filter((item) => !first.includes(item.name.toLowerCase()))
          .map((type, key) => (
            <StatementsTypesItem
              key={`footer-type-${key}`}
              type={type}
              onSubmit={onSubmit}
            />
          ))}
      </div>
    </>
  );
}
