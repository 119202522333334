/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  DocumentNode,
  OperationVariables,
  QueryHookOptions,
} from '@apollo/client';
import { useState, useEffect } from 'react';

interface DataType<T> {
  data?: T;
  loading?: boolean;
  refetch: (variables?: any) => void;
}

interface QueryFakeHookOptions<T>
  extends QueryHookOptions<T, OperationVariables> {
  defaultValue: T;
}

export default function useFakeQuery<T>(
  gql: DocumentNode,
  options: QueryFakeHookOptions<T>,
): DataType<T> {
  const [loading, setLoading] = useState(true);
  const refetch = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return {
    data: options?.defaultValue,
    loading,
    refetch,
  };
}

// HOW TO USE
// import { Category } from '@wgt/types';

// const defaultValue: Category = {
//   name: 'Diamonds',
//   categoryType: { id: 1, name: 'Stone' },
// };

// const { data, loading, refetch } = useFakeQuery<Category>(
//   gql`
//     query hello {
//       hello
//     }
//   `,
//   {
//     defaultValue,
//   },
// );
