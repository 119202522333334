import React, { memo } from 'react';
import { Avatar } from 'evergreen-ui';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import { WorkOrderItem } from '@wgt/types';
import { URGENCIES } from '@wgt/shared/constants';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useWorkOrderItemUpdate from './useWorkOrderItemUpdate';
import useWorkOrderProduct from './useWorkOrderProduct';
import useWorkOrderSchedule from '../useWorkOrderSchedule';
import { LIST_WORK_ORDER_ITEMS_BY_PAGE } from '../graphql';
import { Button } from '../..';
import SelectWorkOrderTypeTree from '../../SelectWorkOrderTypeTree';

interface WorkOrderItemProps {
  item?: WorkOrderItem;
}

export default memo(function WorkOrderItemComponent({
  item,
}: WorkOrderItemProps): JSX.Element {
  const [, { createItem }] = useWorkOrderItemUpdate();
  const [{ workOrder, scheduleId, workOrderId }] = useWorkOrderSchedule();
  const [{ filters, product }] = useWorkOrderProduct();

  const methods = useForm({
    defaultValues: { ...item, workordertype_id: item?.workOrderType.id },
  });

  const { t } = useTranslation('workOrder');
  const { errors, getValues, control } = methods;
  const history = useHistory();

  const onOpenWork = methods.handleSubmit(() => {
    if (!item?.id) {
      createItem({
        variables: {
          urgency: URGENCIES[0].key,
          workorder_id: workOrder?.id,
          workordertype_id: getValues('workordertype_id'),
          status: 'active',
          pageid_id: filters.pageId,
          start: moment()
            .add(1, 'hour')
            .startOf('hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          end: moment()
            .add(1, 'hour')
            .endOf('hour')
            .format('YYYY-MM-DD HH:mm:ss'),
          date: moment().format('YYYY-MM-DD'),
        },
        refetchQueries: [
          {
            query: LIST_WORK_ORDER_ITEMS_BY_PAGE,
            variables: {
              filter: {
                pageid: { id: filters.pageId },
                ...(!!scheduleId
                  ? { schedule: { id: [scheduleId] } }
                  : { workOrder: { id: [workOrderId] } }),
              },
            },
          },
        ],
      });
      return;
    }
    history.push(
      `/crm/products/${product.id}/edit/workOrders/${workOrder?.id}/items/${item.id}?pageId=${filters.pageId}`,
    );
  });

  return (
    <div className="row align-items-center">
      <div className="col-1">
        <Avatar
          src="http://www.sparrowdg.com/images/naturaldiamond.png"
          size={35}
        />
      </div>
      <div className="col-3">
        <div className="row">
          <div className="col-3">
            <span className="font-weight-bold">{t('Start')}</span>
          </div>
          <div className="col px-0">
            <span className="text-sm">
              {(item?.start &&
                moment(item?.start).format('D MMM YYYY h:mm a')) ??
                '---'}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <span className="font-weight-bold ">{t('End')}</span>
          </div>
          <div className="col px-0">
            <span className="text-sm">
              {(item?.end && moment(item?.end).format('D MMM YYYY h:mm a')) ??
                '---'}
            </span>
          </div>
        </div>
      </div>
      <div className="col">
        <Button type="secondary" htmlType="button" small onClick={onOpenWork}>
          {t('Open Work')}
        </Button>
      </div>
      <div className="col-5">
        <Controller
          defaultValue={getValues('workordertype_id') ?? ''}
          name="workordertype_id"
          rules={{ required: true }}
          control={control}
          render={({ onChange, value }) => (
            <>
              <SelectWorkOrderTypeTree
                field="workordertype_id"
                onChange={onChange}
                valueSeleteced={value}
                isDisabled={!!item?.id}
              />
              {errors?.workordertype_id && (
                <span className="text-danger">{t('required')}</span>
              )}
            </>
          )}
        />
      </div>
    </div>
  );
});
