/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Row, Col, Label } from 'reactstrap';
import { useFormContext } from 'react-hook-form';

interface Props {
  items: any;
  field: string;
}

export default function ItemsSelect({ items, field }: Props): JSX.Element {
  const { getValues, setValue, clearErrors } = useFormContext();

  const handleCheck = (item: any) => {
    const fieldValues = getValues(field);
    const index = fieldValues.indexOf(item.id);
    let values = [];
    if (index !== -1) {
      fieldValues.splice(index, 1);
      values = fieldValues;
    } else {
      values = [...fieldValues, item.id];
    }
    setValue(field, values);
    clearErrors(field);
  };

  return (
    <Row>
      {items.map((item: any) => (
        <Col
          md="4"
          key={item.id}
          className="d-flex justify-content-between mb-2"
        >
          <Label className="d-flex align-items-center" htmlFor={item.id}>
            {item.name}
          </Label>
          <div className="d-flex align-items-center">
            <input
              type="checkbox"
              name="types"
              id={item.id}
              onChange={() =>
                handleCheck({
                  id: item.id,
                })
              }
              defaultChecked={getValues(field).includes(item.id)}
            />
          </div>
        </Col>
      ))}
    </Row>
  );
}
