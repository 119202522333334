import { useMutation } from '@apollo/client';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import StateManager from 'react-select';
import { Button, ButtonSelector } from '../../../../../components';
import SelectSearch from '../../../../../components/SelectSearch';
import useToast from '../../../../../hooks/useToast';
import { Shape, ShapeGroup } from '@wgt/types';
import {
  ATTACH_SHAPE,
  GET_TEMPLATE_SHAPES_GQL,
  LIST_SHAPES_GQL,
} from './graphql';

const ShapesSelector: React.FC<{ shapeGroups: ShapeGroup[] }> = ({
  shapeGroups,
}) => {
  const shapeRef = useRef<StateManager<Shape> | null>(null);
  const params = useParams<ParamTypes>();
  const { t } = useTranslation('template');
  const { toast } = useToast();
  const [selectedShapeGroup, setSelectedShapeGroup] = useState<
    number | undefined
  >();
  const [attachShapeToTemplate, { loading }] = useMutation(ATTACH_SHAPE, {
    refetchQueries: [
      {
        query: GET_TEMPLATE_SHAPES_GQL,
        variables: {
          id: params.id,
        },
      },
    ],
    onCompleted: () => [
      toast('success', t('Shape successfully created')),
      shapeRef.current?.setState({ value: null }),
    ],
  });

  const associate = () => {
    if (!shapeRef.current?.state.value) {
      return;
    }

    const selected = shapeRef.current?.state.value as Shape;

    attachShapeToTemplate({
      variables: {
        id: params?.id,
        shape_id: selected.id,
      },
    });
  };

  return (
    <>
      <ButtonSelector
        options={shapeGroups}
        active={selectedShapeGroup}
        setActive={(active: number) =>
          setSelectedShapeGroup(
            active !== selectedShapeGroup ? active : undefined,
          )
        }
        controller={{ key: 'id', text: 'title' }}
      />

      <div className="shapes-list">
        <label>{t('Find a shape to use in this template')}</label> <br />
        <SelectSearch
          gql={LIST_SHAPES_GQL}
          name="title"
          getOptionLabel={(e) => `${e.title ?? ''} `}
          parseResults={(shapes) =>
            shapes.map((shape: Shape) => ({
              ...shape,
              title: `${shape.title} (${shape.shapeGroup.title})`,
            }))
          }
          getOptionValue={(e) => `${e.id}`}
          ref={shapeRef}
          parseVariables={(variables) => ({
            ...variables,
            filter: {
              ...variables.filter,
              shapeGroup: selectedShapeGroup
                ? {
                    id: [selectedShapeGroup],
                  }
                : undefined,
            },
          })}
          triggerQuery={selectedShapeGroup}
        />
        <Button
          htmlType="button"
          onClick={associate}
          disabled={loading}
          isLoading={loading}
          className="mt-3"
        >
          {t('Attach')}
        </Button>
      </div>
    </>
  );
};

export default ShapesSelector;
