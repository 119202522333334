import React, { useState, useMemo } from 'react';
import {
  Row,
  Col,
  UncontrolledPopover,
  PopoverBody,
  FormGroup,
  Label,
  Input,
  Form,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/client';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { PolicyType, Policy } from '@wgt/types';
import {
  Button,
  SimpleInput,
  SimpleDropdown,
  Text,
} from '../../../../../../../components';
import {
  LIST_POLICY_TYPES_GQL,
  CREATE_POLICY_GQL,
  LIST_POLICIES_GQL,
} from '../graphql';
import Loader from '../../../../../../../components/_organisms/Loader';
import { useForm, FormProvider } from 'react-hook-form';
import moment from 'moment';
import useLoggedContext from '../../../../../../../hooks/useLoggedContext';
import useToast from '../../../../../../../hooks/useToast';

interface InputPolicy extends Policy {
  day?: number;
  month?: number;
  year?: number;
  policy_type_id?: number;
}

export default function CreatePolicy(): JSX.Element {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const toggle = () => setPopoverOpen(!popoverOpen);
  const { t } = useTranslation(['pointOfSales', 'common']);
  const { active } = useLoggedContext();
  const { toast } = useToast();

  const methods = useForm<InputPolicy>({
    defaultValues: {
      year: moment().year(),
      month: moment().month() + 1,
      day: moment().date(),
    },
  });

  const { errors, handleSubmit, register, setError } = methods;

  const { data, loading } = useQuery<{ policyTypes: { data: PolicyType[] } }>(
    LIST_POLICY_TYPES_GQL,
  );

  const policyTypes = useMemo(() => data?.policyTypes?.data ?? [], [
    data?.policyTypes?.data,
  ]);

  const [create, { loading: updating }] = useMutation(CREATE_POLICY_GQL, {
    onCompleted: () => [
      toast('success', t('common:Record Successfully Saved')),
      methods.reset({
        year: moment().year(),
        month: moment().month() + 1,
        day: moment().date(),
      }),
      setPopoverOpen(false),
    ],
    refetchQueries: [
      {
        query: LIST_POLICIES_GQL,
        variables: {
          first: 1000,
          filter: {
            firm: { id: [active?.id] },
          },
        },
      },
    ],
    onError: () =>
      toast('error', t('common:Something went wrong. Please try again later')),
  });

  const onSubmit = ({
    day,
    month,
    year,
    title,
    net_day_count,
    policy_type_id,
  }: InputPolicy) => {
    const expiryDate = moment(`${year}-${month}-${day}`, 'YYYY-M-D', true);
    if (!policy_type_id) {
      return setError('policy_type_id', { type: 'required' });
    }
    if (!expiryDate.isValid()) {
      return [
        setError('day', { type: 'pattern' }),
        setError('month', { type: 'pattern' }),
        setError('year', { type: 'pattern' }),
      ];
    }

    create({
      variables: {
        title,
        description: title,
        net_day_count: Number(net_day_count),
        policy_type_id: Number(policy_type_id),
        expiry_date: expiryDate.format('YYYY-MM-DD'),
        firm_id: Number(active?.id),
      },
    });
  };

  const { months, days } = useMemo(
    () => ({
      months: moment.months().map((name, index) => ({
        id: index + 1,
        name: `${name.charAt(0).toUpperCase()}${name.substr(1)}`,
      })),
      days: [...new Array(31)].map((_, index) => ({
        id: index + 1,
        name: (index + 1).toString().padStart(2, '0'),
      })),
    }),
    [],
  );

  if (loading) {
    return (
      <div className="d-flex justify-content-center mt-3">
        <Loader round />
      </div>
    );
  }

  return (
    <FormProvider {...methods}>
      <Button type="combobox" id="createNewPolicyPopover">
        <div className="d-flex justify-content-between w-100">
          <div>{t('Create new policy')}</div>
          <div>{popoverOpen ? <FiChevronUp /> : <FiChevronDown />}</div>
        </div>
      </Button>
      <UncontrolledPopover
        trigger="legacy"
        placement="bottom"
        isOpen={popoverOpen}
        target="createNewPolicyPopover"
        toggle={toggle}
        className="popover-create-new-policy"
      >
        <PopoverBody>
          <Form onSubmit={handleSubmit(onSubmit)} noValidate>
            <SimpleInput
              required
              field="title"
              label={t('common:Title')}
              className="input-small"
              placeholder={t('common:Description')}
            />
            <Row className="mt-2">
              <Col>
                <Text className="expiry-date-label">
                  {t('Expiry Date')}
                  {errors?.expiry_date && (
                    <Text className="text-danger"> ({t('Invalid Date')})</Text>
                  )}
                </Text>
                <Row>
                  <Col xs="4">
                    <SimpleDropdown
                      defaultValue="1"
                      controller="id"
                      text="name"
                      field="day"
                      options={days}
                      label={t('Day')}
                      required
                      className="input-small label-small"
                    />
                  </Col>
                  <Col xs="4" className="px-0">
                    <SimpleDropdown
                      defaultValue="1"
                      controller="id"
                      text="name"
                      field="month"
                      options={months}
                      label={t('Month')}
                      required
                      className="input-small label-small"
                    />
                  </Col>
                  <Col xs="4">
                    <SimpleInput
                      required
                      field="year"
                      label={t('Year')}
                      className="input-small label-small"
                      placeholder={t('Year')}
                      type="number"
                      min={0}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs="6" className="d-flex flex-row  pr-0 align-items-end">
                <div className="title mr-2">{t('Policy Type')}</div>
                <Button small htmlType="submit" isLoading={updating}>
                  {t('common:Add')}
                </Button>
              </Col>
              <Col xs="6">
                <SimpleInput
                  required
                  field="net_day_count"
                  label={t('Net Day Count')}
                  className="input-small label-small"
                  defaultValue="1"
                  type="number"
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs="12">
                {policyTypes.map((policyType: PolicyType) => (
                  <FormGroup check key={policyType.id} className="mt-1">
                    <Label check>
                      <Input
                        type="radio"
                        name="policy_type_id"
                        defaultValue={policyType.id}
                        innerRef={register()}
                      />{' '}
                      {policyType.name}
                    </Label>
                  </FormGroup>
                ))}
              </Col>
            </Row>
          </Form>
        </PopoverBody>
      </UncontrolledPopover>
    </FormProvider>
  );
}
