import React from 'react';
import PageID from './PageID';
import SKU from './SKU';
import { BsEye } from 'react-icons/bs';
import { Title } from '../../../../../components';
import { useTranslation } from 'react-i18next';

const Searcher: React.FC = () => {
  const { t } = useTranslation('products');
  return (
    <div>
      <Title heading="h3">
        <BsEye /> {t('Add Page ID')}
      </Title>

      <div className="sku-form mt-3 ml-4">
        <PageID />
        <SKU />
      </div>
    </div>
  );
};

export default Searcher;
