import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Container } from 'reactstrap';
import { Button, Title } from '../../../../components';
import Loader from '../../../../components/_organisms/Loader';

import useCsvImportContext, { CsvImportProvider } from '../useCsvImport';
import ImportModal from './ImportModal';
import ImportProductsTable from './ImportProductsTable';

function ImportCategoryContent(): JSX.Element {
  const {
    loadingCategory,
    setModalOpen,
    isModalOpen,
    category,
  } = useCsvImportContext();
  const { t } = useTranslation('import');
  return (
    <>
      <ImportModal />
      <Container className="mt-5" fluid>
        <div className="d-flex align-items-center justify-content-between">
          <Title>
            {category?.name} {loadingCategory && <Loader round />}
          </Title>
          {!loadingCategory && !isModalOpen && (
            <Button
              htmlType="button"
              type="white"
              onClick={() => setModalOpen(true)}
            >
              {t('Import')}
            </Button>
          )}
        </div>
        <ImportProductsTable />
      </Container>
    </>
  );
}

export default function ImportCategory(): JSX.Element {
  const params = useParams<{ id: string }>();

  return (
    <CsvImportProvider categoryId={params.id}>
      <ImportCategoryContent />
    </CsvImportProvider>
  );
}
