import { gql } from '@apollo/client';

export const GET_PAYMENT = gql`
  query payment($id: ID!) {
    payment(id: $id) {
      id
      amount
      field_values
      planned
      calculate_interest
      payment_compound
      payment_not_compound
      interest
      interest_after
      scheduled_date
      status
      invoice {
        id
      }
      receipts {
        id
        files {
          url
          id
          filename
        }
      }
    }
  }
`;
