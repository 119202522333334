import React from 'react';
import { FaBars } from 'react-icons/fa';
import { MediaCenterController } from '../../../../../../hooks/useMediaCenterController';
import { useBagContext } from '../../../../../../services/bag';

import ZoomController from './ZoomController';

const ButtonControllers: React.FC = () => {
  const { isExpanded, setExpanded } = useBagContext<MediaCenterController>();
  return (
    <div className="d-flex align-items-center justify-content-between m-3">
      {isExpanded ? <ZoomController /> : <div />}
      <div
        onClick={() => setExpanded(!isExpanded)}
        className="button-expand-media-center"
      >
        {isExpanded ? 'Expanded' : 'Collapsed'} <FaBars />
      </div>
    </div>
  );
};

export default ButtonControllers;
