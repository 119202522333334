import React from 'react';
import BoxItem from './BoxItem';
import { useBagContext } from '../../../../../../services/bag';
import { BoxId } from '@wgt/types';
import { Col, Row } from 'reactstrap';

const CrateIds: React.FC = () => {
  const {
    box: { fields },
  } = useBagContext<{
    box: { fields: BoxId[] };
  }>();

  return (
    <Row className="simple-input">
      {fields.map((boxId, index) => (
        <Col key={`box-${index}`} md="6">
          <BoxItem box={boxId as BoxId} index={index} />
        </Col>
      ))}
    </Row>
  );
};

export default CrateIds;
