import { gql } from '@apollo/client';

export interface AuthorizationProps {
  access_token: string;
  token_refresh: string;
  expires_in: number;
}

export const accessTokenFragment = gql`
  fragment accessToken_Part on AuthTokenType {
    access_token
  }
`;

export const refreshTokenFragment = gql`
  fragment refreshToken_Part on AuthTokenType {
    token_refresh
  }
`;

export const currentTokenFragment = gql`
  fragment tokens_Part on AuthTokenType {
    ...accessToken_Part
    ...refreshToken_Part
  }

  ${accessTokenFragment}
  ${refreshTokenFragment}
`;

export const loginMutation = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      ...tokens_Part
      token_type
    }
  }

  ${currentTokenFragment}
`;

// Authorization: Bearer ${refreshToken}
export const refreshTokenMutation = gql`
  mutation refreshToken {
    refreshToken {
      ...accessToken_Part
      ...refreshToken_Part
      token_type
    }
  }

  ${accessTokenFragment}
  ${refreshTokenFragment}
`;

export const currentAuthQuery = gql`
  query authorization {
    authorization {
      ...tokens_Part
    }
  }
  ${currentTokenFragment}
`;
