import React from 'react';
import { Col, Row } from 'reactstrap';

import {
  SimpleInput,
  SimpleTextArea,
  SimpleDropdown,
} from '../../../../../../components';
import { useProductUpdate } from '../../../Update/Provider';
import { ProductStatus } from '../../../types';
import { useTranslation } from 'react-i18next';

interface CommonAttributesProps {
  statuses: ProductStatus[];
}

const CommonAttributes: React.FC<CommonAttributesProps> = ({ statuses }) => {
  const { pages, isFormEnabled } = useProductUpdate();
  const { t } = useTranslation(['product', 'common']);

  const PAGE = `pages[${pages.activeIndex}]`;
  const PER_CARAT = `${PAGE}.price_per_carat`;
  const CARAT_WEIGHT = `${PAGE}.carat_weight`;
  const TOTAL_PRICE = `${PAGE}.total_price`;

  return (
    <Row>
      <Col>
        <SimpleDropdown
          controller="id"
          text="name"
          label={t('Status')}
          field="product_status_id"
          options={statuses}
          disabled={!isFormEnabled}
        />
        <SimpleTextArea
          field="description"
          label={t('Description')}
          placeholder={t('common:Text')}
          disabled={!isFormEnabled}
          required
        />
        <SimpleInput
          field="name"
          label={t('Search Name')}
          placeholder={t('common:Text')}
          disabled={!isFormEnabled}
          required
        />
      </Col>

      <Col>
        {typeof pages.activeIndex !== 'undefined' && (
          <>
            <SimpleInput
              field={TOTAL_PRICE}
              label={t('Total Price')}
              placeholder={t('common:Number')}
              required
            />
            <SimpleInput
              field={PER_CARAT}
              label={t('Total Carat Weight')}
              placeholder={t('common:Number')}
              disabled={!isFormEnabled}
              type="number"
              required
            />
            <SimpleInput
              field={CARAT_WEIGHT}
              label={t('Price per Carat')}
              placeholder={t('common:Number')}
              disabled={!isFormEnabled}
              type="number"
              required
            />
          </>
        )}
      </Col>
    </Row>
  );
};

export default CommonAttributes;
