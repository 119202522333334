import React from 'react';
import { useFormContext } from 'react-hook-form';
import classnames from 'classnames';
import { CrateId } from '@wgt/types';
import { useProductUpdate } from '../../../Update/Provider';
import { Pane } from 'evergreen-ui';

interface CrateItemProps {
  crate: CrateId;
  index: number;
}

export default function CrateItem({
  crate,
  index,
}: CrateItemProps): JSX.Element {
  const { register, errors } = useFormContext();
  const { isFormEnabled } = useProductUpdate();

  return (
    <Pane marginBottom="10px">
      <label>{`Crate ID ${index + 1}`}</label>
      <input
        type="hidden"
        name={`crates[${index}].id`}
        ref={register()}
        defaultValue={crate.id}
        disabled={!isFormEnabled}
      />
      <input
        name={`crates[${index}].name`}
        ref={register()}
        defaultValue={crate.name}
        className={classnames('form-control w-100', {
          'is-invalid': errors.crates?.[index]?.name,
        })}
        disabled={!isFormEnabled}
      />
    </Pane>
  );
}
