import React, { useMemo } from 'react';
import { PageId, PointOfSaleProduct } from '@wgt/types';
import { Row, Col } from 'reactstrap';
import { FiEdit } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import { IconButton } from 'evergreen-ui';
import { Button } from '../../../../../../../components';
import ProductIds from './ProductIds';
import PosProductReports from './PosProductReports';
import ItemActionButtons from './ItemActionButtons';
import { useFormContext } from 'react-hook-form';
import { useProductUpdate } from '../../../../../Products/Update/Provider';
import PosProductShapes from './PosProductShapes';

export interface ProductType extends PointOfSaleProduct {
  pages: PageId[];
}

const ProductDetails: React.FC = () => {
  const { t } = useTranslation(['product', 'common']);
  const { getValues } = useFormContext<ProductType>();
  const { pages } = useProductUpdate();
  const product = getValues();

  const totalCaratWeight: number = product.pages.reduce(
    (total, pageId) => total + (pageId?.carat_weight ?? 0),
    0,
  );

  const pageSelected: PageId = useMemo(
    () => product.pages[pages?.activeIndex ?? 0] ?? {},
    [pages?.activeIndex],
  );

  return (
    <>
      <div className="position-relative">
        <Row className="point-of-sale-collapse-content">
          <Col xs="5">
            <dl className="row">
              <dt className="col-sm-3 text-right">{t('common:Name')}</dt>
              <dd className="col-sm-9">{product.name}</dd>
              <dt className="col-sm-3 text-right">{t('common:Description')}</dt>
              <dd className="col-sm-9">{product.description}</dd>
            </dl>
          </Col>
          <Col xs="3">
            <dl className="row">
              <dt className="col-sm-7 text-right">{t('Total Carat Weight')}</dt>
              <dd className="col-sm-5">{totalCaratWeight}</dd>
              <dt className="col-sm-7 text-right">{t('Price per Carat')}</dt>
              <dd className="col-sm-5">{pageSelected.price_per_carat}</dd>
              <dt className="col-sm-7 text-right">{t('Length')}</dt>
              <dd className="col-sm-5">{pageSelected.fields?.length}</dd>
              <dt className="col-sm-7 text-right">{t('Width')}</dt>
              <dd className="col-sm-5">{pageSelected.fields?.length}</dd>
              <dt className="col-sm-7 text-right">{t('Depth')}</dt>
              <dd className="col-sm-5">{pageSelected.fields?.depth}</dd>
            </dl>
          </Col>
          <Col xs="4">
            <dl className="row">
              <dt className="col-sm-3 text-right">{t('common:Status')}</dt>
              <dd className="col-sm-9">{product.productStatus.name}</dd>
              <PosProductShapes shapes={pageSelected.shapes ?? []} />
              <PosProductReports reports={product.reports ?? []} />
            </dl>
          </Col>
          <Col xs="12" className="text-center">
            <Button type="link" className="btn-load-more">
              {t('common:Click to load more...')}
            </Button>
          </Col>
        </Row>

        <IconButton
          appearance="minimal"
          intent="danger"
          icon={FiEdit}
          className="btn-edit-common-attributes"
        />
      </div>
      <ProductIds />
      <ItemActionButtons product={product} />
    </>
  );
};

export default ProductDetails;
