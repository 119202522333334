import React, { useState } from 'react';
import { Pane, Tab, Tablist } from 'evergreen-ui';
import TemplateLayoutCharacteristicValues from './PropertyValues';
import classnames from 'classnames';
import './styles.scss';
import { TemplateLayoutColumn } from '@wgt/types';
export interface TemplateLayoutCharacteristicProps {
  col: TemplateLayoutColumn;
  prefix?: string;
  disabled?: boolean;
}

const TemplateLayoutCharacteristic: React.FC<TemplateLayoutCharacteristicProps> = ({
  col,
  prefix,
  disabled,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className="template-characteristic">
      <Tablist>
        {col.group.content
          .filter((x) => !!x.content?.label)
          .map((x, iHeader) => (
            <Tab
              key={`characteristic-selector-${iHeader}`}
              id={`characteristic-selector-${iHeader}`}
              onSelect={() => !disabled && setActiveIndex(iHeader)}
              className={classnames({
                selected: iHeader === activeIndex,
              })}
            >
              {x.content?.label}
            </Tab>
          ))}
      </Tablist>
      {col.group.content.map((groupContent, iContent) => (
        <Pane
          key={`tab-${iContent}`}
          id={`characteristic-selector-${iContent}`}
          role="tabpanel"
          aria-hidden={iContent !== activeIndex}
          display={iContent === activeIndex ? 'block' : 'none'}
        >
          <TemplateLayoutCharacteristicValues
            content={groupContent}
            prefix={prefix}
            disabled={disabled}
          />
        </Pane>
      ))}
    </div>
  );
};

export default TemplateLayoutCharacteristic;
