import React, { useContext, useState } from 'react';
import { TabContent, Nav, NavItem, NavLink, TabPane } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaTrash, FaTimes } from 'react-icons/fa';
import { useMutation } from '@apollo/client';
import { TemplateFieldsContext } from '../../../../../../../hooks/useTemplateFieldsContext';
import ActiveItemForm from './ContentTab/Form';
import DependenciesTab from './DependenciesTab';
import { Button, Text } from '../../../../../../../components';
import {
  DELETE_TEMPLATE_FIELD_GQL,
  GET_TEMPLATE_DETAILS_GQL,
} from '../../../../graphql';
import ConfirmDialog from '../../../../../../../components/_molecules/ConfirmDialog';
import VariationsTab from './VariationsTab';
import useToast from '../../../../../../../hooks/useToast';
import PropertyValuesTab from './PropertyValuesTab';

const ActiveItem: React.FC = () => {
  const { active, select } = useContext(TemplateFieldsContext);
  const params = useParams<ParamTypes>();
  const [activeTab, setActiveTab] = useState('content');
  const [isConfirmingDelete, setConfirmDelete] = useState(false);
  const { toast } = useToast();
  const { t } = useTranslation('template');
  const mutationOptions = {
    refetchQueries: [
      {
        query: GET_TEMPLATE_DETAILS_GQL,
        variables: { id: params.id },
      },
    ],
    onCompleted: () => [toast('success', t('Field saved')), select(null)],
  };

  const [deleteTemplateField, { loading: deletingTemplateField }] = useMutation(
    DELETE_TEMPLATE_FIELD_GQL,
    {
      ...mutationOptions,
      variables: {
        id: active?.id,
      },
      onCompleted: () => [
        toast('success', t('Field removed')),
        setConfirmDelete(false),
        select(null),
      ],
    },
  );

  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <>
      <ConfirmDialog
        isShown={isConfirmingDelete}
        onConfirm={deleteTemplateField}
        isLoading={deletingTemplateField}
        title={t('Confirm delete this field?')}
        onClose={() => setConfirmDelete(false)}
      />

      <div className="template-put-sidebar-tab">
        <div className="template-put-sidebar-tab-header  d-flex flex-column">
          <div className="template-put-sidebar-tab-title d-flex align-items-center justify-content-between">
            <Button small type="secondary" onClick={() => select(null)}>
              <FaTimes />
            </Button>

            <h4 className="text-center">
              {t(`${active?.id ? 'Edit' : 'Create'} Template Field`)}
            </h4>
            {active?.id ? (
              <Button
                small
                type="danger"
                onClick={() => setConfirmDelete(true)}
              >
                <FaTrash />
              </Button>
            ) : (
              <div />
            )}
          </div>
          <Nav tabs>
            <NavItem>
              <NavLink
                active={activeTab === 'content'}
                onClick={() => toggle('content')}
              >
                {t('Content')}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                disabled={!active?.id}
                active={activeTab === 'property_values'}
                onClick={() => toggle('property_values')}
              >
                {t('Property Values')}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                disabled={!active?.id}
                active={activeTab === 'settings'}
                onClick={() => toggle('settings')}
              >
                {t('Dependencies')}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                disabled={!active?.id}
                active={activeTab === 'variations'}
                onClick={() => toggle('variations')}
              >
                {t('Variations')}
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        {!active?.id && (
          <div className="d-flex justify-content-center align-items-center">
            <Text type="muted" className="font-italic">
              {t(
                'You need to save template field before set its dependencies and variations',
              )}
            </Text>
          </div>
        )}
        <div>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="content">
              <ActiveItemForm />
            </TabPane>
            <TabPane tabId="property_values">
              {active?.id && activeTab === 'property_values' && (
                <PropertyValuesTab />
              )}
            </TabPane>
            <TabPane tabId="settings">
              {active?.id && activeTab === 'settings' && <DependenciesTab />}
            </TabPane>
            <TabPane tabId="variations">
              {active?.id && activeTab === 'variations' && <VariationsTab />}
            </TabPane>
          </TabContent>
        </div>
      </div>
    </>
  );
};

export default ActiveItem;
