/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import setCurrencyMaskInput from '../../helpers/CurrencyMaskInput';
import classnames from 'classnames';
import './styles.scss';

interface PriceInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  title: string;
  field?: string;
  muted?: string;
  displayText?: string;
  onKeyUp?: () => void;
}

const PriceInput: React.FC<PriceInputProps> = ({
  title,
  field,
  muted,
  displayText,
  onKeyUp,
  disabled,
}) => {
  const { getValues } = useFormContext();

  const setPrice = (onChange: (...e: any[]) => void) => (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = setCurrencyMaskInput(e.target.value);
    onChange(value);
    onKeyUp?.();
  };

  return (
    <div className="price-holder">
      <div className="title">{title}</div>
      <div className={classnames('content', { disabled })}>
        {displayText && <div>{displayText}</div>}
        {!displayText && field && (
          <Controller
            name={field}
            defaultValue={getValues(field) ?? '0.00'}
            render={({ value, onChange }) => (
              <input
                type="text"
                value={value}
                onChange={setPrice(onChange)}
                placeholder="0.00"
                disabled={disabled}
              />
            )}
          />
        )}
        {muted && <span className="text-sup">{muted}</span>}
      </div>
    </div>
  );
};

export default PriceInput;
