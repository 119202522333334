import { useContext, useMemo } from 'react';
import { RoutesContext, Route } from './routesContext';
import { useLocation } from 'react-router-dom';
import { ProductBase } from '@wgt/types';

interface InitialRoute extends Route {
  // eslint-disable-next-line @typescript-eslint/ban-types
  component: React.FC<{}>;
  exact?: boolean | undefined;
}

interface InitialRoutesProps {
  main: Route;
  routesTree: InitialRoute[];
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useCrmRoutes() {
  const {
    routes,
    productRoutes,
    addCustomName,
    setInitialRoutes: setInitialRoutesFn,
    setCustomProductRoutes,
    customRoute,
    setCustomRoutes,
    customRoutes,
  } = useContext(RoutesContext);
  const location = useLocation();

  function setCustomTitle(title: string) {
    if (routes && routes.length) {
      const lastRoute = routes[routes?.length - 1];
      if (addCustomName) {
        addCustomName({ path: location.pathname, title, name: lastRoute.name });
      }
    }
  }

  function removeCustomTitle() {
    if (routes && routes.length) {
      if (addCustomName) {
        addCustomName({ title: undefined });
      }
    }
  }
  function setProductRoutes(product: ProductBase | null) {
    if (!product) {
      return setCustomProductRoutes([]);
    }
    return setCustomProductRoutes([
      {
        name: 'products',
        path: `/crm/search/products`,
        title: '',
      },
      {
        name: product.category?.categoryGroup?.name ?? '-',
        path: `/crm/category-groups/${product.category?.categoryGroup?.id}`,
        title: '',
      },

      {
        name: product.category.name,
        path: `/crm/products/${product.id}/edit`,
        title: '',
      },
    ]);
  }

  function setInitialRoutes({ main, routesTree }: InitialRoutesProps) {
    if (setInitialRoutesFn) {
      setInitialRoutesFn([
        main,
        ...routesTree.map((route) => ({
          name: route.name,
          path: route.path,
        })),
      ]);
    }
  }

  const isInternalRoute = useMemo(() => {
    return !!location?.pathname?.split('/')[2];
  }, [location]);

  const isCrmInternalRoute = useMemo(() => {
    return !!location?.pathname?.split('/')[3];
  }, [location]);

  const isCrmInternalSubRoute = useMemo(() => {
    return !!location?.pathname?.split('/')[5];
  }, [location]);

  const actualRoute = useMemo(() => {
    return location.pathname.split('/')[2];
  }, [location]);

  const actualSubRoute = useMemo(() => {
    return location.pathname.split('/')[3];
  }, [location]);

  return {
    actualRoute,
    actualSubRoute,
    customRoute,
    isCrmInternalRoute,
    isCrmInternalSubRoute,
    isInternalRoute,
    removeCustomTitle,
    routes,
    productRoutes,
    setCustomTitle,
    setInitialRoutes,
    setProductRoutes,
    setCustomRoutes,
    customRoutes,
  };
}
