import React from 'react';
import { useFormContext } from 'react-hook-form';
import './styles.scss';
import classnames from 'classnames';
import { getProp } from '../../utils/methods';
import { LayoutComponentFormValues } from '@wgt/types';
export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  field: string;
  label?: string;
  parent?: string;
  hint?: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  registerProps?: object;
  templateField?: LayoutComponentFormValues;
}

const SimpleInput: React.FC<InputProps> = ({
  field,
  label,
  hint,
  registerProps,
  parent,
  className,
  required,
  defaultValue,
  ...props
}) => {
  const { register, errors, getValues } = useFormContext();

  const fieldRegistered = parent ? `${parent}[${field}]` : field;

  const htmlFor = parent ? `label-${parent}-${field}` : `label-${field}`;

  const checkIsValid = parent
    ? !!errors && !!errors[parent] && !!errors[parent][field]
    : !!errors && !!errors[field];

  return (
    <div className={classnames('simple-input', className)}>
      {label && <label htmlFor={htmlFor}>{label}</label>}
      <input
        {...props}
        className={classnames('form-control', {
          'is-invalid': getProp(errors, fieldRegistered) || checkIsValid,
        })}
        id={htmlFor}
        name={fieldRegistered}
        ref={register({
          required: {
            message: 'Field required',
            value: required || false,
          },
          ...registerProps,
        })}
        defaultValue={defaultValue ?? getValues(fieldRegistered)}
        aria-invalid={
          getProp(errors, fieldRegistered) || checkIsValid ? 'true' : 'false'
        }
      />
      {!!hint && <span>{hint}</span>}
    </div>
  );
};

export default SimpleInput;
