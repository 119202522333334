import React from 'react';
import { useApolloClient } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { CollapseProvider } from '../../../../../services/collapse';
import { productIdentifierFragment } from '../../graphql';
import { useProductUpdate } from '../Provider';
import ListItem from './ListItem';
import './styles.scss';

const ProductPageList: React.FC = () => {
  const { pages } = useProductUpdate();
  const params = useParams<ParamTypes>();
  const client = useApolloClient();
  const product = client.readFragment({
    fragment: productIdentifierFragment,
    id: `Product:${params.id}`,
  });

  const pageIds = pages.fields.map((x) => x.__id);

  if (
    Number(product.category.categoryType.id) === 1 ||
    !pages?.fields?.length
  ) {
    return <></>;
  }

  return (
    <div className="mt-4 product-page-list">
      <div className="header-page-list d-flex align-items-center py-3 pl-4 rounded mb-3">
        <p className="mb-0">Page ID</p>
      </div>
      <CollapseProvider options={pageIds}>
        {pages.fields?.map((page, index) => (
          <ListItem key={page.__id ?? `page-${index}`} page={page} />
        ))}
      </CollapseProvider>
    </div>
  );
};

export default ProductPageList;
