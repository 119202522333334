/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from '@apollo/client';
import { CategoryBase } from '@wgt/types';
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { CATEGORY_GQL } from './grahpql';
import csv from 'csv';
import xlsx from 'xlsx';
import {
  convertCSVArrayIntoJSON,
  validateImportedProducts,
} from '../../../utils/importMethods';

interface CsvImportContextData {
  isModalOpen?: boolean;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  loadingCategory: boolean;
  category?: CategoryBase;
  file: File | null;
  setFile: Dispatch<SetStateAction<File | null>>;
  jsonFile?: Record<string, any>[] | null;
  products?: Record<string, any>[] | null;
  errors: any[];
  schema?: any;
}

export const CsvImportContext = React.createContext<CsvImportContextData>(
  {} as CsvImportContextData,
);

export const CsvImportProvider: React.FC<{ categoryId: string }> = ({
  children,
  categoryId,
}) => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [jsonFile, setJsonFile] = useState<Record<string, any>[] | null>();

  const { data, loading: loadingCategory } = useQuery<{
    category: CategoryBase;
  }>(CATEGORY_GQL, {
    variables: {
      id: categoryId,
    },
    skip: !categoryId,
    onCompleted: () => setModalOpen(true),
  });

  useEffect(() => {
    if (!file?.name) {
      return;
    }

    const reader = new FileReader();
    if (file.type === 'text/csv' || file.name.includes('.csv')) {
      reader.onload = () => {
        csv.parse(
          reader.result as Buffer,
          (_: any, processedFileData: [[string]]) => {
            const convertedCSVJsonFile = convertCSVArrayIntoJSON(
              processedFileData,
            );
            setJsonFile(convertedCSVJsonFile);
            setModalOpen(false);
          },
        );
      };

      reader.readAsBinaryString(file);
    } else if (file.type.includes('spreadsheet')) {
      reader.onload = (evt) => {
        const bstr = evt?.target?.result;
        if (bstr) {
          const wb = xlsx.read(bstr, { type: 'binary' });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const convertedXLSXJsonFile = xlsx.utils.sheet_to_json(ws) as Record<
            string,
            any
          >[];
          // TODO: need to check if it is building 'page' property
          setJsonFile(convertedXLSXJsonFile);
          setModalOpen(false);
        }
      };

      reader.readAsBinaryString(file);
    }
  }, [file]);

  const { products, errors, schema } = useMemo(
    () => validateImportedProducts(jsonFile?.filter(Boolean), data?.category),
    [jsonFile, data?.category],
  );

  const contextValues: CsvImportContextData = {
    schema,
    isModalOpen,
    setModalOpen,
    loadingCategory,
    category: data?.category,
    file,
    setFile,
    jsonFile,
    products,
    errors,
  };

  return (
    <CsvImportContext.Provider value={contextValues}>
      {children}
    </CsvImportContext.Provider>
  );
};

export default function useCsvImportContext(): CsvImportContextData {
  return useContext(CsvImportContext);
}
