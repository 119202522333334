import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, SimpleInput } from '../../../../../../components';
import { ADD_STOCK_GQL } from '../../../graphql';
import { ProductStatus } from '../../../types';
import BoxIds from '../BoxIds';
import CrateIds from '../CrateIds';
import ButtonCheckbox from '../../../../../../components/ButtonCheckbox';
import BagProvider from '../../../../../../services/bag';
import { useProductUpdate } from '../../Provider';

const RowForm: React.FC = () => {
  const { t } = useTranslation(['product', 'common']);

  const { filters, isFormEnabled, setFormEnabled } = useProductUpdate();
  const { data } = useQuery(ADD_STOCK_GQL, {
    variables: filters,
    fetchPolicy: 'cache-only',
  });

  const statuses = useMemo<ProductStatus[]>(
    () => data?.productsStatus?.data ?? [],
    [data],
  );

  const {
    fields: crates,
    append: appendCrate,
    remove: removeCrate,
  } = useFieldArray({
    name: 'crates',
    keyName: '__id',
  });

  const { fields: boxes, append: appendBox, remove: removeBox } = useFieldArray(
    {
      name: 'boxes',
      keyName: '__id',
    },
  );

  return (
    <>
      <div className="row my-4">
        <div className="col text-right">
          <Button
            type="secondary"
            onClick={() => setFormEnabled(!isFormEnabled)}
          >
            {t(isFormEnabled ? 'common:Finish' : 'common:Edit')}
          </Button>
        </div>
      </div>

      <div className="card-group mb-5 shadow-sm">
        <div className="card border-right-0">
          <div className="card-header p-2">{t('SKU')}</div>
          <div className="card-body p-2 pr-5">
            <SimpleInput field="sku" disabled={!isFormEnabled} required />
          </div>
        </div>
        <div className="card border-right-0">
          <div className="card-header p-2">{t('Lot ID')}</div>
          <div className="card-body p-2 pr-5">
            <SimpleInput field="lot_id" disabled={!isFormEnabled} required />
          </div>
        </div>
        <div className="card border-right-0">
          <div className="card-header p-2">{t('Tray ID')}</div>
          <div className="card-body p-2 pr-5">
            <SimpleInput field="tray_id" disabled={!isFormEnabled} required />
          </div>
        </div>
        <BagProvider
          value={{
            crate: { fields: crates, remove: removeCrate },
            box: { fields: boxes, remove: removeBox },
          }}
        >
          <>
            <div className="card border-right-0">
              <div className="card-header p-2">{t('Crate IDs')}</div>
              <div className="card-body p-2 pr-5 position-relative">
                <CrateIds />
                {isFormEnabled && (
                  <div className="crate-id-btn-wrap">
                    <label>{t('Add Crate Id')}</label>
                    <Button
                      small
                      onClick={() => appendCrate({ name: '' })}
                      className="ml-2"
                    >
                      {t('common:Add')}
                    </Button>
                  </div>
                )}
              </div>
            </div>
            <div className="card">
              <div className="card-header p-2">{t('Box IDs')}</div>
              <div className="card-body p-2 pr-5 position-relative">
                <BoxIds />
                {isFormEnabled && (
                  <div className="box-id-btn-wrap">
                    <label>{t('Add Box Id')}</label>
                    <Button
                      small
                      onClick={() => appendBox({ name: '' })}
                      className="ml-2"
                    >
                      {t('common:Add')}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </>
        </BagProvider>
      </div>
      <ButtonCheckbox
        field="product_status_id"
        label="Status"
        options={statuses}
        controller={{ identifier: 'id', label: 'name' }}
        disabled={!isFormEnabled}
        required
      />
    </>
  );
};

export default RowForm;
