import React from 'react';
import { FormGroup, Row, Col } from 'reactstrap';
import { SimpleInput } from '../../../../components';
import classnames from 'classnames';

interface SearchInputFields {
  field: string;
  placeholder?: string;
}

interface SearchInputFieldProps {
  label: string;
  fields: SearchInputFields[];
  className?: string;
  type?: string;
}

const SearchInputField: React.FC<SearchInputFieldProps> = ({
  label,
  fields,
  className,
  type,
}) => {
  return (
    <FormGroup className={className}>
      <Row>
        <Col md="3" lg="3">
          <label>{label}</label>
        </Col>

        <Col className={classnames({ 'd-flex': fields?.length > 1 })}>
          {fields.map((field, key) => (
            <SimpleInput
              type={type}
              key={`search-field-${key}`}
              field={field.field}
              placeholder={field.placeholder}
              className={classnames({ 'ml-2': key % 2 !== 0 })}
            />
          ))}
        </Col>
      </Row>
    </FormGroup>
  );
};

export default SearchInputField;
