import React from 'react';
import { useQuery } from '@apollo/client';
import Box from './Box';
import { LIST_LAYOUT_COMPONENTS_GQL } from '../../../../graphql';
import Loader from '../../../../../../../components/_organisms/Loader';
import { LayoutComponent } from '@wgt/types';

const SidebarComponents: React.FC = () => {
  const { data, loading } = useQuery(LIST_LAYOUT_COMPONENTS_GQL, {
    fetchPolicy: 'cache-and-network',
  });

  if (loading) {
    return (
      <div className="d-flex align-items-center justify-content-center flex-1">
        <Loader small />
      </div>
    );
  }
  return data?.components?.data
    ?.map((convertedComponent: LayoutComponent) => ({
      ...convertedComponent,
      component_id: convertedComponent.id,
      id: undefined,
    }))
    .map((component: LayoutComponent) => (
      <Box component={component} key={component.key} />
    ));
};

export default SidebarComponents;
