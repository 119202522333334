import React from 'react';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Title } from '../../../../components';
import NavItems from './NavItems';
import ProfileViewSummary from './ProfileViewSummary';
import Security from './Security';
import Content from './Content';
import Results from './Results';
import RequestToJoin from '../RequestToJoin';
import useCrmRoutes from '../../../../hooks/useCrmRoutes';
import InternalRoutes from '../../../../components/_organisms/InternalRoutes';

export default function AccountManagement(): JSX.Element {
  const { t } = useTranslation('hr');
  const { isCrmInternalRoute, actualRoute } = useCrmRoutes();

  const routes = [
    {
      component: RequestToJoin,
      name: 'Request to Join',
      path: `/crm/${actualRoute}/request-to-join`,
      exact: true,
    },
  ];

  return (
    <Row id="hr">
      <Col lg="12">
        <Row className="my-3">
          <Col lg="12">
            <Title>{t('Account Management')}</Title>
          </Col>
        </Row>
        <Row>
          <Col lg="4">
            <ProfileViewSummary />
          </Col>
          <Col lg="8">
            <Row className="d-flex align-items-stretch">
              <Col lg="8">
                <NavItems />
              </Col>
              <Col lg="4  ">
                <Security />
              </Col>
            </Row>
            <Content />
          </Col>
        </Row>
        <Row className="my-3">
          <Col lg="12" id="list-results">
            {isCrmInternalRoute ? (
              <InternalRoutes routes={routes} />
            ) : (
              <Results />
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
