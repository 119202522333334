/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Input } from 'reactstrap';
import { useFormContext } from 'react-hook-form';

interface Props {
  jobTitleId: number;
  jobTitleName: string;
  field: string;
}

export default function JobTitleSelect({
  jobTitleId,
  jobTitleName,
  field,
}: Props): JSX.Element {
  const { getValues, setValue } = useFormContext();

  const handleCheckJobTitle = (item: any) => {
    const jobTitleListValues = getValues(field);
    const index = jobTitleListValues.indexOf(item.id);
    let values = [];
    if (index !== -1) {
      jobTitleListValues.splice(index, 1);
      values = jobTitleListValues;
    } else {
      values = [...jobTitleListValues, item.id];
    }
    setValue(field, values);
  };

  return (
    <>
      <Input
        type="checkbox"
        name="jobTitles"
        onChange={() =>
          handleCheckJobTitle({
            id: jobTitleId,
          })
        }
        defaultChecked={getValues(field).includes(jobTitleId)}
      />{' '}
      {jobTitleName}
    </>
  );
}
