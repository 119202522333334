import React from 'react';
import { FormGroup } from 'reactstrap';
interface OptionValueProps {
  field: string;
  label: string;
}
const OptionValue: React.FC<OptionValueProps> = ({ label, field }) => {
  return (
    <FormGroup className="d-flex flex-column align-items-end">
      <label>{label}</label>
      <input type="checkbox" name={field} />
    </FormGroup>
  );
};

export default OptionValue;
