import React from 'react';
import { Switch, RouteProps } from 'react-router-dom';
import { RouteWithSubRoutes } from '../../../App';

interface Props {
  routes?: RouteProps[];
}
const InternalRoutes: React.FC<Props> = ({ routes }) => {
  return (
    <Switch>
      {routes?.map((route, i) => (
        <RouteWithSubRoutes key={i} {...route} />
      ))}
    </Switch>
  );
};

export default InternalRoutes;
