import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFieldArray } from 'react-hook-form';
import { Button } from '../../';
import BagProvider from '../../../services/bag';
import PlanNextPaymentContent from './PlanNextPaymentContent';

export default function PlanNextPayment(): JSX.Element {
  const { t } = useTranslation(['pointOfSales', 'common']);

  const {
    fields: planNextPayment,
    append: appendPlanNextPayment,
    remove: removePlanNextPayment,
  } = useFieldArray({
    name: 'plan_next_payment',
    keyName: '__id',
  });

  return (
    <div className="section-plan-next-payment mb-4">
      <BagProvider
        value={{
          planNextPayment: {
            fields: planNextPayment,
            remove: removePlanNextPayment,
          },
        }}
      >
        <>
          <PlanNextPaymentContent />
          <div className="text-right">
            <Button
              type="orange"
              className="outline"
              onClick={() =>
                appendPlanNextPayment({
                  payment_after_date: new Date(),
                })
              }
            >
              {t('common:More')}
            </Button>
          </div>
        </>
      </BagProvider>
    </div>
  );
}
