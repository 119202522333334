import React from 'react';
import List from './List';
import { useTranslation } from 'react-i18next';

import Put from './Put';
import { Title } from '../../../../../components';
import useCrmRoutes from '../../../../../hooks/useCrmRoutes';
import InternalRoutes from '../../../../../components/_organisms/InternalRoutes';
import PutModal from './PutModal';
import { PropertyValueType } from '../../types';

interface PropertyValuesProps {
  propertyId?: number;
  propertyType?: PropertyValueType;
}

function PropertyValues({
  propertyId,
  propertyType,
}: PropertyValuesProps): JSX.Element {
  const { isCrmInternalSubRoute, actualRoute } = useCrmRoutes();

  const routes = [
    {
      component: PutModal,
      name: 'Variation Values',
      path: `/crm/${actualRoute}/:id/edit/property-values/:propertyValueId`,
    },
  ];

  const { t } = useTranslation('property');

  return (
    <>
      <div className="border my-4 p-4 rounded">
        <Title className="mb-4" heading="h3">
          {t('Create Property Value')}
        </Title>
        <Put propertyType={propertyType} />
        <Title className="my-4" heading="h3">
          {t('Property Values')}
        </Title>
        <List propertyId={propertyId} propertyType={propertyType} />
      </div>
      {isCrmInternalSubRoute && <InternalRoutes routes={routes} />}
    </>
  );
}

export default PropertyValues;
