import React from 'react';

const Dashboard: React.FC = () => {
  return (
    <div className="mt-4">
      <h2>Welcome to World Gem Trade</h2>
    </div>
  );
};

export default Dashboard;
