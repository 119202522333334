import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { Button, SimpleInput } from '../../../../../../components';
import BagProvider from '../../../../../../services/bag';

import CrateIds from '../CrateIds';
import BoxIds from '../BoxIds';
import { useProductUpdate } from '../../../Update/Provider';
import { Row, Col } from 'reactstrap';
import { Pane } from 'evergreen-ui';
import { useTranslation } from 'react-i18next';

const ProductInlineForm: React.FC = () => {
  const { isFormEnabled } = useProductUpdate();
  const { t } = useTranslation(['product', 'common']);
  const {
    fields: crates,
    append: appendCrate,
    remove: removeCrate,
  } = useFieldArray({
    name: 'crates',
    keyName: '__id',
  });

  const { fields: boxes, append: appendBox, remove: removeBox } = useFieldArray(
    {
      name: 'boxes',
      keyName: '__id',
    },
  );

  return (
    <Row>
      <Col md="2">
        <SimpleInput
          field="lot_id"
          label={t('Lot ID')}
          placeholder={t('common:identification')}
          disabled={!isFormEnabled}
        />
      </Col>
      <Col md="2">
        <SimpleInput
          field="tray_id"
          label={t('Tray ID')}
          placeholder={t('common:identification')}
          disabled={!isFormEnabled}
        />
      </Col>
      <BagProvider
        value={{
          crate: { fields: crates, remove: removeCrate },
          box: { fields: boxes, remove: removeBox },
        }}
      >
        <>
          <Col md="4">
            <BoxIds />
            {isFormEnabled && (
              <Pane className="crate-id-btn-wrap mt-1">
                <small className="text-muted">{t('Add Box Id')}</small>
                <Button
                  small
                  onClick={() => appendBox({ name: '' })}
                  className="ml-2"
                >
                  {t('common:Add')}
                </Button>
              </Pane>
            )}
          </Col>
          <Col md="4">
            <CrateIds />
            {isFormEnabled && (
              <Pane className="crate-id-btn-wrap mt-1">
                <small className="text-muted">{t('Add Crate Id')}</small>
                <Button
                  small
                  onClick={() => appendCrate({ name: '' })}
                  className="ml-2"
                >
                  {t('common:Add')}
                </Button>
              </Pane>
            )}
          </Col>
        </>
      </BagProvider>
    </Row>
  );
};

export default ProductInlineForm;
