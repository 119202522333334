import React from 'react';
import { Title, Button } from '../..';
import useCrmRoutes from '../../../hooks/useCrmRoutes';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

interface CustomHeaderProps {
  title?: string;
  showCreate?: boolean;
  showEdit?: boolean;
  showList?: boolean;
  createLink?: string;
  editLink?: string;
  listLink?: string;
  customActions?: JSX.Element;
}

enum RouteOption {
  create = 'create',
  list = 'list',
  edit = 'edit',
}

const CustomHeader: React.FC<CustomHeaderProps> = ({
  title,
  showCreate,
  showEdit,
  showList,
  createLink,
  editLink,
  listLink,
  customActions,
}) => {
  const { actualRoute } = useCrmRoutes();
  const history = useHistory();

  const handleNavigation = (route: RouteOption) => {
    switch (route) {
      case RouteOption.create:
        return history.push(createLink ?? `/crm/${actualRoute}/${route ?? ''}`);
      case RouteOption.edit:
        return history.push(editLink ?? `${history.location.pathname}/edit`);
      case RouteOption.list:
        return history.push(listLink ?? `/crm/${actualRoute}/`);
      default:
        history.push(listLink ?? `/crm/${actualRoute}/`);
    }
  };

  return (
    <Row className="my-4" noGutters>
      <Col>
        <Title>{title}</Title>
      </Col>
      <Col className="d-flex  justify-content-end">
        {customActions}
        {showCreate && (
          <Button onClick={() => handleNavigation(RouteOption.create)}>
            Create
          </Button>
        )}
        {showList && (
          <Button
            type="secondary"
            onClick={() => handleNavigation(RouteOption.list)}
          >
            List all
          </Button>
        )}
        {showEdit && (
          <Button
            type="secondary"
            onClick={() => handleNavigation(RouteOption.edit)}
          >
            Edit
          </Button>
        )}
      </Col>
    </Row>
  );
};

export default CustomHeader;
