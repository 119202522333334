/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { DocumentNode, gql, useApolloClient, useQuery } from '@apollo/client';
import { TemplateFieldBase, TemplateWithFields } from '@wgt/types';

interface TemplateFieldsContextData {
  template: TemplateWithFields | null;
  fields?: TemplateFieldBase[];
  active: TemplateFieldBase | null;
  onDropComponent(fieldData: TemplateFieldBase): Promise<void>;
  select(fieldData: TemplateFieldBase | null): Promise<void>;
}

export const TemplateFieldsContext = React.createContext<
  TemplateFieldsContextData
>({} as TemplateFieldsContextData);

interface Props {
  defaultTemplate: TemplateWithFields | null;
}

const contextGql = gql`
  {
    fields
    active
  }
`;

interface TemplateContext {
  fields: TemplateFieldBase[];
  active: TemplateFieldBase | null;
}

export const TemplateFieldsProvider: React.FC<Props> = ({
  children,
  defaultTemplate,
}) => {
  const client = useApolloClient();
  const { data } = useQuery(contextGql, {
    fetchPolicy: 'cache-only',
  });

  const { fields, active } = useMemo<TemplateContext>(
    () => data ?? { fields: [], active: undefined },
    [data],
  );

  const params = useParams<ParamTypes>();

  async function select(selected: TemplateFieldBase | null) {
    if (!active?.id) {
      client.writeQuery({
        query: contextGql,
        data: {
          fields: fields?.filter((x) => !!x.id) ?? [],
          active: selected,
        },
      });
      return;
    }

    client.writeQuery({
      query: gql`
        {
          active
        }
      `,
      data: {
        active: selected,
      },
    });
  }

  const write = (query: DocumentNode, args: any) =>
    client.writeQuery({ query, data: args });

  const addField = (field: TemplateFieldBase) => {
    const newField: TemplateFieldBase = {
      ...(field as TemplateFieldBase),
      formValues: {
        component_id: field.component_id ?? 0,
        search_component_id: field.component_id ?? 0,
        is_required: true,
        is_searchable: true,
        is_highlight: false,
        is_single_shape: false,
        values: [],
        data_type_id: undefined,
        property_id: undefined,
        label: '',
        template_id: parseInt(params.id, 10),
      },
    };
    const items: TemplateFieldBase[] = [...fields, newField];
    write(
      gql`
        {
          fields
          active
        }
      `,
      { fields: items, active: newField },
    );
  };

  async function onDropComponent(fieldData: TemplateFieldBase) {
    addField(fieldData);
  }

  const contextValues: TemplateFieldsContextData = {
    template: defaultTemplate,
    fields,
    select,
    active,
    onDropComponent,
  };

  useEffect(() => {
    client.writeQuery({
      query: contextGql,
      data: { active: null, fields: [] },
    });
  }, []);

  useEffect(() => {
    client.writeQuery({
      query: contextGql,
      data: {
        fields: defaultTemplate?.fields ?? [],
        active:
          defaultTemplate?.fields?.find((x) => x.id === active?.id) ?? null,
      },
    });
  }, [defaultTemplate]);

  return (
    <TemplateFieldsContext.Provider value={contextValues}>
      {children}
    </TemplateFieldsContext.Provider>
  );
};

export default function useTemplateFieldsContext(): TemplateFieldsContextData {
  return useContext(TemplateFieldsContext);
}
