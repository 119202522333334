import React from 'react';
import './styles.scss';
import Loader from '../../_organisms/Loader';
import { FaChevronDown } from 'react-icons/fa';

interface Props {
  avatar?: string;
  name: string;
  loading?: boolean;
}

const NavbarAvatar: React.FC<Props> = ({ avatar, name, loading }) => {
  if (loading) {
    return <Loader />;
  }
  return (
    <div className="navbar-avatar">
      <div>
        <img src={avatar} alt={name} />
      </div>
      <span>{name}</span>

      <FaChevronDown className="ml-2" />
    </div>
  );
};

export default NavbarAvatar;
