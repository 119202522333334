import { gql } from '@apollo/client';

export const LIST_WORK_ORDER_TYPES_GQL = gql`
  query workOrderTypes($filter: WorkOrderTypeFilterInput) {
    workOrderTypes(first: 1000, filter: $filter) {
      data {
        id
        is_selectable
        name
        parent {
          id
        }
      }
    }
  }
`;
