import React, { memo } from 'react';
import ContactList, { FakeFirm } from './ContactList';

export const firms = [
  {
    id: 1,
    name: 'World Gem Trade',
    address: 'United States, California, 8605 santa Monica Blvd',
    phone: '213 986 3526',
    email: 'hello@worldgemtrade.com',
    contacts: [
      {
        id: 1,
        name: 'João Hencke',
        email: 'joao.hencke@worldgemtrade.com',
        address: 'United States, California, 8605 santa Monica Blvd',
        phone: '213 986 3526',
        position: 'General manager',
      },
      {
        id: 2,
        name: 'Edilson Junior',
        email: 'edilson.junior@worldgemtrade.com',
        address: 'United States, California, 8605 santa Monica Blvd',
        phone: '213 986 3526',
        position: 'General manager',
      },
    ],
  },
  {
    id: 2,
    name: 'King Gems',
    address: 'United States, California, 8605 santa Monica Blvd',
    phone: '213 986 3526',
    email: 'hello@worldgemtrade.com',
    contacts: [
      {
        id: 4,
        name: 'Mateus Gamba',
        email: 'mateus.gamba@kinggems.com',
        address: 'United States, California, 9663 santa Monica Blvd',
        phone: '213 986 3526',
        position: 'General manager',
      },
    ],
  },
] as FakeFirm[];

export default memo(function WorkOrderContacts(): JSX.Element {
  return (
    <div className="overflow-auto content-scroll">
      <div>
        <ContactList editable items={firms} onSelect={console.log} />
      </div>
    </div>
  );
});
