import React, { useContext } from 'react';
import { DropTargetMonitor, DropTarget, DropTargetConnector } from 'react-dnd';
import { ItemTypes } from '../Sidebar/ItemTypes';
import { DropAreaProps } from '../../../types';
import FieldComponent from './FieldComponent';
import { TemplateFieldsContext } from '../../../../../../hooks/useTemplateFieldsContext';

const DropArea: React.FC<DropAreaProps> = ({
  canDrop,
  isOver,
  connectDropTarget,
}) => {
  const isActive = canDrop && isOver;
  let backgroundColor = 'white';
  if (isActive) {
    backgroundColor = '#d4f9d3';
  } else if (canDrop) {
    backgroundColor = 'darkkhaki';
  }

  const { fields } = useContext(TemplateFieldsContext);

  return (
    <div
      ref={connectDropTarget}
      style={{ backgroundColor }}
      className="template-fields-list"
    >
      {fields?.map((field, key) => (
        <FieldComponent key={key} field={field} />
      ))}
      {!fields?.length && <div>Drop components to start creating</div>}
    </div>
  );
};
export default DropTarget(
  [ItemTypes.FIELD],
  {
    drop: () => ({ name: 'DropArea' }),
  },
  (connect: DropTargetConnector, monitor: DropTargetMonitor) => ({
    canDrop: monitor.canDrop(),
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  }),
)(DropArea);
