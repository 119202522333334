import React, { useState, useMemo } from 'react';
import { Col, Label, Collapse, Row } from 'reactstrap';
import { PaymentTypeGroup, PaymentType } from '@wgt/types';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { IconButton } from 'evergreen-ui';
import { Controller, useFormContext } from 'react-hook-form';

interface Props {
  paymentTypeGroup: PaymentTypeGroup;
  handlePaymentTypeIds: (paymentTypeId: number) => void;
  paymentTypeIdsChecked: number[];
}

const PaymentTypeItem: React.FC<Props> = ({
  paymentTypeGroup,
  handlePaymentTypeIds,
  paymentTypeIdsChecked,
}) => {
  const [isOpen, setOpen] = useState<boolean>(false);

  const toggle = () => setOpen(!isOpen);

  const buttonMore = useMemo(
    () => Number(paymentTypeGroup.paymentTypes?.length) > 1,
    [paymentTypeGroup.paymentTypes],
  );

  const { getValues } = useFormContext();

  return (
    <>
      <Col
        xs="12"
        key={paymentTypeGroup.id}
        className="d-flex align-items-center mb-3 justify-content-end"
      >
        <Label className="mr-2">{paymentTypeGroup.name}</Label>
        {buttonMore ? (
          <IconButton
            appearance="minimal"
            icon={isOpen ? FiChevronUp : FiChevronDown}
            iconSize={26}
            onClick={toggle}
            className="btn-payment-type-more"
          />
        ) : (
          <div>
            {paymentTypeGroup?.paymentTypes?.[0]?.id && (
              <Controller
                name="payment_types"
                defaultValue={getValues('payment_types') ?? []}
                render={({ onChange: onCheckChange }) => (
                  <input
                    type="checkbox"
                    onChange={() =>
                      onCheckChange(
                        handlePaymentTypeIds(
                          Number(paymentTypeGroup?.paymentTypes?.[0]?.id),
                        ),
                      )
                    }
                    defaultChecked={paymentTypeIdsChecked?.includes(
                      Number(paymentTypeGroup?.paymentTypes?.[0]?.id),
                    )}
                  />
                )}
              />
            )}
          </div>
        )}
      </Col>
      {buttonMore && (
        <Collapse isOpen={isOpen} className="container-fluid">
          {paymentTypeGroup.paymentTypes?.map((paymentType: PaymentType) => (
            <Row key={paymentType.id}>
              <Col
                xs="12"
                className="d-flex align-items-center mb-3 justify-content-end pr-4"
              >
                <Label className="mr-2">{paymentType.name}</Label>
                <div className="pr-2">
                  <Controller
                    name="payment_types"
                    defaultValue={getValues('payment_types') ?? []}
                    render={({ onChange: onCheckChange }) => (
                      <input
                        type="checkbox"
                        onChange={() =>
                          onCheckChange(
                            handlePaymentTypeIds(Number(paymentType?.id)),
                          )
                        }
                        defaultChecked={paymentTypeIdsChecked?.includes(
                          Number(paymentType?.id),
                        )}
                      />
                    )}
                  />
                </div>
              </Col>
            </Row>
          ))}
        </Collapse>
      )}
    </>
  );
};

export default PaymentTypeItem;
