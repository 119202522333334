/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { DocumentNode, useMutation } from '@apollo/client';
import { Pane } from 'evergreen-ui';
import {
  NamedTypeNode,
  NonNullTypeNode,
  OperationDefinitionNode,
} from 'graphql';
import { Col, Row, Input } from 'reactstrap';
import { Button } from '../../../../../../components';
import { MediaCenterController } from '../../../../../../hooks/useMediaCenterController';
import { useBagContext } from '../../../../../../services/bag';
import { FileTypeStr } from '../../../types';
import UploadMediaFiles from './UploadMediaFiles';
import UploadThumbnail from './UploadThumbnail';
import { MediaCenterFileType } from '@wgt/types';
import { useTranslation } from 'react-i18next';
import useToast from '../../../../../../hooks/useToast';
import { useHistory } from 'react-router-dom';

export interface MediaSubmit {
  title: string;
  file: File;
  thumbnail?: File;
}

interface NewMediaFormProps {
  filesDescription: string;
  accept: FileTypeStr;
  hasThumbnail?: boolean;
  createGql: DocumentNode;
  type: MediaCenterFileType;
}

export interface FileItem {
  image: string;
  fileToUpload: File[];
}

export interface MediaImage {
  media_title?: string;
  files?: FileItem[];
  thumbnails?: FileItem[];
}

const NewMediaForm: React.FC<NewMediaFormProps> = ({
  accept,
  hasThumbnail,
  createGql,
  filesDescription,
  type,
}) => {
  const [newMedia, setNewMedia] = useState<MediaImage | null>(null);
  const [mediaTitleInvalid, setMediaTitleInvalid] = useState<boolean>(false);
  const history = useHistory();
  const {
    setCreating,
    media: { add },
    productId,
    getProductId,
    categoryId,
  } = useBagContext<MediaCenterController>();
  const { t } = useTranslation('mediaCenter');
  const { toast } = useToast();

  const operationDefinition = createGql.definitions.find(
    (x) => x.kind === 'OperationDefinition',
  ) as OperationDefinitionNode;

  const [create, { loading }] = useMutation(createGql, {
    onCompleted: (data) => {
      setCreating(false);
      setNewMedia(null);

      if (
        operationDefinition?.name?.value &&
        data[operationDefinition.name.value]
      ) {
        add(data[operationDefinition.name.value]);
      }
      toast('success', t('Media sent successfully'));
    },
    onError: () => toast('error', t('Error')),
  });

  const submitMedia = async ({ file, ...input } = {} as MediaSubmit) => {
    const isUpload = (namedType: NamedTypeNode) =>
      namedType.name.value === 'Upload';

    const variables = operationDefinition?.variableDefinitions?.reduce(
      (acc, { variable, type: definitionType }) => {
        const draft = { ...acc };

        if (
          variable.name.value !== 'thumbnail' &&
          definitionType.kind === 'NonNullType' &&
          isUpload((definitionType as NonNullTypeNode).type as NamedTypeNode)
        ) {
          draft[variable.name.value] = file;
        } else if (!draft[variable.name.value]) {
          draft[variable.name.value] = input[variable.name.value];
        }

        return draft;
      },
      { product_id: productId, type },
    );

    if (!productId && getProductId && variables) {
      const createdId = await getProductId();

      if (!createdId) {
        return;
      }

      variables.product_id = createdId;
    }
    await create({ variables });

    if (!productId && variables) {
      history.push(
        `/crm/categories/${categoryId}/products/${variables.product_id}`,
      );
    }
  };

  const submit = () => {
    const { files, thumbnails, media_title } = newMedia as MediaImage;
    if (!files || !files[0] || !files[0].fileToUpload) {
      return;
    }

    if (!media_title) {
      setMediaTitleInvalid(true);
      return;
    }

    submitMedia({
      title: media_title,
      file: files[0].fileToUpload[0],
      thumbnail: thumbnails ? thumbnails[0].fileToUpload[0] : undefined,
    });
  };

  return (
    <Pane className="dropzone-files m-5">
      <Row>
        <Col className="mb-4">
          <UploadMediaFiles
            filesDescription={filesDescription}
            accept={accept}
            setNewMedia={setNewMedia}
            newMedia={newMedia}
          />
        </Col>
      </Row>
      {hasThumbnail && (
        <Row>
          <Col className="mb-4">
            <UploadThumbnail setNewMedia={setNewMedia} newMedia={newMedia} />
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <Input
            invalid={mediaTitleInvalid}
            placeholder={t('Enter the title')}
            onChange={(e) =>
              setNewMedia((oldMedia) => ({
                ...oldMedia,
                media_title: e.target.value,
              }))
            }
            required
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col className="d-flex justify-content-end align-items-center">
          {newMedia?.files?.length && (
            <Button
              htmlType="button"
              isLoading={loading}
              disabled={loading}
              onClick={submit}
            >
              Save
            </Button>
          )}
        </Col>
      </Row>
    </Pane>
  );
};

export default NewMediaForm;
