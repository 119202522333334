import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import routes from './routes';
import WgtLayout from '../../components/_organisms/Layout';
import Dashboard from './Dashboard';

import useCrmRoutes from '../../hooks/useCrmRoutes';
import InternalRoutes from '../../components/_organisms/InternalRoutes';

const CrmIndex: React.FC = () => {
  const {
    setInitialRoutes,
    isInternalRoute,
    removeCustomTitle,
    setProductRoutes,
    setCustomRoutes,
  } = useCrmRoutes();

  const location = useLocation();

  useEffect(() => {
    function setBreadcrumbRoutes() {
      if (setInitialRoutes) {
        setInitialRoutes({
          main: { name: 'Dashboard', path: '/crm/profile' },
          routesTree: routes,
        });
      }
    }
    setBreadcrumbRoutes();

    return () => {
      removeCustomTitle();
      setProductRoutes(null);
      setCustomRoutes(null);
    };
  }, [location.pathname]);

  return (
    <WgtLayout>
      {isInternalRoute && <InternalRoutes routes={routes} />}
      {!isInternalRoute && <Dashboard />}
    </WgtLayout>
  );
};

export default CrmIndex;
