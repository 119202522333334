import React, { createContext, useContext } from 'react';

const context = createContext({});

interface NavbarContext {
  routeArgs?: Record<string, unknown>;
}

interface NavbarProviderProps extends NavbarContext {
  children: JSX.Element | JSX.Element[];
}

export function NavbarProvider({
  children,
  routeArgs,
}: NavbarProviderProps): JSX.Element {
  return (
    <context.Provider
      value={{
        routeArgs,
      }}
    >
      {children}
    </context.Provider>
  );
}

export default function useNavbar(): NavbarContext {
  return useContext(context);
}
