import React, { Dispatch, SetStateAction } from 'react';
import { Worker } from '@react-pdf-viewer/core';
import ReportItem from './ReportItem';
import { MediaCenterItemBase } from '@wgt/types';
import { useTranslation } from 'react-i18next';

export interface ReportTableProps {
  reports: MediaCenterItemBase[];
  setDeleteItem: Dispatch<SetStateAction<MediaCenterItemBase | null>>;
}
const ReportTable: React.FC<ReportTableProps> = ({
  reports,
  setDeleteItem,
}) => {
  const { t } = useTranslation('common');
  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.5.207/build/pdf.worker.min.js">
      <table cellSpacing="0">
        <thead>
          <tr>
            <th>{t('Report')}</th>
            <th>{t('Laboratory')}</th>
            <th />
          </tr>
        </thead>

        <tbody>
          {reports.map((report) => (
            <ReportItem
              report={report}
              key={report.id}
              setDeleteItem={setDeleteItem}
            />
          ))}
        </tbody>
      </table>
    </Worker>
  );
};

export default ReportTable;
