import { gql } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useFakeQuery from '../../hooks/useFakeQuery';
import './styles.scss';
interface UserStorageLocations {
  description: string;
  used: number;
}
interface UserStorageDetails {
  available: number;
  used: number;
  locations: UserStorageLocations[];
}

const defaultValue: UserStorageDetails = {
  available: 30,
  used: 1.1,
  locations: [
    {
      description: 'Stock',
      used: 1.1,
    },
  ],
};
const StorageDetails: React.FC = () => {
  const { t } = useTranslation('storage');
  const { data } = useFakeQuery<UserStorageDetails>(
    gql`
      query userStorageTotal {
        userStorageTotal {
          available
          used
          locations {
            description
            used
          }
        }
      }
    `,
    { defaultValue },
  );

  return (
    <div className="storage-used-description">
      <div>{t('Storage Details')}</div>
      <div className="mt-5">
        {data?.available} {t('GigaByte available')}
      </div>
      <div className="mt-5">
        {data?.used} {t('GigaByte used')}
      </div>
      <div className="mt-5">{t('Locations')}</div>
      {data?.locations.map((location, key) => (
        <div key={`location-${key}`} className="ml-2">
          {t(location.description)} {data?.used} {t('GigaBytes')}
        </div>
      ))}
    </div>
  );
};

export default StorageDetails;
