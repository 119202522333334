import React from 'react';
import { Col } from 'reactstrap';

import PermissionComponent from './Permission';
import { PermissionsProps } from '../types';

const Permissions: React.FC<PermissionsProps> = ({ permissions, role }) => {
  return (
    <>
      {permissions?.map((permission) => (
        <Col key={permission.id} md="4" xl="4" sm="4">
          <PermissionComponent
            role={role}
            permission={permission}
            hasPermission={role?.permissions.some(
              (perm) => perm.id === permission.id,
            )}
          />
        </Col>
      ))}
    </>
  );
};

export default Permissions;
