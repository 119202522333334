/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Col, Row } from 'reactstrap';
import {
  TemplateLayoutRow,
  TemplateLayoutColumn,
  TemplateLayoutGroupContent,
} from '@wgt/types';
import convertValueField from '@wgt/converters/product';
import { Line, Title } from '../../../../../components';
import CharacteristicSection from './CharacteristicSection';
import CardLayoutText from '../../../../../components/CardLayoutText';

interface Props {
  rows: TemplateLayoutRow[];
  values: any;
  fields: any;
}

export default function Section({ rows, values, fields }: Props): JSX.Element {
  const contentValue = (key: string) => {
    return key ? convertValueField(values?.[key], fields, key) : '';
  };

  return (
    <>
      {rows.map((row: TemplateLayoutRow, index: number) => (
        <Row key={`section-row-${index}`}>
          {row.map(
            (
              column: TemplateLayoutColumn,
              indexColumn: number,
              array: TemplateLayoutColumn[],
            ) => (
              <Col lg={column.size} key={`section-column-${indexColumn}`}>
                {column.group.title ? (
                  <>
                    <Title heading="h2" className="mt-4">
                      {column.group.title}
                    </Title>
                    <Line />
                  </>
                ) : array[indexColumn - 1]?.group?.title ||
                  array[indexColumn - 2]?.group?.title ||
                  array[indexColumn - 3]?.group?.title ? (
                  <div className="d-none d-lg-block">
                    <Title heading="h2" className="mt-4">
                      &nbsp;
                    </Title>
                    <Line />
                  </div>
                ) : (
                  <></>
                )}
                {column.group.isCharacteristic ? (
                  <CharacteristicSection
                    content={column.group.content}
                    values={values}
                  />
                ) : (
                  <Row>
                    {column.group &&
                      column.group.content.map(
                        (
                          content: TemplateLayoutGroupContent,
                          indexGroup: number,
                        ) => (
                          <Col
                            key={`section-group-${indexGroup}`}
                            lg={content.size}
                          >
                            <CardLayoutText
                              content={[
                                {
                                  header: content.content?.label ?? '',
                                  content: content.content?.property?.key
                                    ? contentValue(
                                        content.content?.property?.key,
                                      )
                                    : '',
                                },
                              ]}
                              containerClass="mb-3"
                            />
                          </Col>
                        ),
                      )}
                  </Row>
                )}
              </Col>
            ),
          )}
        </Row>
      ))}
    </>
  );
}
