import React from 'react';
import PriceInput from '../../../../../components/PriceInput';
interface SKUPricePops {
  displayText: string;
}
const SKUPrice: React.FC<SKUPricePops> = ({ displayText }) => {
  return (
    <PriceInput displayText={displayText} title="Total SKU PRICE" muted="USD" />
  );
};

export default SKUPrice;
