import React, { useState } from 'react';
import { FormGroup, Label, Collapse } from 'reactstrap';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { IconButton } from 'evergreen-ui';
import { WorkOrderTypeTree } from '@wgt/types';

interface Props {
  workOrderType: WorkOrderTypeTree;
  index?: number;
  scrollToBottom?: () => void;
  field: string;
  onChange: (workOrderTypeId: string) => void;
  value?: string;
  first?: number;
}

export default function WorkOrderTypeList({
  workOrderType,
  index,
  scrollToBottom,
  field,
  onChange,
  value,
  first,
}: Props): JSX.Element {
  const [isOpen, setIsOpen] = useState<boolean>(first === 0 ? true : false);

  const toggle = () => [setIsOpen(!isOpen), scrollToBottom?.()];

  return (
    <>
      <FormGroup
        check={!workOrderType?.children?.length}
        className={`mb-2 ml-tree-${index} mr-1 d-flex`}
      >
        <Label
          check
          htmlFor={`${field}-${workOrderType.id}`}
          className="d-flex align-items-center"
          {...(workOrderType?.children?.length && { onClick: toggle })}
        >
          {workOrderType?.is_selectable && (
            <input
              type="radio"
              name={field}
              className="mr-2"
              id={`${field}-${workOrderType.id}`}
              defaultValue={workOrderType.id}
              onChange={(e) => onChange(e.target.value)}
              defaultChecked={workOrderType.id.toString() === value}
            />
          )}
          {workOrderType.name}
        </Label>
        {!!workOrderType?.children?.length && (
          <IconButton
            appearance="minimal"
            icon={isOpen ? FiChevronUp : FiChevronDown}
            iconSize={20}
            className="ml-3 btn-icon-more-items"
            onClick={toggle}
          />
        )}
      </FormGroup>

      {!!workOrderType?.children && (
        <Collapse isOpen={isOpen}>
          {workOrderType.children?.map((item: WorkOrderTypeTree) => (
            <WorkOrderTypeList
              workOrderType={item}
              key={`work-order-type-list-${item.id}`}
              index={Number(index) + 1}
              scrollToBottom={scrollToBottom}
              field={field}
              onChange={onChange}
              value={value}
            />
          ))}
        </Collapse>
      )}
    </>
  );
}
