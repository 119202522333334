import React, { useMemo } from 'react';
import { FiPlusCircle, FiMinusCircle } from 'react-icons/fi';
import { Collapse, Row, Col, Input } from 'reactstrap';
import { useCollapseContext } from '../../services/collapse';
import ProductResultContent from './ProductResultContent';
import ProductResultColumn from './ProductResultColumn';
import { ProductBase, SearchLayoutResultColumn } from '@wgt/types';
import { IconButton } from 'evergreen-ui';
import { useBagContext } from '../../services/bag';
import MediaDummy from '../../assets/media-dummy.png';
import { useTranslation } from 'react-i18next';

interface ProductResultRowProps {
  product: ProductBase;
  headers: SearchLayoutResultColumn[];
}

const ProductResultRow: React.FC<ProductResultRowProps> = ({
  product,
  headers,
}) => {
  const { active, set } = useCollapseContext();
  const { t } = useTranslation('products');
  const {
    selectedProducts: { list, handleAddProduct },
  } = useBagContext<{
    selectedProducts: {
      list: ProductBase[];
      handleAddProduct(product: ProductBase): void;
    };
  }>();
  const isActive = useMemo<boolean>(() => active?.toString() === product.id, [
    product.id,
    active,
  ]);

  return (
    <Row className="border rounded mb-2 py-2 bg-light item">
      <Col lg="auto" className="d-flex align-items-center">
        <Input
          type="checkbox"
          className="position-relative m-0"
          checked={!!(list.length && list.some((x) => x.id === product.id))}
          onChange={() => product.id && handleAddProduct(product)}
        />
        <IconButton
          appearance="minimal"
          icon={isActive ? FiMinusCircle : FiPlusCircle}
          iconSize={26}
          onClick={() => set(product.id?.toString() ?? '')}
          className="d-inline-block icon-toggle"
        />
        <img
          src={MediaDummy}
          alt={t('Title from Media')}
          className="rounded-circle image-media"
        />
      </Col>

      {headers?.map((header) => (
        <Col key={`header-${header.key}`}>
          <ProductResultColumn product={product} header={header} />
        </Col>
      ))}
      <Col lg="12">
        <Collapse isOpen={isActive} className="w-100 p-2">
          {isActive && <ProductResultContent />}
        </Collapse>
      </Col>
    </Row>
  );
};

export default ProductResultRow;
