import { MediaCenterItemBase } from '@wgt/types';
import { useCallback, useMemo, useState } from 'react';

export interface MediaItemsProps {
  add(item: MediaCenterItemBase): void;
  remove(indexToRemove: number): void;
  selectBackground(background: string): void;
  items: MediaCenterItemBase[];
  setActiveItemIndex(index: number): void;
  setItems(items: MediaCenterItemBase[]): void;
  active?: MediaCenterItemBase;
}

export interface MediaCenterController {
  isExpanded: boolean;
  zoom: ZoomProps;
  setExpanded(isExpanded: boolean): void;
  setZoom(zoom: ZoomProps): void;
  media: MediaItemsProps;
  isCreating: boolean;
  setCreating(isCreating: boolean): void;
  productId?: string | null;
  getProductId?: () => Promise<string | undefined>;
  categoryId?: string;
}

interface MediaCenterControllerProps {
  productId?: string | null;
  categoryId?: string;
  getProductId?: () => Promise<string | undefined>;
}

export type ZoomProps = 100 | 150 | 125;

export default function useMediaCenterController({
  productId,
  categoryId,
  getProductId,
}: MediaCenterControllerProps): MediaCenterController {
  const [isExpanded, setExpanded] = useState<boolean>(false);
  const [isCreating, setCreating] = useState<boolean>(false);
  const [zoom, setZoom] = useState<ZoomProps>(100);

  const [activeItemIndex, setActiveItemIndex] = useState<number | undefined>();
  const [items, setItems] = useState<MediaCenterItemBase[]>([]);

  const selectBackground = useCallback(
    (background: string) =>
      setItems((current) => {
        if (typeof activeItemIndex === 'undefined') {
          return current;
        }
        const draft = [...current];
        draft[activeItemIndex] = { ...draft[activeItemIndex], background };
        return draft;
      }),
    [activeItemIndex],
  );

  const add = useCallback(
    (item: MediaCenterItemBase) => setItems((current) => [...current, item]),
    [],
  );

  const remove = useCallback(
    (indexToRemove: number) =>
      setItems((current) => {
        const draft = [...current];
        draft.splice(indexToRemove, 1);
        return draft;
      }),
    [],
  );

  const active = useMemo<MediaCenterItemBase | undefined>(() => {
    if (typeof activeItemIndex === 'undefined') {
      return undefined;
    }
    return items[activeItemIndex];
  }, [activeItemIndex, items]);

  return {
    isExpanded,
    setExpanded,
    zoom,
    setZoom,
    isCreating,
    setCreating,
    media: {
      add,
      remove,
      selectBackground,
      items,
      active,
      setItems,
      setActiveItemIndex,
    },
    productId,
    categoryId,
    getProductId,
  };
}
