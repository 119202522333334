import { gql } from '@apollo/client';
import { paginatorInfoFragment } from '../../../../../services/graphql/paginator.gql';

export const shapeIdentityFragment = gql`
  fragment shapeIdentity_Part on Shape {
    id
    image
    image_url
    title
    shapeGroup {
      id
      title
    }
  }
`;

export const GET_TEMPLATE_SHAPES_GQL = gql`
  query templateQuery($id: ID) {
    template(id: $id) {
      id
      shapes {
        ...shapeIdentity_Part
        position
      }
    }
    shapeGroups {
      data {
        id
        title
      }
    }
  }
  ${shapeIdentityFragment}
`;

export const LIST_SHAPES_GQL = gql`
  query shapes($page: Int, $filter: ShapeFilterInput) {
    shapes(page: $page, filter: $filter, first: 1000) {
      data {
        ...shapeIdentity_Part
      }
      paginatorInfo {
        ...paginatorInfo_Part
      }
    }
  }
  ${shapeIdentityFragment}
  ${paginatorInfoFragment}
`;

export const ATTACH_SHAPE = gql`
  mutation attachShapeToTemplateMutation($id: ID!, $shape_id: ID!) {
    attachShapeToTemplate(id: $id, shape_id: $shape_id) {
      message
    }
  }
`;
export const DETACH_SHAPE = gql`
  mutation detachShapeToTemplateMutation($id: ID!, $template_id: ID!) {
    detachTemplateToShape(id: $id, template_id: $template_id) {
      message
    }
  }
`;
