/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { CategoryBase } from '@wgt/types';
import { uid } from 'uid';
import * as yup from 'yup';

const KNOWN_PRODUCT_ATTRIBUTES = {
  name: /name/i,
  sku: /sku/i,
  description: /description/i,
  lot_id: /lot(_|\s?)id/i,
  tray_id: /tray(_|\s?)id/i,
};

const KNOWN_PAGE_ATTRIBUTES = {
  price_per_carat: /price(_|\s?)per(_|\s?)carat/i,
  carat_weight: /carat(_|\s?)weight/i,
  total_price: /total(_|\s?)price/i,
};

const createRegex = (value: string) =>
  new RegExp(value.split('').join('(?:\ns*)?'), 'i');

export const convertCSVArrayIntoJSON = (
  csvArray: [[string]],
): Record<string, any>[] | null => {
  if (!csvArray.length) {
    return null;
  }
  const [csvKeys, ...lines] = csvArray;

  return lines.reduce(
    (acc: Record<string, any>[], line: string[]) => [
      ...acc,
      line.reduce<Record<string, string>>(
        (parsed, content: string, column: number) => ({
          ...parsed,
          [csvKeys[column]]: content,
        }),
        {} as Record<string, string>,
      ),
    ],

    [],
  );
};

export const validateImportedProducts = (
  jsonFile?: Record<string, any>[] | null,
  category?: CategoryBase,
) => {
  if (!jsonFile?.length || !category?.id) {
    return {};
  }

  const pageSchema = yup.object({
    name: yup.string(),
    price_per_carat: yup.number().required('required'),
    carat_weight: yup.number().required('required'),
    total_price: yup.number().required('required'),
    fields: yup.object(
      category.template?.fields.reduce((acc, field) => {
        const draft = { ...acc };
        const key = field.property?.key ?? '';
        draft[key] = field.is_required
          ? yup.string().required('required')
          : yup.string().notRequired();

        if (
          ['single-select', 'multi-select'].includes(
            field.property?.dataType?.key ?? '',
          )
        ) {
          const validValues = field.values?.map((x: { key: string }) => x.key);
          draft[key] = draft[key].oneOf(
            validValues,
            `${validValues?.join(', ')}`,
          );
        }

        return draft;
      }, {}),
    ),
  });

  const schema = yup.object({
    pages: yup.array(pageSchema),
    name: yup.string().required('required'),
    description: yup.string().required('required'),
    sku: yup.string().required('required'),
    lot_id: yup.string().notRequired(),
    tray_id: yup.string().notRequired(),
  });

  const parsedProducts = jsonFile.reduce((acc, curr) => {
    const draft = [...acc];
    const { page, ...parsed } = Object.keys(curr).reduce(
      (properties, key) => {
        const attr = Object.keys(KNOWN_PRODUCT_ATTRIBUTES).find((x) =>
          key.match(KNOWN_PRODUCT_ATTRIBUTES[x]),
        );

        if (attr) {
          return { ...properties, [attr]: curr[key] };
        }

        const knownPageAttribute = Object.keys(
          KNOWN_PAGE_ATTRIBUTES,
        ).find((x) => key.match(KNOWN_PAGE_ATTRIBUTES[x]));

        if (knownPageAttribute) {
          return {
            ...properties,
            page: {
              ...properties.page,
              [knownPageAttribute]: curr[key],
            },
          };
        }

        const field = category.template?.fields.find(
          // @TODO: when we add abbreviations to property, we will need to change below.
          (x) => key?.match(createRegex(x.property?.key ?? '')),
        );

        if (!field) {
          return properties;
        }

        const value = field.values?.find(
          (x) =>
            curr[key].match(createRegex(x.key)) ||
            x.abbreviations?.some((y: string) =>
              curr[key].match(createRegex(y)),
            ),
        );

        return {
          ...properties,
          page: {
            ...properties.page,
            fields: {
              ...properties.page?.fields,
              [field.property?.key ?? '']: value?.key ?? curr[key],
            },
          },
        };
      },
      { page: { category, name: uid() } },
    );
    const insertedProductIndex = acc.findIndex(
      (x: any) => x.sku === parsed.sku,
    );
    if (insertedProductIndex >= 0) {
      draft[insertedProductIndex].pages.push(page);
      return draft;
    }

    draft.push({
      ...parsed,
      pages: [page],
    });
    return draft;
  }, []);

  return parsedProducts.reduce(
    (acc, curr, row) => {
      const draft = { ...acc };

      try {
        const validated = schema.validateSync(curr, { abortEarly: false });
        draft.products.push(validated);
      } catch (e) {
        draft.errors.push({ ...e, row: row + 1 });
      }
      return draft;
    },
    { schema, products: [], errors: [] },
  );
};
