import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Permission } from '@wgt/types';
import {
  GIVE_PERMISSION_TO_USER_GQL,
  REVOKE_PERMISSION_FROM_USER_GQL,
} from './graphql';
import { SwitchBox } from '../../../../../../components';

interface Props {
  userId?: number;
  permission: Permission;
  hasPermission?: boolean;
  refetchUserPermission: () => void;
}

export default function PermissionComponent({
  userId,
  permission,
  hasPermission,
  refetchUserPermission,
}: Props): JSX.Element {
  const [isChecked, setChecked] = useState(false);

  const [addPermission, { loading: loadingAdd }] = useMutation(
    GIVE_PERMISSION_TO_USER_GQL,
    {
      variables: {
        id: userId,
        permission_id: permission?.id,
      },
      onCompleted: () => [refetchUserPermission(), setChecked(true)],
    },
  );

  const [revokePermission, { loading: loadingRevoke }] = useMutation(
    REVOKE_PERMISSION_FROM_USER_GQL,
    {
      variables: {
        id: userId,
        permission_id: permission?.id,
      },
      onCompleted: () => [refetchUserPermission(), setChecked(false)],
    },
  );

  const onClick = () => {
    if (!isChecked) {
      addPermission();
    } else {
      revokePermission();
    }
  };

  useEffect(() => {
    setChecked(!!hasPermission);
  }, [hasPermission]);

  return (
    <SwitchBox
      type="switch"
      id={`permission-${userId}-${permission.id}`}
      label={permission.name.split('-').join(' ')}
      className="text-capitalize"
      checked={isChecked}
      onChange={onClick}
      isLoading={loadingAdd || loadingRevoke}
    />
  );
}
