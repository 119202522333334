import React from 'react';
import useCrmRoutes from '../../../hooks/useCrmRoutes';
import InternalRoutes from '../../../components/_organisms/InternalRoutes';
import List from './List';
import Put from './Put';

const ShapeGroups: React.FC = () => {
  const { isCrmInternalRoute, actualRoute } = useCrmRoutes();

  if (isCrmInternalRoute) {
    const routes = [
      {
        component: Put,
        name: 'Shape Group',
        path: `/crm/${actualRoute}/create`,
      },
      {
        component: Put,
        name: 'Shape Group',
        path: `/crm/${actualRoute}/:id/edit`,
      },
    ];
    return <InternalRoutes routes={routes} />;
  }
  return <List />;
};

export default ShapeGroups;
