import React from 'react';
import { Heading } from 'evergreen-ui';
interface Props {
  size?: 900 | 600 | 300 | 200 | undefined;
  heading?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | undefined;
  className?: string;
}

const Title: React.FC<Props> = ({ children, heading, className }) => {
  return (
    <Heading is={heading ?? 'h1'} className={className ?? ''}>
      {children}
    </Heading>
  );
};

export default Title;
