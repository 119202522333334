import React, { useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { FaGlobe } from 'react-icons/fa';
import classnames from 'classnames';
import { PointOfSaleProduct } from '@wgt/types';
import { Button } from '../../../../../../../components';
import PageIds from '../../../../../Products/Update/Header/PageIDs';
import useWorkOrderContext from '../../../../../../../components/WorkOrder/useWorkOrderContext';
import { useProductUpdate } from '../../../../../Products/Update/Provider';

const ItemActionButtons: React.FC<{ product: PointOfSaleProduct }> = ({
  product,
}) => {
  const { t } = useTranslation('common');
  const { isOpen, setOpen, setActivePageId } = useWorkOrderContext();
  const { pages } = useProductUpdate();

  useEffect(() => setActivePageId(pages?.active), [pages?.active]);
  return (
    <Row className="mt-3 item-action-buttons">
      <Col xs="4">
        <Button
          type="orange"
          className={classnames({ outline: !isOpen })}
          onClick={() => setOpen(!isOpen)}
        >
          {t('Work Order')}
        </Button>
        <Button type="orange" className="outline ml-4">
          {t('History')}
        </Button>
      </Col>
      <Col xs="8" className="text-right">
        <Button type="secondary">{t('Calendar')}</Button>
        <Button
          iconBefore={FaGlobe}
          type="primary"
          className="btn-trace-document mx-4"
        >
          {t('Trace Document')}
        </Button>
        <div className="d-inline-block page-ids-details">
          <PageIds productId={product?.id?.toString()} />
        </div>
      </Col>
    </Row>
  );
};

export default ItemActionButtons;
