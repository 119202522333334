import { gql } from '@apollo/client';

export interface Paginator {
  total: number;
  currentPage: number;
  hasMorePages: boolean;
}

export const paginatorInfoFragment = gql`
  fragment paginatorInfo_Part on PaginatorInfo {
    total
    currentPage
    hasMorePages
  }
`;
