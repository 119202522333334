import React, { useMemo } from 'react';
import { TemplateLayoutGroupContent } from '@wgt/types';

import PropertyValueItem from './PropertyValueItem';

interface Props {
  content: TemplateLayoutGroupContent;
  prefix?: string;
  disabled?: boolean;
}

const TemplateLayoutCharacteristicValues: React.FC<Props> = ({
  content,
  prefix = '',
  disabled,
}) => {
  const [property, values] = useMemo(() => {
    const valuesList = content?.content?.values ?? [];

    return [content.content?.property, valuesList];
  }, [content?.content?.values]);

  return disabled ? (
    <React.Fragment />
  ) : (
    <div className="template-characteristic-values">
      {values.map((v, key) => (
        <PropertyValueItem
          propertyValue={v}
          dataType={property?.dataType}
          key={key}
          field={`${prefix}${property?.key}`}
        />
      ))}
    </div>
  );
};

export default TemplateLayoutCharacteristicValues;
