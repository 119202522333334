import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import TemplateLayoutStructure from './TemplateLayoutStructure';

const TemplateLayoutContent: React.FC = () => {
  const methods = useForm();
  return (
    <FormProvider {...methods}>
      <div className="template-layout-container-content">
        <TemplateLayoutStructure />
      </div>
    </FormProvider>
  );
};

export default TemplateLayoutContent;
