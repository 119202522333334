import React, { useState, useCallback } from 'react';
import { FiPlusCircle, FiMinusCircle, FiEdit, FiTrash2 } from 'react-icons/fi';
import { BsPlusSquare } from 'react-icons/bs';
import { IconButton } from 'evergreen-ui';
import { Row, Col, Collapse, Container } from 'reactstrap';
import { WorkOrderType } from '@wgt/types';
import { useMutation, useQuery } from '@apollo/client';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import Loader from '../../../../components/_organisms/Loader';
import {
  GET_WORK_ORDER_TYPE_GQL,
  DELETE_WORK_ORDER_TYPE_GQL,
  LIST_WORK_ORDER_TYPE_GQL,
} from '../graphql';
import ConfirmDialog from '../../../../components/_molecules/ConfirmDialog';
import useToast from '../../../../hooks/useToast';
import Put from '../Put';

interface Props {
  idWorkOrderType: number;
  color?: string;
}

export default function Item({ idWorkOrderType, color }: Props): JSX.Element {
  const { toast } = useToast();
  const { t } = useTranslation('common');

  const [collapse, setCollapse] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);

  const [showRemoveConfirm, setShowRemoveConfirm] = useState<boolean>(false);

  const { data, refetch, loading } = useQuery<{
    workOrderType: WorkOrderType;
  }>(GET_WORK_ORDER_TYPE_GQL, {
    nextFetchPolicy: 'cache-first',
    variables: {
      id: idWorkOrderType,
    },
  });

  const toggle = () => {
    setCollapse(!collapse);
  };

  const [remove, { loading: removing }] = useMutation(
    DELETE_WORK_ORDER_TYPE_GQL,
    {
      variables: { id: idWorkOrderType },
      onCompleted: () => {
        toast('success');
      },
      refetchQueries: [
        {
          query: LIST_WORK_ORDER_TYPE_GQL,
          variables: {
            filter: {
              parent_id: null,
            },
          },
        },
      ],
    },
  );

  const toggleModal = (isEdit = false) => {
    setOpenModal(true);
    setEdit(isEdit);
  };

  const onConfirm = useCallback(async () => {
    await remove();
    setShowRemoveConfirm(false);
  }, []);

  const onClickRemove = (e: React.MouseEvent) => {
    e.preventDefault();
    setShowRemoveConfirm(true);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Row className={classnames('border rounded py-3 mt-3 body', color)}>
          <Col lg="6" className="d-flex align-items-center">
            <div className="icon-toggle-width d-inline-block">
              {!!data?.workOrderType.children?.length && (
                <IconButton
                  appearance="minimal"
                  icon={collapse ? FiMinusCircle : FiPlusCircle}
                  iconSize={26}
                  onClick={toggle}
                  className=" icon-toggle"
                />
              )}
            </div>
            <p>{data?.workOrderType.name}</p>
          </Col>
          <Col lg="1" className="text-center px-0">
            <div
              className={classnames('border rounded', {
                selectable: data?.workOrderType.is_selectable,
                'no-selectable': !data?.workOrderType.is_selectable,
              })}
            >
              <p>
                {!data?.workOrderType.is_selectable && t('common:No')}{' '}
                {t('Selectable')}
              </p>
            </div>
          </Col>
          <Col lg="5" className="d-flex justify-content-end align-items-center">
            <IconButton
              appearance="minimal"
              className="btn-icon-edit mr-2"
              onClick={() => toggleModal(false)}
              iconSize={20}
              icon={BsPlusSquare}
            />

            <IconButton
              appearance="minimal"
              className="btn-icon-edit mr-2"
              onClick={() => toggleModal(true)}
              iconSize={20}
              icon={FiEdit}
            />

            <IconButton
              appearance="minimal"
              className="btn-icon-edit"
              onClick={onClickRemove}
              iconSize={22}
              icon={FiTrash2}
            />
          </Col>
          <Col lg="12" className="px-2">
            <Collapse isOpen={collapse}>
              <Container fluid className="px-4">
                {collapse &&
                  data?.workOrderType.children?.map(
                    (type: WorkOrderType, index: number) => (
                      <Item
                        key={`sub-item-${data?.workOrderType.id}-${index}`}
                        idWorkOrderType={Number(type.id)}
                        color={color === 'bg-light' ? 'bg-white' : 'bg-light'}
                      />
                    ),
                  )}
              </Container>
            </Collapse>
          </Col>
        </Row>
      )}
      <ConfirmDialog
        isShown={showRemoveConfirm}
        onConfirm={onConfirm}
        title={t('common:Are you sure you want to delete this record?')}
        onClose={() => setShowRemoveConfirm(false)}
        isLoading={removing}
      />

      {openModal && (
        <Put
          {...(edit
            ? { id: data?.workOrderType.id }
            : { parentId: data?.workOrderType.id })}
          setOpenModal={setOpenModal}
          refetch={refetch}
          isEdit={edit}
        />
      )}
    </>
  );
}
