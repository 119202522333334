import useToggler from '@wgt/shared/hooks/useToggler';
import { IconButton } from 'evergreen-ui';
import React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaPencilAlt } from 'react-icons/fa';
import {
  Col,
  FormGroup,
  Input,
  InputGroupAddon,
  Label,
  Row,
  InputGroup,
} from 'reactstrap';
import { Button } from '../../../components';
import Dropzone from '../../../components/Dropzone';
import TextEditor from '../../../components/TextEditor';
import './styles.scss';
interface InputToggleProps extends React.InputHTMLAttributes<HTMLInputElement> {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';
  isTextArea?: boolean;
}

function InputToggle({
  isTextArea,
  disabled,
  as: As = 'p',
  ...props
}: InputToggleProps): JSX.Element {
  const { t } = useTranslation('common');
  const [readonly, toggle] = useToggler(true);

  return (
    <Controller
      name={props.name ?? 'uncontrolled'}
      render={({ onChange, value, name }) =>
        readonly ? (
          <div className="d-flex align-items-center">
            <As dangerouslySetInnerHTML={{ __html: value }} />
            {!disabled && (
              <IconButton
                type="button"
                icon={FaPencilAlt}
                appearance="minimal"
                onClick={toggle}
              />
            )}
          </div>
        ) : (
          <InputGroup>
            {isTextArea ? (
              <TextEditor initialValue={value} onChange={onChange} />
            ) : (
              <input
                type="text"
                className="form-control"
                onChange={onChange}
                value={value}
                name={name}
              />
            )}

            <InputGroupAddon addonType="append">
              <Button type="blue" onClick={toggle}>
                {t('Save')}
              </Button>
            </InputGroupAddon>
          </InputGroup>
        )
      }
    >
      /
    </Controller>
  );
}

export default function WebsiteTemplate(): JSX.Element {
  const [isPreview, setIsPreview] = React.useState(false);
  const { t } = useTranslation('website');
  const methods = useForm({
    shouldUnregister: false,
    defaultValues: {
      image: '',
      heading: {
        title: 'Your stock is search',
        subtitle:
          'Our goal is to connect beauty across the industry. Find the perfect gem here!',
      },
      about: {
        title: 'About us',
        content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. In eget enim sed eros efficitur pellentesque. Nam vitae turpis lobortis, sagittis velit eu, vulputate est. Sed orci leo, egestas nec luctus in, bibendum ut nisi. Donec interdum sapien sit amet placerat aliquam. Nunc nec lacus laoreet, porttitor risus sed, imperdiet mi.
          Nulla nulla turpis, viverra fermentum erat vel, ornare viverra odio. Pellentesque ornare congue sem, id pretium nulla posuere id. Fusce ex libero, rutrum quis tincidunt in, consequat a justo. Suspendisse vitae est nec neque laoreet ultrices sed eget odio. Maecenas ullamcorper commodo porttitor. Cras sit amet pretium felis. Sed feugiat, elit laoreet tincidunt imperdiet, lacus urna pulvinar elit, at aliquet purus ligula et ligula.
          Nunc vel eros lacus. Etiam mauris est, maximus vel accumsan sed mollis porta dui. Maecenas fringilla eget tellus vitae cursus. Curabitur a tincidunt lectus. Duis vitae dolor non ipsum viverra finibus quis vitae risus.`,
      },
      store: {
        title: 'Store',
        content:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In eget enim sed eros efficitur pellentesque. Nam vitae turpis lobortis, sagittis velit eu, vulputate est. Sed orci leo, egestas nec luctus in, bibendum ut nisi. Donec interdum sapien sit amet placerat aliquam. Nunc nec lacus laoreet, porttitor risus sed, imperdiet mi.',
      },
    },
  });

  return (
    <FormProvider {...methods}>
      <div className="p-5 website-template">
        <Row>
          <Col md={{ size: 2, offset: 5 }}>
            {!isPreview && (
              <p>
                {t(
                  'This is a sample website template. You can use this or have a custom website',
                )}
              </p>
            )}
          </Col>
          <Col md={{ offset: 1, size: 2 }}>
            <FormGroup check>
              <Label htmlFor="consigmentBuyer" check>
                <Input
                  type="checkbox"
                  id="consigmentBuyer"
                  name="consigmentBuyer"
                />
                {t('Show Stock that is on Consigment Buyer')}
              </Label>
            </FormGroup>
          </Col>
          <Col md={{ size: 2 }}>
            {!isPreview && (
              <Button
                className="float-right"
                onClick={() => setIsPreview(true)}
              >
                {t('select to view as a buyer')}
              </Button>
            )}
          </Col>
        </Row>
        <Row className="my-5">
          <Col md={6} sm={12}>
            <InputToggle name="heading.title" as="h3" disabled={isPreview} />
            <InputToggle name="heading.subtitle" as="p" disabled={isPreview} />
          </Col>
          <Col md={6} sm={12}>
            <Controller
              name="image"
              render={({ onChange, value }) =>
                isPreview ? (
                  <div className="photo-holder py-3 d-flex align-items-center flex-column">
                    <img src={value && URL.createObjectURL(value)} />
                  </div>
                ) : value ? (
                  <div className="photo-preview py-3 d-flex align-items-center flex-column">
                    <img src={value && URL.createObjectURL(value)} />
                    <Button
                      small
                      type="danger"
                      htmlType="button"
                      onClick={() => onChange('')}
                      className="mt-2"
                    >
                      {t('Remove')}
                    </Button>
                  </div>
                ) : (
                  <Dropzone
                    contentMessage={t('and Drop your Image')}
                    onDrop={(e) => onChange(e[0])}
                  />
                )
              }
            />
          </Col>
        </Row>
        <Row className="my-5">
          <Col md={8} sm={12}>
            <InputToggle name="about.title" as="h3" disabled={isPreview} />
            <InputToggle
              name="about.content"
              as="p"
              isTextArea
              disabled={isPreview}
            />
          </Col>
          <Col md={4} sm={12}>
            <InputToggle name="store.title" as="h3" disabled={isPreview} />
            <InputToggle name="store.content" as="p" disabled={isPreview} />
          </Col>
        </Row>
      </div>
    </FormProvider>
  );
}
