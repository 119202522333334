import React, { useState, useMemo, useRef } from 'react';
import { UncontrolledPopover, PopoverBody, Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { useFormContext } from 'react-hook-form';
import { JobTitle, JobTitleTree } from '@wgt/types';
import { useQuery } from '@apollo/client';
import { uid } from 'uid';
import { Button } from '../../../../../../../components';
import Loader from '../../../../../../../components/_organisms/Loader';
import JobTitleList from './JobTitleList';
import { GET_JOB_TITLES_GQL } from '../graphql';

interface Props {
  autoScroll?: boolean;
  field?: string;
}

export default function AddJobTitle({
  autoScroll = true,
  field = 'jobTitles',
}: Props): JSX.Element {
  const { t } = useTranslation('hr');
  const [popoverOpen, setPopoverOpen] = useState(false);

  const { watch } = useFormContext();
  const jobTitleValues = watch(field);

  const toggle = () => setPopoverOpen(!popoverOpen);

  const collapseEnd = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    setTimeout(() => {
      collapseEnd.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }, 300);
  };

  const { data, loading } = useQuery<{ jobTitles: { data: JobTitle[] } }>(
    GET_JOB_TITLES_GQL,
  );

  const jobTitlesTree = (jobTitleValue: JobTitle[]) => {
    const tree = [];
    const mappedArr = {};
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let arrElem: any;
    let mappedElem;

    for (let i = 0, len = jobTitleValue.length; i < len; i++) {
      arrElem = jobTitleValue[i];
      mappedArr[arrElem.id] = arrElem;
      mappedArr[arrElem.id].subJobTitles = [];
      mappedArr[arrElem.id].subordinates = [];
    }

    for (const id in mappedArr) {
      if (mappedArr.hasOwnProperty(id)) {
        mappedElem = mappedArr[id];

        if (mappedElem.parent_id) {
          mappedArr[mappedElem.parent_id].subJobTitles.push(mappedElem);
        } else {
          if (mappedElem.parent_id !== null) {
            tree.push(mappedElem);
          } else {
            mappedArr[mappedElem.main_id].subordinates.push(mappedElem);
          }
        }
      }
    }
    return tree;
  };

  const jobTitlestree = useMemo(() => {
    const jobTitleSerialized =
      data?.jobTitles?.data?.map((item: JobTitle) => {
        const parentId =
          item.parent_id === null && item.main_id === null ? 0 : item.parent_id;

        return {
          ...item,
          parent_id: parentId !== null ? Number(parentId) : null,
          main_id: item.main_id !== null ? Number(item.main_id) : null,
          id: Number(item.id),
        };
      }) || [];
    return jobTitlesTree(jobTitleSerialized);
  }, [data?.jobTitles?.data]);

  const idPopover = useMemo(() => uid(), []);

  return (
    <>
      <Button id={`jobTitlePopover${idPopover}`} type="combobox">
        <div className="d-flex justify-content-between w-100">
          <div className="text-truncate">
            {jobTitleValues.length
              ? data?.jobTitles?.data
                  .filter((jobTitle) =>
                    jobTitleValues.includes(Number(jobTitle.id)),
                  )
                  .map((jobTitle) => jobTitle.name)
                  .join(', ')
              : t('Click to Select Job Title')}
          </div>
          <div>{popoverOpen ? <FiChevronUp /> : <FiChevronDown />}</div>
        </div>
      </Button>

      <UncontrolledPopover
        trigger="legacy"
        target={`jobTitlePopover${idPopover}`}
        toggle={toggle}
        isOpen={popoverOpen}
        className="popover-jobtitle"
        placement="bottom"
      >
        <PopoverBody className="px-4 pt-3 pb-2">
          <Row>
            <Col xs="12" className="list-checkbox">
              <p className="title">{t('Job Title')}</p>
              {loading ? (
                <Loader small />
              ) : (
                <div className="popover-scroll">
                  {jobTitlestree.map((title: JobTitleTree, index: number) => (
                    <JobTitleList
                      key={`job-title-list-${title.id}`}
                      jobTitle={title}
                      index={index}
                      field={field}
                      {...(autoScroll && { scrollToBottom })}
                    />
                  ))}
                  <div id="collapseEnd" ref={collapseEnd} />
                </div>
              )}
            </Col>
          </Row>
        </PopoverBody>
      </UncontrolledPopover>
    </>
  );
}
