import { useMutation } from '@apollo/client';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Col, Input, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Report } from '@wgt/types';
import { Button } from '../../../../../components';
import { NewReportFormProps } from '../../types';
import UploadReportFiles from './UploadReportFiles';
import useToast from '../../../../../hooks/useToast';

import {
  ATTACH_PAGE_ID_TO_FILE,
  CREATE_FILE,
  LIST_FILES,
} from '../../Update/MediaCenter/graphql';
import { useBagContext } from '../../../../../services/bag';

const NewReportForm: React.FC<NewReportFormProps> = ({
  handleReportCreatedSuccess,
}) => {
  const { toast } = useToast();
  const { t } = useTranslation(['product', 'common']);
  const { pageId, productId } = useBagContext();

  const {
    control,
    formState,
    getValues,
    register,
    reset: resetFormValues,
    handleSubmit,
    errors,
  } = useForm({
    mode: 'onChange',
  });
  const params = useParams<ParamTypes>();

  const [createProductReport, { loading }] = useMutation(CREATE_FILE, {
    onError: () => toast('error', t('Failed to create report')),
    onCompleted: ({ createFile }) =>
      attachPageIdToFile({
        variables: {
          id: createFile.id,
          page_id: pageId,
        },
      }),
  });

  const [attachPageIdToFile, { loading: attaching }] = useMutation(
    ATTACH_PAGE_ID_TO_FILE,
    {
      onError: () => toast('error', t('Failed to create report')),
      onCompleted: () => [resetFormValues(), handleReportCreatedSuccess()],
      refetchQueries: [
        {
          query: LIST_FILES,
          variables: {
            filter: {
              product_id: productId ?? params.id,
              type: 'guide',
              pages: {
                id: [pageId],
              },
            },
          },
        },
      ],
    },
  );

  const onSubmit = handleSubmit(async ({ files, ...variables }: Report) =>
    createProductReport({
      variables: {
        file: files?.fileToUpload[0],
        title: variables.identification,
        product_id: productId ?? params.id,
        type: 'guide',
      },
    }),
  );

  const { isValid } = formState;

  return (
    <div>
      <Row className="reports-content-form rounded p-3 mx-0 mb-3">
        <Col lg="5">
          <Input
            placeholder={t('Laboratory')}
            name="laboratory"
            innerRef={register({ required: true })}
            invalid={!!errors?.laboratory}
          />
        </Col>
        <Col lg="5">
          <Input
            name="identification"
            placeholder={t('Identification')}
            innerRef={register({ required: true })}
            invalid={!!errors?.identification}
          />
        </Col>
        <Col lg="2" className="d-flex justify-content-end align-items-center">
          {isValid && (
            <Button
              small
              htmlType="button"
              onClick={onSubmit}
              isLoading={loading || attaching}
            >
              {t('common:Add')}
            </Button>
          )}
        </Col>
      </Row>

      <Controller
        name="files"
        control={control}
        rules={{ required: true }}
        defaultValue={getValues('files') ?? ''}
        render={({ onChange, value }) => (
          <UploadReportFiles
            onChange={onChange}
            value={value}
            errors={errors}
          />
        )}
      />
    </div>
  );
};

export default NewReportForm;
