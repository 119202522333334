import {
  DocumentNode,
  OperationVariables,
  MutationHookOptions,
} from '@apollo/client';
import { useState } from 'react';

interface DataType<T> {
  data?: T;
  loading?: boolean;
}
interface MutatorOptions {
  variables: Record<string, unknown>;
}

interface MutationFakeHookOptions<T>
  extends MutationHookOptions<T, OperationVariables> {
  defaultValue: T;
}

export default function useFakeMutation<T>(
  gql: DocumentNode,
  options: MutationFakeHookOptions<T>,
): [(args: MutatorOptions) => void, DataType<T | null>] {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<T | null>(null);

  const mutate = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setData(options.defaultValue);
      options.onCompleted?.(options.defaultValue);
    }, 500);
  };

  return [mutate, { data, loading }];
}

// HOW TO USE
// const defaultValue: User = {
//   id: 1,
//   name: 'Username',
// };

// const [login, { data, loading }] = useFakeMutation<User>(
//   gql`
//     mutation login($email: String!, $password: String!) {
//       login(email: $email, password: $password) {
//         access_token
//       }
//     }
//   `,
//   {
//     defaultValue,
//     onCompleted: (data) => console.log({ data }),
//   },
// );

// <Button
//     isLoading={loading}
//     disabled={loading}
//     onClick={() =>
//         login({
//         variables: {
//             id: 1,
//             name: 'Username',
//         },
//         })
//     }
// >
//     Login
// </Button>
