import { gql } from '@apollo/client';

export const PRODUCT_DETAIL_GQL = gql`
  query productPutQuery($id: ID!) {
    product(id: $id) {
      id
      name
      description
      sku
      lot_id
      tray_id
      boxes {
        id
        name
      }
      crates {
        id
        name
      }
      category {
        id
        name
      }
      productStatus {
        id
        name
      }
      office {
        id
        name
        address
        phone
        postal_code
        city
        state
        country {
          name
        }
      }
      pages {
        ... on ProductPageId {
          id
          name
          position
          price_per_carat
          carat_weight
          total_price
        }
      }
    }
  }
`;

export const PRODUCT_PAGE_ID_PART = gql`
  fragment productPageId_Part on ProductPageId {
    id
    name
    price_per_carat
    carat_weight
    total_price
    form_values
    fields
    shapes {
      id
      title
      image_url
    }
    category {
      id
      name
      mixed
      categoryType {
        id
      }
      template {
        layout
        shapes {
          id
          title
          value
          image_url
          image_highlight_url
          image_selected_url
          row_position
          shapeGroup {
            id
            title
          }
          descriptions {
            id
            name
            group
            display_type
            icon_url
          }
        }
        fields {
          id
          label
          is_highlight
          is_required
          property {
            name
            key
            dataType {
              name
              key
            }
          }
          component {
            id
            key
            name
          }
          values {
            id
            name
            key
            options
            image
            variations {
              id
              name
              variationValues {
                id
                name
                key
                options
                image
                image_url
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_PAGE_ID_GQL = gql`
  query productPageIdPut($pageId: ID!) {
    productPageId(id: $pageId) {
      ...productPageId_Part
    }
  }
  ${PRODUCT_PAGE_ID_PART}
`;
