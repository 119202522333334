import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import ReturnButton from '../../../../../../components/ReturnButton';
import { TemplateLayoutContext } from '../../../../../../hooks/useTemplateLayout';
import TemplateFieldItem from './TemplateFieldItem';

const TemplateLayoutSidebar: React.FC = () => {
  const { filteredFields } = useContext(TemplateLayoutContext);
  const params = useParams<ParamTypes>();
  return (
    <div className="template-layout-container-sidebar">
      <ReturnButton
        route={`/crm/templates/${params.id}/edit`}
        title="Return to template"
      />
      {filteredFields?.map((field) => (
        <TemplateFieldItem
          field={field}
          key={`${field.type}-${field.id}`}
          isDropped={false}
        />
      ))}
    </div>
  );
};

export default TemplateLayoutSidebar;
