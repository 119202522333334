import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import './styles.scss';

const Footer: React.FC = () => {
  const currentYear = useRef(moment().get('year')).current;
  return (
    <div className="footer d-flex justify-content-between align-items-center">
      <p className="d-flex flex-column">
        <a
          href="https://www.worldgemtrade.com/"
          rel="noreferrer"
          target="_blank"
        >
          Copyright &copy; {currentYear} WorldGemTrade Inc.
        </a>
        <span>All rights reserved</span>
      </p>
      <ul className="list-inline mb-0">
        <li className="list-inline-item">
          <Link to={`/terms-of-use/`} className="nav-link">
            Terms of use
          </Link>
        </li>
        <li className="list-inline-item">
          <Link to={`/pivacy-policy/`} className="nav-link">
            Privacy Police
          </Link>
        </li>
        <li className="list-inline-item">
          <Link to={`/refund-policy/`} className="nav-link">
            Refund &amp; return policy
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Footer;
