import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import PolicyCard from '../PolicyCard';
import { LIST_POLICIES_GQL } from '../graphql';
import { Policy } from '@wgt/types';
import Loader from '../../../../../../../components/_organisms/Loader';
import usePointOfSaleUpdate from '../../../usePointOfSaleUpdate';

export default function FavouriteList(): JSX.Element {
  const { filterFavouritePolicies } = usePointOfSaleUpdate();

  const { data, loading } = useQuery<{ policies: { data: Policy[] } }>(
    LIST_POLICIES_GQL,
    {
      variables: {
        filter: {
          ...(filterFavouritePolicies && {
            title: `%${filterFavouritePolicies}%`,
          }),
          favorite: true,
        },
      },
    },
  );

  const policies = useMemo(() => data?.policies?.data ?? [], [
    data?.policies?.data,
  ]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center mt-3">
        <Loader round />
      </div>
    );
  }

  return (
    <div className="border border-bottom-0 rounded-top favourite-list">
      <div className="h-100 overflow-auto">
        {policies.map((policy: Policy) => (
          <PolicyCard key={policy.id} policy={policy} />
        ))}
      </div>
    </div>
  );
}
