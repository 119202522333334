import { useMutation } from '@apollo/client';
import { TemplateSearchLayoutRow } from '@wgt/types';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { TemplateLayoutContext } from '../../../../../../../hooks/useTemplateLayout';
import useToast from '../../../../../../../hooks/useToast';
import { UPDATE_TEMPLATE_GQL } from '../../../../graphql';
import ResultHeaderBuilder from '../../TableLayoutHeader';
import LayoutSection from './LayoutSection';

import './styles.scss';
const TemplateLayoutStructure: React.FC = () => {
  const { structure, layoutField, sections } = useContext(
    TemplateLayoutContext,
  );
  const { t } = useTranslation('template');
  const params = useParams<ParamTypes>();
  const { toast } = useToast();

  const newLayout = useMemo(
    () =>
      Object.keys(structure)?.reduce(
        (acc, item) => ({
          ...acc,
          [item]: structure[item]?.map((rowItems: TemplateSearchLayoutRow) =>
            rowItems.map((rowItem) => ({
              ...rowItem,
              group: {
                ...rowItem.group,
                content: rowItem.group.content.map((rowItemGroupContent) => {
                  return {
                    ...rowItemGroupContent,
                    content: rowItemGroupContent.content?.id,
                    searchLayoutClassStyle:
                      rowItemGroupContent.searchLayoutClassStyle,
                  };
                }),
              },
            })),
          ),
        }),
        { first: [], second: [], third: [] },
      ),
    [structure],
  );

  const variables = useMemo(() => {
    return {
      id: params.id,
      template: {
        [layoutField]: JSON.stringify(newLayout),
      },
    };
  }, [layoutField, params, newLayout]);

  const [updateTemplate, { loading }] = useMutation(UPDATE_TEMPLATE_GQL, {
    variables,
    onCompleted: () => toast('success', t('Layout saved successfully')),
  });

  const onSubmit = () => {
    const hasInvalidColumnSize = Object.keys(newLayout).some((section) => {
      const rows = newLayout[section] as TemplateSearchLayoutRow[];
      return rows.some(
        (cols) => cols.reduce((acc, col) => Number(col.size) + acc, 0) > 12,
      );
    });

    if (hasInvalidColumnSize) {
      toast('error', t('Check the sum of column size. It cannot exceeds 12'));
      return;
    }

    return updateTemplate();
  };

  return (
    <div className="template-search-layout-dnd">
      {layoutField === 'search_layout' && <ResultHeaderBuilder />}
      {sections.map((section) => (
        <LayoutSection
          key={`section-${section}`}
          loading={loading}
          updateTemplate={onSubmit}
          section={section}
        />
      ))}
    </div>
  );
};

export default TemplateLayoutStructure;
