import React, { useEffect, useMemo, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Pane } from 'evergreen-ui';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Col, Form, Row } from 'reactstrap';
import moment from 'moment';
import TemplateLayout from '../../../../components/TemplateLayout';
import CustomHeader from '../../../../components/_molecules/CustomHeader';
import useCrmRoutes from '../../../../hooks/useCrmRoutes';
import CommonForm from '../CommonForm';
import CreatedAtRange from '../../../../components/CreatedAtRange';
import { GET_CATEGORY_GQL, SEARCH } from '../graphql';
import Results from '../Results';
import SearchActions from '../SearchActions';
import { CategoryFormProps } from '../types';
import ProductStatus from './ProductStatus';
import SearchShapes from './SearchShapes';
import SearchTabs from './SearchTabs';
import PointOfSalesActions from '../PointOfSalesActions';
import './styles.scss';
import {
  CategoryBase,
  LayoutStructure,
  ProductBase,
  TemplateWithFieldsLayout,
  Shape,
} from '@wgt/types';
import {
  convertJsonLayoutToTemplateFields,
  convertPageIdFieldsSearch,
} from '@wgt/converters';
import BagProvider from '../../../../services/bag';
import { Button } from '../../../../components';
import AddProductFromSearch from '../AddProductFromSearch';
import { CREATE_PRODUCT_GQL } from '../../Categories/graphql';
import { useTranslation } from 'react-i18next';
import useToggler from '@wgt/shared/hooks/useToggler';
import WorkOrderScheduleModal from '../../../../components/WorkOrderSchedule';

interface TemplateWithShape extends TemplateWithFieldsLayout {
  shapes?: Shape[];
}

const Category: React.FC = () => {
  const [selectedProducts, setSelectedProducts] = useState<ProductBase[]>([]);
  const [newProductId, setNewProductId] = useState<number | null>(null);
  const { setCustomRoutes, routes } = useCrmRoutes();
  const params = useParams<ParamTypes>();
  const { t } = useTranslation();
  const [isScheduleOpened, toggleScheduleOpened] = useToggler();
  const [createdScheduleId, setCreatedScheduleId] = useState<string>();
  const [search, { data: searchData, loading: searching }] = useLazyQuery(
    SEARCH,
  );

  const { data } = useQuery<{ category: CategoryBase }>(GET_CATEGORY_GQL, {
    fetchPolicy: 'cache-first',
    skip: !params?.category,
    variables: {
      id: params.category,
    },
  });

  useEffect(() => {
    if (data?.category?.id) {
      setCustomRoutes([
        {
          name: data?.category?.name ?? '',
          path: `/crm/search/categories/${data?.category?.id}/products`,
        },
      ]);
    }
  }, [routes, data?.category]);

  const methods = useForm({
    shouldUnregister: false,
    defaultValues: {
      boxes: [{ name: '' }],
      crates: [{ name: '' }],
      pages_number: 1,
      shapes: [],
    },
  });

  const template = useMemo(
    () => data?.category?.template as TemplateWithShape,
    [data?.category?.template],
  );

  const [first, second, third] = useMemo(() => {
    const searchLayout = template?.search_layout as LayoutStructure;

    if (!searchLayout) {
      return [[], []];
    }

    const fields = (template?.fields ?? []).filter(
      (templateField) => templateField.is_searchable,
    );

    return Object.values(searchLayout).map((layout) =>
      convertJsonLayoutToTemplateFields({
        layout,
        fields: fields.map((x) => ({ ...x, is_required: false })),
      }),
    );
  }, [template]);

  const onSubmit: SubmitHandler<CategoryFormProps> = ({
    page,
    ...variables
  }) => {
    const { id: pageId, price_per_carat, carat_weight, ...formValues } = page;

    const pagePerCarat = {
      from: price_per_carat?.from?.length ? price_per_carat.from : undefined,
      to: price_per_carat?.to?.length ? price_per_carat.to : undefined,
    };

    const pageCaratWeight = {
      from: carat_weight?.from?.length ? carat_weight.from : undefined,
      to: carat_weight?.to?.length ? carat_weight.to : undefined,
    };

    const pages = {
      ...(pageId && { name: `%${pageId}%` }),
      price_per_carat:
        pagePerCarat.from || pagePerCarat.to ? pagePerCarat : undefined,
      carat_weight:
        pageCaratWeight.from || pageCaratWeight.to
          ? pageCaratWeight
          : undefined,
      ...(Object.keys(formValues).length && {
        form_values: JSON.stringify(convertPageIdFieldsSearch(formValues)),
      }),
    };

    const totalPrice = {
      from: variables.total_price?.from
        ? variables.total_price.from
        : undefined,
      to: variables.total_price?.to ? variables.total_price.to : undefined,
    };

    search({
      variables: {
        filter: {
          product_status_id: variables.product_status_id?.length
            ? variables.product_status_id
            : undefined,
          lot_id: variables.lot_id?.length ? variables.lot_id : undefined,
          tray_id: variables.tray_id?.length ? variables.tray_id : undefined,
          sku: variables.sku ? `%${variables.sku}%` : undefined,
          name: variables.name ? `%${variables.name}%` : undefined,
          category_id: params.category,
          total_price:
            totalPrice.from || totalPrice.to ? totalPrice : undefined,
          pages,
          created_at:
            variables.created_at?.from || variables.created_at?.to
              ? {
                  from: variables.created_at?.from
                    ? moment(variables.created_at.from).format(
                        'YYYY-M-D HH:mm:ss',
                      )
                    : undefined,
                  to: variables.created_at?.to
                    ? moment(variables.created_at.to).format(
                        'YYYY-M-D HH:mm:ss',
                      )
                    : undefined,
                }
              : undefined,

          ...(variables.shapes?.length && {
            shapes: variables.shapes?.map((item) => item.id),
          }),

          // box_id: variables.boxes.map((box) => box.name), -> waiting for backend make it available
          // crate_id: variables.crates.map((crate) => crate.name), -> waiting for backend make it available
          // myStock: variables.myStock, -> waiting for backend make it available
        },
      },
    });
  };
  const [createProduct, { loading: creatingProduct }] = useMutation(
    CREATE_PRODUCT_GQL,
    {
      variables: {
        category_id: Number(params.category),
      },
      onCompleted: ({ createProduct: dataResult }) =>
        setNewProductId(dataResult.id),
    },
  );

  if (!data?.category?.id) {
    return <React.Fragment />;
  }
  return (
    <>
      <BagProvider
        value={{
          selectedProducts: {
            list: selectedProducts,
            handleAddProduct: (product: ProductBase) =>
              setSelectedProducts((products) =>
                products.some((x) => x.id === product.id)
                  ? products.filter((p) => p.id !== product.id)
                  : [...products, product],
              ),
          },
          setNewProductId,
          newProductId,
        }}
      >
        <Pane className="search-screen-content-holder">
          <SearchActions categoryName={data?.category.name} />
          <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
              <Pane className="search-form-holder p-3">
                <Row>
                  <Col md={4}>
                    <CommonForm onSubmit={onSubmit} />
                  </Col>
                  <Col md={8}>
                    <ProductStatus />
                    <TemplateLayout
                      rows={first}
                      prefix="page."
                      component="searchComponent"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TemplateLayout
                      rows={second}
                      prefix="page."
                      component="searchComponent"
                    />
                  </Col>
                </Row>

                <SearchShapes
                  shapes={template?.shapes}
                  productType={data?.category?.productType}
                />
              </Pane>
              <SearchTabs row={third} loading={searching} />
              <Pane
                marginTop="20px"
                display="flex"
                alignItems="flex-start"
                justifyContent="space-between"
              >
                <Pane display="flex" alignItems="center">
                  <CustomHeader title="Results" />
                  <CreatedAtRange onSubmit={onSubmit} loading={searching} />
                </Pane>

                <Pane
                  display="flex"
                  alignItems="flex-end"
                  flexDirection="column"
                  className="mb-3"
                >
                  <Button
                    className="my-3"
                    onClick={() =>
                      newProductId ? setNewProductId(null) : createProduct()
                    }
                    isLoading={creatingProduct}
                    small
                  >
                    {newProductId ? t('Cancel') : t('Add')}
                  </Button>
                  <Pane display="flex" alignItems="center">
                    <Button
                      type="secondary"
                      onClick={toggleScheduleOpened}
                      className="m-2"
                      disabled={!selectedProducts.length}
                    >
                      {t('Calendar')}
                    </Button>
                    <PointOfSalesActions />
                  </Pane>
                </Pane>
              </Pane>
            </Form>
          </FormProvider>
          {!!newProductId && <AddProductFromSearch />}
          <Results
            category={data?.category}
            results={{
              products:
                searchData?.products?.data?.filter(
                  (product: ProductBase) => !!product.sku,
                ) ?? [],
              paginator: searchData?.products?.paginatorInfo ?? {},
            }}
            loading={searching}
          />
        </Pane>
      </BagProvider>
      {isScheduleOpened && (
        <WorkOrderScheduleModal
          products={selectedProducts}
          toggle={toggleScheduleOpened}
          scheduleId={createdScheduleId}
          onCompleted={({ id }) => setCreatedScheduleId(id)}
        />
      )}
    </>
  );
};

export default Category;
