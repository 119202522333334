import { Dialog } from 'evergreen-ui';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { CreateReportModalProps } from '../../types';

import NewReportForm from './NewReportForm';

const CreateReportModal: React.FC<CreateReportModalProps> = ({
  isShown,
  onClose,
  isLoading,
  handleReportCreatedSuccess,
  query,
  productId,
}) => {
  const methods = useForm();

  return (
    <FormProvider {...methods}>
      <Dialog
        isShown={isShown}
        hasFooter={false}
        onCloseComplete={onClose}
        isConfirmLoading={isLoading}
        title={'Add laboratory Report'}
        confirmLabel={isLoading ? 'Loading...' : 'Confirm'}
        width={800}
        preventBodyScrolling
      >
        <NewReportForm
          query={query}
          productId={productId}
          handleReportCreatedSuccess={handleReportCreatedSuccess}
        />
      </Dialog>
    </FormProvider>
  );
};

export default CreateReportModal;
