import React from 'react';
import { Pane } from 'evergreen-ui';
import PageIds from '../../../Update/Header/PageIDs';
import Actions from '../../../Update/Header/Actions';
import AddPageId from './AddPageId';
import { useProductUpdate } from '../../../Update/Provider';
import './styles.scss';

interface PageIdActionsProps {
  productId?: string;
  loading?: boolean;
}
const PageIdActions: React.FC<PageIdActionsProps> = ({
  productId,
  loading,
}) => {
  const { isFormEnabled } = useProductUpdate();
  return (
    <Pane display="flex" justifyContent="space-between" marginTop="20px">
      <Pane>{isFormEnabled && <AddPageId />}</Pane>
      <Pane alignItems="flex-end" display="flex" flexDirection="column">
        {!!productId && <PageIds productId={productId} />}
        <Actions productId={productId} loading={loading} />
      </Pane>
    </Pane>
  );
};

export default PageIdActions;
