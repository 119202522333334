import React, { useMemo } from 'react';
import { CategoryGroup } from '@wgt/types';
import { Pane } from 'evergreen-ui';
import { Collapse } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { useCollapseContext } from '../../../services/collapse';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import CategoriesList from './CategoriesList';

import {
  PRODUCT_TYPE_SINGLE_ID,
  PRODUCT_TYPE_PARCEL_ID,
} from '@wgt/shared/constants';

interface CategoryGroupitemProps {
  categoryGroup: CategoryGroup;
}

const CategoryGroupitem: React.FC<CategoryGroupitemProps> = ({
  categoryGroup,
}) => {
  const { t } = useTranslation('menus');
  const { active, set } = useCollapseContext();
  const isActive = useMemo<boolean>(
    () => active?.toString() === categoryGroup.id,
    [categoryGroup.id, active],
  );
  return (
    <Pane
      key={`category-group-${categoryGroup.id}`}
      className="navbar-categories-container-item"
    >
      <button
        onClick={() => set(categoryGroup.id?.toString() ?? '')}
        className={classnames({ active: isActive })}
      >
        {categoryGroup.name}
        {isActive ? <FaChevronUp /> : <FaChevronDown />}
      </button>
      <Collapse isOpen={isActive}>
        <Pane className="ml-3 mt-2 d-flex">
          <CategoriesList
            categoryGroup={categoryGroup}
            title={t('Single, Pair, Triplets')}
            productTypeId={PRODUCT_TYPE_SINGLE_ID}
          />
          <CategoriesList
            categoryGroup={categoryGroup}
            title={t('Per Stone, Per Shape, Multiple Shapes')}
            productTypeId={PRODUCT_TYPE_PARCEL_ID}
          />
        </Pane>
      </Collapse>
    </Pane>
  );
};

export default CategoryGroupitem;
