import React from 'react';
import { Pane } from 'evergreen-ui';
import { Button } from '../../../../components';
import CustomHeader from '../../../../components/_molecules/CustomHeader';
import { FaVideo } from 'react-icons/fa';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface SearchActionsProps {
  categoryName?: string;
}
const SearchActions: React.FC<SearchActionsProps> = ({ categoryName }) => {
  const params = useParams<ParamTypes>();
  const history = useHistory();
  const { t } = useTranslation('products');
  // const [createProduct, { loading: creatingProduct }] = useMutation(
  //   CREATE_PRODUCT_GQL,
  //   {
  //     variables: {
  //       category_id: Number(params.category),
  //     },
  //     onCompleted: ({ createProduct: dataResult }) =>
  //       history.push(`/crm/products/${dataResult.id}/edit`),
  //   },
  // );

  return (
    <Pane className="d-flex justify-content-between align-items-center flex-1">
      <Pane className="flex-1">
        <CustomHeader title={categoryName} />
      </Pane>
      <div className="d-flex justify-content-end ">
        <Button
          htmlType="button"
          type="link"
          className="btn btn-outline-secondary mr-2"
        >
          <FaVideo /> {t('Intro')}
        </Button>

        <Button
          htmlType="button"
          onClick={() =>
            history.push(`/crm/categories/${params.category}/products/create`)
          }
        >
          {`Add ${categoryName}`}
        </Button>
      </div>
    </Pane>
  );
};

export default SearchActions;
