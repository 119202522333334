import React from 'react';

import { IconButton, Pane } from 'evergreen-ui';
import { Button, SimpleInput } from '../../../../components';
import { useFieldArray } from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import { FaTrash } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

export default function ShapeAbbreviations(): JSX.Element {
  const { t } = useTranslation('shapes');
  const { fields, append, remove } = useFieldArray({
    name: 'abbreviations',
  });
  return (
    <Row>
      <Col sm={12} md={3}>
        <Pane display="flex" justifyContent="space-between" margin={5}>
          <label>{t('Abbreviations')}</label>
          <Button small htmlType="button" onClick={() => append({ name: '' })}>
            {t('add')}
          </Button>
        </Pane>
        {fields.map((field, index) => (
          <Pane
            margin={5}
            key={field.id}
            display="flex"
            justifyContent="space-between"
          >
            <SimpleInput
              field={`abbreviations[${index}].name`}
              placeholder={t('Abbreviation')}
              className="w-75"
              defaultValue={field.name}
            />
            <IconButton
              icon={FaTrash}
              intent="danger"
              onClick={() => remove(index)}
            />
          </Pane>
        ))}
      </Col>
    </Row>
  );
}
