import React from 'react';
import CustomHeader from '../../../../components/_molecules/CustomHeader';
import UserCalendar from '../../../../components/UserCalendar';

export default function CalendarDetail(): JSX.Element {
  return (
    <div>
      <CustomHeader title="Calendar" />
      <UserCalendar />
    </div>
  );
}
