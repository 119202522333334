import { HomeLocation } from '@wgt/types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { Button, Text } from '..';
import GoogleMaps from './GoogleMaps';

export default function ProfileAddressItem({
  address,
}: {
  address: HomeLocation;
}): JSX.Element {
  const { t } = useTranslation('profile');
  return (
    <Row className="mb-5">
      <Col className="d-flex flex-column">
        <div className="mb-3">
          <Text className="mr-3" color="#D31A1A" bold>
            {t('Emergency Contact')}
          </Text>{' '}
          <Button small>add</Button>
        </div>
        <Text>{address.address1}</Text>
        <Text>{`${address.city} ${address.state}`}</Text>
        <Text>{`${address.country?.name}, ${address.zipcode}`}</Text>

        <Text className="mt-4" bold>
          {address.email}
        </Text>
        <Text>{t('E-mail')}</Text>
      </Col>
      <Col className="d-flex flex-column">
        <Row>
          <Col className="d-flex flex-column">
            <Text bold>{address.toll_free_phone}</Text>
            <Text>{t('Toll Free Phone')}</Text>
          </Col>
          <Col className="d-flex flex-column">
            <Text bold>{address.local_phone}</Text>
            <Text>{t('Local Phone')}</Text>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col className="d-flex flex-column">
            <Text bold>{address.mobile}</Text>
            <Text>{t('Mobile')}</Text>
          </Col>
          <Col className="d-flex flex-column">
            <Text bold>{address.fax}</Text>
            <Text>{t('Fax')}</Text>
          </Col>
        </Row>
      </Col>
      <Col>
        <GoogleMaps
          addressLine1={`${address.address1}, ${address.city}, ${address.state}`}
          addressLine2={` ${address.zipcode}, ${address.country?.name}`}
        />
      </Col>
    </Row>
  );
}
