import React, { useEffect } from 'react';

import Loader from '../../components/_organisms/Loader';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  history: any;
}

const Home: React.FC<Props> = ({ history }) => {
  useEffect(() => {
    setTimeout(() => {
      history.push('/crm/profile');
    }, 500);
  }, [history]);

  return <Loader />;
};

export default Home;
