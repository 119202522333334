import { gql } from '@apollo/client';
import { paginatorInfoFragment } from '../../../services/graphql/paginator.gql';

export const shapeIdentityFragment = gql`
  fragment shapeIdentity_Part on Shape {
    id
    image
    image_url
    image_selected_url
    image_highlight
    image_highlight_url
    abbreviations
    title
    value
    row_position
    descriptions {
      id
    }
    shapeGroup {
      id
      title
    }
  }
`;

export const shapeSpecialityIdentity = gql`
  fragment shapeSpecialityIdentity_Part on ShapeSpeciality {
    id
    image
    image_url
    title
    shape {
      id
      title
    }
  }
`;

export const LIST_SHAPES_GQL = gql`
  query shapes($page: Int, $filter: ShapeFilterInput) {
    shapes(page: $page, filter: $filter) {
      data {
        ...shapeIdentity_Part
      }
      paginatorInfo {
        ...paginatorInfo_Part
      }
    }
  }
  ${shapeIdentityFragment}
  ${paginatorInfoFragment}
`;

export const DELETE_SHAPES_GQL = gql`
  mutation deleteShape($id: ID!) {
    deleteShape(id: $id) {
      message
    }
  }
`;

export const GET_SHAPES_GQL = gql`
  query shape($id: ID!, $isEdit: Boolean!) {
    shape(id: $id) @include(if: $isEdit) {
      ...shapeIdentity_Part
      shapeGroup {
        id
      }
    }
    shapeDescriptions(first: 1000) {
      data {
        id
        name
        display_type
        group
        icon
        icon_url
      }
    }
  }
  ${shapeIdentityFragment}
`;

export const CREATE_SHAPES_GQL = gql`
  mutation createShape(
    $shape_group_id: ID!
    $image: Upload!
    $title: String!
    $value: String
    $abbreviations: [String]
    $image_selected: Upload
    $image_highlight: Upload
    $row_position: RowPosition
    $position: Int
    $descriptions: [ShapeDescriptionInput]
  ) {
    createShape(
      shape_group_id: $shape_group_id
      image: $image
      title: $title
      value: $value
      abbreviations: $abbreviations
      image_selected: $image_selected
      image_highlight: $image_highlight
      row_position: $row_position
      position: $position
      descriptions: $descriptions
    ) {
      id
    }
  }
`;

export const UPDATE_SHAPES_GQL = gql`
  mutation updateShape($id: ID!, $shape: UpdateShapeInput!) {
    updateShape(id: $id, shape: $shape) {
      id
    }
  }
`;

export const LIST_SHAPE_SPECIALITIES_GQL = gql`
  query shapeSpecialities($page: Int, $filter: ShapeSpecialityFilterInput) {
    shapeSpecialities(page: $page, filter: $filter) {
      data {
        ...shapeSpecialityIdentity_Part
      }
      paginatorInfo {
        ...paginatorInfo_Part
      }
    }
  }
  ${shapeSpecialityIdentity}
  ${paginatorInfoFragment}
`;

export const DELETE_SHAPE_SPECIALITY_GQL = gql`
  mutation deleteShapeSpeciality($id: ID!) {
    deleteShapeSpeciality(id: $id) {
      message
    }
  }
`;

export const CREATE_SHAPE_SPECIALITIES_GQL = gql`
  mutation createShapeSpeciality(
    $shape_id: ID!
    $image: Upload!
    $title: String!
  ) {
    createShapeSpeciality(shape_id: $shape_id, image: $image, title: $title) {
      id
    }
  }
`;

export const GET_SHAPE_SPECIALITY_GQL = gql`
  query shapeSpeciality($id: ID!, $isEdit: Boolean!) {
    shapeSpeciality(id: $id) @include(if: $isEdit) {
      ...shapeSpecialityIdentity_Part
      shape {
        id
      }
    }
  }
  ${shapeSpecialityIdentity}
`;

export const UPDATE_SHAPE_SPECIALITIES_GQL = gql`
  mutation updateShapeSpeciality(
    $id: ID!
    $shapeSpeciality: UpdateShapeSpecialityInput!
  ) {
    updateShapeSpeciality(id: $id, shapeSpeciality: $shapeSpeciality) {
      id
    }
  }
`;
