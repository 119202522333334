import { useMutation, useQuery } from '@apollo/client';
import { PropertyValueVariation } from '@wgt/types';
import React, { useContext, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../../../../../../../components';
import { TemplateFieldsContext } from '../../../../../../../../hooks/useTemplateFieldsContext';
import useToast from '../../../../../../../../hooks/useToast';
import { LIST_PROPERTY_VALUE_VARIATIONS_GQL } from '../../../../../../PropertyValueVariation/graphql';
import { ATTACH_TEMPLATE_FIELD_VALUE_VARIATION } from '../../../../../graphql';

import { FieldPropertyValueVariationFormProps } from '../../../../../types';
import { GET_TEMPLATE_FIELD_VALUES } from './graphql';
const FieldPropertyValueVariationForm: React.FC<FieldPropertyValueVariationFormProps> = ({
  propertyValue,
  variations,
}) => {
  const { active } = useContext(TemplateFieldsContext);
  const { toast } = useToast();

  const [attach, { loading: attaching }] = useMutation(
    ATTACH_TEMPLATE_FIELD_VALUE_VARIATION,
    {
      refetchQueries: [
        {
          query: GET_TEMPLATE_FIELD_VALUES,
          variables: { templateId: active?.id },
        },
      ],
      onCompleted: () => toast('success'),
    },
  );

  const { data } = useQuery(LIST_PROPERTY_VALUE_VARIATIONS_GQL);
  const filteredVariations = useMemo<PropertyValueVariation[]>(
    () =>
      data?.propertyValueVariations?.data?.filter(
        (propertyValueVariation: PropertyValueVariation) =>
          !variations
            .map((x) => x.id?.toString())
            .includes(propertyValueVariation.id?.toString()),
      ) ?? [],
    [data, active, variations],
  );

  const { handleSubmit, register } = useForm();

  const onSubmit = handleSubmit((variables) =>
    attach({
      variables: {
        template_field_id: active?.id,
        property_value_id: propertyValue.id,
        property_value_variation_id: variables.variation,
      },
    }),
  );

  return (
    <form className="m-3" onSubmit={onSubmit}>
      <div className="row">
        <div className="col-9">
          <select
            className="form-control"
            name="variation"
            ref={register({ required: true })}
          >
            <option value="">Select a variation</option>
            {filteredVariations.map((variation) => (
              <option value={variation.id} key={variation.id}>
                {variation.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-3">
          <Button htmlType="submit" disabled={attaching} isLoading={attaching}>
            save
          </Button>
        </div>
      </div>
    </form>
  );
};

export default FieldPropertyValueVariationForm;
