/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Row, Col } from 'reactstrap';
import { PageIdDetail, Shape, TemplateLayoutRow } from '@wgt/types';
import { useTranslation } from 'react-i18next';
import CardLayoutText from '../../../../../components/CardLayoutText';
import { Title, Line } from '../../../../../components';
import Section from '../Components/Section';
import setCurrencyMaskInput from '../../../../../helpers/CurrencyMaskInput';
import ProductPageIdNavigator from '../Components/PageIdNavigator';
import { Route } from 'react-router-dom';

interface Props {
  rows: TemplateLayoutRow[];
  pageIdDetails: PageIdDetail;
  values: any;
  fields: any;
}

export default function FirstSection({
  rows,
  pageIdDetails,
  values,
  fields,
}: Props): JSX.Element {
  const { t } = useTranslation(['product', 'common']);

  return (
    <div className="mt-4">
      <Row>
        <Col xs="4" className="d-flex align-items-center">
          <Title heading="h2">{t('Advanced')}</Title>
        </Col>
        <Col
          xs="8"
          className="d-none d-sm-flex align-items-center justify-content-end "
        >
          <Route
            path="/crm/products/:productId/pages/:pageId"
            component={ProductPageIdNavigator}
          />
        </Col>
      </Row>
      <Line />
      <Row className="product-advanced-content">
        <Col lg="2">
          <CardLayoutText
            content={[
              {
                header: t('Total Price'),
                content: setCurrencyMaskInput(
                  pageIdDetails.total_price?.toFixed(2).toString() ?? '0.00',
                ),
              },
            ]}
            containerClass="mb-4"
          />

          <CardLayoutText
            content={[
              {
                header: t('Price per Carat'),
                content: setCurrencyMaskInput(
                  pageIdDetails.price_per_carat?.toFixed(2).toString() ??
                    '0.00',
                ),
              },
            ]}
            containerClass="mb-4"
          />

          <CardLayoutText
            content={[
              {
                header: t('Carat Weight'),
                content: setCurrencyMaskInput(
                  pageIdDetails.carat_weight?.toFixed(2).toString() ?? '0.00',
                ),
              },
            ]}
          />
        </Col>
        <Col lg="2" className="shape-and-price">
          <Row noGutters className="shape-container">
            {pageIdDetails.shapes?.map((shape: Shape, index: number) => (
              <Col
                key={`shape-${index}`}
                className="col col-lg-12 d-flex d-flex flex-column-reverse flex-lg-column"
              >
                <div>
                  <img
                    src={shape.image_url}
                    alt={shape.title}
                    className="w-100"
                  />
                </div>
                <div className="shape-title">
                  <p className="text-center font-regular">{shape.title}</p>
                </div>
              </Col>
            ))}
          </Row>
        </Col>
        <Col lg="8">
          <Section rows={rows} values={values} fields={fields} />
        </Col>
      </Row>
    </div>
  );
}
