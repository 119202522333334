import { Pane } from 'evergreen-ui';
import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Viewer } from '@react-pdf-viewer/core';
import { FaFileAlt, FaLock, FaTimes } from 'react-icons/fa';
import { ReportItemProps } from '../../../types';
import { useProductUpdate } from '../../../Update/Provider';
import { useTranslation } from 'react-i18next';

const ReportItem: React.FC<ReportItemProps> = ({ report, setDeleteItem }) => {
  const [isOpen, handleOpen] = useState(false);
  const { isFormEnabled } = useProductUpdate();
  const { t } = useTranslation();

  const toggle = () => handleOpen((x) => !x);

  return (
    <>
      {isOpen &&
        report?.file_url &&
        createPortal(
          <Modal size="lg" isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>{`${report.title}`}</ModalHeader>
            <ModalBody>
              <Viewer fileUrl={report.file_url} />
            </ModalBody>
          </Modal>,
          document.getElementById('wgt-modal') ?? document.createElement('div'),
        )}
      <Pane
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginX="100px"
      >
        <button onClick={toggle} className="download-btn" type="button">
          <FaFileAlt />
          {t('View')}
        </button>

        <span>{report.title}</span>
        {isFormEnabled ? (
          <FaTimes onClick={() => setDeleteItem(report)} />
        ) : (
          <FaLock />
        )}
      </Pane>
    </>
  );
};

export default ReportItem;
