import { Shape } from '@wgt/types';
import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from '../../../../../components/_organisms/Loader';
import TemplateShapesListItem from './TemplateShapesListItem';

interface TemplateShapesListProps {
  templateId: string | number;
  shapes: Shape[];
  loading?: boolean;
  setShapeToRemove: Dispatch<SetStateAction<Shape | null>>;
}
const TemplateShapesList: React.FC<TemplateShapesListProps> = ({
  shapes,
  loading,
  templateId,
  setShapeToRemove,
}) => {
  const { t } = useTranslation('template');

  if (loading) {
    return <Loader />;
  }
  return (
    <div className="template-shapes-list">
      <label>{t('Shapes attached to this template')}</label>
      {shapes.map((shape) => (
        <TemplateShapesListItem
          setShapeToRemove={setShapeToRemove}
          shape={shape}
          templateId={templateId}
          key={`shape-${shape.id}`}
        />
      ))}
    </div>
  );
};

export default TemplateShapesList;
