import { useMemo } from 'react';
import * as qs from 'query-string';
import { useLocation } from 'react-router-dom';

interface UseQueryString {
  token?: string;
  params?: qs.ParsedQuery<string>[] | undefined;
  newUser?: string;
}

export default function useQueryString(): [UseQueryString] {
  const location = useLocation();
  const params = useMemo(() => {
    const parsed = qs.parse(location.search);

    return parsed;
  }, [location]);
  return [params];
}
