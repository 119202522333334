import React from 'react';
import { createPortal } from 'react-dom';
import { FiFileText } from 'react-icons/fi';
import { Button } from '../../../../../components';
import { ReportItemProps } from '../../types';
import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

const ReportItem: React.FC<ReportItemProps> = ({ report, setDeleteItem }) => {
  const [isOpen, handleOpen] = React.useState(false);

  const toggle = () => handleOpen((x) => !x);

  return (
    <>
      {isOpen &&
        report.file_url &&
        createPortal(
          <Modal size="lg" isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>{report.title}</ModalHeader>
            <ModalBody>
              <Viewer fileUrl={report.file_url} />
            </ModalBody>
          </Modal>,
          document.getElementById('wgt-modal') ?? document.createElement('div'),
        )}
      <tr>
        <td width="15%">
          {!!report.file_url && (
            <button type="button" onClick={toggle} className="download-btn">
              <FiFileText size={20} />
              view
            </button>
          )}
        </td>
        <td>
          <label>{report.title}</label>
        </td>
        <td className="text-right">
          {setDeleteItem && (
            <Button type="danger" onClick={() => setDeleteItem?.(report)}>
              Remove
            </Button>
          )}
        </td>
      </tr>
    </>
  );
};

export default ReportItem;
