import { gql } from '@apollo/client';
import { paginatorInfoFragment } from '../../../services/graphql/paginator.gql';

export const propertyIdentityFragment = gql`
  fragment propertyIdentity_Part on Property {
    id
    name
    key
  }
`;

export const PROPERTY_EDIT_SCREEN_GQL = gql`
  query propertyEditScreen($id: ID!, $isEdit: Boolean!) {
    property(id: $id) @include(if: $isEdit) {
      ...propertyIdentity_Part
      dataType {
        id
      }
      values {
        id
        name
        abbreviations
        key
        image
        options
        position
        is_minerals
      }
    }
    dataTypes {
      data {
        id
        name
        key
      }
    }
  }
  ${propertyIdentityFragment}
`;

export const LIST_PROPERTIES_GQL = gql`
  query propertiesQuery($page: Int) {
    properties(page: $page) {
      data {
        ...propertyIdentity_Part
      }
      paginatorInfo {
        ...paginatorInfo_Part
      }
    }
  }
  ${paginatorInfoFragment}
  ${propertyIdentityFragment}
`;

export const GET_PROPERTY_GQL = gql`
  query propertyQuery($id: ID!) {
    property(id: $id) {
      ...propertyIdentity_Part
      dataType {
        id
      }
      values {
        id
        name
        key
        image
        options
      }
    }
  }
  ${propertyIdentityFragment}
`;

export const DELETE_PROPERTY_GQL = gql`
  mutation deletePropertyMutation($id: ID!) {
    deleteProperty(id: $id) {
      message
    }
  }
`;
export const CREATE_PROPERTY_GQL = gql`
  mutation createPropertyMutation(
    $data_type_id: ID!
    $name: String!
    $key: String!
  ) {
    createProperty(data_type_id: $data_type_id, name: $name, key: $key) {
      id
    }
  }
`;
export const UPDATE_PROPERTY_GQL = gql`
  mutation updatePropertyMutation($id: ID!, $property: UpdatePropertyInput!) {
    updateProperty(id: $id, property: $property) {
      id
    }
  }
`;

export const LIST_DATA_TYPES_GQL = gql`
  query dataTypesQuery {
    dataTypes(first: 1000) {
      data {
        id
        name
        key
      }
    }
  }
`;

export const CREATE_PROPERTY_VALUE_GQL = gql`
  mutation createPropertyValue(
    $property_id: ID!
    $name: String!
    $key: String!
    $image: Upload
    $options: JSON
    $abbreviations: [String]
    $plate_size: String
    $stone_number: String
    $carat_weight: String
    $mm_size: String
    $is_seive_chart: Boolean
    $is_minerals: Boolean
    $is_other: Boolean
  ) {
    createPropertyValue(
      property_id: $property_id
      name: $name
      key: $key
      image: $image
      options: $options
      abbreviations: $abbreviations
      plate_size: $plate_size
      stone_number: $stone_number
      carat_weight: $carat_weight
      mm_size: $mm_size
      is_seive_chart: $is_seive_chart
      is_minerals: $is_minerals
      is_other: $is_other
    ) {
      id
    }
  }
`;

export const CREATE_MINERAL = gql`
  mutation createMineral(
    $property_value_id: ID!
    $classification_id: ID!
    $group_id: ID
    $subgroup_id: ID
    $species_id: ID
    $variety_id: ID
    $series_id: ID
    $type: String!
    $abbreviations: [String]
  ) {
    createMineral(
      classification_id: $classification_id
      property_value_id: $property_value_id
      group_id: $group_id
      subgroup_id: $subgroup_id
      species_id: $species_id
      variety_id: $variety_id
      series_id: $series_id
      type: $type
      abbreviations: $abbreviations
    ) {
      id
    }
  }
`;

export const UPDATE_MINERAL = gql`
  mutation updateMineral($id: ID!, $mineral: UpdateMineralInput!) {
    updateMineral(id: $id, mineral: $mineral) {
      id
    }
  }
`;

export const UPDATE_PROPERTY_VALUE_GQL = gql`
  mutation updatePropertyValueMutation(
    $id: ID!
    $property_value: UpdatePropertyValueInput!
  ) {
    updatePropertyValue(id: $id, property_value: $property_value) {
      id
    }
  }
`;
export const DELETE_PROPERTY_VALUE_GQL = gql`
  mutation deletePropertyValue($id: ID!) {
    deletePropertyValue(id: $id) {
      message
    }
  }
`;

export const SORT_PROPERTY_VALUE_GQL = gql`
  mutation sortPositionPropertyValues($propertyValueIds: [ID]!) {
    sortPositionPropertyValues(propertyValueIds: $propertyValueIds) {
      message
    }
  }
`;

const propertyValueFragment = gql`
  fragment propertyValue_Entity on PropertyValue {
    id
    name
    key
    image
    options
    position
    abbreviations
    plate_size
    stone_number
    carat_weight
    mm_size
    is_seive_chart
    is_minerals
    is_other
  }
`;

export const GET_PROPERTY_VALUE_GQL = gql`
  query propertyValue($id: ID!) {
    propertyValue(id: $id) {
      ...propertyValue_Entity
      minerals {
        id
        type
        abbreviations
        group {
          id
          name
          label: name
        }
        subgroup {
          id
          name
          label: name
        }
        species {
          id
          name
          label: name
        }
        variety {
          id
          name
          label: name
        }
        series {
          id
          name
          label: name
        }
      }
    }
  }
  ${propertyValueFragment}
`;

export const LIST_PROPERTY_VALUE_GQL = gql`
  query propertyValues($filter: PropertyValueFilterInput) {
    propertyValues(filter: $filter, first: 1000) {
      data {
        ...propertyValue_Entity
      }
    }
  }
  ${propertyValueFragment}
`;

export const GET_MINERAL_GROUP = gql`
  query mineralGroup($id: ID!) {
    mineralGroup(id: $id) {
      id
      name
    }
  }
`;
export const REMOVE_MINERAL_GROUP = gql`
  mutation deleteMineralGroup($id: ID!) {
    deleteMineralGroup(id: $id) {
      message
    }
  }
`;
export const UPDATE_MINERAL_GROUP = gql`
  mutation updateMineralGroup(
    $id: ID!
    $mineralGroup: UpdateMineralGroupInput!
  ) {
    updateMineralGroup(id: $id, mineralGroup: $mineralGroup) {
      id
    }
  }
`;
