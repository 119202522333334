import { gql } from '@apollo/client';
import {
  propertyValueVariationFragment,
  propertyValueVariationValueFragment,
} from '../PropertyValueVariation/graphql';

export const shapeFragment = gql`
  fragment shape_Part on Shape {
    id
    title
    value
    image_url
    image_highlight_url
    image_selected_url
    row_position
    shapeGroup {
      id
      title
    }
    descriptions {
      id
      name
      group
      display_type
      icon_url
    }
  }
`;

export const templateFragment = gql`
  fragment template_Part on Template {
    fields {
      id
      label
      is_required
      is_searchable
      is_highlight
      is_single_shape
      config
      property {
        name
        key
        dataType {
          name
          key
        }
      }
      component {
        id
        name
        key
      }
      values {
        id
        name
        key
        options
        image
        variations {
          ...propertyValueVariationInfo_Part
          variationValues {
            ...propertyValueVariationValueInfo_Part
          }
        }
      }
    }
    shapes {
      ...shape_Part
    }
    layout
    result_header
  }
  ${propertyValueVariationFragment}
  ${propertyValueVariationValueFragment}
  ${shapeFragment}
`;

export const categoryFragment = gql`
  fragment productCategory_Part on Category {
    id
    name
    mixed
    categoryType {
      id
    }
  }
`;

export const CREATE_PRODUCT_GQL = gql`
  mutation createProductMutation($input: ProductInput!) {
    createProduct(input: $input) {
      id
    }
  }
`;

export const productIdentifierFragment = gql`
  fragment productIdentifier_Part on Product {
    id
    draft
    category {
      id
      name
      categoryType {
        id
        name
      }
      productType {
        id
        numberPages
      }
    }
  }
`;

export const boxFragment = gql`
  fragment box_Part on ProductBoxId {
    id
    name
  }
`;

export const crateFragment = gql`
  fragment crate_Part on ProductCrateId {
    id
    name
  }
`;

export const productCommonAttributesFragment = gql`
  fragment productCommonAttr_Part on Product {
    sku
    name
    description
    lot_id
    tray_id
    total_price
    crates {
      ...crate_Part
    }
    boxes {
      ...box_Part
    }
  }
  ${crateFragment}
  ${boxFragment}
`;

export const reportFragment = gql`
  fragment report_Part on ProductReport {
    id
    laboratory
    identification
    firm {
      name
    }
    productReportFile {
      file
      file_url
    }
  }
`;
export const officeFragment = gql`
  fragment officePOS_Part on Office {
    id
    name
    address
    phone
    postal_code
    city
    state
    country {
      name
    }
  }
`;

export const productPageFragment = gql`
  fragment productPage_Part on ProductPageId {
    id
    name
    position
    category {
      ...productCategory_Part
      template {
        ...template_Part
      }
    }
    files {
      id
      file_url
      type
    }
  }
  ${categoryFragment}
  ${templateFragment}
`;
export const productStatusFragment = gql`
  fragment productStatusIdentifier_Part on ProductStatus {
    id
    name
  }
`;
export const parcelTypesFragment = gql`
  fragment parcelTypeIdentifier_Part on ParcelType {
    id
    title
    description
  }
`;

export const categoryPaginationFragment = gql`
  fragment categoryPaginator_Part on CategoryPaginator {
    data {
      ...productCategory_Part
    }
  }
  ${categoryFragment}
`;
export const firmFragment = gql`
  fragment firmProduct_Part on Firm {
    id
    name
    extra {
      email
    }
    address {
      address
      state
      phone
      country {
        name
      }
    }
  }
`;

export const productEntityFragment = gql`
  fragment productEntity_Part on Product {
    ...productIdentifier_Part
    ...productCommonAttr_Part
    productStatus {
      ...productStatusIdentifier_Part
    }
    pages {
      ...productPage_Part
      fields
      price_per_carat
      carat_weight
      shapes {
        id
        image_url
        image_selected_url
        image_highlight_url
        descriptions {
          id
          name
          display_type
          group
          icon_url
        }
      }
    }

    office {
      ...officePOS_Part
    }
    category {
      ...productCategory_Part
      template {
        ...template_Part
      }
      categoryGroup {
        id
        name
      }
    }
  }
  ${productStatusFragment}
  ${categoryFragment}
  ${productIdentifierFragment}
  ${productCommonAttributesFragment}
  ${productPageFragment}
  ${officeFragment}
  ${templateFragment}
`;

export const officeProductPaginatorFragment = gql`
  fragment officeProductPaginator_Part on OfficePaginator {
    data {
      ...officePOS_Part
    }
  }
  ${officeFragment}
`;

export const ADD_STOCK_GQL = gql`
  query ADD_STOCK_GQL(
    $id: ID!
    $parcelTypeFilter: ParcelTypeFilterInput
    $officeFilter: OfficeFilterInput
  ) {
    product(id: $id) {
      ...productEntity_Part
    }
    parcelTypes(filter: $parcelTypeFilter) {
      data {
        ...parcelTypeIdentifier_Part
      }
    }
    firms(filter: { types: { id: [10] } }) {
      data {
        ...firmProduct_Part
      }
    }
    productsStatus {
      data {
        ...productStatusIdentifier_Part
      }
    }
    categories(first: 1000) {
      ...categoryPaginator_Part
    }
    offices(filter: $officeFilter) {
      ...officeProductPaginator_Part
    }
  }

  ${officeProductPaginatorFragment}
  ${categoryPaginationFragment}
  ${productEntityFragment}
  ${firmFragment}
  ${parcelTypesFragment}
  ${productStatusFragment}
`;

export const UPDATE_PRODUCT_GQL = gql`
  mutation updateProduct($productId: ID!, $product: UpdateProductInput!) {
    updateProduct(id: $productId, product: $product) {
      ...productEntity_Part
    }
  }
  ${productEntityFragment}
`;

export const UPDATE_PRODUCT_SEARCH_GQL = gql`
  mutation updateProductFromSearch(
    $productId: ID!
    $product: UpdateProductInput!
  ) {
    updateProduct(id: $productId, product: $product) {
      id
    }
  }
`;

export const DELETE_PRODUCT_BOX_ID = gql`
  mutation deleteProductBoxId($id: ID!) {
    deleteProductBoxId(id: $id) {
      id
    }
  }
`;

export const DELETE_PRODUCT_CRATE_ID = gql`
  mutation deleteProductCrateId($id: ID!) {
    deleteProductCrateId(id: $id) {
      id
    }
  }
`;

export const DELETE_PAGE_ID = gql`
  mutation deleteProductPageId($id: ID!) {
    deleteProductPageId(id: $id) {
      message
    }
  }
`;

export const CREATE_PAGE_ID = gql`
  mutation createProductPageId($product_id: ID!, $page: ProductPageIdInput!) {
    createProductPageId(product_id: $product_id, page: $page) {
      id
      name
      position
      form_values
      category {
        ...productCategory_Part
      }
    }
  }
  ${categoryFragment}
`;
