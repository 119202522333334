import { NavbarPageBase, HorizontalAlign } from '@wgt/types';
import { CgShoppingCart } from 'react-icons/cg';
import { IoSettingsSharp } from 'react-icons/io5';
import { officeMenuIcon, marketingMenuIcon } from '../../assets/wgtIcons';

export default [
  {
    name: 'point-of-sale',
    title: 'Point of Sales',
    url: '/crm/point-sales',
    icon: CgShoppingCart,
    backgroundColor: '#FCC8FF',
  },
  {
    name: 'human-resources',
    title: 'Office',
    url: '/crm/human-resources',
    backgroundColor: '#E8D6CF',
    icon: officeMenuIcon,
  },
  {
    name: 'marketing',
    title: 'Marketing',
    backgroundColor: '#FCD0CF',
    icon: marketingMenuIcon,

    children: [
      {
        name: 'website',
        title: 'Website',
        backgroundColor: '#F0FEDA',
        url: '/crm/website-analytics',
        align: HorizontalAlign.right,
      },
    ],
  },
  {
    name: 'learn-to-use',
    title: 'Learn to use',
    url: '/crm/learn-to-use',
    backgroundColor: '#C5DCFA',
    align: HorizontalAlign.left,
    icon: IoSettingsSharp,
    isOnClick: true,
  },
] as NavbarPageBase[];
