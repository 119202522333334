import React, { useMemo } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import Button from '../Button';

interface Props extends DropzoneOptions {
  contentMessage?: string;
  className?: string;
}

export default function Dropzone({
  contentMessage,
  className,
  ...props
}: Props): JSX.Element {
  const { t } = useTranslation('common');

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
  } = useDropzone({ ...props });

  const infoText = useMemo(() => {
    if (isDragReject) {
      return t('Invalid file type');
    }

    return t('Drag files to add', {
      prefix: isDragActive ? t('Drop') : t('Drag'),
      content: contentMessage ?? t('file', { count: props.maxFiles ?? 0 }),
    });
  }, [isDragReject, isDragActive, contentMessage]);

  return (
    <div className={classnames('ibox float-e-margins', className)}>
      <div className="ibox-content">
        <div className="dropzone" {...getRootProps()}>
          <input
            accept="image/*"
            type="file"
            className="d-none"
            {...getInputProps()}
          />
          <div className="dropzone-content">
            <h2 className="mb-4">{infoText}</h2>
            {!isDragActive && (
              <Button htmlType="button" small>
                {t('or click to search')}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
