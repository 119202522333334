import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { FiX } from 'react-icons/fi';

interface Note {
  author: string;
  date: string;
  description: string;
}

const FAKE_NOTES: Note[] = [
  {
    author: 'Author (from Company)',
    date: moment().format('M/D/YYYY hh:mm a'),
    description:
      'Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis ullamco cillum dolor. Voluptate exercitation incididunt aliquip deserunt reprehenderit elit laborum.',
  },
  {
    author: 'Author (from Company)',
    date: moment().format('M/D/YYYY hh:mm a'),
    description:
      'Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis ullamco cillum dolor. Voluptate exercitation incididunt aliquip deserunt reprehenderit elit laborum.',
  },
  {
    author: 'Author (from Company)',
    date: moment().format('M/D/YYYY hh:mm a'),
    description:
      'Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis ullamco cillum dolor. Voluptate exercitation incididunt aliquip deserunt reprehenderit elit laborum.',
  },
];

export default function WorkOrderNotes(): JSX.Element {
  const { t } = useTranslation('workOrder');
  return (
    <div className="form-group h-100 overflow-auto">
      <h4>{t('Notes')}</h4>
      <div className="notes-scroll">
        <div className="card">
          <div className="card-body">
            {FAKE_NOTES.map((note: Note, index: number) => (
              <div key={index}>
                <div className="d-flex flex-row border-bottom align-items-center">
                  <p className="text-muted font-italic flex-fill mb-0">
                    Author (from Company)
                  </p>
                  <div className="text-right">
                    <FiX size={12} />
                  </div>
                </div>
                <p className="text-muted text-right font-italic my-2">
                  {moment().format('M/D/YYYY hh:mm a')}
                </p>
                <p>
                  Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis
                  ullamco cillum dolor. Voluptate exercitation incididunt
                  aliquip deserunt reprehenderit elit laborum.
                </p>

                <p className="mt-3 text-muted font-italic">
                  Name of person that wrote the note
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
