import React from 'react';

export interface CollapseProps {
  options: string[];
  active: string | null;
  set(tab: string): void;
}

export default React.createContext({
  options: [],
  active: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  set: () => {
    console.info('set');
  },
} as CollapseProps);
