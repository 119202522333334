import React from 'react';
import { PointOfSaleBase } from '@wgt/types';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import { Title } from '../../../../components';
import { LIST_POINT_OF_SALES_SHORT } from '../graphql';
import Table from '../../../../components/_organisms/Table';

const List: React.FC = () => {
  const { t } = useTranslation('pointOfSales');

  return (
    <Row>
      <Col xs="12">
        <Row className="my-3">
          <Col xs="12">
            <Title>{t('Point of Sales')}</Title>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <Table
              hover
              striped
              node="pointOfSales"
              columns={[{ label: 'Number', field: 'id' }]}
              query={LIST_POINT_OF_SALES_SHORT}
              getMutationArgs={(item: PointOfSaleBase) => ({ id: item.id })}
              getConfirmMessage={(item: PointOfSaleBase) =>
                `Confirm delete of ${item?.id}?`
              }
              showEdit={false}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default List;
