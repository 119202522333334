import { gql } from '@apollo/client';

export const productPagesFragment = gql`
  fragment ProductPages on Product {
    id
    pages {
      ... on ProductPageId {
        id
        name
        position
        fields
        price_per_carat
        carat_weight
        total_price
        product {
          id
        }
        category {
          id
          name
          mixed
          categoryType {
            id
          }
          template {
            layout
            fields {
              id
              label
              is_highlight
              values {
                id
                name
                key
                options
                image
                variations {
                  id
                  name
                  variationValues {
                    id
                    name
                    key
                    options
                    image
                    image_url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const CREATE_PRODUCT_GQL = gql`
  mutation createProduct(
    $category_id: ID!
    $name: String!
    $sku: String!
    $description: String
    $product_status_id: ID!
    $office_id: ID!
    $lot_id: String
    $tray_id: String
    $boxes: [UpdateProductBoxIdInput]!
    $crates: [UpdateProductCrateIdInput]!
  ) {
    createProduct(
      sku: $sku
      category_id: $category_id
      name: $name
      tray_id: $tray_id
      lot_id: $lot_id
      description: $description
      product_status_id: $product_status_id
      office_id: $office_id
      boxes: $boxes
      crates: $crates
    ) {
      id
    }
  }
`;

export const CREATE_PRODUCT_LEGACY_GQL = gql`
  mutation createProductLegacy(
    $category_id: ID!
    $name: String
    $sku: String
    $description: String
    $product_status_id: ID
    $office_id: ID
    $lot_id: String
    $tray_id: String
    $boxes: [UpdateProductBoxIdInput]
    $crates: [UpdateProductCrateIdInput]
  ) {
    createProductLegacy(
      sku: $sku
      category_id: $category_id
      name: $name
      tray_id: $tray_id
      lot_id: $lot_id
      description: $description
      product_status_id: $product_status_id
      office_id: $office_id
      boxes: $boxes
      crates: $crates
    ) {
      id
      sku
    }
  }
`;

export const EDIT_PRODUCT_GQL = gql`
  mutation updateProduct($id: ID!, $product: UpdateProductInput!) {
    updateProduct(id: $id, product: $product) {
      id
    }
  }
`;

export const PRODUCT_PAGES_GQL = gql`
  query product($id: ID!) {
    product(id: $id) {
      id
      pages {
        ... on ProductPageId {
          id
          name
          position
          fields
          price_per_carat
          carat_weight
          total_price
          product {
            id
          }
          category {
            id
            name
            mixed
            categoryType {
              id
            }
            template {
              layout
              shapes {
                id
                title
                image_url
                image_selected_url
                image_highlight_url
                row_position
                shapeGroup {
                  id
                  title
                }
                descriptions {
                  id
                  name
                  display_type
                  group
                  icon_url
                }
              }
              fields {
                id
                label
                is_highlight
                is_required
                component {
                  id
                  key
                  name
                }
                property {
                  name
                  key
                  dataType {
                    name
                    key
                  }
                }
                values {
                  id
                  name
                  key
                  options
                  image
                  variations {
                    id
                    name
                    variationValues {
                      id
                      name
                      key
                      options
                      image
                      image_url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const PRODUCT_GET_GQL = gql`
  query productPutQuery(
    $id: ID!
    $isEdit: Boolean!
    $officeFilter: OfficeFilterInput
    $parcelTypeFilter: ParcelTypeFilterInput
    $categoryId: ID!
  ) {
    product(id: $id) @include(if: $isEdit) {
      id
      name
      description
      sku
      lot_id
      tray_id
      boxes {
        id
        name
      }
      crates {
        id
        name
      }
      category {
        id
        name
        productType {
          id
          numberPages
        }
      }
      productStatus {
        ... on ProductStatus {
          id
          name
        }
      }
      office {
        ... on Office {
          id
          name
          address
          phone
          postal_code
          city
          state
          country {
            name
          }
        }
      }

      pages {
        ... on ProductPageId {
          id
          name
          position
          price_per_carat
          carat_weight
          total_price
        }
      }
    }
    productsStatus {
      data {
        ... on ProductStatus {
          id
          name
        }
      }
    }
    parcelTypes(filter: $parcelTypeFilter) {
      data {
        ... on ParcelType {
          id
          title
          description
        }
      }
    }
    firms(filter: { types: { id: [10] } }) {
      data {
        ... on Firm {
          id
          name
          extra {
            email
          }
          address {
            address
            state
            phone
            country {
              name
            }
          }
        }
      }
    }
    category(id: $categoryId) {
      id
      name
      mixed
      categoryType {
        id
      }
    }
    offices(filter: $officeFilter) {
      data {
        ... on Office {
          id
          name
          address
          phone
          postal_code
          city
          state
          country {
            name
          }
        }
      }
    }
  }
`;

export const MOVE_PAGE_ID_TO_CURRENT_SKU_GQL = gql`
  mutation associateSKU($id: ID!, $sku: String!) {
    associateSKU(id: $id, sku: $sku) {
      message
    }
  }
`;

export const LIST_PRODUCTS_PAGE_IDS_GQL = gql`
  query productPageIds($filter: ProductPageIdFilterInput) {
    productPageIds(filter: $filter) {
      data {
        id
        name
        product {
          id
          sku
        }
      }
    }
  }
`;
