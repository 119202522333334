import React from 'react';
import { TemplateLayoutRow as TemplateLayoutRowType } from '@wgt/types';
import { Col, Row } from 'reactstrap';
import Title from '../Title';
import Line from '../Line';
import { useFormContext } from 'react-hook-form';

interface TemplateFieldsDetailsProps {
  rows: TemplateLayoutRowType[];
  prefix: string;
}
const TemplateFieldsDetails: React.FC<TemplateFieldsDetailsProps> = ({
  rows,
  prefix,
}) => {
  const { getValues } = useFormContext();

  const getFieldValue = (fieldName: string) => {
    const fieldContent = getValues(fieldName);

    switch (Object.prototype.toString.call(fieldContent)) {
      case '[object String]':
        return fieldContent;
      case '[object Object]':
        return Object.keys(fieldContent)
          .filter((fieldKContentey) => fieldContent[fieldKContentey].selected)
          .map((fieldSelectedKey) => {
            const fieldSelectedValue = fieldContent[fieldSelectedKey];
            if (!fieldSelectedValue.variations) {
              return fieldSelectedKey;
            }

            return `${fieldSelectedKey} (${Object.keys(
              fieldSelectedValue.variations,
            )
              .filter(
                (variationKey) => fieldSelectedValue.variations[variationKey],
              )
              .map((selectedVariation) => selectedVariation.split('-')[1] ?? '')
              .join(',')})`;
          })
          .join(',');

      default:
        return '-';
    }
  };
  return (
    <>
      {rows.map((row, iRow) => (
        <Row key={iRow}>
          {row.map((col, iColumn) => (
            <Col key={`line-${iRow}-column-${iColumn}`} md={col.size}>
              {col.group.title && (
                <>
                  <Title heading="h2">{col.group.title}</Title>
                  <Line />
                </>
              )}

              {col.group.content.map((content, iContent) => (
                <Col
                  md={content.size}
                  key={`line-${iRow}-column-${iColumn}-content-${iContent}`}
                >
                  {content.content && (
                    <p>
                      {content.content.property?.name}{' '}
                      <b>
                        {getFieldValue(
                          `${prefix}${content.content.property?.key}`,
                        )}
                      </b>
                    </p>
                  )}
                </Col>
              ))}
            </Col>
          ))}
        </Row>
      ))}
    </>
  );
};

export default TemplateFieldsDetails;
