import React from 'react';
import { Col, Row } from 'reactstrap';
import useProfileContext from '../../hooks/useProfile';
import NoProfilePhoto from '../../assets/no-profile-photo.jpg';
import Title from '../Title';
import { useTranslation } from 'react-i18next';
import { Button } from '..';
import { FaEdit } from 'react-icons/fa';

export default function ProfileDetailsCard(): JSX.Element {
  const { user } = useProfileContext();
  const { t } = useTranslation('profile');
  return user?.id ? (
    <div className="card p-4">
      <Row>
        <Col md="3" className="d-flex align-items-start">
          <img src={NoProfilePhoto} className="w-100" />
          <Button type="text">
            <FaEdit />
          </Button>
        </Col>
        <Col>
          <Title heading="h4">{user?.first_name}</Title>
          <Title heading="h5">{user?.jobTitles?.[0]?.name}</Title>
          <Title heading="h5">{user?.deparments?.[0]?.name}</Title>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col>
          {t('Home')}
          <p>{user.address1}</p>
          <p>{user.address2}</p>
          <p>{user.country?.name}</p>
          <p>{user.city}</p>
          <p>{user.zipcode}</p>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md="6">
          <b>{t('Phone')}</b>
          <br />
          {user.phone}
        </Col>
        <Col md="6">
          <b>{t('Mobile')}</b>
          <br />
          {user.mobile}
        </Col>
        <Col md="12" className="mt-4">
          <b>{t('Fax')}</b>
          <br />
          {user.fax}
        </Col>
        <Col md="12" className="mt-4 mb-5">
          <b>{t('E-mail')}</b>
          <br />
          {user.email}
        </Col>
      </Row>
    </div>
  ) : (
    <React.Fragment />
  );
}
