import React from 'react';
import { DragPreviewImage, useDrag } from 'react-dnd';
import classnames from 'classnames';
import Icon from '../../../../../../../assets/tag-pointer.png';
import { useBagContext } from '../../../../../../../services/bag';
import { MediaCenterController } from '../../../../../../../hooks/useMediaCenterController';
import { useMutation } from '@apollo/client';
import { ATTACH_TAG } from './graphql';
import { useParams } from 'react-router-dom';
import Loader from '../../../../../../../components/_organisms/Loader';
import {
  InclusionTag as InclusionTagType,
  MediaCenterFileType,
} from '@wgt/types';
import { LIST_FILES } from '../../graphql';

interface InclusionTagsProps {
  inclusionTags: InclusionTagType[];
  type: MediaCenterFileType;
}

function InclusionTag({
  tag,
  type: mediaType,
}: {
  tag: InclusionTagType;
  type: MediaCenterFileType;
}): JSX.Element {
  const { media } = useBagContext<MediaCenterController>();
  const [attachInclusion, { loading }] = useMutation(ATTACH_TAG);
  const params = useParams<ParamTypes>();

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: 'tag', ...tag },
    canDrag: !loading,
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    end: ({ type }: any, monitor) => {
      const dropResult = monitor.getDropResult();
      if (!dropResult || !type) {
        return;
      }
      attachInclusion({
        refetchQueries: [
          {
            query: LIST_FILES,
            variables: {
              filter: {
                product_id: params.id,
                type: mediaType,
              },
            },
          },
        ],
        variables: {
          file_id: media.active?.id,
          inclusion_tag_id: tag.id,
          position_x: dropResult.x,
          position_y: dropResult.y,
        },
      });
    },
  });

  return (
    <>
      <DragPreviewImage connect={preview} src={Icon} />
      <div
        className={classnames('inclusion-tag-item', {
          isDragging,
        })}
        ref={drag}
      >
        {tag.title}
        {loading && <Loader small round />}
      </div>
    </>
  );
}

const InclusionTagsComponent: React.FC<InclusionTagsProps> = ({
  inclusionTags,
  type,
}) => {
  return (
    <div>
      {inclusionTags.map((x) => (
        <InclusionTag tag={x} key={`tag-${x.id}`} type={type} />
      ))}
    </div>
  );
};

export default InclusionTagsComponent;
