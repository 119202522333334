/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useEffect } from 'react';

import { useParams } from 'react-router-dom';
import useCrmRoutes from '../../../../hooks/useCrmRoutes';

import './styles.scss';
import InternalRoutes from '../../../../components/_organisms/InternalRoutes';

interface Props {
  routes: any;
}

const Edit: React.FC<Props> = ({ routes }) => {
  const params = useParams<ParamTypes>();
  const { setCustomTitle } = useCrmRoutes();

  const isEdit = useMemo(() => params?.id, [params]);

  useEffect(() => {
    setCustomTitle(`${isEdit ? 'Edit' : 'Create'} Template`);
  }, []);

  return (
    <div className="template-put">
      <InternalRoutes routes={routes} />
    </div>
  );
};

export default Edit;
