import React, { useMemo, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { PointOfSaleBase } from '@wgt/types';
import { convertPointOfSale } from '@wgt/converters';
import Resume from './Resume';
import HeaderInputs from './HeaderInputs';
import { Title } from '../../../../components';
import OpenMargins from './OpenMargins';
import StockOptions from './StockOptions';
import { GET_POINT_OF_SALE_GQL } from '../graphql';
import Loader from '../../../../components/_organisms/Loader';
import { PointOfSaleProvider } from './usePointOfSaleUpdate';
import useLoggedContext from '../../../../hooks/useLoggedContext';
import Content from './Content';
import useCrmRoutes from '../../../../hooks/useCrmRoutes';
import './style.scss';

export default function Details(): JSX.Element {
  const { t } = useTranslation('pointOfSales');
  const params = useParams<ParamTypes>();
  const { active } = useLoggedContext();
  const POINT_OF_SALE_FILTER = useMemo(
    () => ({
      id: params.id,
      officeFilter: active ? { firm_id: [active.id] } : null,
    }),
    [active, params],
  );

  const { setCustomTitle } = useCrmRoutes();

  useEffect(() => {
    setCustomTitle('Create');
  }, []);

  const { data, loading } = useQuery(GET_POINT_OF_SALE_GQL, {
    variables: POINT_OF_SALE_FILTER,
  });

  const methods = useForm<PointOfSaleBase>({
    // I am using this converter while these totals are not being handled in the backend
    defaultValues: data?.pointOfSale
      ? convertPointOfSale(data.pointOfSale)
      : {},
    shouldUnregister: false,
  });

  useEffect(() => {
    if (data?.pointOfSale) {
      methods.reset(convertPointOfSale(data?.pointOfSale));
    }
  }, [data]);

  if (loading || !data?.pointOfSale?.id) {
    return <Loader />;
  }

  return (
    <PointOfSaleProvider
      // I am using this converter while these totals are not being handled in the backend
      pointOfSale={convertPointOfSale(data.pointOfSale)}
      pointOfSaleFilter={POINT_OF_SALE_FILTER}
    >
      <Row id="point-of-sales">
        <Col xs="12">
          <Row className="my-3">
            <Col xs="4" className="d-flex align-items-center">
              <Title>{t('Point of Sales')}</Title>
            </Col>
            <Col xs="8">
              <HeaderInputs />
            </Col>
          </Row>
          <Row>
            <Col xs="4">
              <Resume />
            </Col>
            <Col xs="8">
              <OpenMargins />
              <StockOptions />
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Content />
            </Col>
          </Row>
        </Col>
      </Row>
    </PointOfSaleProvider>
  );
}
