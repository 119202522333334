import React, { useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { GET_TEMPLATE_DETAILS_GQL } from '../../graphql';
import TemplateLayoutContent from './TemplateLayoutContent';
import TemplateLayoutSidebar from './TemplateLayoutSidebar';
import './styles.scss';
import { TemplateLayoutProvider } from '../../../../../hooks/useTemplateLayout';
import useCrmRoutes from '../../../../../hooks/useCrmRoutes';
import {
  TemplateFieldBase,
  LayoutFieldType,
  SectionStringType,
} from '@wgt/types';
import { useTranslation } from 'react-i18next';

interface ControllerRouteProps {
  field: LayoutFieldType;
  title: string;
  sections: SectionStringType[];
}

const LayoutComponent: React.FC = () => {
  const params = useParams<{ id: string; layout: string }>();

  const { data } = useQuery(GET_TEMPLATE_DETAILS_GQL, {
    variables: {
      id: params.id,
    },
    fetchPolicy: 'cache-first',
  });
  const { setCustomRoutes, routes } = useCrmRoutes();
  const { t } = useTranslation(['template', 'common']);

  const controller: ControllerRouteProps = useMemo(() => {
    switch (params.layout) {
      case 'search-stock':
        return {
          field: 'search_layout',
          title: t('Search Stock'),
          sections: ['first', 'second', 'third'],
        };
      case 'results':
        return {
          field: 'result_layout',
          title: t('Add Stock from Search Stock'),
          sections: ['first', 'second'],
        };

      default:
        return {
          field: 'layout',
          title: t('Add Stock'),
          sections: ['first', 'second'],
        };
    }
  }, [params]);

  useEffect(() => {
    setCustomRoutes([
      { name: 'Templates', path: '/crm/templates' },
      { name: 'Edit Template', path: `/crm/templates/${params.id}/edit` },
      {
        name: `${controller.title} ${t('common:Layout')}`,
        path: `/crm/templates/${params.id}/edit/layout/${params.layout}`,
      },
    ]);
  }, [routes]);

  const template = useMemo(() => {
    if (!data?.template) {
      return undefined;
    }

    return {
      ...data.template,
      fields: data.template?.fields?.filter((field: TemplateFieldBase) =>
        controller.field === 'search_layout' ? field.is_searchable : true,
      ),
    };
  }, [data?.template]);

  return (
    <div className="template-layout-container">
      <TemplateLayoutProvider
        context={
          {
            template,
            layoutField: controller.field,
            layoutSections: controller.sections,
          } ?? {}
        }
      >
        <TemplateLayoutSidebar />
        <TemplateLayoutContent />
      </TemplateLayoutProvider>
    </div>
  );
};

export default LayoutComponent;
