import { gql, useApolloClient, useMutation } from '@apollo/client';
import React, { useRef } from 'react';
import { Spinner } from 'evergreen-ui';
import { FaChevronDown } from 'react-icons/fa';
import { useDrag, useDrop, XYCoord } from 'react-dnd';
import { useHistory, useParams } from 'react-router-dom';
import useToggler from '@wgt/shared/hooks/useToggler';
import { Popover, PopoverBody } from 'reactstrap';
import { PageId, ProductBase } from '../../../../../../../types';
import { PRODUCT_DETAIL_GQL } from '../graphql';

interface RouteParams {
  productId: string;
  pageId?: string;
  categoryId: string;
}

const gqlNavigatorPageID = gql`
  fragment NavigatorPageID on Product {
    id
    pages {
      id
      name
    }
  }
`;

export default function ProductPageIdNavigator(): JSX.Element {
  const params = useParams<RouteParams>();
  const [isOpen, toggle] = useToggler();
  const client = useApolloClient();
  const product = client.readFragment<ProductBase>({
    fragment: gqlNavigatorPageID,
    id: `Product:${params.productId}`,
  });

  const indexOf =
    product?.pages?.findIndex((x) => x.id === params.pageId) ?? -1;

  const pageId = indexOf >= 0 ? product?.pages?.[indexOf].name : null;

  return (
    <div className="page-ids-route p-1 rounded" id="field-page-id">
      <div className="input-group">
        <div className="input-group prepend" style={{ width: '23%' }}>
          <span className="input-group-text rounded">
            <p className="m-0">Page ID</p>
          </span>
        </div>
        <div className="input-group-prepend ml-2">
          <span className="input-group-text rounded page-id-index">
            <p className="m-0">{indexOf >= 0 ? indexOf + 1 : 'x'}</p>
          </span>
        </div>
        <button
          id="pageId"
          type="button"
          className="btn btn-clear text-center d-flex align-items-center justify-content-between my-0 mx-2 p-0"
        >
          <p className="mb-0 text-truncate">{pageId ?? 'Save first'}</p>
          <FaChevronDown size={14} />
        </button>
        <Popover
          placement="bottom"
          isOpen={isOpen}
          target="pageId"
          toggle={toggle}
          popperClassName="max-width-100"
          trigger="hover"
        >
          <PopoverBody>
            {product?.pages?.map((page) => (
              <React.Fragment key={`page-id-header-${page.id ?? page.__id}`}>
                <PageIDItem page={page} />
              </React.Fragment>
            ))}
          </PopoverBody>
        </Popover>
      </div>
    </div>
  );
}

function PageIDItem({ page }: { page: PageId }): JSX.Element {
  const params = useParams<RouteParams>();
  const history = useHistory();
  const ref = useRef<HTMLButtonElement>(null);
  const client = useApolloClient();
  const product = client.readFragment<ProductBase>({
    fragment: gqlNavigatorPageID,
    id: `Product:${params.productId}`,
  });

  const [mutate, { loading }] = useMutation(
    gql`
      mutation updateProductPageId(
        $pageId: ID!
        $page: UpdateProductPageIdInput!
      ) {
        updateProductPageId(id: $pageId, page: $page) {
          id
        }
      }
    `,
    {
      refetchQueries: [
        {
          query: PRODUCT_DETAIL_GQL,
          variables: {
            id: params.productId,
          },
        },
      ],
    },
  );

  const [, drop] = useDrop({
    accept: 'pageId',
    drop: (item: { type: 'pageId'; page: PageId }, monitor) => {
      if (!ref.current) {
        return;
      }
      const fromPosition = product?.pages?.findIndex(
        (x) => x.id === item?.page.id,
      );
      const toPosition = product?.pages?.findIndex((x) => x.id === page.id);
      if (fromPosition === toPosition) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (
        typeof fromPosition === 'undefined' ||
        typeof toPosition === 'undefined'
      ) {
        return;
      }
      if (fromPosition < toPosition && hoverClientY < hoverMiddleY) {
        return;
      }
      if (fromPosition > toPosition && hoverClientY > hoverMiddleY) {
        return;
      }

      mutate({
        variables: {
          pageId: item.page.id,
          page: {
            position: toPosition,
          },
        },
      });
    },
  });
  const [{ isDragging }, drag] = useDrag({
    item: { type: 'pageId', page },
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <button
      className="btn btn-sm btn-clear btn-block text-left page-id-button-content d-flex justify-content-between align-items-center"
      style={{ opacity }}
      ref={ref}
      disabled={loading}
      onClick={() =>
        params.pageId !== page.id &&
        history.push(`/crm/products/${params.productId}/pages/${page.id}`)
      }
    >
      {page.name} {loading && <Spinner size={12} />}
    </button>
  );
}
