import React from 'react';
import { Col, Row } from 'reactstrap';

import Select from 'react-select/creatable';
import { Button, SimpleInput } from '../../../components';

import { useTranslation } from 'react-i18next';
import useStatementsContext from './useStatements';
import { Controller } from 'react-hook-form';

export default function StatementsFilter(): JSX.Element {
  const { t } = useTranslation(['statements', 'common']);
  const { transactionTypes, searching } = useStatementsContext();

  return (
    <>
      <Row>
        <Col>
          <SimpleInput
            field="term"
            label={t('common:Search')}
            placeholder={t('search by sku / lot / IDs / upc')}
          />
        </Col>
        <Col>
          <label>{t('Transaction type')}</label>
          <Controller
            name="transactionTypes"
            defaultValue={[]}
            render={({ onChange, value }) => (
              <Select
                options={transactionTypes ?? []}
                getOptionLabel={(e) => e.name}
                getOptionValue={(e) => `${e.id}`}
                isDisabled={!transactionTypes?.length}
                onChange={onChange}
                value={value}
                isMulti
              />
            )}
          />
        </Col>
        <Col>
          <SimpleInput field="firm_name" label={t('Firm name')} />
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-end mt-3">
          <Button htmlType="submit" isLoading={searching} disabled={searching}>
            {t('common:Search')}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex">
          <SimpleInput
            field="invoice_number"
            label={t('Invoice Number')}
            placeholder={t('enter invoice number')}
          />
          <Button type="secondary" className="mt-4 ml-2">
            {t(' Upload Receipt')}
          </Button>
        </Col>
      </Row>
    </>
  );
}
