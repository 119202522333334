import { useMutation } from '@apollo/client';
import { Mineral } from '@wgt/types';
import React, { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, FormGroup, Row } from 'reactstrap';
import { Button, SimpleInput } from '../../../../../../../components';
import ConfirmDialog from '../../../../../../../components/_molecules/ConfirmDialog';
import useToast from '../../../../../../../hooks/useToast';
import {
  UPDATE_MINERAL,
  DELETE_MINERAL,
  LIST_MINERALS,
  MINERAL_DETAILS,
} from './graphql';
import { DELETE_PROPERTY_VALUE_GQL } from '../../../../graphql';
import MineralsMediaCenter from './MineralsMediaCenter';
import useMineralsContext from './useMinerals';
import Minerals from '../../Put/Minerals';

const MineralItemForm: React.FC<{
  mineral: Mineral;
}> = ({ mineral }) => {
  const [mineralToDelete, setMineralToDelete] = useState<Mineral | undefined>();
  const { filters } = useMineralsContext();
  const { toast } = useToast();
  const methods = useForm({
    shouldUnregister: false,
    defaultValues: { mineral },
  });
  const { t } = useTranslation('minerals');

  const [deletePropertyValue, { loading: removingPropertyValue }] = useMutation(
    DELETE_PROPERTY_VALUE_GQL,
    {
      onError: (response) => toast('error', response.message),
      onCompleted: () => [
        setMineralToDelete(undefined),
        toast('success', t('Mineral Removed')),
      ],
      refetchQueries: [{ query: LIST_MINERALS, variables: filters }],
    },
  );

  const [deleteMineral, { loading: removing }] = useMutation(DELETE_MINERAL, {
    variables: {
      id: mineral.id,
    },
    onError: (response) => toast('error', response.message),
    onCompleted: () =>
      deletePropertyValue({
        variables: {
          id: mineral.propertyValue?.id,
        },
      }),
  });
  const [update, { loading }] = useMutation(UPDATE_MINERAL, {
    onCompleted: () => toast('success', t('Mineral Updated')),
    onError: () => toast('error'),
    refetchQueries: [{ query: MINERAL_DETAILS, variables: { id: mineral.id } }],
  });

  const isRemoving = useMemo(() => removing || removingPropertyValue, [
    removing,
    removingPropertyValue,
  ]);

  const onSubmit = ({ mineral: mineralVariables }: { mineral: Mineral }) =>
    update({
      variables: {
        id: mineral?.id,
        mineral: {
          formula: mineralVariables.formula,
          color: mineralVariables.color,
          color_of_streak: mineralVariables.color_of_streak,
          crystal_system: mineralVariables.crystal_system,
          absorption: mineralVariables.absorption,
          hardness: mineralVariables.hardness,
          transparency: mineralVariables.transparency,
          fluorescence: mineralVariables.fluorescence,
          specific_gravity: mineralVariables.specific_gravity,
          density: mineralVariables.density,
          luster: mineralVariables.luster,
          refractive_index: mineralVariables.refractive_index,
          cleavage: mineralVariables.cleavage,
          dispersion: mineralVariables.dispersion,
          double_refraction: mineralVariables.double_refraction,
          fracture: mineralVariables.fracture,
          pleochroism: mineralVariables.pleochroism,
          group_id: mineralVariables.group?.id ?? null,
          subgroup_id: mineralVariables.subgroup?.id ?? null,
          species_id: mineralVariables.species?.id ?? null,
          variety_id: mineralVariables.variety?.id ?? null,
          series_id: mineralVariables.series?.id ?? null,
          classification_id: mineralVariables.classification?.id ?? null,
        },
      },
    });
  const rows = useMemo(
    () => [
      [
        { field: 'mineral.color_of_streak', label: 'Color of Streak' },
        { field: 'mineral.crystal_system', label: 'Crystal System' },
        { field: 'mineral.dispersion', label: 'Dispersion' },
      ],
      [
        { field: 'mineral.hardness', label: 'Hardness' },
        { field: 'mineral.transparency', label: 'Transparency' },
        { field: 'mineral.pleochroism', label: 'Pleochroism' },
      ],
      [
        { field: 'mineral.density', label: 'Density' },
        { field: 'mineral.refractive_index', label: 'Refractive Index' },
        { field: 'mineral.absorption', label: 'Absorption' },
      ],
      [
        { field: 'mineral.cleavage', label: 'Cleavage' },
        { field: 'mineral.double_refraction', label: 'Double Refraction' },
        { field: 'mineral.fluorescence', label: 'Fluorescence' },
      ],
      [
        { field: 'mineral.fracture', label: 'Fracture' },
        { field: 'mineral.specific_gravity', label: 'Specific Gravity' },
        { field: 'mineral.luster', label: 'Luster' },
      ],
    ],
    [],
  );

  return (
    <>
      <ConfirmDialog
        isShown={!!mineralToDelete}
        onConfirm={deleteMineral}
        onClose={() => setMineralToDelete(undefined)}
        title={t(`Confirm delete mineral?`)}
        isLoading={isRemoving}
      />
      <FormProvider {...methods}>
        <Minerals />
        <form onSubmit={methods.handleSubmit(onSubmit)} className="w-100">
          <div className="border rounded p-3 bg-white">
            <FormGroup>
              <SimpleInput
                label={t('Chemical Composition')}
                field="mineral.formula"
              />
            </FormGroup>
            <FormGroup>
              <SimpleInput label={t('Color')} field="mineral.color" />
            </FormGroup>
            {rows.map((cols, index) => (
              <FormGroup row key={index}>
                {cols.map((col) => (
                  <Col key={col.field} md={4}>
                    <SimpleInput field={col.field} label={t(col.label)} />
                  </Col>
                ))}
              </FormGroup>
            ))}
          </div>

          <MineralsMediaCenter mineral={mineral} />

          <Row className="mt-4">
            <Col>
              <FormGroup className="float-right">
                <Button
                  htmlType="button"
                  type="danger"
                  className="mr-4"
                  isLoading={removing}
                  disabled={loading || removing}
                  onClick={() => setMineralToDelete(mineral)}
                >
                  {t('Delete')}
                </Button>
                <Button
                  htmlType="submit"
                  isLoading={loading}
                  disabled={loading || removing}
                >
                  {t('Save')}
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </form>
      </FormProvider>
    </>
  );
};

export default MineralItemForm;
