import React, { useState } from 'react';
import { MediaCenterItemBase } from '@wgt/types';
import { DEFAULT_BG } from '..';
import { BACKGROUND_OPTIONS } from '../../../../../../utils/constants';
import { useTranslation } from 'react-i18next';

interface BackgroundSelectorProps {
  active?: MediaCenterItemBase | null;
  update(background: string): void;
}

const BackgroundSelector: React.FC<BackgroundSelectorProps> = ({
  active,
  update,
}) => {
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation('mediaCenter');
  const selectOption = (background: string) => {
    update(background);
    setOpen(false);
  };

  return (
    <div className="media-center-background-selector">
      {active?.id && (
        <>
          {t('Background color')}
          <div
            onClick={() => setOpen(!isOpen)}
            className="media-center-background-box"
            style={{
              backgroundColor: active?.background ?? DEFAULT_BG,
            }}
          />
          {isOpen && (
            <div className="media-center-background-options">
              {BACKGROUND_OPTIONS.map((option, key) => (
                <div
                  key={`bg-${key}`}
                  className="media-center-background-option"
                  onClick={() => selectOption(option)}
                  style={{
                    backgroundColor: option,
                  }}
                />
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default BackgroundSelector;
