import React from 'react';
import useCrmRoutes from '../../../hooks/useCrmRoutes';
import List from './List';
import Details from './Details';
import Put from './Put';
import InternalRoutes from '../../../components/_organisms/InternalRoutes';
import ProductPut from '../Products/ProductPut';

const Users: React.FC = () => {
  const { isCrmInternalRoute, actualRoute } = useCrmRoutes();

  const routes = [
    {
      component: Put,
      name: 'User',
      path: `/crm/${actualRoute}/create`,
    },
    {
      component: Details,
      exact: true,
      name: 'User',
      path: `/crm/${actualRoute}/:id`,
    },
    {
      component: Put,
      name: 'User',
      path: `/crm/${actualRoute}/:id/edit`,
    },
    {
      component: ProductPut,
      name: 'Create Product',
      path: `/crm/${actualRoute}/:categoryId/products/create`,
      exact: true,
    },
    {
      component: ProductPut,
      name: 'Update Product',
      path: `/crm/${actualRoute}/:categoryId/products/:productId`,
    },
  ];

  if (isCrmInternalRoute) {
    return <InternalRoutes routes={routes} />;
  }

  return <List />;
};

export default Users;
