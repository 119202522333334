import React from 'react';

import CustomHeader from '../../../../components/_molecules/CustomHeader';
import { LIST_PROPERTIES_GQL, DELETE_PROPERTY_GQL } from '../graphql';
import { Property } from '../types';
import Table from '../../../../components/_organisms/Table';

const List: React.FC = () => {
  return (
    <div>
      <CustomHeader title={'List Properties'} showCreate />
      <Table
        hover
        striped
        node="properties"
        columns={[{ label: 'Name', field: 'name' }]}
        query={LIST_PROPERTIES_GQL}
        deleteMutation={DELETE_PROPERTY_GQL}
        getMutationArgs={(item: Property) => ({ id: item.id })}
        getConfirmMessage={(item: Property) =>
          `Confirm delete of ${item?.name}?`
        }
      />
    </div>
  );
};

export default List;
