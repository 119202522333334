import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { PageId } from '@wgt/types';
import { Button } from '../../../../../components';
import useWorkOrderContext from '../../../../../components/WorkOrder/useWorkOrderContext';

interface Props {
  pageIdActive?: PageId;
}

export default function WorkOrderButton({ pageIdActive }: Props): JSX.Element {
  const { t } = useTranslation('common');
  const { isOpen, setOpen, setActivePageId } = useWorkOrderContext();

  useEffect(() => setActivePageId(pageIdActive), [pageIdActive]);

  return (
    <Button
      htmlType="button"
      type="orange"
      className={classnames('mr-4', { outline: !isOpen })}
      onClick={() => setOpen(!isOpen)}
    >
      {t('Work Order')}
    </Button>
  );
}
