import React from 'react';
import CustomHeader from '../../../../components/_molecules/CustomHeader';
import { LIST_CATEGORIES_GQL, DELETE_CATEGORY_GQL } from '../graphql';
import Table from '../../../../components/_organisms/Table';
import { Category } from '@wgt/types';

const List: React.FC = () => {
  return (
    <div>
      <CustomHeader title={'List Categories'} showCreate />

      <Table
        hover
        striped
        query={LIST_CATEGORIES_GQL}
        columns={[{ label: 'Name', field: 'name' }]}
        node="categories"
        deleteMutation={DELETE_CATEGORY_GQL}
        getMutationArgs={(item: Category) => ({ id: item.id })}
        getConfirmMessage={(item: Category) =>
          `Confirm delete of ${item?.name}?`
        }
      />
    </div>
  );
};

export default List;
