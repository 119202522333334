import React, { useEffect } from 'react';
import { Pane } from 'evergreen-ui';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PageId } from '@wgt/types';
import PriceInput from '../../../../../components/PriceInput';
import { useProductUpdate } from '../Provider';
import setCurrencyMaskInput, {
  removeCurrencyMask,
} from '../../../../../helpers/CurrencyMaskInput';

const PagePrice: React.FC = () => {
  const { pages, isFormEnabled } = useProductUpdate();
  const { getValues, setValue } = useFormContext();
  const { t } = useTranslation('product');

  const TOTAL_PRICE_PAGE_ID = `pages[${pages.activeIndex}].total_price`;
  const PRICE_PER_CARAT = `pages[${pages.activeIndex}].price_per_carat`;
  const CARAT_WEIGHT = `pages[${pages.activeIndex}].carat_weight`;

  const calculateSKUTotals = () =>
    setTimeout(() => {
      const pagesTotalPrices = getValues('pages').reduce(
        (totals: { totalPrice: number; caratWeight: number }, page: PageId) => {
          return {
            caratWeight:
              Number(totals.caratWeight) +
              Number(
                removeCurrencyMask(
                  page.carat_weight ? page.carat_weight.toString() : '0',
                ),
              ),
            totalPrice:
              Number(totals.totalPrice) +
              Number(
                removeCurrencyMask(
                  page.total_price ? page.total_price.toString() : '0',
                ),
              ),
          };
        },
        { caratWeight: 0, totalPrice: 0 },
      );

      setValue('total_price', pagesTotalPrices.totalPrice);
      setValue('total_carat_weight', pagesTotalPrices.caratWeight);
    }, 500);

  useEffect(() => {
    const pricePerCarat = Number(getValues(PRICE_PER_CARAT) ?? 0);
    const caratWeight = Number(getValues(CARAT_WEIGHT) ?? 0);

    setValue(
      CARAT_WEIGHT,
      caratWeight
        ? setCurrencyMaskInput(caratWeight.toFixed(2).toString())
        : '0.00',
    );

    setValue(
      PRICE_PER_CARAT,
      pricePerCarat
        ? setCurrencyMaskInput(pricePerCarat.toFixed(2).toString())
        : '0.00',
    );

    calculateTotalsPerPage('caractType');
  }, []);

  const calculateTotalsPerPage = (section: string) => {
    const pricePerCarat = Number(
      removeCurrencyMask(getValues(PRICE_PER_CARAT) ?? 0),
    );
    const caratWeight = Number(
      removeCurrencyMask(getValues(CARAT_WEIGHT) ?? 0),
    );
    const totalPrice = Number(
      removeCurrencyMask(getValues(TOTAL_PRICE_PAGE_ID) ?? 0),
    );

    if ('caractType' === section) {
      // Please, let's keep the following rules here in first moment
      // a) Price per Carat (x times) Carat Weight (= equals) Total Price$10.00 x 10.00 cts = $100.00
      // c) $300 Total Price / 3 Carat Weight. The Price per carat is $100.
      const resultTotalPrice = pricePerCarat * caratWeight;

      setValue(
        TOTAL_PRICE_PAGE_ID,
        setCurrencyMaskInput(resultTotalPrice.toFixed(2).toString()),
      );
    } else {
      // b) $300.00 Total Price (/ divide) $100.00 Price per Carat (= equals) Carat Weight is 3 cts.
      const resultCaratWeight = totalPrice > 0 ? totalPrice / pricePerCarat : 0;

      setValue(
        CARAT_WEIGHT,
        setCurrencyMaskInput(resultCaratWeight.toFixed(2).toString()),
      );
    }
    calculateSKUTotals();
  };

  return (
    <Pane>
      <PriceInput
        field={TOTAL_PRICE_PAGE_ID}
        title={t('Total Price')}
        muted="USD"
        disabled={!isFormEnabled}
        onKeyUp={() => calculateTotalsPerPage('totalPrice')}
        required
      />
      <PriceInput
        field={PRICE_PER_CARAT}
        title={t('Price per carat')}
        muted="USD"
        disabled={!isFormEnabled}
        onKeyUp={() => calculateTotalsPerPage('caractType')}
        required
      />
      <PriceInput
        field={CARAT_WEIGHT}
        title={t('Carat Weight')}
        muted={t('ct')}
        disabled={!isFormEnabled}
        onKeyUp={() => calculateTotalsPerPage('caractType')}
        required
      />
    </Pane>
  );
};

export default PagePrice;
