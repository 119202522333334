import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import CreatedAtRange from '../../../components/CreatedAtRange';
import CustomHeader from '../../../components/_molecules/CustomHeader';
import useStatementsContext from './useStatements';

export default function StatementsDateRange(): JSX.Element {
  const { t } = useTranslation('statements');
  const { onSubmit, searching } = useStatementsContext();
  return (
    <Row>
      <Col className="text-right">
        {t('anti money luandering is tracing resources')}
        <br />
        {t(
          'providing vital support for the Kimberley Process Certification Scheme, other resource types and documents world wide',
        )}
      </Col>
      <Col md="12" className="d-flex align-items-center">
        <CustomHeader title="Results" />
        <CreatedAtRange onSubmit={onSubmit} loading={searching} />
      </Col>
    </Row>
  );
}
