import React from 'react';
import { PropertyValueVariationValue } from '@wgt/types';
import { useFormContext } from 'react-hook-form';

export interface VariationOptionsProps {
  label?: string;
  field: string;
  isMulti?: boolean;
  required?: boolean;
  imageOption?: boolean;
  options: PropertyValueVariationValue[];
  className?: string;
  disabled?: boolean;
}

const VariationOptions: React.FC<VariationOptionsProps> = ({
  options,
  field,
  disabled,
}) => {
  const { register, getValues } = useFormContext();
  return (
    <div>
      {options.map((variationValue, key) => (
        <React.Fragment key={`field-value-variation-${key}`}>
          <input
            className="field-value-variation-input"
            type="checkbox"
            ref={register()}
            name={`${field}.${variationValue.id}-${variationValue.key}`}
            defaultChecked={getValues(
              `${field}.${variationValue.id}-${variationValue.key}`,
            )}
            id={`variation-${variationValue.id}`}
            disabled={disabled}
          />
          <label htmlFor={`variation-${variationValue.id}`}>
            {variationValue.name}
          </label>
        </React.Fragment>
      ))}
    </div>
  );
};

export default VariationOptions;
