import React from 'react';
import { Badge } from 'evergreen-ui';
interface Props {
  numberProp: number;
  color?:
    | 'red'
    | 'automatic'
    | 'neutral'
    | 'blue'
    | 'orange'
    | 'yellow'
    | 'green'
    | 'teal'
    | 'purple'
    | undefined;
  className?: string;
}
const BadgeComponent: React.FC<Props> = ({ numberProp, color, className }) => {
  return (
    <div className={className}>
      <Badge color={color || 'red'} isSolid>
        {numberProp}
      </Badge>
    </div>
  );
};

export default BadgeComponent;
