/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useFormContext } from 'react-hook-form';
import classnames from 'classnames';

import './styles.scss';
interface Props {
  field: string;
  label?: string;
  parent?: string;
  placeholder?: string;
  required?: boolean;
  defaultValue?: string | number;
  type?: string;
  options: any;
  controller: string;
  text: string;
  hint?: string;
  onChange?: any;
  disabled?: boolean;
  className?: string;
}

const SimpleDropdown: React.FC<Props> = ({
  field,
  placeholder,
  required,
  defaultValue,
  label,
  options,
  controller,
  text,
  hint,
  parent,
  onChange,
  disabled,
  className,
}) => {
  const { register, errors } = useFormContext();

  const classNameSelect = parent
    ? `form-control ${
        errors && errors[parent] && errors[parent][field] && 'is-invalid'
      }`
    : `form-control ${errors && errors[field] && 'is-invalid'}`;

  const fieldRegistered = parent ? `${parent}[${field}]` : field;

  const htmlFor = parent ? `label-${parent}-${field}` : `label-${field}`;

  const ariaInvalid = parent
    ? errors && errors[parent] && errors[parent][field]
      ? 'true'
      : 'false'
    : errors && errors[field]
    ? 'true'
    : 'false';

  function handleChange(event: React.ChangeEvent<HTMLSelectElement>) {
    if (!onChange) {
      return;
    }

    onChange(event);
  }

  return (
    <div className={classnames('simple-dropdown', className)}>
      {label && <label htmlFor={htmlFor}>{label}</label>}
      <select
        placeholder={placeholder}
        className={classNameSelect}
        id={htmlFor}
        name={fieldRegistered}
        ref={register({
          required,
        })}
        onChange={handleChange}
        aria-invalid={ariaInvalid}
        defaultValue={defaultValue || ''}
        disabled={disabled}
      >
        <option value="">{placeholder || 'Select'}</option>
        {options?.map((item: any, key: number) => (
          <option key={key} value={item[controller]}>
            {item[text]}
          </option>
        ))}
      </select>
      {hint && <span>{hint}</span>}
    </div>
  );
};

export default SimpleDropdown;
