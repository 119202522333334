import React, { useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { PointOfSaleBase } from '@wgt/types';
import useWorkOrderContext from './useWorkOrderContext';
import WorkOrderItemForm from '../WorkOrderItemForm';
import { GET_GQL, CREATE_WORK_ORDER_GQL, GET_SCHEDULES } from './graphql';
import { CREATE_SCHEDULE } from '../WorkOrderSchedule/graphql';

interface Props {
  pointOfSale?: PointOfSaleBase;
}

export default function WorkOrder({ pointOfSale }: Props): JSX.Element {
  const {
    isOpen,
    productId,
    setWorkOrder,
    workOrder,
    activePageId,
  } = useWorkOrderContext();

  const workOrderFilterInput = useMemo(
    () => ({
      filter: {
        ...(pointOfSale && {
          posProducts: {
            pointOfSale: { id: [pointOfSale.id] },
            product: { id: [productId] },
          },
        }),
        ...(activePageId && {
          workOrderItems: {
            pageid: { id: [activePageId.id], product_id: [productId] },
          },
        }),
      },
    }),
    [productId, pointOfSale, activePageId],
  );

  const [createWorkOrderMutation, { loading: creating }] = useMutation(
    pointOfSale ? CREATE_WORK_ORDER_GQL : CREATE_SCHEDULE,
    {
      refetchQueries: [
        {
          query: GET_GQL,
          variables: workOrderFilterInput,
        },
      ],
      onCompleted: ({ createWorkOrder, createSchedule }) =>
        setWorkOrder(createWorkOrder ?? createSchedule),
    },
  );

  const { loading } = useQuery(pointOfSale ? GET_GQL : GET_SCHEDULES, {
    skip: !isOpen,
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (!data) {
        return;
      }

      let variables;

      if (pointOfSale) {
        if (!data.workOrders.data.length) {
          variables = {
            posProducts: [
              { product_id: productId, point_of_sale_id: pointOfSale.id },
            ],
          };
        }
      } else {
        if (!data.schedules.data.length) {
          variables = {
            product_ids: [productId],
            urgency: 'normal',
          };
        }
      }

      if (variables) {
        createWorkOrderMutation({ variables });
        return;
      }

      setWorkOrder(data.workOrders?.data[0] ?? data.schedules?.data[0]);
    },
    variables: workOrderFilterInput,
  });

  if (!isOpen || !workOrder || creating || loading) {
    return <React.Fragment />;
  }

  return (
    <WorkOrderItemForm
      workOrderId={workOrder.id}
      pageId={activePageId?.id?.toString()}
      isWorkOrder={!!pointOfSale}
    />
  );
}
