import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Collapse,
  Input,
  Label,
  CardFooter,
  FormGroup,
} from 'reactstrap';
import { FiChevronDown, FiChevronRight } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import { IconButton } from 'evergreen-ui';
import { Button, Text } from '../../../../../components';
import { OfficeLocation, SalesRepresentative, ExecutiveBuyer } from './Boxes';
import InvoiceField from './InvoiceField';

const OpenMargins: React.FC = () => {
  const [collapse, setCollapse] = useState(true);
  const toggle = () => setCollapse(!collapse);
  const { t } = useTranslation(['pointOfSales', 'common']);
  return (
    <Card className="rounded-0 shadow open-margins">
      <CardHeader className="border-bottom-0">
        <Row>
          <Col xs="4" className="d-flex align-items-center pl-2">
            <IconButton
              appearance="minimal"
              icon={collapse ? FiChevronDown : FiChevronRight}
              iconSize={26}
              onClick={toggle}
            />
            <Text className="card-header-title">{t('Open Margins')}</Text>
          </Col>
          <Col xs="8" className="d-flex align-items-center justify-content-end">
            <FormGroup className="simple-input mb-0 w-100" row>
              <Label
                for="password"
                className="m-0 pr-0 text-right text-muted"
                sm={7}
              >
                {t('Re-enter password to open margins')}
              </Label>
              <Col sm={5}>
                <Input
                  type="text"
                  name="password"
                  id="password"
                  className="password-input"
                />
              </Col>
            </FormGroup>
            <div className="ml-2 add-button">
              <Button small>{t('common:Add')}</Button>
            </div>
          </Col>
        </Row>
      </CardHeader>
      <Collapse isOpen={collapse}>
        <CardBody>
          <Row>
            <Col className="pr-0">
              <OfficeLocation />
            </Col>
            <Col className="pr-0">
              <SalesRepresentative />
            </Col>
            <Col>
              <ExecutiveBuyer />
            </Col>
          </Row>
        </CardBody>
        <CardFooter className="bg-white border-bold">
          <Row>
            <Col
              xs={{ size: 6, offset: 6 }}
              className="d-flex align-items-center justify-content-end"
            >
              <InvoiceField />
            </Col>
          </Row>
        </CardFooter>
      </Collapse>
    </Card>
  );
};

export default OpenMargins;
