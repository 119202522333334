import React from 'react';
import { Row, Col, TabContent, TabPane } from 'reactstrap';
import Header from './Header';
import FavouriteList from './FavouriteList';
import FavouriteBody from './FavouriteBody';
import OfficeSharePolicies from './OfficeSharePolicies';
import Footer from './Footer';
import usePointOfSaleUpdate from '../../usePointOfSaleUpdate';
import './style.scss';
import { FormProvider, useForm } from 'react-hook-form';
import FieldsSchedule from './FieldsSchedule';

export default function Policy(): JSX.Element {
  const { contentPolicyTab } = usePointOfSaleUpdate();
  const methods = useForm();
  return (
    <FormProvider {...methods}>
      <div id="policy" className="border shadow mt-3">
        <Header />
        <Row className="px-4 favourite-main" noGutters>
          <Col xs="3" className="h-100">
            <FavouriteList />
          </Col>
          <Col xs="9" className="pl-5 mb-2">
            <TabContent activeTab={contentPolicyTab} className="h-100 ">
              <TabPane tabId="create-policy" className="h-100 pb-1">
                <FavouriteBody />
              </TabPane>
              <TabPane tabId="fields-schedule" className="h-100 pb-1">
                <FieldsSchedule />
              </TabPane>
              <TabPane tabId="shared" className="h-100">
                <OfficeSharePolicies />
              </TabPane>
            </TabContent>
          </Col>
        </Row>
        <Footer />
      </div>
    </FormProvider>
  );
}
