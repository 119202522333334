import React, { useEffect, useRef } from 'react';
import moment from 'moment';
import {
  Label,
  Modal,
  ModalBody,
  Input,
  Form,
  ModalFooter,
  Col,
  Row,
} from 'reactstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { URGENCIES } from '@wgt/shared/constants';
import { useTranslation } from 'react-i18next';
import { FaChevronDown } from 'react-icons/fa';
import { ProductBase } from '@wgt/types';
import { Button } from '..';
import UrgencySelector from '../UrgencySelector';
import WorkOrderNotes from './WorkOrderNotes';
import useWorkOrderSchedule, {
  WorkOrderScheduleProvider,
} from './useWorkOrderSchedule';
import WorkOrderProducts from './WorkOrderProducts';
import WorkOrderBuyerBroker from './WorkOrderBuyerBroker';
import WorkOrderContacts from './WorkOrderContacts';
import './style.scss';

type Toggler = () => void;

interface WorkOrderScheduleProps {
  scheduleId?: string;
  workOrderId?: string;
  toggle: Toggler;
  products?: ProductBase[];
  onCompleted?: (args: { id: string }) => void;
}

function WorkOrderSchedule(): JSX.Element {
  const { t } = useTranslation('workOrder');

  const [
    { workOrder, mutating, workOrderId, activeTab },
    { toggle, submit, selectTab },
  ] = useWorkOrderSchedule();

  const methods = useForm({
    defaultValues: {
      ...workOrder,
      start:
        (workOrder?.start && moment(workOrder?.start).format('HH:mm')) ??
        moment().startOf('hour').format('HH:mm'),
      end:
        (workOrder?.end && moment(workOrder?.end).format('HH:mm')) ??
        moment().startOf('hour').add(1, 'hour').format('HH:mm'),
      date: moment(workOrder?.date).format('YYYY-MM-DD'),
      urgency:
        URGENCIES.find((x) => x.key === workOrder?.urgency) ?? URGENCIES[0],
    },
  });

  const { register, handleSubmit } = methods;

  useEffect(() => {
    methods.reset({
      ...workOrder,
      start:
        (workOrder?.start && moment(workOrder?.start).format('HH:mm')) ??
        moment().startOf('hour').format('HH:mm'),
      end:
        (workOrder?.end && moment(workOrder?.end).format('HH:mm')) ??
        moment().startOf('hour').add(1, 'hour').format('HH:mm'),
      date: moment(workOrder?.date).format('YYYY-MM-DD'),
      urgency:
        URGENCIES.find((x) => x.key === workOrder?.urgency) ?? URGENCIES[0],
    });
  }, [workOrder]);

  const onSubmit = handleSubmit(submit);

  return (
    <Modal isOpen toggle={toggle} size="xl" modalClassName="schedule-modal">
      <Row className="modal-header d-flex align-items-center" noGutters>
        <Col xs="12" sm="6">
          <h2 className="modal-title">{t('Schedule ID')}: 928349234</h2>
        </Col>
        <Col
          xs="12"
          sm="6"
          className="d-flex align-items-center justify-content-start justify-content-sm-end mt-3 mt-sm-0"
        >
          <h2 className="modal-title">{t('Work Order')}</h2>
        </Col>
      </Row>
      <ModalBody>
        <Form className="d-flex h-100" onSubmit={onSubmit}>
          <div className="d-flex h-100">
            <Row>
              <Col xl="4">
                <Row>
                  <Col
                    xs="12"
                    sm="6"
                    className="d-flex flex-row align-items-center"
                  >
                    <Label className="mb-0 mr-2">{t('Start')}&nbsp;</Label>
                    <Input
                      type="time"
                      className="form-control time-input"
                      name="start"
                      innerRef={register}
                    />
                  </Col>
                  <Col
                    xs="12"
                    sm="6"
                    className="d-flex flex-row align-items-center mt-3 mt-sm-0"
                  >
                    <Label className="mb-0 mr-sm-2 mr-3 ">
                      {t('End')}&nbsp;
                    </Label>
                    <Input
                      type="time"
                      className="form-control time-input"
                      name="end"
                      innerRef={register}
                    />
                  </Col>
                  <Col
                    xs="12"
                    className="d-flex flex-row align-items-center mt-3"
                  >
                    <Label className="mb-0 mr-2">{t('Date')}&nbsp;</Label>
                    <Input
                      type="date"
                      className="form-control time-input"
                      name="date"
                      innerRef={register}
                    />
                  </Col>
                  <Col
                    xs="12"
                    className="d-flex flex-row align-items-center mt-3"
                  >
                    <Label className="mb-0 mr-3">{t('SKU')}</Label>
                    <Input
                      type="text"
                      placeholder={t('Enter Add SKU')}
                      className="form-control time-input"
                    />
                    <Button type="primary" small className="ml-2">
                      {t('Add')}
                    </Button>
                  </Col>
                </Row>

                <div className="d-flex mt-5 flex-column h-100 overflow-hidden">
                  <div className="form-group">
                    <h4>{t('Title')}</h4>
                    <Input
                      type="text"
                      placeholder={t('Title')}
                      name="title"
                      innerRef={register}
                    />
                  </div>
                  <WorkOrderNotes />
                </div>
              </Col>

              <Col xl="8">
                <Row>
                  <Col
                    xs="12"
                    lg="6"
                    className="d-flex justify-content-between"
                  >
                    <div className="d-flex flex-row align-items-center">
                      <div>
                        <Label className="mb-0 mr-2">{t('Notify')}</Label>
                      </div>
                      <div>
                        <Input
                          type="time"
                          className="form-control time-input"
                        />
                      </div>
                    </div>
                    <FormProvider {...methods}>
                      <div className="d-flex ml-2 align-items-center">
                        <Label className="mb-0 mr-1">
                          {t('Urgency')}&nbsp;
                        </Label>
                        <UrgencySelector field="urgency" />
                      </div>
                    </FormProvider>
                  </Col>

                  <Col
                    xs="12"
                    lg="6"
                    className="d-flex justify-content-end mt-3 mt-lg-0"
                  >
                    <Button type="blue" className="outline">
                      {t('Consignment')}
                    </Button>
                    <Button type="blue" className="ml-2 outline">
                      {t('Sold')}
                    </Button>
                  </Col>

                  <Col xs="12">
                    <div className="d-flex mt-3 ml-0 ml-xl-2 justify-content-between align-items-center open-margins">
                      <span className="open-margins-title">
                        <span>
                          <FaChevronDown className="mr-2" />
                          {activeTab.label}
                        </span>
                      </span>

                      {activeTab.passwordProtected && (
                        <div className="d-flex flex-row align-items-center">
                          <span className="text-muted">
                            {t('Re-enter password to open margins')}
                          </span>
                          <div className="mx-2">
                            <input
                              type="text"
                              className="form-control form-control-sm"
                            />
                          </div>
                          <Button type="primary" small>
                            {t('View')}
                          </Button>
                        </div>
                      )}
                    </div>

                    <activeTab.component />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Form>
      </ModalBody>
      <ModalFooter className="bg-white">
        <Row>
          <Col
            xs="7"
            lg="3"
            xl="4"
            className="d-flex justify-content-between pr-3 pr-sm-0 pr-xl-3"
          >
            <div>
              <Button
                type={activeTab.id === 'contacts' ? 'primary' : 'secondary'}
                onClick={() =>
                  selectTab(
                    activeTab.id === 'contacts' ? 'open-margins' : 'contacts',
                  )
                }
              >
                {t('Contacts')}
              </Button>
            </div>
            <div>
              <Button
                type={activeTab.id === 'buyer-worker' ? 'primary' : 'secondary'}
                onClick={() =>
                  selectTab(
                    activeTab.id === 'buyer-worker'
                      ? 'open-margins'
                      : 'buyer-worker',
                  )
                }
              >
                {t('Broker')}
              </Button>
            </div>
          </Col>
          <Col xs="5" lg="2" className="d-flex justify-content-end">
            <Button type="danger">{t('Delete')}</Button>
          </Col>
          <Col
            xs="12"
            lg="7"
            xl="6"
            className="d-flex justify-content-end mt-3 mt-lg-0"
          >
            <div className="d-flex flex-row align-items-center">
              <span className="text-muted text-sm text-right mr-1 mr-xl-2">
                {t('Re-enter password to save changes')}
              </span>

              <Input type="password" name="password" />
            </div>

            <Button
              type="primary"
              onClick={onSubmit}
              disabled={mutating || !!workOrderId}
              isLoading={mutating}
              className="ml-2"
            >
              {t('Save')}
            </Button>

            <Button type="secondary" onClick={toggle} className="ml-1 ml-xl-5">
              {t('Close')}
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
}

export default function WorkOrderScheduleModal(
  props: WorkOrderScheduleProps,
): JSX.Element {
  const { t } = useTranslation('workOrder');
  const tabs = useRef([
    {
      id: 'open-margins',
      label: t('Margins'),
      component: WorkOrderProducts,
      passwordProtected: true,
    },
    {
      id: 'buyer-worker',
      label: t('Select Buyer or Broker'),
      component: WorkOrderBuyerBroker,
    },
    {
      id: 'contacts',
      label: t('Contacts'),
      component: WorkOrderContacts,
    },
  ]).current;

  return (
    <WorkOrderScheduleProvider {...props} tabs={tabs}>
      <WorkOrderSchedule />
    </WorkOrderScheduleProvider>
  );
}
