import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import CustomHeader from '../../../../components/_molecules/CustomHeader';
import useCrmRoutes from '../../../../hooks/useCrmRoutes';
import { useMutation } from '@apollo/client';
import { SubmitHandler } from 'react-hook-form';
import Form from './Form';
import { CREATE_TEMPLATE_GQL, LIST_TEMPLATES_GQL } from '../graphql';
import { TemplateBase } from '@wgt/types';
import useToast from '../../../../hooks/useToast';

const Put: React.FC = () => {
  const history = useHistory();
  const { setCustomTitle, actualRoute } = useCrmRoutes();
  const { toast } = useToast();
  const [createFn, { loading }] = useMutation(CREATE_TEMPLATE_GQL, {
    refetchQueries: [{ query: LIST_TEMPLATES_GQL }],
    onCompleted: ({ createTemplate }) => {
      toast('success');
      history.push(`/crm/${actualRoute}/${createTemplate.id}/edit`);
    },
    onError: () => {
      toast('error');
    },
  });

  useEffect(() => {
    setCustomTitle('Create template');
  }, []);

  const onSubmit: SubmitHandler<TemplateBase> = async (variables) => {
    await createFn({ variables });
  };

  return (
    <div>
      <CustomHeader title="Create" showList />
      <Form onSubmit={onSubmit} isSubmitting={loading} />
    </div>
  );
};

export default Put;
