/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { ProductBase, SearchLayoutResultColumn } from '@wgt/types';
import { PageId } from '../../pages/Crm/Products/types';
import Text from '../Text';
import { Link } from 'react-router-dom';

interface ProductWithPages extends ProductBase {
  pages: PageId[];
}
interface ProductResultColumnProps {
  product: ProductBase;
  header: SearchLayoutResultColumn;
}

function RenderLayoutLabel({
  headerKey,
  productData,
}: {
  headerKey: string;
  productData: ProductWithPages;
}): JSX.Element {
  switch (headerKey) {
    case 'sku':
      return (
        <Link to={`/crm/products/${productData.id}`}>{productData.sku}</Link>
      );

    default:
      return <Text>{productData[headerKey] ?? ''}</Text>;
  }
}

const ProductResultColumn: React.FC<ProductResultColumnProps> = ({
  product,
  header,
}) => {
  const productData = product as ProductWithPages;

  const TemplateFieldContent = () => {
    const valueContent = productData.pages?.[0]?.fields?.[header.key];
    const valueObjectString = Object.prototype.toString.call(valueContent);

    switch (valueObjectString) {
      case '[object String]':
        return <Text>{valueContent}</Text>;

      case '[object Object]':
        return Object.entries(valueContent)
          .filter(([, { selected }]: any) => selected)
          .map(([content], key) => (
            <React.Fragment key={`value-content-${key}`}>
              <Text>{content}</Text> <br />
            </React.Fragment>
          ));
      case '[object Array]':
        return valueContent.map((content: string, key: number) => (
          <React.Fragment key={`value-content-${key}`}>
            <Text>{content}</Text>
            <br />
          </React.Fragment>
        ));

      default:
        return <Text>{''}</Text>;
    }
  };

  return header.type === 'templateField' ? (
    <TemplateFieldContent />
  ) : (
    <RenderLayoutLabel headerKey={header.key} productData={productData} />
  );
};

export default ProductResultColumn;
