import React, { useState } from 'react';
import {
  Modal,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  Row,
  Col,
  TabContent,
  TabPane,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../../components';
import Filters from './Filters';
import ListOffices from './ListOffices';
import AddNewEmployee from './AddNewEmployee';
import AddNewOffice from './AddNewOffice';

interface Props {
  opened: boolean;
  toggleAddEmployeeModal: () => void;
}

export default function AddEmployeeModal({
  opened,
  toggleAddEmployeeModal,
}: Props): JSX.Element {
  const { t } = useTranslation(['hr', 'common']);
  const [contentTab, setContentTab] = useState<string>('list-offices');

  const toggleContentTab = (tab: string) =>
    setContentTab((prev) => (prev === tab ? 'list-offices' : tab));

  return (
    <Modal
      isOpen={opened}
      toggle={toggleAddEmployeeModal}
      size="xl"
      className="model-modal-gray hr-modal"
    >
      <div className="modal-header d-flex align-items-center">
        <h2 className="modal-title">{t('Add Employee')}</h2>

        <Button
          htmlType="button"
          type="link"
          className="btn btn-outline-secondary"
          onClick={toggleAddEmployeeModal}
        >
          {t('common:Cancel')}
        </Button>
      </div>

      <ModalBody>
        <Row>
          <Col lg="4" className="mb-2 mt-2">
            <p className="mb-0 text-secondary font-italic">
              {t('Filter for Results')}
            </p>
          </Col>
          <Col lg="8" className="mb-2 mt-2">
            <p className="mb-0 font-italic text-center">
              {contentTab === 'list-offices'
                ? t('Located New Employee Account')
                : contentTab === 'add-new-employee'
                ? t('Add New Employee Account')
                : t('Add New Office Location')}
            </p>
          </Col>
        </Row>
        <Row className="d-flex align-items-stretch">
          <Col lg="4" className="border-right pr-4">
            <Filters />
          </Col>
          <Col lg="8" className="pl-4">
            <TabContent activeTab={contentTab} className="content-scroll">
              <TabPane tabId="list-offices">
                <ListOffices />
              </TabPane>
              <TabPane tabId="add-new-employee">
                <AddNewEmployee />
              </TabPane>
              <TabPane tabId="add-new-office">
                <AddNewOffice />
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </ModalBody>

      <ModalFooter className="mt-3">
        <div className="mr-auto">
          <Button
            type={contentTab === 'add-new-employee' ? 'primary' : 'secondary'}
            onClick={() => toggleContentTab('add-new-employee')}
          >
            {t('New Employee')}
          </Button>
          <Button
            type={contentTab === 'add-new-office' ? 'primary' : 'secondary'}
            className="ml-5"
            onClick={() => toggleContentTab('add-new-office')}
          >
            {t('New Office')}
          </Button>
        </div>
        <Button type="danger" className="mr-3">
          {t('common:Delete')}
        </Button>
        <div className="simple-input d-flex align-items-center">
          <Label className="label-password">
            {t('common:Re-enter password to confirm changes')}
          </Label>
          <Input type="password" name="password" />
        </div>
        <Button htmlType="submit">{t('common:Save')}</Button>
      </ModalFooter>
    </Modal>
  );
}
