import { useMutation } from '@apollo/client';
import { Shape } from '@wgt/types';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { Button } from '../../../../components';
import './styles.scss';
import { DELETE_SHAPES_GQL, LIST_SHAPES_GQL } from '../graphql';
import ConfirmDialog from '../../../../components/_molecules/ConfirmDialog';
import { Dialog } from 'evergreen-ui';
import ShapePut from '../Put';
import useToast from '../../../../hooks/useToast';

interface DetailsProps {
  item: Shape;
}
interface ShapeDescription extends Shape {
  selected?: boolean;
  imageStr?: string;
}

const Details: React.FC<DetailsProps> = ({ item }) => {
  const { t } = useTranslation(['shapes', 'common']);
  const { toast } = useToast();
  const [deleteItem, setDeleteItem] = useState<number>();
  const [shapeIdToEdit, setShapeIdToEdit] = useState<string>();

  const [remove, { loading }] = useMutation(DELETE_SHAPES_GQL, {
    onCompleted: () => setDeleteItem(undefined),
    onError: (response) =>
      toast(
        'error',
        `${response.message}. ${t(
          ' Please check if this Shape is not being used in any template',
        )}`,
      ),
    refetchQueries: [
      {
        query: LIST_SHAPES_GQL,
        variables: { isEdit: true, id: item.shapeGroup.id },
      },
    ],
  });

  const images: Record<string, ShapeDescription> = useMemo(
    () => ({
      image: { ...item, imageStr: item.image_url },
      imageSelected: {
        ...item,
        imageStr: item.image_selected_url,
        selected: true,
      },
      imageHighlight: {
        ...item,
        imageStr: item.image_highlight_url,
        row_position: 'third',
      },
    }),
    [item],
  );
  return (
    <>
      <ConfirmDialog
        isShown={!!deleteItem}
        onConfirm={() =>
          remove({
            variables: { id: deleteItem },
          })
        }
        title={t('Remove shape?')}
        onClose={() => setDeleteItem(undefined)}
        isLoading={loading}
      />
      <td colSpan={7} className="shape-details">
        <Row>
          <Col sm={9}>
            {Object.values(images)
              .filter((shape) => !!shape.imageStr?.length)
              .map((shape, key) => (
                <Row key={`shape-image-${key}`} className="shape-image-card">
                  <Col sm={1}>
                    {shape.imageStr?.length && (
                      <img src={shape.imageStr} className="w-100" />
                    )}
                  </Col>
                  <Col sm={3}>{shape.title}</Col>
                  <Col sm={2}>
                    {shape.abbreviations?.map((abb, abbKey) => (
                      <span key={`abb-${abbKey}`}>{abb}</span>
                    ))}
                  </Col>
                  <Col sm={2}>{shape.shapeGroup.title}</Col>
                  <Col>{shape.row_position}</Col>
                  <Col>
                    {t('Selected')}:{' '}
                    {shape.selected ? t('common:Yes') : t('common:No')}
                  </Col>
                </Row>
              ))}
          </Col>
          <Col />
        </Row>
        <Row>
          <Col className="d-flex justify-content-end">
            <Button
              onClick={() => setShapeIdToEdit(item.id?.toString())}
              isLoading={loading}
              disabled={loading}
              className="mr-3"
            >
              Update
            </Button>
            <Button
              type="danger"
              onClick={() => setDeleteItem(item.id)}
              isLoading={loading}
              disabled={loading}
            >
              remove
            </Button>
          </Col>
        </Row>
      </td>
      {!!shapeIdToEdit && (
        <Dialog
          isShown={!!shapeIdToEdit}
          footer={() => (
            <div>
              <Button
                type="secondary"
                onClick={() => setShapeIdToEdit(undefined)}
              >
                {t('common:Cancel')}
              </Button>
            </div>
          )}
          onCloseComplete={() => setShapeIdToEdit(undefined)}
          width="80%"
        >
          <ShapePut shapeId={shapeIdToEdit} />
        </Dialog>
      )}
    </>
  );
};

export default Details;
