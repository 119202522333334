import { gql, useQuery } from '@apollo/client';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import SelectOfficeCard from '../../SelectOfficeCard';

export default function ExecutiveBuyerSelect(): JSX.Element {
  const { t } = useTranslation('pointOfSales');
  const { data } = useQuery(gql`
    query users {
      users(first: 1000) {
        data {
          id
          name: first_name
          addresses {
            address
            state
            country {
              id
              name
            }
          }
          phone
          jobTitles {
            id
            name
          }
        }
      }
    }
  `);

  const users = useMemo(() => {
    return data?.users.data
      ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data?.users.data.map((item: any) => ({
          id: item.id,
          name: item.name,
          address: item.addresses?.[0]?.address,
          state: item.addresses?.[0]?.state,
          phone: item.phone,
          country: {
            name: item.addresses?.[0]?.country.name,
          },
        }))
      : [];
  }, [data?.users.data]);

  return (
    <SelectOfficeCard
      title={t('Client')}
      options={users}
      field="executiveBuyer"
      placeholder={t('Choose a Client')}
      required={true}
    />
  );
}
