import React, { Fragment } from 'react';
import { Row, Col, Label } from 'reactstrap';
import {
  PropertyValueVariation,
  PropertyValueVariationValue,
  ExpandedDropdownOptions,
} from '@wgt/types';
import { useFormContext } from 'react-hook-form';

interface Props {
  option: ExpandedDropdownOptions;
  field: string;
}

export default function Variations({ option, field }: Props): JSX.Element {
  const { register, getValues } = useFormContext();

  return (
    <div className="px-3 pt-3">
      {option.variations?.map(
        (variation: PropertyValueVariation, variationKey: number) => (
          <div key={`variation-value-${variationKey}`}>
            <p className="popover-titles">{variation.name}</p>
            <Row className="mb-2">
              {variation?.variationValues?.map(
                (value: PropertyValueVariationValue, valueKey: number) => (
                  <Fragment key={`variation-value-${valueKey}`}>
                    <Col className="d-flex align-items-center mb-2 column-variation">
                      <input
                        type="checkbox"
                        className="variation-value-checkbox mr-2"
                        id={`variation-${value.id}`}
                        ref={register()}
                        name={`${field}.${value.key}`}
                        defaultChecked={getValues(`${field}.${value.key}`)}
                      />
                      <Label htmlFor={`variation-${value.id}`}>
                        {value.name}
                      </Label>
                    </Col>
                    {!!Number.isInteger((valueKey + 1) / 3) && (
                      <div className="w-100" />
                    )}
                  </Fragment>
                ),
              )}
            </Row>
          </div>
        ),
      )}
    </div>
  );
}
