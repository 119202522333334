/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react';
import { ApolloQueryResult, useQuery, gql } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import useAuthenticationCookies from '@wgt/authentication/useAuthenticationCookies';

interface GetMeProps {
  fetchPolicy?:
    | 'cache-first'
    | 'network-only'
    | 'cache-only'
    | 'no-cache'
    | 'standby'
    | undefined;
}

interface Permission {
  id: number;
  name: string;
}
interface Role {
  id: number;
  name: string;
  permissions: Permission[];
}

export interface Me {
  id: number;
  first_name: string;
  middle_name: string;
  last_name: string;
  gender: string;
  birthdate: Date;
  email: string;
  business_email: string;
  phone: string;
  mobile: string;
  business_phone: string;
  business_phone_extension: string;
  business_mobile: string;
  toll_free_business_number: string;
  address: string;
  city: string;
  state: string;
  country: string;
  zip_code: string;
  secret_phrase: string;
  fingerprint_code: string;
  roles: Role[];
  permissions: Permission[];
}

const meGql = gql`
  query meQuery {
    me {
      id
      first_name
      middle_name
      last_name
      gender
      birthdate
      email
      business_email
      phone
      mobile
      business_phone
      business_phone_extension
      business_mobile
      toll_free_business_number
      addresses {
        address
        city
        state
        country {
          id
          name
        }
        zip_code
      }

      fingerprint_code
      roles {
        name
        permissions {
          id
          name
        }
      }
      permissions {
        id
        name
      }
      employments {
        id
        name
        status
      }
    }
  }
`;

export default function useMe({
  fetchPolicy = 'cache-first',
}: GetMeProps = {}): [
  Me | undefined,
  (
    variables?: Record<string, any> | undefined,
  ) => Promise<ApolloQueryResult<any>>,
] {
  const history = useHistory();
  const [currentToken, setToken] = useAuthenticationCookies();
  const { data, refetch, client } = useQuery(meGql, {
    fetchPolicy,
    onError: async () => {
      await Promise.all([setToken(), client.clearStore()]);

      history.push('/');
    },
    skip: !currentToken?.access_token,
  });

  const me: Me | undefined = useMemo(() => {
    if (!data) {
      return undefined;
    }
    return data.me;
  }, [data]);

  return [me, refetch];
}
