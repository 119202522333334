import React, { memo, useMemo } from 'react';

import { Pane } from 'evergreen-ui';
import ShapeDescriptionSelector from '../../../../components/ShapeDescriptionSelector';
import ShapeGroupsForm from '../../../../components/ShapeGroupsForm';
import { useFormContext } from 'react-hook-form';
import { CategoryBase } from '../../../../../../types';
import { PARCEL_TYPE_MULTIPLE_ID } from '@wgt/shared/constants';
import { useBagContext } from '../../../../services/bag';

export default memo(function ProductPageIdShapes(): JSX.Element {
  const { watch } = useFormContext();
  const {
    isShapeDescriptionsVisible,
    toggleShapeDescriptionVisible,
  } = useBagContext();
  const category: CategoryBase = watch('page.category');
  const parcelTypeId: number = watch('page.fields.parcel_type_id');
  const shapes = watch('page.shapes');
  const selectedShape = useMemo(
    () => category?.template?.shapes?.find((x) => x.id === shapes?.[0]?.id),
    [category, shapes],
  );
  console.log({ shapes, selectedShape });
  return (
    <div className="mt-5">
      {!!selectedShape && (
        <Pane
          padding={15}
          position="absolute"
          right={0}
          maxHeight={350}
          zIndex={2}
        >
          <ShapeDescriptionSelector
            isAddStock
            shapeDescriptions={selectedShape.descriptions}
            selectorProps={{
              name: 'page.shapes[0].descriptions',
              isVisible: isShapeDescriptionsVisible,
            }}
            onClose={toggleShapeDescriptionVisible}
          />
        </Pane>
      )}
      <ShapeGroupsForm
        shapes={category?.template?.shapes ?? []}
        field="page.shapes"
        isMulti={Number(parcelTypeId) === PARCEL_TYPE_MULTIPLE_ID}
      />
    </div>
  );
});
