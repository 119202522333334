/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation } from '@apollo/client';
import { SearchLayoutResultColumn } from '@wgt/types';
import { Alert, Heading, Pane, SideSheet } from 'evergreen-ui';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../../../components';
import { TemplateLayoutContext } from '../../../../../../hooks/useTemplateLayout';
import useToast from '../../../../../../hooks/useToast';
import { UPDATE_TEMPLATE_GQL } from '../../../graphql';

import Fields from './Fields';
import BagProvider from '../../../../../../services/bag';
import { SEARCH_HEADER_COLUMN_PRODUCT_OPTIONS } from '@wgt/shared/constants';

const ResultHeaderBuilder: React.FC = () => {
  const [isOpen, setOpen] = useState(false);
  const { template } = useContext(TemplateLayoutContext);
  const { t } = useTranslation('template');
  const [{ availables, inserteds }, setFields] = useState<{
    availables: SearchLayoutResultColumn[];
    inserteds: SearchLayoutResultColumn[];
  }>({
    availables: [],
    inserteds: [],
  });

  useEffect(() => {
    const stored: SearchLayoutResultColumn[] =
      typeof template?.result_header === 'string'
        ? JSON.parse(template.result_header)
        : template?.result_header ?? [];

    const fields =
      template?.fields.map<SearchLayoutResultColumn>((field, key) => ({
        title: field.label,
        selected: false,
        type: 'templateField',
        key: field.property?.key?.toString() ?? `templateField-${key}`,
      })) ?? [];

    fields.push(...SEARCH_HEADER_COLUMN_PRODUCT_OPTIONS);
    const availableFields = fields.reduce<SearchLayoutResultColumn[]>(
      (acc, field) => {
        if (!stored.some((x) => x.key === field.key)) {
          acc.push({ ...field, selected: false });
        }
        return acc;
      },
      [],
    );
    setFields({ inserteds: stored, availables: availableFields });
  }, [template?.result_header, template?.fields]);

  const move = ({ from, to }: any) => {
    setFields((current) => {
      const draft = {
        inserteds: [...current.inserteds],
        availables: [...current.availables],
      };

      if (to.kind === 'inserted') {
        const toIndex = draft.inserteds.findIndex(
          (x) => x.key === to.item?.key,
        );
        if (toIndex < 0) {
          if (from.kind === 'available') {
            draft.inserteds.push({ ...from.item.field, selected: true });
            draft.availables.splice(
              draft.availables.findIndex((x) => x.key === from.item.field.key),
              1,
            );
          }
          return draft;
        }
        if (from.kind === 'inserted') {
          draft.inserteds.splice(
            draft.inserteds.findIndex((x) => x.key === from.item.key),
            1,
          );
        } else {
          draft.availables.splice(
            draft.availables.findIndex((x) => x.key === from.item.key),
            1,
          );
        }
        draft.inserteds.splice(
          draft.inserteds.findIndex((x) => x.key === to.item?.key),
          0,
          { ...from.item, selected: true },
        );
      } else if (from.kind === 'inserted' && to.kind === 'available') {
        draft.inserteds.splice(
          draft.inserteds.findIndex((x) => x.key === from.item.key),
          1,
        );
        draft.availables.push({
          ...(from.item.field ?? from.item),
          selected: false,
        });
      }
      return draft;
    });
  };

  const params = useParams<ParamTypes>();
  const { toast } = useToast();

  const [updateTemplate, { loading }] = useMutation(UPDATE_TEMPLATE_GQL, {
    variables: {
      id: params.id,
      template: {
        result_header: JSON.stringify(inserteds),
      },
    },
    onCompleted: () => toast('success', t('Layout saved successfully')),
  });

  return (
    <div>
      <Button htmlType="button" onClick={() => setOpen(true)} type="secondary">
        {t('Create Results Table structure')}
      </Button>

      {isOpen && (
        <SideSheet isShown={isOpen} onCloseComplete={() => setOpen(false)}>
          <BagProvider value={{ availables, inserteds, move }}>
            <Pane
              zIndex={1}
              flexShrink={0}
              elevation={0}
              backgroundColor="white"
            >
              <Pane
                padding={16}
                borderBottom="muted"
                className="d-flex justify-content-between"
              >
                <Heading size={600}>{t('Set search results columns')}</Heading>
                <Button
                  htmlType="button"
                  isLoading={loading}
                  onClick={updateTemplate}
                >
                  {t('save')}
                </Button>
              </Pane>
              <Pane flex={1} padding={16}>
                <Alert>{t('Drop fields here')}</Alert>
                <br />
                <Fields fields={inserteds} type="inserted" />
              </Pane>
              <Pane flex={1} padding={16}>
                <Alert>{t('Drag fields from here')}</Alert>
                <br />
                <Fields fields={availables} type="available" />
              </Pane>
            </Pane>
          </BagProvider>
        </SideSheet>
      )}
    </div>
  );
};

export default ResultHeaderBuilder;
