import React, { useState, ChangeEvent } from 'react';
import { Switch, Tooltip, Paragraph } from 'evergreen-ui';
import { useFormContext } from 'react-hook-form';
import {
  FaEdit,
  FaQuestionCircle,
  FaSave,
  FaTimes,
  FaTrash,
} from 'react-icons/fa';
import classnames from 'classnames';
import { Label, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { SectionPropertiesProps } from '../../../../../../../hooks/useTemplateLayout';

interface TemplateLayoutGroupTitleProps {
  title?: string;
  isCharacteristic?: boolean;
  isFieldsInline?: boolean;
  registerField: string;
  handleEditSectionProperties: (
    sectionProperties: SectionPropertiesProps,
  ) => void;
  toggleIsCharacteristic: () => void;
  toggleIsFieldsInline: () => void;
  size: number | string;
  isSizeInvalid?: boolean;
}

const TemplateLayoutGroupTitle: React.FC<TemplateLayoutGroupTitleProps> = ({
  registerField,
  title,
  isCharacteristic,
  isFieldsInline,
  toggleIsCharacteristic,
  toggleIsFieldsInline,
  size,
  handleEditSectionProperties,
  isSizeInvalid,
}) => {
  const [isEditing, setEditing] = useState(false);
  const { register, getValues } = useFormContext();

  const handleEditGroupTitle = () => {
    handleEditSectionProperties({ groupTitle: getValues(registerField) });
    setEditing(false);
  };

  const handleEditSize = (e: ChangeEvent<HTMLSelectElement>) =>
    handleEditSectionProperties({ size: e.target.value });

  const { t } = useTranslation('template');

  return (
    <div className="d-flex flex-column">
      <div className="group-title-form-holder">
        {isEditing ? (
          <input
            ref={register}
            name={registerField}
            defaultValue={title}
            autoFocus
          />
        ) : (
          <label className={classnames({ placeholder: !title?.length })}>
            {title?.length ? title : t('Enter Group Title (optional)')}
          </label>
        )}
        {isEditing ? (
          <>
            <FaSave onClick={handleEditGroupTitle} color="green" />
            <FaTimes onClick={() => setEditing(false)} />
          </>
        ) : (
          <>
            <FaEdit onClick={() => setEditing(true)} color="#0074a0" />
            {!!title?.length && (
              <FaTrash
                onClick={() => handleEditSectionProperties({ groupTitle: '' })}
                color="red"
              />
            )}
          </>
        )}
      </div>
      <Row>
        <div className="col-6">
          <Label htmlFor="fieldsInline" className="font-small mr-1 mb-0">
            {t('Fields inline')}
          </Label>
          <Switch
            checked={isFieldsInline}
            id="fieldsInline"
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              toggleIsFieldsInline();
            }}
            className="mb-0"
          />
        </div>
        <div className="col-6">
          <Label htmlFor="characteristic" className="font-small mr-1 mb-0">
            {t('Characteristic')}
          </Label>
          <Switch
            checked={isCharacteristic}
            id="characteristic"
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              toggleIsCharacteristic();
            }}
            className="mb-0"
          />
        </div>
        <div className="col-6">
          <Label htmlFor="" className="font-small mr-1 text-nowrap mb-0">
            {t('Column size')}
          </Label>
          <div className="d-flex align-items-center">
            <select
              className={classnames('form-control form-control-sm mr-2', {
                'is-invalid': isSizeInvalid,
              })}
              value={size.toString()}
              onChange={handleEditSize}
            >
              {Array.from(Array(12).keys()).map((index) => (
                <option key={index}>{index + 1}</option>
              ))}
            </select>
            <Tooltip
              appearance="card"
              content={
                <>
                  <Paragraph>
                    {t(`The sum of columns should not exceed 12`)}
                  </Paragraph>
                  <a
                    href="//getbootstrap.com/docs/4.0/layout/grid/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Paragraph color="blue">
                      {t(`Check Bootstrap's Grid System Spec`)}
                    </Paragraph>
                  </a>
                </>
              }
            >
              <span>
                <FaQuestionCircle />
              </span>
            </Tooltip>
          </div>
        </div>
      </Row>
    </div>
  );
};

export default TemplateLayoutGroupTitle;
