import { useQuery } from '@apollo/client';
import React, { useMemo } from 'react';
import { Row, Col } from 'reactstrap';
import useLoggedContext from '../../../../../../../hooks/useLoggedContext';
import { LIST_POLICIES_GQL } from '../graphql';
import { Policy, PolicyType } from '@wgt/types';
import PolicyCard from '../PolicyCard';

interface GroupedPolicyType extends PolicyType {
  policies: Policy[];
}

export default function OfficeSharePolicies(): JSX.Element {
  const { active } = useLoggedContext();

  const { data } = useQuery<{ policies: { data: Policy[] } }>(
    LIST_POLICIES_GQL,
    {
      skip: !active?.id,
      variables: {
        first: 1000,
        filter: {
          firm: { id: [active?.id] },
        },
      },
    },
  );

  const policyGroups = useMemo<GroupedPolicyType[]>(
    () =>
      Object.values(
        data?.policies?.data.reduce<{
          [key: string]: GroupedPolicyType;
        }>((acc, policy) => {
          const typeKey = policy.type?.id?.toString() ?? '';
          if (!typeKey || !policy.type) {
            return acc;
          }
          if (!acc[typeKey]) {
            acc[typeKey] = {
              ...policy.type,
              policies: [],
            };
          }

          acc[typeKey].policies.push(policy);
          return acc;
        }, {}) ?? {},
      ),
    [data],
  );

  return (
    <div className="h-100 overflow-auto office-share-policies">
      <Row className="h-100" noGutters>
        {policyGroups.map(({ id, policies }) => (
          <Col xs="3" className="pr-1" key={`policy-group-${id}`}>
            {policies.map((policy) => (
              <PolicyCard key={policy.id} policy={policy} />
            ))}
          </Col>
        ))}
      </Row>
    </div>
  );
}
