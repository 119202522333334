import { InMemoryCache } from '@apollo/client';
import { currentAuthQuery } from '../../graphql/auth.gql';
import config from './config';
import { AuthenticationActions } from '@wgt/authentication';

const cache = new InMemoryCache(config);

const storedToken = AuthenticationActions.getter();

if (storedToken) {
  const defaultProps = {
    access_token: '',
    token_refresh: '',
  };
  cache.writeQuery({
    query: currentAuthQuery,
    data: {
      authorization: { ...defaultProps, ...storedToken },
    },
  });
}

export default cache;
