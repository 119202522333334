import { gql } from '@apollo/client';
import { paginatorInfoFragment } from '../../../services/graphql/paginator.gql';

export const propertyValueVariationFragment = gql`
  fragment propertyValueVariationInfo_Part on PropertyValueVariation {
    id
    name
  }
`;

export const propertyValueVariationValueFragment = gql`
  fragment propertyValueVariationValueInfo_Part on PropertyValueVariationValue {
    id
    name
    key
    options
    image
    image_url
  }
`;

export const LIST_PROPERTY_VALUE_VARIATIONS_GQL = gql`
  query propertyValueVariations($page: Int) {
    propertyValueVariations(page: $page, first: 1000) {
      data {
        ...propertyValueVariationInfo_Part
      }
      paginatorInfo {
        ...paginatorInfo_Part
      }
    }
  }
  ${paginatorInfoFragment}
  ${propertyValueVariationFragment}
`;

export const CREATE_PROPERTY_VALUE_VARIATIONS_GQL = gql`
  mutation createPropertyValueVariation($name: String!) {
    createPropertyValueVariation(name: $name) {
      id
    }
  }
`;

export const UPDATE_PROPERTY_VALUE_VARIATIONS_GQL = gql`
  mutation updatePropertyValueVariation(
    $id: ID!
    $propertyValueVariation: UpdatePropertyValueVariationInput!
  ) {
    updatePropertyValueVariation(
      id: $id
      property_value_variation: $propertyValueVariation
    ) {
      id
    }
  }
`;

export const DELETE_PROPERTY_VALUE_VARIATION_GQL = gql`
  mutation deletePropertyValueVariation($id: ID!) {
    deletePropertyValueVariation(id: $id) {
      message
    }
  }
`;

export const GET_PROPERTY_VALUE_VARIATION_GQL = gql`
  query propertyValueVariation($id: ID!, $isEdit: Boolean!) {
    propertyValueVariation(id: $id) @include(if: $isEdit) {
      ...propertyValueVariationInfo_Part
      variationValues {
        ...propertyValueVariationValueInfo_Part
      }
    }
  }
  ${propertyValueVariationFragment}
  ${propertyValueVariationValueFragment}
`;

export const CREATE_PROPERTY_VALUE_VARIATION_VALUE_GQL = gql`
  mutation createPropertyValueVariationValue(
    $name: String!
    $key: String!
    $property_value_variation_id: ID!
    $image: Upload
  ) {
    createPropertyValueVariationValue(
      name: $name
      key: $key
      image: $image
      property_value_variation_id: $property_value_variation_id
    ) {
      id
    }
  }
`;

export const UPDATE_PROPERTY_VALUE_VARIATION_VALUE_GQL = gql`
  mutation updatePropertyValueVariationValue(
    $id: ID!
    $propertyValueVariationValue: UpdatePropertyValueVariationValueInput!
  ) {
    updatePropertyValueVariationValue(
      id: $id
      property_value_variation_value: $propertyValueVariationValue
    ) {
      id
      name
    }
  }
`;

export const DELETE_PROPERTY_VALUE_VARIATION_VALUE_GQL = gql`
  mutation deletePropertyValueVariationValue($id: ID!) {
    deletePropertyValueVariationValue(id: $id) {
      message
    }
  }
`;

export const LIST_PROPERTY_VALUE_VARIATION_VALUES_GQL = gql`
  query propertyValueVariationValues(
    $page: Int
    $filter: PropertyValueVariationValueFilterInput
  ) {
    propertyValueVariationValues(page: $page, filter: $filter) {
      data {
        ...propertyValueVariationValueInfo_Part
      }
      paginatorInfo {
        ...paginatorInfo_Part
      }
    }
  }
  ${propertyValueVariationValueFragment}
  ${paginatorInfoFragment}
`;

export const GET_PROPERTY_VALUE_VARIATION_VALUE_GQL = gql`
  query propertyValueVariationValue($id: ID!, $isEdit: Boolean!) {
    propertyValueVariationValue(id: $id) @include(if: $isEdit) {
      ...propertyValueVariationValueInfo_Part
    }
  }
  ${propertyValueVariationValueFragment}
`;
