import { gql, useApolloClient, useMutation } from '@apollo/client';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import StateManager from 'react-select';
import { PageId, ProductBase } from '../../../../../../types';
import { Button } from '../../../../components';
import SelectSearch from '../../../../components/SelectSearch';
import {
  LIST_PRODUCTS_PAGE_IDS_GQL,
  MOVE_PAGE_ID_TO_CURRENT_SKU_GQL,
  PRODUCT_PAGES_GQL,
} from './graphql';

export default function MovePageIDToCurrentSku({
  getProductId,
  disabled,
}: {
  disabled?: boolean;
  getProductId?: (message?: string) => Promise<string | undefined>;
}): JSX.Element {
  const { t } = useTranslation(['product', 'common']);
  const ref = useRef<StateManager<PageId> | null>(null);
  const client = useApolloClient();
  const params = useParams<{ categoryId: string; productId?: string }>();
  const history = useHistory();
  const [mutate, { loading: submitting }] = useMutation(
    MOVE_PAGE_ID_TO_CURRENT_SKU_GQL,
    {
      refetchQueries:
        (params.productId && [
          {
            query: PRODUCT_PAGES_GQL,
            variables: {
              id: params.productId,
            },
          },
        ]) ||
        undefined,
    },
  );

  const product = client.readFragment<ProductBase>({
    fragment: gql`
      fragment productMovePageId_Part on Product {
        id
        name
        sku
        category {
          id
          name
        }
      }
    `,
    id: `Product:${params.productId}`,
  });

  const move = async () => {
    if (!ref.current?.state.value) {
      return;
    }

    let sku = product?.sku;
    let generatedProductId;
    if (!sku && getProductId) {
      generatedProductId = await getProductId(
        t(
          'We will save current product form to bring this Page ID into this SKU',
        ),
      );
      if (!generatedProductId) {
        return;
      }

      sku = client.readFragment<ProductBase>({
        fragment: gql`
          fragment productIdentifier_Part on Product {
            id
            sku
          }
        `,
        id: `Product:${generatedProductId}`,
      })?.sku;
      if (!sku) {
        return;
      }
    }

    const selectedId = (ref.current?.state.value as PageId).id;

    await mutate({
      variables: {
        sku,
        id: selectedId,
      },
    });

    if (generatedProductId) {
      history.push(
        `/crm/categories/${params.categoryId}/products/${
          product?.id || generatedProductId
        }`,
      );
    }
  };

  return (
    <div className="d-flex sku-form">
      <div className="w-100" style={{ fontSize: 10 }}>
        <SelectSearch
          name="name"
          isDisabled={disabled}
          ref={ref}
          placeholder={t('Enter Page ID and add to this SKU')}
          getOptionValue={(e) => `${e.id}`}
          getOptionLabel={(e) =>
            `${e.name ?? ''} ${
              e.product?.sku ? `- ${t('SKU')}: ${e.product.sku}` : ''
            }`
          }
          parseVariables={(variables) => ({
            filter: {
              name: variables.filter.name,
              category_id: params.categoryId,
            },
          })}
          gql={LIST_PRODUCTS_PAGE_IDS_GQL}
        />
      </div>
      <Button
        small
        htmlType="button"
        onClick={move}
        disabled={submitting}
        isLoading={submitting}
        className="ml-3 mr-2"
      >
        {t('common:Add')}
      </Button>
    </div>
  );
}
