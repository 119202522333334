import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormGroup, Label, Col, Input } from 'reactstrap';

export default function InvoiceField(): JSX.Element {
  const { t } = useTranslation('pointOfSales');
  return (
    <FormGroup className="simple-input mb-0 w-100" row>
      <Label for="invoice" className="m-0 pr-0 pt-2 text-right" sm={5}>
        {t('Invoice')}
      </Label>
      <Col sm={7} className="pr-3">
        <Input type="select" name="invoice" id="invoice">
          <option value="">{t('New Invoice ID')} 12312</option>
          <option>2</option>
          <option>3</option>
          <option>4</option>
          <option>5</option>
        </Input>
      </Col>
    </FormGroup>
  );
}
