import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaGlobe } from 'react-icons/fa';
import Button from '../Button';
import useTraceDocuments from './useTraceDocuments';

export default function TraceDocumentsButton(): JSX.Element {
  const { t } = useTranslation('traceDocuments');
  const [, { setSideSheetShown }] = useTraceDocuments();
  return (
    <Button
      small
      iconBefore={FaGlobe}
      type="primary"
      className="btn-trace-document"
      onClick={() => setSideSheetShown(true)}
    >
      {t('Trace Document')}
    </Button>
  );
}
