import { PaymentBase } from '@wgt/types';
import { createContext, useContext } from 'react';

export const ReceiptContext = createContext({});

interface UseReceipt {
  invoice: string;
  editPayment(payment: PaymentBase): void;
  setIsFormVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedInvoice: React.Dispatch<React.SetStateAction<string>>;
}

export default function useReceipt(): UseReceipt {
  return useContext(ReceiptContext) as UseReceipt;
}
