/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { LOCAL_STORAGE } from '../utils/constants';

interface User {
  name: string;
  access_token: string;
  token_refresh: string;
}

export const asyncLocalStorage = {
  setItem(key: string, value: string) {
    return Promise.resolve().then(() => {
      localStorage.setItem(key, value);
    });
  },
  getItem(key: string) {
    return Promise.resolve().then(() => {
      return localStorage.getItem(key);
    });
  },
  removeItem(key: string) {
    return Promise.resolve().then(() => {
      return localStorage.removeItem(key);
    });
  },
};

export const isLoggedIn = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      asyncLocalStorage.getItem(LOCAL_STORAGE.user).then((user) => {
        resolve(user);
      });
    }, 500);
  });
};

export const setLogged = (user: User) => {
  return new Promise((resolve) => {
    asyncLocalStorage
      .setItem(LOCAL_STORAGE.user, JSON.stringify(user))
      .then(() => {
        resolve(user);
      });
  });
};
export const setLogout = () => {
  return new Promise((resolve) => {
    asyncLocalStorage.removeItem(LOCAL_STORAGE.user).then(() => {
      resolve(true);
    });
  });
};
