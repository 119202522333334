import React from 'react';
import { useFormContext } from 'react-hook-form';
import classnames from 'classnames';
import { BoxId } from '@wgt/types';
import { useProductUpdate } from '../../../Update/Provider';
import { Pane } from 'evergreen-ui';

interface BoxItemProps {
  box: BoxId;
  index: number;
}

export default function BoxItem({ box, index }: BoxItemProps): JSX.Element {
  const { register, errors } = useFormContext();
  const { isFormEnabled } = useProductUpdate();

  return (
    <>
      <Pane marginBottom="10px">
        <label>{`Box ID ${index + 1}`}</label>
        <input
          type="hidden"
          name={`boxes[${index}].id`}
          ref={register()}
          defaultValue={box.id}
          disabled={!isFormEnabled}
        />
        <input
          name={`boxes[${index}].name`}
          ref={register()}
          defaultValue={box.name}
          className={classnames('form-control w-100', {
            'is-invalid': errors.boxes?.[index]?.name,
          })}
          disabled={!isFormEnabled}
        />
      </Pane>
    </>
  );
}
