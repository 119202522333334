import { gql } from '@apollo/client';

export const GET_JOB_TITLES_GQL = gql`
  query jobTitles($filter: JobTitleFilterInput) {
    jobTitles(first: 1000, filter: $filter) {
      data {
        id
        name
        parent_id
        main_id
        level
      }
    }
  }
`;

export const CREATE_JOB_TITLE_GQL = gql`
  mutation createJobTitleMutation(
    $name: String!
    $parent_id: ID
    $main_id: ID
    $level: Int!
  ) {
    createJobTitle(
      name: $name
      parent_id: $parent_id
      main_id: $main_id
      level: $level
    ) {
      id
    }
  }
`;

export const GET_DEPARTMENTS_GQL = gql`
  query departments($filter: DepartmentFilterInput) {
    departments(first: 1000, filter: $filter) {
      data {
        id
        name
        parent {
          id
        }
        children {
          id
          name
        }
      }
    }
  }
`;

export const CREATE_DEPARTMENT_GQL = gql`
  mutation createDepartmentMutation(
    $name: String!
    $tenant_id: ID
    $parent_id: ID
  ) {
    createDepartment(
      name: $name
      parent_id: $parent_id
      tenant_id: $tenant_id
    ) {
      id
    }
  }
`;

export const CREATE_EMPLOYEE_GQL = gql`
  mutation createEmployee(
    $first_name: String!
    $last_name: String!
    $email: String!
    $direct_phone: String!
    $ext: String
    $office_mobile: String!
    $office_id: String
  ) {
    createEmployee(
      first_name: $first_name
      last_name: $last_name
      email: $email
      direct_phone: $direct_phone
      ext: $ext
      office_mobile: $office_mobile
      office_id: $office_id
    ) {
      id
    }
  }
`;
