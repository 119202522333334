import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Form, Label, ModalBody, ModalFooter } from 'reactstrap';
import { Button } from '../../';

import PaymentForm from '../../PaymentForm';
import { FormProvider, useForm } from 'react-hook-form';
import SimpleInput from '../../SimpleInput';
import useReceipt from '../useReceipt';
import usePayments from '../../../pages/Crm/PointOfSales/Details/Resume/Payment/usePayments';
import { useQuery } from '@apollo/client';
import { GET_PAYMENT } from '../graphql';

export default function ReceiptPaymentForm({
  toggle,
  paymentId: paymentIdToEdit,
}: {
  toggle: () => void;
  paymentId?: string;
}): JSX.Element {
  const { t } = useTranslation(['pointOfSales', 'common']);
  const methods = useForm();
  const { invoice } = useReceipt();
  const {
    remove: [removePayment, { loading: removingPayment }],
    put,
  } = usePayments({
    invoiceNumber: invoice,
  });
  const paymentId = methods.watch<'id', string>('id');
  const isEditingPayment = !!paymentId;

  const onSubmit = methods.handleSubmit(async (variables) => {
    delete variables.password;
    await put({ ...variables, id: paymentId });
    if (!isEditingPayment) {
      methods.reset();
    }
  });

  const onDelete = async () => {
    await removePayment({
      variables: {
        id: paymentId,
      },
    });
    reset();
  };

  useQuery(GET_PAYMENT, {
    variables: { id: paymentIdToEdit },
    skip: !paymentIdToEdit,
    onCompleted: ({ payment }) => methods.reset(payment),
  });

  const reset = () =>
    methods.reset({
      invoice_id: invoice,
      payment_types: [],
      compound_interest_date: new Date(),
      plan_next_payment: [
        {
          payment_after_date: new Date(),
        },
      ],
    });

  useEffect(() => {
    if (!paymentIdToEdit) {
      reset();
    }
  }, [paymentIdToEdit]);

  return (
    <FormProvider {...methods}>
      <Form onSubmit={onSubmit}>
        <ModalBody>
          <Container fluid>
            <PaymentForm />
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button onClick={toggle} type="secondary" className="mr-auto">
            {t('common:Cancel')}
          </Button>

          {!!isEditingPayment && (
            <Button
              type="danger"
              className="mr-3"
              disabled={removingPayment}
              onClick={onDelete}
            >
              {t('common:Delete')}
            </Button>
          )}
          <Label className="label-password">
            {t('common:Re-enter password to confirm changes')}
          </Label>
          <SimpleInput type="password" name="password" field="password" />
          <Button htmlType="submit" disabled={removingPayment}>
            {t('common:Save')}
          </Button>
        </ModalFooter>
      </Form>
    </FormProvider>
  );
}
