/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import SelectOfficeCard from '../../../../../../components/SelectOfficeCard';
import usePointOfSaleUpdate from '../../usePointOfSaleUpdate';
import { GET_POINT_OF_SALE_GQL } from '../../../graphql';
import { FormProvider, useForm } from 'react-hook-form';

const OfficeLocation: React.FC = () => {
  const { t } = useTranslation();
  const client = useApolloClient();
  const {
    pointOfSaleFilter,
    pointOfSale,
    setFakeData,
  } = usePointOfSaleUpdate();
  const data = client.readQuery({
    query: GET_POINT_OF_SALE_GQL,
    variables: pointOfSaleFilter,
  });

  const methods = useForm({
    defaultValues: {
      office: pointOfSale.office?.id ?? '',
    },
  });

  const w = methods.watch('office');

  useEffect(() => {
    setFakeData((x: any) => ({ ...x, office: w }));
  }, [w]);

  return (
    <FormProvider {...methods}>
      <SelectOfficeCard
        title={t('Office Location')}
        options={data?.offices?.data ?? []}
        field="office"
        placeholder={t('Choose an office')}
      />
    </FormProvider>
  );
};

export default OfficeLocation;
