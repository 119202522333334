/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from '@apollo/client';
import { User } from '@wgt/types';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import SelectOfficeCard from '../../../../../../components/SelectOfficeCard';
import usePointOfSaleUpdate from '../../usePointOfSaleUpdate';
import { USERS_GQL } from '../../../graphql';
import { FormProvider, useForm } from 'react-hook-form';
import useLoggedContext from '../../../../../../hooks/useLoggedContext';

const ExecutiveBuyer: React.FC = () => {
  const { t } = useTranslation();
  const { pointOfSale, setFakeData } = usePointOfSaleUpdate();
  const { active } = useLoggedContext();

  const { data } = useQuery(USERS_GQL, {
    skip: !active?.id,
    variables: {
      filter: {
        employments: {
          sales_representative: true,
          _not: { id: active?.id },
        },
      },
    },
  });
  const users = useMemo(
    () =>
      data?.users?.data?.map(
        ({ id, first_name, middle_name, last_name, email }: User) => ({
          id,
          name: `${first_name} ${middle_name} ${last_name}`,
          email,
        }),
      ) ?? [],
    [data?.users],
  );
  const methods = useForm({
    defaultValues: {
      executive_buyer: pointOfSale.executiveBuyer?.id ?? '',
    },
  });

  const w = methods.watch('executive_buyer');

  /* MOCK DATA */
  useEffect(() => {
    setFakeData((x: any) => ({ ...x, executiveBuyer: w }));
  }, [w]);

  return (
    <FormProvider {...methods}>
      <SelectOfficeCard
        title={t('Executive Buyer')}
        options={users}
        field="executive_buyer"
        placeholder={t('Choose an executive buyer')}
      />
    </FormProvider>
  );
};

export default ExecutiveBuyer;
