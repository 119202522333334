import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { PaymentTypeGroup } from '@wgt/types';
import { FormGroup, Input, Label } from 'reactstrap';
import { LIST_PAYMENT_TYPE_GROUPS_GQL } from './graphql';
import { useFormContext } from 'react-hook-form';
import { Pane } from 'evergreen-ui';

const PaymentFieldsValues: React.FC = () => {
  const { data } = useQuery<{
    paymentTypeGroups: { data: PaymentTypeGroup[] };
  }>(LIST_PAYMENT_TYPE_GROUPS_GQL, {
    fetchPolicy: 'cache-only',
  });
  const { watch, register } = useFormContext();
  const watchPaymentTypes = watch('payment_types', []);

  const paymentTypeGroupsFields = useMemo<PaymentTypeGroup[]>(
    () =>
      data?.paymentTypeGroups?.data?.filter((paymentTypeGroup) =>
        paymentTypeGroup?.paymentTypes?.some((paymentType) =>
          watchPaymentTypes?.includes(Number(paymentType.id)),
        ),
      ) ?? [],
    [data, watchPaymentTypes],
  );

  return (
    <FormGroup>
      {paymentTypeGroupsFields.map((paymentGroup, groupKey) => (
        <Pane key={`group-${groupKey}`}>
          {paymentGroup.fields?.map((field, fieldKey) => (
            <FormGroup key={`group-${paymentGroup.id}-field-${fieldKey}`}>
              <Label>{field.label}</Label>
              <Input
                placeholder={field.placeholder}
                name={`paymentGroup[group-${paymentGroup.id}].${field.name}`}
                innerRef={register()}
              />
            </FormGroup>
          ))}
          <hr />
        </Pane>
      ))}
    </FormGroup>
  );
};

export default PaymentFieldsValues;
