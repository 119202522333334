import { useMutation, useQuery } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import ConfirmDialog from '../../../../../../../../../components/_molecules/ConfirmDialog';
import { TemplateFieldsContext } from '../../../../../../../../../hooks/useTemplateFieldsContext';
import { Title } from '../../../../../../../../../components';
import {
  CREATE_TEMPLATE_FIELD_VALUE_DEPENDENCY,
  TEMPLATE_FIELD_VALUE_DEPENDENCIES,
  REMOVE_TEMPLATE_FIELD_VALUE_DEPENDENCY,
} from '../../../../../../graphql';
import {
  SubmitDependenciesFormProps,
  TemplateFieldDependencyGraphql,
} from '../../../../../../types';
import FieldDependenciesForm from '../../FieldDependenciesForm';
import FieldDependenciesList from '../../FieldDependenciesList';
import {
  LayoutComponentDataTypePropertyValue,
  TemplateFieldBase,
} from '@wgt/types';
import useToast from '../../../../../../../../../hooks/useToast';

interface Props {
  field: LayoutComponentDataTypePropertyValue;
  filteredFields: TemplateFieldBase[];
}

interface SubmitProps {
  dataValues: SubmitDependenciesFormProps;
}

const FieldValueDependencyContent: React.FC<Props> = ({
  field,
  filteredFields,
}) => {
  const { active } = useContext(TemplateFieldsContext);
  const { toast } = useToast();

  const [
    dependencyToRemove,
    setDependencyToRemove,
  ] = useState<TemplateFieldDependencyGraphql | null>(null);

  const { data, loading, refetch } = useQuery(
    TEMPLATE_FIELD_VALUE_DEPENDENCIES,
    {
      fetchPolicy: 'cache-first',
      skip: !field.id || !active?.id,
      variables: {
        template_field_id: Number(active?.id),
        property_value_id: Number(field?.id),
      },
    },
  );

  const [createFieldValueDependency, { loading: creating }] = useMutation(
    CREATE_TEMPLATE_FIELD_VALUE_DEPENDENCY,
    {
      onCompleted: () => {
        refetch();
        toast('success');
      },
    },
  );

  const [removeFieldDependency, { loading: removing }] = useMutation(
    REMOVE_TEMPLATE_FIELD_VALUE_DEPENDENCY,
    {
      onCompleted: () => {
        refetch();
        setDependencyToRemove(null);
        toast('success');
      },
    },
  );

  const onSubmit: SubmitHandler<SubmitProps> = async ({ dataValues }) => {
    createFieldValueDependency({
      variables: {
        template_field_id: Number(active?.id),
        property_value_id: Number(field.id),
        dependency: {
          template_field_id: Number(dataValues.fieldId),
          property_value_id: Number(dataValues.valueId) || null,
        },
      },
    });
  };

  const onDeleteDependency = () => {
    removeFieldDependency({
      variables: {
        template_field_id: Number(active?.id),
        property_value_id: Number(field.id),
        ...dependencyToRemove,
      },
    });
  };

  return (
    <div className="field-value-dependency-holder">
      <ConfirmDialog
        isShown={!!dependencyToRemove}
        onConfirm={onDeleteDependency}
        isLoading={removing}
        title="Confirm delete dependency?"
        onClose={() => setDependencyToRemove(null)}
      />
      <Row>
        <Col>
          <Title size={200}>{field.name}</Title>

          <FieldDependenciesForm
            filteredFields={filteredFields}
            loading={loading || creating}
            onSubmit={(dataValues) => onSubmit({ dataValues })}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FieldDependenciesList
            fieldDependencies={
              data?.templateFieldValueDependencies?.fieldDependencies
            }
            valueDependencies={
              data?.templateFieldValueDependencies?.valueDependencies
            }
            loading={loading}
            setDependencyToRemove={setDependencyToRemove}
          />
        </Col>
      </Row>
    </div>
  );
};

export default FieldValueDependencyContent;
