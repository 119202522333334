import React, { useMemo, useState } from 'react';
import { FiPlusCircle, FiMinusCircle } from 'react-icons/fi';
import { Input, Collapse, Container, Row, Col } from 'reactstrap';
import Barcode from 'react-barcode';
import { IconButton } from 'evergreen-ui';
import MediaDummy from '../../../../../../../assets/media-dummy.png';
import classnames from 'classnames';
import {
  SearchLayoutResultColumn,
  BoxId,
  CrateId,
  Category,
  Report,
  PageId,
  MediaCenterItemBase,
} from '@wgt/types';
import ProductResultColumn from '../../../../../../../components/SearchResultsTable/ProductResultColumn';
import usePointOfSaleUpdate, {
  ProductHighlight,
} from '../../../usePointOfSaleUpdate';
import ProductDetails from './ProductDetails';
import Provider from '../../../../../Products/Update/Provider';
import { FormProvider, useForm } from 'react-hook-form';
import ItemVerificationInput from './ItemVerificationInput';
import useLoggedContext from '../../../../../../../hooks/useLoggedContext';
import WorkOrder from '../../../../../../../components/WorkOrder';
import { WorkOrderProvider } from '../../../../../../../components/WorkOrder/useWorkOrderContext';

interface Props {
  product: ProductHighlight;
  headers: SearchLayoutResultColumn[];
}
interface ProductInput {
  id: number;
  boxes: BoxId[];
  crates: CrateId[];
  category: Category;
  description: string;
  name: string;
  office: { id: number; name: string };
  lot_id: string;
  sku: string;
  tray_id: string;
  reports: Report[];
  pages: PageId[];
  product_status_id?: string;
  office_id?: number;
  files?: MediaCenterItemBase[];
}

const Item: React.FC<Props> = ({ headers, product }) => {
  const [collapse, setCollapse] = useState<boolean>(false);
  const {
    selectedProducts,
    setSelectedProducts,
    pointOfSale,
  } = usePointOfSaleUpdate();
  const { active } = useLoggedContext();

  const UPDATE_STOCK_FILTERS = useMemo(
    () => ({
      id: product.id,
      officeFilter: active ? { firm_id: [active.id] } : null,
    }),
    [active, product.id],
  );

  const toggle = () => setCollapse(!collapse);

  const isSelected = useMemo<boolean>(
    () =>
      !!selectedProducts.find(
        (selectedProduct) => selectedProduct.id === product.id,
      ),
    [selectedProducts],
  );

  const onClick = () =>
    isSelected
      ? setSelectedProducts((oldProducts) => [
          ...oldProducts.filter((oldProduct) => oldProduct.id !== product.id),
        ])
      : setSelectedProducts((oldProducts) => [...oldProducts, product]);

  const methods = useForm<ProductInput>({
    defaultValues: {
      ...product,
      product_status_id: product?.productStatus?.id,
      crates: product.crates?.length ? [...product.crates] : [{ name: '' }],
      boxes: product.boxes?.length ? [...product.boxes] : [{ name: '' }],
      office_id: product.office?.id,
      files: product.files,
    },
    shouldUnregister: false,
  });

  return (
    <Row
      className={classnames('border rounded mb-2 bg-light p-2 items', {
        highlight: product.highlight,
      })}
    >
      <Col lg="auto" className="d-flex align-items-center">
        <Input
          type="checkbox"
          className="position-relative m-0"
          checked={isSelected}
          onChange={onClick}
        />
        <IconButton
          appearance="minimal"
          icon={collapse ? FiMinusCircle : FiPlusCircle}
          iconSize={26}
          onClick={toggle}
          className="d-inline-block icon-toggle"
          style={{ color: 'green' }}
        />
        <img
          src={MediaDummy}
          alt="Title from Media"
          className="rounded-circle image-media"
        />
      </Col>
      {headers?.map((header) => (
        <Col key={`header-${header.key}`}>
          <ProductResultColumn product={product} header={header} />
        </Col>
      ))}

      <Col lg="12">
        {product.highlight && (
          <div className="d-flex justify-content-around bg-white border rounded mt-1">
            <ItemVerificationInput product={product} />
            <Barcode value={product.sku} displayValue={false} height={50} />
          </div>
        )}

        <Collapse isOpen={collapse}>
          {collapse && (
            <WorkOrderProvider productId={product.id}>
              <>
                <Container fluid={true}>
                  <FormProvider {...methods}>
                    <Provider filters={UPDATE_STOCK_FILTERS}>
                      <ProductDetails />
                    </Provider>
                  </FormProvider>
                </Container>
                <WorkOrder pointOfSale={pointOfSale} />
              </>
            </WorkOrderProvider>
          )}
        </Collapse>
      </Col>
    </Row>
  );
};

export default Item;
