import React from 'react';

import { FaPlusCircle } from 'react-icons/fa';

import { MediaCenterController } from '../../../../../../hooks/useMediaCenterController';
import { useBagContext } from '../../../../../../services/bag';

export type UploadFileAttribute = 'image' | 'file' | 'video';

const AddButton: React.FC = () => {
  const { setCreating, setExpanded } = useBagContext<MediaCenterController>();

  return (
    <div
      className="add-media-button"
      onClick={() => [setCreating(true), setExpanded(true)]}
    >
      <FaPlusCircle />
    </div>
  );
};

export default AddButton;
