import React from 'react';

import { ProductStatus } from '../../pages/Crm/Products/types';
import SearchProductDetailsActions from './Actions';
import CommonAttributes from './CommonAttributes';
import SearchProductDetailsOffice from './Office';
import SearchProductDetailsProductIds from './ProductIds';
import TemplateFields from './TemplateFields';

const ProductResultDetails: React.FC<{ statuses: ProductStatus[] }> = () => {
  return (
    <>
      <div className="bg-white p-3">
        <CommonAttributes />
        <TemplateFields />
        <SearchProductDetailsOffice />
      </div>
      <SearchProductDetailsProductIds />
      <SearchProductDetailsActions />
    </>
  );
};

export default ProductResultDetails;
