import React, { useEffect } from 'react';
import useCrmRoutes from '../../../../hooks/useCrmRoutes';
import { GET_FIRM_GQL } from '../graphql';
import { useQuery } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import Loader from '../../../../components/_organisms/Loader';
import CustomHeader from '../../../../components/_molecules/CustomHeader';
import { Button } from '../../../../components';

const Details: React.FC = () => {
  const { setCustomTitle, routes } = useCrmRoutes();
  const history = useHistory();
  const params = useParams<ParamTypes>();

  const { loading, data } = useQuery(GET_FIRM_GQL, {
    skip: !params?.id,
    variables: {
      id: params.id,
    },
  });

  useEffect(() => {
    if (data?.firm?.name) {
      setCustomTitle(data.firm.name);
    }
  }, [data, routes]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <CustomHeader
        title={`${data?.firm?.name || 'Details'}`}
        showList
        customActions={
          <Button
            onClick={() => history.push(`${history.location.pathname}/offices`)}
          >
            Manage offices
          </Button>
        }
      />
    </div>
  );
};

export default Details;
