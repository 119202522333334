import React, { useEffect, useMemo } from 'react';
import { FiPlusCircle, FiMenu } from 'react-icons/fi';
import { Row, Col, Badge } from 'reactstrap';
import usePayments from '../../../pages/Crm/PointOfSales/Details/Resume/Payment/usePayments';
import useReceipt from '../useReceipt';

export default function PaymentList(): JSX.Element {
  const { invoice, editPayment, setIsFormVisible } = useReceipt();
  const { query } = usePayments({
    fetchPolicy: 'cache-only',
    invoiceNumber: invoice,
  });

  const payments = useMemo(() => query.data?.payments?.data ?? [], [
    query.data?.payments,
  ]);

  useEffect(() => {
    if (invoice) {
      query.refetch();
    }
  }, [invoice]);

  if (!invoice) {
    return <></>;
  }

  return (
    <Row className="container-select-badge">
      <Col lg="12" className="d-flex my-2">
        <div className="p-1 rounded bg-white mr-3">
          <Badge
            className="badge-receipt"
            onClick={() => [
              setIsFormVisible(true),
              editPayment({ name: '', invoice_id: invoice }),
            ]}
          >
            <FiPlusCircle size="20" className="orange" />
            <FiMenu size="20" />
          </Badge>
        </div>

        {payments.map((payment) => (
          <div
            className="p-1 rounded bg-white mr-3"
            key={payment.id}
            onClick={() =>
              editPayment({
                ...payment,
                invoice_id: payment.invoice?.id ?? invoice,
              })
            }
          >
            <Badge className="badge-receipt">
              <span className="badge badge-pill badge-number">1</span>
              <FiMenu size="20" />
            </Badge>
          </div>
        ))}
      </Col>
    </Row>
  );
}
