import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { Controller, useFormContext } from 'react-hook-form';
import Select from 'react-select';
import { ADD_STOCK_GQL } from '../../graphql';
import { useProductUpdate } from '../Provider';
import { CATEGORY_TYPE_STONE_ID } from '@wgt/shared/constants';
import { Category } from '@wgt/types';

export default function CategorySelector(): JSX.Element {
  const { getValues } = useFormContext();
  const { pages, filters, isFormEnabled } = useProductUpdate();
  const { data } = useQuery<{ categories: { data: Category[] } }>(
    ADD_STOCK_GQL,
    {
      variables: filters,
      fetchPolicy: 'cache-only',
    },
  );

  const categories = useMemo(() => data?.categories?.data ?? [], [
    data?.categories?.data,
  ]);

  if (!pages.active || pages.activeIndex === undefined) {
    return <></>;
  }

  return (
    <div className="d-flex">
      <div className="form-group w-100">
        <label>Select Stone Type</label>

        <Controller
          as={Select}
          name={`pages[${pages.activeIndex}].category`}
          options={categories.filter(
            (category) =>
              Number(category.categoryType?.id) === CATEGORY_TYPE_STONE_ID,
          )}
          getOptionValue={(e: Category) => `${e.id}`}
          getOptionLabel={(e: Category) => e.name}
          defaultValue={getValues(`pages[${pages.activeIndex}].category`)}
          isDisabled={!isFormEnabled}
        />
      </div>
    </div>
  );
}
