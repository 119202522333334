import React, { useMemo } from 'react';
import { Avatar, IconButton, MinusIcon, PlusIcon } from 'evergreen-ui';
import { URGENCIES } from '@wgt/shared/constants';
import WorkOrderProductItemDetail from './WorkOrderProductItemDetail';
import useWorkOrderProduct from './useWorkOrderProduct';
import useWorkOrderSchedule from '../useWorkOrderSchedule';

export default function WorkOrderProductItem(): JSX.Element {
  const [{ isDetailShown, product }, { toggleDetail }] = useWorkOrderProduct();
  const [{ workOrder }] = useWorkOrderSchedule();

  const urgency = useMemo(
    () => URGENCIES[Math.floor(Math.random() * URGENCIES.length)],
    [],
  );

  return (
    <div
      className="row row-item mb-3"
      style={{
        backgroundColor: urgency.backgroundColor,
        border: `1px solid ${urgency.color}`,
      }}
    >
      <div className="col-12">
        <div className="row align-items-center ">
          <div className="col-auto d-flex align-items-center pr-0">
            <div className="mr-2">
              {workOrder?.id && (
                <IconButton
                  type="button"
                  icon={isDetailShown ? MinusIcon : PlusIcon}
                  appearance="minimal"
                  onClick={toggleDetail}
                />
              )}
            </div>
            <div>
              <Avatar
                src="http://www.sparrowdg.com/images/naturaldiamond.png"
                size={50}
              />
            </div>
          </div>
          <div className="col-1">
            <span className="font-weight-bold">{product.name}</span>
          </div>

          <div className="col-1">
            <span className="text-link">{product.sku}</span>
          </div>
          <div className="col-1 px-0">
            <span>{product.pages?.[0]?.shapes?.[0]?.title} </span>
          </div>
          <div className="col d-flex">
            <div className="row align-items-center w-100 flex-grow-1">
              <div className="col-2 px-0">
                <span>0.10 ct</span>
              </div>
              <div className="col-1 pl-0">
                <span>D</span>
              </div>
              <div className="col-1 pl-0">
                <span>VVS2</span>
              </div>
              <div className="col-3 pl-0 d-flex">
                <span className="mr-2">1.90(l)</span>
                <span className="mr-2">1.24(w)</span>
                <span>1.60(d)</span>
              </div>
              <div className="col-1 pl-4">
                <span>${product.total_price?.toFixed(2)}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isDetailShown && <WorkOrderProductItemDetail />}
    </div>
  );
}
