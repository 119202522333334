/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useState, useRef, useMemo } from 'react';
import { Pane } from 'evergreen-ui';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Calendar,
  momentLocalizer,
  NavigateAction,
  View,
} from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Title from '../Title';
import Line from '../Line';
import PickerInput from './PickerInput';
import WorkOrderSchedule from '../WorkOrderSchedule';
import UserCalendarFilters from './UserCalendarFilters';
import { useQuery } from '@apollo/client';
import { UrgencyType, WorkOrderItem, Schedule } from '@wgt/types';
import useDebounce from '@wgt/shared/hooks/useDebounce';
import useMe from '../../hooks/useMe';
import { LIST_CALENDAR_ITEMS } from './graphql';

const localizer = momentLocalizer(moment);

export default function CustomCalendar(): JSX.Element {
  const { t } = useTranslation(['calendar', 'common']);
  const debounce = useDebounce({ ms: 1000 });
  const datePicker = useRef<DatePicker | null>(null);
  const [me] = useMe();
  const [calendarView, setCalendarView] = useState<View>('month');

  const [activeEvent, setActiveEvent] = useState<WorkOrderItem>();
  const methods = useForm({
    shouldUnregister: false,
    defaultValues: {
      start: moment().startOf('month').toDate(),
      end: moment().endOf('month').toDate(),
      title: '',
      workOrderType: '',
      urgency: '',
      activity: '',
    },
  });

  const { setValue, watch } = methods;

  const { urgency, workOrderType, title, activity } = watch([
    'workOrderType',
    'urgency',
    'title',
    'activity',
  ]);
  const rangeFilter = watch(['start', 'end']);
  const { start, end } = rangeFilter;

  const filter = useMemo(() => {
    const args: any = {
      start: {
        from: moment(start).format('YYYY-MM-DD HH:mm:ss'),
        to: moment(end).format('YYYY-MM-DD HH:mm:ss'),
      },
    };

    if (workOrderType) {
      args.workOrderType = { id: workOrderType };
    }

    if (urgency) {
      args.urgency = (urgency as any).key;
    }

    if (title) {
      args.title = `${title}%`;
    }

    if (activity) {
      const parsedActivity = activity as any;
      switch (parsedActivity.value) {
        case 'consignment':
          args.workOrder = {
            posProducts: {
              pointOfSale: {
                intent: parsedActivity.value,
              },
            },
          };
          break;
        case 'invoice_development':
          args.workOrder = {
            posProducts: {
              pointOfSale: {
                invoices: {
                  status: 'development',
                },
              },
            },
          };
          break;
        case 'consignment_expiry_date':
          args.workOrder = {
            posProducts: {
              pointOfSale: {
                intent: 'consignment',
                policy: {
                  expiry_date: {
                    from: moment()
                      .add(1, 'day')
                      .startOf('day')
                      .format('YYYY-MM-DD HH:mm:ss'),
                  },
                },
              },
            },
          };
          break;
        case 'sold_expiry_date':
          args.workOrder = {
            posProducts: {
              pointOfSale: {
                intent: 'sold',
                policy: {
                  expiry_date: {
                    from: moment()
                      .add(1, 'day')
                      .startOf('day')
                      .format('YYYY-MM-DD HH:mm:ss'),
                  },
                },
              },
            },
          };
          break;
        case 'seller':
          args.workOrder = {
            posProducts: {
              pointOfSale: {
                sales_representative_id: [me?.id],
              },
            },
          };
          break;
        case 'buyer':
          args.workOrder = {
            posProducts: {
              pointOfSale: {
                executive_buyer_id: [me?.id],
              },
            },
          };
          break;
        case 'broker':
          break;
        default:
      }
    }

    return args;
  }, [start, end, workOrderType, urgency, title, activity]);

  const { data } = useQuery<{
    schedules: { data: Schedule[] };
    workOrderItems: { data: WorkOrderItem[] };
  }>(LIST_CALENDAR_ITEMS, {
    variables: {
      workOrderItemFilter: filter,
    },
  });

  const onChange = (dates: any) => {
    if (!dates) {
      return;
    }

    const [begin, finish] =
      Object.prototype.toString.call(dates) === '[object Array]'
        ? dates
        : [dates, moment(dates).endOf('day').toDate()];

    setValue('start', begin);
    setValue('end', finish);
  };

  const onNavigate = useCallback(
    (newDate: Date, view: View, action: NavigateAction) => {
      let unit: moment.unitOfTime.DurationConstructor;
      switch (view) {
        case 'day':
          unit = 'day';
          break;
        case 'week':
          unit = 'week';
          break;
        default:
          unit = 'month';
      }

      switch (action) {
        case 'PREV':
          setValue(
            'start',
            moment(start).subtract(1, unit).startOf(unit).toDate(),
          );
          setValue('end', moment(end).subtract(1, unit).endOf(unit).toDate());
          break;
        case 'NEXT':
          setValue('start', moment(start).add(1, unit).startOf(unit).toDate());
          setValue('end', moment(end).add(1, unit).endOf(unit).toDate());
          break;
        case 'TODAY':
          setValue('start', moment().startOf(unit).toDate());
          setValue('end', moment().endOf(unit).toDate());
          break;
        default:
      }
    },
    [start, end],
  );

  const onSelect = useCallback(
    (event: WorkOrderItem) => setActiveEvent(event),
    [],
  );

  const onTitleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;

      debounce(() => setValue('title', value));
    },
    [],
  );

  const onView = useCallback(
    (view: View) => {
      setCalendarView(view);
      switch (view) {
        case 'week':
          setValue('start', moment().startOf('week').toDate());
          setValue('end', moment().endOf('week').toDate());
          break;
        case 'day':
          setValue('start', moment().startOf('day').toDate());
          setValue('end', moment().endOf('day').toDate());
          break;
        default:
          setValue('start', moment().startOf('month').toDate());
          setValue('end', moment().endOf('month').toDate());
      }
    },
    [start, end],
  );

  return (
    <>
      <FormProvider {...methods}>
        <UserCalendarFilters />
        <Pane display="flex" alignItems="center" justifyContent="space-between">
          <Pane display="flex" alignItems="center" zIndex={5}>
            <Title heading="h4">{t('Results')}</Title>

            <DatePicker
              selectsRange={calendarView !== 'day'}
              ref={datePicker}
              selected={rangeFilter.start}
              startDate={rangeFilter.start}
              endDate={rangeFilter.end}
              onChange={onChange}
              customInput={<PickerInput />}
              shouldCloseOnSelect={calendarView === 'day'}
            />
          </Pane>
          <Pane>
            <Input
              placeholder={t('search in calendar')}
              name="title"
              onChange={onTitleSearch}
            />
          </Pane>
        </Pane>
        <Line />
        <Calendar
          popup
          onSelectEvent={onSelect}
          onNavigate={onNavigate}
          localizer={localizer}
          events={data?.workOrderItems.data ?? []}
          titleAccessor={(e) =>
            `WO-${e.id} - ${
              e.title ?? e.workOrder?.title ?? e.schedule?.title ?? '---'
            }`
          }
          views={{
            month: true,
            week: true,
            day: true,
          }}
          onView={onView}
          date={start}
          style={{ height: 800 }}
          eventPropGetter={(evt) => ({
            style: {
              backgroundColor: (evt.urgency as UrgencyType).backgroundColor,
            },
          })}
        />
      </FormProvider>
      {activeEvent && (
        <WorkOrderSchedule
          scheduleId={activeEvent.schedule?.id}
          workOrderId={activeEvent.workOrder?.id}
          toggle={() => setActiveEvent(undefined)}
        />
      )}
    </>
  );
}
