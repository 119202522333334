import React from 'react';
import CustomHeader from '../../../../components/_molecules/CustomHeader';
import { useTranslation } from 'react-i18next';
import { Pane } from 'evergreen-ui';
import { UploadButton } from '../../../../components';
import { Controller, useFormContext } from 'react-hook-form';
import { Col, Modal, Row } from 'reactstrap';
import ImageCropper from '../../../../components/ImageCropper';

function ShapeImageItem({
  name,
  required,
  isEdit,
}: {
  name: string;
  required?: boolean;
  isEdit?: boolean;
}): JSX.Element {
  const { t } = useTranslation('shapes');
  const [isModalOpen, setModalOpen] = React.useState(false);

  return (
    <Controller
      name={name}
      defaultValue=""
      rules={{ required }}
      render={({ onChange, value }) => (
        <>
          <UploadButton
            setImageToUpload={onChange}
            buttonText={t(`${isEdit ? 'Update' : 'Add'} Image`)}
            accept=".jpg, .png, .jpeg, .gif"
            name="image"
          />
          {value && typeof value !== 'string' && (
            <>
              <button
                type="button"
                className="btn btn-link"
                onClick={() => setModalOpen(true)}
              >
                {t('crop image')}
              </button>
              <Modal
                size="lg"
                isOpen={isModalOpen}
                toggle={() => setModalOpen((x) => !x)}
                zIndex={2050}
              >
                <ImageCropper
                  file={value}
                  onChange={onChange}
                  onConfirm={() => setModalOpen(false)}
                  onCancel={() => setModalOpen(false)}
                  aspect={4 / 4}
                />
              </Modal>
            </>
          )}
        </>
      )}
    />
  );
}

export default function ShapeImages(): JSX.Element {
  const { t } = useTranslation('shapes');
  const { watch, getValues } = useFormContext();
  const rowPosition = watch('row_position', 'first');

  const rowPositionCapitalized = rowPosition.replace(/^\w/, (c: string) =>
    c.toUpperCase(),
  );
  const { image_url, image_selected_url, image_highlight_url } = getValues([
    'image_url',
    'image_selected_url',
    'image_highlight_url',
  ]);

  return (
    <>
      <Row>
        <Col>
          <CustomHeader title={t('Shape Image')} />
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={3}>
          <Pane
            elevation={1}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="space-between"
            minHeight={300}
            padding={20}
          >
            <p>{t(`${rowPositionCapitalized} Row`)}</p>
            {image_url?.length && <img src={image_url} className="w-50 mb-3" />}
            <ShapeImageItem name="image" isEdit={image_url?.length} required />
          </Pane>
        </Col>
        <Col sm={12} md={3}>
          <Pane
            elevation={1}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="space-between"
            minHeight={300}
            padding={20}
          >
            <p>{t(`${rowPositionCapitalized} Row Selected`)}</p>
            {image_selected_url?.length && (
              <img src={image_selected_url} className="w-50 mb-3" />
            )}
            <ShapeImageItem
              name="image_selected"
              isEdit={image_selected_url?.length}
            />
          </Pane>
        </Col>
        <Col sm={12} md={3}>
          <Pane
            elevation={1}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="space-between"
            minHeight={300}
            padding={20}
          >
            <p>{t('Third')}</p>
            {image_highlight_url?.length && (
              <img src={image_highlight_url} className="w-50 mb-3" />
            )}
            <ShapeImageItem
              name="image_highlight"
              isEdit={image_highlight_url?.length}
            />
          </Pane>
        </Col>
      </Row>
    </>
  );
}
