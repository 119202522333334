import React, { Dispatch, SetStateAction } from 'react';
import { FaTrash } from 'react-icons/fa';
import Loader from '../../../../../../../../components/_organisms/Loader';
import { Button } from '../../../../../../../../components';
import {
  TemplateFieldDependencyGraphql,
  ValueDependencies,
} from '../../../../../types';
import { LayoutComponentFormValues } from '@wgt/types';

interface Props {
  fieldDependencies: LayoutComponentFormValues[] | undefined;
  valueDependencies: ValueDependencies[] | undefined;
  loading: boolean;
  setDependencyToRemove: Dispatch<
    SetStateAction<TemplateFieldDependencyGraphql | null>
  >;
}

const FieldDependenciesList: React.FC<Props> = ({
  loading,
  fieldDependencies,
  valueDependencies,
  setDependencyToRemove,
}) => {
  if (loading) {
    return <Loader />;
  }
  return (
    <>
      {!!fieldDependencies?.length && (
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th scope="col">Fields</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {fieldDependencies?.map((fieldDependency, key) => (
              <tr key={key}>
                <td>{fieldDependency.label}</td>
                <td>
                  <Button
                    small
                    type="danger"
                    onClick={() =>
                      setDependencyToRemove({
                        dependency: {
                          template_field_id: fieldDependency.id,
                        },
                      })
                    }
                  >
                    <FaTrash />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {!!valueDependencies?.length && (
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th scope="col">Values</th>
            </tr>
          </thead>
          <tbody>
            {valueDependencies?.map((valueDependency, key) => (
              <tr key={key}>
                <td>
                  {valueDependency.field.label} / {valueDependency.value.name}
                </td>
                <td>
                  <Button
                    small
                    type="danger"
                    onClick={() =>
                      setDependencyToRemove({
                        dependency: {
                          template_field_id: valueDependency.field.id,
                          property_value_id: valueDependency.value.id,
                        },
                      })
                    }
                  >
                    <FaTrash />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};

export default FieldDependenciesList;
