import React, { useMemo } from 'react';
import { BsStopwatch } from 'react-icons/bs';
import classnames from 'classnames';
import { Row, Col, FormGroup } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { WorkOrderItem as WorkOrderItemType } from '@wgt/types';
import { useForm, Controller } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import moment from 'moment';
import { Button } from '../';
import SelectWorkOrderTypeTree from '../SelectWorkOrderTypeTree';
import useWorkOrderItemFormContext from './useWorkOrderItemContext';
import { CREATE_WORK_ORDER_ITEM } from '../WorkOrderSchedule/graphql';
import { GET_WORK_ORDER_ITEMS_WITH_SCHEDULE } from './graphql';
import MediaDummy from '../../assets/media-dummy.png';

interface WorkOrderItemProps {
  workOrderItem?: WorkOrderItemType;
}

export default function WorkOrderItem({
  workOrderItem,
}: WorkOrderItemProps): JSX.Element {
  const { t } = useTranslation(['workOrder', 'common']);
  const [
    { workOrder, pageId, activeWorkOrderItem, fetchArgs },
    { setActiveWorkOrderItemId },
  ] = useWorkOrderItemFormContext();
  const [mutate, { loading }] = useMutation(CREATE_WORK_ORDER_ITEM, {
    refetchQueries: [
      { query: GET_WORK_ORDER_ITEMS_WITH_SCHEDULE, variables: fetchArgs },
    ],
    onCompleted: ({ createWorkOrderItem }) =>
      setActiveWorkOrderItemId(createWorkOrderItem.id),
  });

  const { handleSubmit, control, getValues } = useForm({
    defaultValues: workOrderItem
      ? {
          ...workOrderItem,
          workordertype_id: workOrderItem.workOrderType.id,
          id: workOrderItem.id,
        }
      : {
          workordertype_id: null,
          workorder_id: workOrder?.id,
          pageid_id: pageId,
        },
  });

  const onSubmit = handleSubmit((values) => {
    if (!workOrderItem) {
      mutate({
        variables: {
          ...values,
          pageid_id: pageId,
          workorder_id: workOrder?.id,
          status: 'active',
        },
      });
      return;
    }

    setActiveWorkOrderItemId(
      activeWorkOrderItem?.id === workOrderItem.id
        ? undefined
        : workOrderItem.id,
    );
  });

  const isActive = useMemo(
    () => activeWorkOrderItem && activeWorkOrderItem.id === workOrderItem?.id,
    [workOrderItem, activeWorkOrderItem],
  );

  return (
    <Row className="mx-0 border-bottom py-2" noGutters>
      <Col xl="5" xs="12">
        <Row noGutters>
          <Col
            xs="auto"
            className="d-flex align-items-center justify-content-center pr-2"
          >
            <img
              src={MediaDummy}
              alt={t('Title from Media')}
              className="rounded-circle image-media ml-0"
            />
          </Col>

          <Col xs="5">
            <div className="d-flex flex-wrap flex-column">
              <p className="text-muted mb-2">{t('Estimated Start Date')}</p>
              <div className="text-nowrap">
                {workOrderItem?.start
                  ? moment(workOrderItem?.start).format('MMMM Do YYYY, h:mm a')
                  : '---'}
              </div>
            </div>
          </Col>
          <Col xs="12" sm="5" className="margin-work-order-avatar">
            <div className="d-flex flex-wrap flex-column">
              <p className="text-muted mb-2">{t('Estimated Return Date')}</p>
              <div className="text-nowrap">
                {workOrderItem?.end
                  ? moment(workOrderItem?.end).format('MMMM Do YYYY, h:mm a')
                  : '---'}
              </div>
            </div>
          </Col>
        </Row>
      </Col>
      <Col xl="4" xs="12" md="8" className="mt-2 mt-xl-0">
        <Row noGutters>
          <Col className="pr-3" xs="8">
            <p className="mb-1">
              <strong>{t('Register Work Order')}</strong>
            </p>
            <FormGroup className="simple-input mb-0">
              <Controller
                name="workordertype_id"
                control={control}
                rules={{ required: true }}
                defaultValue={getValues('workordertype_id') ?? ''}
                render={({ onChange, value }) => (
                  <SelectWorkOrderTypeTree
                    field="workordertype_id"
                    onChange={onChange}
                    value={value}
                    workOrderItemId={workOrderItem?.id}
                  />
                )}
              />
            </FormGroup>
          </Col>
          <Col
            xs="4"
            className="d-flex align-items-end justify-content-center justify-content-xl-end"
          >
            <div
              className={classnames({
                'actived-open-work': isActive,
              })}
            >
              <Button
                type="orange"
                className={classnames('btn-load-open-work', {
                  outline: !isActive,
                })}
                disabled={loading}
                isLoading={loading}
                onClick={onSubmit}
              >
                {t('Open Work')}
              </Button>
              {isActive && (
                <p className="mb-0 text-center" style={{ width: '105px' }}>
                  {t('Start your work below.')}
                </p>
              )}
            </div>
          </Col>
        </Row>
      </Col>

      <Col xs="12" md="4" xl="3">
        <Row className="mt-2 mt-xl-0" noGutters>
          <Col xs="6">
            <div className="pl-0 pl-xl-4 w-100">
              {!!workOrderItem?.hour_cost && (
                <div className="d-flex flex-column">
                  <p className="mb-0 font-size-14">
                    <strong>
                      {t('common:Hour')} <BsStopwatch />
                    </strong>
                  </p>
                  <div className="d-flex h-100 align-items-center">
                    <p className="mb-0 font-size-14 w-100">
                      {workOrderItem.hour_cost}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </Col>
          <Col xs="6">
            <div className="pl-0 pl-xl-4 w-100">
              {!!workOrderItem?.hour_price && (
                <div className="d-flex flex-column">
                  <p className="mb-0 font-size-14 text-right">
                    <strong>{t('common:Price')}</strong>
                  </p>
                  <div className="d-flex h-100 align-items-center justify-content-end">
                    <div className="border rounded bg-white text-right work-order-item-price">
                      {workOrderItem.hour_price}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
