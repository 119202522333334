import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Row, Col, Form } from 'reactstrap';
import { Button, SimpleInput } from '../../../../components';
import BtnLoading from '../../../../components/_molecules/BtnLoading';
import { FormProps } from '../types';

const layoutProps = {
  md: '4',
  sm: '12',
  xs: '4',
};
const FormComponent: React.FC<FormProps> = ({
  onSubmit,
  isSubmitting,
  data,
  isEdit,
}) => {
  const methods = useForm({
    defaultValues: data,
  });
  const { errors, watch } = methods;

  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(onSubmit)}>
        <Row>
          <Col {...layoutProps} className="mb-4">
            <SimpleInput
              required={true}
              field="first_name"
              label="First Name"
              hint={errors?.first_name ? errors.first_name.message : ''}
            />
          </Col>

          <Col {...layoutProps} className="mb-4">
            <SimpleInput
              required={true}
              field="middle_name"
              label="Middle Name"
              hint={errors?.middle_name ? errors.middle_name.message : ''}
            />
          </Col>
          <Col {...layoutProps} className="mb-4">
            <SimpleInput
              required={true}
              field="last_name"
              label="Last Name"
              hint={errors?.last_name ? errors.last_name.message : ''}
            />
          </Col>
          <Col {...layoutProps} className="mb-4">
            <SimpleInput
              required={true}
              type="email"
              field="email"
              label="E-mail"
              hint={errors?.email ? errors.email.message : ''}
            />
          </Col>
          <Col {...layoutProps} className="mb-4">
            <SimpleInput
              required={!isEdit}
              type="password"
              field="password"
              label="Password"
              hint={errors?.password ? errors.password.message : ''}
              registerProps={{
                maxLength: {
                  message: 'Password must be a maximum of 12 characters',
                  value: 12,
                },
                minLength: {
                  message: 'Password must have at least 8 characters',
                  value: 8,
                },
              }}
            />
          </Col>
          <Col {...layoutProps} className="mb-4">
            <SimpleInput
              type="password"
              field="password_confirmation"
              label="Password Confirmation"
              hint={
                errors?.password_confirmation
                  ? errors.password_confirmation.message ||
                    'Passwords do not match'
                  : ''
              }
              registerProps={{
                validate: (value: string) => {
                  return value === watch('password');
                },
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col md="12" className="mb-4">
            <SimpleInput
              required={true}
              field="secret_phrase"
              label="Secret Phrase"
              hint={errors?.secret_phrase ? errors.secret_phrase.message : ''}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col>
            <Button htmlType="submit">
              {isSubmitting ? <BtnLoading /> : 'Save'}
            </Button>
          </Col>
        </Row>
      </Form>
    </FormProvider>
  );
};

export default FormComponent;
