import { Shipping } from '@wgt/types';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ShutterStock from '../../../../../../assets/shutterstock.svg';
import { Text } from '../../../../../../components';

export default function ShippingComponent(): JSX.Element {
  const { t } = useTranslation(['pointOfSales', 'common']);
  const { watch } = useFormContext();
  const {
    shipping,
  }: {
    shipping: Shipping;
  } = watch(['executive_buyer', 'office', 'shipping']);

  return (
    <div className="border rounded p-2 bg-white mr-0" id="shipping">
      <div className="d-flex header">
        <img src={ShutterStock} alt="Shipping" className="iconShipping" />
        <Text className="box-title ml-2">
          {shipping?.type ?? t('Shipping & Handling')}
        </Text>
      </div>
      <div className="pl-2 mt-4">
        <Text>
          {t('Tracking')}: {shipping?.tracking ?? '---'}
        </Text>
        <Text>
          {t('Arrival')}:{' '}
          <strong>{shipping?.arrival ?? t('common:emptyDate')}</strong>
        </Text>
      </div>
    </div>
  );
}
