/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';
import {
  PointOfSaleBase,
  TemplateWithFieldsLayout,
  PointOfSaleProduct,
  Shipping,
} from '@wgt/types';
import {
  FetchResult,
  MutationFunctionOptions,
  useLazyQuery,
  useMutation,
} from '@apollo/client';
import {
  GET_POINT_OF_SALE_GQL,
  GET_POINT_OF_SALE_PRODUCTS_GQL,
  UPDATE_POINT_OF_SALE_GQL,
} from '../graphql';

export const PAYMENT_OPTIONS = [
  {
    operator: '-',
    value: 'discount',
    field: 'has_discount',
    label: 'Discount',
  },
  {
    operator: '+',
    value: 'shipping',
    field: 'has_shipping',
    label: 'Shipping & Handling',
  },
  {
    operator: '+',
    value: 'interest',
    field: 'has_interest',
    label: 'Interest',
  },
  {
    operator: '+',
    value: 'custom_duty',
    field: 'has_custom_duty',
    label: 'Custom Duty',
  },
  {
    operator: '+',
    value: 'tax',
    field: 'has_tax',
    label: 'Tax',
  },
];

const Context = createContext({});
export interface ProductHighlight extends PointOfSaleProduct {
  highlight?: boolean;
}
export interface ProductFilter {
  sku: string;
  lot_id: string;
  tray_id: string;
  boxes: string[];
  crates: string[];
}

type ProductFilterFn = React.Dispatch<
  React.SetStateAction<ProductFilter | undefined>
>;
function useProductsHighlight(
  products: PointOfSaleProduct[],
): [
  { products: ProductHighlight[]; filters?: ProductFilter },
  ProductFilterFn,
] {
  const [filters, setFilter] = useState<ProductFilter>();
  const filtered = useMemo<ProductHighlight[]>(
    () =>
      products.map((product) => ({
        ...product,
        highlight:
          filters &&
          ((!!filters.sku?.length && product.sku.includes(filters.sku)) ||
            (!!filters.lot_id?.length &&
              product.lot_id?.includes(filters.lot_id)) ||
            (!!filters.tray_id?.length &&
              product.tray_id?.includes(filters.tray_id))),
      })),
    [filters, products],
  );

  return [{ filters, products: filtered }, setFilter];
}

export function PointOfSaleProvider({
  pointOfSale,
  children,
  pointOfSaleFilter,
}: {
  pointOfSale: PointOfSaleBase;
  children: JSX.Element;
  pointOfSaleFilter: any;
}): JSX.Element {
  const [selectedProducts, setSelectedProducts] = useState<
    PointOfSaleProduct[]
  >([]);

  const [contentTab, setContentTab] = useState<string>('results');
  const [filterFavouritePolicies, setFilterFavouritePolicies] = useState<
    string
  >('');
  const [contentPolicyTab, setContentPolicyTab] = useState<string>(
    'create-policy',
  );

  const [refetchProducts, { data, loading: fetchingProducts }] = useLazyQuery<{
    productsPointOfSale: { products: PointOfSaleProduct[] };
  }>(GET_POINT_OF_SALE_PRODUCTS_GQL, {
    fetchPolicy: 'network-only',
    variables: pointOfSaleFilter,
  });

  const [{ products, filters }, filter] = useProductsHighlight(
    data?.productsPointOfSale?.products ?? pointOfSale?.products ?? [],
  );

  const [update, { loading: isUpdating }] = useMutation(
    UPDATE_POINT_OF_SALE_GQL,
    {
      refetchQueries: [
        {
          query: GET_POINT_OF_SALE_GQL,
          variables: pointOfSaleFilter,
        },
      ],
      variables: {
        id: pointOfSale.id,
      },
    },
  );

  const [fakeData, setFakeData] = useState({});

  return (
    <Context.Provider
      value={{
        update,
        isUpdating,
        products,
        filter,
        filters,
        // pointOfSale,
        pointOfSaleFilter,
        contentTab,
        setContentTab,
        selectedProducts,
        setSelectedProducts,
        refetchProducts,
        fetchingProducts,
        filterFavouritePolicies,
        setFilterFavouritePolicies,
        contentPolicyTab,
        setContentPolicyTab,
        shippingOptions: [],
        template: pointOfSale?.products?.[0]?.category?.template ?? {},
        pointOfSale: { ...pointOfSale, ...fakeData },
        setFakeData,
      }}
    >
      {children}
    </Context.Provider>
  );
}

type ContentTab = 'results' | 'shipping' | 'policy';
interface PointOfSaleUpdate {
  filter: ProductFilterFn;
  filters?: ProductFilter;
  pointOfSale: PointOfSaleBase;
  products: ProductHighlight[];
  template: TemplateWithFieldsLayout;
  pointOfSaleFilter: any;
  contentTab: string;
  setContentTab: Dispatch<SetStateAction<ContentTab>>;
  selectedProducts: PointOfSaleProduct[];
  setSelectedProducts: Dispatch<SetStateAction<PointOfSaleProduct[]>>;
  refetchProducts: () => void;
  fetchingProducts: boolean;
  filterFavouritePolicies: string;
  setFilterFavouritePolicies: Dispatch<SetStateAction<string>>;
  contentPolicyTab: string;
  setContentPolicyTab: Dispatch<SetStateAction<string>>;
  shippingOptions: Shipping[];
  setFakeData: any;
  update: (
    options?:
      | MutationFunctionOptions<
          any,
          {
            id: string;
            point_of_sale: any;
          }
        >
      | undefined,
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  isUpdating: boolean;
}

export default function usePointOfSaleUpdate(): PointOfSaleUpdate {
  const context = useContext(Context) as PointOfSaleUpdate;
  return context;
}
