/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import SimpleInput from '../../SimpleInput';
import LayoutComponentDropdownPopover from '../LayoutComponentDropdownPopover';

import LayoutComponentDropdownList from '../LayoutComponentDropdownList';
import LayoutComponentMinMax from '../LayoutComponentMinMax';
import InputRange from '../InputRange';
import classnames from 'classnames';

import {
  ExpandedDropdownText,
  ExpandedDropdownColor,
  ExpandedDropdownImage,
} from '../ExpandedDropdown';

export const COMPONENTS: any = {
  'simple-text': (props: any) =>
    SimpleInput({
      ...props,
      className: classnames('template-input-component', props.className),
    }),
  'min-max': LayoutComponentMinMax,
  'dropdown-expanded-color': ExpandedDropdownColor,
  'dropdown-expanded-image': ExpandedDropdownImage,
  'dropdown-list': (props: any) =>
    LayoutComponentDropdownList({
      ...props,
      text: 'name',
      options: props.values,
      disabled: props.disabled,
      className: 'template-dropdown-list-component',
    }),
  'dropdown-expanded-text': (props: any) =>
    ExpandedDropdownText({ ...props, text: 'name', disabled: props.disabled }),
  'dropdown-popover': (props: any) =>
    LayoutComponentDropdownPopover({
      ...props,
      text: 'name',
      disabled: props.disabled,
    }),
  range: InputRange,
  fragment: () => React.Fragment,
};

const ComponentElement: React.FC<any> = (props) => {
  const {
    component = 'component',
    resultsForm,
    rangeToSingle,
    templateField,
  } = props;

  const componentToRender = templateField?.[component]?.key;

  if (
    resultsForm &&
    componentToRender &&
    [
      'dropdown-expanded-color',
      'dropdown-expanded-image',
      'dropdown-expanded-text',
    ].includes(componentToRender)
  ) {
    return COMPONENTS['dropdown-list'];
  }

  const fieldComponentFn =
    COMPONENTS[
      (componentToRender === 'min-max' && !!rangeToSingle
        ? 'simple-text'
        : componentToRender) ?? 'fragment'
    ] ?? COMPONENTS.fragment;

  return fieldComponentFn(props);
};

export default ComponentElement;
