import { gql } from '@apollo/client';
import { paginatorInfoFragment } from '../../../services/graphql/paginator.gql';

export const LIST_FIRMS_GQL = gql`
  query firmsQuery($page: Int) {
    firms(page: $page) {
      data {
        id
        name
      }
      paginatorInfo {
        ...paginatorInfo_Part
      }
    }
  }
  ${paginatorInfoFragment}
`;

export const CREATE_FIRM_GQL = gql`
  mutation createFirmMutation(
    $name: String!
    $description: String
    $website: String
    $discount: Float
    $supplier: String
    $status: StatusActive!
    $vatcode: String
    $address: FirmAddressInput
    $extra: FirmExtraInput
    $types: [ID!]!
  ) {
    createFirm(
      name: $name
      description: $description
      website: $website
      discount: $discount
      types: $types
      supplier: $supplier
      status: $status
      vatcode: $vatcode
      address: $address
      extra: $extra
    ) {
      id
    }
  }
`;

export const UPDATE_FIRM_GQL = gql`
  mutation updateFirmMutation($id: ID!, $firm: UpdateFirmInput!) {
    updateFirm(id: $id, firm: $firm) {
      id
    }
  }
`;

export const GET_FIRM_GQL = gql`
  query getFirm($id: ID!) {
    firm(id: $id) {
      id
      name
      description
      website
      discount
      types {
        id
        name
      }
      supplier
      status
      vatcode
      address {
        address
        number
        fax
        phone
        postal_code
        alt_phone
        city
        state
        country {
          id
          name
        }
      }
      extra {
        locale {
          id
          name
        }
        timezone {
          id
          name
        }
        currency {
          id
          name
        }
        contact_name
        email
        logo
        headline
        discount_fee
        as_discount_fee
        default_association
      }
    }
  }
`;

export const GET_PUT_SCREEN_DATA_GQL = gql`
  query screenData {
    firmTypes(first: 1000) {
      data {
        id
        name
      }
    }
  }
`;

export const LOCALES = gql`
  query locales($filter: LocaleFilterInput) {
    locales(filter: $filter) {
      data {
        id
        name
      }
    }
  }
`;
export const TIMEZONES = gql`
  query timezones($filter: TimezoneFilterInput) {
    timezones(filter: $filter) {
      data {
        id
        name
      }
    }
  }
`;
export const CURRENCIES = gql`
  query currencies($filter: CurrencyFilterInput) {
    currencies(filter: $filter) {
      data {
        id
        name
        code
        country {
          name
        }
      }
    }
  }
`;
export const COUNTRIES = gql`
  query countries($filter: CountryFilterInput) {
    countries(filter: $filter) {
      data {
        id
        name
      }
    }
  }
`;
