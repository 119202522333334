import React from 'react';
import { useDrop } from 'react-dnd';
const style: React.CSSProperties = {
  height: '4rem',
  border: '1px dashed #c0c0c0',
  width: '100%',
  color: '#000',
  padding: '1rem',
  textAlign: 'center',
  fontSize: '1rem',
  lineHeight: 'normal',
  float: 'left',
};
export interface TemplateLayoutDustBinProps {
  accept: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDrop: (item: any) => void;
}
const TemplateLayoutDustBin: React.FC<TemplateLayoutDustBinProps> = ({
  accept,
  onDrop,
}) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept,
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isActive = isOver && canDrop;
  let backgroundColor = '#fff';
  if (isActive) {
    backgroundColor = 'darkgreen';
  } else if (canDrop) {
    backgroundColor = 'darkkhaki';
  }

  return (
    <div ref={drop} style={{ ...style, backgroundColor }}>
      {isActive ? 'Release to drop' : `Drop Template Field`}
    </div>
  );
};

export default TemplateLayoutDustBin;
