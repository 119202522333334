import React from 'react';
import './styles.scss';
import CanadaFlag from '../../../assets/canada_flag.svg';

import { Menu, Popover, Button } from 'evergreen-ui';

const NavbarCurrency: React.FC = () => {
  return (
    <Popover
      content={
        <Menu>
          <Menu.Group>
            {/* <Menu.Item onSelect={() => {}}>Share...</Menu.Item> */}
            <Menu.Item>USD</Menu.Item>
            <Menu.Item>REAL</Menu.Item>
          </Menu.Group>
          <Menu.Divider />
        </Menu>
      }
    >
      <Button marginRight={16} className="navbar-currency">
        Currency <img src={CanadaFlag} alt="currency" />
      </Button>
    </Popover>
  );
};

export default NavbarCurrency;
