import React from 'react';
import Text from '../Text';
import GoogleImage from '../../assets/googleMaps.png';

export default function GoogleMaps({
  addressLine1,
  addressLine2,
}: {
  addressLine1: string;
  addressLine2: string;
}): JSX.Element {
  return (
    <div className="bg-gray p-2">
      <div className="d-flex flex-column">
        <Text>{addressLine1}</Text>
        <Text>{addressLine2}</Text>
      </div>
      <img src={GoogleImage} className="w-100" />
    </div>
  );
}
