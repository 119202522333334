/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext } from 'react';
import { DragSourceMonitor, useDrag } from 'react-dnd';

import { ItemTypes } from '../ItemTypes';
import { TemplateFieldsContext } from '../../../../../../../hooks/useTemplateFieldsContext';
import { BoxProps, DragItem, DropResult } from '../../../../types';

const Box: React.FC<BoxProps> = ({ component }) => {
  const { onDropComponent } = useContext(TemplateFieldsContext);
  const componentItem = { ...component, type: ItemTypes.FIELD };

  const [{ opacity }, drag] = useDrag({
    collect: (monitor: any) => ({
      opacity: monitor.isDragging() ? 'transparent' : '',
    }),
    end(item: DragItem | undefined, monitor: DragSourceMonitor) {
      const dropResult: DropResult = monitor.getDropResult();
      if (item && dropResult) {
        onDropComponent(item);
      }
    },
    item: componentItem,
  });

  return (
    <div className="template-put-sidebar-box">
      <div ref={drag} className={opacity}>
        {component.name}
      </div>
    </div>
  );
};

export default Box;
