import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useParams, useHistory } from 'react-router-dom';
import Put from '../Put';
import useCrmRoutes from '../../../../../../hooks/useCrmRoutes';

const SpeciatyPutModal: React.FC = () => {
  const history = useHistory();
  const params = useParams<ParamTypes>();
  const { actualRoute } = useCrmRoutes();
  const [opened, setOpen] = useState<boolean>(true);

  const toggle = () => setOpen((isOpen) => !isOpen);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let time: any;
    if (!opened) {
      time = setTimeout(
        () => history.push(`/crm/${actualRoute}/${params.id}/edit`),
        1000,
      );
    }
    return () => {
      clearTimeout(time);
    };
  }, [opened]);

  return (
    <Modal isOpen={opened} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <h3 className="m-0">Edit Variation Value</h3>
      </ModalHeader>
      <ModalBody>
        <Put toggle={toggle} isModal={true} />
      </ModalBody>
    </Modal>
  );
};

export default SpeciatyPutModal;
