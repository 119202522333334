import React from 'react';
import { ExpandedDropdownOptions } from '@wgt/types';
import { useFormContext } from 'react-hook-form';
import classnames from 'classnames';
import { getProp } from '../../../utils/methods';
import './style.scss';

interface Props {
  required?: boolean;
  label: string;
  field: string;
  values: ExpandedDropdownOptions[];
  handleSelectValue: (value: ExpandedDropdownOptions) => void;
  disabled?: boolean;
  className?: string;
}

const LayoutComponentDropdownList: React.FC<Props> = ({
  label,
  values,
  field,
  required,
  disabled,
  className,
}) => {
  const { register, getValues, errors } = useFormContext();
  return (
    <div className={classnames(className, 'expanded-simple-dropdown')}>
      <span>{label}</span>
      <div className="d-flex">
        <select
          id={field}
          className={classnames('form-control', {
            'is-invalid': getProp(errors, field),
          })}
          name={field}
          ref={register({ required })}
          defaultValue={getValues(field) ?? ''}
          disabled={disabled}
        >
          <option value="">Select</option>
          {values?.map((optionValue, key) => (
            <option
              value={optionValue.key}
              key={`${field}-option-dropdown-list-${key}`}
            >
              {optionValue.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default LayoutComponentDropdownList;
