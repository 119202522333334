/* eslint-disable @typescript-eslint/no-explicit-any */
export interface PropertyValueVariationValue {
  id?: number;
  name: string;
  key: string;
  image: string;
  image_url: string;
  options: string;
}

export interface PropertyValueVariation {
  id?: number;
  name: string;
  key: string;
  variationValues: [PropertyValueVariationValue];
}

export enum ComponentTypes {
  'simple-text' = 'simple-text',
  'dropdown-list' = 'dropdown-list',
}

export interface ComponentItemProperty {
  label: string;
  value: string;
  values?: any[];
}

export interface ComponentItemFieldType {
  label: string;
  property: string;
  values?: any[];
}

export interface ComponentItemType {
  name: string;
  key: 'simple-text' | 'dropdown-list' | 'min-max' | 'dropdown-expanded';
  id?: number;
  field?: ComponentItemFieldType;
}

export interface FixedLayoutComponent {
  type:
    | 'simple-text'
    | 'dropdown-list'
    | 'dropdown-expanded-text'
    | 'dropdown-expanded-image'
    | 'dropdown-expanded-color'
    | 'min-max';
  property: ComponentItemProperty;
}

export interface ExpandedDropdownOptions {
  name: string;
  key: string;
  options?: {
    color?: string;
  };
  image?: string;
  selected?: boolean;
  variations?: PropertyValueVariation[];
  // category: string;
}

export interface BetweenNumberInput {
  from: number;
  to: number;
}
