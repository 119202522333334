import React from 'react';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../../components';

const FAKE_ADDRESS = {
  address: {
    number: '8605',
    address: 'Santa Monica Blvd',
    city: 'Los Angeles',
    state: 'California',
    country: {
      name: 'United States',
    },
    postal_code: '90069-4109',
    phone: '855285 9775',
    fax: '213 986 3526',
  },
  extra: {
    email: 'info@company.com',
  },
};

export default function ProfileViewSummary(): JSX.Element {
  const { t } = useTranslation('hr');
  return (
    <div className="border shadow p-4">
      <Row className="my-5">
        <Col lg="6">
          <p className="subtitle">{t('Firm Address')}</p>
        </Col>
        <Col lg="6" className="text-right">
          <p>
            <strong>
              {t('Show Next Address')} {'>>'}
            </strong>
          </p>
        </Col>
        <Col lg="12" className="mb-3">
          <p>
            {FAKE_ADDRESS.address.number} {FAKE_ADDRESS.address.address}
          </p>
          <p>
            {FAKE_ADDRESS.address.city}, {FAKE_ADDRESS.address.state}
          </p>
          <p>
            {FAKE_ADDRESS.address.country.name},{' '}
            {FAKE_ADDRESS.address.postal_code}
          </p>
        </Col>
        <Col lg="4" className="mb-3">
          <p>{FAKE_ADDRESS.address.phone}</p>
          <p>
            <strong>{t('Phone')}</strong>
          </p>
        </Col>
        <Col lg="4" className="mb-3">
          <p>{FAKE_ADDRESS.address.fax}</p>
          <p>
            <strong>{t('Fax')}</strong>
          </p>
        </Col>
        <Col lg="4" className="mb-3">
          <p>{FAKE_ADDRESS.extra.email}</p>
          <p>
            <strong>{t('E-mail')}</strong>
          </p>
        </Col>
        <Col lg="6" className="mt-4">
          <p className="subtitle">{t('Personal Contact')}</p>
        </Col>
        <Col lg="6" className="text-right mt-4">
          <p>
            <strong>
              {t('Show Next Address')} {'>>'}
            </strong>
          </p>
        </Col>
        <Col lg="12" className="mb-3">
          <p>
            {FAKE_ADDRESS.address.number} {FAKE_ADDRESS.address.address}
          </p>
          <p>
            {FAKE_ADDRESS.address.city}, {FAKE_ADDRESS.address.state}
          </p>
          <p>
            {FAKE_ADDRESS.address.country.name},{' '}
            {FAKE_ADDRESS.address.postal_code}
          </p>
        </Col>
        <Col lg="4" className="mb-3">
          <p>{FAKE_ADDRESS.address.phone}</p>
          <p>
            <strong>{t('Phone')}</strong>
          </p>
        </Col>
        <Col lg="4" className="mb-3">
          <p>{FAKE_ADDRESS.address.fax}</p>
          <p>
            <strong>{t('Fax')}</strong>
          </p>
        </Col>
        <Col lg="4" className="mb-3">
          <p>{FAKE_ADDRESS.extra.email}</p>
          <p>
            <strong>{t('E-mail')}</strong>
          </p>
        </Col>
        <Col lg="4" className="mb-3">
          <p>{FAKE_ADDRESS.address.phone}</p>
          <p>
            <strong>{t('Phone')}</strong>
          </p>
        </Col>
        <Col lg="4" className="mb-3">
          <p>{FAKE_ADDRESS.address.fax}</p>
          <p>
            <strong>{t('Fax')}</strong>
          </p>
        </Col>
        <Col lg="4" className="mb-3">
          <p>{FAKE_ADDRESS.extra.email}</p>
          <p>
            <strong>{t('E-mail')}</strong>
          </p>
        </Col>
        <Col lg="6" className="mt-4">
          <p className="subtitle">{t('Emergency Contact')}</p>
        </Col>
        <Col lg="6" className="text-right mt-4">
          <p>
            <strong>
              {t('Show Next Address')} {'>>'}
            </strong>
          </p>
        </Col>
        <Col lg="12" className="mb-3">
          <p>
            {FAKE_ADDRESS.address.number} {FAKE_ADDRESS.address.address}
          </p>
          <p>
            {FAKE_ADDRESS.address.city}, {FAKE_ADDRESS.address.state}
          </p>
          <p>
            {FAKE_ADDRESS.address.country.name},{' '}
            {FAKE_ADDRESS.address.postal_code}
          </p>
        </Col>
        <Col lg="4" className="mb-3">
          <p>{FAKE_ADDRESS.address.phone}</p>
          <p>
            <strong>{t('Phone')}</strong>
          </p>
        </Col>
        <Col lg="4" className="mb-3">
          <p>{FAKE_ADDRESS.address.fax}</p>
          <p>
            <strong>{t('Fax')}</strong>
          </p>
        </Col>
        <Col lg="4" className="mb-3">
          <p>{FAKE_ADDRESS.extra.email}</p>
          <p>
            <strong>{t('E-mail')}</strong>
          </p>
        </Col>
      </Row>
      <Button type="secondary">{t('Profile')}</Button>
    </div>
  );
}
