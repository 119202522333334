import { URGENCIES } from '@wgt/shared/constants';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from '../..';
import UrgencySelector from '../../UrgencySelector';
import useWorkOrderItemUpdate from './useWorkOrderItemUpdate';

export default function WorkOrderItemForm(): JSX.Element {
  const [{ item }, { setItem }] = useWorkOrderItemUpdate();
  const methods = useForm({
    defaultValues: item,
  });
  const { t } = useTranslation('workOrder');

  const { reset } = methods;

  useEffect(() => {
    reset({ ...item, urgency: URGENCIES[0] });
  }, [item]);

  if (!item) {
    return <div className="offset-4" />;
  }

  return (
    <>
      <div className="col-1">
        <Button type="primary" small onClick={() => setItem(undefined)}>
          {t('Finish')}
        </Button>
      </div>
      <div className="col-3">
        <FormProvider {...methods}>
          <UrgencySelector field="urgency" />
        </FormProvider>
      </div>
    </>
  );
}
