import { Shape } from '@wgt/types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface PosProductShapesProps {
  shapes: Shape[];
}

const PosProductShapes: React.FC<PosProductShapesProps> = ({ shapes }) => {
  const { t } = useTranslation('product');
  const pageIdShapes: string = useMemo(
    () =>
      shapes?.length
        ? shapes.map((shape) => shape.title).join(',')
        : t('No shape selected'),
    [shapes],
  );

  return (
    <>
      <dt className="col-sm-3 text-right">{t('Shape')}</dt>
      <dd className="col-sm-9">{pageIdShapes}</dd>
    </>
  );
};

export default PosProductShapes;
