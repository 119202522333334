import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { SimpleInput, SimpleTextArea } from '../../../../components';

export default function DomainForm(): JSX.Element {
  const { t } = useTranslation('website');
  return (
    <>
      <Row>
        <Col md="4" className="d-flex align-items-center justify-content-end">
          <label>{t('enter your www.domain')}</label>
        </Col>
        <Col>
          <SimpleInput
            field="domain"
            placeholder={t('www.yourdomain.com')}
            required
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md="4" className="d-flex align-items-center justify-content-end">
          <label>{t('Domain provider')}</label>
        </Col>
        <Col>
          <SimpleInput
            field="domain_provider"
            placeholder={t(
              'www.wheredoyoustoreyourdomain.com                 godaddy.com?',
            )}
            required
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md="4" className="d-flex align-items-start justify-content-end">
          <label>{t('Comments')}</label>
        </Col>
        <Col>
          <SimpleTextArea
            field="comments"
            rows={10}
            placeholder={t('Please discuss your topics...')}
          />
        </Col>
      </Row>
    </>
  );
}
