import React, { useCallback } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useDropzone } from 'react-dropzone';
import { Mineral } from '@wgt/types';
import { FileTypeStr } from '../../../../../../Products/types';
import { Button } from '../../../../../../../../components';
import { useMutation } from '@apollo/client';
import {
  CREATE_FILE,
  ATTACH_FILE_TO_MINERAL,
  MINERAL_DETAILS,
} from '../graphql';
import useToast from '../../../../../../../../hooks/useToast';
import Loader from '../../../../../../../../components/_organisms/Loader';
import { useTranslation } from 'react-i18next';

const CreateFileModal: React.FC<{
  isOpen: boolean;
  accept: FileTypeStr;
  mineral: Mineral;
  filesDescription: string;
  toggle(): void;
}> = ({ isOpen, accept, mineral, filesDescription, toggle }) => {
  const { toast } = useToast();
  const { t } = useTranslation();

  const [attach, { loading: attaching }] = useMutation(ATTACH_FILE_TO_MINERAL, {
    onCompleted: () => [toast('success'), toggle()],
    onError: () => toast('error'),
    refetchQueries: [{ query: MINERAL_DETAILS, variables: { id: mineral.id } }],
  });

  const [create, { loading }] = useMutation(CREATE_FILE, {
    onCompleted: ({ createFile: { id } }) =>
      attach({
        variables: {
          id: mineral.id,
          firm_id: 1, // will be removed after backend fix
          file_ids: [id],
        },
      }),
    onError: () => toast('error'),
  });
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file: Blob) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        const binaryStr = reader.result;
        const newFile = { image: binaryStr, fileToUpload: acceptedFiles };

        create({
          variables: {
            product_id: 1, // it will be removed after backend fix
            file: newFile.fileToUpload[0],
            title: 'mineral',
          },
        });
      };
      reader.readAsDataURL(file);
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept,
  });

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader toggle={toggle}>{filesDescription}</ModalHeader>
      <ModalBody>
        {loading || attaching ? (
          <Loader />
        ) : (
          <div className="ibox float-e-margins">
            <div className="ibox-content">
              <div {...getRootProps()} className="dropzone">
                <input {...getInputProps()} />
                <div className="dropzone-content">
                  {isDragActive ? (
                    <h2>{t('Drop files here')}</h2>
                  ) : (
                    <>
                      <h2>{t('Drag file to add')}</h2>
                      <Button small>{t('or click to search')}</Button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button type="secondary" onClick={toggle}>
          {t('Cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CreateFileModal;
