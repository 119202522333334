import React from 'react';
import ShapeGroupsForm from '../../../../../components/ShapeGroupsForm';
import { ProductType, PagesOptions, Shape } from '@wgt/types';
import { PRODUCT_TYPE_SINGLE_ID } from '@wgt/shared/constants';
import { useTranslation } from 'react-i18next';

interface SearchShapesProps {
  shapes?: Shape[];
  productType?: ProductType;
}

const SearchShapes: React.FC<SearchShapesProps> = ({
  shapes = [],
  productType,
}) => {
  const { t } = useTranslation('product');
  const numberOfPagesOptions: PagesOptions[] =
    `${productType?.id}` === `${PRODUCT_TYPE_SINGLE_ID}`
      ? [
          { label: t('Single'), value: 1 },
          { label: t('Pair'), value: 2 },
          { label: t('Triplet'), value: 3 },
        ]
      : [
          { label: t('Per Diamond'), value: 1 },
          { label: t('Per Shape'), value: 2 },
          { label: t('Multiple Shapes'), value: 3 },
        ];

  return (
    <div className="d-flex flex-column w-100">
      <ShapeGroupsForm
        shapes={shapes}
        field="shapes"
        numberOfPagesOptions={numberOfPagesOptions}
        isMulti={true}
      />
    </div>
  );
};

export default SearchShapes;
