/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Button } from 'evergreen-ui';
import './styles.scss';
interface Props {
  options: any;
  active: number | null | undefined;
  setActive: any;
  controller: { key: string; text: string };
}

const ButtonSelectorComponent: React.FC<Props> = ({
  options,
  active,
  setActive,

  controller = { key: 'id', text: 'name' },
}) => {
  return (
    <div className="button-selector">
      {options?.map((item: any, key: number) => (
        <Button
          key={key}
          className={`${item[controller.key] === active && 'active'}`}
          onClick={() => {
            setActive(item[controller.key]);
          }}
        >
          {item[controller.text]}
        </Button>
      ))}
    </div>
  );
};

export default ButtonSelectorComponent;
