import React, { useState } from 'react';
import { Pane, SideSheet, SideSheetProps } from 'evergreen-ui';
import { FiChevronsRight } from 'react-icons/fi';
import { IconType } from 'react-icons';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { Collapse } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { HorizontalAlign, NavbarItemSubtitle } from '@wgt/types';
import BagProvider, { useBagContext } from '../../services/bag';
export { default as SidebarSimpleExpandContent } from './SidebarExpandComponent';
import './styles.scss';

export interface SidebarPaneProps extends SideSheetProps {
  isShown: boolean;
  setShown(isShown: boolean): void;
  size?: number | string;
  header?: string;
  closeText?: string;
  className?: string;
}

interface SidebarPaneItemProps {
  title: string;
  icon?: IconType;
  subTitle?: string;
  url?: string;
  onClick?: (props?: unknown) => void;
  children?: SidebarPaneItemProps[];
  backgroundColor?: string;
  align?: HorizontalAlign;
  subtitle?: NavbarItemSubtitle;
  goToUrl?: boolean;
}

function Content({
  icon: Icon,
  title,
  subtitle,
}: SidebarPaneItemProps): JSX.Element {
  const { t } = useTranslation('menus');
  return (
    <Pane display="flex" alignItems="center">
      {Icon && <Icon size={14} />}
      <Pane display="flex" flexDirection="column">
        {t(title)}
        {subtitle && (
          <small>
            {subtitle?.value} {subtitle?.label && t(subtitle?.label)}
          </small>
        )}
      </Pane>
    </Pane>
  );
}

const Item: React.FC<SidebarPaneItemProps> = ({ url, goToUrl, ...props }) => {
  if (url && goToUrl) {
    return (
      <Link to={url} className="flex-1">
        <Content {...props} />
      </Link>
    );
  }
  return <Content {...props} />;
};

export const SidebarPaneItem: React.FC<SidebarPaneItemProps> = ({
  onClick,
  align = HorizontalAlign.center,
  backgroundColor,
  children,
  ...props
}) => {
  const { setShown } = useBagContext<SidebarPaneProps>();
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <>
      <div className={classnames('wrap-pane-item', align)}>
        <div
          className={classnames('sidebar-pane-item', align)}
          style={{ background: `${backgroundColor}` }}
          onClick={() => {
            if (onClick) {
              onClick?.();
              setShown(false);
            } else {
              if (props.url) {
                setShown(false);
              } else if (children) {
                toggle();
              }
            }
          }}
        >
          <Item {...props} goToUrl={!onClick} />
        </div>
      </div>
      {!!children && (
        <Collapse isOpen={isOpen}>
          {children?.map((submenu, key) => (
            <SidebarPaneItem key={`submenu-${key}`} {...submenu} />
          ))}
        </Collapse>
      )}
    </>
  );
};

const SidebarPane: React.FC<SidebarPaneProps> = ({
  children,
  isShown,
  setShown,
  size = 250,
  header,
  closeText,
  className,
  ...props
}) => {
  const { t } = useTranslation('menus');
  return (
    <BagProvider
      value={{
        isShown,
        setShown,
        ...props,
      }}
    >
      <SideSheet
        {...props}
        isShown={isShown}
        onCloseComplete={() => setShown(false)}
        width={size}
        containerProps={{
          className: classnames('side-sheet', className),
        }}
        preventBodyScrolling
      >
        <Pane
          className="close-sidebar"
          display="flex"
          justifyContent="space-between"
        >
          <div>{header}</div>
          <div onClick={() => setShown(false)} className="icon-close">
            {closeText ?? t('Close menu')} <FiChevronsRight size={18} />
          </div>
        </Pane>
        {children}
      </SideSheet>
    </BagProvider>
  );
};

export default SidebarPane;
