import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { Button } from 'evergreen-ui';
import { useTranslation } from 'react-i18next';
import Loader from '../../_organisms/Loader';
import SidebarPane from '../../SidebarPane';
import { LIST_CATEGORY_GROUPS_GQL } from './graphql';
import { CategoryGroup } from '@wgt/types';

import { NavbarProvider } from '../../../hooks/useNavbar';
import './styles.scss';
import NavbarCategoriesCollapse from './NavbarCategoriesCollapse';
import { FaChevronDown } from 'react-icons/fa';

interface NavbarCategoriesProps {
  title?: string;
  header?: string;
  routeArgs?: Record<string, unknown>;
}

const NavbarCategories: React.FC<NavbarCategoriesProps> = ({
  title,
  header,
  routeArgs,
}) => {
  const { t } = useTranslation(['common', 'categories']);
  const [isSidebarShown, setSidebarShown] = useState(false);

  const { data, loading } = useQuery<{
    categoryGroups: { data: CategoryGroup[] };
  }>(LIST_CATEGORY_GROUPS_GQL, {
    variables: { first: 100 },
    fetchPolicy: 'cache-and-network',
  });

  if (loading) {
    return <Loader small round />;
  }

  return (
    <NavbarProvider routeArgs={routeArgs}>
      <Button onClick={() => setSidebarShown(true)}>
        <span>{title ?? t('common:Products')}</span>
        <FaChevronDown className="ml-3" />
      </Button>
      <SidebarPane
        isShown={isSidebarShown}
        setShown={setSidebarShown}
        size={600}
        header={header}
      >
        <NavbarCategoriesCollapse
          categoryGroups={data?.categoryGroups?.data ?? []}
        />
      </SidebarPane>
    </NavbarProvider>
  );
};

export default NavbarCategories;
