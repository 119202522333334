import React, { useState } from 'react';
import { Navbar, Collapse, Container, NavbarToggler } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';

import './styles.scss';
import Logo from '../Logo';
import NavbarDivider from '../../_molecules/NavbarDivider';
import NavbarFirmMenu from '../../_molecules/NavbarFirmMenu';
import NavbarCurrency from '../../_molecules/NavbarCurrency';
import Breadcrumbs from '../Breadcrumbs';
import { SideSheet } from 'evergreen-ui';
import SidebarMenu from '../SidebarMenu';
import UserDropdownMenu from '../../_molecules/UserDropdownMenu';
import NavbarFirmControls from '../../_molecules/NavbarFirmControls';
import NavbarCategories from '../../_molecules/NavbarCategories';
import NavbarAdminControls from '../../_molecules/NavbarAdminControls';
const NavbarComponent: React.FC = () => {
  const [isShown, setShown] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!isOpen);

  return (
    <>
      <SideSheet
        isShown={isShown}
        onCloseComplete={() => setShown(false)}
        preventBodyScrolling
      >
        <SidebarMenu setShown={setShown} />
      </SideSheet>
      <Navbar expand="lg" className="wgt-navbar">
        <Container fluid={true}>
          <div className="navbar-brand">
            <NavbarToggler onClick={toggle} className="navbar-toggler">
              <FaBars />
            </NavbarToggler>
            <Link to="/crm/profile">
              <Logo />
            </Link>
            <NavbarCurrency />
          </div>

          <Collapse
            isOpen={isOpen}
            navbar
            className="justify-content-around justify-content-lg-end"
          >
            <NavbarDivider />
            <NavbarCategories />
            <NavbarDivider />
            <NavbarFirmMenu />
            <NavbarDivider />
            <NavbarFirmControls />
            <NavbarAdminControls />
            <NavbarDivider />
            <UserDropdownMenu />
          </Collapse>
        </Container>
      </Navbar>
      <Breadcrumbs />
    </>
  );
};

export default NavbarComponent;
