import React from 'react';
import useCrmRoutes from '../../../hooks/useCrmRoutes';
import Details from './Details';
import List from './List';
import InternalRoutes from '../../../components/_organisms/InternalRoutes';

const PointSales: React.FC = () => {
  const { isCrmInternalRoute, actualRoute } = useCrmRoutes();

  const routes = [
    {
      component: Details,
      name: 'Details',
      path: `/crm/${actualRoute}/:id`,
    },
  ];

  if (isCrmInternalRoute) {
    return <InternalRoutes routes={routes} />;
  }

  return <List />;
};

export default PointSales;
