import { gql } from '@apollo/client';

export const dependencyFragment = gql`
  fragment dependency_Part on Dependency {
    fieldDependencies {
      id
      label
    }
    valueDependencies {
      field {
        id
        label
      }
      value {
        id
        name
      }
    }
  }
`;
