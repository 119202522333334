import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaChevronRight } from 'react-icons/fa';
import { Helmet } from 'react-helmet';

import './styles.scss';
import useCrmRoutes from '../../../hooks/useCrmRoutes';
import BreadcrumbSearchInput from '../../_molecules/BreadcrumbSearchInput';
import { CustomRoute } from '../../../hooks/routesContext';
import { DEFAULT_PAGE_TITLE } from '../../../utils/constants';

const Breadcrumbs: React.FC = () => {
  const { routes, customRoute, productRoutes, customRoutes } = useCrmRoutes();
  const location = useLocation();

  const breadcrumbs: CustomRoute[] = useMemo(() => {
    if (productRoutes?.length) {
      return productRoutes;
    }
    if (customRoutes?.length) {
      return [
        {
          name: 'Dashboard',
          path: '/crm/profile',
        },
        ...customRoutes,
      ];
    }

    const breadcrumbsRoutes = location?.pathname?.split('/') || [];

    let breadcrumbRoute = '';
    return breadcrumbsRoutes
      .filter((hasString) => hasString)
      .map((item) => {
        breadcrumbRoute += `/${item}`;

        return {
          name:
            routes?.find((route) => route.path === breadcrumbRoute)?.name ||
            item,
          path: breadcrumbRoute,
        };
      });
  }, [routes, productRoutes, customRoutes]);

  const isActive = (key: number) => key + 1 === breadcrumbs.length;

  function handleBreadcrumbName(key: number) {
    const routeTitle =
      customRoute?.title && key === breadcrumbs?.length - 1
        ? customRoute.title
        : breadcrumbs[key].name || breadcrumbs[key].path;

    return routeTitle;
  }
  const handleBreadcrumbPath = (key: number) =>
    key + 1 === breadcrumbs.length
      ? customRoute?.path || breadcrumbs[key].path
      : breadcrumbs[key].path;

  const pageTitle = useMemo(() => {
    const lastItem = breadcrumbs[breadcrumbs.length - 1];
    if (customRoute?.name || customRoute?.title) {
      return `${customRoute.title || customRoute?.name} | `;
    }
    return lastItem ? `${lastItem.title || lastItem.name} | ` : '';
  }, [breadcrumbs, routes, customRoute]);

  return (
    <>
      <Helmet>
        <title>{`${pageTitle}${DEFAULT_PAGE_TITLE}`}</title>
      </Helmet>
      <div className="breadcrumb-component">
        <div className="container-fluid d-flex align-items-center justify-content-between">
          <div>
            {breadcrumbs?.map((item, key: number) => (
              <React.Fragment key={key}>
                <Link
                  to={handleBreadcrumbPath(key) || item.path || '/'}
                  className={`${isActive(key) ? 'active' : ''}`}
                >
                  {handleBreadcrumbName(key)}
                </Link>

                {!isActive(key) && (
                  <FaChevronRight className="breadcrumb-component-icon" />
                )}
              </React.Fragment>
            ))}
          </div>
          <BreadcrumbSearchInput />
        </div>
      </div>
    </>
  );
};

export default Breadcrumbs;
