import React from 'react';
import { FormProps } from '../types';
import CommonSearchInputField from './CommonSearchInputField';

const CommonForm: React.FC<FormProps> = () => {
  return (
    <div className="common-form-holder">
      <CommonSearchInputField
        label="SKU"
        fields={[{ field: 'sku', placeholder: 'SKU' }]}
        className="template-input-component"
      />
      <CommonSearchInputField
        label="Name"
        fields={[{ field: 'name', placeholder: 'Name' }]}
        className="template-input-component"
      />
      <CommonSearchInputField
        type="number"
        label="Total Price"
        fields={[
          { field: 'total_price.from', placeholder: 'from' },
          { field: 'total_price.to', placeholder: 'to' },
        ]}
        className="template-input-component"
      />
      <CommonSearchInputField
        type="number"
        label="Price Per Carat"
        fields={[
          { field: 'page.price_per_carat.from', placeholder: 'from' },
          { field: 'page.price_per_carat.to', placeholder: 'to' },
        ]}
        className="template-input-component"
      />
      <CommonSearchInputField
        type="number"
        label="Carat Weight"
        fields={[
          { field: 'page.carat_weight.from', placeholder: 'from' },
          { field: 'page.carat_weight.to', placeholder: 'to' },
        ]}
        className="template-input-component"
      />
    </div>
  );
};

export default CommonForm;
