import React from 'react';
import { useDrag } from 'react-dnd';

import { TemplateFieldItemProps } from '../../../types';

const TemplateFieldItem: React.FC<TemplateFieldItemProps> = ({
  field,
  // isDropped,
}) => {
  const [{ opacity }, drag] = useDrag({
    item: { ...field, type: field.type },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
    }),
  });
  return (
    <div
      className="template-layout-container-sidebar-item"
      ref={drag}
      style={{ opacity }}
    >
      {field.label || field.name} <br />
      <small>{field.type}</small>
    </div>
  );
};

export default TemplateFieldItem;
