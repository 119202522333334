import React from 'react';
import { Pane } from 'evergreen-ui';

import PageId from '../../../Update/Searcher/PageID';
import SKU from '../../../Update/Searcher/SKU';
import { Title } from '../../../../../../components';

const AddPageId: React.FC = () => {
  return (
    <Pane>
      <Title heading="h4">Add Page ID</Title>
      <Pane display="flex">
        <Pane className="search-page-id-holder">
          <PageId inline />
          <SKU inline />
        </Pane>
        <Pane>
          <button
            type="button"
            className="btn btn-outline-primary mr-2 btn-sm ml-3"
          >
            Menu
          </button>
        </Pane>
      </Pane>
    </Pane>
  );
};

export default AddPageId;
