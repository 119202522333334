import React from 'react';
import { Button } from '../..';
import Line from '../../Line';
import useToggler from '@wgt/shared/hooks/useToggler';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import useWorkOrderSchedule from '../useWorkOrderSchedule';
import ContactList, { FakeFirm } from '../WorkOrderContacts/ContactList';

interface ContactSelectorProps {
  label: string;
  selecteds: FakeFirm[];
}

export default function ContactSelector({
  label,
  selecteds,
}: ContactSelectorProps): JSX.Element {
  const [isOpened, toggleSearch] = useToggler();
  const { t } = useTranslation('workOrder');
  const [, { selectTab }] = useWorkOrderSchedule();

  return (
    <div className="m-2">
      <span>{label}</span>
      <Line />
      {!selecteds.length && (
        <div className="d-flex d-flex justify-content-between mt-4 mb-5">
          <Button
            type="secondary"
            onClick={() => selectTab('contacts', { type: label })}
          >
            {t(`I am ${label}`)}
          </Button>
          <Button
            type="orange"
            className="outline"
            onClick={() => selectTab('contacts', { type: label })}
          >
            {t('Add')}
          </Button>
        </div>
      )}
      <div className="container-fluid">
        <ContactList items={selecteds} editable={false} />
        {!!selecteds.length && (
          <div className="d-flex justify-content-end my-4">
            <Button
              type="orange"
              className={classnames('float-right', { outline: !isOpened })}
              onClick={toggleSearch}
            >
              {t('More')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
