import { useState, useCallback } from 'react';

type Toggler = (val?: boolean) => void;

type UseToggler = [boolean, Toggler];

export default function useToggler(defaultValue?: boolean): UseToggler {
  const [state, setState] = useState(defaultValue ?? false);

  const toggle = useCallback(
    (value?: boolean) =>
      typeof value === 'boolean' ? setState(value) : setState((x) => !x),
    [],
  );

  return [state, toggle];
}
