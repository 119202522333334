import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { Line } from '../../../../../components';
import AddEmployeeModal from '../AddEmployeeModal';
import { Link } from 'react-router-dom';

export default function NavItems(): JSX.Element {
  const [moreOptions, setMoreOptions] = useState(false);
  const [addEmployeeModal, setAddEmployeeModal] = useState(false);
  const toggleMoreOptions = () => setMoreOptions(!moreOptions);
  const toggleAddEmployeeModal = () => setAddEmployeeModal(!addEmployeeModal);
  const { t } = useTranslation('hr');

  return (
    <div className="border shadow p-4 h-100 contacts">
      <Nav className="nav-item-contact">
        <NavItem>
          <NavLink>{t('Contacts')}</NavLink>
        </NavItem>
      </Nav>
      <Line />
      <div className="height-default">
        <Nav>
          <NavItem>
            <NavLink onClick={() => toggleMoreOptions()}>
              {t('Controls')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink onClick={() => toggleAddEmployeeModal()}>
              {t('Add Employee')}
            </NavLink>
          </NavItem>
          <NavItem>
            <Link
              className="nav-link"
              to="/crm/human-resources/request-to-join#request-to-join-results"
            >
              {t('Request to Join')}
            </Link>
          </NavItem>
          <NavItem>
            <NavLink>{t('Add Clients')}</NavLink>
          </NavItem>
          <NavItem>
            <NavLink>{t('Fast Upload')}</NavLink>
          </NavItem>
        </Nav>
      </div>
      <Line />
      <div className="height-default">
        {moreOptions && (
          <Nav>
            <NavItem>
              <NavLink>{t('Selected Stock')}</NavLink>
            </NavItem>
          </Nav>
        )}
      </div>
      <Line className="no-line-bottom" />
      <AddEmployeeModal
        opened={addEmployeeModal}
        toggleAddEmployeeModal={toggleAddEmployeeModal}
      />
    </div>
  );
}
