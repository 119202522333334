/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import pages from './pages';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ToastContainer } from 'react-toastify';
import createApolloClient from './services/apollo';
import { RoutesProvider } from './hooks/routesContext';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';

import 'moment/locale/pt-br';
import 'moment/locale/es';

import 'react-rangeslider/lib/index.css';
import { AuthenticationProvider } from '@wgt/authentication';

const apolloClient = createApolloClient();

export const RouteWithSubRoutes: React.FC = (route: any) => {
  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={(props) => <route.component {...props} routes={route.routes} />}
    />
  );
};

const App: React.FC = () => {
  const { i18n } = useTranslation();
  moment.locale(i18n.language);
  return (
    <BrowserRouter>
      <DndProvider backend={HTML5Backend}>
        <ApolloProvider client={apolloClient}>
          <AuthenticationProvider>
            <>
              <ToastContainer />
              <RoutesProvider>
                <Switch>
                  {pages.map((route, i) => (
                    <RouteWithSubRoutes {...route} key={i} />
                  ))}
                  <Route
                    render={() => {
                      window.dataLayer?.push(['send', 'pageview']);
                      return null;
                    }}
                  />
                </Switch>
              </RoutesProvider>
            </>
          </AuthenticationProvider>
        </ApolloProvider>
      </DndProvider>
    </BrowserRouter>
  );
};

export default App;
