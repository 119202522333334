import React from 'react';
import { SketchPicker } from 'react-color';
import { Controller, useFormContext } from 'react-hook-form';

interface ColorPickerProps {
  name: string;
}

export default function ColorPicker({ name }: ColorPickerProps): JSX.Element {
  const { getValues } = useFormContext();
  return (
    <Controller
      name={name}
      render={({ onChange, value }) => (
        <SketchPicker
          color={value?.toString()}
          onChange={({ hex }) => onChange(hex)}
        />
      )}
      defaultValue={getValues(name) ?? ''}
    />
  );
}
