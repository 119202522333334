/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from '@apollo/client';
import {
  BoxId,
  CrateId,
  Category,
  Report,
  PageId,
  MediaCenterItemBase,
  ProductBase,
} from '@wgt/types';
import React, { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import useLoggedContext from '../../hooks/useLoggedContext';
import { PRODUCT } from '../../pages/Crm/Products/ProductUpdateRow/graphql';
import {
  ProductQueryType,
  ProductStatus,
} from '../../pages/Crm/Products/types';
import Provider from '../../pages/Crm/Products/Update/Provider';
import { useCollapseContext } from '../../services/collapse';
import Loader from '../_organisms/Loader';
import SearchProductDetails from '../SearchProductDetails';
import { WorkOrderProvider } from '../WorkOrder/useWorkOrderContext';

interface ProductInput {
  id: number;
  boxes: BoxId[];
  crates: CrateId[];
  category: Category;
  description: string;
  name: string;
  office: { id: number; name: string };
  lot_id: string;
  sku: string;
  tray_id: string;
  reports: Report[];
  pages: PageId[];
  product_status_id?: string;
  office_id?: number;
  files?: MediaCenterItemBase[];
}

interface ComponentsProps {
  product: ProductBase;
  filters?: any;
  statuses: ProductStatus[];
}

const ComponentRow: React.FC<ComponentsProps> = ({
  product,
  filters,
  statuses,
}) => {
  const methods = useForm<ProductInput>({
    defaultValues: {
      ...product,
      product_status_id: product?.productStatus?.id,
      crates: product.crates?.length ? [...product.crates] : [{ name: '' }],
      boxes: product.boxes?.length ? [...product.boxes] : [{ name: '' }],
      office_id: product.office?.id,
      files: product.files,
    },
    shouldUnregister: false,
  });

  return (
    <WorkOrderProvider productId={product.id}>
      <FormProvider {...methods}>
        <Provider filters={filters}>
          <SearchProductDetails statuses={statuses} />
        </Provider>
      </FormProvider>
    </WorkOrderProvider>
  );
};

const ProductResultContent: React.FC = () => {
  const { active: productId } = useCollapseContext();
  const { active } = useLoggedContext();

  const UPDATE_STOCK_FILTERS = useMemo(
    () => ({
      id: productId,
      officeFilter: active ? { firm_id: [active.id] } : null,
    }),
    [active, productId],
  );

  const { data, loading } = useQuery<ProductQueryType>(PRODUCT, {
    skip: !productId?.length,
    variables: UPDATE_STOCK_FILTERS,
  });

  if (loading || !data?.product?.id) {
    return (
      <div className="d-flex justify-content-center">
        <Loader round />
      </div>
    );
  }

  return (
    <ComponentRow
      product={data?.product}
      filters={UPDATE_STOCK_FILTERS}
      statuses={data?.productsStatus?.data ?? []}
    />
  );
};

export default ProductResultContent;
