import React from 'react';
import { URGENCIES } from '@wgt/shared/constants';
import { Controller, useFormContext } from 'react-hook-form';
import Select from 'react-select';
import { UrgencyType } from '@wgt/types';
import './styles.scss';

interface UrgencySelectorProps {
  field: string;
}

function UrgencyItem({ item }: { item: UrgencyType }): JSX.Element {
  return (
    <div className="urgency-selector-item">
      <div
        style={{ backgroundColor: item.color }}
        className="urgency-selector-item-color"
      />
      {item.title}
    </div>
  );
}

const UrgencySelector: React.FC<UrgencySelectorProps> = ({ field }) => {
  const { getValues } = useFormContext();
  return (
    <Controller
      as={Select}
      name={field}
      options={URGENCIES}
      getOptionValue={(e: UrgencyType) => `${e.key}`}
      getOptionLabel={(e: UrgencyType) => <UrgencyItem item={e} />}
      defaultValue={getValues(field)}
      className="urgency-selector-container"
      classNamePrefix="urgency-selector"
    />
  );
};

export default UrgencySelector;
