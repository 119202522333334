import React from 'react';
import { PlanNextPayment } from '@wgt/types';
import { useBagContext } from '../../../services/bag';
import PlanNextPaymentInputs from './PlanNextPaymentInputs';

export default function PlanNextPaymentContent(): JSX.Element {
  const {
    planNextPayment: { fields },
  } = useBagContext<{
    planNextPayment: { fields: PlanNextPayment[] };
  }>();

  return (
    <div>
      {fields.map((x, index) => (
        <PlanNextPaymentInputs key={index} index={index} />
      ))}
    </div>
  );
}
