import { gql } from '@apollo/client';

export const GET_GQL = gql`
  query workOrders($filter: WorkOrderFilterInput) {
    workOrders(filter: $filter, first: 1000) {
      data {
        id
      }
    }
  }
`;

export const GET_SCHEDULES = gql`
  query schedules($filter: ScheduleFilterInput) {
    schedules(filter: $filter, first: 1000) {
      data {
        id
      }
    }
  }
`;

export const CREATE_WORK_ORDER_GQL = gql`
  mutation createWorkOrder($posProducts: [POSProductInput!]!) {
    createWorkOrder(pos_products: $posProducts, urgency: normal) {
      id
      urgency
    }
  }
`;
