import { useQuery } from '@apollo/client';
import { Mineral } from '@wgt/types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import Loader from '../../../../../../../components/_organisms/Loader';
import { LIST_MINERALS } from './graphql';
import MineralsListItem from './MineralsListItem';
import MineralsFilters from './MineralsFilters';
import { AlertMessage, Title } from '../../../../../../../components';
import './styles.scss';
import useMineralsContext, { MineralsProvider } from './useMinerals';

function MineralsListContent(): JSX.Element {
  const { t } = useTranslation('minerals');
  const { filters } = useMineralsContext();
  const [openedMineral, setOpenedMineral] = useState<Mineral>();

  const { data, loading } = useQuery<{ minerals: { data: Mineral[] } }>(
    LIST_MINERALS,
    {
      variables: filters,
    },
  );
  return (
    <>
      <MineralsFilters />
      {loading && <Loader />}
      {!data?.minerals?.data?.length ? (
        <AlertMessage>{t('There is no records in the list.')}</AlertMessage>
      ) : (
        <div className="minerals-table">
          <Title heading="h3" className="mx-0 mb-4">
            Results
          </Title>
          <Row className="head border-bottom pb-2 mb-3">
            <Col xs="12" lg="2">
              {t('Classification')}
            </Col>
            <Col xs="12" lg="2">
              {t('Group')}
            </Col>
            <Col xs="12" lg="2">
              {t('Subgroup')}
            </Col>
            <Col xs="12" lg="2">
              {t('Species')}
            </Col>
            <Col xs="12" lg="2">
              {t('Variety')}
            </Col>
            <Col xs="12" lg="4">
              {t('Series')}
            </Col>
          </Row>
          {data?.minerals.data.map((mineral, key) => (
            <MineralsListItem
              mineral={mineral}
              key={`mineral-table-item-${key}`}
              onToggle={() =>
                setOpenedMineral(
                  openedMineral?.id === mineral.id ? undefined : mineral,
                )
              }
              isOpened={openedMineral?.id === mineral.id}
            />
          ))}
        </div>
      )}
    </>
  );
}

const MineralsList: React.FC = () => {
  return (
    <MineralsProvider>
      <MineralsListContent />
    </MineralsProvider>
  );
};

export default MineralsList;
