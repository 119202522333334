/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';
import { Col, Row } from 'reactstrap';
import moment from 'moment';
import CompanyNameLogo from './assets/img/placeholder-company.png';
import { useTranslation } from 'react-i18next';
import Loader from '../_organisms/Loader';
import { InvoiceFull } from '@wgt/types';
import { convertPageIdFieldsToArray } from '@wgt/converters';
import AmlStatement from './aml';

export default function Invoice({
  invoice,
  type = 'invoice',
}: {
  invoice: InvoiceFull;
  type?: 'invoice' | 'aml';
}): JSX.Element {
  const { t, ready } = useTranslation('invoicesReport');

  const { executiveBuyerFirm, totalPageIDs, pageIDs } = useMemo(
    () => ({
      executiveBuyerFirm:
        invoice?.pointOfSale?.executiveBuyer?.employments[0] ?? undefined,
      totalPageIDs: invoice.snapshot?.products?.reduce(
        (acc: number, item: any) => (acc += item.product_page_ids?.length),
        0,
      ),
      pageIDs: invoice.snapshot?.products.reduce(
        (acc: any, item: any) => [...acc, ...item.product_page_ids],
        [],
      ),
    }),
    [invoice],
  );

  const pageIDsColumns = useMemo(() => {
    return pageIDs?.reduce((acc: any, page: any, index: number) => {
      const fieldsSerialized = convertPageIdFieldsToArray(page.form_values);

      let fieldAux: any = [];
      const column: any = [];

      fieldsSerialized.forEach((field: any, indexField: number) => {
        if (indexField > 0 && !(indexField % 6)) {
          column.push(fieldAux);
          fieldAux = [];
        }
        fieldAux.push(field);
        if (indexField === fieldsSerialized.length - 1) {
          column.push(fieldAux);
        }
      });
      acc.push({ pageId: index, column });
      return acc;
    }, []);
  }, [pageIDs]);

  const transformWebsite = (website: string) => {
    return website.replace(/^(?:https?:\/\/)?/i, '').split('/')[0];
  };

  if (!ready) {
    return <Loader round />;
  }

  if (type === 'aml') {
    return (
      <AmlStatement
        invoice={invoice}
        totalPageIDs={totalPageIDs}
        executiveBuyerFirm={executiveBuyerFirm}
        pageIDsColumns={pageIDsColumns}
      />
    );
  }

  return (
    <>
      <Row>
        <Col className="d-flex align-self-center flex-column">
          <p className="color-muted">{t('Invoice review')}</p>
          <p className="color-muted">
            {t('Invoice Identification Code')}: {invoice?.id}
          </p>
          <p className="color-muted">{moment().format('LL')}</p>
        </Col>
        <Col className="d-flex justify-content-end align-items-center">
          <img
            src={invoice?.pointOfSale?.firm?.extra?.logo_url ?? CompanyNameLogo}
            className="img-thumbnail img-logo simple-invoice-logo"
            alt="Company Name"
          />
        </Col>
      </Row>

      <Row>
        <Col className="pt-4">
          <p className="text-uppercase title-text-muted mb-1">{t('Bill To')}</p>
          <p>
            <strong>{executiveBuyerFirm?.name}</strong>
          </p>
          <p>
            {executiveBuyerFirm?.address?.number}{' '}
            {executiveBuyerFirm?.address?.address}
            {executiveBuyerFirm?.address?.state &&
              `, ${executiveBuyerFirm?.address?.state}`}
            {executiveBuyerFirm?.address?.postal_code &&
              `, ${executiveBuyerFirm?.address?.postal_code}`}
          </p>
          <p>
            {executiveBuyerFirm?.address?.city},{' '}
            {executiveBuyerFirm?.address?.country?.name}
          </p>
          <p>{executiveBuyerFirm?.address?.phone}</p>
          <p>
            {!!executiveBuyerFirm?.website &&
              transformWebsite(executiveBuyerFirm?.website)}
          </p>
          <p>
            {!!executiveBuyerFirm?.vatcode &&
              `${t('VAT Code')}: ${executiveBuyerFirm?.vatcode}`}
          </p>
        </Col>

        <Col md="7" className="text-right">
          <h1>{invoice?.pointOfSale?.firm?.name}</h1>
          <p>
            {invoice?.pointOfSale?.firm?.address?.number}{' '}
            {invoice?.pointOfSale?.firm?.address?.address}
            {invoice?.pointOfSale?.firm?.address?.state &&
              `, ${invoice?.pointOfSale?.firm?.address?.state}`}
            {invoice?.pointOfSale?.firm?.address?.postal_code &&
              `, ${invoice?.pointOfSale?.firm?.address?.postal_code}`}
          </p>
          <p>
            {invoice?.pointOfSale?.firm?.address?.city},{' '}
            {invoice?.pointOfSale?.firm?.address?.country?.name}
          </p>
          <p>{invoice?.pointOfSale?.firm?.address?.phone}</p>
          <p>
            {!!invoice?.pointOfSale?.firm?.website &&
              transformWebsite(invoice?.pointOfSale?.firm?.website)}
          </p>

          <p>
            {!!invoice?.pointOfSale?.firm?.vatcode &&
              `${t('VAT Code')}: ${invoice?.pointOfSale?.firm?.vatcode}`}
          </p>
        </Col>
      </Row>

      <table className="table default-report mt-3">
        <thead>
          <tr>
            <th>{t('Item')}</th>
            <th className="text-right w-10">{t('Price')}</th>
            <th className="text-right w-10">{t('Qty')}</th>
            <th className="text-right w-10">{t('Off')}</th>
            <th className="text-right w-10">{t('Total')}</th>
          </tr>
        </thead>
        <tbody>
          {invoice?.snapshot?.products?.map((product: any) => (
            <tr key={product.sku}>
              <td>
                SKU: {product.sku}
                <span className="text-light d-block">{product?.name}</span>
              </td>
              <td className="text-right">${product.total_price}</td>
              <td className="text-right">1</td>
              <td className="text-right">0</td>
              <td className="text-right">${product.total_price}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td>
              <p className="title-text-muted">{t('Notes')}</p>
              <p>worldgemtrade.com</p>
              <p>
                {t(
                  'To view invoice and AML Statement with full stock details.',
                )}
              </p>
              <p>{t('Trace Documents are also available.')}</p>
            </td>
            <td className="text-right">
              <p className="title-text-muted">{t('Subtotal')}</p>
              <p>${invoice?.snapshot?.sub_total ?? 0}</p>
            </td>
            <td className="text-right">
              <p className="title-text-muted">{t('Qty')}</p>
              <p>{invoice?.snapshot?.products?.length}</p>
            </td>
            <td className="text-right">
              <p className="title-text-muted">{t('Discount')}</p>
              <p>${invoice?.snapshot?.discount ?? 0}</p>
            </td>
            <td className="text-right">
              <p className="title-text-muted">{t('Tax Rate')}</p>
              <p>${invoice?.snapshot?.tax ?? 0}%</p>
              <br />
              <p className="title-text-muted">{t('Total')}</p>
              <p>${invoice?.snapshot?.total ?? 0}</p>
            </td>
          </tr>
        </tfoot>
      </table>
    </>
  );
}
