import {
  FetchResult,
  gql,
  MutationFunctionOptions,
  useMutation,
} from '@apollo/client';
import { useRef, useEffect } from 'react';

const noop = () => undefined;

/**
 * React hook for moneris integration
 * @param props
 * @param props.onPageLoad triggers on checkout page loaded
 * @param props.onCancel triggers when user´s cancel the checkout
 * @param props.onError triggers when some error occurs
 * @param props.onPaymentComplete triggers when user´s close the checkout screen after the payment is done
 * @param props.onPaymentReceipt triggers when complete the transaction
 * @returns
 */
export default function useMoneris({
  invoiceId,
  onPageLoad = noop,
  onCancel = noop,
  onError = noop,
  onPaymentComplete = noop,
  onPaymentReceipt = noop,
}: UseMonerisProps = {}): UseMoneris {
  const ref = useRef<HTMLDivElement>(null);

  const [checkout, { data, loading }] = useMutation<
    {
      preloadMoneris: PreloadMoneriesResponse;
    },
    PreloadMoneriesParams
  >(PRELOAD_MONERIS_GQL);

  const [pay] = useMutation(
    gql`
      mutation paymentByMoneris($invoiceId: ID!, $ticket: String!) {
        paymentByMoneris(invoice_id: $invoiceId, ticket: $ticket) {
          id
        }
      }
    `,
    {
      variables: {
        invoiceId,
        ticket: data?.preloadMoneris.ticket,
      },
    },
  );

  useEffect(() => {
    if (!data?.preloadMoneris) {
      return;
    }

    const moneris = data.preloadMoneris;

    const script = document.createElement('script');
    script.src = moneris.library;

    const container = document.createElement('div');
    container.id = 'moneris-container-checkout';

    script.onload = () => {
      const monerisCheckout = new window.monerisCheckout();
      monerisCheckout.setMode(moneris.environment);
      monerisCheckout.setCheckoutDiv(container.id);
      monerisCheckout.startCheckout(moneris.ticket);

      monerisCheckout.setCallback('page_loaded', onPageLoad);
      monerisCheckout.setCallback('error_event', onError);
      monerisCheckout.setCallback('payment_complete', onPaymentComplete);
      monerisCheckout.setCallback('payment_receipt', (e: MonerisEvent) => {
        pay();
        onPaymentReceipt(e);
      });
      monerisCheckout.setCallback('cancel_transaction', (e: MonerisEvent) => {
        monerisCheckout.closeCheckout();
        onCancel(e);
      });
    };

    ref.current?.appendChild(container);
    ref.current?.appendChild(script);

    return () => {
      if (ref.current?.contains(container)) {
        ref.current?.removeChild(container);
      }
      ref.current?.removeChild(script);
    };
  }, [data?.preloadMoneris]);

  return [ref, { checkout, loading }];
}

interface MonerisEvent {
  handler: string;
  ticket: string;
  response_code: string;
}
interface PreloadMoneriesResponse {
  ticket: string;
  library: string;
  environment: 'qa' | 'prod';
}
interface PreloadMoneriesParams {
  total: number;
}

interface UseMonerisHandlers {
  loading: boolean;
  checkout: (
    options?:
      | MutationFunctionOptions<
          {
            preloadMoneris: PreloadMoneriesResponse;
          },
          PreloadMoneriesParams
        >
      | undefined,
  ) => Promise<
    FetchResult<{
      preloadMoneris: PreloadMoneriesResponse;
    }>
  >;
}
type UseMoneris = [React.RefObject<HTMLDivElement>, UseMonerisHandlers];

type UseMonerisProps = {
  invoiceId?: string;
  onPageLoad?: (e: MonerisEvent) => void;
  onCancel?: (e: MonerisEvent) => void;
  onError?: (e: MonerisEvent) => void;
  onPaymentReceipt?: (e: MonerisEvent) => void;
  onPaymentComplete?: (e: MonerisEvent) => void;
};

const PRELOAD_MONERIS_GQL = gql`
  mutation preloadMoneris($total: Float!) {
    preloadMoneris(total: $total) {
      ticket
      library
      environment
    }
  }
`;
