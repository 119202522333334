import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { Button, Title } from '../../../components';
import {
  ProfileActionsMenu,
  ProfileDetailsCard,
  ProfileFirmCard,
  ProfileSecurityMenu,
  ProfileAddresses,
} from '../../../components/Profile';

import Loader from '../../../components/_organisms/Loader';

import useProfileContext, { ProfileProvider } from '../../../hooks/useProfile';

import './styles.scss';

function ProfileContent(): JSX.Element {
  const { loading, user } = useProfileContext();
  const { t } = useTranslation('profile');

  if (loading || !user?.id) {
    return <Loader />;
  }
  return (
    <div className="profile-account-page">
      <Row>
        <Col md="4">
          <ProfileDetailsCard />
        </Col>
        <Col>
          <Row>
            <Col md="8">
              <ProfileActionsMenu />
            </Col>
            <Col>
              <ProfileSecurityMenu />
            </Col>
          </Row>
          <Row>
            <Col>
              <ProfileFirmCard />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="my-5">
        <Col className="d-flex justify-content-end">
          <Button>{t('Search')}</Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Title heading="h4">{t('Search Results')}</Title>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="card p-4">
            <ProfileAddresses />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default function Profile(): JSX.Element {
  return (
    <ProfileProvider>
      <ProfileContent />
    </ProfileProvider>
  );
}
