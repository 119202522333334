import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UploadButton } from '../../../../components';
import useCsvImportContext from '../useCsvImport';

export default function UploadImportFile(): JSX.Element {
  const { t } = useTranslation(['csvImport', 'common']);
  const methods = useForm({
    defaultValues: { file: null },
  });
  const { setFile } = useCsvImportContext();
  return (
    <div className="dropzone d-flex align-items-center justify-content-center">
      <FormProvider {...methods}>
        <UploadButton
          setImageToUpload={setFile}
          name="file"
          buttonText={t('Upload CSV / Excel File')}
          accept=".xlsx, .xls, .csv"
        />
      </FormProvider>
    </div>
  );
}
