import React, { useMemo, useRef } from 'react';
import { Card, CardBody, CardHeader, Collapse, Row, Col } from 'reactstrap';
import { FaPlusCircle, FaGlobe, FaMinusCircle } from 'react-icons/fa';
import { Avatar, IconButton } from 'evergreen-ui';
import { useCollapseContext } from '../../../../../../services/collapse';
import { Text, Button } from '../../../../../../components';
import SKU from '../../Header/SKU';
import { DropTargetMonitor, useDrag, useDrop, XYCoord } from 'react-dnd';
import { useProductUpdate } from '../../Provider';
import { PageId } from '../../../types';
import { DroppedTemplateComponent, PropertyValue } from '@wgt/types';
import PlaceholderImageCircle from '../../../../../../assets/placeholder-image-circle.png';
import { pageIdFieldValue } from '@wgt/shared/methods';

interface Page {
  name: string;
  id: string;
}

interface Props {
  page: PageId;
}

interface Draggable {
  type: 'card';
  page: Page;
}

interface TableFields {
  highlights: DroppedTemplateComponent[];
  common: DroppedTemplateComponent[];
}

const PageItem: React.FC<Props> = ({ page }) => {
  const ref = useRef<HTMLDivElement>(null);
  const { pages } = useProductUpdate();
  const { active, set } = useCollapseContext();
  const isActive = useMemo<boolean>(() => active?.toString() === page.__id, [
    page.__id,
    active,
  ]);

  const [, drop] = useDrop({
    accept: 'card',
    hover: (item: Draggable, monitor: DropTargetMonitor) => {
      if (!ref.current) {
        return;
      }

      const fromPosition = pages.fields.findIndex((x) => x.id === item.page.id);
      const toPosition = pages.fields.findIndex((x) => x.id === page.id);

      if (fromPosition === toPosition) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (fromPosition < toPosition && hoverClientY < hoverMiddleY) {
        return;
      }
      if (fromPosition > toPosition && hoverClientY > hoverMiddleY) {
        return;
      }

      pages.move(fromPosition, toPosition);
    },
  });
  const [{ isDragging }, drag] = useDrag({
    item: { type: 'card', page },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const tableFields = useMemo(
    () =>
      page.category?.template?.fields.reduce<TableFields>(
        (acc, curr) => {
          if (curr.is_highlight) {
            acc.highlights.push(curr);
          } else {
            acc.common.push(curr);
          }
          return acc;
        },
        { highlights: [], common: [] },
      ) ?? { highlights: [], common: [] },
    [page],
  );

  const getFieldValue = (field: DroppedTemplateComponent) => {
    const NA = 'Not avaiable';

    if (!field.property?.key || !page.fields) {
      return NA;
    }
    switch (field.component?.key) {
      case 'simple-text':
        return page.fields[field.property.key]
          ? pageIdFieldValue(page.fields[field.property.key])
          : NA;
      case 'dropdown-expanded-color':
      case 'dropdown-expanded-image':
      case 'multi-select':
      case 'dropdown-list':
      case 'dropdown-expanded-text':
        const values =
          field.values?.filter((x: PropertyValue) => {
            if (!field.property) {
              return false;
            }
            switch (
              Object.prototype.toString.call(page.fields?.[field.property.key])
            ) {
              case '[object Object]':
                return page.fields?.[field.property.key][x.key]?.selected;
              case '[object Array]':
                return page.fields?.[field.property.key].includes(x.key);
              case '[object String]':
                return page.fields?.[field.property.key] === x.key;
              default:
                return false;
            }
          }) ?? [];
        return values.length ? values.map((x) => x.name).join(', ') : NA;
      case 'min-max':
        const range: { min: string; max: string } =
          page.fields[field.property.key] ?? {};

        if (!range.min || !range.max) {
          return NA;
        }
        return `${range.min} to ${range.max}`;
      default:
        return NA;
    }
  };

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <div ref={ref} className="mb-3">
      <Card
        style={{ opacity, backgroundColor: isActive ? '#F4F6F8' : 'initial' }}
      >
        <CardHeader
          className="d-flex align-items-center"
          style={{ backgroundColor: isActive ? '#F4F6F8' : 'initial' }}
        >
          <div className="d-flex align-items-center p-2">
            <input type="checkbox" />
            <IconButton
              type="button"
              icon={isActive ? FaMinusCircle : FaPlusCircle}
              appearance="minimal"
              onClick={() => set(page.__id ?? '')}
              className="ml-2"
            />
          </div>
          <div className="d-flex align-items-center">
            <Avatar src={PlaceholderImageCircle} size={50} />
          </div>
          {tableFields.highlights.map((field) => (
            <div className="d-flex align-items-center ml-3" key={field.id}>
              <Text>{getFieldValue(field)}</Text>
            </div>
          ))}
        </CardHeader>
        <Collapse isOpen={isActive}>
          <CardBody>
            <Row className="bg-white rounded p-3 mx-2 mb-1">
              {tableFields.common.map((field) => (
                <Col lg="4" key={field.id} className="mb-1">
                  <Text>{`${field.label}: `}</Text>
                  <Text type="muted" className="font-italic">
                    {getFieldValue(field)}
                  </Text>
                </Col>
              ))}
            </Row>

            <div className="d-flex justify-content-between mt-5">
              <Button
                iconBefore={FaGlobe}
                type="primary"
                className="btn-trace-document mt-3"
              >
                Trace Document
              </Button>
              <SKU />
            </div>
          </CardBody>
        </Collapse>
      </Card>
    </div>
  );
};

export default PageItem;
