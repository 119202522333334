import React from 'react';
import { useTranslation } from 'react-i18next';
import { FiFileText } from 'react-icons/fi';
import moment from 'moment';
import { Text } from '../../../../../../components';
import usePointOfSaleUpdate from '../../usePointOfSaleUpdate';

export default function PolicyType(): JSX.Element {
  const { t } = useTranslation('pointOfSales');
  const { pointOfSale } = usePointOfSaleUpdate();
  return (
    <div className="border rounded p-2 bg-white mr-0" id="policy-type">
      <div className="d-flex header">
        <FiFileText className="iconFileText" />
        <Text className="box-title ml-2">{t('Policy Type')}</Text>
      </div>
      <div className="ml-2 mt-4">
        <Text>
          {t('Expiry date')}:{' '}
          <strong>Net {pointOfSale.policy?.net_day_count ?? '__'} Days</strong>
        </Text>
        <Text>
          {t('Today')}:{' '}
          <strong>
            {moment(pointOfSale.policy?.expiry_date).format('YYYY/MM/DD')}
          </strong>
        </Text>
      </div>
    </div>
  );
}
