import React, { ChangeEvent, useState } from 'react';
import { FaGlobe } from 'react-icons/fa';
import { Row, Col, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Button, Text, Title, TooltipHelp } from '../';

export default function PageIDCommonActions(): JSX.Element {
  const { t } = useTranslation(['pointOfSales', 'product', 'common']);
  const [skuToPageId, setSkuToPageId] = useState<string>('');

  const handleSkuToPageId = (event: ChangeEvent<HTMLInputElement>) => {
    setSkuToPageId(event.target.value);
  };

  return (
    <Row>
      <Col xs="12" md="6" xl="4" className="px-3 pr-xl-1 pl-xl-3">
        <div className="border rounded p-3">
          <Title heading="h3">
            {t('Add Trace Documents')}{' '}
            <TooltipHelp
              className="mx-2"
              description="Waiting for description..."
            />
          </Title>
          <div className="d-flex mt-3 align-items-center justify-content-between">
            <div className="simple-input w-100">
              <Input
                type="text"
                name="sku"
                placeholder={`${t('Enter SKU or Page ID')}`}
              />
            </div>
            <Button small className="ml-3">
              {t('common:Add')}
            </Button>
            <Button className="btn outline ml-5" type="blue">
              {t('common:Menu')}
            </Button>
          </div>
          <Button
            iconBefore={FaGlobe}
            type="primary"
            className="btn-trace-document mt-3"
          >
            {t('common:Trace Document')}
          </Button>
        </div>
      </Col>

      <Col xs="12" md="6" xl="4" className="px-3 mt-3 mt-md-0">
        <div className="border rounded p-3">
          <Title heading="h3">{t('Add Page ID')}</Title>
          <div className="d-flex justify-content-between">
            <div className="w-100 flex-grow-1">
              <div className="d-flex mt-3 align-items-center">
                <div className="simple-input w-75">
                  <Input
                    type="text"
                    name="sku"
                    placeholder={`${t(
                      'product:Enter Page ID and add to this SKU',
                    )}`}
                  />
                </div>
                <Button small className="ml-3">
                  {t('common:Add')}
                </Button>
              </div>

              <div className="d-flex mt-3 align-items-center">
                <div className="simple-input w-75">
                  <Input
                    type="text"
                    name="sku"
                    placeholder={`${t('Enter SKU and Locate Page ID')}`}
                  />
                </div>
                <Button small className="ml-3">
                  {t('common:Locate')}
                </Button>
              </div>
            </div>

            <Button className="btn outline ml-5 mt-3" type="blue">
              {t('common:Menu')}
            </Button>
          </div>
        </div>
      </Col>
      <Col
        xs="12"
        md="12"
        xl="4"
        className="px-3 pl-xl-1 pr-xl-3 d-flex flex-column mt-3 mt-xl-0"
      >
        <div className="border rounded p-3">
          <Title heading="h3">{t('Move Page ID')}</Title>
          <div className="d-flex mt-3 align-items-center">
            <div className="simple-input w-100">
              <Input
                type="text"
                name="skuToPageId"
                placeholder={`${t('Enter SKU and add this Page ID')}`}
                onChange={handleSkuToPageId}
              />
            </div>
            <Button small className="ml-3">
              {t('common:Add')}
            </Button>
            <Button className="btn outline ml-5" type="blue">
              {t('common:Menu')}
            </Button>
          </div>
        </div>

        {!!skuToPageId && (
          <div className="border rounded p-1 d-flex align-items-center justify-content-between mt-3 mt-xl-auto border-danger">
            <Text className="font-small text-danger">
              {t(
                'Would you like to verify it this Page ID is in the correct location?',
              )}
            </Text>
            <div className="d-flex">
              <Button small className="mr-2">
                {t('common:Yes')}
              </Button>
              <Button small type="danger">
                {t('common:No')}
              </Button>
            </div>
          </div>
        )}
      </Col>
    </Row>
  );
}
